import { useState, useEffect, forwardRef, useRef, useImperativeHandle, useContext } from 'react';
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'
import CourseStepEnum from '../../../../domain/enum/CourseStepEnum';
import InputMask from "react-input-mask";
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { 
    Card, 
    Col, 
    Form, 
    FormControl, 
    InputGroup, 
    Row, 
    Toast
} from "react-bootstrap";

import ICreateCourseInitialDataPayload from '../../../../domain/payloads/createCourse/ICreateCourseInitialDataPayload';
import ICreatableSelect from '../../../../domain/util/ICreatableSelect';

import {
    StyledCreatableSelect,
    RequiredFormMessage
} from "../CreateCourseStyles";

import CategoryServices from '../../../../services/CategoryServices'
import { SubCategoryServices } from '../../../../services/SubCategoryServices'
import { FileUploadServices } from '../../../../services/FileUploadServices'
import CreatorServices from '../../../../services/CreatorServices'
import Loader from "react-loader-spinner";


const creatorService = new CreatorServices();
const uploadService = new FileUploadServices();
const categoryServices = new CategoryServices();
const subCategoryServices = new SubCategoryServices();

export const CreateCourseInitialDataForm = forwardRef((props, ref) => {

    const {
        course,
        isEdit,
        dataIsLoaded,
        isChanged,
        setDataIsLoaded,
        setUpdateDate,
        setIsChanged,
        setIsValid,
        setIsLoading,
        setNavigationStep
    } = useContext(CreateCourseContext);
    

    useImperativeHandle(ref, () => ({
        submit(isBacking:boolean, saveAsDraft: boolean, stepRender?:number) {            
            setSaveAsDraft(saveAsDraft);
            setTimeout(() => {
                const submitButton = submitHiddenButton.current as any
                submitButton.click();    
            }, 200);
        }
    }));

    //workaround    
    const [saveAsDraft, setSaveAsDraft] = useState(false);
    //workaround

    const submitHiddenButton = useRef(null);

    const [showToast, setShowToast] = useState(true);
    const closeToast = () => setShowToast(false);

    const [isCoverImageValid, setIsCoverImageValid] = useState(false);
    const [isWorkLoadValid, setIsWorkLoadValid] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [descriptionMaxLength, setDescriptionMaxLength] = useState(false);
    const [descriptionEqualTitle, setDescriptionEqualTitle] = useState(false);
    const [descriptionCounter, setDescriptionCounter] = useState(0);

    const [allowedExtension, setAllowedExtension] = useState(true);
    const [coverImage, setCoverImage] = useState('');
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    
    const [categoriesInput, setCategoriesInput] = useState('')
    const [categories, setCategories] = useState<ICreatableSelect[]>([])
    const [selectedCategories, setSelectedCategories] = useState<ICreatableSelect[]>([])

    const [subCategoriesInput, setSubCategoriesInput] = useState('')
    const [subCategoriesPlaceholder, setSubCategoriesPlaceholder] = useState('Subcategorias')
    const [subCategories, setSubCategories] = useState<ICreatableSelect[]>([])
    const [selectedSubCategories, setSelectedSubCategories] = useState<ICreatableSelect[]>([])
    const [enabledSubCategories, setEnableSubCategories] = useState(true)

    const [reload, callReload] = useState(true);

    const fetchData = async () => {

        let responseCategories: ICreatableSelect[] = []
        let responseSubCategories: ICreatableSelect[] = []

        await categoryServices.get().then(function (response) {
            if (response.success) {
                responseCategories = response.result.map(cat => (
                    { value: cat.id, label: cat.name }
                ));

                let newVector = categories.concat(responseCategories)
                setCategories(newVector);
            }
        })

        await subCategoryServices.get().then(function (response) {
            if (response.success) {
                responseSubCategories = response.result.map(cat => (
                    { value: cat.id, label: cat.name }
                ));

                let newSubVector = subCategories.concat(responseSubCategories)
                setSubCategories(newSubVector);
            }
        })

        if (dataIsLoaded) {
            setIsLoading(true)

            var shouldValidade = { shouldValidate: true };
            var shouldNotValidade = { shouldValidate: false };

            setValue('title', course.title, shouldValidade);

            setValue('description', course.description, shouldValidade);
            setDescriptionCounter(course.description.length);

            if(course.workLoad)
            {
                setValue('workload', course.workLoad, shouldValidade);             
            }else
            {
                setValue('workload', course.workLoad, shouldNotValidade);             
            }
            setIsWorkLoadValid(true);

            setCoverImage(course.coverImage);
            setIsCoverImageValid(true);            

            let selectedCategoriesIds = course.courseCategories.map(category => {
                return category.categoryId
            })
            let selectedCategories = responseCategories.filter((category) => {
                return selectedCategoriesIds.includes(category.value)
            })
            setSelectedCategories(selectedCategories)

            let selectedSubCategoriesIds = course.courseSubCategories.map(subCategory => {
                return subCategory.subCategoryId
            })
            let selectedSubCategories = responseSubCategories.filter((subCategory) => {
                return selectedSubCategoriesIds.includes(subCategory.value)
            })
            setSelectedSubCategories(selectedSubCategories)

            setIsValid(true);
            setIsLoading(false)
        }
        callReload(!reload);
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        validateMandatoryFields();
    }, [isCoverImageValid, isWorkLoadValid])

    useEffect(() => {
        let isDisabled = selectedCategories.length > 0 ? false : true;
        setEnableSubCategories(isDisabled)

        if (isDisabled) {
            setSubCategoriesPlaceholder("Subcategorias")
        } else {
            setSubCategoriesPlaceholder("Digite para buscar subcategorias sugeridas ou insira novas")
        }

        validateMandatoryFields();
    }, [selectedCategories])

    //BEGIN - Categories Handlers
    const handleCategoriesInputChange = (inputValue: string) => {
        setCategoriesInput(inputValue);
        validateMandatoryFields();
    };

    const handleCategoriesChange = (value: any, actionMeta: any) => {
        if (actionMeta.action === "select-option") {
            let vector = selectedCategories
            vector = vector?.concat(actionMeta.option)
            setSelectedCategories(vector)
        }
        else if (actionMeta.action === "remove-value") {
            let vector = selectedCategories
            vector = vector?.filter((selectedCategory) => {
                return selectedCategory.value !== actionMeta.removedValue.value
            })
            setSelectedCategories(vector)
        } else {
            setSelectedCategories([])
        }

        validateMandatoryFields();
        setIsChanged(true);
    };

    const handleCategoriesCreate = (inputValue: any) => {
        let newCategory = { value: uuidv4(), label: inputValue }
        let newVector = categories.concat(newCategory)
        setCategories(newVector)

        newVector = selectedCategories.concat(newCategory)
        setSelectedCategories(newVector)

        validateMandatoryFields();
        setIsChanged(true);
    };
    //END - Categories Handlers

    //BEGIN - SubCategories Handler
    const handleSubCategoriesInputChange = (inputValue: string) => {
        setSubCategoriesInput(inputValue);
    };

    const handleSubCategoriesChange = (value: any, actionMeta: any) => {
        if (actionMeta.action === "select-option") {
            let vector = selectedSubCategories
            vector = vector?.concat(actionMeta.option)
            setSelectedSubCategories(vector)
        }
        else if (actionMeta.action === "remove-value") {
            let vector = selectedSubCategories
            vector = vector?.filter((selectedSubCategory) => {
                return selectedSubCategory.value !== actionMeta.removedValue.value
            })
            setSelectedSubCategories(vector)
        } else {
            setSelectedSubCategories([])
        }

        setIsChanged(true);
    };

    const handleSubCategoriesCreate = (inputValue: any) => {
        let newSubCategory = { value: uuidv4(), label: inputValue }

        let newVector = subCategories.concat(newSubCategory)
        setSubCategories(newVector)

        newVector = selectedSubCategories.concat(newSubCategory)
        setSelectedSubCategories(newVector)
        
        setIsChanged(true);
    };
    //END - SubCategories Handler

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues
    } = useForm<ICreateCourseInitialDataPayload>({ mode: "onChange" });

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }

    const onInputFileChange = (e: any) => {
        setIsUploading(true)
        const fileUploaded = e.target.files[0];

        setAllowedExtension(true);
        if (fileUploaded != null && (fileUploaded.type !== 'image/png' && fileUploaded.type !== 'image/jpg' && fileUploaded.type !== 'image/jpeg')) {
            setAllowedExtension(false);
            return false;
        }

        const formData = new FormData();
        formData.append('File', fileUploaded);
        formData.append('ValidatedFormat', "true");

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data, image/*, video/*'
            }
        }

        uploadService.uploadTempContainer(formData, config).then(function (response) {
            if (response.success) {
                setCoverImage(response.result.path);
                setIsCoverImageValid(true); // this isValid is from hook form   
            }
            setIsUploading(false)
        })
        
        setIsChanged(true);
        callReload(!reload);

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }
    }

    const handleTitleChange = (e: any) => {
        setValue("title", e.target.value, { shouldValidate: true });

        validateMandatoryFields();
        setIsChanged(true);
        callReload(!reload);
    }

    const handleDescriptionChange = (e) => {
        var maxLength = 150;

        var description150 = e.target.value.substr(0, maxLength);
        setValue("description", description150, { shouldValidate: true });
        setDescriptionCounter(description150.length);

        setDescriptionMaxLength(false)
        if (description150.length === maxLength) {
            setDescriptionMaxLength(true)
        }

        setDescriptionEqualTitle(false)
        var title = getValues("title");
        if (description150 === title) {
            setDescriptionEqualTitle(true)
        }

        validateMandatoryFields();
        setIsChanged(true);
        callReload(!reload);
    }

    const handleWorkloadChange = (e) => {
        var workLoadText = e.target.value;
        setValue("workload", workLoadText, { shouldValidate: true });  

        if(e.target.value.length > 0) {                  
            let validate = /^([0-9]?[0-9]|2[0-9]):[0-5][0-9]$/.test(workLoadText);
            setIsWorkLoadValid(validate);
        }else
        {            
            setIsWorkLoadValid(true);
        }

        validateMandatoryFields();
        setIsChanged(true);
        callReload(!reload);
    }

    let title = register("title", { required: true, maxLength: 150 });
    let description = register("description", { required: true, maxLength: 150 });
    let workload = register("workload", {
        required: true,
        pattern: {
            value: /^([0-9]?[0-9]|2[0-9]):[0-5][0-9]$/,
            message: 'Por favor, entre um formato válido',
        },
    });

    const onCoverImageError = (e: any) => {
        e.target.src = '/icons/default-cover-image.svg';
    }

    const validateMandatoryFields = () => {
        callReload(!reload)
        
        var title = getValues("title");
        var description = getValues("description");
        var workload = getValues("workload");
        var localIsCoverImageValid= isCoverImageValid;        
        
        if (title !== ""
            && description !== ""
            && workload !== "" && isWorkLoadValid
            && selectedCategories.length > 0
            && localIsCoverImageValid
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const onSubmit = (data: ICreateCourseInitialDataPayload) => {

        if(isChanged)
        {
            if (isEdit||course.courseStepId > CourseStepEnum.InitialData) 
            { 
                data.id = course.id;
            }
            else{
                data.id = undefined;
            }

            data.isEditMode = isEdit||course.courseStepId > CourseStepEnum.InitialData;
            data.coverimage = coverImage;
            data.categories = selectedCategories.map(cat => (
                { id: cat.value, description: cat.label }
            ));
            data.subCategories = selectedSubCategories.map(sub => (
                { id: sub.value, description: sub.label }
            ));                

    
            setIsLoading(true);
            creatorService.createCourseInitialData(data).then(function (response) {
                if (response.success) {                
                    updateCourseObj(data, response.result);
                    setUpdateDate(response.result.updateDate);
                    setIsChanged(false);
                    setIsLoading(false);
                    
                    if(!saveAsDraft)
                    {
                        setNavigationStep(CourseStepEnum.Introduction);
                    }
                }
            }).catch(err => {
                console.log(err)
                setIsLoading(false);
            });
        }
        else
        {
            setNavigationStep(CourseStepEnum.Introduction);
        }
    };

    const updateCourseObj = (data: any, response:any) => {
        //once the data is saved, then update the fresh new values into the obj properties   
        course.id = response.id;
        course.title =  data.title;
        course.workLoad =  data.workload;
        course.description =  data.description;
        course.courseStepId = response.courseStepId;
        course.coverImage =  response.coverImage;
        course.id = response.id;
        course.courseCategories = selectedCategories.map((cat) => (
            { courseId: course.id, categoryId: cat.value }
        ));
        course.courseSubCategories = selectedSubCategories.map(sub => (
            { courseId: course.id, subCategoryId: sub.value }
        ));

        setDataIsLoaded(true)
    }

    return (<>
        <Form id="step-form"
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={10} className="mx-auto">
                    <Row>
                        <Toast className="mb-3 mt-2"
                            show={showToast}
                            onClose={closeToast}>
                            <Toast.Header>
                                <Row>
                                    <Col md="auto" className="my-auto">
                                        <i className="bi-chat-dots" />
                                    </Col>
                                    <Col md="auto" className="my-auto mr-2">
                                        O seu curso será salvo automaticamente toda vez que você passar de uma etapa para a outra!
                                    </Col>
                                </Row>
                            </Toast.Header>
                        </Toast>
                    </Row>
                    <Row>
                        <Col>
                            <h1>
                                Dados Iniciais
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Defina os detalhes que serão exibidos no Catálogo de Cursos. Eles ajudarão as pessoas a encontrarem seu curso mais facilmente.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={7}>
                            <Form.Label htmlFor="course-name">Nome do curso</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl
                                    id="course-name"                                    
                                    autoComplete="off"
                                    placeholder="Este é o nome que aparecerá no Catálogo"
                                    aria-describedby="course-name"
                                    onChange={(e) => {
                                        handleTitleChange(e)
                                    }}
                                    onBlur={title.onBlur}
                                    ref={title.ref} />
                            </InputGroup>
                            {errors.title && errors.title.type === "required" && (
                                <RequiredFormMessage className="mt-2">Esse campo é obrigatório</RequiredFormMessage>
                            )}
                            {errors.title && errors.title.type === "maxLength" && (
                                <RequiredFormMessage className="mt-2">Tamanho máximo do texto excedido.</RequiredFormMessage>
                            )}
                        </Col>
                        <Col md={5} >
                            <Form.Label htmlFor="workload">Carga horária</Form.Label>
                            <InputGroup className="mb-3">                                
                                <InputMask 
                                    autoComplete="off"
                                    id="workload"
                                    mask="99:99"
                                    type="text"
                                    className="form-control"
                                    placeholder="Quantas horas são necessárias para completar o curso?"                                    
                                    onBlur={workload.onBlur}
                                    ref={workload.ref} 
                                    onChange={(e:any) => {
                                        handleWorkloadChange(e)
                                    }}
                                />
                            </InputGroup>  
                            {errors.workload && errors.workload.type === "required" && (
                                <RequiredFormMessage className="mt-2">Esse campo é obrigatório</RequiredFormMessage>
                            )}
                            {!isWorkLoadValid && errors.workload && errors.workload.type == "pattern" && (
                                <RequiredFormMessage className="mt-2">{errors.workload.message}</RequiredFormMessage>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label htmlFor="course-description">Descrição</Form.Label>
                            <InputGroup className="mb-2">
                                <FormControl
                                    autoComplete="off"
                                    id="course-description"
                                    as="textarea"
                                    placeholder="Descreva brevemente o conteúdo do curso. Esta descrição vai aparecer no Catálogo, abaixo do título do curso."
                                    aria-describedby="course-description"
                                    onChange={(e) => { handleDescriptionChange(e) }}
                                    onBlur={description.onBlur}
                                    ref={description.ref} />
                            </InputGroup>
                            <Row>
                                <Col>
                                    {errors.description && errors.description.type === "required" && (
                                    <RequiredFormMessage className="mt-2">Esse campo é obrigatório</RequiredFormMessage>
                                    )}
                                    {descriptionMaxLength && (
                                        <RequiredFormMessage className="mt-2">Tamanho máximo do texto excedido.</RequiredFormMessage>
                                    )}
                                    {descriptionEqualTitle && (
                                        <RequiredFormMessage className="mt-2">Descrição não pode ser igual ao Nome do Curso.</RequiredFormMessage>
                                    )}
                                </Col>
                                <Col>
                                    <Form.Text id="descriptionCharCount" muted className="text-right mt-1">
                                        {descriptionCounter}/150
                                    </Form.Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row className="mb-4 mt-4">
                        <Col>
                            <Form.Label>
                                Categorias do curso
                            </Form.Label>
                            <StyledCreatableSelect
                                className='react-select-container'
                                classNamePrefix="react-select"
                                name="categoriesSelect"
                                inputValue={categoriesInput}
                                isMulti
                                //isLoading={isLoading}
                                onChange={handleCategoriesChange}
                                onCreateOption={handleCategoriesCreate}
                                onInputChange={handleCategoriesInputChange}
                                placeholder="Digite para buscar categorias sugeridas ou insira novas"
                                createOptionPosition={"first"}
                                formatCreateLabel={(value) => <span>Criar a Categoria "{value}"</span>}
                                options={categories}
                                value={selectedCategories}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/*classe do Label de controle do disable
                            className={enabledSubCategories?"disabled":''}*/}
                            <Form.Label >
                                Subcategorias do curso
                            </Form.Label>
                            <StyledCreatableSelect
                                className='react-select-container'
                                classNamePrefix="react-select"
                                name="subCategoriesSelect"
                                inputValue={subCategoriesInput}
                                isMulti
                                isDisabled={enabledSubCategories}
                                //isLoading={isLoading}
                                onChange={handleSubCategoriesChange}
                                onCreateOption={handleSubCategoriesCreate}
                                onInputChange={handleSubCategoriesInputChange}
                                placeholder={subCategoriesPlaceholder}
                                createOptionPosition={"first"}
                                formatCreateLabel={(value) => <span>Criar a Subcategoria "{value}"</span>}
                                options={subCategories}
                                value={selectedSubCategories}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="auto">
                            <label className="course-img mb-3">Imagem do Curso</label>
                            <Card as="button" type="button">
                                <Card.Body className="px-1">
                                    <div className="only-cursor-pointer text-center" onClick={handleAddFileButton}>
                                        {
                                            coverImage === ''&&!isUploading
                                                ? (
                                                <>
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <i className="bi-upload" />
                                                        </Col>
                                                    </Row>
                                                    <Row className="my-1">
                                                        <Col>
                                                            <p>
                                                                Enviar uma imagem
                                                            </p>
                                                        </Col>
                                                    </Row> 
                                                </>
                                                )
                                                :
                                                (
                                                    
                                                    isUploading?
                                                    (
                                                        <>
                                                            <Row className="py-1">
                                                                <Col md="auto" className='mx-auto py-4'>
                                                                    <Loader  
                                                                        type="TailSpin"
                                                                        color="#6468C8"
                                                                        height={70}
                                                                        width={70}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                    :(
                                                        <>
                                                            <img width="210" height="130" src={coverImage} onError={onCoverImageError}></img>
                                                            <input name="coverimage" type="hidden" value={coverImage} />
                                                        </>
                                                    )
                                            
                                                ) 
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                            {!allowedExtension &&
                                <RequiredFormMessage>Formato do arquivo é inválido</RequiredFormMessage>
                            }
                        </Col>
                        <Col md={2} className="mt-5">
                            <p className="course-img">
                                Recomendamos imagens nos formatos JPEG ou PNG, retangulares, com um tamanho de até 400x220 pixels.
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <button type="submit" hidden ref={submitHiddenButton} />
        </Form>
        
        <input
            type="file"
            ref={hiddenFileInput}
            onChange={onInputFileChange}
            className="d-none"
            multiple={false}
        />

        </>);
});
