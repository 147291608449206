import { Button, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { PrimaryPurpleButton, SecondaryGreenButton } from '../../../../../components/Buttons/styles';

export const AddingModulePageStyles = styled(Container)`
    @media(min-height: 900px) {
       margin-top:7vh; 
       height: 93vh;        

    }
    @media(max-height: 900px) {
        margin-top:8vh;
       height: 92vh;        

    }
    @media(max-height: 800px) {
        margin-top:9vh;
        height: 91vh;        

    }  
    @media(max-height: 700px) {
        margin-top:10vh; 
        height: 90vh;        

    }
    .row.content{
        height:100%!important;
    }
    .row.content-menu{
        height:78vh;
        max-height:75vh;
        overflow-y: auto;
    }
    .col-md-3.d-flex.flex-column{
        background: #F9F9F9;
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #636060;
        }
        border-right:0.11vmin solid #E8E8E8;
    }
    .row.py-4{
        border-top:0.11vmin solid #E8E8E8;
        border-bottom:0.11vmin solid #E8E8E8;

    }

    .react-toggle.react-toggle--checked .react-toggle-track{
        background-color:#0C8292!important;
        border:none!important;
    }

    .react-toggle .react-toggle-track{
        background-color:#FFFFFF!important;
        border:0.11vmin;
    }

    .react-toggle-track{
        border-radius:2.77vmin;
        width:9.8vmin;
        height:5.5vmin!important;
        border:0.22vmin solid #8D8D8D!important;
    }

    .react-toggle-thumb{
        background-color:#8D8D8D;
        border:none;
        width:4.15vmin;
        height:4.15vmin!important;
        top:0.65vmin;
        left:0.65vmin; 
        &:target{
            box-shadow:none!important;
        }
    }

    .react-toggle--checked .react-toggle-thumb{
        background-color:#FFFFFF!important;
        left:4.8vmin;
    }

    .react-toggle--focus .react-toggle-thumb{
        box-shadow:none;
    }
    
    .react-toggle-screenreader-only{
        display:none !important;
    }
    input{
        border: 0.14vmin solid #B9B8B8;
        box-sizing: border-box;
        border-radius: 1.11vmin;
        height:6.66vmin;
        ::placeholder{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            display: flex;
            align-items: center;
            color: #777575;
        }
        :focus{
            background: #FFFFFF;
            border: 0.14vmin solid #A996F6;
            box-sizing: border-box;
            box-shadow: 0px 0px 0.56vmin #A996F6;
            border-radius: 1.11vmin;

            ::placeholder{
                color: #E8E8E8;
            }
        }
    }
    textarea{
        padding-top:1.5vmin;
        border: 0.14vmin solid #B9B8B8;
        box-sizing: border-box;
        border-radius: 1.11vmin;
        ::placeholder{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            display: flex;
            align-items: center;
            color: #777575;
        }
        :focus{
            background: #FFFFFF;
            border: 0.14vmin solid #A996F6;
            box-sizing: border-box;
            box-shadow: 0px 0px 0.56vmin #A996F6;
            border-radius: 1.11vmin;

            ::placeholder{
                color: #E8E8E8;
            }
        }
    }

    label{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;
        margin-bottom: 0.5vmin;
    }

    p{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #636060;
            margin-bottom:0px;
    }
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        color: #636060;
        margin-bottom:0px;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2vmin;
        color: #636060;
        margin-bottom:0px;
    }

    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        color: #636060;
        margin-bottom:0px;
    }
    .delete-lesson{
        position:relative;
        top: -6.25vmin;
        right:-84%;
        font-size: 3.2vmin;
    }
    .module-content-button .col{
        max-height:7vmin;

    }
`;

export const ModuleDetailButton = styled(SecondaryGreenButton)`
    width:100%;
    height:6.5vmin;
    i{
        font-size:3vmin;
    }
    p{
        max-width:100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
    }

`

export const ReorderModuleButton = styled(SecondaryGreenButton)`
    width:100%;
    i{
        font-size:3vmin;
    }
`
export const AddContentButton = styled(PrimaryPurpleButton)`
    width:100%;
    i{
        font-size:3vmin;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 1.93vmin!important;
    }
    .bi-plus-circle {
        font-size: 3vmin;
    }
`

const ChooseLessonTypeButton = styled(Button)`
    width:100%;
    padding-bottom:1.8vmin;
    background: #FFFFFF;
    border: 0.14vmin solid #E8E8E8!important;
    box-sizing: border-box;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(75, 75, 75, 0.15);
    border-radius: 1.1vmin;
    color:#0C8292;

    &:hover{
        background: #E1F4F7;
        /* Neutral/4 */

        border: 1px solid #E8E8E8;
        box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
        border-radius: 8px;
    }
    &:active{
        background: #E1F4F7!important;
        /* Neutral/4 */

        border: 1px solid #E8E8E8;
        box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
    }
    &:focus{
        background: #E1F4F7!important;
        /* Neutral/4 */

        border: 1px solid #E8E8E8;
        box-shadow: #fff 0 0 0 0.42vmin, #0c8292 0 0 0 0.56vmin !important;
    }
    &:disabled{
        opacity:0.5;
        background: #E1F4F7!important;
    }
    i{
        color: #0C8292;
        font-size:2.77vmin

    }
    p{
        text-align:left;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;

    }
    h3{
        color: #0C8292!important;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.77vmin;
        line-height: 3.33vmin;
        margin-bottom:0px;
    }
`

export const ClassLessonTypeButton = styled(ChooseLessonTypeButton)`

`

export const TaskLessonTypeButton = styled(ChooseLessonTypeButton)`
    i{
        font-size:3.2vmin;
    }
`

export const QuizLessonTypeButton = styled(ChooseLessonTypeButton)`
    i{
        font-size:3.2vmin;
    }
`

export const LessonContentDetailsStyles = styled(Row)`
    overflow-y:auto;

    ::-webkit-scrollbar{
        position:absolute;
        width: 1.5vmin;
        background: #E8E8E8;
    }
    ::-webkit-scrollbar-thumb {
        background:#B9B8B8;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #EA694C;
    }  
    @media(min-height: 900px) {
        height:82vh;      
    }
    @media(max-height: 900px) {
        height:81vh;         
    }
    @media(max-height: 800px) {
        height:80vh;           
    }  
    @media(max-height: 700px) {
        height:79vh;  

    }   
    .blank-space{
        height:8vh;
    } 
`;

export const AddImageCoverStyles = styled(Row)`
    margin-left:-1.4vmin;
    background:#F9F9F9;
    min-height:13vh;
    color:#6468C8;
    i{
        font-size:3.33vmin;
    }
    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 19px;
        color: #6468C8;
        padding-bottom:0px;

    }
`;

