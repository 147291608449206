import CourseStepViewModel from '../../../domain/models/course/CourseStepViewModel'

export const courseSteps: CourseStepViewModel[] = [
    {
        id: 1,
        description: "Dados Iniciais",
        colClassName: "ml-auto my-auto"
    },
    {
        id: 2,
        description: "Introdução",
        colClassName: "my-auto"
    },
    {
        id: 3,
        description: "Conteúdo",
        colClassName: "my-auto"
    },
    {
        id: 4,
        description: "Dependências",
        colClassName: "my-auto"
    },
    {
        id: 5,
        description: "Finalizar",
        colClassName: "mr-auto my-auto"
    }
]