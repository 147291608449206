import { useContext, useEffect, useState } from "react";
import styled from 'styled-components';
import { Col, Container, Modal, ModalBody, ModalFooter, Row } from "react-bootstrap"

//components
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { PrimaryPurpleButton, SecondaryGreenButton } from "../Buttons/styles";

//contexts
import { AssessmentContext } from '../../context/apprentice/attend/AssessmentContext';

const ConfirmExitAssessmentModal = () => {   
    
    const {
        showModalExit, setShowModalExit,
        exitAssessment
    } = useContext(AssessmentContext);

    return (
        <ConfirmExitModalStyles
            centered={true}
            show={showModalExit}
            size={"md"}
        >
            <ModalHeader>
                <Container fluid={true}>
                    <Row>
                        <Col md={"auto"} className="mx-auto">
                            <i className="bi bi-exclamation-circle" />
                        </Col>
                    </Row>
                </Container>
            </ModalHeader>
            <ModalBody className="py-0">
                <Container fluid={true}>
                    <Row>
                        <Col md={"auto"} className="mx-auto">
                            <h1>Deseja mesmo sair da avaliação?</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={"auto"} className="mx-auto mt-2">
                            <h5>Você precisará começar de novo<br />quando voltar.</h5>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter className="pb-4">
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <SecondaryGreenButton className="exit-quiz py-3 px-4" onClick={() => exitAssessment()}>
                                Sair
                            </SecondaryGreenButton>
                        </Col>
                        <Col onClick={()=> setShowModalExit(false)}>
                            <PrimaryPurpleButton className="cancel-exit py-3 px-4">
                                Continuar
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </ConfirmExitModalStyles>
    )
}

export default ConfirmExitAssessmentModal;

const ConfirmExitModalStyles = styled(Modal)`

    .modal-dialog {
        max-width: 31vw;
    }

    .modal-content {
        background: #FFFFFF;
        mix-blend-mode: normal;
        border: 0.28vmin solid #C57700;
        box-shadow: 0px 1.11vmin 11vmin rgba(0, 0, 0, 0.15);
        border-radius: 1.11vmin;
    }

    .modal-header {
        border-bottom:none;
        i{
            font-size:7.78vmin;
            color:#EB8F02;
        }
    }
    .modal-footer {

        border-top:none;
        
        button {
            width: 100%;
        }

        .exit-quiz{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
        }

        .cancel-exit {
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
        }        
    }

    .modal-body {
        h1 {
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 3.33vmin;
            line-height: 3.89vmin;
            text-align: center;
            color: #514F4E;
        }

        h5 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            text-align: center;
            color: #636060;
        }
    }
`