import {useContext} from 'react';
import { Card, Modal } from "react-bootstrap";

import { AddImageModalStyles as AddImageModalStyles } from './AddImageModalStyles';
import { ConfirmExitAddImageModal } from './ConfirmExitAddImageModal';
import { AddImageModalContext, AddImageModalContextProvider } from './context/AddImageModalContext';
import { AddImageObject } from "./context/AddImageObject";
import { AddImageModalFooter as AddImageModalFooter } from './footer/AddImageModalFooter';
import { AddImageModalBody as AddImageModalBody } from './body/AddImageModalBody';
import { AddImageModalTitle as AddImageModalTitle } from './header/AddImageModalTitle';



export const AddImageModalComponent = (props:AddImageModalComponentProps)=>{
    return(
        <AddImageModalContextProvider
            getImageFromModal={props.getImageFromModal}
            reRender={props.reRender}
            onClose={props.onClose}
        >
            <AddImageModal/>
        </AddImageModalContextProvider>
    )
}

interface AddImageModalComponentProps{
    getImageFromModal: (videoObject:AddImageObject) => void
    onClose: () => void
    reRender:()=>void
}

export const AddImageModal = ()=>{

    const {
        isUploading,
        onClose,
    } = useContext(AddImageModalContext);

    return(
        <AddImageModalStyles 
            show={true} 
            onHide={()=>onClose()}
            size="lg"
            >
            {
                !isUploading&&
                (
                    <Modal.Header closeButton className="mt-4 px-4">
                        <AddImageModalTitle/>
                    </Modal.Header>
                )
            }
                <AddImageModalBody/>
                <AddImageModalFooter/>
            <ConfirmExitAddImageModal/>
        </AddImageModalStyles>
    )
}


