import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
import { TaskDeliveryDate } from "./TaskDeliveryDate";

const TaskFeedbackComponent = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);
    
    return (
        <>
        <Row className="task-feedback">
            <Col>
                <Row className="mt-3 mb-3">
                    <Col xs={1} md="auto" className="px-0">
                        <i className="bi bi-file-earmark-check" />
                    </Col>
                    <Col xs={11} md="auto">
                        <h1>
                            Sua tarefa foi corrigida!
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <h2>
                        Nota
                    </h2>
                </Row>
                <Row>
                    <p>
                    {currentUserModuleContentProgress.moduleTask!.feedback.grade}
                    </p>
                </Row>
                <Row>
                    <h2>
                        Feedback
                    </h2>
                </Row>
                <Row>
                    <p>
                    {currentUserModuleContentProgress.moduleTask!.feedback.comments}
                    </p>
                </Row>
            </Col>
        </Row>
        <TaskDeliveryDate/>
        </>
    );
};

export default TaskFeedbackComponent