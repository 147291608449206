import {
    BreadCrumbContainer
    ,FirstBreadCrumbItem
    
} from './styles'
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";



export const BreadCrumb = ({ hrefIn }) => {

    let items = [
        { href: ("/home"), name: "Catálogo", first:true, active:false },
        // { href: "/students/bla", name: "Etapa 1", first:false, active:false },
        // { href: "/students/bla", name: "Etapa 2", first:false, active:false },
        // { href: "/students/bla", name: "Etapa 3", first:false, active:false },
        { href: "/", name: "Detalhes do Curso", first:false, active:true},
    ];

    return (

        <BreadCrumbContainer>
            <Breadcrumb>
                {items.map((item) =>
                    item.first ? (
                        <FirstBreadCrumbItem linkProps={{ to: item.href }} linkAs={Link}>
                            {item.name}
                        </FirstBreadCrumbItem>
                    ) : (                            
                        item.active ? (
                            <Breadcrumb.Item linkProps={{ to: item.href }} linkAs={Link} active>
                                {item.name} 
                            </Breadcrumb.Item>
                        )
                        : (
                            <Breadcrumb.Item linkProps={{ to: item.href }} linkAs={Link}>
                                {item.name}
                            </Breadcrumb.Item>
                        )
                    )
                )}
            </Breadcrumb>
        </BreadCrumbContainer>

        
    );
};
