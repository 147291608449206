import ApiInterface from "../interface/ApiInterface"
import IByInstitutionPaginatedPayLoad from "../domain/payloads/course/IByInstitutionPaginatedPayLoad";

export default class CourseService {
    
    public async getDistributedToApprenticePaginated(payload:IByInstitutionPaginatedPayLoad):Promise<any> {
        let endpoint = '/Course/GetDistributedToApprenticePaginated'
            .concat('?pageNumber=').concat(payload.pageNumber.toString())
            .concat('&pageSize=').concat(payload.pageSize.toString())
            .concat('&orderColumn=').concat(payload.orderColumn);    

        if(payload.categories !== undefined){
            payload.categories.forEach(category => {
                endpoint = endpoint.concat('&categoriesId=').concat(category) 
            })
        }        

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }    

    public async getDistributedToCreatorsPaginated(payload:IByInstitutionPaginatedPayLoad):Promise<any> {
        let endpoint = '/Course/GetDistributedToCreatorsPaginated'
            .concat('?pageNumber=').concat(payload.pageNumber.toString())
            .concat('&pageSize=').concat(payload.pageSize.toString())
            .concat('&orderColumn=').concat(payload.orderColumn);
        
        if(payload.categories !== undefined){
            payload.categories.forEach(category => {
                endpoint = endpoint.concat('&categoriesId=').concat(category) 
            })
        }

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });        
    }

    public async getById(id:string):Promise<any> {
        let endpoint = "/Course/".concat(id).concat("/GetById");        

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async getCourseDetails(id:string):Promise<any> {
        let endpoint = "/Course/".concat(id).concat("/GetCourseDetails");        

        return await ApiInterface.get(endpoint).then(res => {
            if(res != undefined){
                return res.data;
            }
            return null
        }).catch(err => {
            console.log(err);
        });
    }

}