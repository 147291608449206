import { useEffect, useState } from "react"
import styled from 'styled-components'
import { Container, Row } from "react-bootstrap"

import HomeHeader from "../../../components/Headers/HomeHeader"
import ManageCourseSideMenu from "../../../components/SideMenu/ManageCourseSideMenu"
import CreatorCourseListComponent from "../../../components/PaginatedSearch/CreatorCourseListComponent"

import CreatorTrilhaListView from '../../../domain/models/course/CreatorTrilhaListView'
import CreatorHeaderActiveTabEnum from "../../../domain/enum/CreatorHeaderActiveTabEnum"
import ManageCourseActiveButtonEnum from "../../../domain/enum/ManageCourseActiveButtonEnum"
import CourseStatusEnum from "../../../domain/enum/CourseStatusEnum"

import PaginatedSearchResult from "../../../domain/util/PaginatedSearchResult"
import TaskListComponent from "../manageCourse/tasks/TaskListComponent"
import TaskDetails from "../manageCourse/tasks/TaskDetails"
import { useRouteMatch } from "react-router"
import ChangeLessonLoader from "../../../components/Loaders/ChangeLessonLoader"
import ListTasksViewModel from "../../../domain/models/task/ListTasksViewModel"

import { CourseLessonTaskStatusEnum } from "../../../domain/enum/CourseLessonTaskStatusEnum"
import TasksDetailsResultView from "../../../domain/models/task/TasksDetailsResultView"
import ITaskCorrectionFeedbackPayload from "../../../domain/payloads/task/ITaskCorrectionFeedbackPayload"

import CreatorServices from '../../../services/CreatorServices'
import TaskServices from "../../../services/TaskServices"

const creatorService = new CreatorServices()
const taskServices = new TaskServices()

const ManageCoursePage = () => {
    let match = useRouteMatch<any>("/manage/:page?");

    let selectedPage = match!.params.page!

    useEffect(() => {
        pageLoad()
    }, [])

    function pageLoad() {
        switch (selectedPage) {
            case 'publications':
                loadPublications()
                break;
            case 'tasks':
                loadTasks()
                break;
            default:
                loadPublications()
                break;
        }
    }

    const [activeTab, setActiveTab] = useState<CourseStatusEnum>(CourseStatusEnum.Published)

    const [activePage, setActivePage] = useState<ManageCourseActiveButtonEnum>(ManageCourseActiveButtonEnum.publication)
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSearch, setIsLoadingSearch] = useState(true);
    const [reload, callReload] = useState(true);

    const [publishedCoursesListViewPaginatedSearch, setPublishedCoursesListViewPaginatedSearch] = useState<PaginatedSearchResult<CreatorTrilhaListView>>(initialState)
    const [draftedCoursesListViewPaginatedSearch, setDraftedCoursesListViewPaginatedSearch] = useState<PaginatedSearchResult<CreatorTrilhaListView>>(initialState)
    const [archivedCoursesListViewPaginatedSearch, setArchivedCoursesListViewPaginatedSearch] = useState<PaginatedSearchResult<CreatorTrilhaListView>>(initialState)

    function loadPublications() {
        setActivePage(ManageCourseActiveButtonEnum.publication);
        getCourseList(1, CourseStatusEnum.Published)
        getCourseList(1, CourseStatusEnum.Draft)
        getCourseList(1, CourseStatusEnum.Archived)
    }

    function getCourseList(pageNumber: number, courseStatusId: CourseStatusEnum) {
        setIsLoadingSearch(true)
        creatorService.getCourseByStatusPaginated(pageNumber, 10, courseStatusId)
            .then(
                response => {
                    switch (courseStatusId) {
                        case CourseStatusEnum.Published:
                            setPublishedCoursesListViewPaginatedSearch(response.result)
                            break;
                        case CourseStatusEnum.Draft:
                            setDraftedCoursesListViewPaginatedSearch(response.result)
                            break;
                        case CourseStatusEnum.Archived:
                            setArchivedCoursesListViewPaginatedSearch(response.result)
                            break;
                    }
                    setTimeout(
                        () => {
                            setIsLoadingSearch(false)
                            setIsLoading(false)
                        }, 200)

                }
            ).catch(err => {
                console.log(err)
            })
    }

    const viewCourse = async (courseId: string) => {
        window.location.href = "/course/".concat(courseId).concat("/info/");
    }

    const editCourse = async (courseId: string) => {
        await creatorService.restoreCourse(courseId);
        window.location.href = "/course/".concat(courseId).concat("/edit/");
    }

    const archivedCourse = async (courseId: string) => {
        setIsLoading(true);
        creatorService.archivedCourse(courseId).then(function (response) {
            if (response.success) {                
                pageLoad();
                setActiveTab(CourseStatusEnum.Draft)
                setIsLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const deleteCourse = async (courseId: string) => {
        setIsLoading(true);
        creatorService.deleteCourse(courseId).then(function (response) {
            if (response.success) {
                pageLoad();
                setIsLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const [taskViewPaginatedSearchPending, setTaskViewPaginatedSearchPending] = useState<PaginatedSearchResult<ListTasksViewModel>>(initialState)
    const [taskViewPaginatedSearchCorrected, setTaskViewPaginatedSearchCorrected] = useState<PaginatedSearchResult<ListTasksViewModel>>(initialState)

    function loadTasks() {
        setActivePage(ManageCourseActiveButtonEnum.tasks)
        getTaskList(1, CourseLessonTaskStatusEnum.Pending)
        getTaskList(1, CourseLessonTaskStatusEnum.Corrected)
    }

    function getTaskList(pageNumber: number, taskStatusId: CourseLessonTaskStatusEnum) {
        setIsLoadingSearch(true)
        taskServices.getTasksByStatus({
            pageNumber: pageNumber,
            pageSize: 10,
            orderColumn: "",
            taskStatusId: taskStatusId,
        }).then(response => {
            switch (taskStatusId) {
                case CourseLessonTaskStatusEnum.Pending:
                    setTaskViewPaginatedSearchPending(response.result)
                    break;
                case CourseLessonTaskStatusEnum.Corrected:
                    setTaskViewPaginatedSearchCorrected(response.result)
                    break;
            }

            setTimeout(() => {
                setIsLoadingSearch(false)
                setIsLoading(false)
            }, 200);

        }).catch(err => {
            setIsLoading(false)
        })
    }

    const [showSendCorrectionModal, setShowSendCorrectionModal] = useState(false);
    const [showCorrectionTaskModal, setShowCorrectionTaskModal] = useState(false);
    const [showConfirmExitCorrectionTaskModal, setShowConfirmExitCorrectionTaskModal] = useState(false);

    const handleShowSendCorrection = () => [setShowSendCorrectionModal(true), setShowCorrectionTaskModal(false)]
    const handledCloseSendCorrection = () => setShowSendCorrectionModal(false);

    const handleModalShow = () => [setShowCorrectionTaskModal(true), setShowConfirmExitCorrectionTaskModal(false)];
    const handleModalClose = () => [setShowConfirmExitCorrectionTaskModal(true), setShowCorrectionTaskModal(false)];
    const handleModalCloseAll = () => [setShowConfirmExitCorrectionTaskModal(false), setShowCorrectionTaskModal(false)];

    const [isLoadingDownload, setIsLoadingDownload] = useState(true);
    const [error, setError] = useState(false);

    const [openTaskId, setOpenTaskId] = useState("");

    const [taskDetails, setTaskDetails] = useState<TasksDetailsResultView>({
        id: "",
        reviewed: false,
        apprenticeName: "",
        courseTitle: "",
        task: "",
        deliveredDate: "",
        filePath: "",
        fileName: "",
        grade: 0,
        comments: "",
    })

    const [taskCorrectionFeedback, setTaskCorrectionFeedback] = useState<ITaskCorrectionFeedbackPayload>({
        id: '',
        comment: '',
        grade: 0,
    })

    useEffect(
        () => {
            if (openTaskId !== "") {
                setTaskCorrectionFeedback(
                    {
                        id: openTaskId,
                        comment: '',
                        grade: 0,
                    }
                )
                loadTaskDetails()
            }
        }, [openTaskId]
    )

    function loadTaskDetails() {
        setIsLoading(true);
        taskServices.getById(openTaskId).then(response => {
            setTaskDetails(response.result)
            setIsLoading(false);
        }).catch(err => {
            console.log(err)
            setError(true)
            setIsLoading(false);
        })
    }

    function onChange(ev) {
        const { name, value } = ev.target;

        if (name === "grade") {
            setTaskCorrectionFeedback({ ...taskCorrectionFeedback, [name]: parseInt(value) });
        }
        else {
            setTaskCorrectionFeedback({ ...taskCorrectionFeedback, [name]: value });
        }
    }

    function onSubmit(ev) {
        ev.preventDefault();

        taskServices.correctionTask(taskCorrectionFeedback).then(response => {
            handleShowSendCorrection()
            taskDetails.grade = taskCorrectionFeedback.grade
            taskDetails.comments = taskCorrectionFeedback.comment
            taskDetails.reviewed = true

            callReload(!reload)
            
        }).catch(err => {
            console.log(err)
            setError(true)
        })
    }

    function fileDownloadPath() {
        setIsLoadingDownload(false)
        let fileName = taskDetails.filePath

        window.open(fileName)
        setIsLoadingDownload(true)
    }

    function returnToList() {
        setShowSendCorrectionModal(false)
        loadTasks()
        setOpenTaskId("")
    }

    return (<>
        <HomeHeader
            activeTab={CreatorHeaderActiveTabEnum.manageCourse}
        />
        <ManageCoursePageStyles fluid={true}>
            <Row>
                <ManageCourseSideMenu
                    activeButton={activePage}
                    setActivePage={setActivePage}
                    setIsLoading={setIsLoading}
                />
                {
                    !isLoading && activePage === ManageCourseActiveButtonEnum.publication &&
                    (
                        <CreatorCourseListComponent
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            publishedCoursesListViewPaginatedSearch={publishedCoursesListViewPaginatedSearch}
                            draftedCoursesListViewPaginatedSearch={draftedCoursesListViewPaginatedSearch}
                            archivedCoursesListViewPaginatedSearch={archivedCoursesListViewPaginatedSearch}
                            isLoading={isLoadingSearch}
                            getCourseList={getCourseList}
                            viewCourse={viewCourse}
                            editCourse={editCourse}
                            archivedCourse={archivedCourse}
                            deleteCourse={deleteCourse}
                        />
                    )
                }
                {
                    !isLoading && activePage === ManageCourseActiveButtonEnum.tasks && openTaskId === "" &&
                    (

                        <TaskListComponent
                            setOpenTaskId={setOpenTaskId}
                            taskViewPaginatedSearchPending={taskViewPaginatedSearchPending}
                            taskViewPaginatedSearchCorrected={taskViewPaginatedSearchCorrected}
                            isLoading={isLoadingSearch}
                            getTaskList={getTaskList}
                        />
                    )
                }
                {
                    !isLoading && activePage === ManageCourseActiveButtonEnum.tasks && openTaskId !== "" &&
                    (
                        <TaskDetails
                            openTaskId={openTaskId}
                            taskDetails={taskDetails}
                            showConfirmExitCorrectionTaskModal={showConfirmExitCorrectionTaskModal}
                            showSendCorrectionModal={showSendCorrectionModal}
                            showCorrectionTaskModal={showCorrectionTaskModal}
                            isLoading={isLoading}
                            isLoadingDownload={isLoadingDownload}
                            setOpenTaskId={setOpenTaskId}
                            returnToList={returnToList}
                            fileDownloadPath={fileDownloadPath}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            handleModalShow={handleModalShow}
                            handleShowSendCorrection={handleShowSendCorrection}
                            handledCloseSendCorrection={handledCloseSendCorrection}
                            handleModalClose={handleModalClose}
                            handleModalCloseAll={handleModalCloseAll}
                        />
                    )
                }
                {
                    isLoading &&
                    (
                        <ChangeLessonLoader
                            sideMenuIsOpen={false}
                        />
                    )
                }

            </Row>
        </ManageCoursePageStyles>
    </>)

}

export default ManageCoursePage

const ManageCoursePageStyles = styled(Container)`
    margin-top:8.7vmin;
`

const initialState: PaginatedSearchResult<any> = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalRecords: 1,
    hasPrevious: true,
    hasNext: true,
    items: []
}