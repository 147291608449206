import { useContext } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddImageModalContext } from '../context/AddImageModalContext';

export function IndexScreen() {
    const {
       setNavigationPosition
    } = useContext(AddImageModalContext);
    return <>
        <Row>
            <Col>
                <p>
                    Como você quer enviar sua imagem?
                </p>
            </Col>
        </Row>
        <Row className="py-2">
            <Col>
                <Button className='w-100 add-content px-3' onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.sendFile)}>
                    <Row className="mb-2">
                        <Col md="auto" className="my-auto pr-0">
                            <i className="bi bi-upload" />
                        </Col>
                        <Col className="my-auto">
                            <h3>
                                Enviar arquivo do meu dispositivo
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                São aceitos arquivos nos formatos .jpg, .jpeg e .png, com tamanho de até 100MB.
                            </p>
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
        <Row className="py-2">
            <Col>
                <Button className='w-100 add-content px-3' onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.sendLink)}>
                    <Row className="mb-2">
                        <Col md="auto" className="my-auto pr-0">
                            <i className="bi bi-link-45deg" />
                        </Col>
                        <Col className="my-auto">
                            <h3>
                                Enviar imagem da internet
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Você pode enviar uma imagem a partir do seu link original.
                            </p>
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
    </>;
}
