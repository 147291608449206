import { useContext } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { AssessmentContext } from '../../../../context/apprentice/attend/AssessmentContext';
import { PrimaryGreenButton, PrimaryPurpleButton, TertiaryPurpleButton } from "../../../../components/Buttons/styles";
import { AttendCourseHeaderStyles } from "../../../../components/Headers/styles";
import { AttendQuizFormStyles, QuestionContent, AssessmentFooterStyles } from "./styles";

export const AttendAssessmentForm = () => {

    const {
        moduleTitle, currentQuestion, handleAnswerQuestion, nextQuestion, previousQuestion, finishAssessment, questions, isAnswerMarked, IsQuestionAnswered
    } = useContext(AssessmentContext);



    return (<>
        <AttendCourseHeaderStyles className="holdHeaderQuizz d-block d-md-none">
            <Container>
                <Row>
                    <Col xs={10}>
                        <h1>
                            Pratique o que aprendeu
                        </h1>
                    </Col>
                    <Col xs={1} className="pr-2">
                        <i className="bi bi-x-lg mr-3" />
                    </Col>
                </Row>
            </Container>
        </AttendCourseHeaderStyles>
        <AttendQuizFormStyles fluid={true}>
            {/* Menu mobile */}
            {/* Menu mobile end */}
            <Row className="question-content">
                <Col md={10} className="mx-auto">
                    <Row className="mt-5 mb-4">
                        <Col>
                            <h1>{moduleTitle}</h1>
                        </Col>
                    </Row>
                    <Row className="my-md-4 my-2">
                        <Col>
                            <h1>
                                Pergunta {currentQuestion + 1}
                            </h1>
                        </Col>
                    </Row>
                    <Row className="question-row">
                        <Col>
                            <QuestionContent>
                                {questions[currentQuestion].content}
                            </QuestionContent>
                        </Col>
                    </Row>
                    {
                        questions[currentQuestion].imageUrl !== ''&&
                        questions[currentQuestion].imageUrl !== null&&
                        questions[currentQuestion].imageUrl !== undefined&&
                        <Row className="question-img-row">
                            <Col xs='auto' className="mx-auto">
                                <img src={questions[currentQuestion].imageUrl}/>
                            </Col>
                        </Row>
                    }
                    <Row className="answers-row">
                        <Col md={12}>
                            <ul>
                                {questions[currentQuestion].assessmentAnswers.sort((a, b) => a.order! - b.order!).map(
                                    answer => {
                                        return (
                                            <li className="mb-2">
                                                <Row>
                                                    <Col xs={1} md="auto" className="mb-auto pr-0">
                                                        <input
                                                            type="radio"
                                                            checked={isAnswerMarked(answer.id!)}
                                                            onClick={() => handleAnswerQuestion(questions[currentQuestion].id!, answer.id!)} />
                                                    </Col>
                                                    <Col xs={11} className="my-auto">
                                                        <p>
                                                            {answer.content}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </AttendQuizFormStyles>
        <AssessmentFooterStyles className="d-none d-md-block d-lg-block">
            <Row className="mb-3 col_buttons">
                {currentQuestion > 0
                    ?
                    (
                        <>
                            <Col>
                                <TertiaryPurpleButton className="mt-2 previous-question py-2" onClick={previousQuestion}>
                                    <Row>
                                        <Col md="auto">
                                            <i className="bi bi-arrow-left" />
                                        </Col>
                                        <Col className="my-auto">
                                            <p>Pergunta Anterior</p>
                                        </Col>
                                    </Row>
                                </TertiaryPurpleButton>
                            </Col>
                        </>
                    )
                    :
                    (
                        <></>
                    )}
                <Col>
                    {currentQuestion < questions.length - 1
                        ? (
                            <>
                                <PrimaryPurpleButton className="float-right next-question py-2 px-5"
                                    onClick={nextQuestion}
                                    disabled={!IsQuestionAnswered()}>
                                    <Row>
                                        <Col className="my-auto">
                                            <p>Próxima Pergunta</p>
                                        </Col>
                                        <Col md="auto">
                                            <i className="bi bi-arrow-right" />
                                        </Col>
                                    </Row>
                                </PrimaryPurpleButton>
                            </>
                        )
                        : (
                            <PrimaryGreenButton className="float-right finish-quiz py-2 px-5"
                                onClick={finishAssessment}
                                disabled={!IsQuestionAnswered()}>
                                <Row>
                                    <Col className="my-auto">
                                        <p>Finalizar avaliação</p>
                                    </Col>
                                    <Col md="auto">
                                        <i className="bi bi-check-circle" />
                                    </Col>
                                </Row>
                            </PrimaryGreenButton>
                        )}
                </Col>

            </Row>
        </AssessmentFooterStyles>
        <AssessmentFooterStyles className="d-block d-md-none d-lg-none">
            <Row className="mb-3 col_buttons">
                <Col>
                    {currentQuestion < questions.length - 1
                        ? (
                            <>
                                <PrimaryPurpleButton className="d-block d-md-none d-lg-none next-question"
                                    onClick={nextQuestion}
                                    disabled={!IsQuestionAnswered()}>
                                    Próxima Pergunta
                                    <i className="bi bi-arrow-right" />
                                </PrimaryPurpleButton>
                            </>
                        )
                        : (
                            <PrimaryGreenButton className="finish-quiz"
                                onClick={finishAssessment}
                                disabled={!IsQuestionAnswered()}>
                                Finalizar avaliação
                                <i className="bi bi-check-circle" />
                            </PrimaryGreenButton>
                        )}
                </Col>
                {currentQuestion > 0
                    ?
                    (
                        <>
                            <Col xs={12}>
                                <TertiaryPurpleButton className="mt-2 d-block d-md-none d-lg-none previous-question py-2" onClick={previousQuestion}>
                                    <i className="bi bi-arrow-left" />
                                    Pergunta Anterior
                                </TertiaryPurpleButton>
                            </Col>
                        </>
                    )
                    :
                    (
                        <></>
                    )}

            </Row>
        </AssessmentFooterStyles>
    </>);
};
