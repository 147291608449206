import { useState, useEffect, forwardRef, useRef, useImperativeHandle, useContext } from 'react';
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'
import CourseStepEnum from '../../../../domain/enum/CourseStepEnum';

import { Col, Row } from "react-bootstrap";
import ICreateCourseDependencyPayload from '../../../../domain/payloads/createCourse/ICreateCourseDependencyPayload';
import {
    DepModulesList,
    StepDescription,
    StyledSearchableSelect
}
    from '../../createTrilha/createTrilhaStyles';

import CreatorServices from '../../../../services/CreatorServices'
import ModuleServices from '../../../../services/ModuleServices'

const creatorService = new CreatorServices();
const moduleService = new ModuleServices();

export interface IModule {
    id: string,
    title: string,
    order: number,
    availableDepModules: ISelectDepModules[]
}

interface ISelectDepModules {
    value: any,
    label: string,
    moduleId: string
}

export const CreateCourseDependencyForm = forwardRef((props, ref) => {

    const { 
        course, 
        isEdit, 
        setDataIsLoaded,
        setUpdateDate,
        isChanged,
        setIsChanged, 
        setIsValid,
        setHasDependency,
        setNavigationStep
    } = useContext(CreateCourseContext);

    useImperativeHandle(ref, () => ({
        submit(isBacking:boolean, saveAsDraft: boolean, stepRender?:number) {
            onSubmit(isBacking, saveAsDraft, stepRender);
        }
    }));

    const [isLoading, setIsLoading] = useState(false);
    const [callReload, setReload] = useState(true);

    const [modules, setModules] = useState<IModule[]>([]);
    const [selectedDepModules, setSelectedDepModules] = useState<ISelectDepModules[]>([]); //dependency between modules    

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);  
              
        moduleService.getDependencyModulesByTrilha(course.id).then(function (response) {
            if (response.success) {
                const courseModules = response.result.map(mod => ({
                    id: mod.id,
                    title: mod.title,
                    order: mod.order,
                    availableDepModules: mod.depModules.map(dep => ({
                        value: dep.id,
                        label: dep.title,
                        moduleId: mod.id
                    }))
                }));

                let newVector = modules.concat(courseModules)                
                setModules(newVector);
                LoadDependency(newVector);
                setIsLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
 
        setIsLoading(false);
        setReload(!callReload)
        setIsValid(true);        
    }

    const LoadDependency = (savedModules: any) => {

        let responseModules = course.modules
        let savedDependencies: ISelectDepModules[] = []

        for (let i = 0; i < responseModules.length; i++) {
            let module = responseModules[i];

            if (module.modulesImDependent.length > 0) {
                for (let j = 0; j < module.modulesImDependent.length; j++) {                    
                    let moduleToAddToSelect = savedModules.filter(depModule => {
                        return depModule.id == module.modulesImDependent[j].dependencyModuleId
                    })
                    savedDependencies = savedDependencies.concat({
                        value: moduleToAddToSelect[0].id,
                        label: moduleToAddToSelect[0].title,
                        moduleId: module.id!
                    })
                }
            }
        }

        setSelectedDepModules(savedDependencies)        
    }   
    
    useEffect(() => {
        setHasDependency(CheckIfHasDependency())
    }, [callReload]);

    const CheckIfHasDependency = ():boolean =>{
        if(selectedDepModules.length > 0){
            return true
        }
        return false
    } 

    useEffect(() => {
        setReload(!callReload)
    }, [selectedDepModules]);

    const handleChangeDepModule = (value: any, actionMeta: any) => {
        setIsChanged(true)
        if (actionMeta.action === "select-option") {
            let vector = selectedDepModules;
            vector = vector?.concat(actionMeta.option);
            setSelectedDepModules(vector);
        }
        else if (actionMeta.action === "remove-value") {
            let vector = selectedDepModules;
            vector = vector?.filter((selectedModule) => {
                return (selectedModule.value !== actionMeta.removedValue.value || (selectedModule.value === actionMeta.removedValue.value && selectedModule.moduleId !== actionMeta.removedValue.moduleId))
            });
            setSelectedDepModules(vector);
        } else {
            setSelectedDepModules([]);
        }
    };

    const onSubmit = (isBacking:boolean, saveAsDraft: boolean, stepRender?:number) => {
        
        if(isChanged|| (!isChanged&&course.courseStepId < CourseStepEnum.Dependency))
        {
            setIsLoading(true);

            const payLoadDepModules = selectedDepModules.map(dep => (
                { moduleId: dep.moduleId, dependentModuleId: dep.value }
            ));
            
            const data: ICreateCourseDependencyPayload = {
                courseId: course.id,
                isEditMode: isEdit||course.courseStepId >= CourseStepEnum.Dependency,
                modules: payLoadDepModules
            }
    
            creatorService.createCourseDependency(data).then(function (response) {
                if (response.success) {
                    
                    updateCourseObj(data, response.result)
                    setUpdateDate(response.result.updateDate);
    
                    setIsChanged(false);
                    setIsLoading(false);
                    if(!saveAsDraft)
                    {
                        setNavigationStep(stepRender ? stepRender :isBacking ?  CourseStepEnum.Content : CourseStepEnum.Finalization);
                    }
                }else
                {
                    //aqui a gente captura os erros que vem da api e mostra um toast
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
        else
        {
            setNavigationStep(isBacking ? CourseStepEnum.Content : CourseStepEnum.Finalization);
        }
    }

    const updateCourseObj = (data: any, response: any) => {
        course.courseStepId = response.courseStepId;

        for (let i = 0; i < course.modules.length; i++) {
            course.modules[i].modulesImDependent = []; //clear the dependency
            
            for (let j = 0; j < data.modules.length; j++) {
                if(course.modules[i].id == data.modules[j].moduleId)
                {
                    course.modules[i].modulesImDependent.push({ 
                        moduleId: data.modules[j].moduleId, 
                        dependencyModuleId: data.modules[j].dependentModuleId 
                    });
                }
            }
        }

        setDataIsLoaded(true)
    }

    return (
        <Row>
            <Col md={10} className="mx-auto">
                <Row>
                    <Col>
                        <h1>
                            Dependências (opcional)
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Você pode criar dependências entre os módulos do curso, de forma que sejam consumidos em ordens específicas.
                            Os módulos que tiverem dependências vão ficar bloqueados até que os selecionados sejam acessados.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <Row>
                                <Col className="mx-auto">
                                    <StepDescription>
                                        Você pode criar dependências entre os módulos do curso, de forma que sejam
                                        consumidos em ordens específicas. Os módulos que tiverem dependências vão ficar bloqueados
                                        até que os selecionados sejam acessados.
                                    </StepDescription>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col>
                                    {
                                        <DepModulesList>
                                            {
                                                modules.map((module, index) => {
                                                    return (
                                                        <li className="my-4">
                                                            <Row>
                                                                <Col>
                                                                    <h4>
                                                                        {module.title}
                                                                    </h4>
                                                                    {
                                                                        module.order === 0 &&
                                                                        <p>Essa configuração não se aplica ao primeiro módulo.</p>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {
                                                                        module.order > 0 &&
                                                                        <StyledSearchableSelect
                                                                            classNamePrefix="react-select"
                                                                            name="classesSelect"
                                                                            isMulti
                                                                            isClearable={false}
                                                                            isLoading={isLoading}
                                                                            options={module.availableDepModules}
                                                                            value={selectedDepModules.filter(dep => {
                                                                                return dep.moduleId == module.id
                                                                            })}
                                                                            onChange={handleChangeDepModule}
                                                                            placeholder="Selecione quais módulos devem ser consumidos antes deste"
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </DepModulesList>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

});