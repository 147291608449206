import {useState, useEffect, forwardRef, useImperativeHandle, useContext, useRef, isValidElement, ReactNode } from 'react';
import { Card, Modal } from "react-bootstrap";


import VideoSourceEnum  from "../../../domain/enum/VideoSourceEnum";
import { AddVideoModalStyles } from './AddVideoModalStyles';
import { ConfirmExitAddVideoModal } from './ConfirmExitAddVideoModal';
import { AddVideoModalContext, AddVideoModalContextProvider, AddVideoObject } from './context/AddVideoModalContext';
import { ModalFooter as AddVideoModalFooter } from './footer/AddVideoModalFooter';
import { AddVideoModalBody } from './body/AddVideoModalBody';
import { AddVideoModalTitle } from './header/AddVideoModalTitle';



export const AddVideoModalComponent = (props:AddVideoModalComponentProps)=>{
    return(
        <AddVideoModalContextProvider
            setVideoOnModal={props.setVideoOnModal}
            getVideoVideoFromModal={props.getVideoVideoFromModal}
            reRender={props.reRender}
            onClose={props.onClose}
        >
            <AddVideoModal/>
        </AddVideoModalContextProvider>
    )
}

interface AddVideoModalComponentProps{
    setVideoOnModal: (() => AddVideoObject)
    getVideoVideoFromModal: (videoObject:AddVideoObject) => void
    onClose: () => void
    reRender:()=>void
}

export const AddVideoModal = ()=>{

    const {
        isUploading,
        onClose,
    } = useContext(AddVideoModalContext);

    return(
        <AddVideoModalStyles 
            show={true} 
            onHide={()=>onClose()}
            size="lg"
            >
            {
                !isUploading&&
                (
                    <Modal.Header closeButton className="mt-4 px-4">
                        <AddVideoModalTitle/>
                    </Modal.Header>
                )
            }
                <AddVideoModalBody/>
                <AddVideoModalFooter/>
            <ConfirmExitAddVideoModal/>
        </AddVideoModalStyles>
    )
}


