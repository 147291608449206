import { Col, Row } from "react-bootstrap";
import { PureComponent } from "react";
import styled from 'styled-components';
import CertificateDetailsViewModel from "../../../../domain/models/course/CertificateDetailsViewModel";
import { formatDateFull, formatWorkLoad } from "../../../../util/Formatter";
import BrainzHolderDictionary from '../../../../domain/BrainzHolderDictionary';
import { CertContent, CertMainImage } from "../AttentCoursePageStyles";

export class CertificateContent extends PureComponent<Props> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <CertContent className="mx-auto">
                {
                    this.props.customCertificate!==''?
                    (
                        <CertRowStyles className="w-100" dangerouslySetInnerHTML={{__html:this.props.customCertificate}}/>

                    )
                    :
                    (
                        <CertRowStyles className="w-100">
                            <Col className="col-3 px-0 mx-0">
                                <CertMainImage>
                                    {this.props.isModule ?
                                        (
                                            <object className="left-page-marker" data="/img/attendCourse/certificate/woman_trophy_success_module.svg" type="image/svg+xml"></object>
                                        )
                                        :
                                        (
                                            <object className="left-page-marker" data="/img/attendCourse/certificate/woman_trophy_success_course.svg" type="image/svg+xml"></object>
                                        )}
                                </CertMainImage>
                            </Col>
                            <Col className="col-9 my-auto pl-5">
                                <Row className="mb-5 mt-5 pt-2">
                                    <Col>
                                        <p className="certificamos-que">Certificamos que</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="my-auto">
                                        <h3 className="student-name">{this.props.certificateDetails.apprenticeName}</h3>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="my-auto course-text-info">
                                        {this.props.isModule ?
                                            (
                                                <p>
                                                    concluiu o módulo&nbsp;
                                                    <b>{this.props.certificateDetails.moduleTitle}</b>&nbsp; do curso online {this.props.certificateDetails.courseTitle}, <br/>  
                                                    com carga horária de {formatWorkLoad(this.props.certificateDetails.workLoad)}.
                                                </p>
                                            )
                                            :
                                            (
                                                <p>
                                                    concluiu o curso online&nbsp;
                                                    <b>{this.props.certificateDetails.courseTitle}</b>&nbsp;
                                                    no dia {formatDateFull(this.props.certificateDetails.endDate)}, com carga horária de {formatWorkLoad(this.props.certificateDetails.workLoad)}.
                                                </p>
                                            )}

                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="my-auto institution-name">
                                        <p>{this.props.certificateDetails.institutionName}</p>
                                    </Col>
                                </Row>
                                {this.props.holderId.toUpperCase() === BrainzHolderDictionary.bigbrain &&
                                    (
                                        <Row className="bigbrain">
                                            <Col className="col-4 my-auto">
                                                <img className="signature" src={"/img/attendCourse/certificate/signature_big_brain.svg"} />
                                            </Col>
                                            <Col md={"auto"} className="my-auto mx-auto pb-2">
                                                <img className="my-auto mx-auto logo" src={"/img/attendCourse/certificate/logo_big_brain.svg"} />
                                            </Col>
                                            <Col className="col-4 my-auto pb-3">
                                                <img className="partner" src={"/img/attendCourse/certificate/logo_microsoft_partner.svg"} />
                                            </Col>
                                        </Row>
                                    )}
                                {this.props.holderId.toUpperCase() === BrainzHolderDictionary.reeducation &&
                                    (
                                        <Row className="reedu">
                                            <Col className="my-auto">
                                                <img className="signature" src={"/img/attendCourse/certificate/signature_reedu.svg"} />
                                            </Col>
                                            <Col className="my-auto">
                                                <img className="logo" src={"/img/attendCourse/certificate/logo_reedu.svg"} />
                                            </Col>
                                            <Col className="my-auto">
                                                <img className="partner" src={"/img/attendCourse/certificate/logo_google_partner.svg"} />
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                        </CertRowStyles>
                    )
                }
            </CertContent>);
    }
}

type Props = {
    certificateDetails: CertificateDetailsViewModel;
    holderId: string;
    isModule: boolean;
    customCertificate:string;
};

export const CertRowStyles = styled(Row)`
    place-content: center;
`;
