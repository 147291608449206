import CourseDetailView from '../../../domain/models/course/CourseDetailView'

export const mock:CourseDetailView = {
    id: "",
    title: "",
    description:"",
    presentation: "",
    introductionVideoUrl:null,
    introductionImageUrl:null,
    workLoad: "80:00",
    coverImage: "https://storagebrainzservices.blob.core.windows.net/files-trilhas-dev/e174e160-bf70-48dc-8fdf-1cc1564df9cb.png",
    publishedDate: "21/12/2021",
    enableCertificate: true,    
    responsibleUserCourse: "João da Silva e Silva Silva",
    started:true,
    categories: [
        {
            name:"React"
        },
        {
            name:"TypeScript"
        },
        {
            name:"Axios"
        }
    ],
    subCategories: [
        {
            name:"Microsoft"
        },
        {
            name:"C#"
        }
    ],
    modules:[
        {
            id: "string",
            title: "O que é o HTML",
            order: 0,
            qtyLesson: 3,
            workLoad: "05:00",
            hasCertificate: true,
            moduleContents:[
                {
                    id: "string",
                    title: "Por quê Design Thinking é tão falado?",
                    moduleContentTypeId: 1,
                    order: 0,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "As raízes do Design Thinking",
                    moduleContentTypeId: 1,
                    order: 1,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "Design misturado com negócios?",
                    moduleContentTypeId: 1,
                    order: 3,
                    isRequired: false,
                },                
            ]
        },
        {
            id: "string",
            title: "Que tipos de página da web eu posso criar?",
            order: 1,
            workLoad: "05:00",
            hasCertificate: true,
            qtyLesson: 3,
            moduleContents:[
                {
                    id: "string",
                    title: "Por quê Design Thinking é tão falado?",
                    moduleContentTypeId: 1,
                    order: 0,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "As raízes do Design Thinking",
                    moduleContentTypeId: 3,
                    order: 1,
                    isRequired: true,
                },                
                {
                    id: "string",
                    title: "Design misturado com negócios?",
                    moduleContentTypeId: 2,
                    order: 3,
                    isRequired: true,
                },                
            ]
        },
        {
            id: "string",
            title: "Esboçando o seu código",
            order: 2,
            workLoad: "05:00",
            hasCertificate: true,
            qtyLesson: 1,
            moduleContents:[
                {
                    id: "string",
                    title: "Por quê Design Thinking é tão falado?",
                    moduleContentTypeId: 1,
                    order: 0,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "As raízes do Design Thinking",
                    moduleContentTypeId: 1,
                    order: 1,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "Design misturado com negócios?",
                    moduleContentTypeId: 1,
                    order: 3,
                    isRequired: false,
                },                
            ]
        },
        {
            id: "string",
            title: "Colocando a mão na massa",
            order: 3,
            workLoad: "05:00",
            hasCertificate: true,
            qtyLesson: 2,
            moduleContents:[
                {
                    id: "string",
                    title: "Por quê Design Thinking é tão falado?",
                    moduleContentTypeId: 1,
                    order: 0,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "As raízes do Design Thinking",
                    moduleContentTypeId: 1,
                    order: 1,
                    isRequired: false,
                },                
                {
                    id: "string",
                    title: "Design misturado com negócios?",
                    moduleContentTypeId: 1,
                    order: 3,
                    isRequired: false,
                },                
            ]
        }
    ]

}


