import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { CreateCourseContext } from "./CreateCourseContext";

import CourseStepEnum from "../../../domain/enum/CourseStepEnum";
import LessonContentTypeEnum from "../../../domain/enum/LessonContentTypeEnum";
import ModuleContentTypeEnum from "../../../domain/enum/ModuleContentTypeEnum";

import LessonViewModel from "../../../domain/models/course/module/lesson/LessonViewModel";
import TaskViewModel from "../../../domain/models/course/module/task/TaskViewModel";
import ModuleContentViewModel from "../../../domain/models/course/module/ModuleContentViewModel";
import ModuleViewModel from "../../../domain/models/course/module/ModuleViewModel";
import AssessmentViewModel from "../../../domain/models/course/module/assessment/AssessmentViewModel";

import { AddImageObject } from "../../../components/Modals/AddImageModal/context/AddImageObject";

import { ICreateCourseContentPayload } from "../../../domain/payloads/createCourse/ICreateCourseContentPayload";
import { IOrderningModuleContentPayload } from "../../../domain/payloads/createCourse/IOrderningModuleContentPayload";


import CreatorServices from "../../../services/CreatorServices";
import ModuleService from "../../../services/ModuleServices";

const creatorService = new CreatorServices();
const moduleService = new ModuleService();

interface AddModuleContextData {
    module: ModuleViewModel
    setModule: React.Dispatch<React.SetStateAction<ModuleViewModel>>

    isWorkloadValid: boolean
    setIsWorkloadValid: React.Dispatch<React.SetStateAction<boolean>>

    selectedModuleContentIndex: number
    setSelectedModuleContentIndex: React.Dispatch<React.SetStateAction<number>>

    onSubmit: (isFinishing?: boolean) => void

    isValidModuleContent: boolean
    setIsValidModuleContent: React.Dispatch<React.SetStateAction<boolean>>

    isEditingModule: boolean
    setIsEditingModule: React.Dispatch<React.SetStateAction<boolean>>

    showConfirmDeleteModuleContentModal: boolean
    setShowConfirmDeleteModuleContentModal: React.Dispatch<React.SetStateAction<boolean>>


    showInvalidTextPasteModal: boolean
    setShowInvalidTextPasteModal: React.Dispatch<React.SetStateAction<boolean>>

    getSelectedModuleContent: () => ModuleContentViewModel

    handleWorkloadChange: (e: any) => void
    onHasCertificateChange: (e: any) => void
    handleModuleTitleChange: (e: any) => void
    validateModule: () => boolean
    validateModuleInfo: (newTitle?: string | undefined, newWorkLoad?: string | undefined) => boolean
    validateModuleContent: (moduleContent: ModuleContentViewModel) => boolean

    addNewModuleContent: () => void
    deleteModuleContent: (index: number) => void
    setModuleContentType: (lessonType: ModuleContentTypeEnum) => void
    handleModuleContentTitleChange: (e: any) => void
    handleModuleContentTitleBlur: () => void

    hasAssessment: () => boolean

    handleOnDeleteModuleContent: () => void
    handleCancelDeleteModuleContent: () => void

    changeSelectedModuleContextIndex: (index: number) => void

    openModalOrderContent: boolean
    setOpenModalOrderContent: React.Dispatch<React.SetStateAction<boolean>>
    handleOrderingModuleContent: (direction: string, currentOrder: number) => void
    handleSaveOrderModuleContent: () => void

    deleteLessonContent: (index: number) => void

    handleLessonContentFocusLose: () => void
}

const defaultModule: ModuleViewModel = {
    title: "",
    order: 0,
    workLoad: "",
    hasCertificate: false,
    moduleContents: [],
    moduleDependenciesOnMe: [],
    modulesImDependent: []
}

export const AddModuleContext = createContext({} as AddModuleContextData)

interface AddModuleContextProvider {
    children: ReactNode
}

export const AddModuleContextProvider = (props: AddModuleContextProvider) => {
    const {
        course,
        openModuleIndex,
        setOpenModuleIndex,
        setIsValid,
        setIsLoading,
        setIsChanged,
        submitStepData,
        isEdit,
        setUpdateDate,
        reRender
    } = useContext(CreateCourseContext)

    const [isWorkloadValid, setIsWorkloadValid] = useState(false);

    const [isEditingModule, setIsEditingModule] = useState(false);

    const [module, setModule] = useState<ModuleViewModel>(defaultModule);

    const [isValidModuleContent, setIsValidModuleContent] = useState(true);

    const [showConfirmDeleteModuleContentModal, setShowConfirmDeleteModuleContentModal] = useState(false);

    const [showInvalidTextPasteModal, setShowInvalidTextPasteModal] = useState(false);

    //ModuleContent Index
    const [selectedModuleContentIndex, setSelectedModuleContentIndex] = useState<number>(-1);

    //Modal to ordering contents
    const [openModalOrderContent, setOpenModalOrderContent] = useState<boolean>(false);

    useEffect(
        () => {
            if (openModuleIndex > -1 && course.modules.length > openModuleIndex) {
                setModule(course.modules[openModuleIndex])
                setSelectedModuleContentIndex(course.modules[openModuleIndex].moduleContents.length - 1)
                setIsValid(true)
            } else {
                let newModule: ModuleViewModel = {
                    title: "",
                    order: course.modules.length.valueOf(),
                    workLoad: "",
                    hasCertificate: false,
                    moduleContents: [],
                    moduleDependenciesOnMe: [],
                    modulesImDependent: []
                }
                setOpenModuleIndex(course.modules.length)
                setModule(newModule)
                setIsValid(false)
            }
            setIsLoading(false)
        }, []
    )

    const deleteLessonContent = (index: number) => {
        setIsChanged(true);
        const moduleContent = getSelectedModuleContent()

        let newLessonContentList = moduleContent.lesson!.lessonContents.filter(x => { return x.order !== index; });

        newLessonContentList.forEach(
            (moduleContent, index) => {
                moduleContent.order = index;
            }
        );
        moduleContent.lesson!.lessonContents = newLessonContentList;

        setIsValidModuleContent(validateModuleContent(moduleContent));
        reRender();
    };

    const handleLessonContentFocusLose = () => {
        const moduleContent = getSelectedModuleContent()
        if(moduleContent === undefined){
            return
        }
        
        let lesson = moduleContent.lesson;
        if (lesson !== undefined && lesson !== null) {
            var lessonContents = lesson.lessonContents;

            lessonContents.forEach(lessonContent => {
                if ((lessonContent.content === "" || lessonContent.content === "<p><br></p>" || lessonContent.content === undefined)
                    && lessonContent.lessonContentTypeId == LessonContentTypeEnum.Text) {
                    deleteLessonContent(lessonContent.order);
                }
            });

            validateModule();
        }
    };

    function getSelectedModuleContent(){
        return module.moduleContents[selectedModuleContentIndex]
    }
    const changeSelectedModuleContextIndex = (index: number) => {
        setSelectedModuleContentIndex(index)
    }

    const handleWorkloadChange = (e) => {
        setIsValid(validateModuleInfo(undefined, e.target.value))
        setIsChanged(true)
        reRender()
    }

    const handleModuleTitleChange = (e) => {
        setIsValid(validateModuleInfo(e.target.value))
        setIsChanged(true)
        reRender()
    }

    const onHasCertificateChange = (e) => {
        module.hasCertificate = !module.hasCertificate
        setIsValid(validateModuleInfo())
        setIsChanged(true)
        reRender()
    }



    const onSubmit = (isFinishing: boolean = false) => {
        let courseModules = course.modules.filter(thisModule => (thisModule.order !== module.order))
        course.modules = courseModules.concat(module).sort((a, b) => a.order - b.order);
        submitStepData(isFinishing, true)
    }

    // #region Validation

    const validateModuleInfo = (newTitle: string | undefined = undefined, newWorkLoad: string | undefined = undefined): boolean => {
        let validTitleTest: boolean
        let validWorkloadTest: boolean

        if (newTitle != undefined) {
            module.title = newTitle
            validTitleTest = newTitle.replace(/\s+/g, '') !== ""
        } else {
            validTitleTest = module.title.replace(/\s+/g, '') !== ""
        }

        let isWorkloadValid: boolean

        if (newWorkLoad != undefined) {

            isWorkloadValid = /^([0-9]?[0-9]|2[0-9]):[0-5][0-9]$/.test(newWorkLoad);
            module.workLoad = newWorkLoad

            validWorkloadTest =
                //não se tem certificado e (o conteudo é valido ou o conteudo é vazio) 
                //ou se tem certificado e o conteudo é valido
                (!module.hasCertificate && (isWorkloadValid || module.workLoad === "")
                    || module.hasCertificate && isWorkloadValid)
        }
        else {
            isWorkloadValid = /^([0-9]?[0-9]|2[0-9]):[0-5][0-9]$/.test(module.workLoad);

            validWorkloadTest =
                (!module.hasCertificate && (isWorkloadValid || module.workLoad === "")
                    || module.hasCertificate && isWorkloadValid)
        }

        return (validTitleTest && validWorkloadTest)
    }

    const validateModule = (): boolean => {
        let moduleInfoValidation = validateModuleInfo();
        let moduleContentValidation = validateModuleContents();
        return moduleInfoValidation && moduleContentValidation;
    }

    const validateModuleContents = (): boolean => {
        let valid = true;

        let moduleContents = module.moduleContents
        if (module.moduleContents.length === 0) {
            valid = false
        }

        for (let index = 0; index < moduleContents.length; index++) {
            const moduleContent = moduleContents[index];
            valid = validateModuleContent(moduleContent);
            
            if (!valid) break;            
        }

        return valid;
    }

    const validateModuleContent = (moduleContent: ModuleContentViewModel): boolean => {
        if (moduleContent === undefined) {
            return false
        }

        var valid = true;
        switch (moduleContent.moduleContentTypeId) {
            case ModuleContentTypeEnum.Lesson:
                if (!validateLesson(moduleContent)) {
                    valid = false
                }
                break
            case ModuleContentTypeEnum.Task:
                if (!validateTask(moduleContent)) {
                    valid = false
                }
                break
            case ModuleContentTypeEnum.Assessment:
                if (!validateAssessment(moduleContent)) {
                    valid = false
                }
                break
            default:
                valid = false
        }

        return valid;
    }

    const validateModuleContentTitle = (moduleContent: ModuleContentViewModel): boolean => {
        return moduleContent.title.trim() !== "";
    }

    const hasAssessment = (): boolean => {
        const moduleContents = module.moduleContents

        for (let index = 0; index < moduleContents.length; index++) {
            const moduleContent = moduleContents[index];
            if (moduleContent.moduleContentTypeId === ModuleContentTypeEnum.Assessment) {
                return true
            }
        }
        return false
    }

    const validateLesson = (moduleContent: ModuleContentViewModel): boolean => {
        if (!validateModuleContentTitle(moduleContent)) {            
            return false
        }
        
        const lesson = moduleContent.lesson!

        if (lesson.lessonContents.length < 1) {
            //TODO: Estado do Tip de conteudo
            return false
        }

        let textLessonContents = lesson.lessonContents.filter(lessonContent => { return lessonContent.lessonContentTypeId === LessonContentTypeEnum.Text })

        for (let index = 0; index < textLessonContents.length; index++) {
            const textLessonContent = textLessonContents[index];
            if ((textLessonContent.content.trim() === "")) {
                return false
            }
        }

        return true
    }

    const validateTask = (moduleContent: ModuleContentViewModel): boolean => {
        if (!validateModuleContentTitle(moduleContent)) {
            return false
        }

        const task = moduleContent.moduleTask!
        if (task.orientation.trim() === "") {
            return false
        }

        return true
    }

    const validateAssessment = (moduleContent: ModuleContentViewModel): boolean => {
        if (!validateModuleContentTitle(moduleContent)) {
            return false
        }
        const assessment = moduleContent.assessment!

        if (moduleContent.isRequired && (assessment.minimumGrade === null || assessment.minimumGrade === "")) {
            return false
        }

        if (assessment.assessmentQuestions.length === 0) {
            return false
        }

        for (let index = 0; index < assessment.assessmentQuestions.length; index++) {
            let flag = false
            const question = assessment.assessmentQuestions[index];

            if (question.assessmentAnswers.length < 2) {
                return false
            }

            for (let index = 0; index < question.assessmentAnswers.length; index++) {
                const answer = question.assessmentAnswers[index];
                if (answer.isRightAnswer) {
                    flag = true
                }
            }

            //se não tiver resposta correta marcada
            if (!flag) {
                return false
            }

        }

        return true
    }
    // #endregion Validation

    //#region ModuleContent

    const addNewModuleContent = () => {
        setIsChanged(true);

        //always add a new content at the las position of the array.
        let newModuleContent: ModuleContentViewModel = {
            title: "",
            moduleContentTypeId: ModuleContentTypeEnum.Undefined,
            order: module.moduleContents.length,
            isRequired: false,
        }
        module.moduleContents = module.moduleContents.concat(newModuleContent);

        setSelectedModuleContentIndex(module.moduleContents.length - 1);
        setIsValidModuleContent(false);
        reRender();
    }

    const handleOnDeleteModuleContent = () => {
        setShowConfirmDeleteModuleContentModal(true)
        reRender()
    }

    const handleCancelDeleteModuleContent = () => {
        setShowConfirmDeleteModuleContentModal(false)
        reRender()
    }

    const deleteModuleContent = (index: number) => {
        const moduleContentToDelete = module.moduleContents.filter(x => { return x.order === index })[0]

        setSelectedModuleContentIndex(index - 1)
        setIsLoading(true)

        module.moduleContents = module.moduleContents.filter(x => { return x.order !== index });
        module.moduleContents.forEach((moduleContent, index) => {
            moduleContent.order = index;
        });

        if (moduleContentToDelete.moduleContentTypeId !== ModuleContentTypeEnum.Undefined) {

            let courseModules = course.modules.filter(thisModule => (thisModule.order !== module.order))
            course.modules = courseModules.concat(module).sort((a, b) => a.order - b.order);

            let data: ICreateCourseContentPayload = {
                courseId: course.id,
                isEditMode: isEdit || course.courseStepId >= CourseStepEnum.Content,
                modules: course.modules
            }
            creatorService.createCourseContent(data).then(function (response) {
                if (response.success) {
                    updateCourseObj(data, response.result);
                    setIsValidModuleContent(validateModuleContents())
                    reRender()
                }
            }).catch(err => {
                setIsLoading(false);
            });
        } else {
            setIsValidModuleContent(validateModuleContents())
            setIsChanged(true)
            setIsLoading(false);
            reRender()
        }
        setShowConfirmDeleteModuleContentModal(false)
    }

    const updateCourseObj = (data: any, response: any) => {
        course.courseStepId = response.courseStepId;
        course.modules = response.modules.sort((a, b) => a.order - b.order);

        module.id = course.modules[openModuleIndex].id
        module.courseId = course.modules[openModuleIndex].courseId
        module.moduleContents = course.modules[openModuleIndex].moduleContents

        setUpdateDate(response.updateDate);

        setIsLoading(false)
        reRender()
    }

    const setModuleContentType = (moduleContentType: ModuleContentTypeEnum) => {
        const moduleContent = getSelectedModuleContent();
        
        switch (moduleContentType) {
            case ModuleContentTypeEnum.Lesson:
                let lesson: LessonViewModel = {
                    coverImage: "",
                    lessonContents: []
                }                
                moduleContent.isRequired = true;
                moduleContent.lesson = lesson;
                break;
            case ModuleContentTypeEnum.Task:
                let newTask: TaskViewModel = {
                    orientation: ""
                }                
                moduleContent.moduleTask = newTask;
                break;
            case ModuleContentTypeEnum.Assessment:
                let assessment: AssessmentViewModel = {
                    description: "",
                    minimumGrade: null,
                    assessmentQuestions: [{
                        order: 0,
                        minimized: false,
                        content: '',
                        hint: '', 
                        imageUrl:'',
                        hintCollapsed: true,
                        assessmentAnswers: [
                            {
                                order: 0,
                                content: '',
                                isRightAnswer: true
                            },
                            {
                                order: 1,
                                content: '',
                                isRightAnswer: false
                            }
                        ]
                    }]
                }                
                moduleContent.assessment = assessment;
                break;
            default:
                break;
        }

        moduleContent.title = "";
        moduleContent.moduleContentTypeId = moduleContentType;

        onSubmit();
        reRender();
    }

    const handleModuleContentTitleChange = (e: any) => {
        const moduleContent = module.moduleContents[selectedModuleContentIndex]

        let newModuleContent: ModuleContentViewModel = {
            id: moduleContent.id,
            moduleContentTypeId: moduleContent.moduleContentTypeId,
            title: e.target.value,
            order: moduleContent.order,
            isRequired: moduleContent.isRequired,
            lesson: moduleContent.lesson,
            moduleTask: moduleContent.moduleTask,
            assessment: moduleContent.assessment
        }

        module.moduleContents[selectedModuleContentIndex] = newModuleContent

        setIsValidModuleContent(validateModuleContent(moduleContent))
        setIsChanged(true)
        reRender()

    }

    const handleModuleContentTitleBlur = () => {
        const moduleContent = module.moduleContents[selectedModuleContentIndex]

        //if (moduleContent.title === "") {
            let newModuleContent: ModuleContentViewModel = {
                id: moduleContent.id,
                moduleContentTypeId: moduleContent.moduleContentTypeId,
                title: moduleContent.title,
                order: moduleContent.order,
                isRequired: moduleContent.isRequired,
                lesson: moduleContent.lesson,
                moduleTask: moduleContent.moduleTask,
                assessment: moduleContent.assessment
            }

            module.moduleContents[selectedModuleContentIndex] = newModuleContent;
            setIsChanged(true);
            reRender();
        //}
    } 

    const handleOrderingModuleContent = (direction: string, currentOrder: number) => {
        var modIndex = module.moduleContents.findIndex((obj => obj.order == currentOrder));
        
        switch (direction) {
            case "UP":
                module.moduleContents[modIndex].order = module.moduleContents[modIndex].order - 1; //sobe
                module.moduleContents[modIndex - 1].order = module.moduleContents[modIndex - 1].order+ 1; //desce
                break;
            case "DOWN":
                module.moduleContents[modIndex].order = module.moduleContents[modIndex].order + 1; //desce
                module.moduleContents[modIndex + 1].order = module.moduleContents[modIndex + 1].order - 1; //sobe
                break;
        }
        
        reRender();
    }
    
    const handleSaveOrderModuleContent = () => {
        setIsLoading(true);

        let data: IOrderningModuleContentPayload = {
            moduleId: module.id!,            
            moduleContents: module.moduleContents.map((value) => {
                return  { ModuleContentId: value.id, order: value.order}
            })
        }

        moduleService.orderingModuleContent(data).then(function (response) {
            if (response.success) {
                setIsLoading(false);
                setOpenModalOrderContent(false)
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });

    }

    //#endregion ModuleContent

    return (
        <AddModuleContext.Provider
            value={{
                module,
                setModule,

                isValidModuleContent,
                setIsValidModuleContent,

                isWorkloadValid,
                setIsWorkloadValid,

                selectedModuleContentIndex,
                setSelectedModuleContentIndex,

                isEditingModule,
                setIsEditingModule,

                setShowConfirmDeleteModuleContentModal,
                showConfirmDeleteModuleContentModal,

                showInvalidTextPasteModal, 
                setShowInvalidTextPasteModal,

                hasAssessment,

                onSubmit,

                getSelectedModuleContent,

                handleModuleTitleChange,
                onHasCertificateChange,
                handleWorkloadChange,
                validateModuleInfo,
                validateModuleContent,
                validateModule,

                addNewModuleContent,
                changeSelectedModuleContextIndex,
                deleteModuleContent,
                setModuleContentType,

                handleModuleContentTitleChange,
                handleModuleContentTitleBlur,

                handleCancelDeleteModuleContent,
                handleOnDeleteModuleContent,



                openModalOrderContent,
                setOpenModalOrderContent,
                handleOrderingModuleContent,
                handleSaveOrderModuleContent,
                deleteLessonContent,
                handleLessonContentFocusLose

            }}
        >
            {
                props.children
            }
        </AddModuleContext.Provider>
    )
}

