import pt from "date-fns/locale/pt";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, Form, ListGroup, ListGroupItem, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import styled from 'styled-components';
import { AttendCourseLessonNavBar } from "../../mylearning/styles";
import { PrimaryPurpleButton } from "../../../components/Buttons/styles";
import { formatDateToDDMMYYYY, formatDateToSend } from "../../../util/Formatter";
import Search from "../../../components/Search/Search";
import Datepicker, { registerLocale } from "react-datepicker";
import CampaignServices from "../../../services/CampaignServices";
import ChangeLessonLoader from "../../../components/Loaders/ChangeLessonLoader";
import { StyledPagedSearchButtonList } from "../../../components/PaginatedSearch/styles";
import PaginatedSearchResult from "../../../domain/util/PaginatedSearchResult";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { BsExclamationCircle } from "react-icons/bs";

interface CampaignsComponentProps {
}
const campaignServices = new CampaignServices();

const CampaignsComponent = (props: CampaignsComponentProps) => {

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<boolean>(true);
    const [openOptionDate, setOpenOptionDate] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [listActiveCampaign, setListActiveCampaign] = useState<any>();
    const [listActiveCampaignPaginated, setListActiveCampaignPaginated] = useState<any>();
    const [listInactiveCampaign, setListInactiveCampaign] = useState<any>();
    const [listInactiveCampaignPaginated, setListInactiveCampaignPaginated] = useState<any>();
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [showDesactiveCampaign, setShowDesactiveCampaign] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    registerLocale("pt", pt);


    const handleModalDesactiveCampaignClose = () => setShowDesactiveCampaign(false);
    const handleModalDesactiveCampaignShow = (id: any) => {
        setCampaignId(id);
        setShowDesactiveCampaign(true);
    }

    useEffect(() => {
        setStartDate(undefined);
        setEndDate(undefined)
        setSearchFilter('')
        setOpenOptionDate(false)
        loadCampaigns()
    }, [activeTab])

    function queryFilters() {
        let query = "";
        if (searchFilter !== "") query = query += "&search=" + searchFilter;
        if (startDate !== undefined)
            query = query +=
                "&initialDate=" + formatDateToSend(startDate) + "&";
        if (endDate !== undefined)
            query = query += "finalDate=" + formatDateToSend(endDate) + "&";
        return query;
    }

    useEffect(() => {
        loadCampaignsActive(1)
    }, [searchFilter, startDate, endDate]);

    function loadCampaigns() {
        switch (activeTab) {
            case true:
                loadCampaignsActive(1)
                break;
            case false:
                loadCampaignsDisabled(1)
                break;
            default:
                loadCampaignsActive(1)
                break;
        }
    }
    function loadCampaignsActive(pageNumber: number) {
        setLoading(true)
        campaignServices.GetActiveSalesCampaign(queryFilters(), pageNumber)
            .then(response => {
                setListActiveCampaign(response.result.items)
                setListActiveCampaignPaginated(response.result)
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
    }


    function loadCampaignsDisabled(pageNumber: number) {
        setLoading(true)
        campaignServices.GetInactiveSalesCampaign(queryFilters(), pageNumber)
            .then(response => {
                setListInactiveCampaign(response.result.items)
                setListInactiveCampaignPaginated(response.result)
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })

    }


    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        // Aqui você pode adicionar a lógica para lidar com a opção selecionada
    };
    function redirectToCreate() {
        window.location.href = "/campaign/create/";
    }
    function redirectToDetailsCampaign(id: string) {
        window.location.href = "/campaign/" + id
    }
    function redirectToEditCampaign(id: string) {
        window.location.href = "/campaign/edit/" + id
    }
    function loadPagined(pageNumber: number) {
        if (activeTab) {
            loadCampaignsActive(pageNumber)
        } else {
            loadCampaignsDisabled(pageNumber)
        }
    }

    const RenderPageButtons = (activeSearch: PaginatedSearchResult<any>): JSX.Element => {
        let buttons: JSX.Element[] = [
            <ListGroup.Item>
                <Button className={activeSearch.currentPage === 1 ? "active" : ""} onClick={() => loadPagined(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for (let i = 2; i < activeSearch.totalPages; i++) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto " + (activeSearch.currentPage === i ? "active" : "")} onClick={() => loadPagined(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }
        if (activeSearch.totalPages > 1) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={activeSearch.totalPages === activeSearch.currentPage ? "active" : ""} onClick={() => loadPagined(activeSearch.totalPages)}>
                        <p>{activeSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )

        }


        return (
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasPrevious} onClick={() => loadPagined(activeSearch.currentPage - 1)}>
                        <i className="bi bi-chevron-left" />
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasNext} onClick={() => loadPagined(activeSearch.currentPage + 1)}>
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>)
    }

    function handleOptionDateFilter(filterDate: number) {
        setEndDate(new Date())
        const dataAtual = new Date();
        const XdiasAtras = new Date();
        XdiasAtras.setDate(dataAtual.getDate() - filterDate);
        setStartDate(XdiasAtras)
    }

    const handleDesactivateCampaign = () => {
        setShowDesactiveCampaign(false)
        setLoading(true)
        campaignServices.DesactivateCampaign(campaignId)
            .then(response => {
                loadCampaigns()
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })


    }



    const renderActiveTab = (activeTab: boolean) => {
        switch (activeTab) {
            case true:
                return (
                    <>{!loading ?
                        <>
                            {
                                listActiveCampaign &&
                                <Row className="ml-1 w-100">
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Nome da Campanha
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Curso(s) vinculado(s)
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Desconto
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list" >
                                            Data início
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Data fim
                                        </div>
                                    </Col>
                                    <Col md={1} className="pl-0">
                                        <div className="title-list">
                                            Opções
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {
                                listActiveCampaign ?
                                    <> {
                                        listActiveCampaign.map((campaign) => {
                                            return (

                                                <Row className="w-100">
                                                    <Col>
                                                        <ListGroup className="w-100 px-0">
                                                            <ListGroupItem className="w-100 px-0  py-2">
                                                                <Card>
                                                                    <Card.Body className="py-2">
                                                                        <Row>
                                                                            <Col md={2} className="my-auto">
                                                                                <p>
                                                                                    {campaign.name}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p >
                                                                                    {campaign.qntCourses}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p className="p-percents">
                                                                                    {campaign.discountPercentage}%
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p>
                                                                                    {formatDateToDDMMYYYY(campaign.campaignStartDate)}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p>
                                                                                    {formatDateToDDMMYYYY(campaign.campaignEndDate)}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <Button className="view-icon"
                                                                                    onClick={() => redirectToDetailsCampaign(campaign.id)}
                                                                                    title="Visualizar campanha">
                                                                                    <Row>
                                                                                        <Col className="my-auto pl-1">
                                                                                            <i className="bi bi-eye" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Button>

                                                                                <Button className="edit-icon"
                                                                                    onClick={() => redirectToEditCampaign(campaign.id)}
                                                                                    title="Editar campanha">
                                                                                    <Row>
                                                                                        <Col className="my-auto pl-1">
                                                                                            <i className="bi bi-pencil" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Button>

                                                                                <Button className="archive-icon"
                                                                                    onClick={() => handleModalDesactiveCampaignShow(campaign.id)}
                                                                                    title="Desativar campanha">
                                                                                    <Row>
                                                                                        <Col className="my-auto pl-1">
                                                                                            <i className="bi bi-folder-symlink" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Button>

                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </Col>
                                                </Row>

                                            )
                                        })
                                    }
                                        {listActiveCampaignPaginated.totalPages > 1 &&
                                            <Row className="mx-auto">
                                                <Col md={"auto"} className="mx-auto mt-1">
                                                    {
                                                        RenderPageButtons(listActiveCampaignPaginated)
                                                    }
                                                </Col>
                                            </Row>

                                        }
                                    </>
                                    :
                                    <Row className="">
                                        <Col md="auto" className="ml-auto">
                                            <img className="mr-3" style={{ position: 'static' }} src={"/img/student/home/woman-sleep.svg"} />
                                        </Col>
                                        <Col md="auto" className="my-auto mr-auto">
                                            <Row>
                                                <Col>
                                                    <h1>
                                                        Nenhuma campanha encontrada
                                                    </h1>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h3 style={{ textAlign: 'center' }}>
                                                        Nenhuma campanha foi criada ainda. Crie uma<br />
                                                        nova campanha no botão acima.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            }
                        </> :
                        <Row className="my-auto">
                            <Col >
                                <ChangeLessonLoader
                                    sideMenuIsOpen={false}
                                />
                            </Col>
                        </Row>

                    }
                    </>

                );
            case false:
                return (
                    <>{!loading ?
                        <>
                            {
                                listInactiveCampaign &&
                                <Row className="ml-1 w-100">
                                    <Col md={4} className="pl-0">
                                        <div className="title-list">
                                            Nome da Campanha
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Desconto
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list" >
                                            Data início
                                        </div>
                                    </Col>
                                    <Col md={2} className="pl-0">
                                        <div className="title-list">
                                            Data fim
                                        </div>
                                    </Col>
                                    <Col md={1} className="pl-0">
                                        <div className="title-list">
                                            Opções
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {
                                listInactiveCampaign ?
                                    <> {
                                        listInactiveCampaign.map((campaign) => {
                                            return (

                                                <Row className="w-100">
                                                    <Col>
                                                        <ListGroup className="w-100 px-0">
                                                            <ListGroupItem className="w-100 px-0  py-2">
                                                                <Card>
                                                                    <Card.Body className="py-2">
                                                                        <Row>
                                                                            <Col md={4} className="my-auto">
                                                                                <p>
                                                                                    {campaign.name}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p className="p-percents">
                                                                                    {campaign.discountPercentage}%
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p>
                                                                                    {formatDateToDDMMYYYY(campaign.campaignStartDate)}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <p>
                                                                                    {formatDateToDDMMYYYY(campaign.campaignEndDate)}
                                                                                </p>
                                                                            </Col>
                                                                            <Col md={2} className="my-auto">
                                                                                <Button className="view-icon"
                                                                                    onClick={() => redirectToDetailsCampaign(campaign.id)}
                                                                                    title="Visualizar campanha">
                                                                                    <Row>
                                                                                        <Col className="my-auto pl-1">
                                                                                            <i className="bi bi-eye" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Button>

                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                    </Col>
                                                </Row>

                                            )
                                        })
                                    }
                                        {listInactiveCampaignPaginated.totalPages > 1 &&
                                            <Row className="mx-auto">
                                                <Col md={"auto"} className="mx-auto mt-1">
                                                    {
                                                        RenderPageButtons(listInactiveCampaignPaginated)
                                                    }
                                                </Col>
                                            </Row>
                                        }

                                    </>
                                    :
                                    <Row className="">
                                        <Col md="auto" className="ml-auto">
                                            <img className="mr-3" style={{ position: 'static' }} src={"/img/student/home/woman-sleep.svg"} />
                                        </Col>
                                        <Col md="auto" className="my-auto mr-auto">
                                            <Row>
                                                <Col>
                                                    <h1>
                                                        Nenhuma campanha encontrada
                                                    </h1>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h3 style={{ textAlign: 'center' }}>
                                                        Nenhuma campanha foi criada ainda. Crie uma <br />
                                                        nova campanha no botão acima.
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </Col >
                                    </Row >
                            }
                        </> :
                        <Row className="my-auto">
                            <Col className="my-auto" >
                                <ChangeLessonLoader
                                    sideMenuIsOpen={false}
                                />
                            </Col>
                        </Row>

                    }
                    </>

                );
            default:
                return (
                    <></>
                );
        }
    }

    return (
        <CampaignsListStyles className="container-fluid">

            <Row className="container-fluid d-flex">
                <Col>
                    <Row className="row pl-3 ml-0">
                        <Col className="col-md-12 pl-0 ml-0">
                            <h2>Campanhas</h2>
                        </Col>
                    </Row>
                    <Row className="row pl-3 pt-2">
                        <Col className="col-md-12 ">
                            <h3>Visualize e edite as campanhas ativas e defina porcentagens de desconto.</h3>
                        </Col>
                    </Row>
                </Col>
                <Col >
                    <div style={{ float: 'right' }}>
                        <PrimaryPurpleButton className="d-none d-md-block pl-2" onClick={redirectToCreate} >
                            <Row>
                                <Col md="auto" className="my-auto">
                                    <i className="bi bi-plus-circle pl-2"></i>
                                </Col>
                                <Col md="auto" className="my-auto pl-0">
                                    <p>Nova Campanha</p>
                                </Col>
                            </Row>
                        </PrimaryPurpleButton>
                    </div>
                </Col>
            </Row>
            <Row className="ml-auto pl-0 ml-0 nav-item align-items-center">
                <AttendCourseLessonNavBar className="pl-0 ml-0">
                    <Nav variant="pills" defaultActiveKey="/home" className="px-3 mt-2 mb-4 pl-0 ml-0">
                        <Nav.Item className="mx-1">
                            <Nav.Link
                                active={activeTab === true}
                                onClick={() => setActiveTab(true)}
                                className="pt-3 pb-2"
                            >Campanhas ativas</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mx-1">
                            <Nav.Link
                                active={activeTab === false}
                                onClick={() => setActiveTab(false)}
                                className="pt-3 pb-2"
                            >Campanhas desativadas</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </AttendCourseLessonNavBar>
                <Col md={3}>
                    <Search
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        placeholder="Nome da campanha"
                    />
                    <i className="bi bi-search"></i>
                </Col>
                <Col md={3}>
                    <Dropdown className="w-100">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="custom-dropdown w-100">
                            {selectedOption ? selectedOption : 'Intervalo de datas'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-menu w-100">
                            <Dropdown.Item className="custom-dropdown-item" onClick={() => handleOptionDateFilter(30)}>
                                <i className="bi bi-calendar-range mr-2" /> Último mês
                            </Dropdown.Item>
                            <Dropdown.Item className="custom-dropdown-item" onClick={() => handleOptionDateFilter(90)}>
                                <i className="bi bi-calendar3 mr-2" /> Últimos 3 meses
                            </Dropdown.Item>
                            <Dropdown.Item className="custom-dropdown-item" onClick={() => setOpenOptionDate(true)}>
                                <i className="bi bi-calendar-plus mr-2" /> Personalizado
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                {openOptionDate &&
                    <Row className="ml-auto mb-3">
                        <Col sm={6} className="px-0">
                            <Col sm={12}>
                                <Datepicker
                                    className="input-date w-100"
                                    placeholderText={
                                        "Data início"
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange={false}
                                    minDate={new Date()}
                                    locale="pt"
                                    selected={startDate}
                                    onChange={(date) => date && setStartDate(date as Date)}
                                    todayButton="Hoje"
                                />
                                <i className="bi bi-calendar3 icon-calendar1"></i>
                            </Col>
                        </Col>
                        <Col sm={6}>
                            <Col sm={12}>
                                <Datepicker
                                    className="input-date w-100"
                                    placeholderText={
                                        "Data fim"
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange={false}
                                    minDate={startDate}
                                    locale="pt"
                                    selected={endDate}
                                    onChange={(date) => date && setEndDate(date as Date)}
                                    todayButton="Hoje"
                                />
                                <i className="bi bi-calendar3 icon-calendar2"></i>
                            </Col>
                        </Col>
                    </Row>
                }
            </Row>
            <Row className="ml-3">
                {
                    renderActiveTab(activeTab)
                }
            </Row>
            <ConfirmModal
                show={showDesactiveCampaign}
                onHide={handleModalDesactiveCampaignClose}
                title="Deseja desativar essa campanha?"
                subTitle=""
                confirmAction={handleDesactivateCampaign}
                refuseAction={handleModalDesactiveCampaignClose}
                confirmText="Desativar campanha"
                refuseText="Não"
                principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
            />

        </CampaignsListStyles >


    );
}

export default CampaignsComponent;

const CampaignsListStyles = styled(Col)`
    margin-top: 3.88vmin;
    .input-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 8px;
        width: 100%;
        height: 48px;
        background: #f5f5f5;
        border: 1px solid #e4e3e3;
        box-sizing: border-box;
        border-radius: 8px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;
        &:focus {
            box - shadow: none !important;
        }
    }
    .icon-calendar1{
        position: absolute;
        left: 181px;
        top: 8px;
        font-size: 21px;
    }
                        .icon-calendar2{
                            position: absolute;
                        left: 162px;
                        top: 8px;
                        font-size: 21px;
    }
                        .bi-search{
                            position: absolute;
                        top: 5px;
                        right: 30px;
                        font-size: 24px;
    
       }
                        .form-control {
                            background: #fff;

                        padding: 12px 8px !important;
                        width: 100% !important;
                        height: 50px !important;

                        border: 1px solid #a6a6a6;
                        border-radius: 8px;
      }
                        Col{
                            text - align: left;
    }

                        .card1{
                            font - family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 20px;
                        color: #514F4E;
                        align-items: center;
    }

                        .subTitle{
                            margin: 0;
                        padding: 0;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 20px;
                        color: #514F4E;
                        text-align: left;
    }

                        .valueCard{
                            margin: 0;
                        margin-left: auto;
                        padding: 0;
                        text-align: left;
    }

                        h2{
                            text - align: left;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: #514F4E;
    }

                        h3{
                            text - align: left;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #514F4E;
    }

                        h4{
                            text - align: left;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: #514F4E;
    }
                        .title-list{
                            text - align: left;
                        height: 24px;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #636060;
                        margin:0;
    }

                        p{
                            text - align: left;
                        height: 24px;
                        left: 612px;
                        top: 293px;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #514F4E;
                        margin:0;
    }
                        .p-percents{
                            color: #218749
    }

                        img {
                            position: absolute;
                        left: 25.94%;
                        right: 58.75%;
                        top: 23.47%;
                        bottom: 43.09%;
                        left: 259px;
                        top: 92px;
    }
                        .custom-dropdown{
                            height: 48px;
                        gap: 12px;
                        border-radius: 8px;
                        border: 1px solid #E8E8E8;
                        opacity: 0px;
                        background-color: white !important;
                        color: #6c757d !important; /* Cor do texto em cinza claro */
}
                        .custom-dropdown-item{
                            font - family: Lato;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #777575;
                        i {
                            font - size: 24px;
    }
}

                        .list-group-item{
                            border:none;
                        .card{
                            heigth: 56px;
                            font-size: 1.8vmin;
                        background: #FFFFFF;
                        border: 1px solid #E8E8E8;
                        box-sizing: border-box;
                        box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
                        border-radius: 8px;
                        button{
                            text-align:left;
                        background-color:#fff!important;
                        border:none;
                        box-shadow:none;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #514F4E;
                        :active{
                            background-color:#fff!important;
                        border:none!important;
                        box-shadow:none!important;
                }
                        :focus{
                            background-color:#fff!important;
                        border:none!important;
                        box-shadow:none!important;
                }
            }

                        .edit-icon
                        {
                            color:#0C8292;
                        i{
                            font-size:4.5vmin;
                }
            }

                        .view-icon
                        {
                            color:#0C8292;
                        i{
                            font-size:4vmin;
                }
            }

                        .edit-icon {
                            color:#0C8292;
                        i{
                            font-size:4vmin;
                }
            }

                        .archive-icon {
                            color:#C57700;
                        i{
                            font-size:4vmin;
                }
            }

                        .delete-icon {
                            color:#D30808;
                        i{
                            font-size:4vmin;
                }
            }

                        .restore-edit {
                            color:#0C8292;
                        i{
                            font-size:3vmin;
                } 
            }

                        .active-course{
                            font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: #218749;
                        i{
                            font-size:20px;
                }    
            }
                        .draft-course{
                            font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: #6468C8;
                        i{
                            font-size:20px;
                }    
            }
                        .archive-course{
                            font-family: Lato;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        color: #C57700;
                        i{
                            font-size:20px;
                }    
            }
        }
    }

                        `