import ApiInterface from "../interface/ApiInterface"
import { IFeedbackAssessmentPayload, IResponseAssessmentPayload } from "../domain/payloads/quiz/QuizResponsePayloads";

export default class AssessmentService {

    public async getByCoseId(courseId): Promise<any> {
        return await ApiInterface.get("/Assessment/" + courseId + "/GetByCourse").then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }
        
    public async Response(data: IResponseAssessmentPayload): Promise<any> {
        return await ApiInterface.post('/Assessment/Response/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async Feedback(data: IFeedbackAssessmentPayload): Promise<any> {
        return await ApiInterface.post('/Assessment/Feedback/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}