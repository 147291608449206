import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';

export const AttendTextLessonComponent = (props: AttendTextLessonComponent) => {

    return (
        <AttendTextLessonComponentStyles>
            <Col md={11} className="mx-md-auto">
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </Col>
        </AttendTextLessonComponentStyles>
    );
};

export default AttendTextLessonComponent;

const AttendTextLessonComponentStyles = styled(Row)`
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
    }
    blockquote{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 1.95vmin;
            line-height: 2.78vmin;
            padding: 0 0.7vmin 0 2.8vmin;
            color:#999;
        }

        border: solid #b1b1b1;
        border-width: 0 0 0 0.7vmin;
    }
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.88vmin;
        line-height: 4.44vmin;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.33vmin;
        line-height: 3.88vmin;
    }
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
    }
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 2.5vmin;
    }
    h6{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 1.95vmin;
        line-height: 2.5vmin;
    }
    li{
        color:#514F4E;
        font-size:2.22vmin!important;
        line-height:3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    ul{
        color:#514F4E;
        font-size:2.22vmin!important;
        line-height:3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`;

interface AttendTextLessonComponent {
    content: string;
}
