import history from '../../../../History';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { BreadCrumb } from '../../../../components/BreadCrumb/Index';
import { TransparentOkButton } from '../../../../components/oldLayouts/SharedComponentStyles'
import CourseDetailView from '../../../../domain/models/course/CourseDetailView';
import ModuleDetailView from '../../../../domain/models/course/module/ModuleDetailView'
import {
    formatWorkLoad,
    formatDateToDDMMYYYY
} from '../../../../util/Formatter'
import {
    contentTypeIcon,
    contentTypeDescription
} from '../../../../util/FormatterLessonType'
import {
      CourseDescription
    , CourseIntroductionHeader
    , CourseIntroductionDescription
    , CourseContentHeader
    , CourseContentDescription
    , CourseImage
    , StartCourseButton
    , ModuleCardContainer
    , ModuleCardItem
    , ModuleCardItemTitle
    , ModuleLessonTitle
    , ModuleLessonIcon
    , ChevronButton
    , CourseResponsibleHeader
    , CourseResponsibleContent
    , CourseWorkLoadHeader
    , CourseWorkLoadContent
    , CoursePublisedDateHeader
    , CoursePublisedDateContent
    ,CourseInfoComponent
    ,MenuBottom
    ,HoldCourseInfo
    ,Intro
    ,IntroModules
    ,IntroCertificate,
    CourseIntroductionImage
} from './styles'

import ApprenticeServices from '../../../../services/ApprenticeServices';
import { CollapsableList } from '../../../../domain/util/Collapsable';
import { GetYoutubeId } from '../../../../util/YoutubeUtils';
import { GetVimeoId } from '../../../../util/VimeoUtils';
import { ModuleCertificateAwardTag } from './ModuleCertificateAwardTag';
import { CourseCertificateAwardTag } from './CourseCertificateAwardTag';
import styled from 'styled-components'
const apprenticeService = new ApprenticeServices();

interface CourseInfoProps {
    hasBreadCrumb: boolean;
    renderSideOptions: boolean;
    isMyFavouriteCourse: boolean;
    apprenticeCourseDetail: CourseDetailView;
}


export const CourseInfo = (props: CourseInfoProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [reload, callReload] = useState(false);
    const [collapsableListOfModules, setCollapsableList] = useState<CollapsableList<ModuleDetailView>>(new CollapsableList<ModuleDetailView>(props.apprenticeCourseDetail.modules))

    function handleCollapseMenuItemChange(index: number) {
        let newModules = collapsableListOfModules

        newModules.handleCollapseMenuItemAnyCanBeOpen(index)

        setCollapsableList(newModules)
        callReload(!reload)
    }

    function startCourse() {
        setIsLoading(true);
        apprenticeService.startCourse({ courseId: props.apprenticeCourseDetail.id }).then(function (responseStep) {
            if (responseStep.success) {
                history.push("attend/");
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }
    
    const returnVideoUrlSRC = () => {
        let videoIntroductionUrl = "";
        let url = props.apprenticeCourseDetail.introductionVideoUrl;

        if(url !== null) {
            if(url.includes("storage")) {
                videoIntroductionUrl =  url;
            }else
            if(GetYoutubeId(url)!== ""){
                videoIntroductionUrl = "https://www.youtube.com/embed/".concat(GetYoutubeId(url))
            }
            else
                videoIntroductionUrl = "https://player.vimeo.com/video/".concat(GetVimeoId(url))
        }        
        return videoIntroductionUrl;
    }

    const onSetColorMenu = (target) => {        
            if(target.id === 'menu-intro'){
                (document.getElementById('menu-intro') as HTMLFormElement).style.color = "#6468C8";
                (document.getElementById('menu-modules') as HTMLFormElement).style.color = "black";
                (document.getElementById('menu-certificate') as HTMLFormElement).style.color = "black";
            }else if (target.id === 'menu-modules'){
                (document.getElementById('menu-intro') as HTMLFormElement).style.color = "black";
                (document.getElementById('menu-modules') as HTMLFormElement).style.color = "#6468C8";
                (document.getElementById('menu-certificate') as HTMLFormElement).style.color = "black";
            }else if(target.id === 'menu-certificate'){
                (document.getElementById('menu-certificate') as HTMLFormElement).style.color = "#6468C8";
                (document.getElementById('menu-intro') as HTMLFormElement).style.color = "black";
                (document.getElementById('menu-modules') as HTMLFormElement).style.color = "black";
            }         
       }

    return (
        <>
            <HoldCourseInfo>
            <CourseInfoComponent className="mx-auto pr-md-5 pr-lg-5"> 
                <MenuBottom className="d-block d-md-none d-lg-none">
                    <ul>
                        <li><a onClick={({target}) => onSetColorMenu(target)} id='menu-intro' href="#intro">Introdução</a> </li>
                        <li><a onClick={({target}) => onSetColorMenu(target)} id='menu-modules' href="#modules">Módulos</a> </li>
                        {props.apprenticeCourseDetail.enableCertificate === true && (
                        <li>
                            <a 
                                onClick={({target}) => onSetColorMenu(target)} 
                                id='menu-certificate' 
                                href="#certificate">Certificado
                            </a> 
                        </li>   
                        )}
                    </ul>
                </MenuBottom>             
               
                {
                    props.hasBreadCrumb ? (
                        <Row className="mt-4">
                            <BreadCrumb hrefIn={'teste'} />
                        </Row>
                    ) : (<></>)
                }
                <Row className='mt-2 pt-1'>
                    <Col>
                        <h1>{props.apprenticeCourseDetail.title}</h1>
                    </Col>
                </Row>                
                {
                        props.apprenticeCourseDetail.enableCertificate ? (
                        <Row className='mt-4 d-block d-md-none'>
                             <Col className="mt-3 mb-3">
                                <CourseCertificateAwardTag />
                            </Col>
                        </Row>
                        ) : (<></>)
                        }                                          

                <Row className='mt-2'>
                    <Col>
                        <CourseDescription>{props.apprenticeCourseDetail.description}</CourseDescription>
                    </Col>
                </Row>
                <Intro id="intro" /> 
                <Row className='mt-4 pt-2 mb-3'>
                    <Col>
                        <CourseIntroductionHeader>Introdução</CourseIntroductionHeader>
                    </Col>
                </Row>
                {
                   props.apprenticeCourseDetail.introductionImageUrl!==null &&
                   props.apprenticeCourseDetail.introductionImageUrl!==""   &&
                   props.apprenticeCourseDetail.introductionImageUrl!==undefined?
                   (
                       <Row className='mb-5'>
                           <Col md="auto" className='mx-auto'>
                                <CourseIntroductionImage src={props.apprenticeCourseDetail.introductionImageUrl}/>
                           </Col>
                       </Row>
                    )
                    :
                    (
                        <></>
                    )
                }
                {
                   props.apprenticeCourseDetail.introductionVideoUrl!==null&&props.apprenticeCourseDetail.introductionVideoUrl!==""&&props.apprenticeCourseDetail.introductionVideoUrl!==undefined?
                   (
                       <Row className='mb-5'>
                           <Col>
                                <iframe src={returnVideoUrlSRC()}/>
                           </Col>
                       </Row>
                    )
                    :
                    (
                        <></>
                    )
                }
                {
                    props.apprenticeCourseDetail.presentation!==""&&props.apprenticeCourseDetail.presentation!== null&&props.apprenticeCourseDetail.presentation!== undefined?
                    (
                        <Row className='mt-2'>
                            <Col>                                             
                                <CourseIntroductionDescription dangerouslySetInnerHTML={{ __html: props.apprenticeCourseDetail.presentation }} />
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <></>
                    )
                }
                <IntroModules id="modules" /> 
                <Row className='mt-4'>
                    <Col>
                        <CourseContentHeader>Módulos</CourseContentHeader>
                    </Col>
                </Row>
                <Row className='mt-1'>
                    <Col>
                        <CourseContentDescription>
                            O conteúdo do curso é dividido em módulos. Veja o que você vai aprender em cada um deles:
                        </CourseContentDescription>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        {
                            collapsableListOfModules.list.map((module, index) => {
                                return (
                                    <ModuleCardContainer key={"module-" + index} className="my-2 pb-3">
                                        <ModuleCardItem className="w-100 py-1">
                                            <TransparentOkButton className={'btn bg-transparent text-left'} onClick={() => handleCollapseMenuItemChange(index)}>

                                                <Card.Header className={"py-2"}>
                                                    <Row>
                                                        <Col xs={8} md={10} className="my-auto">
                                                            <ModuleCardItemTitle className="d-none d-md-block">                                                                
                                                                {module.item.order + 1}. {module.item.title}
                                                            </ModuleCardItemTitle>
                                                            <ModuleCardItemTitle className="d-block d-md-none">                                                                
                                                                {module.item.order + 1}. 
                                                                {
                                                                module.item.title.length > 30 ? 
                                                                    module.item.title.substring(0,30)+ "..."
                                                                    :
                                                                    module.item.title
                                                                }
                                                            </ModuleCardItemTitle>
                                                        </Col>                                                       
                                                        {
                                                            module.item.hasCertificate ? (
                                                                <Col xs={1} md={1} className="my-auto">
                                                                    <ModuleCardItemTitle>
                                                                        <i className="bi bi-award"></i>
                                                                    </ModuleCardItemTitle>
                                                                </Col>
                                                            ) 
                                                            : (
                                                            
                                                                <Col xs={1} md={1} className="d-xs-none d-sm-none d-md-block">
                                                                </Col>
                                                                )
                                                        }
                                                      
                                                        <Col xs={2} md={1} className="my-auto">
                                                                <ChevronButton
                                                                $rotate={(module.collapsed === true ? "0deg" : "180deg")}
                                                                src={`/img/createTrilha/chevron.svg`} alt=""
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                            </TransparentOkButton>                                            

                                            {
                                                module.collapsed ? (<>
                                                    <Card.Body className={"pt-0 pb-1 px-4"}>
                                                        <ul className="pl-4">
                                                            
                                                            { module.item.hasCertificate ? (
                                                                <li className="ml-3 mxText">
                                                                    <ModuleCertificateAwardTag/>
                                                                </li>
                                                            ) 
                                                            : (<></>)
                                                            }                                                            
                                                        
                                                            {
                                                                module.item.moduleContents.map((content, contentIndex) => {
                                                                    return (
                                                                        <li key={"lesson-" + contentIndex} className="my-1">                                                                            
                                                                            <Card>
                                                                                <Card.Body className="py-1">                                                                                    
                                                                                    <Row>
                                                                                        <Col xs={7} md={8}>
                                                                                            <ModuleLessonTitle className="mt-1">
                                                                                                {content.title}
                                                                                            </ModuleLessonTitle>
                                                                                        </Col>
                                                                                        <Col xs={4} className="my-auto ml-auto text-left" md={"auto"}>
                                                                                            <ModuleLessonTitle className="mt-1">
                                                                                                {contentTypeDescription(content.moduleContentTypeId, content.isRequired)}                                                                                                
                                                                                            </ModuleLessonTitle>
                                                                                        </Col>
                                                                                        <Col xs={1} className="my-auto px-1" md={"auto"}>
                                                                                            <ModuleLessonIcon className="mt-1">
                                                                                                {contentTypeIcon(content.moduleContentTypeId)}
                                                                                            </ModuleLessonIcon>
                                                                                        </Col>

                                                                                    </Row>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Card.Body>
                                                </>)
                                                    : (<></>)
                                            }
                                        </ModuleCardItem>
                                    </ModuleCardContainer>
                                )


                            })
                        }
                    </Col>
                </Row> 
                <IntroCertificate id="certificate" /> 
                {props.apprenticeCourseDetail.enableCertificate === true ? (
                    <>
                        <Row className='mt-md-5'>
                            <Col>
                                <CourseContentHeader>Certificados</CourseContentHeader>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <CourseContentDescription>
                                    Alguns módulos do curso podem oferecer certificados. Ao completar todos os módulos, 
                                    você também receberá um certificado referente ao curso inteiro.
                                </CourseContentDescription>
                            </Col>
                        </Row></>
                ) :
                    (
                        <></>
                    )} 

                <Row className='mt-5'>
                    {props.apprenticeCourseDetail.categories.length > 0 ?
                        (<Col md={6}>
                            <CourseContentHeader>Categorias</CourseContentHeader> 
                                <ul>
                                {
                                    props.apprenticeCourseDetail.categories.map((cat, index) => {
                                        return (
                                            <CourseContentDescription>
                                                <li>{cat.name}</li>
                                            </CourseContentDescription>
                                        )
                                    })
                                }
                                </ul>
                        </Col>)
                        :
                        (<Col md={6}>
                            <CourseContentHeader></CourseContentHeader>                            
                        </Col>)
                    }
                    
                        {props.apprenticeCourseDetail.subCategories.length > 0 ?
                            (<Col md={6}>
                                <CourseContentHeader>Sub Categorias</CourseContentHeader>
                                <ul>
                                    {
                                        props.apprenticeCourseDetail.subCategories.map((cat, index) => {
                                            return (
                                                <CourseContentDescription>
                                                    <li>{cat.name}</li>
                                                </CourseContentDescription>
                                            )
                                        })
                                    }
                                </ul>
                            </Col>)
                            :
                            (<Col md={6}><CourseContentHeader></CourseContentHeader></Col>)
                        }
                </Row>

                {/* <Row className='mt-2 d-none d-md-block d-lg-block'>
                    <Col md={6}>
                        Subcat
                        <ul>
                            {
                                props.apprenticeCourseDetail.categories.map((cat, index) => {
                                    return (
                                        <CourseContentDescription>
                                            <li>{cat.name}</li>
                                        </CourseContentDescription>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                    <Col md={6} className="d-none d-md-block d-lg-block">
                        Categorias<ul>
                            {
                                props.apprenticeCourseDetail.subCategories.map((cat, index) => {
                                    return (
                                        <CourseContentDescription>
                                            <li>{cat.name}</li>
                                        </CourseContentDescription>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row> */}

            </CourseInfoComponent>

            {
                props.renderSideOptions ? (
                    <section className="position-relative overflow-hidden">
                    <Col md={"auto"} className='ml-auto'>
                        <Row className="d-none d-md-block d-lg-bock">
                            <Col className="px-0">
                                <CourseImage src={props.apprenticeCourseDetail.coverImage} />
                            </Col>
                        </Row>

                        <Row className="d-none d-md-block d-lg-block">
                            <Col className='mx'>
                                <StartCourseButton className="w-100"
                                    onClick={() => startCourse()}>
                                    <Row>
                                        <Col md={"auto"} className="px-1 ml-auto">
                                            <i className="bi bi-play"></i>
                                        </Col>
                                        <Col md={"auto"} className="px-1 my-auto mr-auto">
                                            {
                                                props.apprenticeCourseDetail.started?("Continuar Curso"):("Iniciar Curso")
                                            }
                                        </Col>
                                    </Row>
                                </StartCourseButton>
                            </Col>
                        </Row>                       

                        {
                        props.apprenticeCourseDetail.enableCertificate ? (
                        <Row className='mt-4 d-none d-md-block d-lg-block'>
                             <Col>
                                <CourseCertificateAwardTag/>
                            </Col>
                        </Row>
                        ) : (<></>)
                        }                        

                        <Row className='mt-4 hold-responsible'>
                            <Col>
                                <CourseResponsibleHeader>Responsável pelo Curso</CourseResponsibleHeader>
                                <div className="d-block d-md-none d-lg-none">
                                    <CourseResponsibleContent>
                                        {props.apprenticeCourseDetail.responsibleUserCourse}
                                    </CourseResponsibleContent>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-2 d-none d-md-block d-lg-block'>
                            <Col>                                
                                <CourseResponsibleContent>
                                    {props.apprenticeCourseDetail.responsibleUserCourse}
                                </CourseResponsibleContent>
                            </Col>
                        </Row>
                        <Row className='mt-5 hold-workload'>
                            <Col md={4} >
                                
                                <div className="d-none d-md-block d-lg-block">
                                    <CourseWorkLoadHeader>Carga Horária</CourseWorkLoadHeader>
                                    <CourseWorkLoadContent>
                                        <i className="bi bi-clock"></i>
                                    </CourseWorkLoadContent>
                                    <CourseWorkLoadContent className="ml-2">
                                        {formatWorkLoad(props.apprenticeCourseDetail.workLoad)}
                                    </CourseWorkLoadContent>
                                </div>
                                <div className="d-block d-md-none d-lg-none">
                                    <CourseWorkLoadHeader>Carga Horária</CourseWorkLoadHeader>
                                    <CourseWorkLoadContent className="pl-1">
                                        <i className="bi bi-clock"></i>
                                    </CourseWorkLoadContent>
                                    <CourseWorkLoadContent className="ml-2">
                                        {formatWorkLoad(props.apprenticeCourseDetail.workLoad)}
                                    </CourseWorkLoadContent>
                                </div>
                            </Col>
                            <Col>
                                
                                <div className="d-none d-md-block d-lg-block">
                                    <CoursePublisedDateHeader>Publicado em</CoursePublisedDateHeader>
                                    <CoursePublisedDateContent>
                                        <i className="bi bi-calendar3"></i>
                                    </CoursePublisedDateContent>
                                    <CoursePublisedDateContent className="ml-2">
                                        {formatDateToDDMMYYYY(props.apprenticeCourseDetail.publishedDate)}
                                    </CoursePublisedDateContent>
                                </div>
                                <div className="d-block d-md-none d-lg-none">
                                    <CoursePublisedDateHeader>Publicado em</CoursePublisedDateHeader>
                                    <CoursePublisedDateContent className="pl-1">
                                        <i className="bi bi-calendar3"></i>
                                    </CoursePublisedDateContent>
                                    <CoursePublisedDateContent>
                                       {formatDateToDDMMYYYY(props.apprenticeCourseDetail.publishedDate)}
                                    </CoursePublisedDateContent>
                                </div>
                            </Col>
                        </Row>                                                                       
                    </Col>                    
                    <section className="courseInitial d-block d-md-none d-lg-none">
                            <Row>
                                <Col className='mx-4'>
                                    <StartCourseButton className="w-100"
                                        onClick={() => startCourse()}>
                                        <section className="hold-text-main">
                                            <i className="bi bi-play"></i>
                                            <span>
                                                {
                                                    props.apprenticeCourseDetail.started?("Continuar Curso"):("Iniciar Curso")
                                                }
                                            </span>
                                        </section>
                                    </StartCourseButton>
                                </Col>
                            </Row>
                        </section>                        
                    </section>
                )
                    : (<></>)
            } 
            </HoldCourseInfo>           
        </>
    );
}

export default CourseInfo;




