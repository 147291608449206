import { useContext } from 'react';
import { CreateCourseContext } from '../../context/creator/createCourse/CreateCourseContext';
import { Row, Col, Modal } from 'react-bootstrap';
import { PrimaryPurpleButton, SecondaryYellowButton } from '../Buttons/styles';
import { ConfirmActionModalStyles } from './styles';

export function ConfirmDeleteModuleModal() {
    const { 
            openModuleIndex, 
            deleteModule, 
            handleOnCancelDeleteModule, 
            showConfirmDeleteModuleModal
    } = useContext(CreateCourseContext);

    return (
        <ConfirmActionModalStyles show={showConfirmDeleteModuleModal}>
            <Modal.Body>
                <Row className="my-3" style={{ placeContent: 'center' }}>
                    <i className="bi bi-exclamation-circle" style={{ fontSize: 80, color: '#C57700' }}></i>
                </Row>
                <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                    <Col>
                        <h1>
                            Deseja excluir esse módulo?
                        </h1>
                    </Col>
                </Row>
                <Row className="my-2" style={{ textAlign: 'center' }}>
                    <Col>
                        Você vai perder todo o conteúdo inserido nele.
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer as={Row}>
                <Col>
                    <SecondaryYellowButton onClick={()=>deleteModule(openModuleIndex)}>
                        <Row>
                            <Col md="auto" className="ml-auto pr-0 my-auto">
                                <i className='bi bi-trash'/>
                            </Col>
                            <Col md="auto" className="ml-0 mr-auto my-auto">
                                <p>
                                    Excluir Módulo
                                </p>
                            </Col>
                        </Row>
                    </SecondaryYellowButton>
                </Col>
                <Col>
                    <PrimaryPurpleButton onClick={()=>handleOnCancelDeleteModule()}>
                        <Row>
                            <Col md="auto" className="mx-auto">
                                <p>
                                    Manter módulo
                                </p>
                            </Col>
                        </Row>
                    </PrimaryPurpleButton>
                </Col>
            </Modal.Footer>
        </ConfirmActionModalStyles>
    );
}



