import { Button, Col, Modal } from "react-bootstrap";
import { PrimaryPurpleButton, SecondaryRedButton } from "../../../components/Buttons/styles";

import styled from "styled-components";
export const ModalErrorCreateCampaign = ({ show, handleCloseErrorModal }) => {

    return (
        <ModalErrorStyle show={show} onHide={handleCloseErrorModal} centered>
            <Modal.Body>
                <img className="img-fluid mb-3" style={{ position: 'static' }} src={"/img/campaign/womam-finance.svg"} />
                <h2>Ops...</h2>
                <p>Algo deu errado na criação da campanha, por favor tente novamente!</p>
                <Modal.Footer>
                    <SecondaryRedButton
                         className="w-100" onClick={handleCloseErrorModal}>
                        Tentar novamente
                    </SecondaryRedButton>
                </Modal.Footer>
            </Modal.Body>
        </ModalErrorStyle>
    );
}

const ModalErrorStyle = styled(Modal)`
.modal-content{
    flex-direction: row;
}
.modal-body{
    width: 473px;
     height: 440px;
     text-align: center;
     padding: 30px 12px;
     h2{
        font-family: Lato;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: red
     }
     p{
        
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;

        color: #636060
     }
}
    .img-fluid {
        //width: 100%;
    }
    .modal-footer{
        padding: 30px 0px;
        border-top: 0px
    }
`;