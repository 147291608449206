import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

interface SearchProps {
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  searchFilter: string;
  placeholder?: string;
  isDisabled?: boolean;
}
export default function Search(props: SearchProps) {
  const [searchFilterTimed, setSearchFilterTimed] = useState<string>("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setSearchFilter(searchFilterTimed);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchFilterTimed]);

  useEffect(() => {
    setSearchFilterTimed(props.searchFilter);
  }, [props.searchFilter]);

  return (
    <SearchStyles.Control
      className={`filter-name ${props.isDisabled ? "search-disabled" : ""}`}
      value={searchFilterTimed}
      onChange={(e) => {
        setSearchFilterTimed(e.target.value);
      }}
      type="text"
      placeholder={
        props.placeholder !== ""
          ? props.placeholder
          : "Buscar por Tipo, Identificador, Nome ou E-mail"
      }
      size="sm"
      onKeyDown={(event: any) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      }}
      disabled={props.isDisabled}
    />
  );
}

const SearchStyles = styled(Form)`
  .filter-name {
    width: 295px;
    height: 48px;
    top: 196px;
    left: 743px;
    padding: 12px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    justify: space-between;
    opacity: 0px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
  }
`;

