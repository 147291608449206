import { Modal } from "react-bootstrap";
import styled from 'styled-components';

export const ConfirmActionModalStyles = styled(Modal)`
    .modal-content{
        border-radius:1.11vmin;
    }
    .modal-header{
        border:none;
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 3vmin;
            line-height: 28px;
            color: #514F4E;
        }
        button{
            padding:1vmin 2vmin 2vmin;2vmin;
        }
        span{
            font-size:5vmin;
        }
    }
    .modal-body{
        padding-top:0;
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 3vmin;
            line-height: 24px;
            color: #514F4E;
            margin-top:0.5vmin;

            margin-bottom:0;  
        }
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.5vmin;
            line-height: 24px;
            color: #636060;
            margin-top:0.5vmin;

            margin-bottom:0;

        }
        h3{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.5vmin;
            line-height: 2.77vmin;
            color: #636060;
            margin:0;
        }



    }
    .modal-footer{
        border:none;
        button{
            min-height:6.6vmin;
            width: 100%;
            p{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 2vmin;
                line-height: 2.5vmin;
                padding:0;
            }
        }
        .bi-trash{
            font-size:2.5vmin;
        }
        .bi-arrow-repeat{
            font-size:2.5vmin;
        }
    }
`;

export const ConfirmExitAddContentModalStyles = styled(Modal)`
    .modal-content{
        text-align: center;
        background: #FFFFFF;
        mix-blend-mode: normal;
        border: 0.28vmin solid #C57700;
        box-shadow: 0px 1.11vmin 1.11vmin  rgba(0, 0, 0, 0.15);
        border-radius: 1.11vmin;
        h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        }
        .bi-exclamation-circle{
            font-size:10vmin;
            color:#C57700;
        }
        button{
            .bi-x{
                font-size: 3vmin;
            }
            p{
                font-size: 2.22vmin;

            }
        }
    }

`

export const CourseCertificationModalStyles = styled(Modal)`
  
    .modal-content {
        border-radius:1.11vmin;
    }

    .modal-header {
        border:none;

        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 3.33vmin;
            line-height: 28px;
            color: #514F4E;
        }

        button {
            padding:1vmin 2vmin 2vmin;
        }

        span {
            font-size:5vmin;
        }
    }

    .modal-body {
        padding-top:0;
        padding-bottom:0;
        
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 3vmin;
            line-height: 24px;
            color: #514F4E;
            margin-top:0.5vmin;

            margin-bottom:0;  
        }

        h2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 24px;
            color: #636060;
            margin-top:0.5vmin;
            margin-bottom:0;
        }

        h3 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 1.97vmin;
            line-height: 2.77vmin;
            color: #636060;
            margin:0;
        }
    }

    @media screen and (max-width: 990px){       
        padding-right: 15px !important;

        .modal-header-mobile {
            padding: 12px 15px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            box-shadow: 0px 0.50vmin 0.1vmin rgba(75, 75, 75, 0.15);
        }

        h3 {
            font-size: 4.54vmin;
            line-height: 6vmin;
            margin-bottom: 15px;

            i {
                font-size: 4.2vmin;
            }
        }

        h2 {
            font-size: 6vmin;
            margin-top: 0vmin;
            line-height: 7vmin;
            margin-bottom: 0;
        }

        img {
            max-width: 100%;
            margin-bottom: 35px;
        }

        button {
            position: relative;
            z-index:2;
            top: 6px;
            right: 3px;

            span {
                font-size: 8vmin !important;
            }
        }
    }    
`;