import { Button, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const CreatorsHeaderStylesMobile = styled.header`  
       
    @media screen and (max-width:992px){
        top: 0;
        width: 100%;
        position: fixed;       
        z-index: 10;
        background: #FFF;

        ul {
            padding: 12px 20px 0px 20px;
            list-style-type: none;
            display: flex;
            justify-content: space-between; 
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
            margin-bottom:0px;

            li {
                display: inline-flex;

                svg {
                    margin: auto;
                }
            }
            
        }
    }      
`
