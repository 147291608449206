import { Button, Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { removeToken } from '../../router/TokenUtils';

interface ProfileMenuProps{
    showProfileMenu:boolean
}

 const ProfileMenu = (props:ProfileMenuProps)=>{
    return(
        <ProfileMenuStyles className={props.showProfileMenu?(""):"d-none"}>
            <Card.Body className="mx-3 mb-1">
                <Row>
                    <Col>
                        <h1>
                            Olá!
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>
                            O que deseja fazer?
                        </h5>
                    </Col> 
                </Row>
                <Row className="mt-1">
                    <Button className="py-3" onClick={removeToken}>
                        <i className="bi bi-box-arrow-left mr-3 pt-2"/>Sair do Cursos Livres
                    </Button>  
                </Row>
            </Card.Body>
        </ProfileMenuStyles>
    )
}

const ProfileMenuStyles = styled(Card)`
    position:fixed;
    top:8.2vmin;
    right:0px;
    z-index:1000;
    background: #FFFFFF;
    box-shadow: 0px 0.28vmin 0.56vmin rgba(0, 0, 0, 0.25);
    border-radius: 1.12vmin 0px 1.12vmin 1.12vmin;
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #514F4E;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #514F4E;
    }
    button{
        background-color:#fff;
        border:none!important;
        box-shadow:none!important;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        color: #6468C8!important;
        :hover{
            background-color:#E0E1F4;
        }
        :active{
            background-color:#E0E1F4!important;
        }
        :focus{
            background-color:#fff!important;
            border: 1px solid #0C8292!important;
            box-sizing: border-box;
        }
        i{
            font-size:2.5vmin
        }

    }
`

export default ProfileMenu;
