import { Redirect } from "react-router";
import { Button } from "@fluentui/react-northstar";
import React from 'react';
import styled from 'styled-components'

const UserDefault = () => {
    var [redirect, setRedirect] = React.useState<JSX.Element>();

    return (
        <UserDefaultStyles>
            {redirect}
            <section
                className="userDefault"
            >
                <div>
                    <img src="/img/img-user-default.png" />
                    <h1>Parece que você não tem permissao para acessar o Cursos Livres</h1>
                    <p className="pt-10">Era para você estar com acesso? Tente recarregar o aplicativo.</p>
                    <section className="pt-20">
                        <Button
                            content={"Recarregar Aplicativo"}
                            iconPosition="before"
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 11.6236 19.4723 11.2538 19.4188 10.8923C19.3515 10.4382 19.6839 10 20.1429 10C20.5138 10 20.839 10.2562 20.8953 10.6228C20.9642 11.0718 21 11.5317 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C14.3051 3 16.4077 3.86656 18 5.29168V4.25C18 3.83579 18.3358 3.5 18.75 3.5C19.1642 3.5 19.5 3.83579 19.5 4.25V7.25C19.5 7.66421 19.1642 8 18.75 8H15.75C15.3358 8 15 7.66421 15 7.25C15 6.83579 15.3358 6.5 15.75 6.5H17.0991C15.7609 5.25883 13.9691 4.5 12 4.5Z" fill="white" />
                            </svg>}
                            primary
                            onClick={() => setRedirect(<Redirect to="/connHandTeams"></Redirect>)} />
                    </section>
                    <p className="pt-60">Caso isto não resolva, pedimos que entre em contato com a instituição para solicitar acesso ao aplicativo.</p>
                </div>
            </section>
        </UserDefaultStyles>
    );
};

const UserDefaultStyles = styled.div`

    .userDefault {
        text-align: center;
        position: relative;
        padding-top: 20vh;
        max-width: 600px;
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;


        h1 {
        font-size: 2.22vmin;
        }

        img {
        width: 40%;
        }
    }
`

export default UserDefault

