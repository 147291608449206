export function GetYoutubeId(link: string): string {
    let id = "";
    let regex = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim

    let linkGroups = regex.exec(link)

    if (linkGroups !== null) {
        id = linkGroups[3]
    }

    return id;
}