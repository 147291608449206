import Moment from 'react-moment';
import moment from "moment";
import 'moment-timezone';
import 'moment/locale/pt-br';

export const formatWorkLoad = (unformatedWorkLoad: string) => {
    //output: 01h05min
    //output: 00:45min

    let formatedString = "0h00";
    if (unformatedWorkLoad !== "" || unformatedWorkLoad !== undefined) {
        let arrayString = unformatedWorkLoad.split(":");
        formatedString = arrayString[0] + "h" + arrayString[1] + "min"
    }

    return formatedString;
}

export const formatDateToDDMMYYYY = (unformatedDate: any) => {

    return (
        <Moment locale="pt-BR" format="DD/MM/YYYY">
            {unformatedDate}
        </Moment>
    )
}

export const formatDateFull = (unformatedDate: any) => {
    /*
        example: 
        25 de janeiro de 2021
        12 de agosto de 2021
        16 de agosto de 2021
        11 de novembro de 2021
    */
    return (
        <Moment locale="pt-BR" format="LL">
            {unformatedDate}
        </Moment>
    )
}


export const formatTime = (unformatedDate: string) => {
    //input:  2022-01-18T10:54:12.7554968+00:00
    //output: 10:54

    return (
        <Moment locale="pt-BR" format="LT">
            {unformatedDate}
        </Moment>
    )
}

export const getCurrentTime = () => {
    //OUTPUT: 9:30
    //OUTPUT: 15:25
    //OUTPUT: 18:45
    return (
        <Moment locale="pt-BR" format="LT">
            {moment()}
        </Moment>
    )
}

export function formatDateToSend(value: any) {
    if (value) {
        moment.locale('pt-BR');
        const x = moment(value, 'DD-MM-YYYY')
        const result = x.format("YYYY-MM-DD")
        return result
    } else return ""
}

export function formatarValor(numero) {
    if (numero == null || numero == undefined) {
        return "0,00"
    } else {
        // Primeiro, substituímos "." por ","
        let numeroFormatado = numero.toString().replace(".", ",");

        // Verificamos se há apenas um dígito após a vírgula
        if (numeroFormatado.includes(",")) {
            const partes = numeroFormatado.split(",");
            if (partes[1].length === 1) {
                // Adicionamos um zero após a vírgula
                numeroFormatado = partes[0] + "," + partes[1] + "0";
            } else if (partes[1].length > 2) {
                // Se houver mais de duas casas decimais, truncamos para apenas duas
                numeroFormatado = partes[0] + "," + partes[1].slice(0, 2);
            }
        }

        return numeroFormatado;
    }
}