import {useContext} from 'react';
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { AddEmbedModalStyles } from './AddEmbedModalStyles';
import { AddEmbedModalContext, AddEmbedModalContextProvider } from './context/AddEmbedModalContext';
import { PrimaryGreenButton } from '../../Buttons/styles';



export const AddEmbedModalComponent = (props:AddEmbedModalComponentProps)=>{
    return(
        <AddEmbedModalContextProvider
            getEmbedFromModal={props.getEmbedFromModal}
            reRender={props.reRender}
            onClose={props.onClose}
        >
            <AddEmbedModal/>
        </AddEmbedModalContextProvider>
    )
}

interface AddEmbedModalComponentProps{
    getEmbedFromModal: (embed:string) => void
    onClose: () => void
    reRender:()=>void
}

export const AddEmbedModal = ()=>{

    const {
        onSubmit,
        isEmbedValid,
        onClose,
        handleEmbedStringChange,
        embedString
    } = useContext(AddEmbedModalContext);

    return(
        <AddEmbedModalStyles 
            show={true} 
            onHide={()=>onClose()}
            size="lg"
            >
            <Modal.Header closeButton className="mt-4 px-4">
                <Modal.Title>
                    Escolher conteúdo para incorporar
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="px-4">
                    <Row className='mb-2'>
                        <Col>
                            <p>
                                Conteúdo
                            </p>
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col>
                            <Form.Control
                                className='w-100'
                                placeholder='Insira o código do conteúdo'
                                onChange={(e) => handleEmbedStringChange(e)} />
                        </Col>
                    </Row>
                    {
                        embedString!==""&&(
                        <Row>
                            <Col>
                            <div dangerouslySetInnerHTML={{ __html: embedString }} />
                            </Col>
                        </Row>
                        )
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row className="mb-2">
                        <Col md="auto" className="ml-auto">
                            {/*disabled se NÃO for valido*/}
                            <PrimaryGreenButton className="py-2 px-3" disabled={!isEmbedValid()} onClick={() => onSubmit()}>
                                <Row>
                                    <Col className="my-auto">
                                        <p>
                                            Enviar
                                        </p>
                                    </Col>
                                    <Col md="auto" className="my-auto pl-0">
                                        <i className="bi bi-check" />
                                    </Col>
                                </Row>
                            </PrimaryGreenButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </AddEmbedModalStyles>
    )
}


