import { Col, Row } from "react-bootstrap";
import { TaskDeliveryDate } from "./TaskDeliveryDate";

const TaskSentComponent = () => {
    return (
        <>
            <Row className="task-sent">
                <Col xs={1} md="auto" className="pr-0">
                    <i className="bi bi-check-circle" />
                </Col>
                <Col xs={11} md="auto">
                    <p>
                        Você enviou a tarefa!
                    </p>
                </Col>
            </Row>
            <TaskDeliveryDate/>
        </>
    );
};

export default TaskSentComponent


