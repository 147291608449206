import CourseListComponent from './CourseListComponent';
import HomeHeader from '../../components/Headers/HomeHeader';
import CreatorHeaderActiveTabEnum from '../../domain/enum/CreatorHeaderActiveTabEnum';
import { isAdmin, isAdminGlobal, isTeacher } from '../../util/UserUtil';
import StudentHeader from '../../components/Headers/StudentHeader';

export default function MyLearning() {

    return (<>
        {
            (isTeacher() || isAdmin() || isAdminGlobal()) ?
            (
                <HomeHeader
                    activeTab={CreatorHeaderActiveTabEnum.myKnowledge}
                />
            )
            :
            (
                <StudentHeader
                    activeTab={CreatorHeaderActiveTabEnum.myKnowledge}
                />
            )
        }
        <CourseListComponent />
    </>)
}
