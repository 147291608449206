import { useContext } from 'react';
import { formatWorkLoad } from "../../../../util/Formatter";
import {
    Row,
    Col,
    Card,
    ListGroup,
    ListGroupItem
} from 'react-bootstrap';
import { ModuleListComponentStyles, EditButton, DeleteButton } from './CreateCourseContentFormStyles';
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext';
import { CourseViewModel } from '../../../../domain/models/course/CourseViewModel';

export const CourseModulesList = (course: CourseViewModel) => {

    const { 
        editModule, 
        deleteModule, 
        handleOnDeleteModule
    } = useContext(CreateCourseContext);

    return (
        <ModuleListComponentStyles>
            <Row>
                <Col md={10} className="mx-auto">
                    <Row>
                        <Col>
                            <Row>
                                <p>{course.modules.length} módulo(s)</p>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={10} className="pl-0 mx-auto">
                    <Row className="mt-4">
                        <Col md={6}>
                            <p>Título</p>
                        </Col>
                        <Col md={2} className="pl-0 mx-auto">
                            <p>Carga horária</p>
                        </Col>
                        <Col md={2} className="pl-0 mx-auto">
                            <p>Emite certificado?</p>
                        </Col>
                        <Col md={2} className="pl-0 mx-auto">
                            <p>Opções</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={10} className="pl-0 mx-auto">
                    <Row>
                        <Col>
                            <ListGroup className="w-100 px-0">
                                {course.modules.sort((a, b) => a.order - b.order).map((item, modIndex) => {
                                    return (
                                        <ListGroupItem className="px-0 py-2" key={modIndex}>
                                            <Card>
                                                <Card.Body className="py-2">
                                                    <Row>
                                                        <Col md={6} className="mt-1 my-auto">
                                                            <p>{item.title}</p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            {
                                                                item.workLoad?
                                                                (
                                                                <Row className="active-course">
                                                                    <Col className="my-auto pl-0">
                                                                        <p>{formatWorkLoad(item.workLoad!)}</p>
                                                                    </Col>
                                                                </Row>
                                                                )
                                                                :
                                                                (
                                                                    <></>
                                                                )
                                                            }

                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <p>{item.hasCertificate ? "Sim" : "Não"}</p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <EditButton onClick={() => editModule(item.order)}>
                                                                <Row>
                                                                    <Col className="my-auto">
                                                                        <i className="bi bi-pencil" />
                                                                    </Col>
                                                                </Row>
                                                            </EditButton>
                                                            <DeleteButton onClick={() => handleOnDeleteModule(item.order)}>
                                                                <Row>
                                                                    <Col className="my-auto pt-1">
                                                                        <i className="bi bi-trash" />
                                                                    </Col>
                                                                </Row>
                                                            </DeleteButton>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModuleListComponentStyles>
    );
};
