import ApiInterface from "../interface/ApiInterface"

export class IntegrationServices {

    public async getInstitutionGroups() {
        return await ApiInterface.get('/Integration/GetInstitutionGroups/', {}).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async GetInstitutionGroupsByName(name: string) {
        return await ApiInterface.get(`/Integration/GetInstitutionGroupsByName?name=${name}`, {}).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async getUserGroups() {
        return await ApiInterface.get('/Integration/GetUserGroups/', {}).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

}