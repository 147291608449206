import styled from 'styled-components';
import { Button, Col, Container, ListGroup, ProgressBar, Row } from "react-bootstrap"




export const ManageCourseSideMenuStyles = styled(Col)`
    width:19vw;
    min-height:91.1vh;
    background-color:rgba(185, 184, 184, 0.116);
    .row.fixed-bottom{
        margin:0px;
        max-width:20%;
    }
    .row.nav-buttons{
        position:fixed;
        width:19vw;

        .list-group-item{
            background-color:transparent;
            border:none;
        }
        button{
            background-color:transparent;
            width:100%;
            border:none;
            box-shadow:none!important;

            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 1.94vmin;
            line-height: 2.78vmin;
            color: #636060;

            :active{
                background-color:transparent!important;
                color: #636060!important;
                border:none;
                box-shadow:none!important;
            }
            :focus{
                border:none!important;
                box-shadow:none!important;
            }
            i{
                font-size:2.78vmin;
            }
        }
        button.active{
            background: #FFFFFF;
            border: 0.14vmin solid #E8E8E8!important;
            box-sizing: border-box;
            border-radius: 1.11vmin;
            font-weight: 600;
            color: #0C8292;
        }
    }
    img{
        height:21.11vmin;
    }
`