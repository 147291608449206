import { useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import TasksDetailsResultView from '../../../../domain/models/task/TasksDetailsResultView';
import { formatDateToDDMMYYYY } from '../../../../util/Formatter';
import ChangeLessonLoader from '../../../../components/Loaders/ChangeLessonLoader';
import Loader from 'react-loader-spinner';

import {
    PrimaryPurpleButton,
    SecondaryGreenButton,
    SecondaryYellowButton
} from '../../../../components/Buttons/styles'

interface TaskDetailsProps {
    openTaskId: string
    taskDetails: TasksDetailsResultView
    showConfirmExitCorrectionTaskModal: boolean
    showSendCorrectionModal: boolean
    showCorrectionTaskModal: boolean
    isLoading: boolean
    isLoadingDownload: boolean
    setOpenTaskId: (taskId: string) => void
    returnToList: () => void
    fileDownloadPath: () => void
    onChange: (ev: any) => void
    onSubmit: (ev: any) => void
    handleModalShow: () => void
    handleShowSendCorrection: () => void
    handledCloseSendCorrection: () => void
    handleModalClose: () => void
    handleModalCloseAll: () => void
}

export default function TaskDetails(props: TaskDetailsProps) {

    return (<>
        {props.isLoading === false ? (
            <BodyTasks>
                <Row>
                    <SecondaryGreenButton onClick={props.returnToList}>
                        <Row>
                            <Col md="auto" className="pr-0">
                                <i className="bi bi-arrow-left" />
                            </Col>
                            <Col className="my-auto">
                                <h3>Ver todas as tarefas</h3>
                            </Col>
                        </Row>
                    </SecondaryGreenButton>
                </Row>

                <Row className="mt-4 mb-3">
                    <h2>Detalhes da Tarefas</h2>
                </Row>
                <Row className="details pt-3">
                    <Col md={4} className="pl-0">
                        Status
                    </Col>
                    <Col md={3} className="pl-0">
                        Enviado por
                    </Col>
                    <Col md={5} className="pl-0">
                        Curso
                    </Col>
                </Row>

                <Row className="details pt-3">
                    <Col md={4} className="pl-0">
                        {props.taskDetails.reviewed ?
                            (<Row className="statusAdjusted">
                                <Col md="auto" className="pr-1 pl-0">
                                    <i className="bi bi-check2 mr-3"></i>
                                </Col>
                                <Col className="my-auto pl-1">
                                    Corrigida
                                </Col>
                            </Row>)
                            :
                            (<Row className="statusAwaint">
                                <Col md="auto" className="pr-1 pl-0">
                                    <i className="bi bi-clock  mr-3"></i>
                                </Col>
                                <Col className="my-auto pl-1">
                                    Pendente
                                </Col>
                            </Row>)
                        }
                    </Col>
                    <Col md={3} className="pl-0">
                        {props.taskDetails.apprenticeName}
                    </Col>
                    <Col md={5} className="pl-0">
                        {props.taskDetails.courseTitle}
                    </Col>
                </Row>
                <Row className="details pt-4">
                    <Col className="col-4 pl-0">
                        Tarefa
                    </Col>
                    <Col className="col-4 pl-0">
                        Enviado em
                    </Col>
                </Row>
                <Row className="details pt-3">
                    <Col className="col-4 pl-0">
                        {props.taskDetails.task}
                    </Col>
                    <Col className="col-4 pl-0">
                        {formatDateToDDMMYYYY(props.taskDetails.deliveredDate)}
                    </Col>
                </Row>
                <Row className="row mt-5 mb-2">
                    <h2>Arquivo enviado</h2>
                </Row>

                <Row className="row mb-4">
                    <Col className="col-2 pl-0">
                        <SecondaryGreenButton onClick={props.fileDownloadPath}>
                            <Row>
                                <Col md="auto" className="my-auto pr-0 ml-auto">
                                    {props.isLoadingDownload === true ?
                                        (
                                            <i className="bi bi-download"></i>
                                        ) : (
                                            <PreparingLoader className="mr-3" type="TailSpin" color="#EA694C" />
                                        )
                                    }
                                </Col>
                                <Col md="auto" className="my-auto mr-auto">
                                    <p>Baixar Arquivo</p>
                                </Col>
                            </Row>
                        </SecondaryGreenButton>
                    </Col>
                    <Col className="col-5 filename mt-3 pl-0">
                        {props.taskDetails.fileName}
                    </Col>
                </Row>

                <Row className="row mt-5 mb-2">
                    <h2>Correção</h2>
                </Row>

                <Row className="row mb-2">
                    {props.taskDetails.reviewed ?
                        (
                            <Col className="col-12 mt-2">
                                <Row><Col className="details pl-0">Nota</Col></Row>
                                <Row><Col className="details pt-4 pl-0">{props.taskDetails.grade} %</Col></Row>
                                <Row><Col className="details pt-4 pl-0">Comentários</Col></Row>
                                <Row><Col className="details pt-4 pb-3 pl-0">{props.taskDetails.comments}</Col></Row>
                            </Col>
                        )
                        :
                        (<>
                            <Col className="col-4 mt-2 pl-0">
                                <PrimaryPurpleButton onClick={props.handleModalShow}>
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0 ml-auto">
                                            <i className="bi bi-file-earmark-check" />
                                        </Col>
                                        <Col md="auto" className="my-auto mr-auto">
                                            <p>Corrigir tarefa</p>
                                        </Col>
                                    </Row>
                                </PrimaryPurpleButton>
                            </Col>
                        </>
                        )}
                </Row>
            </BodyTasks>
        ) :
            (
                <Row className="justify-content-center">
                    <ChangeLessonLoader sideMenuIsOpen={false} />
                </Row>
            )
        }

        <CorrectionTaskModal
            show={props.showCorrectionTaskModal}
            onHide={props.handleModalClose}
            onChange={props.onChange}
            onSubmit={props.onSubmit}
        />

        <ConfirmExitCorrectionModal
            show={props.showConfirmExitCorrectionTaskModal}
            onHide={props.handleModalClose}
            handleModalCloseAll={props.handleModalCloseAll}
            handleModalShow={props.handleModalShow}
            size={"md"}
        />

        <CorrectedTaskModal
            show={props.showSendCorrectionModal}
            onHide={props.handledCloseSendCorrection}
            returnToList={props.returnToList}
        />

    </>
    )

}


function ConfirmExitCorrectionModal(props: any) {

    return (
        <ConfirmExitCorrectionTaskModalStyles
            {...props}
            centered={true}
        >
            <Modal.Header className="mx-3 mt-4">
                <Container>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <i className="bi bi-exclamation-circle" />
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>

            <Modal.Body className="mx-3">
                <Container className="px-0">
                    <Row>
                        <Col xs={12} md={12}>
                            <Modal.Title className="mt-4">Deseja cancelar o envio?</Modal.Title>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={12}>
                            <p>
                                Você precisará voltar depois para corrigir <br />a tarefa.
                            </p>
                        </Col>
                    </Row>

                    <Row className="my-4">
                        <Col xs={6} md={6} className="text-center">
                            <SecondaryYellowButton onClick={props.handleModalCloseAll}>
                                <Row>
                                    <Col md="auto" className="my-auto pr-0 ml-auto">
                                        X
                                    </Col>
                                    <Col md="auto" className="my-auto mr-auto">
                                        <p>
                                            Cancelar Correção
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryYellowButton>
                        </Col>
                        <Col xs={6} md={6} className="text-center">
                            <PrimaryPurpleButton className="px-4" onClick={props.handleModalShow}>
                                Continuar Correção
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>

        </ConfirmExitCorrectionTaskModalStyles>
    )
}
const ConfirmExitCorrectionTaskModalStyles = styled(Modal)`

    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;

    .modal-content{
        border-radius: 12px!important;
    }

    .modal-dialog{
        max-width:64vmin;
    }

    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
        i{
            font-size:11.11vmin;
            color:#C57700;
        }
    }

    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }

    .modal-body{
        padding-top:0px!important;
        button{
            height:6.66vmin;
            width:100%;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
            i{
                font-size: 3vmin;
            }        }
        p{
            color:#514F4E;
            font-size: 2.22vmin;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;
        }
    }

    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }
`

function CorrectedTaskModal(props: any) {

    return (<>
        <ModalStartTasks
            {...props}
            centered={true}
        >
            <Modal.Body className="mx-3">
                <Container>
                    <Row className="my-3" style={{ placeContent: 'center' }}>
                        <img className="" src={"/img/student/home/woman-drink.png"} width="180" height="180" alt="imagem tarefa corrigida" />
                    </Row>
                    <Row className="mb-3 mt-4" style={{ placeContent: 'center' }}>
                        <h2 style={{ color: '#218749' }}>Tarefa corrigida!</h2>
                    </Row>
                    <Row className="my-2" style={{ textAlign: 'center' }}>
                        <p>Tome uma água e veja se tem outras tarefas para corrigir.</p>
                    </Row>
                    {/* Desktop */}
                    <Row className="my-2 d-none d-md-block d-lg-block" style={{ textAlign: 'center' }}>
                        <PrimaryPurpleButton onClick={props.returnToList}>
                            <Row>
                                <Col md="auto" className="my-auto pr-0 ml-auto">
                                    <p>Ver lista de tarefas</p>
                                </Col>
                                <Col md="auto" className="my-auto mr-auto">
                                    <i className="bi bi-arrow-right" />
                                </Col>
                            </Row>
                        </PrimaryPurpleButton>
                    </Row>
                    {/* Mobile */}
                    <Row className="my-2 d-block d-md-none d-lg-none">
                        <PrimaryPurpleButton onClick={props.returnToList}>
                            Ver lista de tarefas
                            <i className="bi bi-arrow-right" />
                        </PrimaryPurpleButton>
                    </Row>
                </Container>
            </Modal.Body>
        </ModalStartTasks>
    </>)
}
const ModalStartTasks = styled(Modal)`

    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;

    .modal-content{
        border-radius: 12px!important;
    }

    .modal-dialog{
        max-width:64vmin;
    }

    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
        i{
            font-size:11.11vmin;
            color:#C57700;
        }
    }

    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }

    .modal-body{
        padding-top:0px!important;
        button{
            height:6.66vmin;
            width:100%;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
            i{
                font-size: 3vmin;
            }        }
        p{
            color:#514F4E;
            font-size: 2.22vmin;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;
        }
    }

    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    @media screen and (max-width: 990px){
        padding-right: 0 !important;
        
        .modal-dialog{
            max-width:100%;

            button {
                font-size: 5vmin !important;
            }
        }

        p {
            font-size: 5vmin !important;
            line-height: 6vmin !important;
        }  
    }
`

const PreparingLoader = styled(Loader)`
    svg{
        width:3.33vmin;
        height:3.33vmin;
    }
`

function CorrectionTaskModal(props: any) {

    const [comment, SetComment] = useState('');
    const [descriptionMaxLength, setDescriptionMaxLength] = useState(false);
    const [descriptionCounter, setDescriptionCounter] = useState(0);

    function onChangeComment(ev) {
        const { value } = ev.target;
        var maxLength = 1000;

        var comments = value.substr(0, maxLength);
        SetComment(comments)
        setDescriptionCounter(comments.length);

        setDescriptionMaxLength(false)
        if (comments.length === maxLength) {
            setDescriptionMaxLength(true)
        }

        props.onChange(ev)
    }

    function overrideOnSubmit(ev) {
        ev.preventDefault();
        if (comment !== "" || comment !== undefined) {
            props.onSubmit(ev)
        }
    }

    return (
        <ModalCorrectionTask
            {...props}
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>Corrigir Tarefa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Label htmlFor="comment">Comentários *</Form.Label>
                            <Form.Control
                                name='comment'
                                placeholder="Digite comentários sobre a resolução da tarefa"
                                as={"textarea"}
                                rows={7}
                                onChange={onChangeComment}
                                value={comment}
                            >
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="mb-3 counter-caracteres">
                        <Col md={8}>
                            {descriptionMaxLength && (
                                <RequiredFormMessage className="mt-2">Tamanho máximo do texto excedido.</RequiredFormMessage>
                            )}
                        </Col>
                        <Col md={4}>
                            <Form.Text id="descriptionCharCount" muted className="text-right mt-1">
                                {descriptionCounter}/1000
                            </Form.Text>
                        </Col>
                    </Row>

                    <Row className="mb-1">
                        <Col md={6}>
                            <Form.Label htmlFor="grade">Deseja dar uma nota?</Form.Label>
                            <Form.Control
                                name="grade"
                                placeholder="Digite uma nota em %"
                                type="number"
                                min="0"
                                max="100"
                                onChange={props.onChange}
                            >
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row className="btn-footer">
                        <Col md="auto" className="ml-auto">
                            <PrimaryPurpleButton onClick={overrideOnSubmit} disabled={comment.length <= 0}>
                                <Row>
                                    <Col md="auto" className="my-auto pr-0 ml-auto">
                                        <p>Enviar Correção</p>
                                    </Col>
                                    <Col md="auto" className="my-auto mr-auto">
                                        <i className="bi bi-check2 ml-3"></i>
                                    </Col>
                                </Row>
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>
        </ModalCorrectionTask>)
}
const ModalCorrectionTask = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
   
    .modal-content {
        border-radius: 12px!important;
    }

    .modal-dialog{
        max-width:64vmin;
    }

    .modal-header{
        border-bottom:none;        
        i{
            font-size:11.11vmin;
            color:#C57700;
        }
    }

    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }

    .modal-body{
        padding-top:0px!important;
        
        label{
            color:#514F4E;
            font-size: 2vmin;
            line-height: 2.5vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;            
        }

        small {
            color:#514F4E;
            font-size: 1.5vmin;            
            font-style: normal;
        }

        textarea {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 8px;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            border-radius: 8px;
        }

        
        .btn-footer {
            button{
                min-height: 5.5vmin;
            }    
        }
    }

    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }

`

const BodyTasks = styled(Col)`
    margin-top: 4vmin;
    margin-left: 4vmin;

    h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 24px;
        color: #514F4E;
    }
    
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.25vmin;
        line-height: 2.64vmin;
        color: #0C8292;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin-bottom:0px;
    }

    i{        
        font-size: 3.33vmin;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 12px;
    }

    .details{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.8vmin;
        line-height: 20px;
        color: #514F4E;
    }

    .filename {        
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2vmin;
        line-height: 24px;
        color: #514F4E;
    }

    .statusAdjusted {
        color: #218749;
    }

    .statusAwaint{
        color: #C57700;
    }
`

export const RequiredFormMessage = styled.p`
    color: #bf1650 !important;
    font-size: 1.5vmin !important;
    line-height: 5px !important;
    margin-bottom: 2vmin !important;
    font-style: normal !important;
    font-weight: 600 !important;
`