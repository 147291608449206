import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { AddFileNavigationPositionEnum } from "../../../../domain/enum/AddFileNavigationPosition";
import VideoSourceEnum from "../../../../domain/enum/VideoSourceEnum";
import { GetVimeoId } from "../../../../util/VimeoUtils";
import { GetYoutubeId } from "../../../../util/YoutubeUtils";
import { FileUploadServices } from '../../../../services/FileUploadServices'

const uploadService = new FileUploadServices();

export const AddVideoModalContext = createContext({} as AddVideoModalContextData);

interface AddVideoModalContextProps {
    setVideoOnModal: (() => AddVideoObject)
    getVideoVideoFromModal: (videoObject:AddVideoObject) => void
    onClose: () => void
 

    reRender:()=>void
    children: ReactNode;
}


export const AddVideoModalContextProvider = (props: AddVideoModalContextProps) => {
    
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirmExitAddVideo, setShowConfirmExitAddVideo] = useState(false)
    const [showAddVideoModal, setShowAddVideoModal] = useState(true)
    const [navigationPosition, setNavigationPosition] = useState<AddFileNavigationPositionEnum>(AddFileNavigationPositionEnum.index)
    const [isInvalidVideoInput,setIsInvalidVideoInput] = useState(false)

    const [videoObject, setVideoObject] = useState<AddVideoObject>({
        VideoLink:"",
        VideoName:"",
        VideoSource: VideoSourceEnum.Undefined
    })

    const [errorMessage,setErrorMessage] = useState("")
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    useEffect(
        ()=>{
            setVideoObject(
                props.setVideoOnModal()
            )
            props.reRender()
        },
        []
    )

    const onInputFileChange = (e) => {
        setIsUploading(true);
        setIsInvalidVideoInput(false)


        const fileUploaded = e.target.files[0]

        if ((fileUploaded.type === "video/mp4" && (fileUploaded.size/1024/1024) < 100)) {

            const formData = new FormData();
            formData.append('File', fileUploaded);
            formData.append('ValidatedFormat', "true");

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data, image/*, video/*'
                }
            }

            uploadService.uploadTempContainer(formData, config).then(function (response) {
                if (response.success) {

                    var videoDetails = videoObject;
                    videoDetails.VideoLink = response.result.path
                    videoDetails.VideoName = response.result.name
                    videoDetails.VideoSource = VideoSourceEnum.Azure

                    setVideoObject(videoDetails)
                    setIsUploading(false)

                }
            }).catch(err => {
                setErrorMessage("Não conseguimos carregar seu arquivo, tente novamente")
                setIsInvalidVideoInput(true)
                console.log(err);
            });

            //setUploadMessage(fileUploaded.name)

        }

        if ((fileUploaded.size/1024/1024) > 100) {
            setErrorMessage("O arquivo é muito grande, escolha um arquivo com até 100MB")
            setIsInvalidVideoInput(true)
            setIsUploading(false)

        }

        
        if (!(fileUploaded.type === "video/mp4" )) {
            setErrorMessage("Formato não suportado")
            setIsInvalidVideoInput(true)
            setIsUploading(false)
        }

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
        }
    }

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
            hiddenFileInput.current.click();
        }
    }

    const onConfirmClose = () => {
        props.onClose()
    };

    const onCancelClose = () => {
        setShowAddVideoModal(true);
        setShowConfirmExitAddVideo(false);
    };
    
    const onClose = ()=>{
        if(videoObject.VideoLink === ""){
            onConfirmClose()
        }
        else{
            setShowConfirmExitAddVideo(true)
            setShowAddVideoModal(false);
            props.reRender()
        }
    }

    const cancelUpload = ()=>{
        setIsUploading(false)
    }

    const handleVideoLinkChange = (e) => {
        let properties = videoObject;
        properties.VideoLink = e.target.value
        properties.VideoName = ""

        if(GetYoutubeId(properties.VideoLink)!== ""){
            properties.VideoSource = VideoSourceEnum.Youtube
        }
        else if(GetVimeoId(properties.VideoLink)!== ""){
            properties.VideoSource = VideoSourceEnum.Vimeo
        }else{
            properties.VideoSource = VideoSourceEnum.Undefined
        }

        setVideoObject(properties)
        setIsInvalidVideoInput(!isVideoValid)
        setErrorMessage("Este link não é válido, confira ou tente outro link")
        setVideoObject(properties)
        props.reRender()

    }

    const isVideoValid = ():boolean =>{
        return (videoObject.VideoName !== ""&&videoObject.VideoLink !== ""&&videoObject.VideoSource === VideoSourceEnum.Azure)
                ||(GetVimeoId(videoObject.VideoLink)!== ""||GetYoutubeId(videoObject.VideoLink)!== "")
    }

    const onSubmit = () =>{
        props.getVideoVideoFromModal(videoObject)

        setNavigationPosition(AddFileNavigationPositionEnum.index)
        setVideoObject({
            VideoLink:"",
            VideoName:"",
            VideoSource: VideoSourceEnum.Undefined
        })
        setShowAddVideoModal(false)
        props.reRender()
    }

    const returnVideoUrlSRC = () =>{
        if(GetYoutubeId(videoObject.VideoLink)!== ""){
            return "https://www.youtube.com/embed/"+GetYoutubeId(videoObject.VideoLink)
    
        }
        return "https://player.vimeo.com/video/" + GetVimeoId(videoObject.VideoLink)

    }


    return (
        <AddVideoModalContext.Provider
            value={{
                isInvalidVideoInput,
                setIsInvalidVideoInput,

                isUploading,
                setIsUploading,
            
                showConfirmExitAddVideo,
                setShowConfirmExitAddVideo,
            
                showAddVideoModal,
                setShowAddVideoModal,
            
                navigationPosition,
                setNavigationPosition,
            
                videoObject,
                setVideoObject,
            
                errorMessage,
                setErrorMessage,
            
            
                returnVideoUrlSRC,
                onSubmit,
                handleVideoLinkChange,
                handleAddFileButton,
                isVideoValid,

                cancelUpload,
                onClose,
                onConfirmClose,
                onCancelClose,
            
                hiddenFileInput,
                onInputFileChange
            }}
        >
            {props.children}
        </AddVideoModalContext.Provider>
    );
};

interface AddVideoModalContextData {
    isInvalidVideoInput: boolean
    setIsInvalidVideoInput: React.Dispatch<React.SetStateAction<boolean>>

    isUploading: boolean
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>

    showConfirmExitAddVideo: boolean
    setShowConfirmExitAddVideo: React.Dispatch<React.SetStateAction<boolean>>

    showAddVideoModal: boolean
    setShowAddVideoModal: React.Dispatch<React.SetStateAction<boolean>>

    navigationPosition:AddFileNavigationPositionEnum
    setNavigationPosition:React.Dispatch<React.SetStateAction<AddFileNavigationPositionEnum>>

    videoObject:AddVideoObject
    setVideoObject:React.Dispatch<React.SetStateAction<AddVideoObject>>

    errorMessage:string
    setErrorMessage:React.Dispatch<React.SetStateAction<string>>


    returnVideoUrlSRC:()=> string
    onSubmit:()=> void
    handleVideoLinkChange:(e:any)=> void
    handleAddFileButton:()=> void
    isVideoValid:()=> boolean

    cancelUpload:()=> void
    onClose:()=> void
    onConfirmClose: () => void
    onCancelClose: () => void 

    hiddenFileInput:React.RefObject<HTMLInputElement>
    onInputFileChange:(e:any)=> void
}


export interface AddVideoObject{
    VideoLink:string,
    VideoName:string,
    VideoSource: VideoSourceEnum
}

