import React from "react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import SendTaskModalState from "../../../domain/enum/SendTaskModalState";
import { AttendCourseContext } from "./AttendCourseContext";

import ApprenticeServices from "../../../services/ApprenticeServices";
import { FileUploadServices } from "../../../services/FileUploadServices";
import IDeliveryTaskPayload from "../../../domain/payloads/apprentice/IDeliveryTaskPayload";
import { ApprenticeProgressStatusEnum } from "../../../domain/enum/ApprenticeProgressStatusEnum";
import axios from "axios";

const uploadService = new FileUploadServices();
const apprenticeService = new ApprenticeServices();


interface TaskContextData{
    modalState:SendTaskModalState
    setModalState:React.Dispatch<React.SetStateAction<SendTaskModalState>>

    showModal:boolean,
    setShowModal:React.Dispatch<React.SetStateAction<boolean>>

    fileInfo:FileUploadInfo
    setFileInfo:React.Dispatch<React.SetStateAction<FileUploadInfo>>

    hiddenFileInput:React.RefObject<HTMLInputElement>

    handleRetrySend:()=>void

    onInputFileChange:(e: any)=>void
    handleAddFileButton:()=>void
    handleSendTask:(courseId:string,moduleContentId:string)=>void
    handleCancelUpload:()=>void
    handleClose:()=>void
    continueSent:()=>void
    closeModal:()=>void

}

export const TaskContext = createContext({} as TaskContextData)

interface TaskContextProviderProps {    
    children: ReactNode
}
export const TaskContextProvider = (props:TaskContextProviderProps)=>{
    const {
        currentUserModuleContentProgress,
        apprenticeCourseProgress,
        reRender,
        changeModuleContentState
    } = useContext(AttendCourseContext); 

    const source = axios.CancelToken.source();
    
    const [modalState, setModalState] = useState<SendTaskModalState>(SendTaskModalState.ChooseFile);
    const [fileInfo, setFileInfo] = useState<FileUploadInfo>(null as any);

    const [showModal, setShowModal] = useState(false);
    
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    
    function handleClose(){
        if(modalState==SendTaskModalState.ChooseFile||
            modalState==SendTaskModalState.SuccessfullSent||
            modalState==SendTaskModalState.Error){
                closeModal()

        }else if(modalState==SendTaskModalState.ConfirmSelection){
            setModalState(SendTaskModalState.ConfirmExit)
        }
    }

    function closeModal(){
        setShowModal(false)
        setTimeout(() => {
            setFileInfo(null as any)
            setModalState(SendTaskModalState.ChooseFile)
        }, 500);
    }
    function continueSent(){
        if(fileInfo){
            setModalState(SendTaskModalState.ConfirmSelection)
        }else{
            setModalState(SendTaskModalState.ChooseFile)
        }
    }

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }
        
    const onInputFileChange = (e: any) => {

        const fileUploaded = e.target.files[0];

        const formData = new FormData();
        formData.append('File', fileUploaded);
        formData.append('ValidatedFormat', "false");

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data, image/*, video/*'
            }
        }
        uploadService.uploadTempContainer(formData, config).then(function (response) {
            if (response.success) {
                setFileInfo(
                    {
                        fileName:response.result.name,
                        filePath:response.result.path
                    }
                )
                setModalState(SendTaskModalState.ConfirmSelection)
            }
        }).catch(err => {
            setModalState(SendTaskModalState.Error)

            console.log(err);
        });

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }
    }

    const handleSendTask = (courseId:string,moduleContentId:string) => {

        setModalState(SendTaskModalState.UploadingFile)

        let data:IDeliveryTaskPayload = {
            courseId: courseId,
            moduleContentId: moduleContentId,
            filepath: fileInfo.filePath,
            fileName:fileInfo.fileName
        }

        apprenticeService.deliveryTask(data).then(function (response) {
            if (response.success) {
                if (response.result == false) {
                    setModalState(SendTaskModalState.Error)
                } else {                   
                    setModalState(SendTaskModalState.SuccessfullSent)
                    

                    changeModuleContentState(ApprenticeProgressStatusEnum.Sent, currentUserModuleContentProgress)
                }
            } else {
                setModalState(SendTaskModalState.Error)
            }
        }).catch(err => {
            console.log(err);
            setModalState(SendTaskModalState.Error)
        });
        reRender()

    }

    const handleRetrySend = () =>{
        if(fileInfo === null){
            setModalState(SendTaskModalState.ChooseFile)

        }else{
            setModalState(SendTaskModalState.ConfirmSelection)
        }
    }

    const handleCancelUpload = ()=>{
        setModalState(SendTaskModalState.ConfirmSelection)
        handleCancelSent()

    }

    const handleCancelSent = ()=>{
        if (source) {
            source.cancel("Landing Component got unmounted");
        }
    }

    return(
        <TaskContext.Provider
            value={{
                modalState, setModalState,
                showModal, setShowModal,
                fileInfo, setFileInfo,
                handleAddFileButton,
                onInputFileChange,
                handleClose,
                continueSent,
                handleSendTask,
                handleRetrySend,
                handleCancelUpload,
                closeModal,
                hiddenFileInput
            
            }}
        >
            {
                props.children
            }
        </TaskContext.Provider>
    )
}

interface FileUploadInfo{
    fileName:string,
    filePath:string
}