import { useContext } from 'react';
import { Container, Modal } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddDocumentModalContext } from '../context/AddDocumentModalContext';
import { ChooseViewTypeScreen } from './ChooseViewTypeScreen';
import { IndexScreen } from './IndexScreen';
import { SendFileScreen } from './SendFileScreen';
import { UploadingScreen } from './UploadingScreen';

export const AddImageModalBody = () => {
    const {
        isUploading, hiddenFileInput, onInputFileChange,
    } = useContext(AddDocumentModalContext);

    return (
        <Modal.Body>
            <Container>
                {isUploading ?
                    (
                        <UploadingScreen/>
                    )
                    :
                    (
                        <AddDocumentModalNavigationPosition />
                    )}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onInputFileChange}
                    className="d-none"
                    multiple={false}                    
                />
            </Container>
        </Modal.Body>
    );
};

const AddDocumentModalNavigationPosition = () => {
    const {
        navigationPosition
    } = useContext(AddDocumentModalContext);

    switch (navigationPosition) {
        case AddFileNavigationPositionEnum.index: {
            return (
                <IndexScreen/>
            );
        }
        case AddFileNavigationPositionEnum.verifyFile: {
            return (
                <SendFileScreen/>
            );
        }
        case AddFileNavigationPositionEnum.chooseViewType: {
            return (
                <ChooseViewTypeScreen/>
            );
        }
        default:
            return <></>;
    }

};
