import { Container, Modal, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export const CreateCourseStyles = styled(Container)`
    @media(min-height: 900px) {
       margin-top: 15vh;        
    }
    @media(max-height: 900px) {
        margin-top: 16vh;    
    }
    @media(max-height: 800px) {
        margin-top: 17vh;
    }  
    @media(max-height: 700px) {
        margin-top: 21vh;   
    }
    min-height:60vmin;
    padding-bottom: 20vmin;
    padding-top: 3vmin;
    .toast{
        max-width:100%;
        flex-basis:auto;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;

        border: 0.14vmin solid #E1F4F7;
        box-sizing: border-box;
        border-radius: 0.56vmin;
        box-shadow:none;
        .toast-header{
            height:6.67vmin;
            background: #F9F9F9;
            border-bottom:none;
            button{
                color:#0C8292;
                opacity:1;
                font-size:4.167vmin;
                font-weight:400;
            }
        }
        i{
            font-size:3.33vmin;
            color:#0C8292;
        }
    }
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #6468C8;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;
    }
    label{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;
        margin-bottom: 0.5vmin;
    }
    textarea{
        border: 0.14vmin solid #B9B8B8;
        box-sizing: border-box;
        border-radius: 1.11vmin;
        height: 9.45vmin;
        padding-top:1.5vmin;
        ::placeholder{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            display: flex;
            align-items: center;
            color: #777575;
        }
        :focus{
            background: #FFFFFF;
            border: 0.14vmin solid #A996F6;
            box-sizing: border-box;
            box-shadow: 0px 0px 0.56vmin #A996F6;
            border-radius: 1.11vmin;
            
            ::placeholder{
                color: #E8E8E8;
            }
        }

    }
    input{
        border: 0.14vmin solid #B9B8B8;
        box-sizing: border-box;
        border-radius: 1.11vmin;
        height:6.66vmin;
        ::placeholder{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            display: flex;
            align-items: center;
            color: #777575;
        }
        :focus{
            background: #FFFFFF;
            border: 0.14vmin solid #A996F6;
            box-sizing: border-box;
            box-shadow: 0px 0px 0.56vmin #A996F6;
            border-radius: 1.11vmin;

            ::placeholder{
                color: #E8E8E8;
            }
        }
    }
    button.card{
        .card-body{
            padding:0px;
            min-width:23vmin;
            min-height:15vmin;

        }
        background: #FFFFFF;
        border: 0.14vmin solid #F9F9F9;
        box-sizing: border-box;
        box-shadow: 0px 0.14vmin 0.56vmin rgba(75, 75, 75, 0.2);
        border-radius: 1.11vmin;
        :hover{
            box-shadow: 0px 0.14vmin 0.56vmin rgba(75, 75, 75, 0.6);

        }
        i{
            font-size:4.44vmin;
            color:#636060;
        }
    }
    label.course-img{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #636060;
    }
    p.course-img{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.77vmin;
        color: #777575;

    }
    .row.foward-back{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
        }
        i{
            font-size: 3.33vmin;
        }
    }

    input[type=checkbox] {
        position: relative;
        cursor: pointer;
        width: 30px;
        height: 30px;    
        -webkit-appearance: inherit;
        background-color: #fff;
        border: 2px solid #777575;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }
    
    input[type=checkbox]:active, input[type=checkbox]:checked:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
    
    input[type=checkbox]:before {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background-color:#e9e9e9;
    }

    input[type=checkbox]:checked {
        background-color: #EA694C;
        border: 1px solid #EA694C;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    } 

    input[type=checkbox]:checked:after {
        content: '\\2714';
        font-size: 25px;
        position: absolute;
        top: -4px;
        left: 5px;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
    }


    .react-toggle.react-toggle--checked .react-toggle-track{
        background-color:#6468C8!important;
        length:100px!important;
        border:none!important;
    }

    .react-toggle .react-toggle-track{
        background-color:#FFFFFF!important;
        border:1px;
    }

    .react-toggle-track{
        border-radius:20px;
        width:71px;
        height:40px!important;
        border:2px solid #8D8D8D!important;
    }

    .react-toggle-thumb{
        background-color:#8D8D8D;
        border:none;
        width:30px;
        height:30px!important;
        top:5px;
        left:5px; 
        &:target{
            box-shadow:none!important;
        }
    }

    .react-toggle--checked .react-toggle-thumb{
        background-color:#FFFFFF!important;
        left:35px;
    }

    .react-toggle--focus .react-toggle-thumb{
        box-shadow:none;
    }
    
    .react-toggle-screenreader-only{
        display:none !important;
    }

    .sun-editor-editable{
        li{
            color:#514F4E;
            font-size:2.22vmin!important;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: 400;
            font-style: normal;
        }
        ul{
            color:#514F4E;
            font-size:2.22vmin!important;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: 400;
            font-style: normal;
        }

    }
    .se-placeholder{
            color:#8c8a88;
            font-size:2vmin!important;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: 400;
            font-style: normal;

        }

`

export const StyledCreatableSelect = styled(CreatableSelect)`
    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }
    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }
    .react-select__control
    {
        
        border: 0.14vmin solid #B9B8B8;
        box-sizing: border-box;
        border-radius: 1.11vmin;
    }

    .react-select__value-container{
        height:6.66vmin;
    }
    .react-select__placeholder{
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        font-family: 'Lato', sans-serif;
        font-weight: 400!important;
        letter-spacing: 0px;
        margin-top:auto;
        margin-bottom:auto;
    }
    .react-select__control--is-disabled{
        background: #fbfbff99 0% 0% no-repeat padding-box!important;
        border: 0.14vmin solid #8d8d8d69!important;
        .react-select__placeholder{
            color:#8d8d8d69!important;
        }
    }
    .react-select__control--is-focused 
    {
        background: #FFFFFF;
        border: 0.14vmin solid #A996F6!important;
        box-sizing: border-box;
        box-shadow: 0px 0px 0.56vmin #A996F6!important;
        border-radius: 1.11vmin;
        .react-select__placeholder{
            color: #E8E8E8;
        }
        
    }
    .react-select__multi-value {
        background: #f5f7ff 0% 0% no-repeat padding-box!important;
        border: 0.14vmin solid #6468C8;
        border-radius: 1.66vmin!important;
        opacity: 1;
        color: #6468C8;
        width: auto;
    }
    .react-select__multi-value__remove {    
        border-radius: 50%!important;    
        background: #6468C8;
        text-align: center;    
        color: #FFF;
        margin: 0.28vmin;    
    }
    .react-select__multi-value__remove:hover {
        background: transparent!important;
        color: #5a5ed6!important;
        cursor: pointer;
    }
    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const StyledSearchableSelect = styled(Select)`

    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }

    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }

    .react-select__control
    {
        border-color: #b3b5df!important;
        border-radius:8px;
    }

    .react-select__control--is-focused 
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }

    .react-select__multi-value {
        background: #f5f7ff 0% 0% no-repeat padding-box!important;
        border: 1px solid #6468C8;
        border-radius: 12px!important;
        opacity: 1;
        color: #6468C8;
        width: auto;
    }

    .react-select__multi-value__remove {    
        border-radius: 50%!important;    
        background: #6468C8;
        text-align: center;    
        color: #FFF;
        margin: 2px;    
    }

    .react-select__multi-value__remove:hover {
        background: transparent!important;
        color: #5a5ed6!important;
        cursor: pointer;
    }

    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const StyledSearchableAsyncSelect = styled(AsyncSelect)`

    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }

    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }

    .react-select__control
    {
        border-color: #b3b5df!important;
        border-radius:8px;
    }

    .react-select__control--is-focused 
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }

    .react-select__multi-value {
        background: #f5f7ff 0% 0% no-repeat padding-box!important;
        border: 1px solid #6468C8;
        border-radius: 12px!important;
        opacity: 1;
        color: #6468C8;
        width: auto;
    }

    .react-select__multi-value__remove {    
        border-radius: 50%!important;    
        background: #6468C8;
        text-align: center;    
        color: #FFF;
        margin: 2px;    
    }

    .react-select__multi-value__remove:hover {
        background: transparent!important;
        color: #5a5ed6!important;
        cursor: pointer;
    }

    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const CoursePublishedModal = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;
    .modal-dialog{
        max-width:70vmin;
    }
    .modal-content{
        padding:15px;
        border-radius: 12px!important;
    }
    .modal-header{
        border-bottom:none;
        img{
            height:25vmin;
        }
    }
    .modal-body{
        padding-top:0px!important;
        padding-left:0px;
        padding-right:0px;
    }
    .modal-title{
        cursor:default;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.94vmin;
        line-height: 3.33vmin;

        text-align: center;

        color: #218749;
    }

    p{
        color:#514F4E;
        font-size:2.22vmin!important;
        line-height:3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`

export const CreateCourseQuizComponent = styled(Col)`
    .react-toggle.react-toggle--checked .react-toggle-track{
        background-color:#6468C8!important;
        length:100px!important;
        border:none!important;
    }

    .react-toggle .react-toggle-track{
        background-color:#FFFFFF!important;
        border:1px;
    }
    .react-toggle-track{
        border-radius:20px;
        width:71px;
        height:40px!important;
        border:2px solid #8D8D8D!important;
    }
    .react-toggle-thumb{
        background-color:#8D8D8D;
        border:none;
        width:30px;
        height:30px!important;
        top:5px;
        left:5px; 
        &:target{
            box-shadow:none!important;
        }
    }
    .react-toggle--checked .react-toggle-thumb{
        background-color:#FFFFFF!important;
        left:35px;
    }
    .react-toggle--focus .react-toggle-thumb{
        box-shadow:none;
    }
`

export const RequiredFormMessage = styled.p`
    color: #bf1650 !important;
    font-size: 1.5vmin !important;
    line-height: 5px !important;
    margin-bottom: 2vmin !important;
    font-style: normal !important;
    font-weight: 600 !important;
`

export const AddVideoButton = styled(Col)`

    .video-thumbnail-row{
        width:100%;
        background-color:#F9F9F9;
        border: 0.14vmin solid #E8E8E8;
        border-radius: 1.11vmin;
        h5{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 1.944vmin;
            line-height: 2.77vmin;
            color: #636060;
            margin-bottom:0px;
        }
        h3{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #514F4E;
            margin-bottom:0px;
        }
        i{
            font-size: 2.63vmin;
        }
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.22vmin;
            line-height: 2.63vmin;
        }
        video{
            width:21.6vmin; 
            border-radius: 1.11vmin;
        }
        img{
            width:21.6vmin; 
            border-radius: 1.11vmin;
            object-fit: fill;
        }
    }

    .add-video{
        width:21.6vmin;
        height:13.44vmin; 
        box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2);
        border: 0.14vmin solid #F9F9F9;
        border-radius: 1.11vmin;
        background-color: #FFF;
        i{
            font-size:4.44vmin;
            color:#636060;
        }
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.22vmin;
            line-height: 2.5vmin;
            color:#636060!important;
        }
        &:hover{
            background-color: #E1F4F7;
            border: 0.14vmin solid #F9F9F9;
        }
        &:focus, &:active{
            background-color: #d1f3f8!important;
            box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2)!important;
            border: none!important;
        }
        &:focus:after, &:active:after{
            content: '';
            position: absolute;
            top: 0px;
            left: 0.8vmin;
            bottom: 0px;
            right: 0.8vmin;
            border: 0.20vmin solid #0B717E!important;
            border-radius: 1.11vmin;
        }
    }
    .question-image{
        width:auto;
        max-width:50vmax;
        height:30vh;

    }
`