import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const StudentFooterLogo = styled.img`
    height: 10vmin !important;    
    width:100%;

    @media screen and (max-width: 990px){
        height: 27vmin !important
    }
`
export const ReeducationLogo = styled.img`
    height: 15vmin !important; 
    width:auto;
    z-index:1;
`

export const StudentFooterCopyRights = styled.p`
    padding-top:13px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6vmin;
    line-height: 3.33vmin;
    color: #514F4E;

    @media screen and (max-width: 990px){
        font-size: 4vmin;
        text-align: center;
    }
`
export const StudentFooterButtons = styled(Button)`
    padding-bottom:10px;
    background: #FFFFFF!important;
    border:none;
    border-radius:0px;

    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 3.33vmin;
    color: #0C8292!important;

    &:hover{
        font-weight: 500!important;
    }
`

export const StudentFooterStyles = styled.footer`
    position:static;
    width:100%;
    bottom:0;
    background-color:#FFFFFF!important;
    border:none;
    margin-top:5vh;
    margin-bottom:2vh;
`
