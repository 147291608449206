import { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import styled from 'styled-components'
import { Button, Card, Col, Row, Container } from "react-bootstrap"

//Components
import { PrimaryPurpleButton, SecondaryGreenButton, TertiaryPurpleButton } from "../../../../components/Buttons/styles"
import ChangeLessonLoader from "../../../../components/Loaders/ChangeLessonLoader";

//Context
import { AssessmentContext } from '../../../../context/apprentice/attend/AssessmentContext';
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
//import { FeedbackQuizCourseComponentStyle, FeedbackContainer, HoldAnswer, QuestionContent } from "./styles";

interface AssessmentFeedbackPageContentProps {
    isFullScreen: boolean
}

const AssessmentFeedbackPageContent = (props: AssessmentFeedbackPageContentProps) => {

    const {
        assessmentFeedbackResult,
        isReproved,
        handleLearningHintCollapse,
        redoAssessment,
        goBackCourse,
        isLoading
    } = useContext(AssessmentContext);

    const {
        menuCollapsed
    } = useContext(AttendCourseContext);

    return (<>
        {isLoading
            ? (<ChangeLessonLoader
                sideMenuIsOpen={menuCollapsed} />
            )
            : (
                <FeedbackQuizCourseComponentStyle isFullScreen={props.isFullScreen}>
                    <FeedbackContainer isFullScreen={props.isFullScreen}>
                        <Col md={7} className="mt-md-5 pt-4  ml-md-4">
                            {isReproved()
                                ? (<>
                                    <Row>
                                        <Col className="px-md-2">
                                            <p>Avaliação do módulo</p>
                                        </Col>
                                    </Row>
                                
                                    <Row>
                                        <Col className="px-md-2 mb-4">
                                            <h1 className="reproved">Você acertou {assessmentFeedbackResult.apprenticeQuizGrade}% das perguntas! Tente novamente.</h1>
                                        </Col>
                                    </Row>
                                </>)
                                : (<>
                                    <Row>
                                        <Col className="px-md-2">
                                            <p>Avaliação do módulo</p>
                                        </Col>
                                    </Row>
                                    {assessmentFeedbackResult.qtyCorrectQuestions >= assessmentFeedbackResult.qtyWrongQuestions &&
                                        <Row>
                                            <Col className="px-md-2 mb-4">
                                                <h1 className="passed">Parabéns! Você acertou {assessmentFeedbackResult.apprenticeQuizGrade}% das perguntas.</h1>
                                            </Col>
                                        </Row>
                                    }
                                </>)
                            }
                            <Row>
                                <Col className="px-md-2 mb-2">
                                    <p>Resumo da sua avaliação</p>
                                </Col>
                            </Row>
                            <Row className="holdCardMobile">
                                <Col xs={6} md={"auto"} className="pl-4 px-md-1 mx-md-1">
                                    <Card>
                                        <Card.Header className="px-md-1">
                                            <h5>Sua nota</h5>
                                        </Card.Header>
                                        <Card.Body className="py-1 px-1">
                                            <h1>{assessmentFeedbackResult.apprenticeQuizGrade}%</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {
                                    assessmentFeedbackResult.quizHasGrade &&
                                    <Col xs={6} md={"auto"} className="px-md-1 mx-md-1">
                                        <Card>
                                            <Card.Header className="px-1">
                                                <h5>Nota mínima</h5>
                                            </Card.Header>
                                            <Card.Body className="py-1 px-1">
                                                <h1>{assessmentFeedbackResult.quizGrade}%</h1>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                }
                                <Col xs={6} md={"auto"} className="px-md-1 mx-md-1">
                                    <Card>
                                        <Card.Header className="px-1">
                                            <h5>Acertos</h5>
                                        </Card.Header>
                                        <Card.Body className="py-1 px-1">
                                            <h1>{assessmentFeedbackResult.qtyCorrectQuestions}</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={6} md={"auto"} className="px-md-1 mx-md-1">
                                    <Card >
                                        <Card.Header className="px-1">
                                            <h5>Erros</h5>
                                        </Card.Header>
                                        <Card.Body className="py-1 px-1">
                                            <h1>{assessmentFeedbackResult.qtyWrongQuestions}</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>   
                                {
                                    isReproved() &&
                                    <Col className="px-2 mb-2 mb-md-5 mt-md-5 mt-3 col-5">
                                        <SecondaryGreenButton
                                            className="d-none d-md-block px-5 retry-quiz" onClick={redoAssessment}>
                                            <Row>
                                                <Col md="auto" className="pr-1">
                                                    <i className="bi bi-arrow-counterclockwise" />
                                                </Col>
                                                <Col md="auto" className="my-auto">
                                                    <p>Refazer avaliação</p>
                                                </Col>
                                            </Row>
                                        </SecondaryGreenButton>
                                        <SecondaryGreenButton className="redo-evaluation d-block d-md-none" onClick={redoAssessment}>
                                            Refazer avaliação
                                            <i className="bi bi-arrow-counterclockwise" />
                                        </SecondaryGreenButton>
                                    </Col>
                                }
                                <Col className="px-2 mb-2 mb-md-5 mt-md-5 mt-3">
                                    {props.isFullScreen &&
                                        <>
                                            <PrimaryPurpleButton
                                                className="d-none d-md-block px-5"
                                                onClick={() => goBackCourse()}>
                                                <Row>
                                                    <Col md="auto" className="my-auto">
                                                        <p>Voltar para o curso</p>
                                                    </Col>
                                                    <Col md="auto">
                                                        <i className="bi bi-arrow-right" />
                                                    </Col>
                                                </Row>
                                            </PrimaryPurpleButton>
                                            <PrimaryPurpleButton className="hold-back-btn d-block d-md-none" onClick={() => goBackCourse()}>
                                                Voltar para o curso
                                                <i className="bi bi-arrow-right" />
                                            </PrimaryPurpleButton>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-none d-md-block mt-md-5 mr-auto ml-5">
                            {isReproved()
                                ? (<img className="reproved" src={"/img/student/home/woman-meditating.svg"} />)
                                : (<img className="passed" src={"/img/student/attend/woman-boss-business.svg"} />)
                            }
                        </Col>
                        <Col className="d-none d-md-none">
                            {isReproved()
                                ? (<img className="reproved" src={"/img/student/home/woman-meditating.svg"} />)
                                : (<img className="passed" src={"/img/student/attend/woman-boss-business.svg"} />)
                            }
                        </Col>
                    </FeedbackContainer>

                    {/*wrong answers*/}
                    {
                        assessmentFeedbackResult.qtyWrongQuestions > 0 ?
                            (
                                <HoldAnswerRow isFullScreen={props.isFullScreen}>
                                    <Col>
                                        <div className="mt-md-5">
                                            <Col>
                                                <h3>Confira quais perguntas você não acertou</h3>
                                            </Col>
                                        </div>
                                        <div className="answers-row">
                                            <Col>
                                                <ul>
                                                    {
                                                        assessmentFeedbackResult.questions.map((question, index) => {
                                                            return (
                                                                <li className="mb-4">
                                                                    <div className="my-3">
                                                                        <Col>
                                                                            <h1>Pergunta {question.order + 1}</h1>
                                                                        </Col>
                                                                    </div>
                                                                    <div className="pb-2">
                                                                        <Col>
                                                                            <QuestionContent>
                                                                                {question.content}
                                                                            </QuestionContent>
                                                                        </Col>
                                                                    </div>
                                                                    <div>
                                                                        <Col>
                                                                            <ul>
                                                                                {
                                                                                    question.assessmentAnswers.map((answer) => {
                                                                                        return (
                                                                                            <li className={(answer.selectedAnswer ? "selected-answer" : "")}>
                                                                                                <Row className="py-1">
                                                                                                    <Col xs={1} md="auto" className="my-auto pr-0">
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            checked={answer.selectedAnswer}
                                                                                                        />
                                                                                                    </Col>
                                                                                                    <Col xs={11} md={9} className="my-auto">
                                                                                                        <p>{answer.content}</p>
                                                                                                    </Col>
                                                                                                    {/* {
                                                                                                        answer.selectedAnswer &&
                                                                                                        (<Col md="auto" className="my-auto pl-0">
                                                                                                            <i className="bi bi-x-lg" />
                                                                                                        </Col>)
                                                                                                    } */}
                                                                                                </Row>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </Col>
                                                                    </div>
                                                                    {
                                                                        question.hint !== "" &&
                                                                        (
                                                                            <>
                                                                                <Row className="d-none d-md-block">
                                                                                    <Col>
                                                                                        <SecondaryGreenButton
                                                                                            className="mt-3 mb-2"
                                                                                            onClick={() => handleLearningHintCollapse(index)}>
                                                                                            <Row>
                                                                                                <Col className="my-auto">
                                                                                                    <p>Há uma dica disponível para esta pergunta</p>
                                                                                                </Col>
                                                                                                <Col xs={1} md={"auto"} className="ml-2 mr-2"                                                                                                   
                                                                                                    >
                                                                                                    {question.hintCollapsed === true
                                                                                                        ? (<i className="bi bi-chevron-up" />)
                                                                                                        : (<i className="bi bi-chevron-down" />)
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </SecondaryGreenButton>
                                                                                    </Col>
                                                                                </Row>
                                                                                <section className="d-block d-md-none mt-3">
                                                                                    <Button className="custom-btn-accordion" onClick={() => handleLearningHintCollapse(index)}>
                                                                                        Há uma dica disponível
                                                                                        {question.hintCollapsed === true
                                                                                            ? (<i className="bi bi-chevron-up" />)
                                                                                            : (<i className="bi bi-chevron-down" />)
                                                                                        }                                                                                            
                                                                                    </Button>
                                                                                </section>

                                                                                {
                                                                                    question.hintCollapsed === true &&
                                                                                    (
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <Card className="mt-2 text-left">
                                                                                                    <Card.Body>
                                                                                                        <Row>
                                                                                                            <Col className="text-left">
                                                                                                                <h5>
                                                                                                                    Dica
                                                                                                                </h5>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row>
                                                                                                            <Col className="text-left">
                                                                                                                <p>{question.hint}</p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Card.Body>
                                                                                                </Card>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    )
                                                                                }

                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        assessmentFeedbackResult.questions.length > index + 1 && <hr className="my-4" />
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                        </div>
                                    </Col>
                                </HoldAnswerRow>
                            )
                            :
                            (
                                <></>
                            )
                    }
                </FeedbackQuizCourseComponentStyle>
            )}
    </>)
}

export default AssessmentFeedbackPageContent;

export const HoldAnswerRow = styled(Row)` 

    padding-left: ${props => (props.isFullScreen ? '15vmin' : '0')};
    padding-right: ${props => (props.isFullScreen ? '15vmin' : '0')};

    @media screen and (max-width:990px){
        padding: 20px 0px 0px 0px !important;        

        .col {
            padding: 0px;
            margin: 0px 8px;
        }

        .custom-btn-accordion {
            color: #0C8292;   
            border-color: #0C8292 !important;
            width: 100%; 
            border-radius: 8px !important;   
            
            i {
                margin-left: 7px;
            }            

            &:focus {
                background: #FFF !important;
                outline: 0 !important;
            }            
        }

        h1 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h3 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }        

        h5 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        .card-body {
            padding: 0 !important;
        }

        p {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        input {
            margin-right: 7px !important;
        }

        pre {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }
    }
`

export const FeedbackQuizCourseComponentStyle = styled(Col)` 
    max-width:100vw;
    max-height:91vh;
    overflow-y:scroll;
    padding-right:0;
    padding-left:0;


    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8; 
        width: 1.12vmin;
    }

    img.reproved{
        max-height:40vmin;
        max-height:${props => (props.isFullScreen ? '40vmin' : '33vmin')}
    }

    img.passed{
        max-height:${props => (props.isFullScreen ? '50vmin' : '40vmin')}
    }

    button.retry-quiz {
        i{
            font-size:3.33vmin;
        }
    }

    button{
        p{
            margin-bottom:0px;
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.36vmin;
            line-height: 2.63vmin;
        }
        i{
            font-size:3.33vmin;
        }
    } 
    
    h1.passed{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #218749;
    }
    
    h1.reproved{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #6468C8;
    }
    
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.78vmin;
        color: #514F4E;
        padding: 0 1.5vmin;
    }
    
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }

    //attempt info cards
    .card{
        background: #FFFFFF;
        box-shadow: 0px 0.14vmin 0.83vmin rgba(81, 79, 78, 0.2);
        border-radius: 1.11vmin;
        min-width:12vmin;
        border:none;
        .card-body{
            text-align:center;
            h1{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 3.33vmin;
                line-height: 3.33vmin;
                text-align: center;
                color: #514F4E;
            }
        }
        .card-header{
            text-align:center;
            padding-bottom:0px;
            border-bottom:none;
            border-top-left-radius:1.11vmin;
            border-top-right-radius:1.11vmin;

            background: #FFFFFF;

            h5{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 1.94vmin;
                line-height: 2.78vmin;
                text-align: center;
                color: #514F4E;

            }
        }
    }
    
    //list of questions
    ul{
        list-style-type:none;
        padding-left:0px;
        hr{
            border-top: 0.14vmin solid #B9B8B8
        }
        li{
            h1{
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 2.22vmin;
                line-height: 2.78vmin;
                color: #995C00;
                margin-bottom:0px;
            }
            h5{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2.22vmin;
                line-height: 3.33vmin;
                color: #514F4E;
            }
            button{
                border: 0.14vmin solid #0C8292;
                box-sizing: border-box;
                border-radius: 1.11vmin;
                background-color:#FFFFFF;
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.22vmin;
                    line-height: 2.63vmin;
                    color: #0C8292;
                }
                i{
                    color:#0C8292;
                }

            }
            ul{
                .selected-answer{
                    p{
                        color: #995C00;
                    }
                    i{
                        font-size: 2.22vmin;
                        color: #995C00;
                    }
                } 
                input[type="radio"]{
                    appearance: none;
                    width:4.44vmin;
                    height:4.44vmin;
                    border: 0.28vmin solid #777575;
                    border-radius:50%;
                    padding:0.42vmin;
                    background-clip: content-box;
                }
                input[type="radio"]:checked{
                    background-color: #995C00;
                    border: 0.28vmin solid #995C00;
                }
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    color: #777575;
                    margin-bottom:0.5vmin;
                }
            }
            .card{
                background: #F9F9F9;
                border-radius: 1.11vmin;
                box-shadow: none;
                border:none;
                h5{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.22vmin;
                    line-height: 2.78vmin;
                    color: #514F4E;
                }
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    color: #514F4E;
                }
                a{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    text-decoration-line: underline;
                    color: #0C8292;
                }
            }
        }
    }
`

export const FeedbackContainer = styled(Row) <{ isFullScreen: boolean }>`
    margin:0;
    background: #F9F9F9;  
    padding-bottom: 15px;

    padding-left: ${props => (props.isFullScreen ? '15vmin' : '0')};
    padding-right: ${props => (props.isFullScreen ? '15vmin' : '0')};

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 24px;
        color: #514F4E;
    }

    @media screen and (max-width:990px){  
        
        .holdCardMobile {
            padding: 10px;
        }
        
        .hold-back-btn {
            width: 100%;

            i {
                margin-left: 7px;
            }
        }

        .col-6 {
            padding: 5px !important;
        } 
        
        .img-passed {
            display: none !important;
        }

        .img-reproved {
            display: none !important;
        }        

        .card {
            h5 {
                font-size: 4vmin !important;
            }

            h3 {
                font-size: 6.2vmin !important;
                font-weight: 600 !important;
            }

            h1 {
                font-size: 6.2vmin !important;
                font-weight: 600 !important;
            }
        }

        p {
            font-size: 4.8vmin !important;
        }

        h1 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h3 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h4 {
            font-size: 5vmin;
            line-height: 6.5vmin;
        }

        .redo-evaluation {
            width: 100%;

            i {
                margin-left: 7px;
                font-size: 5.5vmin !important;
            }
        }
    }
`

export const QuestionContent = styled.pre`
    white-space: pre-wrap;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;    
    color: #636060;    
`
