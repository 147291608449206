export function GetVimeoId(link: String): string {
    let id = "";
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;

    var match = link.match(regExp);

    if (match) {
        id = match[5];
    }
    return id;
}