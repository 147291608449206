import ApiInterface from "../interface/ApiInterface"

export default class CategoryServices {
    
    public async get() {
        return await ApiInterface.get('/Category/Get', {
        })
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getCategoryFilter(creator:boolean = false) {
        return await ApiInterface.get('/Category/GetCategoriesFilter?creator='+creator, {
        })
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}