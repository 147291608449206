import { useContext } from "react";
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
import { ApprenticeProgressStatusEnum } from "../../../../domain/enum/ApprenticeProgressStatusEnum";
import TaskFeedbackComponent from "./TaskFeedbackComponent";
import TaskNotSentComponent from "./TaskNotSentComponent";
import TaskSentComponent from "./TaskSentComponent";

export const TaskStatusComponent = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);

    switch (currentUserModuleContentProgress.progressStatusId) {
        case (ApprenticeProgressStatusEnum.InProgress): {
            return (
                <TaskNotSentComponent />
            );
        }
        case (ApprenticeProgressStatusEnum.Sent): {
            return (
                <TaskSentComponent />
            );
        }
        case (ApprenticeProgressStatusEnum.Done): {
            return (
                <TaskFeedbackComponent />
            );
        }
        default: {
            return (
                <TaskNotSentComponent />
            );
        }
    }
};
