import { Breadcrumb } from "react-bootstrap";

import styled from 'styled-components';

export const BreadCrumbContainer = styled.div`    

    ol {
        background-color: #fff;
    }

    .breadcrumb.item {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 1.94vmin;
        line-height: 2.3vmin;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
        font-size: 2.8vmin;
        line-height: 2vmin;
        color: #0C8292 !important;
        position: relative;
        top: 5px;
    }

    @media screen and (max-width: 990px){

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
            font-size: 6vmin;
            position: relative;
            top: 8px;
        }        
    }
`

export const FirstBreadCrumbItem = styled(Breadcrumb.Item)`
    a {
        color: #0C8292 !important;
    }
`