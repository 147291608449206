import { useState, useEffect, forwardRef, useRef, useImperativeHandle, useContext } from 'react';
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'
import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { RequiredFormMessage, StyledSearchableAsyncSelect } from "../CreateCourseStyles";
import Toggle from 'react-toggle'

import CourseStepEnum from '../../../../domain/enum/CourseStepEnum';
import ICreatableSelect from '../../../../domain/util/ICreatableSelect';
import { ICreateCourseFinalizationPayload } from '../../../../domain/payloads/createCourse/ICreateCourseFinalizationPayload'

import ModalCoursePublished from "../components/modals/ModalCoursePublished"
import { getProviderCode } from "../../../../util/UserUtil";
import CurrencyInput from 'react-currency-masked-input'
import CreatorServices from '../../../../services/CreatorServices'
import { IntegrationServices } from '../../../../services/IntegrationServices'
import { useForm } from 'react-hook-form';
import InputMask from "react-input-mask";

const creatorService = new CreatorServices();
const integrationServices = new IntegrationServices();

export const CreateCourseFinalizationForm = forwardRef((props, ref) => {

    const {
        course,
        isEdit,
        isLoading,
        isChanged,
        setUpdateDate,
        setIsChanged,
        setIsValid,
        setIsLoading,
        setNavigationStep
    } = useContext(CreateCourseContext);

    useImperativeHandle(ref, () => ({
        submit(isBacking: boolean, saveAsDraft: boolean, stepRender?: number) {
            onSubmit(isBacking, saveAsDraft, stepRender);
        }
    }));



    const fetchData = async () => {
        setValue('courseResponsible', course.courseResponsible);
    }

    const getPrice = ():string|void=>{
        if(course.price!== undefined){
            let stringPrice = course.price.toString()
            let priceTest = stringPrice.split('.');
            if(priceTest.length ===1){
                stringPrice = stringPrice.concat(',00')
            }
            if(priceTest.length ===2&& priceTest[1].length === 1){
                stringPrice = stringPrice.concat('0')
            }

            return stringPrice.replace('.',',');
        }
    }

    const [reload, callReload] = useState(true);
    const [showModalPublished, setShowModalPublished] = useState(false);

    const [toSomeClasses, setToSomeClasses] = useState<boolean>(false);
    const handleApprenticesChange = (event: any) => {
        setSelectedClasses([]);
        setToSomeClasses(event.target.checked);
        setIsChanged(true);
        callReload(!reload);        
    }

    const [isCertificateEnabled, setIsCertificateEnabled] = useState<boolean>(false);
    const onIsCertificateEnabled = (event: any) => {
        setIsChanged(true);
        setIsCertificateEnabled(event.target.checked);        
        callReload(!reload);
    }
    
    const [savedClasses, setSavedClasses] = useState<ICreatableSelect[]>([]) //classes persistas + novas classes adicionadas
    const [selectedClasses, setSelectedClasses] = useState<ICreatableSelect[]>([]) //classes persistidas em banco

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues
    } = useForm<ICreateCourseFinalizationPayload>({ mode: "onChange" });

    
    let courseResponsible = register("courseResponsible", { required: false, maxLength: 150 });
    let price = register("price", { required: false});

    const handleCourseResponsibleChange = (e: any) => {
        setValue("courseResponsible", e.target.value, { shouldValidate: true });
        setIsChanged(true);
        callReload(!reload);
    }

    const handlePriceChange = (e:any) => {
        setIsChanged(true);
        callReload(!reload);
    }

    //Class Handlers
    const handleClassesChange = (value: ICreatableSelect, actionMeta: any) => {
        switch (actionMeta.action) {
            case "select-option":
                if (actionMeta.option.label === "Selecionar todas") {
                    setSelectedClasses([]);
                    setSelectedClasses(savedClasses);
                }
                else {
                    let vector = selectedClasses;
                    vector = vector?.concat(actionMeta.option);
                    setSelectedClasses(vector);
                }
                break;
            case "remove-value":
                let vector = selectedClasses;
                vector = vector?.filter((selectedClass) => {
                    return selectedClass.value !== actionMeta.removedValue.value;
                });
                setSelectedClasses(vector);
                break;
        }

        setIsChanged(true);
        callReload(!reload);
    };

    const filterClassess = (inputValue: string, callback) => {
        let responseClass: ICreatableSelect[] = [];
            
        if (inputValue.length >= 3) {
            integrationServices.GetInstitutionGroupsByName(inputValue).then(response => {
                if (response.success && response.result !== null && response.result.length > 0) {
                    responseClass = response.result.map(cla => (
                        { value: cla.id, label: cla.name }
                    ));

                    let newClassVector = savedClasses.concat(responseClass);
                    setSavedClasses(newClassVector);
                    callback(responseClass);
                }
                else                
                    callback(responseClass);                
            });
        }
    };

    const promiseClassess = (inputValue: string) =>
        new Promise<ICreatableSelect[]>((resolve) => {
            if (inputValue.length >= 3) {
                filterClassess(inputValue, function (response) {                    
                    resolve(response);
                })
            }
    });
    
    useEffect(() => {
        setIsCertificateEnabled(course.enableCertificate);
        //setToCreators(course.creator);
        setToSomeClasses(course.courseLinkedClasses.length > 0);
        
        //concatena as turmas ja persistidas no curso a retorno da lista de busca
        if (course.apprentice && course.courseLinkedClasses.length > 0) {
            let courseLinkedClasses: ICreatableSelect[] = [];
            courseLinkedClasses = course.courseLinkedClasses.map(cla => (
                { value: cla.classId, label: cla.className }
            ));
            setSelectedClasses(courseLinkedClasses)
        }

        fetchData();


        callReload(!reload)
    }, []);    

    useEffect(() => {
        setIsValid(true);
        if (toSomeClasses) {
            setIsValid(toSomeClasses && selectedClasses.length > 0);
        }
    }, [reload]);

    const onSubmit = (isBacking: boolean, saveAsDraft: boolean, stepRender?: number) => {
        if (isChanged || (!isBacking && !isBacking && !(stepRender !== undefined))) {
            let priceInput:HTMLElement = document.getElementById('price')!;

        
            let value = Number.parseFloat(priceInput.getAttribute('value')!.replace(',','.'));
            let price = value;
            let courseReponsible = getValues('courseResponsible');

            let data: ICreateCourseFinalizationPayload = {
                courseId: course.id,
                isEditMode: isEdit || course.courseStepId >= CourseStepEnum.Finalization,
                saveAsDraft: saveAsDraft || isBacking || stepRender !== undefined,
                enableFaq: false,
                enableCertificate: isCertificateEnabled,
                apprentice: true, //toApprentices,
                creator: true, //toCreators,
                linkedClasses: selectedClasses.map(cla => (
                    { id: cla.value }
                ))
            }

            if(price !== undefined){
                data.price = price
            }
            if(courseReponsible!== undefined){
                data.courseResponsible = courseReponsible
            }
            

            setShowModalPublished(false);
            setIsLoading(true);
            creatorService.createCourseFinalization(data).then(function (response) {
                if (response.success) {
                    updateCourseObj(data);
                    setUpdateDate(response.result.updateDate);

                    setShowModalPublished(!(saveAsDraft || isBacking));
                    setIsChanged(false);
                    setIsLoading(false);

                    if (!saveAsDraft) {
                        setNavigationStep(stepRender ? stepRender : isBacking ? CourseStepEnum.Dependency : CourseStepEnum.Finalization);
                    }
                }
            }).catch(err => {
                setIsLoading(false);
            });

        }
        else {
            setNavigationStep(isBacking ? CourseStepEnum.Dependency : CourseStepEnum.Finalization);
        }
    };

    const updateCourseObj = (data: any) => {
        course.courseStepId = CourseStepEnum.Finalization
        course.enableFaq = data.enableFaq;
        course.enableCertificate = data.enableCertificate;
        course.apprentice = data.apprentice;
        course.price = data.price;
        course.courseResponsible = data.courseResponsible;
        course.creator = data.creator;
        course.courseLinkedClasses = selectedClasses.map(m => ({
            courseId: course.id,
            classId: m.value,
            className: m.label
        }))
    }

    const onReturnHome = () => {
        setShowModalPublished(false);
        window.location.href = "/";
    }

    return (        
        <Form id="step-form"
        autoComplete='off'>
        <Row>
            <Col md={10} className="mx-auto">
                <Row>
                    <Col>
                        <h1>
                            Filtrar distribuição (opcional)
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Deseja distribuir este curso para apenas alguma(s) turma(s)? Escreva o nome da(s) turma(s) e selecione quem você deseja que visualize este curso. <br />
                            <b>Caso não selecione nenhuma turma, o curso será distribuído para todos os usuários.</b>
                        </p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={"auto"} className={"pr-0"}>
                        <Toggle
                            icons={false}
                            checked={toSomeClasses}
                            onChange={handleApprenticesChange}
                        />
                    </Col>
                    <Col md={"auto"} className={"my-auto"}>
                        <label>Distribuir curso apenas para algumas turmas</label>
                    </Col>
                </Row>
                {toSomeClasses &&
                    <Row className="mt-2">
                        <Col>
                            <Form.Group className={toSomeClasses ? "" : "d-none"}>
                                <StyledSearchableAsyncSelect
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    placeholder="Digite o nome da(s) turma(s) para buscar e selecionar"
                                    isMulti                                    
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={promiseClassess}
                                    options={savedClasses}
                                    value={selectedClasses}
                                    onChange={handleClassesChange}
                                />

                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row className="mt-5">
                    <Col>
                        <h1>
                            Certificado de Conclusão (opcional)
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Por fim, deseja oferecer um Certificado de Conclusão para o seu curso? Nele estará o nome de quem o completou,
                            o nome do curso e a carga horária.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={"auto"} className={"pr-0"}>
                        <Toggle
                            icons={false}
                            checked={isCertificateEnabled}
                            onChange={onIsCertificateEnabled}
                        />
                    </Col>
                    <Col md={"auto"} className={"my-auto"}>
                        <label>Habilitar o Certificado de Conclusão</label>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <h1>
                            Informações de venda (opcional)
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            As informações abaixo só são necessárias caso você queira usar nossa plataforma para criar cursos para venda 
                        </p>
                    </Col>
                </Row>
                <Row className="mb-3">
                        <Col md={8}>
                            <Form.Label htmlFor="course-name">Responsável do curso</Form.Label>
                            <InputGroup className="mb-3">
                                <FormControl
                                    id="course-responsible-name"                                    
                                    autoComplete="off"
                                    placeholder="Este é o nome que aparecerá no campo responsável no site de vendas"
                                    aria-describedby="course-responsible-name"
                                    onChange={(e) => {
                                        handleCourseResponsibleChange(e)
                                    }}
                                    onBlur={courseResponsible.onBlur}
                                    ref={courseResponsible.ref} />
                            </InputGroup>
                            {errors.courseResponsible && errors.courseResponsible.type === "maxLength" && (
                                <RequiredFormMessage className="mt-2">Tamanho máximo do texto excedido.</RequiredFormMessage>
                            )}
                        </Col>
                        <Col md={4} >
                            <Form.Label htmlFor="workload">Preço do curso</Form.Label>
                            <InputGroup className="mb-3">                                
                                <CurrencyInput 
                                    autoComplete="off"
                                    id="price"
                                    separator={','}
                                    type="text"
                                    className="form-control"
                                    placeholder="Qual o preço de venda do curso?"                                    
                                    onBlur={price.onBlur}
                                    defaultValue={getPrice()}
                                    onChange={(e:any) => {
                                        handlePriceChange(e)
                                    }}
                                />
                            </InputGroup>  
                            {errors.price && errors.price.type === "required" && (
                                <RequiredFormMessage className="mt-2">Esse campo é obrigatório</RequiredFormMessage>
                            )}
                        </Col>
                    </Row>
            </Col>
        </Row>
        <ModalCoursePublished show={showModalPublished} onHide={() => onReturnHome()} />
    </Form>);
});