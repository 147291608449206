import LessonContentTypeEnum from "../../../../domain/enum/LessonContentTypeEnum";
import LessonContentModelView from "../../../../domain/models/course/module/lesson/LessonContentViewModel";
import VideoLessonContentViewModel from "../../../../domain/models/course/module/lesson/VideoLessonContentViewModel";
import { AttendTextLessonComponent } from "./AttendTextLessonComponent";
import styled from 'styled-components'
import AttendVideoContentComponent from "./AttendVideoContentComponent";
import AttendImageContentComponent from "./AttendImageContentComponent";
import ImageLessonContentViewModel from "../../../../domain/models/course/module/lesson/ImageLessonContentViewModel";
import AttendEmbedContentComponent from "./AttendEmbedContentComponent";
import AttendDocumentContentComponent from "./AttendDocumentContentComponent";
import DocumentLessonContentViewModel from "../../../../domain/models/course/module/lesson/DocumentLessonContentViewModel";

export const AttendLessonContentComponent = (props: AttendLessonContentComponentProps) => {
    switch (props.lessonContent.lessonContentTypeId) {
        case LessonContentTypeEnum.Text: {
            return (
                <AttendTextLessonComponent
                    key={props.lessonContent.order}
                    content={props.lessonContent.content} />
            );
        }
        case LessonContentTypeEnum.VideoExternal: {
            return (
                <AttendVideoContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                    videoLesson={JSON.parse(props.lessonContent.content) as VideoLessonContentViewModel}
                />
            );
        }
        case LessonContentTypeEnum.VideoUpload: {
            return (
                <AttendVideoContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                    videoLesson={JSON.parse(props.lessonContent.content) as VideoLessonContentViewModel}
                />
            );
        }
        case LessonContentTypeEnum.ImageExternal: {
            return (
                <AttendImageContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                    imageLesson={JSON.parse(props.lessonContent.content) as ImageLessonContentViewModel}
                />
            );
        }
        case LessonContentTypeEnum.ImageUpload: {
            return (
                <AttendImageContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                    imageLesson={JSON.parse(props.lessonContent.content) as ImageLessonContentViewModel}
                />
            );
        }
        case LessonContentTypeEnum.Embed: {
            return (
                <AttendEmbedContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                />
            );
        }
        case LessonContentTypeEnum.Document: {
            return (
                <AttendDocumentContentComponent
                    content={props.lessonContent.content}
                    key={props.lessonContent.order}
                    documentLesson={JSON.parse(props.lessonContent.content) as DocumentLessonContentViewModel}
                />
            );
        }
        default: {
            return (
                <></>
            );
        }
    }
};
export default AttendLessonContentComponent;

interface AttendLessonContentComponentProps {
    lessonContent: LessonContentModelView;
}

