import styled from 'styled-components';
import { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TertiaryGreenButton, TertiaryPurpleButton, TertiaryYellowButton } from '../Buttons/styles';
import { CreateCourseContext } from '../../context/creator/createCourse/CreateCourseContext'
import { AddModuleContext } from '../../context/creator/createCourse/AddModuleContext';

export const AddingModuleHeader = () => {

    const {
        module,
        onSubmit,
        validateModule,
        handleLessonContentFocusLose
    } = useContext(AddModuleContext)

    const {
        handleOnDeleteModule,
        isChanged
    } = useContext(CreateCourseContext)

    return (
        <AddingModuleHeaderStyles onClick={()=> handleLessonContentFocusLose()}>
            <Container fluid={true} className='h-100'>
                <Row className='h-100'>
                    <Col md={"auto"} className="my-auto">
                        <h4>
                            Criar Curso {'>'} Módulo
                        </h4>
                    </Col>
                    <Col md={"auto"} className="ml-auto">
                        <TertiaryPurpleButton 
                                onClick={() => onSubmit()}
                                disabled={!validateModule()||!isChanged}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi-pencil-square" />
                                </Col>
                                <Col md={"auto"} className="my-auto">
                                    Salvar Rascunho
                                </Col>
                            </Row>
                        </TertiaryPurpleButton>
                    </Col>
                    <Col md={"auto"}>
                        <DeleteModuleButton
                            onClick={()=>handleOnDeleteModule(module.order)} >
                            <Row>
                                <Col md="auto" className='pr-0 my-auto'>
                                    <i className='bi bi-trash' />
                                </Col>
                                <Col md="auto" className="my-auto">
                                    <p>
                                        Excluir módulo
                                    </p>
                                </Col>
                            </Row>
                        </DeleteModuleButton>
                    </Col>
                    <Col md={"auto"}>
                        <FinishAddModuleButton 
                         onClick={() => onSubmit(true)} 
                         disabled={!validateModule()} >
                            <Row>
                                <Col md="auto" className='pr-0 my-auto'>
                                    <p>
                                        Finalizar módulo
                                    </p>
                                </Col>
                                <Col md="auto" className='my-auto'>
                                    <i className='bi bi-check' />
                                </Col>  
                            </Row>
                        </FinishAddModuleButton>
                    </Col>
                </Row>
            </Container>
        </AddingModuleHeaderStyles>
    );
};

const DeleteModuleButton = styled(TertiaryYellowButton)`
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        margin-bottom:0px;
        font-size: 2.22vmin;
    }
    i{
        font-size:3vmin;
    }
`;

const FinishAddModuleButton = styled(TertiaryGreenButton)`
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        margin-bottom:0px;
        font-size: 2.22vmin;
    }
    i{
        font-size:3vmin;
    }
`;

const AddingModuleHeaderStyles = styled.header`
    z-index:100;
    @media(min-height: 900px) {
        min-height: 7vh;  
        button{
            min-height: 6.7vh;  
        }       
    }
    @media(max-height: 900px) {
        min-height: 8vh; 
        button{
            min-height: 8vh;  
        }         
    }
    @media(max-height: 800px) {
        min-height: 9vh;
        button{
            min-height: 9vh;  
        }          
    }  
    @media(max-height: 700px) {
        min-height: 10vh;   
        button{
            min-height: 10vh;  
        }       
    }       
    position:fixed;
    top:0px;
    width:100%;
    background: #F9F9F9  0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #514F4E33;
    opacity: 1;
    h4{
        cursor:default;
        font-size: 2.5vmin !important;
        color:#514F4E;
        position: relative;
        letter-spacing: 0px;
        opacity: 1;   
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 40vw; 
        overflow: hidden;   
        margin-bottom:0px;     
    }
    h5
    {        
        font-size: 2.3vmin !important;
        color: #817f7f;     
        position: relative;
        letter-spacing: 0px;
        opacity: 1;    
    }    
    button{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        margin-bottom:0px;
        i{
            font-size: 3.33vmin;
        }
    }
`;
