import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const UndecoratedLink = styled(Link)`
    :hover{
        text-decoration:none!important;
    }
`

export const TrilhasOrangePrimaryButton = styled(Button)`
    color: #FFF!important;
    background-color: #F27649!important;
    border-color: #F27649!important;
    border-radius:8px;
    box-shadow: 0px 3px 6px #F276494D;
    :hover:enabled {
        background-color: #fa6028!important;
        color: white!important;
    }
    :disabled{
        background-color: #E2E2E2!important;
        border:none;
    }
    :focus {
        box-shadow: 0 4px 4px #f276498a!important;
    }
`

export const TrilhasOrangeSecondaryButton = styled(Button)`
    color: #F27649!important;
    background-color: #FFF!important;
    border-color: #F27649!important;
    border-radius:8px;
    box-shadow: 0px 3px 6px #F276494D;
    :hover:enabled {
        color: #F27649!important;
        background-color: #FFF!important;
        border-color: #F27649!important;
        box-shadow: 0 4px 4px #f276498a!important;
    }
    :focus {
        box-shadow: 0 4px 4px #f276498a!important;
    }
`

export const TrilhasPurplePrimaryButton = styled(Button)`
    min-height:50px;
    background: #6468C8 0% 0% no-repeat padding-box!important;
    border-radius: 10px!important;
    opacity: 1!important;  
    text-align: center!important;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0px!important;
    color: #FFF!important;
    opacity: 1!important;
    border:none;
    :hover:enabled {
        background-color: #3d3f77!important;
        color: white!important;
    }
    :disabled {  
        background: #7c80f58e 0% 0% no-repeat padding-box!important;
    }
    :focus {
        box-shadow: 0 0 0 0.2rem #6a6eda41!important;
    }
`

export const TrilhasPurpleSecondaryButton = styled(Button)`
    min-height:50px;
    border: 1px solid #6468C8!important;
    border-radius: 8px!important;
    opacity: 1!important;
    text-align: center!important;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0px!important;
    color: #6468C8!important;
    opacity: 1!important;
    background-color: #FFF!important;
    :hover:enabled {
        background-color: #6a6eda41!important;
    }
    :active {
        background-color: #6a6eda41!important;
    }
    :focus {
        box-shadow: 0 0 0 0.2rem #6a6eda41!important;
    }
`

export const TransparentOkButton = styled(Button)`
    background-color: #FFF0!important;
    border: none;

    :hover {
        font-weight: 700!important;
    }
    :focus {
        box-shadow: none!important;
    }
    :active {
        box-shadow: none!important;
    }
    :disabled {
        background-color: #FFF0!important;
    }
`

export const TransparentCancelButton = styled(Button)`
    background-color:#FFF!important;
    border:none;
    :disabled{
        background-color: #FFF!important;
    }
    h5{
        color:#E50012;
        font-weight:500;
    }
`
