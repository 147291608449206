import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../context/creator/createCourse/AddModuleContext';
import { ClassLessonTypeButton, QuizLessonTypeButton, TaskLessonTypeButton } from '../AddingModulePageStyles';
import ModuleContentTypeEnum from "../../../../../../domain/enum/ModuleContentTypeEnum";

export const ChooseModuleContentType = () => {
    const {
        setModuleContentType,
        hasAssessment
    } = useContext(AddModuleContext);

    return (
        <>
            <Row>
                <Col className='mt-4 ml-3 pt-2'>
                    <Row className='mb-4'>
                        <Col>
                            <h3>
                                Que tipo de conteúdo você quer adicionar?
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ClassLessonTypeButton onClick={() => setModuleContentType(ModuleContentTypeEnum.Lesson)}>
                                <Row className='pt-1'>
                                    <Col md="auto">
                                        <i className='bi bi-file-text' />
                                    </Col>
                                    <Col md="auto" className='mt-auto pl-0'>
                                        <h3>
                                            Aula
                                        </h3>
                                    </Col>
                                </Row>
                                <Row className='pt-1'>
                                    <Col>
                                        <p>
                                            Pode conter textos, imagens, vídeos e links. São conteúdos para serem consumidos de forma mais passiva.
                                        </p>
                                    </Col>
                                </Row>
                            </ClassLessonTypeButton>
                        </Col>


                    </Row>
                    <Row>
                        <Col className='my-4'>
                            <TaskLessonTypeButton onClick={() => setModuleContentType(ModuleContentTypeEnum.Task)}>
                                <Row>
                                    <Col md="auto">
                                        <i className='bi bi-puzzle' />
                                    </Col>
                                    <Col md="auto" className='mt-2 pl-0'>
                                        <h3>
                                            Tarefa
                                        </h3>
                                    </Col>
                                </Row>
                                <Row className='pt-1'>
                                    <Col>
                                        <p>
                                            Permite que as pessoas enviem um arquivo para ser corrigido por você, de acordo com orientações que você poderá inserir.
                                        </p>
                                    </Col>
                                </Row>
                            </TaskLessonTypeButton>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <QuizLessonTypeButton onClick={() => setModuleContentType(ModuleContentTypeEnum.Assessment)} disabled={hasAssessment()}>
                                <Row>
                                    <Col md="auto">
                                        <i className='bi bi-list-ul' />
                                    </Col>
                                    <Col md="auto" className='mt-2 pl-0'>
                                        <h3>
                                            Avaliação
                                        </h3>
                                    </Col>
                                </Row>
                                <Row className='pt-1'>
                                    <Col>
                                        <p>
                                            Crie uma avaliação com uma ou mais perguntas objetivas. Você pode definir se a avaliação é obrigatória, e qual a nota mínima para aprovação na atividade.
                                        </p>
                                    </Col>
                                </Row>
                            </QuizLessonTypeButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
