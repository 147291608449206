import { Button, Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import ControlPanelActiveButtonEnum from "../../../domain/enum/ControlPanelActiveButtonEnum";
import ReportServices from "../../../services/ReportServices";
import AccountServices from "../../../services/AccountServices";
import LogService from "../../../services/LogService";
import { CardSectionStyles, InstitutionStyles, StyledSingleSelect } from "../ControlPanelPage";
import ReportByApprenticeListComponent from "../../../components/PaginatedSearch/ReportByApprenticeListComponent";
import { useEffect, useState } from "react";
import ICreatableSelect from "../../../domain/util/ICreatableSelect";
import { getInstitutionId, isAdminGlobal } from "../../../util/UserUtil";
import { LogUserActivityTypeReportEnum } from "../../../domain/enum/LogUserActivityTypeReportEnum";
import ReportByCourseListComponent from "../../../components/PaginatedSearch/ReportByCourseListComponent";
import ReportTabEnum from "../../../domain/enum/ReportTabEnum";
import ConsumptionByApprenticeViewModel from "../../../domain/models/report/ConsumptionByApprenticeViewModel";
import ConsumptionByCourseViewModel from "../../../domain/models/report/ConsumptionByCourseViewModel";
import PaginatedSearchResult from "../../../domain/util/PaginatedSearchResult";
import { getCurrentTime } from "../../../util/Formatter";


var reportService = new ReportServices();
var accountService = new AccountServices();
var logService = new LogService();

const initialState: PaginatedSearchResult<any> = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    totalRecords: 1,
    hasPrevious: true,
    hasNext: true,
    items: []
}

interface ReportsComponentProps {
}

const ReportsComponent = (props: ReportsComponentProps) => {
    const [institutions, setInstitutions] = useState<ICreatableSelect[]>([]);
    const [institutionId, setInstitutionId] = useState<string>(getInstitutionId());

    const [isLoading, setIsLoading] = useState(true);

    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [users, setUsers] = useState<ICreatableSelect[]>([]);

    const [isLoadingReportData, setLoadingReportData] = useState(true);
    const [dataAccessPerDay, setDataAccessPerDay] = useState<any[]>([]);
    const [typeReport, setTypeReport] = useState<LogUserActivityTypeReportEnum>(LogUserActivityTypeReportEnum.Last15Days)

    const [isLoadingCourseSearch, setIsLoadingCourseSearch] = useState(true);
    const [consumptionByCourseListViewPaginatedSearch, setConsumptionByCourseListViewPaginatedSearch] = useState<PaginatedSearchResult<ConsumptionByCourseViewModel>>(initialState)

    const [isLoadingApprenticeSearch, setIsLoadingApprenticeSearch] = useState(true);
    const [consumptionByApprenticeListViewPaginatedSearch, setConsumptionByApprenticeListViewPaginatedSearch] = useState<PaginatedSearchResult<ConsumptionByApprenticeViewModel>>(initialState)
    const [activeTab, setActiveTab] = useState<ReportTabEnum>(ReportTabEnum.Course)


    const handleInstitutionChange = (value: ICreatableSelect, actionMeta: any) => {
        setInstitutionId(value.value);
    };

    useEffect(() => {
        loadPublications();
        getTotalUserProfilesByApplication();
        getUserActivities();
    }, [institutionId]);

    useEffect(() => {
        getUserActivities();
    }, [typeReport]);

    useEffect(() => {
        loadReports();
    }, []);

    function loadReports() {
        loadListInstitution();
        loadPublications();
        getUserActivities();
    }


    function loadListInstitution() {
        accountService.getInstitutions().then(response => {
            let responseList: ICreatableSelect[] = [];
            if (response.success && response.result !== null && response.result.length > 0) {
                responseList = response.result.map(inst => (
                    { value: inst.id, label: inst.name }
                ));
                setInstitutions(responseList);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function loadPublications() {
        getTotalUserProfilesByApplication();
        getConsumptionByCourse(1);
        getConsumptionByApprentice(1);
    }

    function getTotalUserProfilesByApplication() {
        setIsLoadingUsers(true)
        accountService.getTotalUserProfilesByApplication(institutionId).then(response => {
            let responseList: ICreatableSelect[] = [];
            if (response.success && response.result !== null) {
                responseList = response.result.profiles.map(p => (
                    { value: p.qtd, label: p.profile }
                ));

                setTotalUsers(response.result.total);
                setUsers(responseList);
            }

        }).catch(err => {
            console.log(err)
        })
    }

    function getUserActivities() {
        setLoadingReportData(true)
        logService.getUserActivities(institutionId, typeReport).then(response => {
            if (response.success) {
                setDataAccessPerDay(response.result);
                setLoadingReportData(false)
            }
        }).catch(err => {
            setLoadingReportData(false)
            console.log(err)
        });
    }

    function getConsumptionByCourse(pageNumber: number) {
        setIsLoadingCourseSearch(true)
        reportService.getConsumptionByCourse({
            pageNumber: pageNumber,
            pageSize: 5,
            orderColumn: "",
            institutionId: institutionId
        }).then(response => {
            if (response.success) {
                setConsumptionByCourseListViewPaginatedSearch(response.result)
                setTimeout(() => {
                    setIsLoadingCourseSearch(false)
                    setIsLoading(false)
                }, 200)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function getConsumptionByApprentice(pageNumber: number) {
        setIsLoadingApprenticeSearch(true)
        reportService.getConsumptionByApprentice({
            pageNumber: pageNumber,
            pageSize: 5,
            orderColumn: "",
            institutionId: institutionId
        }).then(response => {
            if (response.success) {
                setConsumptionByApprenticeListViewPaginatedSearch(response.result)
                setTimeout(() => {
                    setIsLoadingApprenticeSearch(false)
                    setIsLoading(false)
                }, 200)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    function refreshData() {
        getConsumptionByCourse(1);
        getConsumptionByApprentice(1);
        getUserActivities();
    }

    function FilterUser(label: string) {
        const result = users?.find(obj => {
            return obj.label === label;
        });

        if (result !== undefined) {
            return result.value;
        }
        else
            return "";
    }

    const renderLineChart = () => {
        return (
            <ResponsiveContainer width="95%" height={300}>
                <LineChart data={isLoadingReportData ? [] : dataAccessPerDay}
                    margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                    <Line type="monotone" dataKey="total" stroke="#6a65c2" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    function ReportOptions() {

        //default option
        const [option, setOption] = useState("Últimos 15 dias");

        const handleChangeOption = (option: string) => {
            setOption(option);

            switch (option) {
                case "Últimos 15 dias":
                    setTypeReport(LogUserActivityTypeReportEnum.Last15Days);
                    break;
                case "Mensal":
                    setTypeReport(LogUserActivityTypeReportEnum.Monthly);
                    break;
                case "Anual":
                    setTypeReport(LogUserActivityTypeReportEnum.Yearly);
                    break;
            }
        }

        return (
            <Navbar>
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbar-report" />
                    <Navbar.Collapse id="navbar-report">
                        <Nav className="ml-auto">
                            <NavDropdown id="reportOption" title={option} className="dropdown-menu-right">
                                <NavDropdown.Item
                                    onClick={() => handleChangeOption("Últimos 15 dias")}>
                                    Últimos 15 dias</NavDropdown.Item>
                                <NavDropdown.Item onClick={(e) => handleChangeOption("Mensal")}>
                                    Mensal</NavDropdown.Item>
                                <NavDropdown.Item onClick={(e) => handleChangeOption("Anual")}>
                                    Anual</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }



    return (
        <Col>
            <InstitutionStyles>
                <Col>
                    <Row>
                        <Col md={2}>
                            <p>Instituição</p>
                        </Col>
                        <Col md={5}>
                            <StyledSingleSelect
                                className='react-select-container'
                                classNamePrefix="react-select"
                                placeholder="Selecione a Instituição"
                                cacheOptions
                                defaultOptions
                                options={institutions}
                                value={
                                    institutions.filter(option =>
                                        option.value === institutionId)
                                }
                                onChange={handleInstitutionChange}
                                isDisabled={!isAdminGlobal()}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md="auto" className="mx-auto">
                    <h1>
                        Dados Atualizados às {getCurrentTime()} &nbsp;&nbsp;
                        <i className="bi bi-arrow-repeat" title="Atualizar dados" onClick={() => refreshData()}></i>
                    </h1>
                </Col>
            </InstitutionStyles>

            <Row className={isLoading ? "d-none" : ""}>
                <CardSectionStyles>
                    <Row>
                        <h1>Quantidade de usuários</h1>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center text-center">
                            <img className="" src="/img/report/round_square_purple_user_icon.svg" alt="" />
                        </Col>
                        <Col>
                            <Row><p className="user_label">Total de usuários</p></Row>
                            <Row><p className="total_users">{totalUsers}</p></Row>
                        </Col>
                    </Row>
                    <Row className="mt-3 half-a-border-on-bottom"></Row>
                    <Row className="mt-3">
                        <Col>
                            <Row>
                                <Col md={3} className="d-flex justify-content-center text-center">
                                    <img className="" src="/img/report/round_square_white_user_purple_icon.svg" alt="" />
                                </Col>
                                <Col md={3}><p className="d-flex justify-content-center text-center user_counter">{FilterUser("Estudante")}</p></Col>
                                <Col md={6}><p className="text-right user_label">Aprendizes</p></Col>
                            </Row>
                            <Row>
                                <Col md={3} className="d-flex justify-content-center text-center">
                                    <img className="" src="/img/report/round_square_white_user_orange_icon.svg" alt="" />
                                </Col>
                                <Col md={3}><p className="d-flex justify-content-center text-center user_counter">{FilterUser("Professor")}</p></Col>
                                <Col md={6}><p className="text-right user_label">Criadores</p></Col>
                            </Row>
                            <Row>
                                <Col md={3} className="d-flex justify-content-center text-center">
                                    <img className="" src="/img/report/round_square_white_user_green_icon.svg" alt="" />
                                </Col>
                                <Col md={3}><p className="d-flex justify-content-center text-center user_counter">{FilterUser("Admin Global Trilhas") + FilterUser("Admin Trilhas")}</p></Col>
                                <Col md={6} className="ml-auto"><p className="text-right user_label">Administradores</p></Col>
                            </Row>
                        </Col>
                    </Row>
                </CardSectionStyles>
                <CardSectionStyles xs={8}>
                    <Row>
                        <Col><h1>Quantidade de acessos por dia</h1></Col>
                        <Col>
                            {ReportOptions()}
                        </Col>
                    </Row>
                    <Row>
                        {renderLineChart()}
                    </Row>
                </CardSectionStyles>
            </Row>

            <Row className={isLoading ? "d-none" : "mt-2 pt-3 pb-1 tab-buttons"}>
                <Col md="auto">
                    <Button className={"px-0 " + (activeTab === ReportTabEnum.Course ? "active" : "")}
                        onClick={() => setActiveTab(ReportTabEnum.Course)}>
                        Consumo por curso
                    </Button>
                </Col>
                <Col md="auto">
                    <Button className={"px-0 " + (activeTab === ReportTabEnum.People ? "active" : "")}
                        onClick={() => setActiveTab(ReportTabEnum.People)}>
                        Consumo por pessoa
                    </Button>
                </Col>
            </Row>
            {
                activeTab === ReportTabEnum.Course &&
                (
                    <Row className="mt-4">
                        {
                            !isLoading &&
                            <ReportByCourseListComponent
                                consumptionByCourseListViewPaginatedSearch={consumptionByCourseListViewPaginatedSearch}
                                isLoading={isLoadingCourseSearch}
                                getConsumptionByCourse={getConsumptionByCourse}
                            />
                        }
                    </Row>
                )
            }
            {
                activeTab == ReportTabEnum.People &&
                (
                    <Row className="mt-4">
                        {
                            !isLoading &&
                            <ReportByApprenticeListComponent
                                consumptionByApprenticeListViewPaginatedSearch={consumptionByApprenticeListViewPaginatedSearch}
                                isLoading={isLoadingApprenticeSearch}
                                getConsumptionByApprentice={getConsumptionByApprentice}
                            />
                        }
                    </Row>
                )
            }
        </Col>);
}

export default ReportsComponent;
