import { useContext } from 'react';
import { Col, Container, Modal, Row } from "react-bootstrap";
import { PrimaryGreenButton, SecondaryGreenButton } from '../../../Buttons/styles';
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddVideoModalContext } from '../context/AddVideoModalContext';

export const ModalFooter = () => {
    const {
        navigationPosition, handleAddFileButton, onSubmit, isUploading, isVideoValid,

    } = useContext(AddVideoModalContext);

    return (
        <Modal.Footer>
            {(navigationPosition !== AddFileNavigationPositionEnum.index && !isUploading) && (
                <Container>
                    <Row className="mb-2">
                        {navigationPosition === AddFileNavigationPositionEnum.sendFile ?
                            (
                                <Col md="auto">
                                    <SecondaryGreenButton onClick={handleAddFileButton} className="py-2 px-3">
                                        <Row>
                                            <Col md="auto" className="my-auto pr-0">
                                                <i className='bi bi-arrow-repeat' />
                                            </Col>
                                            <Col className="my-auto">
                                                <p>
                                                    Alterar Arquivo
                                                </p>
                                            </Col>
                                        </Row>
                                    </SecondaryGreenButton>
                                </Col>
                            )
                            :
                            (
                                <></>
                            )}

                        <Col md="auto" className="ml-auto">
                            {/*disabled se NÃO for valido*/}
                            <PrimaryGreenButton className="py-2 px-3" disabled={!isVideoValid()} onClick={() => onSubmit()}>
                                <Row>
                                    <Col className="my-auto">
                                        <p>
                                            Enviar Video
                                        </p>
                                    </Col>
                                    <Col md="auto" className="my-auto pl-0">
                                        <i className="bi bi-check" />
                                    </Col>
                                </Row>
                            </PrimaryGreenButton>
                        </Col>
                    </Row>
                </Container>
            )}
        </Modal.Footer>
    );
};
