import { useContext } from "react";
import { PrimaryPurpleButton } from "../../../../../components/Buttons/styles";
import { Modal, Row, Col, Container } from "react-bootstrap";
import { AttendCourseContext } from "../../../../../context/apprentice/attend/AttendCourseContext";
import styled from 'styled-components'

export const SuccessfullSentTask = () => {
    const {
        attendNextModuleContent
    } = useContext(AttendCourseContext);

    return (
        <>
            <HoldModal>
                <Modal.Body className="successfull-sent">
                    <Row className="my-3" style={{ placeContent: 'center' }}>
                        <img className="" src={"/img/student/home/woman-drink.png"} />
                    </Row>
                    <Row className="mb-3 mt-4" style={{ placeContent: 'center' }}>
                        <h2 style={{ color: '#218749' }}>
                            Tarefa enviada!
                        </h2>
                    </Row>
                    <Row className="my-2" style={{ textAlign: 'center' }}><Col><p>Tome uma água e continue o seu curso.</p></Col></Row>
                </Modal.Body>
                {/* Desktop */}
                <Modal.Footer className="d-none d-md-block d-lg-block">
                    <Row>                   
                        <Col md={12}>
                            <PrimaryPurpleButton
                                        onClick={() => attendNextModuleContent()}
                                    >
                                <Row>
                                    <Col md="auto" className="ml-auto my-auto">
                                        <p>
                                            Ir para a próxima aula
                                        </p>
                                    </Col>
                                    <Col md="auto" className="ml-0 mr-auto my-auto">
                                        <i className="bi bi-arrow-right"></i>
                                    </Col>
                                </Row>
                            </PrimaryPurpleButton>
                        </Col>                        
                    </Row>
                </Modal.Footer>
                {/* Mobile */}
                <Modal.Footer className="d-block d-md-none d-lg-none">
                    <Row>                   
                        <Col md={12}>
                            <PrimaryPurpleButton onClick={() => attendNextModuleContent()}>
                                Ir para a próxima aula
                                <i className="bi bi-arrow-right"></i>                                
                            </PrimaryPurpleButton>
                        </Col>                        
                    </Row>
                </Modal.Footer>
            </HoldModal>
        </>
    );
};

const HoldModal = styled(Col)`
    @media screen and (max-width: 990px){      

        img {
            max-height: 60vmin !important;
        }
        h2 {
            font-size: 6.3vmin !important;
        }

        p {
            font-size: 5.2vmin !important; 
        }
    }
`
