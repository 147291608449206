import { useContext } from "react";
import { AttendCourseContext } from '../../../context/apprentice/attend/AttendCourseContext'
import { formatWorkLoad, formatDateToDDMMYYYY } from '../../../util/Formatter'
import { Col, Row, Modal, Container } from "react-bootstrap";
import {
      CourseDescription
    , CourseIntroductionHeader
    , CourseIntroductionDescription
    , CourseCertificateTag
    , CourseResponsibleHeader
    , CourseResponsibleContent
    , CourseWorkLoadHeader
    , CourseWorkLoadContent
    , CoursePublisedDateHeader
    , CoursePublisedDateContent
} from '../info/CourseInfo/styles'
import { ModalCourseInfoStyles, ModalCourseInfoHeader } from "./AttentCoursePageStyles";
import { GetYoutubeId } from "../../../util/YoutubeUtils";
import { GetVimeoId } from "../../../util/VimeoUtils";

const ModalCourseInfo = (props: any) => {

    const { courseDetails, setShowModalCourseInfo } = useContext(AttendCourseContext);

    const returnVideoUrlSRC = (url:string):string => {

        if(url.includes("storage")) {
            return url;
        }else
        if(GetYoutubeId(url)!== ""){
            return "https://www.youtube.com/embed/".concat(GetYoutubeId(url))
        }
        else
            return "https://player.vimeo.com/video/".concat(GetVimeoId(url))

    }

    return (
        <ModalCourseInfoStyles {...props} centered size="lg" aria-labelledby="contained-modal-title-vcenter" >
            <ModalCourseInfoHeader>
                <Modal.Header closeButton>               
                    <Modal.Title id="contained-modal-title-vcenter">
                        Detalhes do curso           
                    </Modal.Title>              
                </Modal.Header>
            </ModalCourseInfoHeader>
            <Modal.Body className="show-grid">
                <Container fluid={true}>
                    <Row className='mt-2 pt-1'>
                        <Col>
                            <h1 className="course-title">{courseDetails.title}</h1>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <CourseDescription>{courseDetails.description}</CourseDescription>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Row className='mt-4'>
                                <Col className=''>
                                    <CourseResponsibleHeader>Responsável pelo Curso</CourseResponsibleHeader>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <CourseResponsibleContent>{courseDetails.responsibleUserCourse}</CourseResponsibleContent>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className='mt-4 pl-0'>
                                <Col>
                                    {courseDetails.enableCertificate ?
                                        (
                                        <CourseCertificateTag>
                                            <i className="bi bi-award"></i> Este curso oferece certificado!
                                        </CourseCertificateTag>
                                        )
                                        : (<></>)
                                    }
                                </Col>
                            </Row>                            
                        </Col>
                    </Row>

                    <Row className='mt-md-5 mt-5'>
                        <Col md={4}>                            
                            <section className="d-block d-md-none">
                                <CourseWorkLoadHeader>Carga Horária</CourseWorkLoadHeader>
                                <CourseWorkLoadContent>
                                    <i className="bi bi-clock"></i>
                                </CourseWorkLoadContent>
                                <CourseWorkLoadContent className="ml-2">
                                    {formatWorkLoad(courseDetails.workLoad)}
                                </CourseWorkLoadContent>
                            </section>
                            
                        </Col>
                        <Col md={4}>                            
                            <section className="d-block d-md-none">
                                <CoursePublisedDateHeader>Publicado em</CoursePublisedDateHeader>
                                <CoursePublisedDateContent>
                                    <i className="bi bi-calendar3"></i>
                                </CoursePublisedDateContent>
                                <CoursePublisedDateContent className="ml-2">
                                    {formatDateToDDMMYYYY(courseDetails.publishedDate)}
                                </CoursePublisedDateContent>
                            </section>
                        </Col>
                    </Row>
                    {/* Desktop */}
                    <section className="d-none d-md-block">
                        <Row className='mt-2'>
                            <Col md={6}>    
                                <CourseWorkLoadHeader>Carga Horária</CourseWorkLoadHeader>                       
                                <CourseWorkLoadContent>
                                    <i className="bi bi-clock"></i>
                                </CourseWorkLoadContent>
                                <CourseWorkLoadContent className="ml-2">
                                    {formatWorkLoad(courseDetails.workLoad)}
                                </CourseWorkLoadContent>
                            </Col>
                            <Col md={6}>  
                                <CoursePublisedDateHeader>Publicado em</CoursePublisedDateHeader>                             
                                <CoursePublisedDateContent>
                                    <i className="bi bi-calendar3"></i>
                                </CoursePublisedDateContent>
                                <CoursePublisedDateContent className="ml-2">
                                    {formatDateToDDMMYYYY(courseDetails.publishedDate)}
                                </CoursePublisedDateContent>
                            </Col>
                        </Row>
                    </section>

                    <Row className='mt-4 pt-2 mb-3'>
                        <Col>
                            <CourseIntroductionHeader>Introdução</CourseIntroductionHeader>
                        </Col>
                    </Row>
                    {
                    courseDetails.introductionVideoUrl!==null&&courseDetails.introductionVideoUrl!==""&&courseDetails.introductionVideoUrl!==undefined&&
                        (
                        <Row className='mb-md-5 mb-3'>
                            <Col md={10} className="mx-auto">
                                    <iframe src={returnVideoUrlSRC(courseDetails.introductionVideoUrl)}/>
                            </Col>
                        </Row>
                        )
                    }
                    <Row className='mt-2'>
                        <Col>
                            <CourseIntroductionDescription dangerouslySetInnerHTML={{ __html: courseDetails.presentation }} />
                        </Col>
                    </Row>
                    {
                        courseDetails.enableCertificate&&
                        (
                            <>
                                <Row className='mt-md-4 pt-2 mb-3'>
                                    <Col>
                                        <CourseIntroductionHeader>Certificados</CourseIntroductionHeader>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col>
                                        <CourseDescription>
                                            Alguns módulos do curso podem oferecer certificados. Ao completar todos os módulos, você também receber um certificado referente ao curso inteiro.
                                        </CourseDescription>
                                    </Col>
                                </Row> 
                            </>
                        )
                    }
                </Container>
            </Modal.Body>
        </ModalCourseInfoStyles>
    )
}

export default ModalCourseInfo;

