import styled from 'styled-components';

const FileViewer = ({ linkFile }) => {
    const typeFile = linkFile.split('.').pop();

    if (!linkFile) {
        return (
            <div>
                <h3>Visualizador de Arquivos</h3>
                <p>nenhum arquivo selecionado.</p>
            </div>
        );
    }

    return (<div>
        {typeFile.includes("pdf") || typeFile.includes("txt") ?
            (<div>
                {/* {typeFile.includes("pdf") && <DivStyleDisabledDownload />} */}
                <object
                    data={linkFile.concat('#toolbar=0&navpanes=0&scrollbar=0"')}
                />
            </div>)
            :
            (<>
                <DivStyleDisabledDownloadMicrosoft />
                <StyledFileViewer src={"https://view.officeapps.live.com/op/embed.aspx?src=" + linkFile} />
            </>
            )
        }
    </div>);
};

export default FileViewer;

export const StyledFileViewer = styled.iframe`
    border-radius: 5px;
    width: 100% !important;    
    height: 75vmin!important;
`

export const DivStyleDisabledDownloadMicrosoft = styled.div`
    display: flex;
    float: right;
    margin-right: 3px;
    height: 22px!important;
    width: 150px!important;
    top: -30px;
    position: inherit;
    z-index:9999;
`
export const DivStyleDisabledDownload = styled.div`
    height: 55px;
    width: 100%;   
    position: absolute;
    z-index:1;
    background-color:#f5f5f5;  
`