import React from "react"
import { Button, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import ControlPanelActiveButtonEnum from "../../domain/enum/ControlPanelActiveButtonEnum"
import { ManageCourseSideMenuStyles } from './styles'

interface ControlPanelSideMenuProps {
    activeButton: ControlPanelActiveButtonEnum
    setActivePage: (activePage: ControlPanelActiveButtonEnum) => void
    setIsLoading: (loading: boolean) => void
}

const ControlPanelSideMenu = (props: ControlPanelSideMenuProps) => {

    function activeReports() {
        window.location.href = "/controlpanel/reports"
        props.setIsLoading(true)
    }
    function activeCampaigns() {
        window.location.href = "/controlpanel/campaigns"
        props.setIsLoading(true)
    }

    return (
        <ManageCourseSideMenuStyles md={"auto"}>
            <Row className="mt-4 nav-buttons">
                <Col>
                    <ListGroup className="px-2">
                        <ListGroupItem className="px-0 py-0">
                            <Button className={props.activeButton === ControlPanelActiveButtonEnum.reports ? "active" : ""} onClick={activeReports}>
                                <Row>
                                    <Col md="auto" className="pr-0 py-2">
                                        <i className="bi bi-bar-chart" />
                                    </Col>
                                    <Col md="auto" className="my-auto">
                                        Relatórios
                                    </Col>
                                </Row>
                            </Button>
                        </ListGroupItem>
                    </ListGroup>
                    <ListGroup className="px-2 py-2">
                        <ListGroupItem className="px-0 py-0">
                            <Button className={props.activeButton === ControlPanelActiveButtonEnum.campaigns ? "active" : ""} onClick={activeCampaigns}>
                                <Row>
                                    <Col md="auto" className="pr-0 py-2">
                                    <i className="bi bi-tag"></i>
                                    </Col>
                                    <Col md="auto" className="my-auto">
                                        Campanhas
                                    </Col>
                                </Row>
                            </Button>
                        </ListGroupItem>
                    </ListGroup>
                </Col>
            </Row>
            <Row className="fixed-bottom">
                <Col md="auto" className="mx-auto mb-4">
                    <img className="" src="/img/report/woman_looking_report.svg" alt="" />
                </Col>
            </Row>
        </ManageCourseSideMenuStyles>
    )
}

export default ControlPanelSideMenu;
