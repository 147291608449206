import React from "react"
import { Col, Container, Row } from 'react-bootstrap';
import { StudentFooterCopyRights, StudentFooterLogo, StudentFooterStyles } from "./styles"

const BigBrainFooter = (): JSX.Element => (
    <StudentFooterStyles>
        <Container fluid={true} className="mt-2">
            <Row>
                <Col md={"auto"}>
                    <StudentFooterLogo src={"/img/logos/big-brain.svg"} />
                </Col>
                {/* <Col md={"auto"} className="my-auto">
                    <StudentFooterButtons>
                            Termos de Uso
                    </StudentFooterButtons>
                </Col>
                <Col md={"auto"} className="my-auto">
                    <StudentFooterButtons>
                            Política de Privacidade
                    </StudentFooterButtons>
                </Col>
                <Col md={"auto"} className="my-auto">
                    <StudentFooterButtons>
                            Portal Big Brain
                    </StudentFooterButtons>
                </Col> */}
                <Col md={"auto"} className="mt-4 pt-1 ml-auto mr-2">
                    <StudentFooterCopyRights>
                        Big Brain Education - {new Date().getFullYear()} ⓒ
                    </StudentFooterCopyRights>
                </Col>
            </Row>
        </Container>
    </StudentFooterStyles>
)

export default BigBrainFooter;


