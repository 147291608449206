import { Modal } from "react-bootstrap";
import styled from 'styled-components';

export const AddDocumentModalStyles = styled(Modal)`
    .img-row{
        height:45vh;
    }
    .modal-content{
        min-height:88vh;
        border-radius:1.11vmin;
        border:none;
    }
    .modal-header, .modal-footer{
        border:none;
    }
    .modal-header{
        .close{
            padding-top:1.2vmin;
            span{
                font-size:5vmin;
                font-weight: 400;
            }
        }
    }
    .modal-footer{
        button{
            min-height:6.8vmin;
            min-width: 18vmin;
            p{
                font-size:2.22vmin;
            }
            .bi-check{
                font-size:3vmin;
            }
            .bi-arrow-repeat{
                font-size:2.8vmin;
            }
            .bi-arrow-right{
                font-size:2.8vmin;
            }
            .bi-arrow-left{
                font-size:2.8vmin;
            }
        }
    }
    .modal-title{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 3.33vmin;
        line-height: 3.89vmin;
        color: #514F4E;
        span{
            color: #777575;
        }
    }
    .modal-content{
        input{
            min-height: 6.66vmin;
            border: 0.14vmin solid #B9B8B8;
            box-sizing: border-box;
            border-radius: 1.11vmin;
            &:focus{
                border: 0.14vmin solid #A996F6;
                box-sizing: border-box;
                box-shadow: 0px 0px 0.56vmin #A996F6;
            }
            ::placeholder{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2.22vmin;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #777575;
            }
        }
        iframe{
            border:none;
            width:60vmin;
            height:40vmin;

        }
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #636060;
            margin-bottom:0px;
        }
        .erro-message{
            background: #F9F9F9;
            border: 0.14vmin solid #F9DADA;
            box-sizing: border-box;
            border-radius: 0.56vmin;
            .bi-exclamation-octagon{
                font-size:3.3vmin;
                color:#D30808;
            }
        }
        .add-content{
            text-align:left;
            width:21.6vmin;
            height:14.44vmin; 
            box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2);
            border: 0.14vmin solid #F9F9F9;
            border-radius: 1.11vmin;
            background-color: #FFF;
            i{
                font-size:3.33vmin;
                color:#0C8292;
            }
            h3{
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 2.83vmin;
                line-height: 3.33vmin;
                color: #0C8292;
                margin-bottom:0px;
            }
            &:hover{
                background-color: #E1F4F7;
                border: 0.14vmin solid #F9F9F9;
            }
            &:focus, &:active{
                background-color: #d1f3f8!important;
                box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2)!important;
                border: none!important;
            }
            &:focus:after, &:active:after{
                content: '';
                position: absolute;
                top: -0.5vmin;
                left: 1.2vmin;
                bottom: -0.5vmin;
                right: 1.2vmin;
                border: 0.20vmin solid #0B717E!important;
                border-radius: 1.11vmin;
            }
        }
        img{
            height:30vmin; 
        }
        button{
            .bi-x{
                font-size: 3vmin;
            }
            p{
                font-size: 2.22vmin;

            }
        }
        object, iframe{
            width:100%;
            height:57vh;
        }
    }
`;

