import { useContext } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { PrimaryPurpleButton } from '../Buttons/styles';
import { ConfirmActionModalStyles } from './styles';
import { AddModuleContext } from '../../context/creator/createCourse/AddModuleContext';



export function InvalidTextContentPasteModal() {
    const {
        showInvalidTextPasteModal, setShowInvalidTextPasteModal
    } = useContext(AddModuleContext);

    return (
        <ConfirmActionModalStyles show={showInvalidTextPasteModal}>
            <Modal.Body>
                <Row className="my-3" style={{ placeContent: 'center' }}>
                    <i className="bi bi-exclamation-circle" style={{ fontSize: 80, color: '#C57700' }}></i>
                </Row>
                <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                    <Col>
                        <h1>
                            Ação inválida
                        </h1>
                    </Col>
                </Row>
                <Row className="my-2" style={{ textAlign: 'center' }}>
                    <Col>
                        Não é permitido colar imagens em conteúdos de texto, para isso, você deve usar o conteúdo de imagem
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer as={Row}>
                <Col className="pl-1">
                    <PrimaryPurpleButton onClick={() => setShowInvalidTextPasteModal(false)}>
                        <Row>
                            <Col md="auto" className="mx-auto">
                                <p>
                                    Entendi!
                                </p>
                            </Col>
                        </Row>
                    </PrimaryPurpleButton>
                </Col>
            </Modal.Footer>
        </ConfirmActionModalStyles>
    );
}
