import ModuleContentTypeEnum from "../domain/enum/ModuleContentTypeEnum";

export const contentTypeIcon = (lessonTypeId: ModuleContentTypeEnum) => {
    switch (lessonTypeId) {
        case ModuleContentTypeEnum.Lesson:
            return (<i className="fs-2 bi bi-file-earmark-richtext"></i>);
        case ModuleContentTypeEnum.Task:
            return (<i className='fs-2 bi bi-puzzle'></i>);
        case ModuleContentTypeEnum.Assessment:
            return (<i className="fs-2 bi bi-question-circle"></i>);      
        case ModuleContentTypeEnum.Certificate:
            return (<i className="fs-2 BI  bi-award"></i>);        
    }
}

export const contentTypeDescription = (lessonTypeId: ModuleContentTypeEnum, isRequired: boolean) => {
    switch (lessonTypeId) {
        case ModuleContentTypeEnum.Lesson:
            return "Aula";
        case ModuleContentTypeEnum.Task:
            return isRequired ? "Tarefa obrigatória" :  "Tarefa não obrigatória";
        case ModuleContentTypeEnum.Assessment:
            return isRequired ? "Avaliação obrigatória" :  "Avaliação não obrigatória";
        case ModuleContentTypeEnum.Certificate:
            return ("Certificado");   
    }
}