import { Button } from "react-bootstrap";
import styled from "styled-components"

export const PrimaryPurpleButton = styled(Button)`
height: 48px;
    background: #6468C8;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border:none!important;
    color: #FFFFFF;
    p{
        color: #FFFFFF!important;
        margin-bottom:0px;
    }

    i {
        color: #FFFFFF;
    }

    &:hover:not(:disabled){
        background-color: #3E408E;
    }
    &:active:not(:disabled){
        background-color: #3E408E!important;
    }
    &:focus:not(:disabled){
        background-color: #6468C8!important;
        box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:disabled{
        background-color:#B9B8B8;
        box-shadow:none;
    }

    @media screen and (max-width: 990px){        

        i {
            font-size: 4vmin !important;
            margin-right: 10px;
        }

        svg {
            margin-left: 10px;
        }

        p {
            font-size: 5vmin !important;  
        }      
    }
`

export const PrimaryGreenButton = styled(Button)`
    background: #218749;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border:none!important;

    color: #FFFFFF;
    p{
        color: #FFFFFF!important;
        margin-bottom:0px;
    }

    i {
        color: #FFFFFF;
    }

    &:hover:not(:disabled){
        background-color: #186C39;
    }
    &:active:not(:disabled){
        background-color: #186C39!important;
    }
    &:focus:not(:disabled){
        background-color: #218749!important;
        box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:disabled{
        background-color:#B9B8B8;
        box-shadow:none;
    }
`

export const PrimaryDarkGrayButton = styled(Button)`
    background: #514F4E;
    box-shadow:none!important;
    border-radius: 1.11vmin;
    border:none!important;

    color: #FFFFFF;
    p{
        color: #FFFFFF!important;
        margin-bottom:0px;
    }

    i {
        color: #FFFFFF;
    }

    &:hover:not(:disabled){
        background-color: #514F4E;
    }
    &:active:not(:disabled){
        background-color: #514F4E!important;
    }
    &:focus:not(:disabled){
        background-color: #514F4E!important;
        box-shadow:none!important;
    }
    &:disabled{
        background-color:#B9B8B8;
        box-shadow:none;
    }
`

export const SecondaryGreenButton = styled(Button)`
    background: #FFF;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border: 0.14vmin solid #0C8292!important;

    color: #0C8292;
    p{
        color: #0C8292!important;
        margin-bottom:0px;
    }
    i {
        color: #0C8292;
    }

    &:hover:not(:disabled){
        background: #0C8292;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:active:not(:disabled){
        background-color: #0C8292!important;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:focus:not(:disabled){
        background: #0C8292;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
        box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:disabled{
        color: #B9B8B8;
        background: #FFF;
        
        border: 0.14vmin solid #B9B8B8!important;
    p{
        color: #B9B8B8!important;
        margin-bottom:0px;
    }
    i {
        color: #B9B8B8;
    }
        box-shadow:none;
    }

    @media screen and (max-width: 990px){
        box-shadow: none !important;
        border: 0 !important;

        /* &.large {
            padding: 10px 20px !important;
            border-radius: 8px !important;

            i {
                font-size: 6vmin !important;
            }
        }

        &.small {
            width: 150px;
            padding: 2px 10px 6px 10px !important;
            border-radius: 8px !important;

            i {
                font-size: 6vmin !important;
            }
        } */

        i {
            font-size: 7vmin !important;
            margin-right: 10px;
        }
        p {
            font-size: 5vmin !important;  
        }  
        
        /* &:hover:not(:disabled) {
            background: #CCC !important;
            color: #0C8292 !important;
        }  */
        
    }
`

export const SecondaryDarkGrayButton = styled(Button)`
    background: #FFF;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border: 0.14vmin solid #0C8292!important;

    color: #0C8292;
    p{
        color: #0C8292!important;
        margin-bottom:0px;
    }
    i {
        color: #0C8292;
    }

    &:hover:not(:disabled){
        background: #0C8292;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:active:not(:disabled){
        background-color: #0C8292!important;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:focus:not(:disabled){
        background: #0C8292;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
        box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:disabled{
        color: #B9B8B8;
        background: #FFF;
        
        border: 0.14vmin solid #B9B8B8!important;
    p{
        color: #B9B8B8!important;
        margin-bottom:0px;
    }
    i {
        color: #B9B8B8;
    }
        box-shadow:none;
    }
`

export const SecondaryYellowButton = styled(Button)`
    background: #FFF;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border: 0.14vmin solid #C57700!important;

    color: #C57700;
    p{
        color: #C57700!important;
        margin-bottom:0px;
    }
    i {
        color: #C57700;
    }

    &:hover:not(:disabled){
        background: #C57700;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:active:not(:disabled){
        background-color: #C57700!important;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:focus:not(:disabled){
        background: #C57700;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
        box-shadow:#fff 0 0 0 0.42vmin, #C57700 0 0 0 0.56vmin!important;
    }
    &:disabled{
        color: #B9B8B8;
        border: 0.14vmin solid #B9B8B8!important;
    p{
        color: #B9B8B8!important;
        margin-bottom:0px;
    }
    i {
        color: #B9B8B8;
    }
        box-shadow:none;
    }
`

export const SecondaryRedButton = styled(Button)`
    background: #FFF;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border: 0.14vmin solid #D30808!important;

    color: #D30808;
    p{
        color: #D30808!important;
        margin-bottom:0px;
    }
    i {
        color: #D30808;
    }

    &:hover:not(:disabled){
        background: #D30808;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:active:not(:disabled){
        background-color: #D30808!important;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }
    &:focus:not(:disabled){
        background: #D30808;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
        box-shadow:#fff 0 0 0 0.42vmin, #D30808 0 0 0 0.56vmin!important;
    }
    &:disabled{
        color: #B9B8B8;
        border: 0.14vmin solid #B9B8B8!important;
    p{
        color: #B9B8B8!important;
        margin-bottom:0px;
    }
    i {
        color: #B9B8B8;
    }
        box-shadow:none;
    }
`

export const TertiaryPurpleButton = styled(Button)`
    background: inherit;
    box-shadow: none!important;
    border-radius: 1.11vmin;
    border: none!important;

    color: #6468C8;
    p{
        color: #6468C8!important;
        margin-bottom:0px;
    }
    i {
        color: #6468C8;
    }

    &:hover:not(:disabled){
        background: #E0E1F4;
        color: #6468C8;
        p{
            color: #6468C8;
        }
        i {
            color: #6468C8;
        }
    }
    &:active:not(:disabled){
        background-color: #E0E1F4!important;
        color: #6468C8!important;
        p{
            color: #6468C8;
        }
        i {
            color: #6468C8;
        }
    }
    &:focus:not(:disabled){
        background: #E0E1F4;
        color: #6468C8;
        p{
            color: #6468C8;
        }
        i {
            color: #6468C8;
        }
    }
    &:disabled{
        color: #B9B8B8;
        background-color: inherit!important;
        p{
            color: #B9B8B8;
        }
        i {
            color: #B9B8B8;
        }
    }    
`

export const TertiaryGreenButton = styled(Button)`
    background: ${props => props.active?'#E1F4F6!important':'inherit'} ;
    box-shadow: none!important;
    border-radius: 1.11vmin;
    border: none!important;

    color: #0B717E!important;
    p{
        color: #0B717E!important;
        margin-bottom:0px;
    }
    i {
        color: #0B717E!important;
    }
    .active{
        background-color: #E1F4F6!important;
        color: #0B717E!important;;
        p{
            color: #0B717E!important;;
        }
        i {
            color: #0B717E!important;;
        }
    }

    &:hover:not(:disabled){
        background: #E1F4F6;
        color: #0B717E;
        p{
            color: #0B717E;
        }
        i {
            color: #0B717E;
        }
    }
    &:active:not(:disabled){
        background-color: #E1F4F6!important;
        color: #0B717E;
        p{
            color: #0B717E;
        }
        i {
            color: #0B717E;
        }
    }
    &:focus:not(:disabled){
        background: #E1F4F6;
        color: #0B717E;
        p{
            color: #0B717E;
        }
        i {
            color: #0B717E;
        }
    }
    &:disabled{
        background:inherit!important;
        color: #B9B8B8!important;
        p{
            color: #B9B8B8;
        }
        i {
            color: #B9B8B8;
        }
    }
`

export const TertiaryYellowButton = styled(Button)`
    background: inherit;
    box-shadow: none!important;
    border-radius: 1.11vmin;
    border: none!important;

    color: #C57700;
    p{
        color: #C57700!important;
        margin-bottom:0px;
    }
    i {
        color: #C57700;
    }

    &:hover:not(:disabled){
        background: #FCE8C7;
        color: #C57700;
        p{
            color: #C57700;
        }
        i {
            color: #C57700;
        }
    }
    &:active:not(:disabled){
        background-color: #FCE8C7!important;
        color: #C57700!important;
        p{
            color: #C57700!important;
        }
        i {
            color: #C57700;
        }
    }
    &:focus:not(:disabled){
        background: #FCE8C7;
        color: #C57700;
        p{
            color: #C57700;
        }
        i {
            color: #C57700;
        }
    }
    &:disabled{
        color: #B9B8B8;
        p{
            color: #B9B8B8;
        }
        i {
            color: #B9B8B8;
        }
    }
`

export const TertiaryRedButton = styled(Button)`
    background: inherit;
    box-shadow: none!important;
    border-radius: 1.11vmin;
    border: none!important;

    color: #D30808;
    p{
        color: #D30808!important;
        margin-bottom:0px;
    }
    i {
        color: #D30808;
    }

    &:hover:not(:disabled){
        background: #F9DADA;
        color: #D30808;
        p{
            color: #D30808;
        }
        i {
            color: #D30808;
        }
    }
    &:active:not(:disabled){
        background-color: #F9DADA!important;
        color: #D30808!important;
        p{
            color: #D30808;
        }
        i {
            color: #D30808;
        }
    }
    &:focus:not(:disabled){
        background: #F9DADA;
        color: #D30808;
        p{
            color: #D30808;
        }
        i {
            color: #D30808;
        }
    }
    &:disabled{
        color: #B9B8B8;
        background-color: inherit!important;
        p{
            color: #B9B8B8;
        }
        i {
            color: #B9B8B8;
        }
    }  
`