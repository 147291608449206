import ApiInterface from "../interface/ApiInterface"
import IConsumptionByCoursePaginatedPayLoad from "../domain/payloads/report/IConsumptionByCoursePaginatedPayLoad";
import IConsumptionByApprenticePaginatedPayLoad from "../domain/payloads/report/IConsumptionByApprenticePaginatedPayLoad";

export default class ReportServices {

    public async getConsumptionByCourse(payload: IConsumptionByCoursePaginatedPayLoad) {
        return await ApiInterface.get('/report/getConsumptionByCourse'
            .concat('?pageNumber=').concat(payload.pageNumber.toString())
            .concat('&pageSize=').concat(payload.pageSize.toString())
            .concat('&orderColumn=').concat(payload.orderColumn)
            .concat('&institutionId=').concat(payload.institutionId)
        ).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getConsumptionByApprentice(payload: IConsumptionByApprenticePaginatedPayLoad) {
        return await ApiInterface.get('/report/getConsumptionByApprentice'
            .concat('?pageNumber=').concat(payload.pageNumber.toString())
            .concat('&pageSize=').concat(payload.pageSize.toString())
            .concat('&orderColumn=').concat(payload.orderColumn)
            .concat('&institutionId=').concat(payload.institutionId)
        ).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}
