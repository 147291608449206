import ApiInterface from "../interface/ApiInterface"
import { CourseCertificatePayload } from "../domain/payloads/apprentice/CourseCertificatePayload";
import IByMyLearningPaginatedPayLoad from "../domain/payloads/apprentice/IByMyLearningPaginatedPayLoad"
import IStartCoursePayload from "../domain/payloads/apprentice/IStartCoursePayload"
import IStartLessonPayload from "../domain/payloads/apprentice/IStartLessonPayload"
import IMarkLessonPayload from "../domain/payloads/apprentice/IMarkLessonPayload"
import IDeliveryTaskPayload from "../domain/payloads/apprentice/IDeliveryTaskPayload"
import { UsersCourseProgressView } from "../domain/models/course/UsersCourseProgressView";
import axios from "axios";
import ModuleCertificatePayload from "../domain/payloads/apprentice/ModuleCertificatePayload";
import CertificateDetailsViewModel from "../domain/models/course/CertificateDetailsViewModel";

const cancelTokenSource = axios.CancelToken.source();


export default class ApprenticeServices {

    
    public async getMyLearning(payload:IByMyLearningPaginatedPayLoad) {
        var endpoint = "/Apprentice/GetMyLearning"
            .concat("?pageNumber=").concat(payload.pageNumber.toString())
            .concat("&pageSize=").concat(payload.pageSize.toString())
            .concat("&enrollmentCourseStatus=").concat(payload.enrollmentCourseStatus.toString());

        return await ApiInterface.get(endpoint).then(response => {
            return response.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async startCourse(data: IStartCoursePayload): Promise<any> {
        return await ApiInterface.post('/Apprentice/StartCourse/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getMyCourseProgress(id:string):Promise<APIResponse<UsersCourseProgressView>> {
        let endpoint = "/Apprentice/GetMyCourseProgress?courseId=".concat(id);

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async startContent(data: IStartLessonPayload): Promise<any> {
        return await ApiInterface.post('/Apprentice/StartContent/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async markContentDone(data: IMarkLessonPayload): Promise<any> {
        return await ApiInterface.post('/Apprentice/MarkContentDone/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async deliveryTask(data: IDeliveryTaskPayload): Promise<any> {
        return await ApiInterface.post('/Apprentice/deliverytask/', data,{
            cancelToken:cancelTokenSource.token
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getMyCourseCertificateDetails(data: CourseCertificatePayload): Promise<APIResponse<GetCertificateViewModel>> {
        let endpoint = "/Apprentice/GetMyCourseCertificate?courseId="
            .concat(data.courseId)
            .concat("&apprenticeId=")
            .concat(data.apprenticeId);

        if(data.institutionId!== undefined){
            endpoint = endpoint.concat("&institutionId=").concat(data.institutionId);
        }

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async getMyModuleCertificateDetails(data: ModuleCertificatePayload): Promise<APIResponse<GetCertificateViewModel>> {
        let endpoint = "/Apprentice/GetModuleCertificate?courseId="
            .concat(data.courseId)
            .concat("&moduleId=")
            .concat(data.moduleId)
            .concat("&apprenticeId=")
            .concat(data.apprenticeId);

        if(data.institutionId!== undefined){
            endpoint = endpoint.concat("&institutionId=").concat(data.institutionId);
        }

        return await ApiInterface.get(endpoint).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }
}

export interface APIResponse<T> {
    success: boolean;
    result:  T;
    errors:  null;
} 

export interface GetCertificateViewModel {
    institutionName: string;
    holderName:  string;
    certificate:  string;
}