import { Col, ListGroup, Row } from 'react-bootstrap';
import ImageLessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/ImageLessonContentViewModel";
import LessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import VideoSourceEnum  from "../../../../../../../../domain/enum/VideoSourceEnum";
import HorizontalAlignEnum from "../../../../../../../../domain/enum/HorizontalAlignEnum";
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { TertiaryGreenButton, TertiaryYellowButton } from '../../../../../../../../components/Buttons/styles';
import { CreateCourseContext } from '../../../../../../../../context/creator/createCourse/CreateCourseContext';
import { GetYoutubeId } from '../../../../../../../../util/YoutubeUtils';
import { GetVimeoId } from '../../../../../../../../util/VimeoUtils';
import { AddModuleContext } from '../../../../../../../../context/creator/createCourse/AddModuleContext';
import { useCreateLessonModuleContentContext } from '../../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface ImageLessonContentComponentProps {
    lessonContent: LessonContentViewModel;
    isFocused: boolean;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    imageLesson:ImageLessonContentViewModel;
}

export const ImageLessonContentComponent = (props:ImageLessonContentComponentProps) => {

    const {
        reRender,
        setIsChanged
    } = useContext(CreateCourseContext)
    
    const {
        module,
        selectedModuleContentIndex,
        deleteLessonContent,
    } = useContext(AddModuleContext)

    const{
        selectedLessonContentIndex,
        editImageLessonContent
    } = useCreateLessonModuleContentContext()

    const onFocus = ()=>{
        props.setSelectedIndex(props.lessonContent.order)
    }

    const setAlignLeft = ()=>{
        let newVideoLesson:ImageLessonContentViewModel = {
            Align:HorizontalAlignEnum.Left,
            FullSize:props.imageLesson.FullSize,
            ImageObject:props.imageLesson.ImageObject,
            IsNew:props.imageLesson.IsNew
        }

        module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents[selectedLessonContentIndex].content = JSON.stringify(newVideoLesson);
        setIsChanged(true)
        reRender()
    }

    const setAlignCenter = ()=>{
        let newVideoLesson:ImageLessonContentViewModel = {
            Align:HorizontalAlignEnum.Center,
            FullSize:props.imageLesson.FullSize,
            ImageObject:props.imageLesson.ImageObject,
            IsNew:props.imageLesson.IsNew
        }

        module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents[selectedLessonContentIndex].content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()

    }
    const setAlignRight = ()=>{
        let newVideoLesson:ImageLessonContentViewModel = {
            Align:HorizontalAlignEnum.Right,
            FullSize:props.imageLesson.FullSize,
            ImageObject:props.imageLesson.ImageObject,
            IsNew:props.imageLesson.IsNew
        }
        module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents[selectedLessonContentIndex].content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()
    }

    const changeSize = ()=>{
        let newVideoLesson:ImageLessonContentViewModel = {
            Align:HorizontalAlignEnum.Center,
            FullSize:!props.imageLesson.FullSize,
            ImageObject:props.imageLesson.ImageObject,
            IsNew:props.imageLesson.IsNew
        }
        module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents[selectedLessonContentIndex].content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()
    }

    function getVideoAlign(): string {
        switch (props.imageLesson.Align) {
            case HorizontalAlignEnum.Center:
                return 'mx-auto';
            case HorizontalAlignEnum.Left:
                return 'mr-auto';
            case HorizontalAlignEnum.Right:
                return 'ml-auto';
            default:
                return '';
        }
    }    

    return (
        <ImageLessonContentComponentStyles 
            onClick={onFocus}
            fullSize={props.imageLesson.FullSize}
            isFocused={props.isFocused}
        >
            <Col
                className={"py-3 "+(props.isFocused?"on-focus":"")}
            >
                <Row>
                    <Col md={props.imageLesson.FullSize?11:6} className={getVideoAlign()}>
                        <img src={props.imageLesson.ImageObject.ImageUrl}/>
                    </Col>
                </Row>
                {
                    props.isFocused?
                    (
                        <Row className='mt-3'>
                            <Col md="auto" className='ml-3'>
                                <ListGroup horizontal>
                                    <ListGroup.Item>
                                        <TertiaryGreenButton onClick={editImageLessonContent}>
                                            <i
                                                className='bi bi-pencil'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <TertiaryGreenButton active={props.imageLesson.Align === HorizontalAlignEnum.Left} disabled={props.imageLesson.FullSize} onClick={setAlignLeft}>
                                            <i
                                                className='bi bi-text-left'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <TertiaryGreenButton active={props.imageLesson.Align === HorizontalAlignEnum.Center} disabled={props.imageLesson.FullSize} onClick={setAlignCenter}>
                                            <i
                                                className='bi bi-text-center'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>                           
                                        <TertiaryGreenButton active={props.imageLesson.Align === HorizontalAlignEnum.Right} disabled={props.imageLesson.FullSize} onClick={setAlignRight}>
                                            <i
                                                className='bi bi-text-right'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>                           
                                        <TertiaryGreenButton onClick={changeSize}>
                                            <i
                                                className={
                                                    props.imageLesson.FullSize?
                                                    'bi bi-arrows-angle-contract'
                                                    :
                                                    'bi bi-arrows-angle-expand'
                                                }
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col md="auto" className='ml-auto my-auto mr-2'>
                                <TertiaryYellowButton onClick={()=>deleteLessonContent(props.lessonContent.order)}>
                                    <i className='bi bi-trash'/>
                                </TertiaryYellowButton>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <></>
                    )
                }

            </Col>
        </ImageLessonContentComponentStyles>
    );
}


const ImageLessonContentComponentStyles = styled(Row)`
    background:${props => props.isFocused? "#F9F9F9":"inherit"};
    border:none!important;
    .list-group-horizontal{
        border-radius:1vmin!important;
        background-color:#E8E8E8;
        padding:none;
    }
    .list-group-item{
        padding:0px!important;
        border-radius:3vmin!important;
        border:none;
        background-color:#E8E8E8;
        i{
            font-size:3.5vmin;
        }

    }
    .list-group-item:first-of-type{
        border-right:0.11vmin solid #B9B8B8;
    }
    .list-group-item:last-of-type{
        border-left:0.11vmin solid #B9B8B8;
    }
    .bi.bi-trash{
        font-size:4vmin;
    }
    img{
        width:100%;
    }
`;
