export interface AddDocumentObject {
    Url: string;
    Name: string;
    ViewType: DocumentViewTypeEnum;
    /*Type: DocumentObjectTypeEnum;*/
    Type: string;
}

export enum DocumentViewTypeEnum{
    undefined = 0,
    minimized = 1,
    documentReader = 2
}