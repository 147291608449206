import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../context/creator/createCourse/AddModuleContext';
import InputMask from "react-input-mask";
import styled from 'styled-components'
import { TooltipContainer } from '../../../../../components/Tooltip/styles';

export const ModuleInfoForm = () => {

    const {
        module,
        handleModuleTitleChange,
        onHasCertificateChange,
        handleWorkloadChange,
        validateModuleInfo,
        validateModule,
        isWorkloadValid,
        
    } = useContext(AddModuleContext);

    return (
        <Col md={9}>
            <Row>
                <Col className='mt-4 mb-4'>
                    <Form.Label htmlFor='module-title'>
                        Título do Módulo
                    </Form.Label>
                    <InputGroup>
                        <FormControl
                            id="module-title"
                            placeholder="Digite o título do módulo"
                            autoComplete="off"
                            aria-describedby="module-title"
                            onChange={(e) => handleModuleTitleChange(e)}
                            value={module.title} />
                    </InputGroup>
                </Col>
            </Row>
            <Row className="py-4 mx-1">
                <Col className='my-auto pl-0'>
                    <Row>
                        <Col className='mb-3'>
                            <h4>
                                Certificado
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                Escolha se as pessoas vão receber um certificado ao fim deste módulo.
                            </h5>
                        </Col>
                    </Row>
                </Col>
                <Col md="2" className='mr-3 my-auto'>
                    <Row>
                        <Col md="auto">
                            <Toggle
                                icons={false}
                                checked={module.hasCertificate}
                                onChange={onHasCertificateChange} />
                        </Col>
                        <Col md={5} className="my-auto">
                            <p>
                                {module.hasCertificate ?
                                    "Ativado" : "Desativado"}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={5} className='mt-4'>
                    <Form.Label htmlFor='module-workload'>
                        Carga horária do módulo
                    </Form.Label>
                    <InputGroup>
                        <InputMask
                            autoComplete="off"
                            id="module-workload"
                            mask="99:99"
                            type="text"
                            className="form-control"
                            placeholder="Quantas horas são necessárias para completar o curso?"
                            value={module.workLoad}
                            onChange={(e: any) => {
                                handleWorkloadChange(e);
                            }} />
                    </InputGroup>
                </Col>
            </Row>
            {
                (module.workLoad === "" && module.hasCertificate) &&
                (
                    <Row>
                        <Col className="mx-2" md="auto">
                            <TooltipContainer className="mt-3">
                                <Row className="pl-2">
                                    <Col md={1}>
                                        <i className="bi bi-arrow-up"></i></Col>
                                    <Col md="auto">
                                        <p>A carga horária é obrigatória para módulos com certificado</p>
                                    </Col>
                                </Row>
                            </TooltipContainer>
                        </Col>
                    </Row>
                )
            }
            {
                ((validateModuleInfo() && module.moduleContents.length === 0) || validateModule()) &&
                (
                    <BottomRow>
                        <Col className="mx-2" md="auto">
                            <TooltipContainer className="mt-3">
                                <Row className="pl-2">
                                    <Col md={1}>
                                        <i className="bi bi-arrow-left"></i></Col>
                                    <Col md="auto">
                                        <p>Você já pode começar a adicionar seus conteúdos</p>
                                    </Col>
                                </Row>
                            </TooltipContainer>
                        </Col>
                    </BottomRow>
                )
            }

        </Col>
    );
};

export const BottomRow = styled(Row)`
    position: absolute;
    bottom: 3.47vmin;
`