import { useState } from "react"
import { Modal, Container, Button, Card, Col, ListGroup, ListGroupItem, Navbar, Row } from "react-bootstrap"
import CreatorTrilhaListView from '../../domain/models/course/CreatorTrilhaListView'
import CreatorCourseListActiveTabEnum from "../../domain/enum/CourseStatusEnum"
import CourseStatusEnum from "../../domain/enum/CourseStatusEnum"
import PaginatedSearchResult from "../../domain/util/PaginatedSearchResult"
import { formatDateToDDMMYYYY } from "../../util/Formatter"
import { StyledPagedSearchButtonList, CreatorCourseListComponentStyles } from "./styles"
import styled from 'styled-components'
import { PrimaryPurpleButton, SecondaryRedButton, SecondaryYellowButton } from "../Buttons/styles"

interface CreatorCourseListComponentProps {
    activeTab: CourseStatusEnum
    setActiveTab: React.Dispatch<React.SetStateAction<CourseStatusEnum>> 

    publishedCoursesListViewPaginatedSearch: PaginatedSearchResult<CreatorTrilhaListView>
    archivedCoursesListViewPaginatedSearch: PaginatedSearchResult<CreatorTrilhaListView>
    draftedCoursesListViewPaginatedSearch: PaginatedSearchResult<CreatorTrilhaListView>
    isLoading: boolean
    getCourseList: (pageNumber: number, courseStatusId: CourseStatusEnum) => void
    viewCourse: (courseId: string) => void
    editCourse: (courseId: string) => void
    archivedCourse: (courseId: string) => void
    deleteCourse: (courseId: string) => void
}

const CreatorCourseListComponent = (props: CreatorCourseListComponentProps) => {
    
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [modalArchiveShow, setModalArchiveShow] = useState(false);

    const getCourseList = (pageNumber: number) => {
        switch (props.activeTab) {
            case CourseStatusEnum.Published:
                props.getCourseList(pageNumber, CourseStatusEnum.Published);
                break;
            case CourseStatusEnum.Draft:
                props.getCourseList(pageNumber, CourseStatusEnum.Draft);
                break;
            case CourseStatusEnum.Archived:
                props.getCourseList(pageNumber, CourseStatusEnum.Archived);
                break;
            default:
                break;
        }
    }

    function loadPage(pageNumber: number) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        getCourseList(pageNumber)
    }

    const RenderPageButtons = (activeSearch: PaginatedSearchResult<any>): JSX.Element => {
        let buttons: JSX.Element[] = [
            <ListGroup.Item>
                <Button className={activeSearch.currentPage === 1 ? "active" : ""} onClick={() => loadPage(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for (let i = 2; i < activeSearch.totalPages; i++) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto " + (activeSearch.currentPage === i ? "active" : "")} onClick={() => loadPage(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }
        if (activeSearch.totalPages > 1) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={activeSearch.totalPages === activeSearch.currentPage ? "active" : ""} onClick={() => loadPage(activeSearch.totalPages)}>
                        <p>{activeSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )
        }

        return (
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasPrevious} onClick={() => loadPage(activeSearch.currentPage - 1)}>
                        <i className="bi bi-chevron-left" />
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasNext} onClick={() => loadPage(activeSearch.currentPage + 1)}>
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>
        )
    }

    const [courseIdToDelete, setCourseIdToDelete] = useState('');
    const internalDeleteCourse = (courseId: string) =>
    {
        setCourseIdToDelete(courseId);
        setModalDeleteShow(true);
    }
    
    const [courseIdToArchive, setCourseIdToArchive] = useState('');
    const internalArchiveCourse = (courseId: string) => {
        setCourseIdToArchive(courseId);
        setModalArchiveShow(true)
    }

    return (<>
        <CreatorCourseListComponentStyles className="ml-3">
            <Row className="mt-4">
                <Col>
                    <h1>
                        Publicações
                    </h1>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h5>
                        Visualize e edite os seus cursos ativos, finalize seus rascunhos e veja os cursos que você arquivou.
                    </h5>
                </Col>
            </Row>
            <Row className="mt-2 pt-3 pb-1 nav-buttons">
                <Col md="auto">
                    <Button className={"px-0 " + (props.activeTab === CreatorCourseListActiveTabEnum.Published ? "active" : "")} onClick={() => props.setActiveTab(CreatorCourseListActiveTabEnum.Published)}>
                        Cursos Ativos
                    </Button>
                </Col>
                <Col md="auto">
                    <Button className={"px-0 " + (props.activeTab === CreatorCourseListActiveTabEnum.Draft ? "active" : "")} onClick={() => props.setActiveTab(CreatorCourseListActiveTabEnum.Draft)}>
                        Rascunhos
                    </Button >
                </Col>
                <Col md="auto">
                    <Button className={"px-0 " + (props.activeTab === CreatorCourseListActiveTabEnum.Archived ? "active" : "")} onClick={() => props.setActiveTab(CreatorCourseListActiveTabEnum.Archived)}>
                        Cursos Arquivados
                    </Button>
                </Col>
            </Row>
            {
                props.activeTab === CreatorCourseListActiveTabEnum.Published &&
                (
                    <>
                        {
                            props.publishedCoursesListViewPaginatedSearch.totalRecords > 0 ?
                                (
                                    <>
                                        <Row className="mt-4">
                                            <Col>
                                                <h2>
                                                    {props.publishedCoursesListViewPaginatedSearch.totalRecords} curso(s) encontrado(s)
                                                </h2>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col md={5}>
                                                <p className="pl-3">
                                                    Curso
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Status
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Publicado em
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Opções
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={props.isLoading ? "d-none" : ""}>
                                            <Col>
                                                <ListGroup className="w-100 px-0">
                                                    {
                                                        props.publishedCoursesListViewPaginatedSearch.items.map(
                                                            item => {
                                                                return (
                                                                    <ListGroupItem className="px-0 py-2">
                                                                        <Card>
                                                                            <Card.Body className="py-2">
                                                                                <Row>
                                                                                    <Col md={5} className="my-auto">
                                                                                        <p>
                                                                                            {item.title}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <Row className="active-course">
                                                                                            <Col md="auto" className="my-auto pr-1">
                                                                                                <i className="bi bi-check-circle pr-2" />
                                                                                            </Col>
                                                                                            <Col className="my-auto pl-0">
                                                                                                <p >
                                                                                                    Ativo
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <p>
                                                                                            {formatDateToDDMMYYYY(item.publishedDate!)}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={3} className="my-auto">

                                                                                        <Button className="view-icon" onClick={() => props.viewCourse(item.id)} title="Visualizar curso">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-eye pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>

                                                                                        <Button className="edit-icon" onClick={() => props.editCourse(item.id)} title="Editar curso">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-pencil pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>

                                                                                        <Button className="archive-icon" onClick={() => internalArchiveCourse(item.id)} title="Arquivar curso">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-folder-symlink pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>

                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </ListGroupItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                        {
                                            props.publishedCoursesListViewPaginatedSearch.totalPages > 1 ?
                                                (
                                                    <Row className={props.isLoading ? "d-none" : ""}>
                                                        <Col md={"auto"} className="mx-auto my-4">
                                                            {
                                                                RenderPageButtons(props.publishedCoursesListViewPaginatedSearch)
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Row className="mt-5 empty-result">
                                            <Col md="auto" className="ml-auto">
                                                <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                                            </Col>
                                            <Col md="auto" className="my-auto mr-auto">
                                                <Row>
                                                    <Col>
                                                        <h1>
                                                            Nenhum curso por aqui!
                                                        </h1>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h3>
                                                            Quando você publicar um curso, ele aparecerá aqui.
                                                        </h3>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )
                        }
                    </>
                )

            }
            {
                props.activeTab === CreatorCourseListActiveTabEnum.Draft &&
                (
                    <>
                        {
                            props.draftedCoursesListViewPaginatedSearch.totalRecords > 0 ?
                                (
                                    <>
                                        <Row className="mt-4">
                                            <Col>
                                                <h2>
                                                    {props.draftedCoursesListViewPaginatedSearch.totalRecords} cursos encontrados
                                                </h2>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col md={5}>
                                                <p className="pl-3">
                                                    Curso
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Status
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Criado em
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Opções
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={props.isLoading ? "d-none" : ""}>
                                            <Col>
                                                <ListGroup className="w-100 px-0">
                                                    {
                                                        props.draftedCoursesListViewPaginatedSearch.items.map(
                                                            item => {
                                                                return (
                                                                    <ListGroupItem className="px-0 py-2">
                                                                        <Card>
                                                                            <Card.Body className="py-2">
                                                                                <Row>
                                                                                    <Col md={5} className="my-auto">
                                                                                        <p>
                                                                                            {item.title}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <Row className="draft-course">
                                                                                            <Col md="auto" className="my-auto pr-1">
                                                                                                <i className="bi bi-pencil-square pr-2" />

                                                                                            </Col>
                                                                                            <Col className="my-auto pl-0">
                                                                                                <p>
                                                                                                    Rascunho
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>

                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <p>
                                                                                            {formatDateToDDMMYYYY(item.creationDate!)}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={3} className="my-auto">

                                                                                        <Button className="edit-icon" onClick={() => props.editCourse(item.id)} title="Editar curso">
                                                                                            <Row>
                                                                                                <Col md="auto" className="my-auto pr-1">
                                                                                                    <i className="bi bi-pencil pr-2" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>
                                                                                        <Button className="archive-icon" onClick={() => internalArchiveCourse(item.id)} title="Arquivar curso">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-folder-symlink pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>
                                                                                        <Button className="delete-icon" onClick={() => internalDeleteCourse(item.id)} title="Excluir definitivamente">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-trash pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </ListGroupItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                        {
                                            props.draftedCoursesListViewPaginatedSearch.totalPages > 1 ?
                                                (
                                                    <Row className={props.isLoading ? "d-none" : ""}>
                                                        <Col md={"auto"} className="mx-auto my-4">
                                                            {
                                                                RenderPageButtons(props.draftedCoursesListViewPaginatedSearch)
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Row className="mt-5 empty-result">
                                            <Col md="auto" className="ml-auto">
                                                <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                                            </Col>
                                            <Col md="auto" className="my-auto mr-auto">
                                                <Row>
                                                    <Col>
                                                        <h1>
                                                            Nenhum curso por aqui!
                                                        </h1>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h3>
                                                            Quando você criar um rascunho, ele aparecerá aqui.
                                                        </h3>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )
                        }


                    </>
                )

            }
            {
                props.activeTab === CreatorCourseListActiveTabEnum.Archived &&
                (
                    <>
                        {
                            props.archivedCoursesListViewPaginatedSearch.totalRecords > 0 ?
                                (
                                    <>
                                        <Row className="mt-4">
                                            <Col>
                                                <h2>
                                                    {props.archivedCoursesListViewPaginatedSearch.totalRecords} cursos encontrados
                                                </h2>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col md={5}>
                                                <p className="pl-3">
                                                    Curso
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Status
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Arquivado em
                                                </p>
                                            </Col>
                                            <Col md={2}>
                                                <p>
                                                    Opções
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className={props.isLoading ? "d-none" : ""}>
                                            <Col>
                                                <ListGroup className="w-100 px-0">
                                                    {
                                                        props.archivedCoursesListViewPaginatedSearch.items.map(
                                                            item => {
                                                                return (
                                                                    <ListGroupItem className="px-0 py-2">
                                                                        <Card>
                                                                            <Card.Body className="py-2">
                                                                                <Row>
                                                                                    <Col md={5} className="my-auto">
                                                                                        <p>
                                                                                            {item.title}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <Row className="archive-course">
                                                                                            <Col md="auto" className="pr-0">
                                                                                                <i className="bi bi-folder-symlink pr-3" />
                                                                                            </Col>
                                                                                            <Col className="pl-0">
                                                                                                <p>
                                                                                                    Arquivado
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>

                                                                                    </Col>
                                                                                    <Col md={2} className="my-auto">
                                                                                        <p>
                                                                                            {formatDateToDDMMYYYY(item.updateDate!)}
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col md={3} className="my-auto">
                                                                                        <Button className="edit-icon" onClick={() => props.editCourse(item.id)} title="Editar curso">
                                                                                            <Row>
                                                                                                <Col md="auto" className="my-auto pr-1">
                                                                                                    <i className="bi bi-pencil pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>

                                                                                        <Button className="delete-icon" onClick={() => internalDeleteCourse(item.id)} title="Excluir definitivamente">
                                                                                            <Row>
                                                                                                <Col className="my-auto pr-0">
                                                                                                    <i className="bi bi-trash pr-3" />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </ListGroupItem>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                        {
                                            props.archivedCoursesListViewPaginatedSearch.totalPages > 1 ?
                                                (
                                                    <Row className={props.isLoading ? "d-none" : ""}>
                                                        <Col md={"auto"} className="mx-auto my-4">
                                                            {
                                                                RenderPageButtons(props.archivedCoursesListViewPaginatedSearch)
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Row className="mt-5 empty-result">
                                            <Col md="auto" className="ml-auto">
                                                <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                                            </Col>
                                            <Col md="auto" className="my-auto mr-auto">
                                                <Row>
                                                    <Col>
                                                        <h1>
                                                            Nenhum curso por aqui!
                                                        </h1>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h3>
                                                            Quando você criar um rascunho, ele aparecerá aqui.
                                                        </h3>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>
                                )
                        }
                    </>
                )

            }

        </CreatorCourseListComponentStyles>

        <ModalDeleteCourse
            show={modalDeleteShow}
            onHide={() => setModalDeleteShow(false)}
            deleteCourse={props.deleteCourse}
            courseId={courseIdToDelete}
        />

        <ModalArchiveCourse
            show={modalArchiveShow}
            onHide={() => setModalArchiveShow(false)}
            archivedCourse={props.archivedCourse}
            courseId={courseIdToArchive}
        />

    </>)
}

export default CreatorCourseListComponent;


function ModalDeleteCourse(props: any) {

    const deleteCourse = () => {
        props.deleteCourse(props.courseId)
    }

    return (
        <ConfirmDeleteCourse
            {...props}
            centered
        >
            <Modal.Header className="mx-3 mt-4">
                <Container>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <i className="bi-exclamation-circle" />
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Container className="px-0">
                    <Row>
                        <Col xs={12} md={12}>
                            <Modal.Title className="mt-4">Deseja excluir o curso definitivamente?</Modal.Title>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={12}>
                            <p>
                                Todas as informações e dados deste curso serão perdidos permanentemente.
                                Para manter os dados do curso e apenas deixá-lo inativo para consumo,
                                basta arquivá-lo.
                            </p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col xs={6} md={6} className="text-center">
                            <SecondaryRedButton onClick={() => deleteCourse()}>
                                <Row>
                                    <Col md="auto" className="my-auto pr-0 ml-auto">
                                        <i className="bi bi-trash pr-3" />
                                    </Col>
                                    <Col md="auto" className="my-auto mr-auto">
                                        <p>
                                            Excluir curso
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryRedButton>
                        </Col>
                        <Col xs={6} md={6} className="text-center">
                            <PrimaryPurpleButton className="px-4" onClick={props.onHide}>
                                Cancelar
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </ConfirmDeleteCourse>
    );
}

const ConfirmDeleteCourse = styled(Modal)`
    
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;

    .modal-content{
        border: 3px solid #A60303;
        border-radius: 8px;
        border-radius: 12px!important;
    }
    .modal-dialog{        
        max-width:64vmin;
    }
    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
        i{
            font-size:11.11vmin;
            color:#A60303;
        }
    }
    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }
    .modal-body{
        padding-top:0px!important;
        button{
            height:6.66vmin;
            width:100%;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
            i{
                font-size: 3vmin;
            }        
        }
        p{
            color:#514F4E;
            font-size: 2.22vmin;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;
        } 
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }

`

function ModalArchiveCourse(props: any) {

    const archiveCourse = () => {
        props.archivedCourse(props.courseId);
        props.onHide();
    }

    return (
        <ConfirmArchiveCourse
            {...props}
            centered
        >
            <Modal.Header className="mx-3 mt-4">
                <Container>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <i className="bi-exclamation-circle" />
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Container className="px-0">
                    <Row>
                        <Col xs={12} md={12}>
                            <Modal.Title className="mt-4">Deseja arquivar o curso?</Modal.Title>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={12}>
                            <p>
                                O curso não ficará mais disponível para consumo, porém os dados não serão perdidos
                                e você podera republicar este curso no futuro.
                            </p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col xs={6} md={6} className="text-center">
                            <SecondaryYellowButton onClick={() => archiveCourse()}>
                                <Row>
                                    <Col md="auto" className="my-auto pr-0 ml-auto">
                                        <i className="bi bi-folder-symlink pr-3" />
                                    </Col>
                                    <Col md="auto" className="my-auto mr-auto">
                                        <p>
                                            Arquivar curso
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryYellowButton>
                        </Col>
                        <Col xs={6} md={6} className="text-center">
                            <PrimaryPurpleButton className="px-4" onClick={props.onHide}>
                                Cancelar
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </ConfirmArchiveCourse>
    );
}

const ConfirmArchiveCourse = styled(Modal)`
    
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;

    .modal-content{
        border: 3px solid #C57700;
        border-radius: 8px;
        border-radius: 12px!important;
    }
    .modal-dialog{
        
        max-width:64vmin;
    }
    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
        i{
            font-size:11.11vmin;
            color:#C57700;
        }
    }
    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }
    .modal-body{
        padding-top:0px!important;
        button{
            height:6.66vmin;
            width:100%;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
            i{
                font-size: 3vmin;
            }        }
        p{
            color:#514F4E;
            font-size: 2.22vmin;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;
        } 
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }

`