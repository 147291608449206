import { useContext } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddDocumentModalContext } from '../context/AddDocumentModalContext';
import { DocumentViewTypeEnum } from '../context/AddDocumentObject';

export function ChooseViewTypeScreen() {
    const {
        setNavigationPosition,
        onSubmit
    } = useContext(AddDocumentModalContext);
    return <>
        <Row className="py-2">
            <Col>
                <Button className='w-100 add-content px-3' onClick={() => onSubmit(DocumentViewTypeEnum.minimized)}>
                    <Row className="mb-2">
                        <Col md="auto" className="my-auto pr-0">
                            <i className="bi bi-box-arrow-up-right" />
                        </Col>
                        <Col className="my-auto">
                            <h3>
                                Exibir um atalho
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                As pessoas poderão clicar no atalho e exibir o documento completo em outra aba do navegador.
                            </p>
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
        <Row className="py-2">
            <Col>
                <Button className='w-100 add-content px-3' onClick={() => onSubmit(DocumentViewTypeEnum.documentReader)}>
                    <Row className="mb-2">
                        <Col md="auto" className="my-auto pr-0">
                            <i className="bi bi-arrows-fullscreen" />
                        </Col>
                        <Col className="my-auto">
                            <h3>
                                Exibir documento completo
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                O documento será exibido no próprio espaço da aula, respeitando a largura máxima do conteúdo.
                            </p>
                        </Col>
                    </Row>
                </Button>
            </Col>
        </Row>
    </>;
}
