import ApiInterface from "../interface/ApiInterface"
import ICreateCourseInitialDataPayload from "../domain/payloads/createCourse/ICreateCourseInitialDataPayload"
import ICreateCourseIntroductionPayload from "../domain/payloads/createCourse/ICreateCourseIntroductionPayload"
import { ICreateCourseContentPayload } from "../domain/payloads/createCourse/ICreateCourseContentPayload"
import ICreateCourseDependencyPayload from "../domain/payloads/createCourse/ICreateCourseDependencyPayload"
import { ICreateCourseQuizPayload } from "../domain/payloads/createCourse/ICreateCourseQuizPayload"
import { ICreateCourseFinalizationPayload } from "../domain/payloads/createCourse/ICreateCourseFinalizationPayload"
import ModuleViewModel from "../domain/models/course/module/ModuleViewModel"

export default class CreatorServices {

    public async createCourseInitialData(data: ICreateCourseInitialDataPayload): Promise<any> {
        return await ApiInterface.post('/Creator/CreateCourseInitialData/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async createCourseIntroduction(data: ICreateCourseIntroductionPayload): Promise<any> {
        return await ApiInterface.post('/Creator/CreateCourseIntroduction/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async createCourseContent(data: ICreateCourseContentPayload): Promise<ApiResponse<CourseStepViewModel>> {
        return await ApiInterface.post('/Creator/CreateCourseContent/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async createCourseDependency(data: ICreateCourseDependencyPayload): Promise<any> {
        return await ApiInterface.post('/Creator/CreateCourseDependency/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async createCourseQuiz(data: ICreateCourseQuizPayload): Promise<any> {
        return await ApiInterface.post('/Creator/CreateCourseQuiz/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async createCourseFinalization(data: ICreateCourseFinalizationPayload): Promise<any> {
        return await ApiInterface.post('/Creator/CreateCourseFinalization/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getCreatorsResume(): Promise<any> {
        return await ApiInterface.get('/Creator/GetCreatorsResume', {
        })
    }

    public async getCourseInitialData(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseInitialData', {
        })
    }

    public async getCourseIntroduction(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseIntroduction', {
        })
    }

    public async getCourseContent(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseContent', {
        })
    }

    public async getCourseDependency(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseDependency', {
        })
    }

    public async getCourseQuiz(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseQuiz', {
        })
    }

    public async getCourseFinalization(courseId: string): Promise<any> {
        return await ApiInterface.get('/Creator/' + courseId + '/GetCourseFinalization').then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async archivedCourse(courseId: string): Promise<any> {
        return await ApiInterface.put('/Creator/' + courseId + '/ArchivedCourse/').then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async restoreCourse(courseId: string): Promise<any> {
        return await ApiInterface.put('/Creator/' + courseId + '/RestoreCourse').then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async deleteCourse(courseId: string): Promise<any> {
        return await ApiInterface.delete('/Creator/' + courseId + '/deleteCourse').then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getCourseByStatusPaginated(pageNumber: number, pageSize: number, statusId: number) {
        return await ApiInterface.get('/Creator/GetCoursesByStatusPaginated?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&StatusId=' + statusId + "&orderColumn=", {
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}

interface ApiResponse<T> {
    success: boolean,
    result: T,
    erros: ApiError[]
}

interface ApiError {
    code: string,
    message: string
}

interface CourseStepViewModel {
    id: string,
    title: string,
    coverImage: string,
    courseStepId: number,
    updateDate: string,
    introductionVideoUrl: string,
    introductionVideoName: string,
    modules: ModuleViewModel[]
}