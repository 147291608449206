import { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { PrimaryDarkGrayButton, PrimaryPurpleButton, SecondaryGreenButton, TertiaryPurpleButton, TertiaryRedButton } from "../../../../../components/Buttons/styles";
import { AttendCourseContext } from "../../../../../context/apprentice/attend/AttendCourseContext";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import styled from 'styled-components'

export const SendingTaskModal = () => {
    const {
    } = useContext(AttendCourseContext);
    
    const {        
        handleAddFileButton,
        handleCancelUpload
    } = useContext(TaskContext);

    return (
        <>
            <HoldModal>
                <Modal.Body className="choose-task-file pt-4">
                    <Row className="d-none d-md-block">
                        <Col md="auto" className="text-center mx-auto my-4">
                            <img src={"/img/attendCourse/task/running-woman.svg"}/>
                        </Col>
                    </Row>
                    <Row className="d-block d-md-none">
                        <Col>
                            <img src={"/img/attendCourse/task/running-woman.svg"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto" className="mx-auto py-1">
                            <h1>
                                Estamos enviando seu arquivo...
                            </h1>
                        </Col>
                    </Row>
                </Modal.Body>
                 {/* Desktop */}
                <Modal.Footer className="d-none d-md-block d-lg-block">
                    <Row>                   
                        <Col md={5}>
                            <TertiaryRedButton onClick={() => handleCancelUpload()}>
                                <Row>
                                    <Col md="auto" className="mx-auto py-1">
                                        <p>
                                            Cancelar envio
                                        </p>
                                    </Col>
                                </Row>
                            </TertiaryRedButton>
                        </Col>
                        <Col md={7}>
                            <PrimaryDarkGrayButton>
                                <Row>
                                    <Col md="auto" className="ml-auto my-auto pr-0">
                                        <p>
                                            Enviando Arquivo
                                        </p>
                                    </Col>
                                    <Col md="auto" className="mr-auto">
                                        <Loader  
                                            type="TailSpin"
                                            color="#2ABE64"
                                            height={25}
                                            width={25}
                                        />
                                    </Col>
                                </Row>
                            </PrimaryDarkGrayButton>
                        </Col>
                    </Row>
                </Modal.Footer>
                {/* Mobile */}
                <Modal.Footer as={Row} className="d-block d-md-none d-lg-none">  
                    <Col>                    
                        <PrimaryDarkGrayButton>
                            <Row>                                
                                <Col className="pl-3" xs={8}>
                                    Enviando Arquivo                                    
                                </Col>
                                <Col xs={4}>
                                    <Loader  
                                        type="TailSpin"
                                        color="#2ABE64"
                                        height={25}
                                        width={25}
                                    />
                                </Col>
                            </Row>
                        
                        </PrimaryDarkGrayButton>
                        <TertiaryRedButton className="mt-2" onClick={() => handleCancelUpload()}>
                            Cancelar envio
                        </TertiaryRedButton>
                        </Col>
                    </Modal.Footer> 
            </HoldModal>
        </>
    );
};

const HoldModal = styled(Col)`

    @media screen and (max-width: 990px){
        img {
            margin: auto !important;
            width: 100% !important;
        }

        h1 {
            font-size: 5vmin !important;
            margin: 20px auto !important;
            text-align: center;
        }
    }
`
