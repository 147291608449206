import { useContext } from "react";

//Components
import { ApprenticeProgressStatusEnum } from "../../../../domain/enum/ApprenticeProgressStatusEnum";
import AssessmentStartPage from "./AssessmentStartPage";
import AssessmentFeedbackPageContent from './AssessmentFeedbackPageContent'

//Context
import { AttendCourseContext } from '../../../../context/apprentice/attend/AttendCourseContext';
import { AssessmentContextProvider } from '../../../../context/apprentice/attend/AssessmentContext';

export const AttendAssessmentComponent = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);
    
    switch(currentUserModuleContentProgress.progressStatusId) {
        case ApprenticeProgressStatusEnum.Done:
        case ApprenticeProgressStatusEnum.Sent:
            {
            return (                
                <AssessmentContextProvider>
                    <AssessmentFeedbackPageContent
                        isFullScreen={false} />
                </AssessmentContextProvider>
            )
        }
        case ApprenticeProgressStatusEnum.InProgress:
        case ApprenticeProgressStatusEnum.Required:
        case ApprenticeProgressStatusEnum.NotRequired:
        default: {
            return ( <AssessmentStartPage /> )
        }
    }
};