import React from "react";
import { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { PrimaryPurpleButton } from "../../../../../components/Buttons/styles";
import { AttendCourseContext } from "../../../../../context/apprentice/attend/AttendCourseContext";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import styled from 'styled-components'

export const ChooseFileModal = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);

    const { 
            hiddenFileInput,
            onInputFileChange,
            handleAddFileButton
        } = useContext(TaskContext);

    return (
        <>
            <HoldModal>
            <Modal.Header closeButton>
                <h1>Envio de Tarefa</h1>
            </Modal.Header>
            <Modal.Body className="choose-task-file">
                <Row>
                    <Col>
                        <h3>
                            Tarefa
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>
                            {currentUserModuleContentProgress.title}
                        </h2>
                    </Col>
                </Row>
                <Row className="choose-task-msg">
                    <Col xs={2} md="auto" className="pr-0">
                        <i className="bi bi-exclamation-circle" />
                    </Col>
                    <Col xs={10}>
                        <h2>
                            Não saia da janela até ver a confirmação do envio aquii
                        </h2>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="choose-task-file-footer">
                <PrimaryPurpleButton onClick={()=>handleAddFileButton()} className="d-none d-md-block">
                    <Row>
                        <Col md="auto" className="ml-auto pr-0">
                            <i className="bi bi-files" />
                        </Col>
                        <Col md="auto" className="mr-auto">
                                Escolher Arquivo
                        </Col>
                    </Row>
                </PrimaryPurpleButton>
                <PrimaryPurpleButton className="d-block d-md-none" onClick={()=>handleAddFileButton()}>                                
                    <i className="bi bi-upload"/>
                    Escolher Arquivo                 
                </PrimaryPurpleButton>
                
            </Modal.Footer>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={onInputFileChange}
                className="d-none"
                multiple={false} />
            </HoldModal>
        </>
    );
};

const HoldModal = styled(Col)`        

    @media screen and (max-width: 990px){
        .close {
            span {
                font-size: 10vmin !important;
                position: absolute;
                top: 10px !important;
                right: 15px !important;
            }
        }
       .modal-header {
            h1 {
                font-size: 6.3vmin !important;
            }
       }

        h3 {
            font-size: 5vmin !important;
            margin-top: 10px;
        }

        h2 {
            font-size: 5vmin !important;
            margin-top: 10px;
        }

        .choose-task-file {
            i {
                font-size: 8vmin !important;
            }
        }

        .choose-task-file-footer { 
            .col {
                padding: 0 !important;
            }
        }
    }
`
