import ApiInterface from "../interface/ApiInterface"


export default class AccountServices {
    
    public async getInstitutions() {
        return await ApiInterface.get("/Accounts/GetInstitutions").then(response => {
            return response.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getTotalUserProfilesByApplication(institutionId: string): Promise<APIResponse<IUserTest>> {
        return await ApiInterface.get("/Accounts/GetTotalUserProfilesByApplication"
            .concat("?institutionId=").concat(institutionId)
        ).then(response => {
            return response.data;
        }).catch(err => {
            console.log(err);
        })
    }

}

interface APIResponse<T> {
    success: boolean;
    result: T;
    errors: null;
}

interface IUserTest {
    total: number,
    profiles: any[]
}