import { useContext } from 'react';
import { Card, Modal } from "react-bootstrap";
import { AddDocumentModalStyles as AddDocumentModalStyles } from './AddDocumentModalStyles';
import { ConfirmExitAddMediaContentModal } from '../ConfirmExitAddMediaContentModal';
import { AddDocumentModalContext, AddDocumentModalContextProvider } from './context/AddDocumentModalContext';
import { AddDocumentObject } from "./context/AddDocumentObject";
import { AddDocumentModalFooter as AddDocumentModalFooter } from './footer/AddDocumentModalFooter';
import { AddImageModalBody as AddImageModalBody } from './body/AddImageModalBody';
import { AddDocumentModalTitle as AddDocumentModalTitle } from './header/AddDocumentModalTitle';

export const AddDocumentModalComponent = (props: AddDocumentModalComponentProps) => {
    return (
        <AddDocumentModalContextProvider
            getDocumentFromModal={props.getDocumentFromModal}
            reRender={props.reRender}
            onClose={props.onClose}
        >
            <AddImageModal />
        </AddDocumentModalContextProvider>
    )
}

interface AddDocumentModalComponentProps {
    getDocumentFromModal: (documentObject: AddDocumentObject) => void
    onClose: () => void
    reRender: () => void
}

export const AddImageModal = () => {

    const {
        isUploading,
        onClose,
        onConfirmClose,
        onCancelClose,
        showConfirmExitAddDocument
    } = useContext(AddDocumentModalContext);

    return (
        <AddDocumentModalStyles
            show={true}
            onHide={() => onClose()}
            size="lg"
        >
            {
                !isUploading &&
                (
                    <Modal.Header closeButton className="mt-2 px-4">
                        <AddDocumentModalTitle />
                    </Modal.Header>
                )
            }
            <AddImageModalBody />
            <AddDocumentModalFooter />
            <ConfirmExitAddMediaContentModal
                onConfirmClose={onConfirmClose}
                onCancelClose={onCancelClose}
                showModal={showConfirmExitAddDocument}
            />
        </AddDocumentModalStyles>
    )
}
