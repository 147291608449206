import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../context/creator/createCourse/AddModuleContext';
import { CreateTaskModuleContentContextProvider } from "../../../../../../context/creator/createCourse/CreateTaskModuleContentContext";
import ModuleContentTypeEnum from "../../../../../../domain/enum/ModuleContentTypeEnum";
import { LessonDetailsComponent } from './lesson/LessonDetailsForm';
import { TaskDetailsComponent } from './task/TaskDetailsComponent';
import { AssessmentDetailsForm } from './assessment/AssessmentDetailsForm';
import { CreateLessonModuleContentContextProvider } from '../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';
import { CreateAssessementModuleContentContextProvider } from '../../../../../../context/creator/createCourse/CreateAssessementModuleContentContext';

export const ModuleContentDetails = () => {
    const {
        getSelectedModuleContent
    } = useContext(AddModuleContext);

    const moduleContent = getSelectedModuleContent();

    switch (moduleContent.moduleContentTypeId) {
        case ModuleContentTypeEnum.Lesson:
            return (
                <CreateLessonModuleContentContextProvider>
                    <LessonDetailsComponent />
                </CreateLessonModuleContentContextProvider>
            );
        case ModuleContentTypeEnum.Task:
            return (
                <CreateTaskModuleContentContextProvider>
                    <TaskDetailsComponent />
                </CreateTaskModuleContentContextProvider>
            );
        case ModuleContentTypeEnum.Assessment:
            return (
                <CreateAssessementModuleContentContextProvider>
                    <AssessmentDetailsForm />
                </CreateAssessementModuleContentContextProvider>
            );
        default:
            return (
                <>
                    Erro
                </>
            );
    }
};
