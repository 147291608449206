import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';

import styled from 'styled-components'
import CategoryViewModel from '../../domain/models/CategoryViewModel';
import { SetStateAction, useState } from 'react';

interface AvaliableApprenticeCourseFilterProps{
    categories:CategoryViewModel[],
    activeFilter:CategoryViewModel[]
    setActiveFilter:(categoryModel: CategoryViewModel[])=> void,
    getDistributedTrilhas: (pageNumber: number, filters?:string[]) => void
    results:number
}

const AvaliableApprenticeCourseFilter = (props:AvaliableApprenticeCourseFilterProps)=>{

    const [reload,callReload] = useState(false);
    const [showOptions, setShowOptions] = useState(false)

    const [selectedCategories, setSelectedCategories] = useState<CategoryViewModel[]>(props.activeFilter)

    const handleSelectCategoryFilter = (index:number) =>{

        if(!isSelected(props.categories[index].id)){
            setSelectedCategories(selectedCategories.concat(props.categories[index]))
        }else{
            setSelectedCategories(selectedCategories.filter(
                category =>{
                    return(category.id !== props.categories[index].id)
                }
            ))
        }
        callReload(!reload);
    }

    const applyFilter = () =>{
        props.setActiveFilter(selectedCategories)

        collapseFilterOptions();

        (document.getElementById('overside-div-categoria') as HTMLFormElement).style.zIndex = "0";

        callReload(!reload);

        let filters = selectedCategories.map(element=>{return(element.id)})
        props.getDistributedTrilhas(1,filters)
    }

    const clearFilter = () =>{
        setSelectedCategories([])
        props.setActiveFilter([])

        props.getDistributedTrilhas(1)
        callReload(!reload);

    }
    const clearSelectedCategories = () =>{
        setSelectedCategories([])
        callReload(!reload);
    }


    const isSelected = (id:string):boolean =>{
        return selectedCategories.filter(
            selectedCategory =>{
                return selectedCategory.id === id
            }
        ).length > 0
    }

    const changeFilterOptions = ()=>{
        setShowOptions(!showOptions);  
        if(showOptions){
            (document.getElementById('overside-div-categoria') as HTMLFormElement).style.zIndex = "0";
        }else{
            (document.getElementById('overside-div-categoria') as HTMLFormElement).style.zIndex = "1";
        }
    }

    const collapseFilterOptions = ()=>{
        setShowOptions(false)
    }

    const removeCategory = (categoryId:string) =>{
        let newCategories = selectedCategories.filter(
            category => {
                return(category.id !== categoryId)
            }
        )       

        setSelectedCategories(newCategories)
        props.setActiveFilter(newCategories)

        let filters = newCategories.map(element=>{return(element.id)})
        callReload(!reload);

        props.getDistributedTrilhas(1,filters)
    }
    return(
        <>
        <FilterComponentStyles>
            <DropdownButtonStyles md={"auto"}>
                <Row className="mt-2">
                    <Col>
                        <h2>
                            Filtrar por
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => changeFilterOptions()}>
                            <Row className="my-1">
                                <Col className="my-auto">
                                    <h5>Categorias</h5>
                                </Col>
                                <Col className="my-auto pl-0">
                                    {
                                        showOptions?
                                        (
                                            <i className="bi bi-chevron-up"/>
                                        )
                                        :
                                        (
                                            <i className="bi bi-chevron-down"/>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Button>
                        {
                            showOptions?
                            (            
                                <FilterOptionsMenu>
                                    <Card.Body>
                                        <ListGroup>
                                            {
                                                props.categories.map(
                                                    (category, index) =>{
                                                        return(
                                                            <ListGroup.Item>
                                                                <Row>
                                                                    <Col xs={2} md={'auto'}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={isSelected(category.id)}
                                                                            onClick={() => handleSelectCategoryFilter(index)}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={10}>
                                                                        <p>
                                                                            {category.name}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </ListGroup.Item>
                                                        )
                                                    }
                                                )
                                            }
        
                                        </ListGroup>
                                    </Card.Body>
                                    <Card.Footer className="pb-3">
                                        <Row>
                                            <Col md={'auto'}>
                                                <Button className="clear-button py-3 px-4" disabled={(selectedCategories.length === 0)} onClick={()=>clearSelectedCategories()}>
                                                    Limpar
                                                </Button>
                                            </Col>
                                            <Col md={'auto'} className="ml-auto">
                                                <Button className="apply-button py-3 px-4" onClick={()=>applyFilter()}>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </FilterOptionsMenu>
                            )
                            :
                            (
                                <></>
                            )

                        }

                    </Col>
                </Row>
            </DropdownButtonStyles>

        </FilterComponentStyles>
        <Row className="mb-2 mt-3">
            <Col className="my-auto">
                <Row>
                    
                    {
                        
                            props.activeFilter.length > 0?
                            (
                            <>
                            <Col md={"auto"} className="my-auto">
                                <ResultsAndFiltersText>{props.results} Resultados</ResultsAndFiltersText>
                            </Col>
                            <Col md={1} className="d-none d-md-block d-lg-block my-auto text-right">
                                <ResultsAndFiltersText>Filtros:</ResultsAndFiltersText>
                            </Col>
                            <Col md={12} className="d-block d-md-none d-lg-none my-auto">
                                <ResultsAndFiltersText>Filtros:</ResultsAndFiltersText>
                            </Col>
                            </>

                            )
                            :
                            (<></>)

                    }  
                    <Col>
                        <Row>
                            {
                                props.activeFilter.map(
                                    selectedCategory =>{
                                        return(
                                            <Col md={"auto"} className="px-1">
                                                <CategoryButton onClick={()=>removeCategory(selectedCategory.id)}>
                                                    <Row className="py-2"> 
                                                        <Col xs={10} md="auto">                                                           
                                                            {selectedCategory.name?.length > 32
                                                                ? selectedCategory.name?.substring(0, 32) + "..."
                                                                : selectedCategory.name}                                                        
                                                        </Col>
                                                        <Col xs={2} className="pl-0">
                                                        <i className="bi bi-x"></i>
                                                        </Col>
                                                    </Row>
                                                </CategoryButton>
                                            </Col>
                                        )
                                    }
                                )
                            }

                        </Row>
                    </Col>
                </Row>
            </Col>
            {

                props.activeFilter.length > 0?
                (
                    <Col md={"auto"} className="my-auto ml-auto">
                        <CleanButton onClick={() =>clearFilter()}>
                            Limpar Busca
                        </CleanButton>
                    </Col>
                )
                :
                (
                    <></>
                )

            }

        </Row>
        </>

    )
}

const ResultsAndFiltersText = styled.h3`
    margin-bottom:0px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2vmin;
    line-height: 24px;
    color: #514F4E;

    @media screen and (max-width: 990px){
        font-size: 5vmin;
        margin-bottom: 10px;               
    }
`

const CategoryButton = styled(Button)`
    display:inline-block;
    box-sizing: border-box;
    border-radius: 70px!important;
    background: #FFFFFF!important;

    border: 1px solid #0C8292!important;
    box-shadow:none!important;

    text-align:center;
    min-width:10vmin;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.64vmin;
    width:100%;
    text-overflow:ellipsis;
    color: #0C8292!important;

    .py-2 {
        font-size: 2vmin;
        top: 2px;
        position: relative;
        padding-left: 5px;
    }

    @media screen and (max-width: 990px){
        font-size: 5vmin;
        max-width: 100% !important;
        margin: auto auto 10px auto !important;

        .py-2 {
            font-size: 5vmin;
            white-space: nowrap; 
            height: 28px;
        }       

       .px-1 {
            button {
                width: 90% !important;
                opacity: 0.6 !important;
                margin-bottom: 25px !important;
                margin-top: 20px !important;
            }
       }

       i {
           font-size: 10vmin;
           position: absolute;
           top: -11px;
           right: 10px;
       }
    }
`
const FilterComponentStyles = styled(Row)`
`

const DropdownButtonStyles = styled(Col)`
    h2{

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.78vmin;
        color: #514F4E;       
    }    

    
    button{
        background: #FFFFFF!important;
        border: 1px solid #0C8292!important;
        box-shadow:none!important;
        box-sizing: border-box;
        border-radius: 8px;
        position: relative;
        z-index:2;

        &:hover{
            background: #E1F4F7!important;
        }
        &:focus{
                box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
        }

        h5{
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #0C8292;
            margin-bottom:0px;
        }
        i{
            color: #0C8292;
            font-size: 2.78vmin;
        }       
    }

    @media screen and (max-width:990px){
        
        h2 {
            font-size: 5vmin;
            margin:25px 0px 15px 0px;
        }

        .my-auto {
            h5 {
                font-size: 5vmin;
                color: #333;
            }
        }
       
        p {
            font-size: 4vmin;  
        }

        i {
            font-size: 5vmin !important;
            color: #333 !important;
        }

        button {
            border: solid 1px #333 !important;
        }
    }
`

const FilterOptionsMenu = styled(Card)`
    position:absolute;
    background: #FFFFFF;
    box-shadow: 0px 0.28vmin 0.56vmin rgba(0, 0, 0, 0.25);
    border-radius: 1.12vmin ;
    z-index:100;
    min-width:25vw;
    .card-body{
        max-height:25vmin;

        overflow-y:scroll;
        overflow-x: hidden;
        margin-top:2.5vmin;
        padding:0px;
        height:95%;
        max-width:24.5vw;        

        .list-group-item{
            border:none;
            width:100%;
            p{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2.22vmin;
                line-height: 3.33vmin;

                color: #777575;
                margin-bottom:0px;
            }
            input[type=checkbox] {
                position: relative;
                cursor: pointer;
                width: 4vmin;
                height: 4vmin;    
                -webkit-appearance: inherit;
                background-color: #fafafa;
                border: 0.28vmin solid #777575;
                box-sizing: border-box;
                border-radius: 0.56vmin;
                display: inline-block;
                position: relative;
            }
            input[type=checkbox]:hover {
                border: 2px solid #0C8292;
                box-sizing: border-box;
                box-shadow: 0px 0px 0px 3px rgba(12, 130, 146, 0.2);
                border-radius: 4px;
            }
            
            input[type=checkbox]:checked {
                background-color: #EA694C;
                border: 0.14vmin solid #EA694C;
                
            }
            input[type=checkbox]:checked:after {
                content: '\\2713';
                font-size: 2.8vmin;
                font-weight:bolder;
                position: absolute;
                left: 0.8vmin;
                color: #fff;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
        ::-webkit-scrollbar{
            position:absolute;
            width: 0.8vmin ;
            background: #E8E8E8;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 0.4vmin ;
            background: #B9B8B8;;
            width: 0.4vmin ;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background:#B9B8B8;; 
            width: 1.12vmin ;

        }      
    }
    .card-footer{
        background: #FFFFFF;
        border-top:none;
        border-radius: 1.12vmin ;

        border-radius: 1.12vmin ;


        button{
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 1.11vmin;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;

        }
        .clear-button{
            border: 0.14vmin solid #636060!important;
            box-sizing: border-box;
            color: #636060;
            :disabled{
                color: #a39e9e;
                border: 0.14vmin solid #a39e9e!important;
                background: #FFFFFF!important;
            }
            :hover:not(:disabled){
                background: #0C8292!important;
                border: 0.14vmin solid #0C8292!important;
                color: #FFFFFF;
            }
        }
        .apply-button{
            border: 0.14vmin solid #0C8292;
            color: #0C8292;
            :hover{
                background: #0C8292!important;
                border: 0.14vmin solid #0C8292!important;
                color: #FFFFFF;
            }
        }
    }

    @media screen and (max-width: 990px){
            margin-top:10px;
            left:10px;
            right:10px;
            border-radius:7px;

            .card-body {
                max-width: 100%;  
                max-height: 250px;
            }  

            input[type=checkbox] {
                width: 25px !important;
                height: 25px !important;  
            }

            input[type=checkbox]:checked:after {
                font-size: 5vmin !important;
                left: 5px !important;
            }

            p {
                font-size: 5vmin !important;
                line-height: 7vmin !important;
            }

            img {
                max-width: 100% !important;
            }

            button {
                width: 100%;
                margin:10px 0px;
                font-size: 4vmin !important;
            }

            .row {
                margin:0;
            }
        }
`

const CleanButton = styled(Button)`
    border:none!important;
    box-shadow:none!important;
    background:#FFFFFF!important;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.64vmin;
    text-align: center;
    /* Brand/Tertiary/Medium */
    color: #0C8292!important;


    @media screen and (max-width: 990px){
        font-size: 5vmin !important;
        min-width: 110px;
        padding: 0 !important;
        margin-left: -7px
    }
`

export default AvaliableApprenticeCourseFilter