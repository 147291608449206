enum BrainzHolderDictionary {
    //Certo
    bigbrain = "F8A6C763-BC92-40E8-B2C0-60BFA9B98D99",
    reeducation = "6C2E7253-94F3-4C6D-878F-11196EEAA027"

    //Teste
    // reeducation = "F8A6C763-BC92-40E8-B2C0-60BFA9B98D99",
    // bigbrain = "6C2E7253-94F3-4C6D-878F-11196EEAA027"
}

export default BrainzHolderDictionary