import { useContext } from 'react';
import { Container, Modal } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddImageModalContext } from '../context/AddImageModalContext';
import { IndexScreen } from './IndexScreen';
import { SendFileScreen } from './SendFileScreen';
import { SendLinkScreen } from './SendLinkScreen';
import { UploadingScreen } from './UploadingScreen';

export const AddImageModalBody = () => {
    const {
        isUploading, hiddenFileInput, onInputFileChange,
    } = useContext(AddImageModalContext);
    return (
        <Modal.Body>
            <Container className="px-4">
                {isUploading ?
                    (
                        <UploadingScreen/>
                    )
                    :
                    (
                        <AddImageModalNavigationPosition />
                    )}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onInputFileChange}
                    className="d-none"
                    multiple={false}
                    accept="image/*" />
            </Container>
        </Modal.Body>
    );

};

const AddImageModalNavigationPosition = () => {
    const {
        navigationPosition
    } = useContext(AddImageModalContext);

    switch (navigationPosition) {
        case AddFileNavigationPositionEnum.index: {
            return (
                <IndexScreen/>
            );
        }
        case AddFileNavigationPositionEnum.sendLink: {
            return (
                <SendLinkScreen/>
            );
        }
        case AddFileNavigationPositionEnum.sendFile: {
            return (
                <SendFileScreen/>
            );
        }
        default:
            return <></>;
    }

};


