import { useContext } from 'react';
import { CreateCourseContext } from '../../../context/creator/createCourse/CreateCourseContext'
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import {
    PrimaryGreenButton,
    SecondaryGreenButton,
    PrimaryPurpleButton
} from "../../../components/Buttons/styles";
import { courseSteps } from './courseStep';

const CreateCourseFooter = () => {

    const {
        isLoading,
        isValid,
        isChanged,
        navigationStep,
        submitStepData        
    } = useContext(CreateCourseContext);

    return (
        <CreateCourseFooterStyles className={isLoading ? "d-none" : ""}>
            <Container fluid={true} className="py-4">
                <Row>
                    <Col md={10} className="px-0 mx-auto my-auto">
                        <Row className="foward-back">
                            {navigationStep > 1 &&
                                <Col md="auto" className="ml-0">
                                    <SecondaryGreenButton className="px-4"
                                        onClick={() => submitStepData(true, false)} disabled={!isValid && isChanged}>
                                        <Row>
                                            <Col md="auto" className="pr-0">
                                                <i className="bi-arrow-left" />
                                            </Col>
                                            <Col md="auto" className="my-auto">
                                                <p>
                                                    Passo anterior: {courseSteps.filter(s => s.id == navigationStep - 1).map(f => f.description)}
                                                </p>
                                            </Col>
                                        </Row>
                                    </SecondaryGreenButton>
                                </Col>}

                            <Col md="auto" className="ml-auto">
                                {navigationStep == 5 ?
                                    <PrimaryGreenButton className="px-4"
                                        onClick={() => submitStepData(false, false)} 
                                        disabled={!isValid}>
                                        <Row>
                                            <Col md="auto" className="my-auto ml-auto">
                                                <p>Publicar Curso</p>
                                            </Col>
                                            <Col md="auto" className="pl-0 ml-auto">
                                                <i className="bi bi-check2-circle" />
                                            </Col>
                                        </Row>
                                    </PrimaryGreenButton> :

                                    <PrimaryPurpleButton 
                                        className="px-4"
                                        onClick={() => submitStepData(false, false)} 
                                        disabled={!isValid}
                                    >
                                        <Row>
                                            <Col md="auto" className="my-auto">
                                                <p>Próximo passo: {courseSteps.filter(s => s.id == navigationStep + 1).map(f => f.description)}</p>
                                            </Col>
                                            <Col md="auto" className="pl-0">
                                                <i className="bi-arrow-right" />
                                            </Col>
                                        </Row>
                                    </PrimaryPurpleButton>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </CreateCourseFooterStyles>

    );
};

export default CreateCourseFooter;

const CreateCourseFooterStyles = styled.footer`
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0 2px 0 2px;
    background-color:#FFFFFF;
    border: none;
    box-shadow: 0px -0.14vmin 0.28vmin rgba(0, 0, 0, 0.1);
    z-index:100;
    
    button {
        min-width: 31.5vmin;
        i {
            font-size: 3.33vmin;
        }
        p {
            font-size: 2vmin;
        }
    }
`