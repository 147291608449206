import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import VideoLessonContentViewModel from "../../../../../../../domain/models/course/module/lesson/VideoLessonContentViewModel";
import LessonContentViewModel from "../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import LessonContentTypeEnum from "../../../../../../../domain/enum/LessonContentTypeEnum";
import { VideoLessonContentComponent } from './videoLesson/VideoLessonContentComponent';
import { TextLessonComponent } from './textLesson/TextContentComponent';
import { ImageLessonContentComponent } from './imageLesson/ImageLessonContentComponent';
import ImageLessonContentViewModel from '../../../../../../../domain/models/course/module/lesson/ImageLessonContentViewModel';
import { EmbedLessonContentComponent } from './embedLesson/EmbedLessonContentComponent';
import DocumentLessonContentViewModel from '../../../../../../../domain/models/course/module/lesson/DocumentLessonContentViewModel';
import { DocumentLessonContentComponent } from './documentLesson/DocumentLessonContentComponent';
import { useCreateLessonModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface RenderLessonContentProps {
    lessonContent: LessonContentViewModel;
}

export const RenderLessonContent = (props: RenderLessonContentProps) => {
    const {
        setSelectedLessonContentIndex, selectedLessonContentIndex,
    } = useCreateLessonModuleContentContext();

    

    switch (props.lessonContent.lessonContentTypeId) {
        case LessonContentTypeEnum.VideoUpload:
            return (
                <VideoLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}
                    videoLesson={JSON.parse(props.lessonContent.content) as VideoLessonContentViewModel} />
            );

        case LessonContentTypeEnum.VideoExternal:
            return (
                <VideoLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}
                    videoLesson={JSON.parse(props.lessonContent.content) as VideoLessonContentViewModel} />
            );

        case LessonContentTypeEnum.Text:
            return (
                <TextLessonComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex} />
            );

        case LessonContentTypeEnum.ImageUpload:
            return (
                <ImageLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}
                    imageLesson={JSON.parse(props.lessonContent.content) as ImageLessonContentViewModel} />
            );

        case LessonContentTypeEnum.ImageExternal:
            return (
                <ImageLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}
                    imageLesson={JSON.parse(props.lessonContent.content) as ImageLessonContentViewModel} />
            );

        case LessonContentTypeEnum.Embed:
            return (
                <EmbedLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}/>
            );

        case LessonContentTypeEnum.Document:
            return (
                <DocumentLessonContentComponent
                    key={props.lessonContent.order}
                    setSelectedIndex={setSelectedLessonContentIndex}
                    lessonContent={props.lessonContent}
                    isFocused={props.lessonContent.order === selectedLessonContentIndex}
                    documentLesson={JSON.parse(props.lessonContent.content) as DocumentLessonContentViewModel}
                    />
            );
        default:
            return (<>ocorreu um erro ao carrgegar o conteudo da aula</>);
    }
};