import { Card, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DurationString = styled.p`
font-family: Lato;
font-style: normal;
font-weight: 400;
font-size: 1.94vmin;
line-height: 2.36vmin;
color: #514F4E;
margin:0px;
`

export const AvaliableCourseCard = styled(Card)`
    padding:0px;
    min-width:23.5vw;
    text-align:left;
    text-decoration:none!important;
    cursor:pointer;
    border:none!important;
    background: #FFFFFF;
    box-shadow: 0vmin 0.14vmin 0.56vmin rgba(75, 75, 75, 0.2);
    border-radius: 1.12vmin;
    outline: none;
    &:focus{
            box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:hover{
            background: #F9F9F9!important;
    }

    h2.card-title{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        min-height: 9vmin;
    }

p.card-text{
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 1.94vmin;
    line-height: 2.77vmin;
    min-height: 11.11vmin;
    color: #636060;
}
.card-header{
    background:#E8E8E8;
    border:none;
    width:100%;
    min-height:22.22vmin;
    border-radius: 1.11vmin 1.11vmin 0vmin 0vmin;
}
.card-img-top{
    background:#FFF;
    object-fit:cover;
    width:100%;
    height:22.22vmin;
    border-radius: 1.11vmin 1.11vmin 0vmin 0vmin;
}
.card-footer{
    background:inherit;
    border:none;
    border-bottom-left-radius:1.11vmin;
    border-bottom-right-radius:1.11vmin;
    min-width:100%;
}
.loading h2.card-title{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.12vmin;
    line-height: 1.7vmin;
    min-height:8.9vmin;
    color: #E8E8E8;
    background: #E8E8E8;
}

.loading p.card-text{
    color: #E8E8E8;
    background: #E8E8E8;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.95vmin;
    line-height: 2.78vmin;
    min-height: 11.11vmin;
    margin-bottom:1.4vmin;
}
.loading .card-img-top{
    color: #E8E8E8;
    background: #E8E8E8;
    opacity:0;
}
i{
    font-size:3.33vmin;
    color:#636060;
}
button{
    z-index:100;
    padding:0px;
    background:#FFFFFF;
    border:none;
    &:hover{
        background:#FFFFFF;
        border:none;
    }
    &:focus{
        box-shadow:none!important;
        background:#FFFFFF!important;
    }
    &:active{
        box-shadow:none!important;
        background:#FFFFFF!important;
    }
    }
    button i{
        font-size:4.16vmin;
        &:hover{
            background:#FFFFFF;
            color: #EA694C!important;
        }
    }
    i.bi-info-circle{
        font-size:4.16vmin;
        color: #EA694C;
    }

    @media screen and (max-width:990px){

        .card-footer{
            i {
                font-size: 8vmin;
            }

            p {
                font-size: 4.5vmin;
            }
        }       

        .card-img-top{
            height:auto;
        }

        h2.card-title{
            font-size: 6vmin;
            line-height: 7vmin;
        }

        p.card-text{
            font-size: 5vmin;
            line-height: 7vmin;
        }

        .iconCourseMobile {
            div {
                width: 50% !important
            }
        }
    }
`

export const StyledPagedSearchButtonList = styled(ListGroup)`
.list-group-item{
    border:none;
    padding-left:0.24vmin;
    padding-right:0.24vmin;
}
button{
    display: flex;
    align-items: center;
    text-align: center;
    width:6.11vmin;
    height:6.11vmin;

    border: 0.14vmin solid #FFFFFF;
    background-color:#FFFFFF!important;
    border-radius: 0.56vmin;
    p{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        color:#6468C8!important;    
        padding:0px;
        margin:auto;

    }
    i{
        margin-bottom:0.56vmin;
        font-size:3.61vmin;
        color:#6468C8;
    }
    &:hover{
        background:#FFFFFF;
        border: 0.14vmin solid #6468C8!important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
    }
    &:disabled{
        background:#FFFFFF;
        border: 0.14vmin solid #FFFFFF;
        i{
            color:#B9B8B8;
        }        
        &:hover{
            background:#FFFFFF;
            border: none!important;
            box-sizing: border-box;
            border-radius: 0.56vmin;
        }
    }
    &:active{
        background-color:#FFFFFF!important;
        border: 0.14vmin solid #6468C8!important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
        box-shadow: 0 0 0 0.28vmin #6468c899!important;
    }
    &:focus{
        background:#FFFFFF;
        border: 1px solid #6468C8!important;
        box-shadow:0 0 0 0.28vmin #6468c899!important;
    }

}
button.active{
    background:#FFFFFF;
    border: 0.14vmin solid #6468C8!important;
    box-shadow:none;
}

@media screen and (max-width: 990px){
    /* width: 100% !important;
    margin: auto !important;    
    display: block; */

    .list-group-item{
        display: inline-flex;        
        padding-left:0 !important;
        padding-right: 0 !important;
        width:50px;
        overflow: hidden;

        &:first-child{
            width: 50px;
            padding: 0;
            margin: 0;

            button {
                position: relative;
                top: 15px;
                padding-left: 5px;
            }
        }

        &:last-child {
            width: 50px;
            padding: 0;
            margin: 0;

            button {
                position: relative;
                top: 15px;
                left: -20px;                
            }
        } 
    }

    button {
        padding: 15px 15px 25px 25px;
        border-radius: 6px;

        &:disabled {
            i {
                font-size: 10vmin !important;
            }
        }

        i {
            font-size: 10vmin;
        }

        p {
            font-size: 5vmin;
            position: relative;
            left: -9px;
        }
    }
}

`

export const AvaliableCourseForStudentComponentTitle = styled(Col)`
h3{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.77vmin;
    line-height: 3.33vmin;
    color: #514F4E;
}

.loading{
    color: #E8E8E8;
    background: #E8E8E8;
}

    @media screen and (max-width: 990px){
        h3{
        font-size: 5vmin;
    }
}
`
export const CreatorCourseListComponentStyles = styled(Col)`
    margin-bottom: 5vmin;

    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 3vmin;
        line-height: 24px;
        color: #514F4E;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2vmin;
        line-height: 24px;
        color: #514F4E;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2vmin;
        line-height: 24px;
        color: #514F4E;  
    }
    p{
        margin-bottom:0px
    }
    .row.nav-buttons{
        position:sticky;
        position: -webkit-sticky;
        top:8.7vmin;
        z-index:10;
        background-color:#fff;

        button{
            text-align:left;
            background-color:#fff!important;
            border:none;
            box-shadow:none;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2vmin;
            line-height: 24px;
            color: #514F4E!important;
            :active{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
            :focus{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
        }
        button.active{
            border-bottom: 4px solid #EA694C!important;
        }
    }

    .list-group-item{
        border:none;
        .card{
            font-size: 1.8vmin;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
            border-radius: 8px;
            button{
                text-align:left;
                background-color:#fff!important;
                border:none;
                box-shadow:none;
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #514F4E;
                :active{
                    background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
                }
                :focus{
                    background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
                }
            }

            .edit-icon 
            {
                color:#0C8292;
                i{
                    font-size:3vmin;
                }
            }

            .view-icon 
            {
                color:#0C8292;
                i{
                    font-size:3.5vmin;
                }
            }

            .edit-icon {
                color:#0C8292;
                i{
                    font-size:3.5vmin;
                }
            }

            .archive-icon {
                color:#C57700;
                i{
                    font-size:3.5vmin;
                }
            }

            .delete-icon {
                color:#D30808;
                i{
                    font-size:3.5vmin;
                }
            }            

            .restore-edit {
                color:#0C8292;
                i{
                    font-size:3vmin;
                } 
            }

            .active-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #218749;
                i{
                    font-size:20px;
                }    
            }
            .draft-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #6468C8;
                i{
                    font-size:20px;
                }    
            }
            .archive-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #C57700;
                i{
                    font-size:20px;
                }    
            }
        }
    }

    .row.empty-result{
        img{
            height:285px;
        }
        h1{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.5vmin;
            line-height: 24px;
            color: #514F4E;
        }
        h3{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2vmin;
            line-height: 24px;
            color: #636060;
        }
    }
`