import { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class GenericErrorPage extends Component {

    render() {
        return (
            <Container fluid={true}>  

            <Row className="p-4">
                <Col></Col>
                <Col xs={6}>                    
                    <h4>404 - Pagina nao encontrada</h4>
                </Col>
                <Col></Col>
            </Row>

            <Row className="p-4">
                <Col></Col>
                <Col xs={6}>                
                   <h2>Ops... parece que a pagina que voce solicitou nao existe ou nao foi encontrada</h2>
                   <Link to="/">voltar para tela inicial</Link>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        )
    }
}

export default GenericErrorPage;
