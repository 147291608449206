import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import ConsumptionByApprenticeViewModel from '../../domain/models/report/ConsumptionByApprenticeViewModel'
import PaginatedSearchResult from "../../domain/util/PaginatedSearchResult"
import { StyledPagedSearchButtonList, CreatorCourseListComponentStyles } from "./styles"

interface ReportByApprenticeListComponentProps{
    consumptionByApprenticeListViewPaginatedSearch:PaginatedSearchResult<ConsumptionByApprenticeViewModel>    
    isLoading:boolean
    getConsumptionByApprentice :(pageNumber:number) => void    
}

const ReportByApprenticeListComponent = (props:ReportByApprenticeListComponentProps) => {    

    function loadPage(pageNumber:number){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        props.getConsumptionByApprentice(pageNumber);
    }

    const RenderPageButtons = (activeSearch:PaginatedSearchResult<any>):JSX.Element =>{
        let buttons:JSX.Element[] = [
            <ListGroup.Item>
                <Button className={activeSearch.currentPage === 1?"active":""} onClick={() => loadPage(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for(let i=2; i < activeSearch.totalPages ; i++){
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto "+(activeSearch.currentPage === i?"active":"")} onClick={() => loadPage(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }

        if( activeSearch.totalPages > 1){
            buttons.push(
                <ListGroup.Item>
                    <Button className={activeSearch.totalPages === activeSearch.currentPage?"active":""} onClick={() => loadPage(activeSearch.totalPages)}>
                        <p>{activeSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )
        }

        return(
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasPrevious} onClick={() => loadPage(activeSearch.currentPage-1)}>
                        <i className="bi bi-chevron-left"/>
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasNext} onClick={() => loadPage(activeSearch.currentPage+1)}>
                        <i className="bi bi-chevron-right"/>
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>)
    }

    const RenderLoadging = () : JSX.Element => {
        return (<>
            <Row className="mt-5 empty-result">
                <Col md="auto" className="ml-auto">
                    <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                </Col>
                <Col md="auto" className="my-auto mr-auto">
                    <Row>
                        <Col>
                            <h1>
                                Carregando dados...
                            </h1>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </>)
    }

    const RenderNoResult = () : JSX.Element => {
        return (<>
            <Row className="mt-5 empty-result">
                <Col md="auto" className="ml-auto">
                    <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                </Col>
                <Col md="auto" className="my-auto mr-auto">
                    <Row>
                        <Col>
                            <h1>Nenhum registro</h1>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </>)
    }

    const RenderResultTable = () : JSX.Element => {
        return (<>
            {   
                props.consumptionByApprenticeListViewPaginatedSearch.totalRecords >0?
                (<>     
                    <Row className="mt-2">
                        <Col md={4}>
                            <p className="pl-3">
                                Nome
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                Email
                            </p>
                        </Col>
                        <Col md={2}>
                            <p>
                                Cursos em andamento
                            </p>
                        </Col>                     
                        <Col md={2}>
                            <p>
                                Cursos finalizados
                            </p>
                        </Col>                                
                    </Row>
                    <Row className={props.isLoading?"d-none":""}>
                        <Col>
                            <ListGroup className="w-100 px-0">
                                {
                                    props.consumptionByApprenticeListViewPaginatedSearch.items.map(
                                        item =>{
                                            return(
                                                <ListGroupItem className="px-0 py-2">
                                                    <Card>
                                                        <Card.Body className="py-2">
                                                            <Row>
                                                                <Col md={4} className="my-auto">
                                                                    <p>{item.apprenticeName}</p>
                                                                </Col>
                                                                <Col md={4} className="my-auto">
                                                                    <p>{item.apprenticeEmail}</p>
                                                                </Col>                                                                        
                                                                <Col md={2} className="my-auto">
                                                                    <p>{item.qtyCourseInProgress}</p>
                                                                </Col>
                                                                <Col md={2} className="my-auto">
                                                                    <p>{item.qtyCourseDone}</p>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </ListGroupItem>
                                            )
                                        }
                                    )
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                    {
                        props.consumptionByApprenticeListViewPaginatedSearch.totalPages > 1 ?
                        (
                        <Row className={props.isLoading?"d-none":""}>
                            <Col md={"auto"} className="mx-auto my-4">
                                {RenderPageButtons(props.consumptionByApprenticeListViewPaginatedSearch)}
                            </Col>
                        </Row>
                        )
                        :
                        (
                            <></>
                        )
                    }
                </>)
                : (RenderNoResult())
            }
        </>)
    }

    return(
        <CreatorCourseListComponentStyles className="ml-3"> 
            {props.isLoading 
                ? (RenderLoadging())
                : (RenderResultTable())
            }
        </CreatorCourseListComponentStyles>
    )
}

export default ReportByApprenticeListComponent;