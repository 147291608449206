import { CreateCourseContext } from '../../../../../context/creator/createCourse/CreateCourseContext'
import { Col, Row, Modal, Container } from "react-bootstrap";
import { CoursePublishedModal } from "../../CreateCourseStyles";
import { PrimaryPurpleButton } from "../../../../../components/Buttons/styles";

const ModalCoursePublished = (props: any) => {

    return (
        <CoursePublishedModal {...props} centered className="p-3">                    
            <Modal.Header>
                <Container>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <img src={"/img/student/attend/woman-boss-business.svg"} /> 
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container fluid={true}>
                    <Row>
                        <Col md={12}>
                            <Modal.Title>Curso Publicado!</Modal.Title>
                        </Col>                                
                    </Row>
                    <Row className="mb-4 mt-4">
                        <Col md={12}>
                            <p>
                                Seu curso já foi disponibilizado para os públicos <br/>selecionados. Gerencie ele na página “Gerenciar Cursos”.
                            </p>
                        </Col>                                
                    </Row>
                    <Row className="modal-div-button">
                        <Col md={12} className="text-center mx-auto">
                            <PrimaryPurpleButton size="lg" className="w-100 py-3" type="button" onClick={props.onHide}>
                                Ir para página inicial  <i className="bi-arrow-right"/>
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </CoursePublishedModal>
    );
}

export default ModalCoursePublished;