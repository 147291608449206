import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router";

import { CourseViewModel } from "../../../domain/models/course/CourseViewModel"
import CourseStepEnum from "../../../domain/enum/CourseStepEnum";
import { ICreateCourseContentPayload } from "../../../domain/payloads/createCourse/ICreateCourseContentPayload";
import { IOrderningModulesPayload } from "../../../domain/payloads/createCourse/IOrderningModulesPayload";

import CourseService from '../../../services/CourseService'
import CreatorServices from "../../../services/CreatorServices";
import ModuleServices from "../../../services/ModuleServices";

const courseService = new CourseService()
const creatorService = new CreatorServices();
const moduleService = new ModuleServices();

interface CreateCourseContextData {
    course: CourseViewModel    
    setCourse: React.Dispatch<React.SetStateAction<CourseViewModel>>

    openModuleIndex: number,
    setOpenModuleIndex: React.Dispatch<React.SetStateAction<number>>

    isEdit: boolean
    stepRef:React.MutableRefObject<undefined>

    hasDependency: boolean    
    setHasDependency: React.Dispatch<React.SetStateAction<boolean>>   

    dataIsLoaded: boolean
    setDataIsLoaded: React.Dispatch<React.SetStateAction<boolean>>

    updateDate: string
    setUpdateDate: React.Dispatch<React.SetStateAction<string>>

    openModulePage: boolean
    setOpenModulePage: React.Dispatch<React.SetStateAction<boolean>>

    openOrderModulePage: boolean
    setOpenOrderModulePage: React.Dispatch<React.SetStateAction<boolean>>

    isLoading: boolean
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    
    isChanged: boolean
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>

    isValid: boolean    
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>   

    showConfirmDeleteModuleModal: boolean    
    setShowConfirmDeleteModuleModal: React.Dispatch<React.SetStateAction<boolean>>   

    hasQuiz: boolean    
    setHasQuiz: React.Dispatch<React.SetStateAction<boolean>>  
    
    navigationStep: number
    setNavigationStep: React.Dispatch<React.SetStateAction<number>>
    
    submitStepData: (isBacking: boolean, saveAsDraft: boolean, stepRender?:number) => void
    
    handleOrderModule: (direction: string, currentOrder: number) => void
    handleSaveOrderModule: () => void
    handleOnDeleteModule:(index:number) => void
    handleOnCancelDeleteModule:() => void

    addNewModule: () => void
    orderModule: () => void
    editModule: (index:number) => void
    deleteModule: (index:number) => void
    reRender: () => void
}

export const CreateCourseContext = createContext({} as CreateCourseContextData)

interface CreateCourseContextProviderProps{
    isEdit:boolean,
    children:ReactNode
}

const defaultCourse:CourseViewModel = {
    id: "",
    creatorId: "",
    institutionId: "",
    title: "",
    description: "",
    presentation: "",
    workLoad: "",
    coverImage: "",
    enableFaq: false,
    enableCertificate: false,
    apprentice: false,
    creator: false,
    courseStatusId: 1,
    courseStepId: CourseStepEnum.InitialData,
    publishedDate: "",
    introductionVideoName:"",
    introductionVideoUrl:"",
    introductionImageUrl:"",
    courseLinkedClasses: [],
    courseCategories: [],
    courseSubCategories: [],
    modules: [],
    quiz: {
        id: "",
        minimumGrade: "",
        courseId: "",
        courseTitle: "",
        active: false,
        questions: []
    }
}

export const CreateCourseContextProvider = (props:CreateCourseContextProviderProps) =>{

    const [reload, callReload] = useState(true);
    const [isLoading, setIsLoading] = useState(true);    

    const [isValid, setIsValid] = useState(false)
    const [dataIsLoaded, setDataIsLoaded] = useState(false)
    const [hasDependency, setHasDependency] = useState(false)
    const [hasQuiz, setHasQuiz] = useState(false)
    const [openModulePage, setOpenModulePage] = useState(false)
    const [openOrderModulePage, setOpenOrderModulePage] = useState(false)
    const [showConfirmDeleteModuleModal, setShowConfirmDeleteModuleModal] = useState(false)

    const [updateDate, setUpdateDate] = useState(null as any) 
    const [isChanged, setIsChanged] = useState(false) 

    const [openModuleIndex, setOpenModuleIndex] = useState<number>(-1);

    const [course, setCourse] = useState<CourseViewModel>(defaultCourse);
    const [navigationStep, setNavigationStep] = useState(0);

    const { isEdit } = props

    const stepRef = useRef();
    
    let routeMatch = useRouteMatch<any>("/course/:id/edit/");
    let courseId = "";    

    if (isEdit) {
        courseId = routeMatch!.params.id!
    }
    useEffect(() => {
        reRender()
    }, [isLoading, openModulePage, openOrderModulePage, isValid]) 
       
    useEffect(()=>{
        setIsChanged(false)
    },[navigationStep])
    
    useEffect(() => {

        if (isEdit) {
            setIsLoading(true);

            courseService.getById(courseId).then(function (response) {
                if (response.success) {                    
                    setCourse(response.result)
                    setNavigationStep(response.result.courseStepId + 1 > 5 ? response.result.courseStepId : response.result.courseStepId + 1)

                    //to do: to remove
                    setHasQuiz(false)
                    
                    let flag = response.result.modules.some(
                        module => (module.moduleDependenciesOnMe.length > 0|| module.modulesImDependent.length > 0)
                    )

                    setHasDependency(flag)
                    setIsLoading(false);
                    setDataIsLoaded(true);
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }else 
        {
            setNavigationStep(1);
            setIsLoading(false);
        }      
    }, [])

    const reRender = ()=>{
        callReload(!reload)
    }
        
    const submitStepData = (isBacking?:boolean, saveAsDraft?: boolean, step?:number) => 
    {
        setOpenOrderModulePage(false)
        const createCourseStepFormComponent = stepRef.current as any
        createCourseStepFormComponent.submit(isBacking, saveAsDraft, step);
    }

    const addNewModule = () =>{
        setIsLoading(true)
        setOpenModuleIndex(-1)
        setOpenModulePage(true)
    }

    const editModule = (index:number) => {
        setOpenModuleIndex(index)
        setOpenModulePage(true)
    }

    const orderModule = () => {
        //setIsLoading(true)
        setOpenOrderModulePage(true)
    }

    const handleOrderModule = (direction: string, currentOrder: number) => {
        var modIndex = course.modules.findIndex((obj => obj.order == currentOrder));
        switch (direction) {
            case "UP":
                course.modules[modIndex].order = course.modules[modIndex].order - 1; //sobe
                course.modules[modIndex - 1].order = course.modules[modIndex - 1].order+ 1; //desce
                break;
            case "DOWN":
                course.modules[modIndex].order = course.modules[modIndex].order + 1; //desce
                course.modules[modIndex + 1].order = course.modules[modIndex + 1].order - 1; //sobe
                break;
        }
        reRender()
        //console.log("course.modules", course.modules);
    }

    const handleSaveOrderModule = () => {

         setIsLoading(true);

        let data: IOrderningModulesPayload = {
            courseId: course.id,            
            modules: course.modules.map((value) => {
                return  { id: value.id, order: value.order}
            })
        }

        moduleService.orderingModules(data).then(function (response) {
            if (response.success) {
                setIsLoading(false);
                setOpenOrderModulePage(false)
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });

    }

    const handleOnDeleteModule = (index:number)=>{
        setOpenModuleIndex(index)
        setShowConfirmDeleteModuleModal(true)
    }

    const handleOnCancelDeleteModule = ()=>{
        setOpenModuleIndex(-1)
        setShowConfirmDeleteModuleModal(false)
    }

    const deleteModule = (index:number) => {
        let newModules = course.modules.filter(x =>(x.order !== index))

        newModules.forEach((newModules,index) => {
                newModules.order = index
            }
        ) 

        if(course.modules.length > 0){          
            course.modules = newModules
            let data: ICreateCourseContentPayload = {
                courseId: course.id,
                isEditMode: isEdit||course.courseStepId >= CourseStepEnum.Content,
                modules:newModules
            }

            setIsLoading(true);
            creatorService.createCourseContent(data).then(function (response) {
                if (response.success) {                
                    updateCourseObj(response.result);
                    setUpdateDate(response.result.updateDate);
                    
                    setIsChanged(false);
                    setIsLoading(false);
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });

            setOpenModuleIndex(-1)
        }

        setShowConfirmDeleteModuleModal(false)
        setOpenModulePage(false)
    }

    const updateCourseObj = (response: any) => {
        
        course.courseStepId = response.courseStepId;
        course.modules = response.modules

        setUpdateDate(response.updateDate);  

        setIsLoading(false) 
        setIsChanged(false);
        setDataIsLoaded(true)

        reRender()
    }

    return(
        <CreateCourseContext.Provider 
            value={{ 
                course, 
                setCourse,

                openModuleIndex, 
                setOpenModuleIndex,

                isEdit,
                stepRef,
                
                dataIsLoaded,
                setDataIsLoaded,

                showConfirmDeleteModuleModal, 
                setShowConfirmDeleteModuleModal,
                
                updateDate,
                setUpdateDate,

                openModulePage,
                setOpenModulePage,

                openOrderModulePage,
                setOpenOrderModulePage,

                isLoading, 
                setIsLoading,
                
                isChanged, 
                setIsChanged, 
                
                isValid,
                setIsValid,
                
                navigationStep, 
                setNavigationStep,

                hasDependency,
                setHasDependency,

                hasQuiz,
                setHasQuiz,

                submitStepData,

                addNewModule,
                orderModule,
                editModule,
                deleteModule,
                handleOrderModule,
                handleSaveOrderModule,
                handleOnDeleteModule,
                handleOnCancelDeleteModule,

                reRender
            }}
        >
            {
                props.children
            }          
        </CreateCourseContext.Provider> 
    )
}