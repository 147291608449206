import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import LessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { TertiaryGreenButton, TertiaryYellowButton } from '../../../../../../../../components/Buttons/styles';
import { CreateCourseContext } from '../../../../../../../../context/creator/createCourse/CreateCourseContext';
import { AddModuleContext } from '../../../../../../../../context/creator/createCourse/AddModuleContext';
import DocumentLessonContentViewModel from '../../../../../../../../domain/models/course/module/lesson/DocumentLessonContentViewModel';
import { DocumentViewTypeEnum } from '../../../../../../../../components/Modals/AddDocumentModal/context/AddDocumentObject';
import FileViewer from '../../../../../../../../components/Common/FileViewer';
import { useCreateLessonModuleContentContext } from '../../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface DocumentLessonContentComponentProps {
    lessonContent: LessonContentViewModel;
    isFocused: boolean;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    documentLesson: DocumentLessonContentViewModel;
}

export const DocumentLessonContentComponent = (props: DocumentLessonContentComponentProps) => {

    const { 
        reRender,
        setIsChanged
    } = useContext(CreateCourseContext)

    const {
        module,
        selectedModuleContentIndex,
        deleteLessonContent,
    } = useContext(AddModuleContext)

    const {
        selectedLessonContentIndex,
        editDocumentLessonContent

    } = useCreateLessonModuleContentContext()

    useEffect(() => {

    }, [])

    const onFocus = () => {
        props.setSelectedIndex(props.lessonContent.order)
    }

    const setViewType = (viewType: DocumentViewTypeEnum) => {
        let newDocumentLesson: DocumentLessonContentViewModel = {
            Url: props.documentLesson.Url,
            Name: props.documentLesson.Name,
            ViewType: viewType,
            IsNew: props.documentLesson.IsNew
        }

        module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents[selectedLessonContentIndex].content = JSON.stringify(newDocumentLesson);
        setIsChanged(true)
        reRender()
    }

    return (
        <DocumentLessonContentComponentStyles
            onClick={onFocus}
            isFocused={props.isFocused}
        >
            <Col
                className={"py-3 " + (props.isFocused ? "on-focus" : "")}
            >
                <Row>
                    {
                        props.documentLesson.ViewType === DocumentViewTypeEnum.minimized ?
                            (
                                <Col md={6}>
                                    <Button className='w-100 open-new-tab-button px-3'>
                                        <Row className="mb-2">
                                            <Col md="auto" className="my-auto pr-0">
                                                <i className="bi bi-box-arrow-up-right" />
                                            </Col>
                                            <Col className="my-auto">
                                                <h3>
                                                    Abrir documento inserido
                                                </h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p>
                                                    {props.documentLesson.Name}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>
                            )
                            :
                            (
                                <Col md={10} className='mx-auto'>
                                    <FileViewer linkFile={props.documentLesson.Url} />
                                </Col>
                            )
                    }
                </Row>
                {
                    props.isFocused ?
                        (
                            <Row className='mt-3'>
                                <Col md="auto" className='ml-3'>
                                    <ListGroup horizontal>
                                        <ListGroup.Item>
                                            <TertiaryGreenButton onClick={editDocumentLessonContent}>
                                                <i
                                                    className='bi bi-pencil'
                                                />
                                            </TertiaryGreenButton>
                                        </ListGroup.Item>

                                        <ListGroup.Item>
                                            {props.documentLesson.ViewType === DocumentViewTypeEnum.minimized ?
                                                (
                                                    <TertiaryGreenButton onClick={() => setViewType(DocumentViewTypeEnum.documentReader)}>
                                                        <i className='bi bi-arrows-angle-expand' />
                                                    </TertiaryGreenButton>
                                                )
                                                :
                                                (
                                                    <TertiaryGreenButton onClick={() => setViewType(DocumentViewTypeEnum.minimized)}>
                                                        <i className='bi bi-arrows-angle-contract' />
                                                    </TertiaryGreenButton>
                                                )
                                            }
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md="auto" className='ml-auto my-auto mr-2'>
                                    <TertiaryYellowButton onClick={() => deleteLessonContent(props.lessonContent.order)}>
                                        <i className='bi bi-trash' />
                                    </TertiaryYellowButton>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <></>
                        )
                }

            </Col>
        </DocumentLessonContentComponentStyles>
    );
}


const DocumentLessonContentComponentStyles = styled(Row)`
    background:${props => props.isFocused ? "#F9F9F9" : "inherit"};
    border:none!important;
    .open-new-tab-button{
        text-align:left;
        width:auto;
        height:14.44vmin; 
        box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2);
        border: 0.14vmin solid #F9F9F9;
        border-radius: 1.11vmin;
        background-color: #FFF;
        i{
            font-size:3.33vmin;
            color:#0C8292;
        }
        h3{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.83vmin;
            line-height: 3.33vmin;
            color: #0C8292;
            margin-bottom:0px;
        }
        &:hover{
            background-color: #E1F4F7;
            border: 0.14vmin solid #F9F9F9;
        }
        &:focus, &:active{
            background-color: #d1f3f8!important;
            box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2)!important;
            border: none!important;
        }
        &:focus:after, &:active:after{
            content: '';
            position: absolute;
            top: -0.5vmin;
            left: 1.2vmin;
            bottom: -0.5vmin;
            right: 1.2vmin;
            border: 0.20vmin solid #0B717E!important;
            border-radius: 1.11vmin;
        }
    }
    .list-group-horizontal{
        border-radius:1vmin!important;
        background-color:#E8E8E8;
        padding:none;
    }
    .list-group-item{
        padding:0px!important;
        border-radius:3vmin!important;
        border:none;
        background-color:#E8E8E8;
        i{
            font-size:3.5vmin;
        }

    }
    .list-group-item:first-of-type{
        border-right:0.11vmin solid #B9B8B8;
    }
    .list-group-item:last-of-type{
        border-left:0.11vmin solid #B9B8B8;
    }
    .bi.bi-trash{
        font-size:4vmin;
    }
    object, iframe {
        width:100%;
        height:70vh;
        border:none;
    }
`;
