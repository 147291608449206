import { Button, Card, Col, Row } from 'react-bootstrap';
import { useContext, useState} from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import { CreateCourseContext } from '../../../../../../../context/creator/createCourse/CreateCourseContext';
import { AddVideoButton, CreateCourseQuizComponent } from '../../../../CreateCourseStyles';
import { TransparentOkButton } from '../../../../../../../components/oldLayouts/SharedComponentStyles'
import {
    CardComponentTitle,
    CardComponentSubTitle,    
    CreateQuestionMinimizeButton,
    CreateQuizEnunciado,
    CreateTrilhaCustomCard,
    CreateQuestionButton,
    GridDottedCardNoPadding,
    PurpleButtonText,
    AddAlternativeButton,
    AddNewQuestionButton,
} from '../../../../../createTrilha/createTrilhaStyles';
import { SecondaryGreenButton, SecondaryYellowButton } from '../../../../../../../components/Buttons/styles';
import { AddImageModalComponent } from '../../../../../../../components/Modals/AddImageModal/AddImageModal';
import { AddImageObject } from '../../../../../../../components/Modals/AddImageModal/context/AddImageObject';
import { AnswerList } from './AnswerList';
import { useCreateAssessementModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateAssessementModuleContentContext';
import { AssessmentContentDetailsStyles, AssessmentHeaderStyles } from './styles';
import { AssessementInfoForm } from './AssessementInfoForm';
import { ConfirmQuestionModal } from '../../../../../../../components/Modals/ConfirmQuestionModal';

export const AssessmentDetailsForm = () => {

    const {
        reRender
    } = useContext(CreateCourseContext);

    const {
        module,
        selectedModuleContentIndex,        
    } = useContext(AddModuleContext);

    const {
        handleQuestionDelete,
        handleAddQuestion,
        handleQuestionMinimize,
        handleQuestionTitleChange,
        handleQuestionHintChange,
        handleAddAnswer,
        showQuestionImageModal, setShowQuestionImageModal,
        clearQuestionImage, setSelectedQuestionIndex,
        handleAddQuestionImage,
        handleAnswerContentChange,
        handleAnswerDelete,
        handleMarkRightAnswer,
        showAssessementForm,
        deleteQuestion
    } = useCreateAssessementModuleContentContext();

    function handleShowImageModal(questionIndex:number){
        setSelectedQuestionIndex(questionIndex) 
        setShowQuestionImageModal(true)
    }

    const questions = [... module.moduleContents[selectedModuleContentIndex].assessment!.assessmentQuestions]
    
    return (<>
        <AssessmentContentDetailsStyles>
            <Col>
                <AssessmentHeaderStyles>
                    <Col className='my-auto pt-1'>
                        <p>Detalhes da avaliação</p>    
                    </Col>
                </AssessmentHeaderStyles>
                <AssessementInfoForm/>
                <Row>
                    <Col>
                        <AssessmentHeaderStyles>
                            <Col className='my-auto pt-1'>
                                <p>Perguntas</p>
                            </Col>
                        </AssessmentHeaderStyles>
                    </Col>
                </Row>

                <Row>
                    <CreateCourseQuizComponent>
                        <Row className="p-2">
                            <Col className="mx-auto">
                                <>
                                {
                                    (<>
                                        <GridDottedCardNoPadding>
                                        {
                                            showAssessementForm&&questions.sort((a, b) => a.order - b.order).map((question, questionIndex) =>{
                                                
                                                return (
                                                    <li className="my-2">
                                                        <CreateTrilhaCustomCard className="w-100 p-3">
                                                            <Card.Body className={"py-0 "}>
                                                                
                                                                <Row>
                                                                    <Col>
                                                                        <CardComponentTitle>
                                                                        {
                                                                            question.content === '' ? "Nova Pergunta" 
                                                                            : (question.content.length > 80 ? (question.content.substring(0,80)+"...") : (question.content))
                                                                        }
                                                                        </CardComponentTitle>
                                                                    </Col>
                                                                    <Col className="my-auto" md={"auto"}>
                                                                        <TransparentOkButton className={((module.moduleContents[selectedModuleContentIndex].assessment!.assessmentQuestions!.length > 1)?'':'d-none')} 
                                                                            onClick={() => handleQuestionDelete(questionIndex)}
                                                                            >
                                                                            <img src={`/img/createTrilha/trash-step-four.svg`} alt="" />
                                                                        </TransparentOkButton>
                                                                    </Col>
                                                                    <Col className={"my-auto mr-1 "} md={"auto"}>
                                                                        <TransparentOkButton 
                                                                            onClick={() => handleQuestionMinimize(questionIndex)}
                                                                        >
                                                                            <CreateQuestionMinimizeButton $rotate={(question.minimized === true?"180deg":"0deg")} src={`/img/createTrilha/chevron.svg`} alt="" />
                                                                        </TransparentOkButton>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <Row className={(question.minimized?"d-none":"")}>
                                                                    <Col>
                                                                        <CardComponentSubTitle className="mb-2">Enunciado</CardComponentSubTitle>
                                                                        <CreateQuizEnunciado rows="3"
                                                                            value={question.content} 
                                                                            onChange={(e) => handleQuestionTitleChange(e,questionIndex)} 
                                                                            type="textarea" placeholder="Insira o enunciado da pergunta"/>
                                                                    </Col>
                                                                    {
                                                                                question.imageUrl  !== ""&&question.imageUrl !== null&&question.imageUrl !== undefined?
                                                                                (
                                                                                    <AddVideoButton xs={12} >

                                                                                    <Row className="py-4 pb-1">
                                                                                        <Col>
                                                                                            <Row>
                                                                                                <Col md="auto" className='mx-auto'>
                                                                                                    <img className='question-image' src={question.imageUrl}>
                                                                                                    </img>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                        <Col md="auto" className='my-auto'>
                                                                                            <Row className='my-auto pb-3'>
                                                                                                <Col md="auto" className='my-auto mb-3'>
                                                                                                    <SecondaryGreenButton   SecondaryGreenButton econdaryGreenButton onClick={()=> handleShowImageModal(questionIndex)} >
                                                                                                        <Row>
                                                                                                            <Col md="auto" className='my-auto pr-0'>
                                                                                                                <i className='bi bi-arrow-repeat'/>
                                                                                                            </Col>
                                                                                                            <Col className='my-auto'>
                                                                                                                <p>
                                                                                                                    Alterar imagem
                                                                                                                </p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </SecondaryGreenButton>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col md="auto" className='my-auto mb-1'>
                                                                                                    <SecondaryYellowButton onClick={()=>clearQuestionImage(questionIndex)}>
                                                                                                        <Row>
                                                                                                            <Col md="auto" className='my-auto pr-0'>
                                                                                                                <i className='bi bi-trash'/>
                                                                                                            </Col>
                                                                                                            <Col className='my-auto'>
                                                                                                                <p>
                                                                                                                    Excluir imagem
                                                                                                                </p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </SecondaryYellowButton>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>


                                                                                    </Row>
                                                                                </AddVideoButton>

                                                                                )
                                                                                :
                                                                                (
                                                                                <AddVideoButton xs='auto' className="add-video my-auto">

                                                                                    <Button className="add-video my-auto" onClick={()=>handleShowImageModal(questionIndex)}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <i className="bi bi-image"/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <p>Adicionar imagem</p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Button>
                                                                                </AddVideoButton>
                                                                                )
                                                                            }
                                                                </Row>

                                                                <Row className={"mt-3 mb-3 " + (question.minimized?"d-none":"")}>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col>
                                                                                <CardComponentSubTitle>Alternativas</CardComponentSubTitle>
                                                                            </Col>
                                                                        </Row>                                                                        
                                                                    {
                                                                        question.assessmentAnswers!.length > 0 ?
                                                                        (
                                                                            <AnswerList
                                                                                answers={question.assessmentAnswers} 
                                                                                questionIndex={questionIndex}
                                                                                handleAnswerContentChange={handleAnswerContentChange}
                                                                                handleAnswerDelete={handleAnswerDelete}
                                                                                handleMarkRightAnswer={handleMarkRightAnswer}
                                                                            />
                                                                        )
                                                                        :
                                                                        (<></>)
                                                                    }
                                                                        <Row>
                                                                            <Col>
                                                                                <AddAlternativeButton
                                                                                    onClick={() => handleAddAnswer(questionIndex)}
                                                                                >
                                                                                    <Row className="my-2">
                                                                                        <Col className="my-auto pr-1" md={"auto"}>
                                                                                            <i className='bi bi-plus-lg' />
                                                                                        </Col>
                                                                                        <Col className="" md={"auto"}>
                                                                                            <p>Adicionar Alternativa &nbsp;&nbsp;</p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AddAlternativeButton> 
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>

                                                                <Row className={(question.minimized? "d-none" : "mt-4")}>

                                                                    <Col className='my-auto'>
                                                                        <Row>
                                                                            <Col className='mb-3'>
                                                                                <CardComponentSubTitle>Dica de aprendizagem (opcional)</CardComponentSubTitle>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col className='mb-3'>
                                                                                <h5>Você pode adicionar uma dica para a pergunta, junto de um link para um material de apoio.
                                                                                    Eles serão exibidos quando a pergunta não for respondida corretamente.
                                                                                </h5>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <CreateQuizEnunciado rows="3"
                                                                                    value={question.hint}
                                                                                    onChange={(e) => handleQuestionHintChange(e,questionIndex)} 
                                                                                    type="text" placeholder="Insira a dica"/>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col> 

                                                                </Row>
                                                                    
                                                            </Card.Body>
                                                        </CreateTrilhaCustomCard>
                                                    </li>                                                
                                                )
                                            })
                                        }
                                        </GridDottedCardNoPadding>         
                                        
                                        
                                        <Row className="my-4">
                                            <Col > 
                                                {/* <CreateQuestionButton className='w-100' onClick={() => handleAddQuestion()} disabled={!isValid}> */}
                                                <AddNewQuestionButton className='w-100' 
                                                    onClick={() => handleAddQuestion()}>
                                                    <Row className="justify-content-center">
                                                        <Col className="my-auto pr-3" md={"auto"}>
                                                            <i className='bi bi-plus-lg' />
                                                        </Col>
                                                        <Col className="px-2" md={"auto"}>
                                                            <p>Adicionar outra pergunta</p>
                                                        </Col>
                                                    </Row>
                                                </AddNewQuestionButton>
                                            </Col>
                                        </Row>

                                    </>
                                    )
                                }
                                </>
                                
                            </Col>
                        </Row>  


                    </CreateCourseQuizComponent>
                </Row>
            </Col>
        </AssessmentContentDetailsStyles>
        {
            showQuestionImageModal&&
            <AddImageModalComponent
                getImageFromModal={(imageObject)=>handleAddQuestionImage(imageObject)}
                onClose={()=>setShowQuestionImageModal(false)} 
                reRender={reRender}
                />
        }     
            <ConfirmQuestionModal/>

    </>)
}

