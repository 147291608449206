import {
    Row,
    Col
} from 'react-bootstrap';
import { ModuleBlankListComponentStyles } from './CreateCourseContentFormStyles';

export const CourseBlankModule = () => {
    return (
        <ModuleBlankListComponentStyles md={10} className="mx-auto">
            <Row className="text-center h-100">
                <Col md={"auto"} className="mx-auto my-auto">
                    <Row>
                        <Col className="mx-auto">
                            <i className="bi bi-list-ol"></i>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Seu curso precisa ter pelo menos um módulo</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ModuleBlankListComponentStyles>
    );

};
