import ApiInterface from "../interface/ApiInterface"
//import IUploadFilePayload from "../models/IUploadFilePayload"

export class FileUploadServices {
    
    public async uploadTempContainer(data: any, config: any) {
        return await ApiInterface.post('/UploadFile/UploadTempContainer', data, config)
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
    
    public async uploadFinalContainer(data: any) {
        return await ApiInterface.post('/UploadFile/UploadFinalContainer', data, {
            headers: {
              'Content-Type': 'multipart/form-data, image/*'
            }
        })
        .then(res => {            
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
}