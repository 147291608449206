import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap"
import { AttendQuizHeaderStyles } from './styles'
import styled from 'styled-components';
import { TertiaryPurpleButton } from "../Buttons/styles";
import { AttendCourseContext } from '../../context/apprentice/attend/AttendCourseContext';
import { AssessmentContext } from '../../context/apprentice/attend/AssessmentContext';

interface AttendAssessmentHeaderProps {
    totalQuestions: number,
    currentQuestion: number,
}

const AttendAssessmentHeader = (props: AttendAssessmentHeaderProps) => {

    const {
        currentUserModuleContentProgress,
        isLoadingAssessment, setIsLoadingAssessment
    } = useContext(AttendCourseContext);
    
    const {
        setShowModalExit
    } = useContext(AssessmentContext);   

    return (
        <div>
            <AttendQuizHeaderStyles>
                <Container fluid={true}>
                    <Row className="base">
                        <Col md={3} className="my-auto ml-4">
                            <h2>{currentUserModuleContentProgress.title}</h2>
                        </Col>
                        {
                            props.totalQuestions > 0 ?
                                (
                                    <Col md={3} className="my-auto mx-auto">
                                        <Row>
                                            <Col className="mx-auto">
                                                <h5 className="mb-1">
                                                    Pergunta {props.currentQuestion} de {props.totalQuestions}
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mx-auto">
                                                <ProgressBar now={(props.currentQuestion / props.totalQuestions) * 100} variant="success" />
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                                :
                                (
                                    <></>
                                )
                        }

                        <Col md={"auto"} className="my-auto ml-auto pr-4">
                            <TertiaryPurpleButton className="ml-auto" onClick={()=> setShowModalExit(true)}>
                                <i className="bi bi-box-arrow-left" /> Sair da avaliação
                            </TertiaryPurpleButton>                            
                        </Col>

                    </Row>
                </Container>
            </AttendQuizHeaderStyles>
        </div>
    )
}

export default AttendAssessmentHeader;