import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UndecoratedLink } from '../../../components/oldLayouts/SharedComponentStyles';
import CreateCourseHeader from '../../../components/Headers/CreateCourseHeader'
import {    
    ChooseSection, CreateTrilhaContainer, CreateTrilhaPageRow 
  } from '../createTrilha/createTrilhaStyles';  

class ChooseOptionPage extends Component {    

    render() {
        return(
            <CreateTrilhaContainer fluid={true} className="pb-5">
                <CreateCourseHeader />
                <CreateTrilhaPageRow className="align-items-center">
                    <Col md={"auto"} className="ml-auto">
                        <ChooseSection>
                            <UndecoratedLink to="/course/create/step/one">                            
                                <Card>
                                    <Card.Body className="p-4">
                                        <img className="mx-auto mt-2 mb-4" src={'/img/createTrilha/create-trilha-icon.svg'} alt="" />
                                        <h5 className="my-3">Nova trilha</h5>
                                        <p>Criar uma trilha do zero</p>
                                    </Card.Body>
                                </Card>
                            </UndecoratedLink>
                        </ChooseSection>
                    </Col>
                    <Col md={"auto"} className="mr-auto">
                        <ChooseSection>
                            <UndecoratedLink to="/course/reuse">
                                <Card>
                                    <Card.Body className="p-4">
                                        <img className="mx-auto mt-2 mb-4" src={`/img/createTrilha/reuse-trilha-icon.svg`} alt="" />
                                        <h5 className="my-3">Reutilizar trilha</h5>
                                        <p>Criar uma trilha a partir de <br/>outra trilha já publicada</p>
                                    </Card.Body>
                                </Card> 
                            </UndecoratedLink>
                        </ChooseSection>  
                    </Col>
                </CreateTrilhaPageRow>

            </CreateTrilhaContainer>
        );
    }
}

export default ChooseOptionPage