import { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    ListGroup,
    Nav,
    ProgressBar,
    Row,
} from "react-bootstrap";
import { StyledPagedSearchButtonList } from "../../components/PaginatedSearch/styles";
import MyLearningViewModel from "../../domain/models/course/MyLearningViewModel";
import PaginatedSearchResult from "../../domain/util/PaginatedSearchResult";
import NoCoursesStarted from "./NoCoursesStarted";
import NoCoursesCompleted from "./NoCoursesCompleted";
import { EnrollmentCourseStatusEnum } from "../../domain/enum/EnrollmentCourseStatusEnum";
import ChangeLessonLoader from "../../components/Loaders/ChangeLessonLoader";
import {
    ButtonCardTask,
    BodyLearning,
    AttendCourseLessonNavBar,
} from "./styles";
import { TertiaryGreenButton } from "../../components/Buttons/styles";
import styled from "styled-components";
import { CourseCertificationModal } from "../../components/Modals/CourseCertification";
import { getUserId } from "../../util/UserUtil";
import CertificateDetailsViewModel from "../../domain/models/course/CertificateDetailsViewModel";

import ApprenticeServices from "../../services/ApprenticeServices";
import PageLoader from "../../components/Common/Loader";
import InstitutionCertificateService from "../../services/InstitutionCertificateService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ApprenticeService = new ApprenticeServices();
const institutionCertificateService = new InstitutionCertificateService();


const InitialState: PaginatedSearchResult<MyLearningViewModel> = {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    totalRecords: 0,
    hasPrevious: false,
    hasNext: true,
    items: [],
};

export default function CourseListComponent() {
    const [
        CourseViewPaginatedSearchInProgress,
        setCourseViewPaginatedSearchInProgress,
    ] = useState<PaginatedSearchResult<MyLearningViewModel>>(InitialState);

    const [CourseViewPaginatedSearchDone, setCourseViewPaginatedSearchDone] =
        useState<PaginatedSearchResult<MyLearningViewModel>>(InitialState);

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, changeActiveTab] = useState(1);

    const certificateRef= useRef<HTMLDivElement>(null);


    function pageLoad() {
        getDistributedCoursesinProgress(1);
        getDistributedCoursesDone(1);
    }

    useEffect(() => {
        pageLoad();
    }, []);


    function getDistributedCoursesinProgress(pageNumber: number) {
        ApprenticeService.getMyLearning({
            pageNumber: pageNumber,
            pageSize: 10,
            enrollmentCourseStatus: EnrollmentCourseStatusEnum.InProgress,
        })
            .then((response) => {
                setCourseViewPaginatedSearchInProgress(response.result);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    function getDistributedCoursesDone(pageNumber: number) {
        ApprenticeService.getMyLearning({
            pageNumber: pageNumber,
            pageSize: 10,
            enrollmentCourseStatus: EnrollmentCourseStatusEnum.Done,
        })
            .then((response) => {
                setCourseViewPaginatedSearchDone(response.result);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }



    const ContinueCourse = (CourseId: string) => {
        window.location.href = "/course/" + CourseId + "/attend";
    };

    const [modalCertificateShow, setModalCertificateShow] = useState(false);
    const [customCertificate, setCustomCertificate] = useState('');

    const [certificateViewModel, setCertificateViewModel] =
        useState<CertificateDetailsViewModel>(null as any);

    const seeCertificate = (CourseId: string) => {
        getCertificateDetails()
        async function getCertificateDetails() {
        setIsLoading(true);
        
        const certificatePlaceHolder = certificateRef.current;

        if(certificatePlaceHolder==null){
            return;
        }
        let response = await ApprenticeService.getMyCourseCertificateDetails({
            courseId: CourseId,
            apprenticeId: getUserId()
        })


        certificatePlaceHolder.innerHTML = response.result.certificate.trim();
        

        await convertImagesToBase64(certificatePlaceHolder.firstElementChild as HTMLElement)

        setTimeout(() => {
            certificatePlaceHolder.style.display = 'block';

            html2canvas(certificatePlaceHolder.firstElementChild as HTMLElement,{scale:3}).then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1);
                // Cria um documento PDF usando jsPDF
                const pdf = new jsPDF('landscape', 'px', 'a4'); // Defina o tamanho e orientação conforme necessário
                const pdfWidth = pdf.internal.pageSize.getWidth();  // Largura do PDF em mm
                const pdfHeight = pdf.internal.pageSize.getHeight(); // Altura do PDF em mm
        
                // Dimensões da imagem
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
        
                // Calcular escala para ajustar a imagem ao PDF
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight); // A menor escala que mantém a imagem proporcional
        
                const imgScaledWidth = imgWidth * ratio;  // Largura da imagem escalada
                const imgScaledHeight = imgHeight * ratio; // Altura da imagem escalada
    
                pdf.addImage(imgData, 'PNG', 0, 0, imgScaledWidth, imgScaledHeight);
    
                // Converte o PDF para um blob
                const pdfBlob = pdf.output('blob');
    
                // Cria uma URL temporária a partir do Blob
                const pdfUrl = URL.createObjectURL(pdfBlob);

                const link = document.createElement('a');
                link.href = pdfUrl!;
                link.setAttribute('download', 'Certificado.pdf');
            
                // Adiciona o link temporário ao DOM e clica nele para iniciar o download
                document.body.appendChild(link);
                link.click();
            
                // Remove o link temporário do DOM
                document.body.removeChild(link);
            })
    
            certificatePlaceHolder.style.display = 'none';
            certificatePlaceHolder.innerHTML = '';
    
            setIsLoading(false);
        }, 100);
        }
    };

    async function GetImageFromStorage(src: string) {
        return await fetch(src, { method: 'GET', mode: 'cors', cache: 'no-cache', referrerPolicy: "strict-origin-when-cross-origin" });
    }

    const convertImagesToBase64 = async (element:HTMLElement) => {
        try{
            const images = element.querySelectorAll('img');

            for (let index = 0; index < images.length; index++) {

                const img = images[index];

                const src = img.getAttribute('src');
            
                if (src && !src.startsWith('data:')) {
                    
                    let response = await GetImageFromStorage(src)
                    
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        img.setAttribute('src', base64data);
                    
                    };
                    
                    let blob = await response.blob()

                    reader.readAsDataURL(blob);
                }
            }
        }catch (error) {
            throw error
        }
      };

    const renderActiveTab = (activeTab: number) => {
        switch (activeTab) {
            case 1:
                return (
                    <>
                        {isLoading === false ? (
                            <>
                                <Row className="pl-3 pt-md-2 pb-md-2 w-100">
                                    <Col>
                                        <h2>
                                            {CourseViewPaginatedSearchInProgress.totalRecords > 0 ? (
                                                CourseViewPaginatedSearchInProgress.totalRecords ===
                                                    1 ? (
                                                    <>
                                                        {CourseViewPaginatedSearchInProgress.totalRecords}{" "}
                                                        curso encontrado
                                                    </>
                                                ) : (
                                                    <>
                                                        {CourseViewPaginatedSearchInProgress.totalRecords}{" "}
                                                        cursos encontrados
                                                    </>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="pl-md-3 pt-md-2 pb-md-2 w-100 justify-content-center">
                                    {CourseViewPaginatedSearchInProgress.totalRecords > 0 ? (
                                        <>
                                            <Col md={12} className="d-none d-md-block ml-0">
                                                <Row>
                                                    <Col md={4} className="subTitle pl-5">
                                                        Curso
                                                    </Col>
                                                    <Col md={2} className="subTitle">
                                                        Status
                                                    </Col>
                                                    <Col md={3} className="subTitle">
                                                        Progresso
                                                    </Col>
                                                    <Col md={3} className="subTitle"></Col>
                                                </Row>
                                            </Col>
                                            {CourseViewPaginatedSearchInProgress.items.map((item) => {
                                                return (
                                                    <>
                                                        <ButtonCardTask className="d-none d-md-block w-100">
                                                            <Card.Body>
                                                                <Row style={{ alignItems: "center" }}>
                                                                    <Col md={4} className="my-auto">
                                                                        <h4>{item.title}</h4>
                                                                    </Col>
                                                                    <Col md={2} className="inProgressStatus">
                                                                        <Row>
                                                                            <Col md="auto" className="my-auto pl-0">
                                                                                <i className="bi bi-graph-up"></i>
                                                                            </Col>
                                                                            <Col md="auto" className="my-auto pl-0">
                                                                                <h4>Em Andamento</h4>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={2} className="pl-0">
                                                                        <ProgressBar
                                                                            now={item.porcentageComplete}
                                                                            variant="success"
                                                                        />
                                                                    </Col>
                                                                    <Col md="auto" className="progressPercentage">
                                                                        <h4>
                                                                            {item.porcentageComplete}% concluído
                                                                        </h4>
                                                                    </Col>
                                                                    <Col md="auto" className="ml-auto mr-3">
                                                                        <TertiaryGreenButton
                                                                            className="pl-2"
                                                                            onClick={() => ContinueCourse(item.id)}
                                                                        >
                                                                            <Row>
                                                                                <Col md="auto" className="my-auto">
                                                                                    <p>Continuar Curso</p>
                                                                                </Col>
                                                                                <Col md="auto" className="my-auto pl-0">
                                                                                    <i className="bi bi-arrow-right ml-0"></i>
                                                                                </Col>
                                                                            </Row>
                                                                        </TertiaryGreenButton>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </ButtonCardTask>
                                                        {/* Em andamento */}
                                                        <HoldCoursesMobile>
                                                            <Row className="d-block d-md-none">
                                                                <Col xs={12}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col xs={12}>
                                                                                    <img src={item.coverImage} />
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <h2>{item.title}</h2>
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <section className="hold-progress">
                                                                                        <Row className="hold-bar">
                                                                                            <Col xs={7}>
                                                                                                <ProgressBar
                                                                                                    now={item.porcentageComplete}
                                                                                                    variant="success"
                                                                                                />
                                                                                            </Col>
                                                                                            <Col xs={5}>
                                                                                                <span>
                                                                                                    {item.porcentageComplete}% concluído
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </section>
                                                                                </Col>
                                                                                <Col xs={12} className="hold-continue">
                                                                                    <TertiaryGreenButton
                                                                                        className="pl-2"
                                                                                        onClick={() => ContinueCourse(item.id)}
                                                                                    >
                                                                                        Continuar Curso
                                                                                        <i className="bi bi-arrow-right ml-0"></i>
                                                                                    </TertiaryGreenButton>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </HoldCoursesMobile>
                                                        {/* fim andamento */}
                                                    </>
                                                );
                                            })}
                                            {CourseViewPaginatedSearchInProgress.totalPages > 1 ? (
                                                <Row className="buttons-center" style={{ margin: "auto" }}>
                                                    <Col md={"auto"} className="mx-auto mt-3">
                                                        {RenderPageButtonsInProgress()}
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <NoCoursesStarted />
                                        </>
                                    )}
                                </Row>
                            </>
                        ) : (
                            <Row className="justify-content-center">
                                <ChangeLessonLoader sideMenuIsOpen={false} />
                            </Row>
                        )}
                    </>
                );
            case 2:
                return (
                    <>
                        {isLoading === false ? (
                            <>
                                <Row className="row pl-3 pt-2 pb-2 w-100">
                                    <Col>
                                        <h2>
                                            {CourseViewPaginatedSearchDone.totalRecords > 0 ? (
                                                CourseViewPaginatedSearchDone.totalRecords === 1 ? (
                                                    <>
                                                        {CourseViewPaginatedSearchDone.totalRecords} curso
                                                        encontrado
                                                    </>
                                                ) : (
                                                    <>
                                                        {CourseViewPaginatedSearchDone.totalRecords} cursos
                                                        encontrados
                                                    </>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="row pl-md-3 pt-2 pb-2 w-100 justify-content-center">
                                    {CourseViewPaginatedSearchDone.totalRecords > 0 ? (
                                        <>
                                            <Col xs={12} className="ml-0 d-none d-md-block">
                                                <Row className="">
                                                    <Col md={4} className="subTitle pl-5">
                                                        Curso
                                                    </Col>
                                                    <Col md={4} className="subTitle">
                                                        Status
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {CourseViewPaginatedSearchDone.items.map((item) => {
                                                return (
                                                    <>
                                                        <ButtonCardTask className="d-none d-md-block w-100">
                                                            <Card.Body>
                                                                <Row style={{ alignItems: "center" }}>
                                                                    <Col md={4}>
                                                                        <h4>{item.title}</h4>
                                                                    </Col>
                                                                    <Col md={3} className="finished">
                                                                        <Row>
                                                                            <Col md="auto" className="my-auto pl-0 pr-1">
                                                                                <i className="bi bi-check2-circle mr-3"></i>
                                                                            </Col>
                                                                            <Col md="auto" className="my-auto pl-0">
                                                                                <h4>Finalizado</h4>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    {item.enableCertificate ? (
                                                                        <Col md={2} style={{ textAlign: "right" }}>
                                                                            <TertiaryGreenButton onClick={() =>{seeCertificate(item.id)}}>
                                                                                <Row>
                                                                                    <Col md="auto" className="my-auto">
                                                                                        <i className="bi bi-trophy-fill mr-2"></i>
                                                                                    </Col>
                                                                                    <Col md="auto" className="my-auto pl-0">
                                                                                        <p>Ver certificado</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </TertiaryGreenButton>
                                                                        </Col>
                                                                    ) : (
                                                                        <Col md={2} />
                                                                    )}
                                                                    {item.deleted === false ? (
                                                                        <>
                                                                            <Col md="auto">
                                                                                <TertiaryGreenButton onClick={() => ContinueCourse(item.id)}>
                                                                                    <Row>
                                                                                        <Col md="auto" className="my-auto">
                                                                                            <i className="bi bi-arrow-repeat mr-3"></i>
                                                                                        </Col>
                                                                                        <Col md="auto" className="my-auto pl-0">
                                                                                            <p>Revisar Conteúdo</p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </TertiaryGreenButton>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col md={2} />
                                                                    )}
                                                                </Row>
                                                            </Card.Body>
                                                        </ButtonCardTask>
                                                        <HoldCoursesMobile>
                                                            <Row className="d-block d-md-none">
                                                                <Col xs={12}>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <Row>
                                                                                <Col xs={12}>
                                                                                    <img src={item.coverImage} />
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <h2>{item.title}</h2>
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <section className="hold-status">
                                                                                        <i className="bi bi-check2-circle"></i>
                                                                                        <h4>Finalizado</h4>
                                                                                    </section>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xs={12} className="hold-certificate">
                                                                                    <Row>
                                                                                        <Col xs={5}>
                                                                                            {item.deleted === false ? (
                                                                                                <section>
                                                                                                    <TertiaryGreenButton
                                                                                                        onClick={() =>
                                                                                                            ContinueCourse(item.id)
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="hold-review bi bi-arrow-repeat"></i>
                                                                                                        Revisar
                                                                                                    </TertiaryGreenButton>
                                                                                                </section>
                                                                                            ) : (
                                                                                                <div></div>
                                                                                            )}
                                                                                        </Col>
                                                                                        <Col xs={7}>
                                                                                            {item.enableCertificate ? (
                                                                                                <TertiaryGreenButton
                                                                                                    onClick={() => {seeCertificate(item.id)}}
                                                                                                >
                                                                                                    <i className="hold-trophy bi bi-trophy-fill mr-2"></i>
                                                                                                    <span>Ver certificado</span>
                                                                                                </TertiaryGreenButton>
                                                                                            ) : (
                                                                                                <Col md={12} />
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </HoldCoursesMobile>
                                                    </>
                                                );
                                            })}
                                            {CourseViewPaginatedSearchDone.totalPages > 1 ? (
                                                <Row
                                                    className="buttons-center"
                                                    style={{ margin: "auto" }}
                                                >
                                                    <Col md={"auto"} className="mx-auto mt-3">
                                                        {RenderPageButtonsDone()}
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <NoCoursesCompleted />
                                        </>
                                    )}
                                </Row>
                            </>
                        ) : (
                            <Row className="justify-content-center">
                                <ChangeLessonLoader sideMenuIsOpen={false} />
                            </Row>
                        )}
                    </>
                );
            default:
                return <></>;
        }
    };

    const RenderPageButtonsInProgress = (): JSX.Element => {
        let buttons: JSX.Element[] = [
            <ListGroup.Item>
                <Button
                    className={
                        CourseViewPaginatedSearchInProgress.currentPage === 1
                            ? "active"
                            : ""
                    }
                    onClick={() => getDistributedCoursesinProgress(1)}
                >
                    <p>1</p>
                </Button>
            </ListGroup.Item>,
        ];

        for (let i = 2; i < CourseViewPaginatedSearchInProgress.totalPages; i++) {
            buttons.push(
                <ListGroup.Item>
                    <Button
                        className={
                            "my-auto " +
                            (CourseViewPaginatedSearchInProgress.currentPage === i
                                ? "active"
                                : "")
                        }
                        onClick={() => getDistributedCoursesinProgress(i)}
                    >
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            );
        }

        if (CourseViewPaginatedSearchInProgress.totalPages > 1) {
            buttons.push(
                <ListGroup.Item>
                    <Button
                        className={
                            CourseViewPaginatedSearchInProgress.totalPages ===
                                CourseViewPaginatedSearchInProgress.currentPage
                                ? "active"
                                : ""
                        }
                        onClick={() =>
                            getDistributedCoursesinProgress(
                                CourseViewPaginatedSearchInProgress.totalPages
                            )
                        }
                    >
                        <p>{CourseViewPaginatedSearchInProgress.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            );
        }

        return (
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button
                        disabled={!CourseViewPaginatedSearchInProgress.hasPrevious}
                        onClick={() =>
                            getDistributedCoursesinProgress(
                                CourseViewPaginatedSearchInProgress.currentPage - 1
                            )
                        }
                    >
                        <i className="bi bi-chevron-left" />
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button
                        disabled={!CourseViewPaginatedSearchInProgress.hasNext}
                        onClick={() =>
                            getDistributedCoursesinProgress(
                                CourseViewPaginatedSearchInProgress.currentPage + 1
                            )
                        }
                    >
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>
        );
    };

    const RenderPageButtonsDone = (): JSX.Element => {
        let buttons: JSX.Element[] = [
            <ListGroup.Item>
                <Button
                    className={
                        CourseViewPaginatedSearchDone.currentPage === 1 ? "active" : ""
                    }
                    onClick={() => getDistributedCoursesDone(1)}
                >
                    <p>1</p>
                </Button>
            </ListGroup.Item>,
        ];

        for (let i = 2; i < CourseViewPaginatedSearchDone.totalPages; i++) {
            buttons.push(
                <ListGroup.Item>
                    <Button
                        className={
                            "my-auto " +
                            (CourseViewPaginatedSearchDone.currentPage === i ? "active" : "")
                        }
                        onClick={() => getDistributedCoursesDone(i)}
                    >
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            );
        }

        if (CourseViewPaginatedSearchDone.totalPages > 1) {
            buttons.push(
                <ListGroup.Item>
                    <Button
                        className={
                            CourseViewPaginatedSearchDone.totalPages ===
                                CourseViewPaginatedSearchDone.currentPage
                                ? "active"
                                : ""
                        }
                        onClick={() =>
                            getDistributedCoursesDone(
                                CourseViewPaginatedSearchDone.totalPages
                            )
                        }
                    >
                        <p>{CourseViewPaginatedSearchDone.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            );
        }

        return (
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button
                        disabled={!CourseViewPaginatedSearchDone.hasPrevious}
                        onClick={() =>
                            getDistributedCoursesDone(
                                CourseViewPaginatedSearchDone.currentPage - 1
                            )
                        }
                    >
                        <i className="bi bi-chevron-left" />
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button
                        disabled={!CourseViewPaginatedSearchDone.hasNext}
                        onClick={() =>
                            getDistributedCoursesDone(
                                CourseViewPaginatedSearchDone.currentPage + 1
                            )
                        }
                    >
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>
        );
    };

    return (
        <>
            {isLoading ? (<PageLoader />) :
                (<BodyLearning fluid={true}>
                    <h1 className="title-default-mob d-block d-md-none">Cursos Livres</h1>
                    <Row className="ml-0">
                        <Col>
                            <h2>Meu Aprendizado</h2>
                        </Col>
                    </Row>
                    <Row className="ml-0 pt-2">
                        <Col>
                            <h3>
                                Acesse seus cursos em andamento ou cursos já finalizados. Você
                                também pode acessar seus certificados e revisar os conteúdos.
                            </h3>
                        </Col>
                    </Row>
                    <Row className="ml-0">
                        <AttendCourseLessonNavBar className="">
                            <Nav variant="pills" defaultActiveKey="/home" className="mt-2 mb-4">
                                <Nav.Item className="d-none d-md-block mx-1">
                                    <Nav.Link
                                        active={activeTab === 1}
                                        onClick={() => changeActiveTab(1)}
                                        className="pt-3 pb-2"
                                    >
                                        Cursos em andamento
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="d-none d-md-block mx-1">
                                    <Nav.Link
                                        active={activeTab === 2}
                                        onClick={() => changeActiveTab(2)}
                                        className="pt-3 pb-2"
                                    >
                                        Cursos finalizados
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="d-block d-md-none mx-1">
                                    <Nav.Link
                                        active={activeTab === 1}
                                        onClick={() => changeActiveTab(1)}
                                        className="pt-3 pb-2"
                                    >
                                        Em andamento
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="d-block d-md-none mx-1">
                                    <Nav.Link
                                        active={activeTab === 2}
                                        onClick={() => changeActiveTab(2)}
                                        className="pt-3 pb-2"
                                    >
                                        Finalizados
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </AttendCourseLessonNavBar>
                    </Row>
                    <Row className="ml-0">
                        {renderActiveTab(activeTab)}
                        <CourseCertificationModal
                            show={modalCertificateShow}
                            onHide={() => setModalCertificateShow(false)}
                            certificateViewModel={certificateViewModel}
                            customCertificate={customCertificate}
                        />
                    </Row>
                </BodyLearning>)
            }
            <div ref={certificateRef} style={{display:'none'}}>
            </div>
        </>
    );
}

const HoldCoursesMobile = styled(Col)`
  @media screen and (max-width: 990px) {

    padding: 7px;
    margin: 10px auto 30px auto !important;

    .card {
      margin-left: 20px;
      border-radius: 12px 12px 0px 0px;
      border-color: #F8F8F8 !important;
      background: #F8F8F8 !important;
      position: relative;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;

      h2 {
        font-size: 6.4vmin;
        color: #514F4E;
        padding-left: 12px !important;
        font-weight: 500;
      }

      h4 {
        display: inline-flex !important;
        font-size: 5vmin !important;
        color: #218749;
        font-weight: normal;
      }

      i {
        display: inline-flex !important;
        font-size: 6vmin !important;
        margin-right: 10px !important;
        color: #218749;
        position: relative;
        top: 5px !important;
      }

      img {
        border-radius: 12px 12px 0px 0px;
        margin-bottom: 20px;
      }
    }

    .hold-status {
      text-align: left;
      margin: 6px 0px;
      padding: 6px 10px 10px 0px;
      border-bottom: 1px solid #C7C7C7;
      margin: auto;
      width: 94%;
    }

    .hold-progress {
      text-align: left;
      margin: 6px 0px;
      padding: 8px 10px 10px 0px;
      border-bottom: 1px solid #C7C7C7;
      margin: auto;
      width: 94%;
      text-align: right;
    }

    .hold-bar {
      padding-right: 0px !important;
      padding-top: 5px;

      .col-7 {
        padding-right:0;
      }

      .progress {
        margin-top: 6px;
        border-radius: 10px;
        height: 14px !important;
      }
      
      span {
        font-size: 4.4vmin;
      }
    }

    .hold-continue {
      text-align: right;

      button {
        i {
          padding-left: 7px;
        }
      }
    }

    .hold-certificate {
      display: block;
      padding: 0px 20px;
      margin-bottom: 3px;

      .col-5 {
        padding-left: 0px;
      }
      
      span {
        font-size: 5vmin !important;
        top: 2px !important;
        position: relative;
      }

      button {
        font-size: 5vmin !important;

        .hold-review {
          font-size: 6vmin !important;
        }

        .hold-trophy {
          font-size: 5.1vmin !important;
        }
      }
    }

    .card-body {
      padding: 0;
    }

    img {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      max-width: 100%;
    }
  }
`;
