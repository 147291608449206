import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import styled from 'styled-components';
import { SecondaryGreenButton } from '../../../../../../../components/Buttons/styles';
import { TooltipContainer } from '../../../../../../../components/Tooltip/styles';
import { useCreateLessonModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

export const LessonContentButtonBar = () => {

    const {
        module,
        selectedModuleContentIndex,
        handleLessonContentFocusLose,
        isValidModuleContent
    } = useContext(AddModuleContext);

    const {

        setShowVideoModal,
        setShowImageModal,
        setShowEmbedModal,
        setShowDocumentModal,
        addNewTextLessonComponent,
    } = useCreateLessonModuleContentContext()

    const AddTextContent = () => {
        addNewTextLessonComponent()
    }

    const AddImageContent = () => {
        setShowImageModal(true)
    }

    const AddVideoContent = () => {
        setShowVideoModal(true)
    }

    const AddDocumentContent = () => {
        setShowDocumentModal(true)
    }

    const AddEmbedContent = () => {
        setShowEmbedModal(true)
    }

    return (<>
        {
            module.moduleContents[selectedModuleContentIndex].title !== "" &&
                !isValidModuleContent &&
            (
                <TooltipRow>
                    <Col className="mx-2" md="auto">
                        <TooltipContainer className="mt-3">
                            <Row className="pl-2">
                                <Col md={1}>
                                    <i className="bi bi-arrow-down"></i></Col>
                                <Col md="auto">
                                    <p>Sua aula deve ter pelo menos um bloco de conteúdo</p>
                                </Col>
                            </Row>
                        </TooltipContainer>
                    </Col>
                </TooltipRow>
            )
        }

        <LessonContentButtonBarStyles>
            <Col md="auto" className='px-2' onClick={()=>handleLessonContentFocusLose()}/>
            <Col md="auto" className='pr-0'>
                <LessonContentTypeButton onClick={()=>AddTextContent()}>
                    <Row>
                        <Col md="auto" className='pr-0 my-auto'>
                            <i className='bi bi-text-left' />
                        </Col>
                        <Col className='my-auto'>
                            <p>
                                Texto
                            </p>
                        </Col>
                    </Row>
                </LessonContentTypeButton>
            </Col>
            <Col md="auto" className='pr-0'>
                <LessonContentTypeButton onClick={()=>AddImageContent()}>
                    <Row>
                        <Col md="auto" className='pr-0 my-auto'>
                            <i className='bi bi-image' />
                        </Col>
                        <Col className='my-auto'>
                            <p>
                                Imagem
                            </p>
                        </Col>
                    </Row>
                </LessonContentTypeButton>
            </Col>
            <Col md="auto" className='pr-0'>
                <LessonContentTypeButton onClick={()=>AddVideoContent()}>
                    <Row>
                        <Col md="auto" className='pr-0 my-auto'>
                            <i className='bi bi-camera-video' />
                        </Col>
                        <Col className='my-auto'>
                            <p>
                                Video
                            </p>
                        </Col>
                    </Row>
                </LessonContentTypeButton>
            </Col>
            <Col md="auto" className='pr-0'>
                <LessonContentTypeButton onClick={()=>AddDocumentContent()}>
                    <Row>
                        <Col md="auto" className='pr-0 my-auto'>
                            <i className='bi bi-file-text' />
                        </Col>
                        <Col className='my-auto'>
                            <p>
                                Documento
                            </p>
                        </Col>
                    </Row>
                </LessonContentTypeButton>
            </Col>
            <Col md="auto" className='pr-0'>
                <LessonContentTypeButton onClick={()=>AddEmbedContent()}>
                    <Row>
                        <Col md="auto" className='pr-0 my-auto'>
                            <i className='bi bi-link' />
                        </Col>
                        <Col className='my-auto'>
                            <p>
                                Incorporar
                            </p>
                        </Col>
                    </Row>
                </LessonContentTypeButton>
            </Col>
            <Col  onClick={()=>handleLessonContentFocusLose()}/>
        </LessonContentButtonBarStyles>

    </>);
};

const TooltipRow = styled(Row)`
    position: absolute;
    bottom: 14vmin;
`;

const LessonContentButtonBarStyles = styled(Row)`
    width:100%;
    position:absolute;
    bottom:0px;
    height:11vh;

    background: #F9F9F9;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.15);
`;

const LessonContentTypeButton = styled(SecondaryGreenButton)`
    margin-top:2vmin;
    p{
        font-size:2.22vmin;
    }
    i{
        font-size:3vmin;
    }
`;
