import {useContext} from "react"
import { CreateCourseContext } from "../../../context/creator/createCourse/CreateCourseContext"

import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { courseSteps } from './courseStep'
import CourseStepEnum from "../../../domain/enum/CourseStepEnum";

export const CreateCourseStepBar = () => {

    const { 
        isValid,
        isChanged,
        navigationStep,
        setNavigationStep,
        course, 
        hasDependency,
        submitStepData,
        isLoading
    } = useContext(CreateCourseContext);

    //TO DO: MELHORAR A LOGICA DESSA FUNCAO
    const renderCourseStepIconStatus = (step: number) => {
        if(step == CourseStepEnum.Dependency){
            if(course.courseStepId +1 >= CourseStepEnum.Dependency && !hasDependency){
                return (<i className="bi bi-dash-circle" />)
            }
        }

        if(step > course.courseStepId && navigationStep!= step){
            return (<p className="step-number">{step}</p>)
        }

        if(!isValid&& step === navigationStep){
            return (<i className="bi bi-clock" />)
        }

        if((step <= course.courseStepId)||(step <= course.courseStepId+1&&isValid)){
            return (<i className="bi bi-check2-circle" />)
        }          
    }

    const renderCourseStepStyle = (stepNumber:number):string =>{
        if(stepNumber == CourseStepEnum.Dependency){
            if(course.courseStepId +1 >= CourseStepEnum.Dependency && !hasDependency){
                return ""
            }
        }

        if(stepNumber==navigationStep&&!isValid){
            return "step-current"
        }
        if(stepNumber==navigationStep&&isValid||stepNumber <= course.courseStepId ){
            return "step-done"
        }

        return ""
    }

    const renderCourseStep = (stepRender:number) => 
    {
        if(isChanged){
            submitStepData(false,false,stepRender);
        }
        else{
            setNavigationStep(stepRender)
        }
    }

    return (
        <CreateCourseStepBarStyles fluid={true} className={isLoading ? "d-none" : ""}>
            <Row>
                {
                    courseSteps.map(buttonStep => {
                        return (
                            <Col md={"auto"} className={buttonStep.colClassName} key={buttonStep.id}>
                                <Button onClick={() => renderCourseStep(buttonStep.id)} 
                                    className={renderCourseStepStyle(buttonStep.id)+" "+(buttonStep.id === navigationStep?"selected-step":"")}
                                    disabled=
                                    {
                                        !(
                                            ((!isChanged||isValid)&& course.courseStepId >= buttonStep.id)||
                                             navigationStep == buttonStep.id||
                                             isValid&&buttonStep.id==course.courseStepId+2&&navigationStep==course.courseStepId+1||
                                             isValid&&buttonStep.id==course.courseStepId+1&&navigationStep<=course.courseStepId
                                        )
                                    }
                                    >
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0">
                                            {renderCourseStepIconStatus(buttonStep.id)}
                                        </Col>
                                        <Col md="auto" className="my-auto">
                                            <p>
                                                {buttonStep.description}
                                            </p>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        )
                    })
                }                
            </Row>        
        </CreateCourseStepBarStyles>
    );
};

export const CreateCourseStepBarStyles = styled(Container)`
    @media(min-height: 900px) {
       top: 7vh;        
    }
    @media(max-height: 900px) {
        top: 8vh;    
    }
    @media(max-height: 800px) {
        top: 9vh;
    }  
    @media(max-height: 700px) {
        top: 10vh;   
    }
    box-sizing: border-box;
    z-index:50;
    background:#F9F9F9;
    position:fixed;
    width:100%;
    border-radius:0px;

    i.bi-clock {
        color: #C57700;
        font-size:3.33vmin;
    }

    i.bi-check2-circle {        
        color: #108314;
        font-size:4vmin;     
    }
    i.bi-dash-circle { 
        color: #514F4E;  
        font-size:3.33vmin;     
    }

    p.step-number {
        border-radius: 50%;
        width: 4.16vmin;
        height: 4.16vmin;
        padding-top: 0.56vmin;
        border: 0.28vmin solid #514F4E;
        color: #514F4E;
        text-align: center;
        font-style: normal;
        font-weight: normal; 
        font-size:2.22vmin;
        
    }
    
    p{
        color: #514F4E;
        margin-bottom:0px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 20px;  
    }

    p.step-done     
    {
        color: #108314 !important;
    }
    p.step-current
    {
        color: #C57700 !important;
    }

    button {
        background: transparent !important;
        border: none;
        box-shadow:none!important;

        &:hover {
            background: transparent !important;
            color: #000;
            border: none !important;
            outline: none !important;
        }
        .btn-primary:not(:disabled):not(.disabled):active:focus{
            box-shadow:none!important;

        }
        &:active{
            box-shadow:none!important;

        }
        
        &:focus:not(:disabled) {
            outline: none !important;
            box-shadow:none!important;

        }
    }
    button.step-done     
    {
        p{
            color: #108314 !important;
        }
    }
    button.step-current
    {
        p{
            color: #C57700 !important;
        }
    }

    button.step-done.selected-step     
    {
        border-bottom: 3px solid#108314!important;
    }
    button.step-current.selected-step    
    {
        border-bottom: 3px solid#C57700!important;
    }
    button.selected-step    
    {
        border-bottom: 3px solid#514F4E!important;
    }
`
