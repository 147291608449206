import { useContext } from 'react';
import { Container, Modal } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddVideoModalContext } from '../context/AddVideoModalContext';
import { IndexScreen } from './IndexScreen';
import { SendFileScreen } from './SendFileScreen';
import { SendLinkScreen } from './SendLinkScreen';
import { UploadingScreen } from './UploadingScreen';

export const AddVideoModalBody = () => {
    const {
        isUploading, hiddenFileInput, onInputFileChange,
    } = useContext(AddVideoModalContext);
    return (
        <Modal.Body>
            <Container className="px-4">
                {isUploading ?
                    (
                        <UploadingScreen/>
                    )
                    :
                    (
                        <AddVideoModalNavigationPosition />
                    )}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={onInputFileChange}
                    className="d-none"
                    multiple={false}
                    accept=".mp4" />
            </Container>
        </Modal.Body>
    );

};

const AddVideoModalNavigationPosition = () => {
    const {
        navigationPosition
    } = useContext(AddVideoModalContext);

    switch (navigationPosition) {
        case AddFileNavigationPositionEnum.index: {
            return (
                <IndexScreen/>
            );
        }
        case AddFileNavigationPositionEnum.sendLink: {
            return (
                <SendLinkScreen/>
            );
        }
        case AddFileNavigationPositionEnum.sendFile: {
            return (
                <SendFileScreen/>
            );
        }
        default:
            return <></>;
    }

};


