import { useContext, useState } from "react";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import { SendTaskModal } from "../../AttentCoursePageStyles";
import { ChooseFileModal } from "./ChooseFileModal";
import { ConfirmSelectionFileModal } from "./ConfirmSelectionFileModal";
import { SendingTaskModal } from "./SendingTaskModal";
import SendTaskModalState from "../../../../../domain/enum/SendTaskModalState";
import SendTaskError from "./SendTaskError";
import Loader from "react-loader-spinner";
import { TertiaryRedButton, PrimaryDarkGrayButton, SecondaryGreenButton } from "../../../../../components/Buttons/styles";
import { ConfirmExitModal } from "./ConfirmExitModal";
import { SuccessfullSentTask } from "./SuccessfullSentTask";

export const DeliveryTaskModal = () => {
    const {
        showModal,
        handleClose
    } = useContext(TaskContext);

    return (
        <SendTaskModal
            centered
            show={showModal}
            onHide={()=>handleClose()}
            >
            <ModalContentState/>
        </SendTaskModal>
    );
};


const ModalContentState = () => {
    const {
        modalState,
    } = useContext(TaskContext);


    switch(modalState){
        case SendTaskModalState.ChooseFile:{
            return <ChooseFileModal/>
            //return <SendingTaskModal/>
            //return <SendTaskError/>
            //return <SuccessfullSentTask/>
            //return <ConfirmExitModal/>
            // return <ConfirmSelectionFileModal/>
        }
        case SendTaskModalState.ConfirmSelection:{
            return <ConfirmSelectionFileModal/>
        }
        case SendTaskModalState.UploadingFile:{
            return <SendingTaskModal/>
        }
        case SendTaskModalState.SuccessfullSent:{
            return (
                <SuccessfullSentTask/>
            )
        }
        case SendTaskModalState.ConfirmExit:{
            return (
                <ConfirmExitModal/>
            )
        }
        case SendTaskModalState.Error:{
            return (
                <SendTaskError/>
            )
        }
        default:
            return (
                <SendTaskError/>
            )
    }
};


