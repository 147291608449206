import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Container, Row } from "react-bootstrap";

import CreatorHeaderActiveTabEnum from '../../domain/enum/CreatorHeaderActiveTabEnum'
import HomeHeader from '../../components/Headers/HomeHeader'
import { AvaliableCourseForStudentComponent } from '../../components/PaginatedSearch/AvaliableCourseForApprenticeComponent';

import CategoryViewModel from '../../domain/models/CategoryViewModel';
import PaginatedSearchResult from '../../domain/util/PaginatedSearchResult';
import TrilhaStudentCardView from '../../domain/models/cardView/TrilhaStudentCardView';

import CourseService from '../../services/CourseService';
import CategoryServices from '../../services/CategoryServices';
import BigBrainFooter from '../../components/Footers/BigBrainFooter';
import { getHolderId, getUserName, isTeacher, isAdmin, isAdminGlobal } from '../../util/UserUtil';
import BrainzHolderDictionary from '../../domain/BrainzHolderDictionary';
import ReeducationFooter from '../../components/Footers/ReeducationFooter';
import ReactGA from "react-ga4";

const courseService = new CourseService();
const categoryService = new CategoryServices();

const HomePage = (): JSX.Element => {
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);


    const [error, setError] = useState(false);

    const [filterCategories, setFilterCategories] = useState<CategoryViewModel[]>([]);
    const [activeFilterCategories, setActiveFilterCategories] = useState<string[]>([]);

    const [
        trilhasStudentCardViewPaginatedSearch,
        setTrilhasStudentCardViewPaginatedSearch,
    ] = useState<PaginatedSearchResult<TrilhaStudentCardView>>(InitialState);

    useEffect(() => {
        pageLoad();
    }, []);

    function pageLoad() {
        let isCreator = (isTeacher() || isAdmin() || isAdminGlobal());

        categoryService
            .getCategoryFilter(isCreator)
            .then((response) => {
                setFilterCategories(response.result);
                setIsLoadingPage(false);
            })
            .catch((err) => {
                console.log(err);
            });

        if (isCreator) {
            ReactGA.send({ hitType: "pageview", page: "CreatorHome" });
        } else {
            ReactGA.send({ hitType: "pageview", page: "ApprenticeHome" });
        }

        getDistributedTrilhas(1);
    }

    function getDistributedTrilhas(pageNumber: number, categoriesFilter: string[] = []) {
        setIsLoadingFilter(true);
        setActiveFilterCategories(categoriesFilter);

        let isCreator = (isTeacher() || isAdmin() || isAdminGlobal());
        if (isCreator) {
            courseService
                .getDistributedToCreatorsPaginated({
                    pageNumber: pageNumber,
                    pageSize: 8,
                    orderColumn: "",
                    categories: categoriesFilter,
                })
                .then((response) => {
                    setTrilhasStudentCardViewPaginatedSearch(response.result);
                    setIsLoadingFilter(false);
                })
                .catch((err) => {
                    console.error(err)
                    setError(true);
                    setIsLoadingFilter(false);
                });
        } else {
            courseService.getDistributedToApprenticePaginated({
                pageNumber: pageNumber,
                pageSize: 8,
                orderColumn: "",
                categories: categoriesFilter
            }).then(response => {
                setTrilhasStudentCardViewPaginatedSearch(response.result)
                setIsLoadingFilter(false)
            }).catch(err => {
                console.error(err)
                setError(true)
                setIsLoadingFilter(false)
            })
        }
    }

    function redirectToCreate() {
        window.location.href = "/course/create/";
    }

    function redirectToTasks() {
        window.location.href = "/manage/tasks";
    }

    return (
        <>
            <HomePageLayout>
                <Container fluid={true} className="page-container">
                    <section className="d-block d-md-none d-lg-none">
                        <h3 className="titleMobile mb-4">Cursos livres</h3>
                    </section>
                    <Row className="content">
                        <Col md={5} className="d-none d-md-block d-lg-block">
                            <img
                                className="my-3 ml-5 home-image"
                                src={"/img/student/home/woman-learning.svg"}
                            />
                        </Col>
                        <Col md={7} className="my-auto">
                            <Row className="mb-3">
                                <Col>
                                    <h1>Boas-vindas, {getUserName()}!</h1>
                                </Col>
                            </Row>
                            {
                                isTeacher() || isAdmin() || isAdminGlobal() ?
                                    (
                                        <>
                                            <Row className="d-block d-md-none d-lg-none">
                                                <Col>
                                                    <p className="pMobile">
                                                        Que bom que você chegou! Vamos aprender?
                                                    </p>
                                                </Col>
                                            </Row>
                                            <section className="d-none d-md-block d-lg-block">
                                                <Row>
                                                    <Col>
                                                        <h2>O que você quer fazer hoje?</h2>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-4">
                                                    <Col md="auto">
                                                        <Button className="pb-3 create" onClick={redirectToCreate}>
                                                            <Row>
                                                                <Col>
                                                                    <i className="bi bi-plus-circle" />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-1">
                                                                <Col>
                                                                    <p>Criar Curso</p>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Button className="pb-3" onClick={redirectToTasks}>
                                                            <Row>
                                                                <Col>
                                                                    <i className="bi bi-file-check" />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-1">
                                                                <Col>
                                                                    <p>Corrigir Tarefas</p>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </section>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Row className="d-block d-md-none d-lg-none">
                                                <Col>
                                                    <p className="pMobile">
                                                        Que bom que você chegou! Vamos aprender?
                                                    </p>
                                                </Col>
                                            </Row>
                                            <section className="d-none d-md-block d-lg-block">
                                                <Row>
                                                    <Col>
                                                        <h2>Que bom que você chegou! Vamos aprender?</h2>
                                                    </Col>
                                                </Row>
                                            </section>
                                        </>
                                    )
                            }

                        </Col>
                    </Row>
                    <AvaliableCourseForStudentComponent
                        categoriesFilter={filterCategories}
                        trilhasStudentCardViewPaginatedSearch={
                            trilhasStudentCardViewPaginatedSearch
                        }
                        getDistributedTrilhas={getDistributedTrilhas}
                        isLoading={isLoadingFilter}
                        headPhrase={(isTeacher() || isAdmin() || isAdminGlobal() ? ("Você também pode assistir a estes cursos") : ("Cursos disponíveis para você"))}
                    />
                </Container>
            </HomePageLayout>
        </>
    );
};

export default HomePage;

export const HomePageLayout = (props: any): JSX.Element => {
    return (
        <HomeStyles>
            <HomeHeader activeTab={CreatorHeaderActiveTabEnum.home} />
            {props.children}
            {getHolderId().toUpperCase() === BrainzHolderDictionary.bigbrain && (
                <BigBrainFooter />
            )}
            {getHolderId().toUpperCase() === BrainzHolderDictionary.reeducation && (
                <ReeducationFooter />
            )}
        </HomeStyles>
    );
};

const HomeStyles = styled.div`
  overflow-y:scroll;
  max-height:100vh;
  ::-webkit-scrollbar{
      height:90vh;
      position:absolute;
      width: 1.5vmin;
      background: #E8E8E8;
  }

  ::-webkit-scrollbar-thumb{
      background:#B9B8B8;
  }
  ::-webkit-scrollbar-thumb:hover{
      background: #EA694C!important;
  }  
  .page-container{
    margin-top:9vh;
  }
  .titleMobile {
    color: #6468c8;
    font-size: 5vmin;
    margin-bottom: 7vmin;
    margin-top: 90px;
  }

  .titleSmallMobile {
    font-size: 5.8vmin;
    font-weight: 500;
  }

  .pMobile {
    font-size: 5.1vmin;
  }

  .content {
    img.home-image {
      height: 360px;
    }
    h1 {
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 3.8vmin;
      line-height: 4.4vmin;
      color: #6468c8;
    }
    h2 {
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 3.1vmin;
      line-height: 3.8vmin;
      color: #636060;
    }
    button {
      background: #ffffff !important;
      border: none;
      box-sizing: border-box;
      box-shadow: 0px 0.14vmin 0.55vmin rgba(75, 75, 75, 0.2);
      border-radius: 1.11vmin;
      min-width: 21.66vmin;
      min-height: 14.44vmin;
      :hover {
        background: #e1f4f7 !important;
      }
      :focus {
        box-sizing: border-box;
        border-radius: 1.11vmin;
      }
      &:focus {
        box-shadow: #fff 0 0 0 0.42vmin, #0c8292 0 0 0 0.56vmin !important;
      }
      :active {
        box-shadow: 0px 0.14vmin 0.55vmin rgba(75, 75, 75, 0.2) !important;
      }
      p {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 2.63vmin;
        color: #636060;
        margin-bottom: 0px;
      }
      i {
        font-size: 4.44vmin;
        color: #636060;
      }
    }
    button.create {
      p {
        color: #0c8292;
      }
      i {
        color: #0c8292;
      }
    }
    // mobile
    @media screen and (max-width: 990px) {
      h1 {
        font-size: 6vmin;        
      }
    }
  }
`;

const InitialState: PaginatedSearchResult<TrilhaStudentCardView> = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 8,
    totalRecords: 8,
    hasPrevious: false,
    hasNext: true,
    items: [
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
        {
            id: "",
            title: "",
            description: "",
            coverImage: "",
            workLoad: "",
            favorited: true,
        },
    ],
};
