import { createContext, ReactNode, useContext, useState } from "react";
import { CreateCourseContext } from "./CreateCourseContext";
import { AddImageObject } from "../../../components/Modals/AddImageModal/context/AddImageObject";
import { AddModuleContext } from "./AddModuleContext";
import {produce} from 'immer';
import ModuleViewModel from "../../../domain/models/course/module/ModuleViewModel";
import AssessmentViewModel from "../../../domain/models/course/module/assessment/AssessmentViewModel";

const CreateAssessementModuleContentContext = createContext({} as CreateAssessementModuleContentContextData);
interface CreateAssessementModuleContentContextProviderProps {
    children: ReactNode;
}

export function CreateAssessementModuleContentContextProvider(props: CreateAssessementModuleContentContextProviderProps) {

    const {
        setIsChanged, reRender
    } = useContext(CreateCourseContext);

    const {
        getSelectedModuleContent,
        setIsValidModuleContent,
        validateModuleContent, 
        module,
        setModule,
        selectedModuleContentIndex
    } = useContext(AddModuleContext);

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(-1);

    const [showConfirmDeleteQuestionModal, setShowConfirmDeleteQuestionModal] = useState(false);

    const [showQuestionImageModal, setShowQuestionImageModal] = useState(false);

    const [showAssessementForm, setShowAssessementForm] = useState(true);


    const handleAssessmentDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.description = e.target.value
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsChanged(true);
        reRender();
    };

    const handleAssessmentMinimunGradeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            let newMinimunGrade = parseInt(e.target.value);
            if (!Number.isNaN(newMinimunGrade) && newMinimunGrade <= 100 && newMinimunGrade >= 0) {
                draft.minimumGrade = newMinimunGrade;
            } else {
                draft.minimumGrade= null;
            }
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleQuestionDelete = (questionIndex: number): void => {
        setSelectedQuestionIndex(questionIndex);
        setShowConfirmDeleteQuestionModal(true);
        reRender();
    };

    const handleCancelDeleteQuestion = (): void => {
        setSelectedQuestionIndex(-1);
        setShowConfirmDeleteQuestionModal(false);
        reRender();
    };

    const deleteQuestion = (): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions.splice(selectedQuestionIndex, 1)
            for (let i = 0; i < draft.assessmentQuestions.length; i++) {
                draft.assessmentQuestions[i].order = (i);
            }
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement


        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]));

        setSelectedQuestionIndex(-1);
        setShowConfirmDeleteQuestionModal(false);
        setIsChanged(true);
        reRender();
    };

    const handleAddQuestion = (): void => {

        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {
            for (let i = 0; i < draft.assessmentQuestions.length; i++) {
                draft.assessmentQuestions[i].minimized = true;
            }

            draft.assessmentQuestions =  draft.assessmentQuestions.concat({
                order: draft.assessmentQuestions.length,
                minimized: false,
                content: '',
                hint: '',
                imageUrl: '',
                hintCollapsed: true,
                assessmentAnswers: [
                    {
                        order: 0,
                        content: '',
                        isRightAnswer: true
                    },
                    {
                        order: 1,
                        content: '',
                        isRightAnswer: false
                    }
                ]
            });
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement


        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleQuestionMinimize = (questionIndex: number): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].minimized= !draft.assessmentQuestions[questionIndex].minimized  
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement


        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleQuestionTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, questionIndex: number): void => {

        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].content= e.target.value     
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement


        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleQuestionHintChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, questionIndex: number): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].hint= e.target.value     
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleAddQuestionImage = (imageObject: AddImageObject) => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[selectedQuestionIndex].imageUrl= imageObject.ImageUrl.valueOf()        
            
        })

        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement


        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        setShowQuestionImageModal(false)
        reRender();
        
    };

    const handleAddAnswer = (questionIndex: number): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].assessmentAnswers = draft.assessmentQuestions[questionIndex].assessmentAnswers?.concat({
                order: draft.assessmentQuestions![questionIndex].assessmentAnswers.length,
                content: '',
                isRightAnswer: false
            });
            
        })

        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))

        setIsChanged(true);

        setShowQuestionImageModal(false)

        reRender();

    };

    const returnMinimunGradeText = (): string => {
        const moduleContent = getSelectedModuleContent();

        if (moduleContent.assessment!.minimumGrade! !== null) {
            return moduleContent.assessment!.minimumGrade!.toString();
        }

        return "";
    };

    const handleIsAssessmentActive = () => {
        const moduleContent = getSelectedModuleContent();

        moduleContent.isRequired = !moduleContent.isRequired
        setIsChanged(true)
        reRender()
    }

    const clearQuestionImage = (questionIndex:number): void => {

        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].imageUrl= ''        
            
        })

        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))

        setIsChanged(true);

        setShowQuestionImageModal(false)

        reRender();

    }

    
    const handleAnswerContentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, answerIndex: number, questionIndex: number): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].assessmentAnswers[answerIndex].content = e.target.value;

            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleAnswerDelete = (questionIndex: number, answerIndex: number): void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            draft.assessmentQuestions[questionIndex].assessmentAnswers.splice(answerIndex, 1)

            for (let i = 0; i < draft.assessmentQuestions[questionIndex].assessmentAnswers.length; i++) {
                draft.assessmentQuestions[questionIndex].assessmentAnswers[i].order = (i);
            }
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();
    };

    const handleMarkRightAnswer = (questionIndex: number, answerIndex: number):void => {
        const newAssessement = produce<AssessmentViewModel>(module.moduleContents[selectedModuleContentIndex].assessment!, (draft) => {

            let answers = draft.assessmentQuestions[questionIndex].assessmentAnswers

            for (let i = 0; i < answers.length; i++) {
                draft.assessmentQuestions[questionIndex].assessmentAnswers[i].isRightAnswer = false;
            }

            draft.assessmentQuestions[questionIndex].assessmentAnswers[answerIndex].isRightAnswer = true
            
        })
        module.moduleContents[selectedModuleContentIndex].assessment = newAssessement

        setIsValidModuleContent(validateModuleContent(module.moduleContents[selectedModuleContentIndex]))
        setIsChanged(true);
        reRender();


    };


    return (
        <>
            <CreateAssessementModuleContentContext.Provider value={{

                selectedQuestionIndex,
                setSelectedQuestionIndex,

                showQuestionImageModal, 
                setShowQuestionImageModal,

                showAssessementForm, 
                setShowAssessementForm,

                showConfirmDeleteQuestionModal, 
                setShowConfirmDeleteQuestionModal,

                handleIsAssessmentActive,

                handleAssessmentDescriptionChange,

                handleAssessmentMinimunGradeChange,

                handleQuestionDelete,

                handleAddQuestion,

                handleQuestionMinimize,

                handleQuestionTitleChange,

                handleQuestionHintChange,

                handleAddAnswer,

                deleteQuestion,

                handleCancelDeleteQuestion,

                returnMinimunGradeText,

                handleAddQuestionImage,

                clearQuestionImage,
                handleAnswerContentChange,
                handleAnswerDelete,
                handleMarkRightAnswer
            }}>
                
                {showAssessementForm&&props.children}
            </CreateAssessementModuleContentContext.Provider>
        </>
    );
}

export function useCreateAssessementModuleContentContext() {
    return useContext(CreateAssessementModuleContentContext);
}
interface CreateAssessementModuleContentContextData {
    selectedQuestionIndex: number
    setSelectedQuestionIndex: React.Dispatch<React.SetStateAction<number>>
    
    showConfirmDeleteQuestionModal: boolean
    setShowConfirmDeleteQuestionModal: React.Dispatch<React.SetStateAction<boolean>>

    showAssessementForm: boolean
    setShowAssessementForm: React.Dispatch<React.SetStateAction<boolean>>

    showQuestionImageModal: boolean
    setShowQuestionImageModal: React.Dispatch<React.SetStateAction<boolean>>

    handleIsAssessmentActive: () => void
    handleAssessmentDescriptionChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    handleAssessmentMinimunGradeChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    returnMinimunGradeText: () => string
    
    handleAddQuestion: () => void
    handleQuestionTitleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, questionIndex: number) => void
    handleQuestionHintChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, questionIndex: number) => void
    handleQuestionMinimize: (questionIndex: number) => void
    handleQuestionDelete: (questionIndex: number) => void
    deleteQuestion: (questionIndex: number) => void
    handleCancelDeleteQuestion: () => void
    
    handleAddAnswer: (questionIndex: number) => void
    handleAddQuestionImage:(imageObject:AddImageObject) => void

    clearQuestionImage:(questionIndex: number)=> void
    handleAnswerContentChange:(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, lessonIndex: number, questionIndex: number)=> void
    handleAnswerDelete:(questionIndex: number, answerIndex: number) => void
    handleMarkRightAnswer:(questionIndex: number, answerIndex: number) => void

}
