import { useContext } from 'react';
import { Col, Container, Modal, Row } from "react-bootstrap";
import { PrimaryPurpleButton, SecondaryYellowButton } from '../../Buttons/styles';
import { AddImageModalContext } from './context/AddImageModalContext';
import { ConfirmExitAddImageModalStyles } from './AddImageModalStyles';

export const ConfirmExitAddImageModal = () => {
    const {
        showConfirmExitAddImage, onConfirmClose, onCancelClose
    } = useContext(AddImageModalContext);


    return (
        <ConfirmExitAddImageModalStyles
            show={showConfirmExitAddImage}
        >
            <Modal.Body className="px-0">
                <Container className="px-0">
                    <Row>
                        <Col>
                            <i className='bi bi-exclamation-circle' />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                Deseja cancelar o envio?
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <p>
                                Você vai perder o conteúdo inserido.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <SecondaryYellowButton onClick={onConfirmClose}>
                                <Row>
                                    <Col md="auto" className="pr-0">
                                        <i className='bi bi-x' />
                                    </Col>
                                    <Col className="my-auto">
                                        <p>
                                            Cancelar envio
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryYellowButton>
                        </Col>
                        <Col>
                            <PrimaryPurpleButton className="py-2" onClick={onCancelClose}>
                                <Col className="py-1 px-4">
                                    <p>
                                        Continuar envio
                                    </p>
                                </Col>
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </ConfirmExitAddImageModalStyles>
    );
};

