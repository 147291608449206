import Loader from "react-loader-spinner";
import { Col, Container, Row } from "react-bootstrap"
import styled from 'styled-components';


const PreparingCourse = (props:any) => {

    return(
        <Container fluid={true}>
            <PreparingCourseComponentStyle>
                <Col className="my-auto">
                    <Row>
                        <Col xs={12} md={"auto"} className="mx-auto">
                            <img src={"/img/student/attend/men-mounting-lego.svg"} /> 
                        </Col>  
                    </Row>
                    <Row className="mt-3"> 
                        <Col xs={12} md={"auto"} className="ml-auto">
                            <PreparingLoader
                                type="TailSpin"
                                color="#EA694C"
                            />

                        </Col>  
                        <Col xs={12} md={"auto"} className="mr-auto">
                            <PreparingHeader>
                                Preparando seu curso...
                            </PreparingHeader>
                        </Col>
                    </Row>
                </Col>
            </PreparingCourseComponentStyle>
        </Container>
    )
}

export default PreparingCourse;

const PreparingCourseComponentStyle = styled(Row)`
    min-height:80vh;

    @media screen and (max-width:990px){
        text-align: center;
        display: block;

        img {
            margin: auto;
            max-height: 50vmin !important;
        }
    }
`

const PreparingHeader = styled.h2`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 3.33vmin;
    line-height: 3.9vmin;
    display: flex;
    align-items: center;
    color: #514F4E;  
    
    @media screen and (max-width:990px){
        margin-top: 25px;
        font-size: 5vmin;
        display: block;
        text-align: center;
    }
`
const PreparingLoader = styled(Loader)`
    svg{
        width:3.33vmin;
        height:3.33vmin;
    }

    @media screen and (max-width:990px){
        svg{
            width:15vmin !important;
            height:15vmin !important;
            margin: auto;
        }
    }
`


