import ApiInterface from "../interface/ApiInterface"
import IByInstitutionPaginatedPayLoad from "../domain/payloads/task/IByInstitutionPaginatedPayLoad";
import ITaskCorrectionFeedbackPayload from "../domain/payloads/task/ITaskCorrectionFeedbackPayload";
import ITaskFeedbackPayLoad from "../domain/payloads/task/ITaskFeedbackPayLoad";

export default class TaskServices {

    // /api/v1/Task/status/{taskStatusId}
    public async getTasksByStatus(payload: IByInstitutionPaginatedPayLoad) {
        return await ApiInterface.get('/task/status/'.concat(payload.taskStatusId.toString())
            .concat('?pageNumber=').concat(payload.pageNumber.toString())
            .concat('&pageSize=').concat(payload.pageSize.toString())
            .concat('&taskStatusId=').concat(payload.taskStatusId.toString())
            .concat('&orderColumn=').concat(payload.orderColumn)
        )
            .then(res => {
                return res.data;
            }).catch(err => {
                console.log(err);
            })
    }

    // /api/v1/Task/{id}
    public async getById(id: string) {
        return await ApiInterface.get('/task/'.concat(id), {})
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    // /api/v1/Task/feedback/course/{courseId}/moduleContentId/{ModuleContentId}
    public async getFeedback(payload: ITaskFeedbackPayLoad) {
        return await ApiInterface.get('/task/feedback/course/'
            .concat(payload.courseId.toString())
            .concat('/moduleContentId=').concat(payload.moduleContentId.toString()), {
        })
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    // /api/v1/Task/{id}/correction
    public async correctionTask(data: ITaskCorrectionFeedbackPayload) {
        return await ApiInterface.post('/task/'.concat(data.id.toString()).concat('/correction'), data)
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}
