import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { AddFileNavigationPositionEnum as AddFileNavigationPositionEnum } from "../../../../domain/enum/AddFileNavigationPosition";
import { FileUploadServices } from '../../../../services/FileUploadServices'
import { AddImageObject } from "./AddImageObject";
import { ImageSourceEnum } from "./ImageSourceEnum";

const uploadService = new FileUploadServices();

export const AddImageModalContext = createContext({} as AddImageModalContextData);

interface AddImageModalContextProps {
    getImageFromModal: (videoObject:AddImageObject) => void
    onClose: () => void
 

    reRender:()=>void
    children: ReactNode;
}

interface AddImageModalContextData {
    isInvalidVideoInput: boolean
    setIsInvalidVideoInput: React.Dispatch<React.SetStateAction<boolean>>

    isUploading: boolean
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>

    showConfirmExitAddImage: boolean
    setShowConfirmExitAddImage: React.Dispatch<React.SetStateAction<boolean>>

    showAddImageModal: boolean
    setShowAddImageModal: React.Dispatch<React.SetStateAction<boolean>>

    navigationPosition:AddFileNavigationPositionEnum
    setNavigationPosition:React.Dispatch<React.SetStateAction<AddFileNavigationPositionEnum>>

    imageObject:AddImageObject
    setImageObject:React.Dispatch<React.SetStateAction<AddImageObject>>

    errorMessage:string
    setErrorMessage:React.Dispatch<React.SetStateAction<string>>

    onSubmit:()=> void
    handleVideoLinkChange:(e:any)=> void
    handleAddFileButton:()=> void
    isVideoValid:()=> boolean

    cancelUpload:()=> void
    onClose:()=> void
    onConfirmClose: () => void
    onCancelClose: () => void 

    hiddenFileInput:React.RefObject<HTMLInputElement>
    onInputFileChange:(e:any)=> void
}

export const AddImageModalContextProvider = (props: AddImageModalContextProps) => {
    
    const [isUploading, setIsUploading] = useState(false);
    const [showConfirmExitAddImage, setShowConfirmExitAddImage] = useState(false)
    const [showAddImageModal, setShowAddImageModal] = useState(true)
    const [navigationPosition, setNavigationPosition] = useState<AddFileNavigationPositionEnum>(AddFileNavigationPositionEnum.index)
    const [isInvalidImageInput,setIsInvalidImageInput] = useState(false)

    const [imageObject, setImageObject] = useState<AddImageObject>({ImageUrl:""} as any)

    const [errorMessage,setErrorMessage] = useState("")
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const onInputFileChange = (e) => {
        setIsUploading(true);
        setIsInvalidImageInput(false)


        const fileUploaded = e.target.files[0]

        if (((fileUploaded.type === "image/png"||fileUploaded.type === "image/jpeg"||fileUploaded.type === "image/jpg" )&& (fileUploaded.size/1024/1024) < 100)) {

            const formData = new FormData();
            formData.append('File', fileUploaded);
            formData.append('ValidatedFormat', "true");

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data, image/*, video/*'
                }
            }

            uploadService.uploadTempContainer(formData, config).then(function (response) {
                if (response.success) {

                    var imageDetails = imageObject;
                    imageDetails.ImageUrl = response.result.path
                    imageDetails.ImageName = response.result.name
                    imageDetails.ImageSource = ImageSourceEnum.Internal

                    setImageObject(imageDetails)
                    setIsUploading(false)

                }
            }).catch(err => {
                setErrorMessage("Não conseguimos carregar seu arquivo, tente novamente")
                setIsInvalidImageInput(true)
                console.log(err);
            });

            //setUploadMessage(fileUploaded.name)

        }

        if ((fileUploaded.size/1024/1024) > 100) {
            setErrorMessage("O arquivo é muito grande, escolha um arquivo com até 100MB")
            setIsInvalidImageInput(true)
            setIsUploading(false)

        }

        
        if ((fileUploaded.type !== "image/jpg" &&fileUploaded.type !== "image/jpeg" && fileUploaded.type !== "image/png")) {
            setErrorMessage("Formato não suportado")
            setIsInvalidImageInput(true)
            setIsUploading(false)
        }

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
        }
    }

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
            hiddenFileInput.current.click();
        }
    }

    const onConfirmClose = () => {
        props.onClose()
    };

    const onCancelClose = () => {
        setShowAddImageModal(true);
        setShowConfirmExitAddImage(false);
    };
    
    const onClose = ()=>{
        if(imageObject.ImageUrl === ""){
            onConfirmClose()
        }
        else{
            setShowConfirmExitAddImage(true)
            setShowAddImageModal(false);
            props.reRender()
        }
    }

    const cancelUpload = ()=>{
        setIsUploading(false)
    }

    const handleVideoLinkChange = (e) => {
        let properties = imageObject;
        properties.ImageUrl = e.target.value
        properties.ImageName = ""
        properties.ImageSource = ImageSourceEnum.External

        setImageObject(properties)
        setIsInvalidImageInput(!isImageValid)
        setErrorMessage("Este link não é válido, confira ou tente outro link")
        setImageObject(properties)
        props.reRender()

    }

    const isImageValid = ():boolean =>{
        return (imageObject.ImageUrl !== "")
    }

    const onSubmit = () =>{
        props.getImageFromModal(imageObject)

        setNavigationPosition(AddFileNavigationPositionEnum.index)
        setImageObject({
            ImageUrl:"",
            ImageName:"",
            ImageSource: ImageSourceEnum.Undefined
        })
        setShowAddImageModal(false)
        props.reRender()
    }


    return (
        <AddImageModalContext.Provider
            value={{
                isInvalidVideoInput: isInvalidImageInput,
                setIsInvalidVideoInput: setIsInvalidImageInput,

                isUploading,
                setIsUploading,
            
                showConfirmExitAddImage,
                setShowConfirmExitAddImage,
            
                showAddImageModal,
                setShowAddImageModal,
            
                navigationPosition,
                setNavigationPosition,
            
                imageObject,
                setImageObject,
            
                errorMessage,
                setErrorMessage,
            
                onSubmit,
                handleVideoLinkChange,
                handleAddFileButton,
                isVideoValid: isImageValid,

                cancelUpload,
                onClose,
                onConfirmClose,
                onCancelClose,
            
                hiddenFileInput,
                onInputFileChange
            }}
        >
            {props.children}
        </AddImageModalContext.Provider>
    );
};




