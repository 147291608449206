import { createContext, useContext, ReactNode, useEffect, useState } from "react";
import { getUserId } from "../../../util/UserUtil";

//Components
import AssessmentFeedbackResult from '../../../domain/models/course/module/assessment/AssessmentFeedbackResult';

//Contexts
import {AttendCourseContext} from "./AttendCourseContext";

//Services
import AssessmentService from '../../../services/AssessmentService'
import { AnsweredQuestion } from "../../../domain/payloads/quiz/QuizResponsePayloads";
import { ApprenticeProgressStatusEnum } from "../../../domain/enum/ApprenticeProgressStatusEnum";
import AssessmentQuestionViewModel from "../../../domain/models/course/module/assessment/AssessmentQuestionViewModel";

const asessmentService = new AssessmentService();

interface AssessmentContextData {
    assessmentFeedbackResult : AssessmentFeedbackResult,
    setAssessmentFeedbackResult : React.Dispatch<React.SetStateAction<AssessmentFeedbackResult>>

    showFeedback: boolean
    setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>

    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>

    moduleTitle: string
    setModuleTitle: React.Dispatch<React.SetStateAction<string>>

    showModalExit: boolean
    setShowModalExit: React.Dispatch<React.SetStateAction<boolean>>

    sendingResponse: boolean
    setSendingResponse: React.Dispatch<React.SetStateAction<boolean>>

    markedAnswers:AnsweredQuestion[]
    setMarkedAnswers: React.Dispatch<React.SetStateAction<AnsweredQuestion[]>>

    currentQuestion: number
    setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>

    questions:AssessmentQuestionViewModel[]
    handleAnswerQuestion(questionId: string, anwerId: string): void

    isAnswerMarked:(answerId: string) => boolean
    IsQuestionAnswered:()=> boolean 
    nextQuestion:() => void
    previousQuestion:() => void
    finishAssessment:() => void

    reRender:() => void
    isReproved: () => boolean
    handleLearningHintCollapse: (index: number) => void
    redoAssessment:() => void
    goBackCourse:() => void
    exitAssessment:() => void
}

export const AssessmentContext = createContext({} as AssessmentContextData)

interface AssessmentContextProviderProps {    
    children: ReactNode
}

export const AssessmentContextProvider = (props: AssessmentContextProviderProps) => {    

    const {
        courseDetails,
        currentUserModuleContentProgress,
        setIsLoadingAssessment,
        apprenticeCourseProgress,
        changeModuleContentState
    } = useContext(AttendCourseContext);   
    
    const [isLoading, setLoading] = useState(true);    
    const [reload, callReload] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);   
    const [showModalExit, setShowModalExit] = useState(false);
    const [moduleTitle, setModuleTitle] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [markedAnswers, setMarkedAnswers] = useState<AnsweredQuestion[]>([]);  
    const [sendingResponse, setSendingResponse] = useState(false);    
    
    const [assessmentFeedbackResult, setAssessmentFeedbackResult] = useState<AssessmentFeedbackResult>({
        courseHasCertificate: false,
        quizHasGrade: false,
        quizGrade: 0,
        apprenticeQuizGrade: 0,
        qtyCorrectQuestions: 0,
        qtyWrongQuestions: 0,    
        questions: []
    })

    const questions = currentUserModuleContentProgress.assessment!.assessmentQuestions

    function reRender(){
        callReload(!reload)
    }

    useEffect(() => {
        let module = courseDetails.modules.find((mod) => {
            return mod.id === currentUserModuleContentProgress.moduleId;
        })
        if (module) setModuleTitle(module.title);
        pageLoad();        
    }, []);
  
    function pageLoad() {
        //if (showFeedback) {
            asessmentService.Feedback({
                courseId: courseDetails.id,
                moduleContentId: currentUserModuleContentProgress.id,
                apprenticeId: getUserId()
            }).then(response => {                
                setAssessmentFeedbackResult(response.result)
                setLoading(false);        
            }).catch(err => {
                console.log(err);
            })
        //}
    }


    function handleLearningHintCollapse(index: number) {
        let newAttepmtState = assessmentFeedbackResult!
        if (newAttepmtState.questions[index].hintCollapsed === undefined) {
            newAttepmtState.questions[index].hintCollapsed = true;
        } else {
            newAttepmtState.questions[index].hintCollapsed = !newAttepmtState.questions[index].hintCollapsed;
        }

        setAssessmentFeedbackResult(newAttepmtState);
        reRender();
    }    

    function redoAssessment() {
        setIsLoadingAssessment(true)
        setShowFeedback(false)
        setShowModalExit(false)
    }

    function goBackCourse()
    {
        setIsLoadingAssessment(false)
    }

    function exitAssessment()
    {
        setIsLoadingAssessment(false) // indica que nao ira mostar a avaliacao mais
        setShowModalExit(false) //fecha o modal
    }


    function isAnswerMarked(answerId: string): boolean {
        

        let index = markedAnswers.findIndex(markedAnswer => {
            return (markedAnswer.questionId === questions[currentQuestion].id!
                && markedAnswer.answerId === answerId)
        });

        return (index > -1);
    }

    function IsQuestionAnswered(): boolean {
        let index = markedAnswers.findIndex(markedAnswer => {
            return (markedAnswer.questionId === questions[currentQuestion].id!)
        })

        return (index > -1);
    }

    function isReproved(): boolean {
        if (assessmentFeedbackResult == null) {
            return true;
        } else {
            return (assessmentFeedbackResult.quizGrade > assessmentFeedbackResult.apprenticeQuizGrade);
        }
    }


    function nextQuestion() {
        setCurrentQuestion(currentQuestion + 1)
    }

    function previousQuestion() {
        setCurrentQuestion(currentQuestion - 1)
    }

    function finishAssessment() {
        setSendingResponse(true)
        asessmentService.Response({
            courseId: courseDetails.id,
            moduleContentId: currentUserModuleContentProgress.id,
            answers: markedAnswers
        }).then(response => {
            setSendingResponse(false);
            setAssessmentFeedbackResult(response.result);
            setShowFeedback(true);
            updateApprenticeAndCurrentProgressStatus(response.result);
            setMarkedAnswers([])
            setCurrentQuestion(0)
        })
    }

    function updateApprenticeAndCurrentProgressStatus(feedbackResponse: any) {
        if (feedbackResponse.apprenticeQuizGrade >= feedbackResponse.quizGrade)
        {
            changeModuleContentState(ApprenticeProgressStatusEnum.Done, currentUserModuleContentProgress)
        } else {
            changeModuleContentState(ApprenticeProgressStatusEnum.Sent, currentUserModuleContentProgress)
        }
    }

    function handleAnswerQuestion(questionId: string, anwerId: string) {
        let newMarkedAnswers = markedAnswers.filter(markedAnswer => {
            return (markedAnswer.questionId !== questionId)
        })

        setMarkedAnswers(newMarkedAnswers.concat({
            questionId: questionId,
            answerId: anwerId
        }))
    }

  

    return (<AssessmentContext.Provider
        value={{
            assessmentFeedbackResult,
            setAssessmentFeedbackResult,           
            showFeedback, setShowFeedback,        
            isLoading, setLoading,
            moduleTitle, setModuleTitle,
            showModalExit, setShowModalExit,
            sendingResponse, setSendingResponse,
            markedAnswers, setMarkedAnswers,
            currentQuestion, setCurrentQuestion,
            questions,

            isAnswerMarked,
            IsQuestionAnswered,
            nextQuestion,
            previousQuestion,
            finishAssessment,

            handleAnswerQuestion,
            reRender,
            isReproved,
            handleLearningHintCollapse,
            redoAssessment,
            goBackCourse,
            exitAssessment
        }}>
        {
            props.children
        }
    </AssessmentContext.Provider>);
}