import { useContext } from 'react';
import { Modal } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddVideoModalContext } from '../context/AddVideoModalContext';

export const AddVideoModalTitle = () => {
    const {
        navigationPosition,
    } = useContext(AddVideoModalContext);

    switch (navigationPosition) {
        case AddFileNavigationPositionEnum.index: {
            return (
                <Modal.Title>
                    Enviar Vídeo
                </Modal.Title>
            );
        }
        case AddFileNavigationPositionEnum.sendFile: {
            return (
                <Modal.Title>
                    <span>Enviar Vídeo {">"}</span> Enviar Arquivo
                </Modal.Title>
            );
        }
        case AddFileNavigationPositionEnum.sendLink: {
            return (
                <Modal.Title>
                    <span>Enviar Vídeo {">"}</span> Enviar da internet
                </Modal.Title>
            );
        }
        default:
            return <></>;
    }
};
