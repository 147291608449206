import { createContext, ReactNode, useEffect, useState } from "react";
import { useRouteMatch, match } from "react-router";
import ReactGA from "react-ga4";
import CourseDetailView from '../../../domain/models/course/CourseDetailView';
import { mock } from "../../../pages/apprentice/attend/mock";
import { 
    UsersCourseProgressView, UserModuleContentProgressView, UsersModuleProgressView 
} from "../../../domain/models/course/UsersCourseProgressView";
import { ApprenticeProgressStatusEnum } from "../../../domain/enum/ApprenticeProgressStatusEnum";
import { CollapsableItem, CollapsableList } from "../../../domain/util/Collapsable";
import ModuleContentTypeEnum from "../../../domain/enum/ModuleContentTypeEnum";

import { v4 as uuidv4 } from 'uuid';


import ApprenticeServices from "../../../services/ApprenticeServices";
import CourseService from "../../../services/CourseService";
const apprenticeSerivce = new ApprenticeServices();
const courseService = new CourseService();

interface AttendCourseContextData {
    courseDetails: CourseDetailView    
    setCourseDetails: React.Dispatch<React.SetStateAction<CourseDetailView>>

    currentUserModuleContentProgress: UserModuleContentProgressView    
    setCurrentUserModuleContentProgress: React.Dispatch<React.SetStateAction<UserModuleContentProgressView>>
    
    apprenticeCourseProgress:UsersCourseProgressView
    setApprenticeCourseProgress:React.Dispatch<React.SetStateAction<UsersCourseProgressView>>

    collapsableListOfModules:CollapsableList<UsersModuleProgressView>
    setCollapsableList:React.Dispatch<React.SetStateAction<CollapsableList<UsersModuleProgressView>>>

    openModuleContentType: ModuleContentTypeEnum
    setOpenModuleContentType: React.Dispatch<React.SetStateAction<ModuleContentTypeEnum>>

    showModalCourseInfo: boolean
    setShowModalCourseInfo: React.Dispatch<React.SetStateAction<boolean>>

    menuCollapsed: boolean
    setMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>

    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>

    isLoadingAssessment: boolean,
    setIsLoadingAssessment: React.Dispatch<React.SetStateAction<boolean>>

    selectedCertificate:boolean,

    attendCertificate:()=> void

    changeModuleContentState:(newState:ApprenticeProgressStatusEnum,module:UserModuleContentProgressView)=>void

    handleCollapseMenuItemChange:(index:number)=>void
    isModuleComplete:(moduleProgress:UsersModuleProgressView)=>boolean
    isSelectedModule:(moduleId:string)=>boolean
    attendModuleContent:(moduleContentProgress : UserModuleContentProgressView)=>void
    attendNextModuleContent:()=>void
    attendPreviousModuleContent:()=>void

    canSeePreviousModuleContentButton:()=>boolean
    canSeeNextModuleContentButton:()=>boolean

    isCourseComplete:()=>boolean

    reRender:()=>void
    collapseMenu:()=>void

    match: match<any> | null 
    courseId:string 
    selectedPage:string
}

export const AttendCourseContext = createContext({} as AttendCourseContextData)

interface AttendCourseContextProviderProps {    
    children: ReactNode
}

export const AttendCourseContextProvider = (props: AttendCourseContextProviderProps) => {    

    const match: match<any> | null = useRouteMatch<any>('/course/:courseId/attend/:page?');
    const courseId =  match!.params.courseId!
    const selectedPage = match!.params.page!
    
    const [showModalCourseInfo, setShowModalCourseInfo] = useState(false);
    const [courseDetails, setCourseDetails] = useState<CourseDetailView>(mock);

    const [currentUserModuleContentProgress, setCurrentUserModuleContentProgress] = useState<UserModuleContentProgressView>({moduleId:""} as any);

    const [apprenticeCourseProgress, setApprenticeCourseProgress] = useState<UsersCourseProgressView>({
        title:"",
        apprenticeId: "",
        courseId:"",
        qtyContentInTotal: 1,
        qtyContentDone: 0,
        enableCertificate: false,
        userCanPrintCertificate: false,
        isCourseCompleted:false,
        modules: []
    })

    const [collapsableListOfModules,setCollapsableList] = useState<CollapsableList<UsersModuleProgressView>>(new CollapsableList<UsersModuleProgressView>() )

    const [menuCollapsed, setMenuCollapsed] = useState(false);    

    const [openModuleContentType, setOpenModuleContentType] = useState<ModuleContentTypeEnum>(ModuleContentTypeEnum.Undefined);

    const [isLoading, setLoading] = useState(true);
    
    const [reload, callReload] = useState(false);

    const [isLoadingAssessment, setIsLoadingAssessment] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(false);

    const [listOfModuleContentProgress, setListOfModuleContentProgress] = useState<UserModuleContentProgressView[]>([]);

    useEffect(() => {
        pageLoad();
        
         if(window.screen.width < 990){
             setMenuCollapsed(true);              
         }
    }, []);

    useEffect(
        ()=>{
            handleModuleContentChange()
        },[currentUserModuleContentProgress]
    )
  
    async function pageLoad() {
        try {
            
            ReactGA.send({ hitType: "pageview", page: "AttendCourse" });
    
            if(!(selectedPage==="certificate"||selectedPage==="quiz"||selectedPage===undefined)){
                window.location.href = "/course/"+courseId+"/attend"
            }
    
            let courseProgress = await getCourseProgress()    
    
    
            switch(selectedPage){
                case "certificate":{ 
                    verifyCanSeeCertificate(courseProgress!)
                    break;
                }
                default:{
                    break;
                }
            }
            let response = await courseService.getCourseDetails(courseId)
    
            if(response.success){
                setCourseDetails(response.result);
                setLoading(false)
                reRender()
            }

        } catch (error) {
           console.log(error);
            
        }
        
    }

    async function getCourseProgress() :Promise<UsersCourseProgressView|undefined>{
        try {
            let courseProgress: UsersCourseProgressView = null as any

            let response = await apprenticeSerivce.getMyCourseProgress(courseId)

            if(response.success) {                
                let courseProgress = response.result;
                if(courseProgress!== undefined){
                    setApprenticeCourseProgress(courseProgress)
                    await mountCourseContentList(courseProgress);
               
                    let firstUndoneLesson = getFirstUndoneLesson(courseProgress);
                    setCurrentUserModuleContentProgress(firstUndoneLesson)
                    
                    loadModuleContent(firstUndoneLesson);
                
                }
            }
            return courseProgress;
            
        } catch (error) {
            console.log(error)
        }
    }

    function mountCourseContentList(courseProgress: UsersCourseProgressView) {
        let list: UserModuleContentProgressView[] = [];
        courseProgress!.modules.forEach(module => {

            let moduleId = module.id
            
            list = list.concat(module.moduleContents);
            if (module.hasCertificate) {
                let certificate:UserModuleContentProgressView = {
                    id:uuidv4(),
                    title: "Cerficado do módulo",
                    order: module.moduleContents.length,
                    moduleId: moduleId,
                    moduleContentTypeId: ModuleContentTypeEnum.Certificate,
                    isRequired:false,
                    progressStatusId:ApprenticeProgressStatusEnum.Done
                };

                list = list.concat(certificate as UserModuleContentProgressView);
                module.moduleContents = module.moduleContents.concat(certificate as UserModuleContentProgressView)
            }
        });
        
        setListOfModuleContentProgress(list);
        reRender()
    }

    function getFirstUndoneLesson(userProgress: UsersCourseProgressView): UserModuleContentProgressView {
        for(let m = 0; m < userProgress.modules.length ; m++) {
            for(let l = 0; l < userProgress.modules[m].moduleContents.length; l++) {
                if(userProgress.modules[m].moduleContents[l].progressStatusId !== ApprenticeProgressStatusEnum.Done 
                    || userProgress.modules[m].moduleContents[l].progressStatusId !== ApprenticeProgressStatusEnum.Sent) {
                    return(userProgress.modules[m].moduleContents[l]);
                }
            }
        }
        return(userProgress.modules[0].moduleContents[0]);
    } 



    function attendCertificate()
    {
        markContentDone(currentUserModuleContentProgress)
        setSelectedCertificate(true)
        setCurrentUserModuleContentProgress({id:""} as any)

    }

    async function attendModuleContent(moduleContentProgress : UserModuleContentProgressView) {
        let currentModuleContentProgress = listOfModuleContentProgress.find( x=> x.id === currentUserModuleContentProgress.id)!
        setOpenModuleContentType(ModuleContentTypeEnum.Undefined)
        setSelectedCertificate(false);
        markContentDone(currentModuleContentProgress)
        // alert("Nessa função");
        if(window.screen.width < 990){
            collapseMenu();   
            setMenuCollapsed(true);              
        }

        await loadModuleContent(moduleContentProgress) 

    }

    async function loadModuleContent(moduleContentProgress:UserModuleContentProgressView) {  
        setOpenModuleContentType(ModuleContentTypeEnum.Undefined);
        setCurrentUserModuleContentProgress(moduleContentProgress)

        if(moduleContentProgress.progressStatusId === null){           
            
            await startContent(moduleContentProgress);

        }


        if(moduleContentProgress.moduleContentTypeId===ModuleContentTypeEnum.Lesson||
            moduleContentProgress.moduleContentTypeId===ModuleContentTypeEnum.Task){
            setTimeout(() => {
                changeModuleContentType(moduleContentProgress);
                
            }, 500);
        }else{
            changeModuleContentType(moduleContentProgress);

        }
        
    }

    function changeModuleContentType(moduleContentProgress: UserModuleContentProgressView) {
        switch (moduleContentProgress.moduleContentTypeId) {
            case ModuleContentTypeEnum.Lesson:
                {
                    setOpenModuleContentType(ModuleContentTypeEnum.Lesson);
                    break;
                }
            case ModuleContentTypeEnum.Task:
                {
                    setOpenModuleContentType(ModuleContentTypeEnum.Task);
                    break;
                }
            case ModuleContentTypeEnum.Assessment:
                {
                    setOpenModuleContentType(ModuleContentTypeEnum.Assessment);
                    break;
                }
            case ModuleContentTypeEnum.Certificate:
                {
                    setOpenModuleContentType(ModuleContentTypeEnum.Certificate);
                    break;
                }
        }
    }


    function handleModuleContentChange(newMenuState:CollapsableList<UsersModuleProgressView>|undefined = undefined){
        if(newMenuState === undefined){
            newMenuState = new CollapsableList<UsersModuleProgressView>(apprenticeCourseProgress.modules)
        }
        
        for(let m = 0; m < apprenticeCourseProgress.modules.length ; m++) {
            if(collapsableListOfModules.list.length === 0){
                newMenuState.list[m].collapsed = false
            }else{
                newMenuState.list[m].collapsed = collapsableListOfModules.list[m].collapsed
            }
            for(let l = 0; l < apprenticeCourseProgress.modules[m].moduleContents.length; l++) {                
                if(apprenticeCourseProgress.modules[m].moduleContents[l].id === currentUserModuleContentProgress.id) {
                    newMenuState.list[m].collapsed = true&&!(selectedCertificate);
                }
            }
        }

        
        setCollapsableList(newMenuState)
        reRender()
    }

    function isSelectedModule(moduleId:string):boolean{ 
        if(moduleId === currentUserModuleContentProgress.moduleId) {                    
            return true;
        }
        return false
    }

    function handleCollapseMenuItemChange(index:number):void{
        let newModules = collapsableListOfModules;
        newModules.handleCollapseMenuItemAnyCanBeOpen(index);
        setCollapsableList(newModules);
        reRender();
    }

    function colapseAll(){
        let newModules = collapsableListOfModules;
        newModules.collapseAllItems()
        setCollapsableList(newModules);
        reRender();
    }

    // #region Apprentice Progress Status Change
    
    async function startContent(moduleContentProgress:UserModuleContentProgressView) {
        try {
            await apprenticeSerivce.startContent({
                courseId: courseId,
                moduleContentId: moduleContentProgress.id
            })

            changeModuleContentState(ApprenticeProgressStatusEnum.InProgress, moduleContentProgress)

        } catch (error) {
            console.log(error);
        }
    }

    async function markContentDone(module:UserModuleContentProgressView){
        try {   
            if(module.progressStatusId !== ApprenticeProgressStatusEnum.Done&&module.moduleContentTypeId === ModuleContentTypeEnum.Lesson){
                var payload = {
                    courseId:courseId,
                    moduleContentId:module.id
                }
        
                await apprenticeSerivce.markContentDone(payload)
                changeModuleContentState(ApprenticeProgressStatusEnum.Done, module)
            } 
        } catch (error) {
            console.log(error);
        }
    }

    function changeModuleContentState(newState:ApprenticeProgressStatusEnum, moduleContentToChange:UserModuleContentProgressView){

        let moduleIndex = apprenticeCourseProgress.modules.findIndex(module => {
            return module.id === moduleContentToChange.moduleId;
        })        

        let currentModule = apprenticeCourseProgress.modules[moduleIndex]

        let moduleContentIndex = currentModule.moduleContents.findIndex(moduleContent => {
            return moduleContent.id === moduleContentToChange.id;
        })

        let currentModuleContent = currentModule.moduleContents[moduleContentIndex];
        let previousState = currentModuleContent.progressStatusId;

        apprenticeCourseProgress.modules[moduleIndex].moduleContents[moduleContentIndex].progressStatusId = newState;
        collapsableListOfModules.list[moduleIndex].item.moduleContents[currentModuleContent.order].progressStatusId = newState;
        listOfModuleContentProgress.find(x => x.id === currentModuleContent.id)!.progressStatusId = newState;

        if(currentModuleContent.isRequired && 
            ((newState === ApprenticeProgressStatusEnum.Done && currentModuleContent.moduleContentTypeId === ModuleContentTypeEnum.Assessment)
             || (newState === ApprenticeProgressStatusEnum.Sent && currentModuleContent.moduleContentTypeId === ModuleContentTypeEnum.Task)
             || (previousState === null && currentModuleContent.moduleContentTypeId === ModuleContentTypeEnum.Lesson)
            )
        ) {
            apprenticeCourseProgress.qtyContentDone++;

            if(isModuleComplete(apprenticeCourseProgress.modules[moduleIndex])) {

                apprenticeCourseProgress.modules[moduleIndex].moduleDependenciesOnMe.forEach(moduleDependency => {
                    
                    let dependentModuleIndex =  apprenticeCourseProgress.modules.findIndex(x => x.id === moduleDependency.moduleId);
                    let dependentModule = apprenticeCourseProgress.modules[dependentModuleIndex];
                    let isModuleBlocked = false;

                    for(let i = 0; i< dependentModule.modulesImDependent.length; i++) {
                        let parentModuleIndex =  apprenticeCourseProgress.modules.findIndex(x => x.id === dependentModule.modulesImDependent[i].dependencyModuleId)
                        let parentModule = apprenticeCourseProgress.modules[parentModuleIndex]

                        //if any dependency module is not complete its enough to keep the current module blocked
                        if(!isModuleComplete(parentModule)){
                            isModuleBlocked = true;
                            break;
                        }
                    }

                    apprenticeCourseProgress.modules[dependentModuleIndex].isBlocked = isModuleBlocked;
                    collapsableListOfModules.list[dependentModuleIndex].item.isBlocked = isModuleBlocked;
                })
            }
        }        

        handleModuleContentChange();
        reRender();
    }
    
    // #endregion Apprentice Progress Status Change

    // #region Validation

    function verifyCanSeeCertificate(courseProgress: UsersCourseProgressView){
        if(courseProgress.userCanPrintCertificate){
            setSelectedCertificate(true)
        }else{
            window.location.href = "/course/"+courseId+"/attend"
        }
    }

    function isCourseComplete():boolean {
        let isValid = true;

        for(let i=0; i<apprenticeCourseProgress.modules.length; i++){
            if(!isModuleComplete(apprenticeCourseProgress.modules[i])){
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    function isModuleComplete(courseModule:UsersModuleProgressView):boolean{   
        
        //validate Lessons
        let lessons = courseModule.moduleContents.filter((moduleContents)=>{
            return moduleContents.moduleContentTypeId== ModuleContentTypeEnum.Lesson
        })

        for(let i=0 ;i< lessons.length ;i++) {
            if(lessons[i].progressStatusId === null)
                return false;
        }

        //validate Tasks
        let requiredTasks = courseModule.moduleContents.filter((moduleContent)=>{
            return moduleContent.moduleContentTypeId == ModuleContentTypeEnum.Task && moduleContent.isRequired
        })

        for (let i = 0; i < requiredTasks.length; i++) {
            if(requiredTasks[i].progressStatusId !== ApprenticeProgressStatusEnum.Done &&
                requiredTasks[i].progressStatusId !== ApprenticeProgressStatusEnum.Sent)            
                return false;
        }

        //validate Assessment
        let requiredAssessments = courseModule.moduleContents.filter((moduleContent)=>{
            return moduleContent.moduleContentTypeId == ModuleContentTypeEnum.Assessment && moduleContent.isRequired
        })

        for (let i = 0; i < requiredAssessments.length; i++) {
            if(requiredAssessments[i].progressStatusId !== ApprenticeProgressStatusEnum.Done)            
                return false;
        }
        return true;
    }
    
    // #endregion Validation
    
    // #region Navigation
    
    function attendPreviousModuleContent(moduleContentIndex:number|undefined = undefined) {

        if(moduleContentIndex === undefined){
            if(selectedCertificate){
                attendModuleContent(listOfModuleContentProgress[listOfModuleContentProgress.length-1])
                return;
            }   
            
            moduleContentIndex = listOfModuleContentProgress.findIndex(moduleContent => {
                return moduleContent.id === currentUserModuleContentProgress.id
            }) as number
        }
        let previousContentIndex = previousContentAvaliableIndex(moduleContentIndex)


        if(listOfModuleContentProgress[previousContentIndex].moduleContentTypeId === ModuleContentTypeEnum.Certificate){
            let previousContentModuleId =  listOfModuleContentProgress[previousContentIndex].moduleId
            let moduleIndex = -1
            if(currentUserModuleContentProgress.moduleId === previousContentModuleId){
                moduleIndex = collapsableListOfModules.list.findIndex(module => {
                    return module.item.id === currentUserModuleContentProgress.moduleId
                })
            }
            else{
                moduleIndex = collapsableListOfModules.list.findIndex(module => {
                    return module.item.id ===  previousContentModuleId
                })
            }

            let certBlocked = !isModuleComplete(collapsableListOfModules.list[moduleIndex].item)
            if(certBlocked){
                attendPreviousModuleContent(previousContentIndex)
            }else{
                attendModuleContent(listOfModuleContentProgress[previousContentIndex])
                setSelectedCertificate(false)
            }
        }else{
            attendModuleContent(listOfModuleContentProgress[previousContentIndex])
            setSelectedCertificate(false)
        }
    }

    function attendNextModuleContent(moduleContentIndex:number|undefined = undefined) {
        let moduleIndex = collapsableListOfModules.list.findIndex(module => {
            return module.item.id === currentUserModuleContentProgress.moduleId
        })

        if(moduleContentIndex === undefined){
            moduleContentIndex = listOfModuleContentProgress.findIndex(moduleContent => {
                return moduleContent.id === currentUserModuleContentProgress.id
            })
        }

        let nextContentIndex = nextContentAvaliableIndex(moduleContentIndex)

        if(listOfModuleContentProgress.length > nextContentIndex && nextContentIndex > -1) {
            if(listOfModuleContentProgress[nextContentIndex].moduleContentTypeId === ModuleContentTypeEnum.Certificate){
                let certBlocked = !isModuleComplete(collapsableListOfModules.list[moduleIndex].item)
                if(certBlocked){
                    attendNextModuleContent(nextContentIndex)
                }else{
                    attendModuleContent(listOfModuleContentProgress[nextContentIndex])
                    setSelectedCertificate(false)
                }
            }else{
                attendModuleContent(listOfModuleContentProgress[nextContentIndex])
                setSelectedCertificate(false)
            }
        }        
        else if(isCourseComplete())
        {
            attendCertificate()
        }
        else
        {
            attendModuleContent(listOfModuleContentProgress[0])
        }
    }

    function canSeeNextModuleContentButton():boolean {
        let currentContentIndex = listOfModuleContentProgress.findIndex(moduleContent =>{return moduleContent.id === currentUserModuleContentProgress.id})
        if(!(nextContentAvaliableIndex(currentContentIndex)>-1)){
            return false;
        }
        return (currentContentIndex < listOfModuleContentProgress.length&& !selectedCertificate)
    }

    function canSeePreviousModuleContentButton():boolean {
        let currentContentIndex = listOfModuleContentProgress.findIndex(moduleContent =>{return moduleContent.id === currentUserModuleContentProgress.id})
        if(!(previousContentAvaliableIndex(currentContentIndex)>-1)){
            return false;
        }
        return (currentContentIndex > 0|| selectedCertificate)
    }
    
    function nextContentAvaliableIndex(contentIndex:number):number{
        for(let i = contentIndex+1; i<listOfModuleContentProgress.length ; i++){

            let currentContentModuleId = listOfModuleContentProgress[i].moduleId;
            let moduleIndex = apprenticeCourseProgress.modules.findIndex(x => x.id === currentContentModuleId)
            if(moduleIndex!== undefined){
                if(!apprenticeCourseProgress.modules[moduleIndex].isBlocked){
                    return i
                }
            }

        }
        return -1;
    }

    function previousContentAvaliableIndex(contentIndex:number):number{
        for(let i = contentIndex-1; i>=0 ; i--){

            let currentContentModuleId = listOfModuleContentProgress[i].moduleId;
            let moduleIndex = apprenticeCourseProgress.modules.findIndex(x => x.id === currentContentModuleId)
            if(moduleIndex!== undefined){
                if(!apprenticeCourseProgress.modules[moduleIndex].isBlocked){
                    return i
                }
            }

        }
        return -1;
    }

    // #endregion Navigation
    
    function reRender(){
        callReload(!reload)
    }

    function collapseMenu() {
        setMenuCollapsed(!menuCollapsed)
    }

    return (<AttendCourseContext.Provider
        value={{
            openModuleContentType, setOpenModuleContentType,
            courseDetails, setCourseDetails,
            apprenticeCourseProgress, setApprenticeCourseProgress,
            currentUserModuleContentProgress, setCurrentUserModuleContentProgress,
            showModalCourseInfo, setShowModalCourseInfo,  
            collapsableListOfModules,setCollapsableList,          
            isLoading, setLoading,
            isLoadingAssessment, setIsLoadingAssessment,
            menuCollapsed, setMenuCollapsed,
            changeModuleContentState,
            canSeeNextModuleContentButton,
            canSeePreviousModuleContentButton,
            attendNextModuleContent,
            attendPreviousModuleContent,
            attendModuleContent,
            reRender,
            collapseMenu,  
            isSelectedModule,  
            isModuleComplete, 
            handleCollapseMenuItemChange, 
            attendCertificate,      
            isCourseComplete,
            match,
            courseId,
            selectedPage,
            selectedCertificate
        }}>
        {
            props.children
        }
    </AttendCourseContext.Provider>);
}