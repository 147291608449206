import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AttendCourseContext } from '../../../../context/apprentice/attend/AttendCourseContext';
import { AttendLessonContentComponent } from "./AttendLessonContentComponent";
import styled from "styled-components"

export const AttendLessonComponent = () => {
    const {
        currentUserModuleContentProgress, 
    } = useContext(AttendCourseContext);

    return (
        <AttendLessonComponentStyles className="attendLessonComponent">
            {
                currentUserModuleContentProgress.lesson!.coverImage&&            
                    <Row className="cover-image">
                        <Col className="mx-auto p-0">
                            <img src={currentUserModuleContentProgress.lesson!.coverImage}></img>
                        </Col>
                    </Row>
            }

            <Row >
                <Col md={11} className="mx-auto">
                    <h1 className="title mt-5">
                        {currentUserModuleContentProgress.title}
                    </h1>
                </Col>
            </Row>
            {
                currentUserModuleContentProgress.lesson!.lessonContents.sort((a, b) => a.order - b.order).map(lessonContent => {
                    return (<AttendLessonContentComponent lessonContent={lessonContent} />);
                })
            }
        </AttendLessonComponentStyles>
    );
};
export default AttendLessonComponent;

const AttendLessonComponentStyles = styled(Col)`
    max-height:91vh;
    overflow-y:scroll;
    padding:0;
    margin:0;

    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }
    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #636060;
    }
    .cover-image{
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 990px){
        max-height: auto !important; 

        .title {
            font-size: 5vmin;
            line-height: 7vmin !important;
        }

        p {
            font-size: 5vmin;
            line-height: 8vmin;
        }

        video {
            width: 100%;
        }        
    }
`
