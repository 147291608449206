import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import Toggle from 'react-toggle';
import { CreateQuizEnunciado } from '../../../../../createTrilha/createTrilhaStyles';
import { useCreateAssessementModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateAssessementModuleContentContext';
import { AssessmentHeaderStyles } from './styles';

export function AssessementInfoForm() {

    const {
        module, selectedModuleContentIndex, handleModuleContentTitleChange, handleModuleContentTitleBlur,

    } = useContext(AddModuleContext);

    const {
        handleIsAssessmentActive, handleAssessmentDescriptionChange, handleAssessmentMinimunGradeChange, returnMinimunGradeText,
    } = useCreateAssessementModuleContentContext();

    return (<>
        <Row className='pt-3'>
            <Col>
                <Form.Label htmlFor='assessment-title'>
                    Título da Avaliação
                </Form.Label>
                <InputGroup>
                    <FormControl
                        id="assessment-title"
                        placeholder="Digite um título para a avaliação"
                        autoComplete="off"
                        aria-describedby="assessment-title"
                        value={module.moduleContents[selectedModuleContentIndex].title}
                        onChange={(e) => handleModuleContentTitleChange(e)}
                        onBlur={() => handleModuleContentTitleBlur()} />
                </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col className='mt-4 mb-4'>
                <Form.Label htmlFor='assessment-description'>
                    Descrição da Avaliação (opcional)
                </Form.Label>
                <CreateQuizEnunciado rows="2"
                    id="assessment-description"
                    placeholder="Conte um pouco mais sobre esta avaliação"
                    autoComplete="new-password"
                    aria-describedby="assessment-description"
                    onChange={(e) => handleAssessmentDescriptionChange(e)}
                    value={module.moduleContents[selectedModuleContentIndex].assessment!.description!} />

            </Col>
        </Row>
        <Row>
            <Col>
                <AssessmentHeaderStyles>
                    <Col className='my-auto pt-1'>
                        <p>Obrigatoriedade</p>
                    </Col>
                </AssessmentHeaderStyles>
            </Col>
        </Row>
        <Row className="py-4 mx-1">
            <Col className='my-auto pl-0'>
                <Row>
                    <Col className='mb-3'>
                        <h4>Avaliação Obrigatória</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>Se estiver ativado, as pessoas não poderão seguir em frente no curso sem responder esta avaliação.</h5>
                    </Col>
                </Row>
            </Col>
            <Col md="2" className='mr-3 my-auto'>
                <Row>
                    <Col md="auto">
                        <Toggle
                            icons={false}
                            checked={module.moduleContents[selectedModuleContentIndex].isRequired}
                            onChange={() => handleIsAssessmentActive()} />
                    </Col>
                    <Col md={5} className="my-auto">
                        <p>
                            {module.moduleContents[selectedModuleContentIndex].isRequired ?
                                "Ativado" : "Desativado"}
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="py-4 mx-1">
            <Col className='my-auto pl-0'>
                <Row>
                    <Col className='mb-3'>
                        <Form.Label htmlFor='assessment-minimun-grade'>
                            Mínimo de acertos (opcional)
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col className='mb-3'>
                        <h5>Você pode definir uma porcentagem mínima de acertos para a aprovação na avaliação.</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <InputGroup>
                            <FormControl
                                id="assessment-minimun-grade"
                                placeholder="Digite um valor entre 0 e 100%"
                                autoComplete="new-password"
                                aria-describedby="assessment-minimun-grade"
                                value={returnMinimunGradeText()}
                                onChange={(e) => handleAssessmentMinimunGradeChange(e)} />
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
    );
}
