import { Col, Row } from "react-bootstrap";
import HorizontalAlignEnum from "../../../../domain/enum/HorizontalAlignEnum";
import ImageLessonContentViewModel from "../../../../domain/models/course/module/lesson/ImageLessonContentViewModel";
import { AttendMediaContentComponentStyles } from "../AttentCoursePageStyles";

export default function AttendImageContentComponent(props: AttendImageContentComponentProps) {

    function getImageAlign(): string {
        switch (props.imageLesson.Align) {
            case HorizontalAlignEnum.Center:
                return 'mx-auto';
            case HorizontalAlignEnum.Left:
                return 'mr-auto';
            case HorizontalAlignEnum.Right:
                return 'ml-auto';
            default:
                return '';
        }
    }

    return (
        <AttendMediaContentComponentStyles
            className="md-mx-4 mb-3"
            fullSize={props.imageLesson.FullSize}
        >
            <Col
                className={"py-3"}
            >
                <Row>
                    <Col md={props.imageLesson.FullSize ? 11 : 6} className={getImageAlign()}>
                        <img
                            src={props.imageLesson.ImageObject.ImageUrl} />
                    </Col>
                </Row>
            </Col>
        </AttendMediaContentComponentStyles>
    );
}
interface AttendImageContentComponentProps {
    content: string;
    imageLesson: ImageLessonContentViewModel;
}


