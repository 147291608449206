import { Col, Container, Row } from "react-bootstrap";
import styled from 'styled-components';
import { PrimaryPurpleButton } from "../../../../components/Buttons/styles";


export const AttendQuizFormStyles = styled(Container)`
    .question-content{
        max-height:85vh;
        overflow-y:scroll;
        padding-bottom: 50px;
    }
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        margin-bottom:0px;
    }

    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        margin-bottom:0px;
    }

    p{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #777575;
        margin-bottom:1.1vmin;
    }

    .answers-row{
        margin-bottom: 3vmin !important;        
    }

    .question-row{                
        margin-bottom: 3vmin !important;
    }

    .question-img-row{
        img{
            max-height:40vmin;
        }
    }

    ul{
        list-style-type:none;
        padding-left:1.4vmin;
    }

    input[type="radio"]{
        margin-bottom:auto;
        appearance: none;
        width:4.44vmin;
        height:4.44vmin;
        border: 0.28vmin solid #777575;
        border-radius:50%;
        padding:0.42vmin;
        background-clip: content-box;
    }

    input[type="radio"]:hover{
        border: 2px solid #0C8292;
        box-shadow: #22808d63  0px 0px 0px 3px
        
    }

    input[type="radio"]:checked{
        /* background-color: #EA694C;
        border: 0.28vmin solid #EA694C; */
        background-color:  #0C8292;
        border: 0.28vmin solid  #0C8292;
       
    }

    @media screen and (max-width: 990px){
        margin-top: 5vmin;
        .question-content{
            margin-bottom:20px;

        }
        .question-img-row{
            img{
                max-height:30vmin;
                margin-bottom:20px;
            }
        }
        .holdHeaderQuizz {
            left: 0;
            top: -7px;

            i {
                position: relative;
                top: 8px;
                color: #6468C8 !important;
            }

            h1 {
                font-size: 6vmin;
                line-height: 5vmin;
            }
        }

        h1 {
            font-size: 5vmin !important;
            line-height: 6vmin !important;
        }

        .answers-row {

            p {
                font-size: 5vmin !important;
            }
        }


        .question-row {
            pre {
                font-size: 5vmin !important;
                line-height: 6vmin !important;
            }
        }
    }    
`;

export const QuestionContent = styled.pre`
    white-space: pre-wrap;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;    
    color: #636060;    
`;
export const AssessmentFooterStyles = styled.footer`
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0 2px 0 2px;
    background-color:#FFFFFF;
    border: none;
    box-shadow: 0px -0.14vmin 0.28vmin rgba(0, 0, 0, 0.1);
    z-index:100;
    
    div.col_buttons
    {
        padding: 10px 10px;
        margin: 10px 10px;
    }

    button.next-question{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.5vmin;
        }
        i{
            font-size:2.77vmin;
        }
    }

    button.previous-question{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.5vmin;
            margin-bottom:0px;
        }
        i{
            font-size:2.77vmin;
        }
    }

    button.finish-quiz{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.5vmin;
            margin-bottom:0px;
        }
        i{
            font-size:2.77vmin;
        }
    }

    @media screen and (max-width: 990px){
        button.next-question{
            font-size: 5vmin;
            line-height: 5vmin;
            width: 100% !important;

            i{
                font-size:3vmin;
                margin-left: 7px;
            }
        }
        button.previous-question{
            font-size: 5vmin;
            line-height: 5vmin;
            width: 100% !important;

            i{
                font-size:3vmin;
                margin-left: 7px;
            }
        } 
        button.finish-quiz{
            font-size: 5vmin;
            line-height: 5vmin;
            width: 100% !important;

            i{
                font-size:4vmin;
                margin-left: 7px;
            }
        } 
    }
`;
export const AssessmentContainer = styled(Col)`
    padding-top: 7vmin;
    margin: 10px;

    @media screen and (max-width: 990px){
        margin: 0 !important;
        padding: 20px !important;
    }
`

export const AssessmentRequiredTitle = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 20px;
    color: #6468C8;

    @media screen and (max-width:990px){
        font-size: 5vmin;
        margin-top: 18px;
    }
`

export const AssessmentRequiredDescription = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 24px;
    color: #777575;

    @media screen and (max-width:990px){
        font-size: 5vmin;
    }
`

export const Practice = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vmin;
    line-height: 24px;
    color: #514F4E;

    @media screen and (max-width:990px){
        font-size: 5vmin;
    }
`

export const PracticeDescription = styled.p`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 24px;
    color: #514F4E;

    @media screen and (max-width:990px){
        font-size: 5vmin;
    }
`

export const TagAlert = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    background: #F9F9F9;
    border: 1px solid #E1F4F7;
    border-radius: 0.55vmin;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 24px;
    color: #514F4E;
    i{
        font-size:3vmin;
        color:#0C8292;
    }

    @media screen and (max-width: 990px){

        &.tagAlertMobile {
            background: transparent;
            border: 0;
        }
        
        font-size: 4vmin;
        line-height: 6vmin;

        i {
            font-size: 6.8vmin;
            margin-right: 4px;
        }
    }
`

export const AssessmentStartButton = styled(PrimaryPurpleButton)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 16px;
    background: #6468C8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    i{
        font-size:2.7vmin;
    }
    p{
        font-size:2.22vmin;
        margin-bottom:0;
    }

    @media screen and (max-width: 990px){
        width:100%;
    }
`

export const HoldAnswer = styled(Col)` 

    @media screen and (max-width:990px){
        padding: 20px 0px 0px 0px !important;

        .custom-btn-accordion {
            color: #0C8292;   
            border-color: #0C8292 !important;
            width: 100%; 
            border-radius: 8px !important;   
            
            i {
                margin-left: 7px;
            }            

            &:focus {
                background: #FFF !important;
                outline: 0 !important;
            }            
        }

        h1 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h3 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }        

        h5 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        .card-body {
            padding: 0 !important;
        }

        p {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        input {
            margin-right: 7px !important;
        }

        pre {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }
    }
`

export const FeedbackQuizCourseComponentStyle = styled(Col)` 
    max-width:100vw;
    max-height:91vh;
    overflow-y:scroll;
    padding-right:0;
    padding-left:0;


    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8; 
        width: 1.12vmin;
    }

    img.reproved{
        max-height:40vmin;
        max-height:${props => (props.isFullScreen ? '40vmin' : '33vmin')}
    }

    img.passed{
        max-height:${props => (props.isFullScreen ? '50vmin' : '40vmin')}
    }

    button.retry-quiz {
        i{
            font-size:3.33vmin;
        }
    }

    button{
        p{
            margin-bottom:0px;
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.36vmin;
            line-height: 2.63vmin;
        }
        i{
            font-size:3.33vmin;
        }
    } 
    
    h1.passed{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #218749;
    }
    
    h1.reproved{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #995C00;
    }
    
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.78vmin;
        color: #514F4E;
        padding: 0 1.5vmin;
    }
    
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }

    //attempt info cards
    .card{
        background: #FFFFFF;
        box-shadow: 0px 0.14vmin 0.83vmin rgba(81, 79, 78, 0.2);
        border-radius: 1.11vmin;
        min-width:12vmin;
        border:none;
        .card-body{
            text-align:center;
            h1{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 3.33vmin;
                line-height: 3.33vmin;
                text-align: center;
                color: #514F4E;
            }
        }
        .card-header{
            text-align:center;
            padding-bottom:0px;
            border-bottom:none;
            border-top-left-radius:1.11vmin;
            border-top-right-radius:1.11vmin;

            background: #FFFFFF;

            h5{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 1.94vmin;
                line-height: 2.78vmin;
                text-align: center;
                color: #514F4E;

            }
        }
    }
    
    //list of questions
    ul{
        list-style-type:none;
        padding-left:0px;
        hr{
            border-top: 0.14vmin solid #B9B8B8
        }
        li{
            h1{
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 2.22vmin;
                line-height: 2.78vmin;
                color: #995C00;
                margin-bottom:0px;
            }
            h5{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2.22vmin;
                line-height: 3.33vmin;
                color: #514F4E;
            }
            button{
                border: 0.14vmin solid #0C8292;
                box-sizing: border-box;
                border-radius: 1.11vmin;
                background-color:#FFFFFF;
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 2.22vmin;
                    line-height: 2.63vmin;
                    color: #0C8292;
                }
                i{
                    color:#0C8292;
                }

            }
            ul{
                .selected-answer{
                    p{
                        color: #995C00;
                    }
                    i{
                        font-size: 2.22vmin;
                        color: #995C00;
                    }
                } 
                input[type="radio"]{
                    appearance: none;
                    width:4.44vmin;
                    height:4.44vmin;
                    border: 0.28vmin solid #777575;
                    border-radius:50%;
                    padding:0.42vmin;
                    background-clip: content-box;
                }
                input[type="radio"]:checked{
                    background-color: #995C00;
                    border: 0.28vmin solid #995C00;
                }
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    color: #777575;
                    margin-bottom:0.5vmin;
                }
            }
            .card{
                background: #F9F9F9;
                border-radius: 1.11vmin;
                box-shadow: none;
                border:none;
                h5{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.22vmin;
                    line-height: 2.78vmin;
                    color: #514F4E;
                }
                p{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    color: #514F4E;
                }
                a{
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    text-decoration-line: underline;
                    color: #0C8292;
                }
            }
        }
    }
`
export const FeedbackContainer = styled(Row)`
    margin:0;
    background: #F9F9F9;  
    //background: #ddd;    
    padding-bottom: 10px;  

    @media screen and (max-width:990px){  
        
        .holdCardMobile {
            padding: 10px;
        }
        
        .hold-back-btn {
            width: 100%;

            i {
                margin-left: 7px;
            }
        }

        .col-6 {
            padding: 5px !important;
        } 
        
        .img-passed {
            display: none !important;
        }

        .img-reproved {
            display: none !important;
        }        

        .card {
            h5 {
                font-size: 4vmin !important;
            }

            h3 {
                font-size: 6.2vmin !important;
                font-weight: 600 !important;
            }

            h1 {
                font-size: 6.2vmin !important;
                font-weight: 600 !important;
            }
        }

        p {
            font-size: 4.8vmin !important;
        }

        h1 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h3 {
            font-size: 5vmin !important;
            line-height: 7vmin !important;
        }

        h4 {
            font-size: 5vmin;
            line-height: 6.5vmin;
        }

        .redo-evaluation {
            width: 100%;

            i {
                margin-left: 7px;
                font-size: 5.5vmin !important;
            }
        }
    }
`
