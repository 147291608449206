import styled from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap';
import { TertiaryYellowButton } from '../../../components/Buttons/styles';


interface CreateCampaignHeaderProps {
    titleHeader: string
}
function CreateCampaignHeader(props: CreateCampaignHeaderProps) {

    const handleExitCreation = () => {
        window.location.href = "/controlpanel/campaigns";
    }
    return (
        <CreateCampaignHeaderStyles className="mb-1">
            <Container fluid={true}>
                <Row>
                    <Col md={10} className="my-auto ml-2">
                        <h4 className="ml-2 mt-1 text-left">
                            {props.titleHeader}
                        </h4>
                    </Col>
                    <Col md={"auto"}>
                        <TertiaryYellowButton onClick={handleExitCreation}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi-x" />
                                </Col>
                                <Col md={"auto"} className="my-auto">
                                    Cancelar
                                </Col>
                            </Row>
                        </TertiaryYellowButton>
                    </Col>
                </Row>
            </Container>
        </CreateCampaignHeaderStyles>
    );
}

const CreateCampaignHeaderStyles = styled.header`
    z-index:100;
    @media(min-height: 900px) {
        min-height: 7vh;  
        button{
            min-height: 6.7vh;  
        }       
    }
    @media(max-height: 900px) {
        min-height: 8vh; 
        button{
            min-height: 8vh;  
        }         
    }
    @media(max-height: 800px) {
        min-height: 9vh;
        button{
            min-height: 9vh;  
        }          
    }  
    @media(max-height: 700px) {
        min-height: 10vh;   
        button{
            min-height: 10vh;  
        }       
    }       
    position:fixed;
    top:0px;
    width:100%;
    background: #F9F9F9  0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #514F4E33;
    opacity: 1;
    h4{
        cursor:default;
        font-size: 3vmin !important;
        color:#514F4E;
        position: relative;
        letter-spacing: 0px;
        opacity: 1;   
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 40vw; 
        overflow: hidden;        
    }
    h5
    {        
        font-size: 2.3vmin !important;
        color: #817f7f;     
        position: relative;
        letter-spacing: 0px;
        opacity: 1;    
    }    
    button{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        margin-bottom:0px;
        i{
            font-size: 3.33vmin;
        }
    }
`

export default CreateCampaignHeader
