import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import { CreateCourseContext } from '../../../../../../../context/creator/createCourse/CreateCourseContext';
import { TransparentOkButton } from '../../../../../../../components/oldLayouts/SharedComponentStyles';
import { Answer } from "../../../../../../../domain/models/quiz/QuizModel";
import {
    GridDottedList,
    GridDottedItemList, CreateQuizTextInput,
    CreateQuizRadioInput,
    CreateQuizInputLabel
} from '../../../../../createTrilha/createTrilhaStyles';
import { useCreateAssessementModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateAssessementModuleContentContext';

interface AnswerListProps {
    answers: Answer[],
    questionIndex: number,
    handleAnswerContentChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, lessonIndex: number, questionIndex: number): void,
    handleAnswerDelete(questionIndex: number, answerIndex: number): void,
    handleMarkRightAnswer (questionIndex: number, answerIndex: number):void 
}


export function AnswerList({ answers, questionIndex, handleAnswerContentChange, handleAnswerDelete, handleMarkRightAnswer }: AnswerListProps) {
    const {

        showAssessementForm
    } = useCreateAssessementModuleContentContext();
    return (
        <Row>
            <Col>
                <GridDottedList className="pl-4">
                    {showAssessementForm&&answers.map((answer, answerIndex) => {
                        return (
                            <GridDottedItemList className="my-3">
                                <Row className="align-items-center">
                                    <Col md={9} className="align-items-center">
                                        <CreateQuizTextInput className="my-auto" value={answer.content}
                                            onChange={(e) => handleAnswerContentChange(e, answerIndex, questionIndex)}
                                            type="text" placeholder={"Digite a alternativa " + (answerIndex + 1)} />
                                    </Col>
                                    <Col md={3} className="align-items-center my-auto">
                                        <Row>
                                            <Col className="align-items-center my-auto" md={"auto"}>
                                                <CreateQuizRadioInput type="radio" checked={answer.isRightAnswer}
                                                    onChange={() => handleMarkRightAnswer(questionIndex, answerIndex)} />
                                            </Col>
                                            <Col className="align-items-center my-auto px-0" md={"auto"}>
                                                <CreateQuizInputLabel>Correta</CreateQuizInputLabel>
                                            </Col>
                                            <Col className="my-auto" md={"auto"}>
                                                <TransparentOkButton className={(answers.length < 3 ? ('d-none') : '')}
                                                    onClick={() => handleAnswerDelete(questionIndex, answerIndex)}>
                                                    <img src={`/img/createTrilha/trash-step-four.svg`} alt="" />
                                                </TransparentOkButton>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </GridDottedItemList>
                        );
                    })}
                </GridDottedList>
            </Col>
        </Row>);
}

