import { Col, ListGroup, Row } from 'react-bootstrap';
import ImageLessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/ImageLessonContentViewModel";
import LessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import VideoSourceEnum  from "../../../../../../../../domain/enum/VideoSourceEnum";
import HorizontalAlignEnum from "../../../../../../../../domain/enum/HorizontalAlignEnum";
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { TertiaryGreenButton, TertiaryYellowButton } from '../../../../../../../../components/Buttons/styles';
import { CreateCourseContext } from '../../../../../../../../context/creator/createCourse/CreateCourseContext';
import { GetYoutubeId } from '../../../../../../../../util/YoutubeUtils';
import { GetVimeoId } from '../../../../../../../../util/VimeoUtils';
import { AddModuleContext } from '../../../../../../../../context/creator/createCourse/AddModuleContext';
import { useCreateLessonModuleContentContext } from '../../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface EmbedLessonContentComponentProps {
    lessonContent: LessonContentViewModel;
    isFocused: boolean;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const EmbedLessonContentComponent = (props:EmbedLessonContentComponentProps) => {

    const {
        deleteLessonContent,
    } = useContext(AddModuleContext)

    const {
        editEmbedLessonContent
    } = useCreateLessonModuleContentContext()

    const onFocus = ()=>{
        props.setSelectedIndex(props.lessonContent.order)
    }
 
    return (
        <ImageEmbedContentComponentStyles 
            onClick={onFocus}
            isFocused={props.isFocused}
        >
            <Col
                className={"py-3 "+(props.isFocused?"on-focus":"")}
            >
                <Row>
                    <Col md="auto" className="mx-auto">
                        <div dangerouslySetInnerHTML={{ __html: props.lessonContent.content }} />
                    </Col>
                </Row>
                {
                    props.isFocused?
                    (
                        <Row className='mt-3'>
                            <Col md="auto" className='ml-auto '>
                                <TertiaryGreenButton onClick={editEmbedLessonContent}>
                                    <i
                                        className='bi bi-pencil'
                                    />
                                </TertiaryGreenButton>
                            </Col>
                            <Col md="auto" className='my-auto mr-2'>
                                <TertiaryYellowButton onClick={()=>deleteLessonContent(props.lessonContent.order)}>
                                    <i className='bi bi-trash'/>
                                </TertiaryYellowButton>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <></>
                    )
                }

            </Col>
        </ImageEmbedContentComponentStyles>
    );
}


const ImageEmbedContentComponentStyles = styled(Row)`
    background:${props => props.isFocused? "#F9F9F9":"inherit"};
    border:none!important;
    .list-group-horizontal{
        border-radius:1vmin!important;
        background-color:#E8E8E8;
        padding:none;
    }
    .list-group-item{
        padding:0px!important;
        border-radius:3vmin!important;
        border:none;
        background-color:#E8E8E8;
        i{
            font-size:3.5vmin;
        }

    }
    .list-group-item:first-of-type{
        border-right:0.11vmin solid #B9B8B8;
    }
    .list-group-item:last-of-type{
        border-left:0.11vmin solid #B9B8B8;
    }
    .bi.bi-trash{
        font-size:4vmin;
    }
    i{
        font-size:3.5vmin;
    }
    img{
        width:100%;
    }
`;
