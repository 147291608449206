import { useContext } from "react";
import { Row, Col } from "react-bootstrap"

//Contexts
import { AttendCourseContext, AttendCourseContextProvider } from '../../../context/apprentice/attend/AttendCourseContext'
import { AssessmentContextProvider } from "../../../context/apprentice/attend/AssessmentContext";

//Components
import {AttentCourseCollapsableSideMenu} from './sideMenu/AttentCourseCollapsableSideMenuWithCollapsableItems'
import {AttendCourseHeaderComponent} from '../../../components/Headers/AttendCourseHeaderComponent'
import PreparingCourse from "../../../components/Loaders/PreparingCourse";
import AttendAssessmentPage from "./assessment/AttendAssessmentPage";
import ModalCourseInfo from "./ModalCourseInfo";
import { AttendCourseContainer, PreviousButtonStyles, NextButtonStyles } from "./AttentCoursePageStyles";
import { AttendCourseModuleContent } from "./AttendCourseModuleContent";
import CertificateCoursePage from "./certificate/CertificateCoursePage";

export const AttendCoursePageWrapper = () => {
    return (
        <AttendCourseContextProvider>
            <AttentCoursePage />
        </AttendCourseContextProvider>
    )
}

export const AttentCoursePage = () => {
    
    const {
        courseDetails, 
        apprenticeCourseProgress, 
        showModalCourseInfo,setShowModalCourseInfo,        
        isLoading, 
        menuCollapsed, 
        isLoadingAssessment,
        canSeeNextModuleContentButton,
        canSeePreviousModuleContentButton,
        attendNextModuleContent,
        attendPreviousModuleContent,   
        selectedCertificate   
    } = useContext(AttendCourseContext)

    return(
        <>
            <ModalCourseInfo show={showModalCourseInfo} onHide={() => setShowModalCourseInfo(false)} />            
            {
                isLoading?
                (
                    <PreparingCourse/>
                )
                :
                (<>
                    {isLoadingAssessment?
                    (
                        <AssessmentContextProvider>
                            <AttendAssessmentPage />
                        </AssessmentContextProvider>                        
                    ) : 
                    (
                        <>                    
                        <AttendCourseHeaderComponent
                            courseName={courseDetails.title}
                            progress={(apprenticeCourseProgress.qtyContentDone / apprenticeCourseProgress.qtyContentInTotal) *100}
                        />
                        <AttendCourseContainer fluid={true} className="mr-0 px-0">
                            <Row className="page-row">
                                {
                                    selectedCertificate&&<CertificateCoursePage isModule={false}/>                                           
                                }
                                {
                                    !selectedCertificate&&<AttendCourseModuleContent/>
                                }
                                <AttentCourseCollapsableSideMenu/>
                                {
                                    canSeePreviousModuleContentButton() &&                                    
                                        <PreviousButtonStyles 
                                            className="pl-1 pr-1 py-3"

                                            onClick={()=>attendPreviousModuleContent()}>
                                            <i className="bi bi-chevron-left"/>
                                            <p>
                                                Anterior
                                            </p>
                                        </PreviousButtonStyles>
                                }
                                {
                                    canSeeNextModuleContentButton()&&
                                    <NextButtonStyles
                                        className="pl-1 pr-2 py-3"
                                        onClick={()=>attendNextModuleContent()}
                                        sideMenuIsOpen={!menuCollapsed}
                                        >
                                        <p>
                                            Próxima
                                        </p>
                                        <i className="bi bi-chevron-right"/>
                                    </NextButtonStyles>
                                }
                            </Row>
                        </AttendCourseContainer>
                    </>)
                    }

                </>)
            }
        </>
    )
}



