import { Col, ListGroup, Row } from 'react-bootstrap';
import VideoLessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/VideoLessonContentViewModel";
import LessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import VideoSourceEnum  from "../../../../../../../../domain/enum/VideoSourceEnum";
import HorizontalAlignEnum from "../../../../../../../../domain/enum/HorizontalAlignEnum";
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { TertiaryGreenButton, TertiaryYellowButton } from '../../../../../../../../components/Buttons/styles';
import { CreateCourseContext } from '../../../../../../../../context/creator/createCourse/CreateCourseContext';
import { GetYoutubeId } from '../../../../../../../../util/YoutubeUtils';
import { GetVimeoId } from '../../../../../../../../util/VimeoUtils';
import { AddModuleContext } from '../../../../../../../../context/creator/createCourse/AddModuleContext';
import { useCreateLessonModuleContentContext } from '../../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface VideoLessonContentComponentProps {
    lessonContent: LessonContentViewModel;
    isFocused: boolean;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    videoLesson:VideoLessonContentViewModel;
}

export const VideoLessonContentComponent = (props:VideoLessonContentComponentProps) => {

    const {
        reRender,
        setIsChanged
    } = useContext(CreateCourseContext)
    
    const {
        deleteLessonContent
    } = useContext(AddModuleContext)

    const {
        editVideoLessonContent,
        getSelectedLessonContent
    } = useCreateLessonModuleContentContext()


    const lessonContent = getSelectedLessonContent()

    const onFocus = ()=>{
        props.setSelectedIndex(props.lessonContent.order)
    }

    const setAlignLeft = ()=>{
        let newVideoLesson:VideoLessonContentViewModel = {
            Align:HorizontalAlignEnum.Left,
            FullSize:props.videoLesson.FullSize,
            VideoObject:props.videoLesson.VideoObject,
            IsNew:props.videoLesson.IsNew
        }

        lessonContent.content = JSON.stringify(newVideoLesson);
        setIsChanged(true)
        reRender()
    }

    const setAlignCenter = ()=>{
        let newVideoLesson:VideoLessonContentViewModel = {
            Align:HorizontalAlignEnum.Center,
            FullSize:props.videoLesson.FullSize,
            VideoObject:props.videoLesson.VideoObject,
            IsNew:props.videoLesson.IsNew
        }

        lessonContent.content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()

    }
    const setAlignRight = ()=>{
        let newVideoLesson:VideoLessonContentViewModel = {
            Align:HorizontalAlignEnum.Right,
            FullSize:props.videoLesson.FullSize,
            VideoObject:props.videoLesson.VideoObject,
            IsNew:props.videoLesson.IsNew
        }
        lessonContent.content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()
    }

    const changeSize = ()=>{
        let newVideoLesson:VideoLessonContentViewModel = {
            Align:HorizontalAlignEnum.Center,
            FullSize:!props.videoLesson.FullSize,
            VideoObject:props.videoLesson.VideoObject,
            IsNew:props.videoLesson.IsNew
        }
        lessonContent.content = JSON.stringify(newVideoLesson);
        setIsChanged(true)

        reRender()
    }

    const returnVideoUrlSRC = (): string => {
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Azure) {
            return props.videoLesson.VideoObject.VideoLink.trim();
        }
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Youtube) {
            return "https://www.youtube.com/embed/" + GetYoutubeId(props.videoLesson.VideoObject.VideoLink.trim());
        }
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Vimeo) {
            return "https://player.vimeo.com/video/" + GetVimeoId(props.videoLesson.VideoObject.VideoLink.trim());
        }
        return "";
    };

    function getVideoAlign(): string {
        switch (props.videoLesson.Align) {
            case HorizontalAlignEnum.Center:
                return 'mx-auto';
            case HorizontalAlignEnum.Left:
                return 'mr-auto';
            case HorizontalAlignEnum.Right:
                return 'ml-auto';
            default:
                return '';
        }
    }

    return (
        <VideoLessonContentComponentStyles 
            onClick={onFocus}
            fullSize={props.videoLesson.FullSize}
            isFocused={props.isFocused}
        >
            <Col
                className={"py-3 "+(props.isFocused?"on-focus":"")}
            >
                <Row>
                    <Col md={props.videoLesson.FullSize?11:6} className={getVideoAlign()}>
                        {props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Azure ?
                            (
                                <video controls src={returnVideoUrlSRC()}>
                                </video>
                            )
                            :
                            (
                                <iframe src={returnVideoUrlSRC()} />
                            )}
                    </Col>
                </Row>
                {
                    props.isFocused?
                    (
                        <Row className='mt-3'>
                            <Col md="auto" className='ml-3'>
                                <ListGroup horizontal>
                                    <ListGroup.Item>
                                        <TertiaryGreenButton onClick={editVideoLessonContent}>
                                            <i
                                                className='bi bi-pencil'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    
                                        
                                    <ListGroup.Item>
                                        <TertiaryGreenButton active={props.videoLesson.Align === HorizontalAlignEnum.Left} onClick={setAlignLeft} disabled={props.videoLesson.FullSize}>
                                            <i
                                                className='bi bi-text-left'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <TertiaryGreenButton active={props.videoLesson.Align === HorizontalAlignEnum.Center} onClick={setAlignCenter} disabled={props.videoLesson.FullSize}>
                                            <i
                                                className='bi bi-text-center'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                    <ListGroup.Item>                           
                                        <TertiaryGreenButton active={props.videoLesson.Align === HorizontalAlignEnum.Right} onClick={setAlignRight} disabled={props.videoLesson.FullSize}>
                                            <i
                                                className='bi bi-text-right'
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                            
                                    <ListGroup.Item>                           
                                        <TertiaryGreenButton onClick={changeSize}>
                                            <i
                                                className={
                                                    props.videoLesson.FullSize?
                                                    'bi bi-arrows-angle-contract'
                                                    :
                                                    'bi bi-arrows-angle-expand'
                                                }
                                            />
                                        </TertiaryGreenButton>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col md="auto" className='ml-auto my-auto mr-2'>
                                <TertiaryYellowButton onClick={()=>deleteLessonContent(props.lessonContent.order)}>
                                    <i className='bi bi-trash'/>
                                </TertiaryYellowButton>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <></>
                    )
                }

            </Col>
        </VideoLessonContentComponentStyles>
    );
}


const VideoLessonContentComponentStyles = styled(Row)`
    background:${props => props.isFocused? "#F9F9F9":"inherit"};
    border:none!important;
    .list-group-horizontal{
        border-radius:1vmin!important;
        background-color:#E8E8E8;
        padding:none;
    }
    .list-group-item{
        padding:0px!important;
        border-radius:3vmin!important;
        border:none;
        background-color:#E8E8E8;
        i{
            font-size:3.5vmin;
        }

    }
    .list-group-item:first-of-type{
        border-right:0.11vmin solid #B9B8B8;
    }
    .list-group-item:last-of-type{
        border-left:0.11vmin solid #B9B8B8;
    }
    .bi.bi-trash{
        font-size:4vmin;
    }
    video{
        width:100%;
        border:none;
    }
    iframe{
        width:100%;
        min-height:${props => props.fullSize? "70vh":"40vh"};
        border:none;
    }
`;
