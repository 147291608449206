import { useContext } from "react";
import styled from 'styled-components'
import { Col, Row, Container } from "react-bootstrap"

//Components
import AssessmentFeedBackHeader from '../../../../components/Headers/AssessmentFeedBackHeader'
import AssessmentFeedbackPageContent from './AssessmentFeedbackPageContent'

//Context
import { AssessmentContext } from '../../../../context/apprentice/attend/AssessmentContext';
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";

interface AssessmentFeedbackPageProps {
    isFullScreen: boolean    
}

const AssessmentFeedbackPage = (props: AssessmentFeedbackPageProps) => {

    const {
    } = useContext(AttendCourseContext);
    
    const {
        isReproved,
    } = useContext(AssessmentContext);

    return (<>
        <AssessmentFeedBackHeader
            reproved={isReproved()}
        />
        <FeedbackContainer fluid={true}>
            <Row className="page-row">
                <AssessmentFeedbackPageContent
                    isFullScreen={props.isFullScreen} />
            </Row>
        </FeedbackContainer>
    </>)
}

export default AssessmentFeedbackPage;


const FeedbackContainer = styled(Container)`
    
    .page-row
    {
        max-width:100vw;
        margin-top:8.9vh;
        min-height:80vh;
    }
`