import React, { forwardRef, useContext } from 'react'
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'

import { 
    Row, 
    Col, 
    Card,
    ListGroup,
    ListGroupItem } from 'react-bootstrap';


import { 
    ModuleListComponentStyles, 
    OrderModuleButtonStyles, 
    SaveOrderModuleButtonStyles, 
    DirectionOrderModuleButtonStyles 
} from './CreateCourseContentFormStyles';

export const ModuleOrdering = forwardRef((props, ref) => {
    const { 
        course, 
        isEdit,
        isChanged,
        setIsLoading,         
        setIsChanged,         
        orderModule,
        openOrderModulePage,
        setOpenOrderModulePage,        
        setDataIsLoaded, 
        handleOrderModule,
        handleSaveOrderModule,
    } = useContext(CreateCourseContext);
  
    return (<>
        
        <Row>
            <Col md={10} className="mx-auto">
                <Row>
                    <Col md={7}>
                        <Row>
                            <h1>Conteúdo</h1>
                        </Row>
                        <Row>
                            <p>Reordenar modulos</p>
                        </Row>
                        <Row>
                            <p>Utilize as setas para movimentar cada módulo e alterar a ordenação.</p>
                        </Row>
                    </Col>

                    <Col md="auto" className="ml-auto my-auto">
                        <SaveOrderModuleButtonStyles  className="float-right px-4"
                            onClick={() => handleSaveOrderModule()}>
                            <Row>
                                <Col md="auto" className="my-auto">
                                    <p>Salvar ordenação</p>
                                </Col>
                                <Col md="auto" className="pl-0">
                                <i className="bi bi-check2" />
                                </Col>
                            </Row>                            
                        </SaveOrderModuleButtonStyles>
                    </Col> 

                </Row>
            </Col>
        </Row>

        <Row>
            <ModuleListComponentStyles>
                <Row>
                    <Col md={10} className="pl-0 mx-auto">
                        <Row>
                            <Col>
                                <ListGroup className="w-100 px-0">
                                    {course.modules.sort((a, b) => a.order - b.order).map((item, modIndex) => {
                                        return (
                                            <ListGroupItem className="px-0 py-2" key={modIndex}>
                                                <Row>
                                                    <Col md={1} className="mt-1 my-auto">
                                                        <DirectionOrderModuleButtonStyles  className="float-right px-4"
                                                            title="mover para baixo"
                                                            disabled={course.modules[course.modules.length - 1].order == item.order ? true : false}
                                                            onClick={() => handleOrderModule("DOWN", item.order)}>
                                                            <Row>
                                                                <Col md="auto" className="my-auto">
                                                                    <i className="bi bi-arrow-down" />
                                                                </Col>
                                                            </Row>
                                                        </DirectionOrderModuleButtonStyles>
                                                    </Col>
                                                    <Col md={1} className="mt-1 my-auto">                                                        
                                                        <DirectionOrderModuleButtonStyles title="mover para cima"
                                                            disabled={course.modules[0].order == item.order ? true : false}
                                                        className="float-right px-4"
                                                            onClick={() => handleOrderModule("UP", item.order)}>
                                                            <Row>
                                                                <Col md="auto" className="my-auto">
                                                                    <i className="bi bi-arrow-up" />
                                                                </Col>
                                                            </Row>
                                                        </DirectionOrderModuleButtonStyles>
                                                    </Col>
                                                    <Col md={10} className="mt-1 my-auto">
                                                        <Card>
                                                            <Card.Body className="py-2">
                                                                <p>{item.title}</p>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                
                                            </ListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ModuleListComponentStyles>
        </Row>

    </>)

})

