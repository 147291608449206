import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import TrilhaStudentCardView from '../../domain/models/cardView/TrilhaStudentCardView';
import PaginatedSearchResult from '../../domain/util/PaginatedSearchResult';
import AvaliableApprenticeCourseFilter from '../FilterInterface/AvaliableApprenticeCourseFilter'
import {
        AvaliableCourseCard,
        AvaliableCourseForStudentComponentTitle,
        DurationString,
        StyledPagedSearchButtonList
} from './styles'
import { formatWorkLoad } from '../../util/Formatter'
import styled from 'styled-components'
import CategoryViewModel from '../../domain/models/CategoryViewModel';
import { useState } from 'react';


interface AvaliableCourseForApprenticeComponentProps{
    categoriesFilter:CategoryViewModel[];
    trilhasStudentCardViewPaginatedSearch:PaginatedSearchResult<TrilhaStudentCardView>;
    getDistributedTrilhas:(pageNumber: number, categories?:string[]) => void;
    isLoading:boolean;
    headPhrase:string;
}

export const AvaliableCourseForStudentComponent = (props:AvaliableCourseForApprenticeComponentProps)=>{
    const [activeFilter, setActiveFilter] = useState<CategoryViewModel[]>([])

    const getDistributedTrilhas = (pageNumber: number, filters:string[]=[]) => {
        if(filters.length > 0){
            props.getDistributedTrilhas(pageNumber,filters)
        }else{
            props.getDistributedTrilhas(pageNumber)
        }
    };

    function AttendCourse(courseId: string) {
        window.location.href = "/course/" + courseId + "/info"
    }

    const RenderPageButtons = ():JSX.Element =>{
        let buttons:JSX.Element[] = [
            <ListGroup.Item>
                <Button className={props.trilhasStudentCardViewPaginatedSearch.currentPage === 1?"active":""} onClick={() => getDistributedTrilhas(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for(let i=2; i < props.trilhasStudentCardViewPaginatedSearch.totalPages ; i++){
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto "+(props.trilhasStudentCardViewPaginatedSearch.currentPage === i?"active":"")} onClick={() => getDistributedTrilhas(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }
        if( props.trilhasStudentCardViewPaginatedSearch.totalPages > 1){
            buttons.push(
                <ListGroup.Item>
                    <Button className={props.trilhasStudentCardViewPaginatedSearch.totalPages === props.trilhasStudentCardViewPaginatedSearch.currentPage?"active":""} onClick={() => getDistributedTrilhas(props.trilhasStudentCardViewPaginatedSearch.totalPages)}>
                        <p>{props.trilhasStudentCardViewPaginatedSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )

        }


        return(
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!props.trilhasStudentCardViewPaginatedSearch.hasPrevious} onClick={() => getDistributedTrilhas(props.trilhasStudentCardViewPaginatedSearch.currentPage-1)}>
                        <i className="bi bi-chevron-left"/>
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!props.trilhasStudentCardViewPaginatedSearch.hasNext} onClick={() => getDistributedTrilhas(props.trilhasStudentCardViewPaginatedSearch.currentPage+1)}>
                        <i className="bi bi-chevron-right"/>
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>)
    }

    return(
        <Row className="mt-3 mx-md-2 mx-lg-2 mx-xl-2">
            <Col className="mx-auto">
                <Row>
                    <AvaliableCourseForStudentComponentTitle md={"auto"}>
                        <section className='d-none d-lg-block d-md-block'>
                            <h3 className={props.isLoading?"loading":""}>
                                {props.headPhrase}   
                            </h3> 
                        </section>
                        <section className='d-block d-lg-none d-md-none'>
                            <h3 className={props.isLoading?"loading":""}>
                                Cursos disponíveis para você   
                            </h3> 
                        </section>

                    </AvaliableCourseForStudentComponentTitle>
                </Row>
                {
                    props.isLoading?
                    (
                        <></>
                    )
                    :
                    (
                        <AvaliableApprenticeCourseFilter
                            categories={props.categoriesFilter}
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                            getDistributedTrilhas={getDistributedTrilhas}
                            results={props.trilhasStudentCardViewPaginatedSearch.totalRecords}
                        />
                    )
                }

                <Row>
                    {
                        props.trilhasStudentCardViewPaginatedSearch.items.length > 0 ?
                        (
                            props.trilhasStudentCardViewPaginatedSearch.items.map(
                                (trilha: TrilhaStudentCardView)=>{
                                    return(
                                    <Col lg={3} md={6} className="my-2 mt-3" >
                                        <AvaliableCourseCard as={"button"} className={(props.isLoading?"loading":"")+" card"} onClick={() => AttendCourse(trilha.id)}>
                                                {
                                                    props.isLoading?<Card.Header></Card.Header>:<Card.Img variant="top" src={trilha.coverImage}/>
                                                }
                                                
                                                <Card.Body className="pb-0 pt-2 px-2">
                                                    <Card.Title as={"h2"} className="pb-0 pt-3">
                                                        {trilha.title}
                                                    </Card.Title>
                                                    <Card.Text>
                                                        {trilha.description}
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer className={"pb-2 pt-1 "+(props.isLoading?"d-none":"")}>
                                                    <Row className="iconCourseMobile">
                                                        <Col xs={2} md={"auto"} className="my-auto">
                                                            <i className="bi bi-alarm"/>
                                                        </Col>
                                                        <Col xs={4} md={"auto"} className="my-auto px-0 pt-1 pl-2">
                                                            <DurationString>
                                                                {
                                                                    formatWorkLoad(trilha.workLoad)
                                                                }
                                                            </DurationString>
                                                        </Col>
                                                        <Col xs={2} md={"auto"} className="ml-auto">
                                                                <i className="bi bi-info-circle"/>
                                                        </Col>
                                                        {/* <Col md={"auto"}>
                                                            <Button>
                                                                <i className="bi bi-heart"/>
                                                            </Button>
                                                        </Col> */}
                                                    </Row>
                                                </Card.Footer>
                                        </AvaliableCourseCard>
                                    </Col>
                                    )
                            })
                        )
                        :
                        (
                            <>

                                
                                <Col sm={12} xs={12} md={6} className="my-auto text-center">
                                    <img className ="img-responsive" src={"/img/student/home/woman-meditating.svg"} />
                                </Col>

                                <Col className="my-xs-2 my-sm-2 my-md-auto my-lg-auto my-xl-auto text-left">
                                    <Row className="py-2">
                                        <NoResultSearchText>
                                        Não encontramos nenhum resultado para a sua busca!
                                        </NoResultSearchText>
                                    </Row>
                                    <Row className="py-2">
                                        <BreathDeepText>
                                        Respire fundo e tente buscar novamente.
                                        </BreathDeepText>
                                    </Row>
                                </Col>
                            </>
                        )
                    }

                </Row>
                {
                    props.trilhasStudentCardViewPaginatedSearch.totalPages > 1 ?
                    (
                    <Row className={props.isLoading?"d-none":""}>
                        <Col xs={"auto"} md={"auto"} className="mx-auto mt-5">                        
                            {
                                RenderPageButtons()
                            }
                        </Col>
                    </Row>
                    )
                    :
                    (
                        <></>
                    )
                }

            </Col>
        </Row>
    )
}

export const NoResultSearchText = styled.h3`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    /* Neutral/8 */
    color: #514F4E;

    @media screen and (max-width: 990px){
        padding: 20px;
        text-align: center;
    }
`
export const BreathDeepText = styled.h4`

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #636060;

    @media screen and (max-width: 990px){
        padding: 20px;
        text-align: center;
    }
`


