import { useState } from "react";
import { StudentHeaderButtons, StudentHeaderStyles, StudentHeaderTabs, StudentHeaderTitle } from "./styles"
import { Button, Col, Container, Row } from 'react-bootstrap';
import CreatorHeaderActiveTabEnum from "../../domain/enum/CreatorHeaderActiveTabEnum";
import ProfileMenu from '../PopupMenu/ProfileMenu'

interface StudentHeaderProps{
    activeTab:CreatorHeaderActiveTabEnum;
}
const StudentHeader = (props:StudentHeaderProps): JSX.Element => {
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    function redirectToLearning(){
        window.location.href = "/mylearning"
    }
    function redirectToHome(){
        window.location.href = "/home"
    }

    return(
        <>
        <StudentHeaderStyles>
            <Container fluid={true}>
                <Row> 
                    <Col md={"auto"} className="d-table">
                        <StudentHeaderTitle className="px-4 d-table-cell align-middle">
                            Cursos Livres
                        </StudentHeaderTitle>
                    </Col>
                    <StudentHeaderTabs md={"auto"} className="px-1">
                        <Button className={"py-2 px-4 "+(props.activeTab === CreatorHeaderActiveTabEnum.home?"selected":"")} onClick={redirectToHome}>
                            <Row>
                                <Col md={"auto"} className="px-1">
                                    <i className="bi bi-house-door"/>                                    
                                </Col>
                                <Col className="px-2 d-table">
                                    <p className="d-table-cell align-middle">
                                        Catálogo 
                                    </p>                                    
                                </Col>
                            </Row>

                        </Button>
                    </StudentHeaderTabs>
                    <StudentHeaderTabs md={"auto"} className="px-1">
                        <Button className={"py-2 px-4 "+(props.activeTab === CreatorHeaderActiveTabEnum.myKnowledge?"selected":"")} onClick={redirectToLearning} >
                            <Row>
                                <Col md={"auto"} className="px-1">
                                    <i className="bi bi-compass"/>                                    
                                </Col>
                                <Col className="px-2 d-table">
                                    <p className="d-table-cell align-middle">
                                        Meu Aprendizado
                                    </p>                                    
                                </Col>
                            </Row>

                        </Button>
                    </StudentHeaderTabs>
                    {/* <StudentHeaderButtons md={"auto"} className="my-auto px-1 ml-auto">
                        <Button>
                            <i className="bi bi-search"/>
                        </Button>
                    </StudentHeaderButtons>
                    <StudentHeaderButtons md={"auto"} className="my-auto px-1">
                        <Button>
                            <i className="bi bi-heart"/>
                        </Button>
                    </StudentHeaderButtons>
                    <StudentHeaderButtons md={"auto"} className="my-auto px-1">
                        <Button>
                            <i className="bi bi-bell"/>
                        </Button>
                    </StudentHeaderButtons>
                    <StudentHeaderButtons md={"auto"} className="my-auto px-1">
                        <Button>
                            <i className="bi bi-brightness-high"/>
                        </Button>
                    </StudentHeaderButtons> */}
                    <StudentHeaderButtons md={"auto"} className="ml-auto my-auto pl-1 pr-4" onClick={() => setShowProfileMenu(!showProfileMenu)}>
                        <Button>
                            <i className="bi bi-person"/>
                        </Button>
                    </StudentHeaderButtons>
                </Row>
            </Container>
        </StudentHeaderStyles>
        <ProfileMenu
            showProfileMenu={showProfileMenu}
        />
        </>
    )
} 
export default StudentHeader;