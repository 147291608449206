import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { isAdmin, isAdminGlobal, isTeacher } from '../../util/UserUtil';
import CreatorHeaderActiveTabEnum from '../../domain/enum/CreatorHeaderActiveTabEnum'
import {HomeHeaderStyles} from './styles'
import {CreatorsHeaderStylesMobile} from './stylesMobile'
import ProfileMenu from '../PopupMenu/ProfileMenu'
import ProfileMenuMobile from '../PopupMenu/ProfileMenuMobile'

interface HomeHeaderProps{
    activeTab:CreatorHeaderActiveTabEnum
}

const HomeHeader = (props:HomeHeaderProps): JSX.Element => {

    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showProfileMenuMobile, setShowProfileMenuMobile] = useState(false);
    
    function redirectToCreate() {        
        window.location.href = "/course/create/"        
    }

    function redirectToManage(){
        window.location.href = "/manage"
    }

    function redirectToHome(){
        window.location.href = "/home"
    }

    function redirectToLearning(){
        window.location.href = "/mylearning"
    }

    function redirectToControlPanel(){
        window.location.href = "/controlpanel";
    }

    return(
        <>
            <HomeHeaderStyles className="d-none d-md-block d-lg-block">
                <Container fluid={true}>
                    <Row className='header-row w-100'> 
                        <Col md={"auto"} className="d-table">
                            <h1 className="px-4 d-table-cell align-middle">
                                Cursos Livres
                            </h1>
                        </Col>
                        <Col md={"auto"} className="px-1 tab">
                            <Button className={"py-2 px-4 "+ (props.activeTab === CreatorHeaderActiveTabEnum.home?"selected":"")} onClick={redirectToHome}>
                                <Row>
                                    <Col md={"auto"} className="px-1">
                                        <i className="bi bi-house-door"/>
                                    </Col>
                                    <Col className="px-2 d-table">
                                        <p className="d-table-cell align-middle">
                                            Catálogo
                                        </p>                                    
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                        {
                            (isTeacher() || isAdmin() || isAdminGlobal()) &&
                                <Col md={"auto"} className="px-1 tab">
                                    <Button className={"py-2 px-4 "+ (props.activeTab === CreatorHeaderActiveTabEnum.manageCourse?"selected":"")} onClick={redirectToManage}>
                                        <Row>
                                            <Col md={"auto"} className="px-1">
                                                <i className="bi bi-list-task"/>                                    
                                            </Col>
                                            <Col className="px-2 d-table">
                                                <p className="d-table-cell align-middle">
                                                    Gerenciar Cursos
                                                </p>                                    
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>                   
                        }
                        <Col md={"auto"} className="px-1 tab">
                            <Button className={"py-2 px-4 "+ (props.activeTab === CreatorHeaderActiveTabEnum.myKnowledge?"selected":"")} 
                                onClick={redirectToLearning}>
                                <Row>
                                    <Col md={"auto"} className="px-1">
                                        <i className="bi bi-compass"/>
                                    </Col>
                                    <Col className="px-2 d-table">
                                        <p className="d-table-cell align-middle">
                                            Meu Aprendizado
                                        </p>                                    
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                        {
                            (isAdmin() || isAdminGlobal()) &&
                            <Col md={"auto"} className="px-1 tab">
                                <Button className={"py-2 px-4 "+ (props.activeTab === CreatorHeaderActiveTabEnum.controlPanel?"selected":"")} 
                                    onClick={redirectToControlPanel}>
                                    <Row>
                                        <Col md={"auto"} className="px-1">
                                            <i className="bi bi-briefcase"/>
                                        </Col>
                                        <Col className="px-2 d-table">
                                            <p className="d-table-cell align-middle">
                                                Painel de Controle
                                            </p>
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        }
                        {
                            (isTeacher() || isAdmin() || isAdminGlobal()) &&
                            <Col md={"auto"} className="my-auto px-1 ml-auto nav-button">
                                <Button onClick={redirectToCreate}>
                                    <i className="bi bi-plus-circle"/>
                                </Button>
                            </Col>
                        }
                        {
                            /*
                            <Col md={"auto"} className="my-auto px-1 nav-button">
                                <Button >
                                    <i className="bi bi-search"/>
                                </Button>
                            </Col>
                            <Col md={"auto"} className="my-auto px-1 nav-button">
                                <Button>
                                    <i className="bi bi-heart"/>
                                </Button>
                            </Col>
                            <Col md={"auto"} className="my-auto px-1 nav-button">
                                <Button>
                                    <i className="bi bi-bell"/>
                                </Button>
                            </Col>
                            <Col md={"auto"} className="my-auto px-1 nav-button">
                                <Button>
                                    <i className="bi bi-brightness-high"/>
                                </Button>
                            </Col>
                            */
                        }
                        <Col md={"auto"} className={"my-auto pl-1 pr-0 nav-button " + ((isTeacher() || isAdmin() || isAdminGlobal())?"":"ml-auto")}>
                            <Button onClick={() => setShowProfileMenu(!showProfileMenu)} >
                                <i className="bi bi-person"/>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </HomeHeaderStyles>
            <CreatorsHeaderStylesMobile className="d-block d-md-none d-lg-none"> 
                    <ul>
                        <li>
                            <a href="/home">
                                <svg width="26" height="46" viewBox="0 0 26 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.292 0.292811C12.3849 0.199685 12.4952 0.125799 12.6167 0.0753859C12.7382 0.024973 12.8685 -0.000976562 13 -0.000976562C13.1315 -0.000976563 13.2618 0.024973 13.3833 0.0753859C13.5048 0.125799 13.6151 0.199685 13.708 0.292811L25.708 12.2928C25.8008 12.3858 25.8744 12.4963 25.9245 12.6178C25.9746 12.7392 26.0002 12.8694 26 13.0008V27.0008C26 27.266 25.8946 27.5204 25.7071 27.7079C25.5196 27.8955 25.2652 28.0008 25 28.0008H16C15.7348 28.0008 15.4804 27.8955 15.2929 27.7079C15.1054 27.5204 15 27.266 15 27.0008V19.0008H11V27.0008C11 27.266 10.8946 27.5204 10.7071 27.7079C10.5196 27.8955 10.2652 28.0008 10 28.0008H1C0.734785 28.0008 0.480431 27.8955 0.292895 27.7079C0.105358 27.5204 1.59499e-06 27.266 1.59499e-06 27.0008V13.0008C-0.000233099 12.8694 0.0254349 12.7392 0.0755383 12.6178C0.125642 12.4963 0.199198 12.3858 0.292002 12.2928L12.292 0.292811ZM2 13.4148V26.0008H9V18.0008C9 17.7356 9.10536 17.4812 9.2929 17.2937C9.48043 17.1062 9.73479 17.0008 10 17.0008H16C16.2652 17.0008 16.5196 17.1062 16.7071 17.2937C16.8946 17.4812 17 17.7356 17 18.0008V26.0008H24V13.4148L13 2.41481L2 13.4148Z" fill="#6468C8"/>
                                    <path d="M0 46C0 43.7909 1.79086 42 4 42H22C24.2091 42 26 43.7909 26 46H0Z" fill="#6468C8"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="/mylearning">
                                <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30.0322C18.4478 30.0322 21.7544 28.6626 24.1924 26.2246C26.6304 23.7866 28 20.48 28 17.0322C28 13.5844 26.6304 10.2778 24.1924 7.83984C21.7544 5.40187 18.4478 4.03223 15 4.03223C11.5522 4.03223 8.24558 5.40187 5.80761 7.83984C3.36964 10.2778 2 13.5844 2 17.0322C2 20.48 3.36964 23.7866 5.80761 26.2246C8.24558 28.6626 11.5522 30.0322 15 30.0322ZM15 32.0322C18.9782 32.0322 22.7936 30.4519 25.6066 27.6388C28.4196 24.8258 30 21.0105 30 17.0322C30 13.054 28.4196 9.23867 25.6066 6.42562C22.7936 3.61258 18.9782 2.03223 15 2.03223C11.0218 2.03223 7.20644 3.61258 4.3934 6.42562C1.58035 9.23867 0 13.054 0 17.0322C0 21.0105 1.58035 24.8258 4.3934 27.6388C7.20644 30.4519 11.0218 32.0322 15 32.0322Z" fill="#514F4E"/>
                                    <path d="M11 2C11 1.46957 11.2107 0.960859 11.5858 0.585787C11.9608 0.210714 12.4695 0 13 0L17 0C17.5304 0 18.0391 0.210714 18.4142 0.585787C18.7893 0.960859 19 1.46957 19 2C19 2.53043 18.7893 3.03914 18.4142 3.41421C18.0391 3.78929 17.5304 4 17 4H13C12.4695 4 11.9608 3.78929 11.5858 3.41421C11.2107 3.03914 11 2.53043 11 2ZM12.88 14.88L22.78 9.22L17.12 19.12L7.21997 24.78L12.88 14.88Z" fill="#514F4E"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={() => setShowProfileMenuMobile(!showProfileMenuMobile)}>
                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 16.7348 0.105357 16.4804 0.292893 16.2929C0.48043 16.1054 0.734784 16 1 16H21C21.2652 16 21.5196 16.1054 21.7071 16.2929C21.8946 16.4804 22 16.7348 22 17C22 17.2652 21.8946 17.5196 21.7071 17.7071C21.5196 17.8946 21.2652 18 21 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17ZM0 9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9C22 9.26522 21.8946 9.51957 21.7071 9.70711C21.5196 9.89464 21.2652 10 21 10H1C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9ZM0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H21C21.2652 0 21.5196 0.105357 21.7071 0.292893C21.8946 0.48043 22 0.734784 22 1C22 1.26522 21.8946 1.51957 21.7071 1.70711C21.5196 1.89464 21.2652 2 21 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1Z" fill="#514F4E"/>
                                </svg>
                            </a>
                        </li>
                    </ul>
            </CreatorsHeaderStylesMobile>
            <ProfileMenu
                showProfileMenu={showProfileMenu}
            />
            <div className="d-block d-md-none d-lg-none">
                <ProfileMenuMobile 
                    showProfileMenuMobile={showProfileMenuMobile}
                />
            </div>
        </>
    )
} 

export default HomeHeader;

