import { useContext } from 'react';
import { Col, Container, Modal, Row } from "react-bootstrap";
import { PrimaryGreenButton, PrimaryPurpleButton, SecondaryGreenButton } from '../../../Buttons/styles';
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddDocumentModalContext } from '../context/AddDocumentModalContext';

export const AddDocumentModalFooter = () => {
    const {
        navigationPosition, 
        documentObject,
        handleAddFileButton, 
        onSubmit,
        setNavigationPosition
    } = useContext(AddDocumentModalContext);

    return (
        <Modal.Footer>
            {(navigationPosition === AddFileNavigationPositionEnum.verifyFile) && (
                <Container>
                    <Row className="mb-2">
                        {
                            documentObject.Url!==""&&
                            (
                                <>
                                    <Col md="auto">
                                        <SecondaryGreenButton onClick={handleAddFileButton} className="py-2 px-3">
                                            <Row>
                                                <Col md="auto" className="my-auto pr-0">
                                                    <i className='bi bi-arrow-repeat' />
                                                </Col>
                                                <Col className="my-auto">
                                                    <p>
                                                        Alterar Arquivo
                                                    </p>
                                                </Col>
                                            </Row>
                                        </SecondaryGreenButton>
                                    </Col>
                                    <Col md="auto" className="ml-auto">
                                        <PrimaryPurpleButton className="py-2 px-3" onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.chooseViewType)}>
                                            <Row>
                                                <Col className="my-auto">
                                                    <p>
                                                        Continuar
                                                    </p>
                                                </Col>
                                                <Col md="auto" className="my-auto pl-0">
                                                    <i className="bi bi-arrow-right" />
                                                </Col>
                                            </Row>
                                        </PrimaryPurpleButton>
                                    </Col>
                                </>
                            )
                        }


                    </Row>
                </Container>
            )}

            {(navigationPosition === AddFileNavigationPositionEnum.chooseViewType) && (
                <Container>
                    <Row className="mb-2">
                        <Col md="auto">
                            <SecondaryGreenButton onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.verifyFile)} className="py-2 px-3">
                                <Row>
                                    <Col md="auto" className="my-auto pr-0">
                                        <i className='bi bi-arrow-left' />
                                    </Col>
                                    <Col className="my-auto">
                                        <p>
                                            Voltar
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryGreenButton>
                        </Col>
                    </Row>
                </Container>
            )}
        </Modal.Footer>
    );
};
