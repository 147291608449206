import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";

export const TaskDeliveryDate = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);
    return (
        <>
            <Row>
                <Col>
                    <h2>
                        Entregue em
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        30/05/2022
                    </p>
                </Col>
            </Row>
        </>
    );
};
