import { useParams } from "react-router";
import ApprenticeServices, { APIResponse, GetCertificateViewModel } from "../../../services/ApprenticeServices";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CertificateArea, CertificateContainer } from "../attend/AttentCoursePageStyles";
import { Button, Col, Container, Row } from "react-bootstrap";
import { setToken } from "../../../router/TokenUtils";
import PageLoader from "../../../components/Common/Loader";
import BigBrainFooter from "../../../components/Footers/BigBrainFooter";
import ReeducationFooter from "../../../components/Footers/ReeducationFooter";

type ValidateCertificateProps = {
    institutionId:string
    apprenticeId:string;
    courseId:string; 
    moduleId:string|undefined
}
const apprenticeService = new ApprenticeServices();

function ValidateCertificate(){
    const { apprenticeId, courseId, moduleId, institutionId } = useParams<ValidateCertificateProps>();
    
    const [isLoading, setIsLoading] = useState(true);
    const certificateRef= useRef<HTMLDivElement>(null);
    const [certificateInfo, setCertificateInfo] = useState<GetCertificateViewModel|null>(null); 

    const [pdfUrl, setPdfUrl] = useState<string|null>(null); 

    useEffect(() => {
        loadPage()
    }, [])

    async function loadPage(){

        setToken(String(process.env.REACT_APP_SERVICE_TOKEN))


        const certificatePlaceHolder = window.document.createElement('div');

        certificatePlaceHolder.style.display = 'none';

        window.document.body.appendChild(certificatePlaceHolder);

        if(certificatePlaceHolder==null){            
            return;
        }

        let response:APIResponse<GetCertificateViewModel>

        if(moduleId!==undefined){
            response = await apprenticeService.getMyModuleCertificateDetails({
                institutionId: institutionId,
                courseId: courseId,
                moduleId: moduleId,
                apprenticeId: apprenticeId
            })


        }else{
            response = await apprenticeService.getMyCourseCertificateDetails({
                institutionId: institutionId,
                courseId: courseId,
                apprenticeId: apprenticeId
            })

        }
                
        if(response === undefined||response.result === null){
            setIsLoading(false);
            return;
        }
        setCertificateInfo(response.result);
        certificatePlaceHolder.innerHTML = response.result.certificate.trim();
        await convertImagesToBase64(certificatePlaceHolder.firstElementChild as HTMLElement)

        setTimeout(() => {
            certificatePlaceHolder.style.display = 'block';

            html2canvas(certificatePlaceHolder.firstElementChild as HTMLElement,{scale:3}).then((canvas) => {
                certificatePlaceHolder.style.display = 'none';
                certificatePlaceHolder.innerHTML = '';
                const imgData = canvas.toDataURL('image/png', 1);

                // Cria um documento PDF usando jsPDF
                const pdf = new jsPDF('landscape', 'px', 'a4'); // Defina o tamanho e orientação conforme necessário
                const pdfWidth = pdf.internal.pageSize.getWidth();  // Largura do PDF em mm
                const pdfHeight = pdf.internal.pageSize.getHeight(); // Altura do PDF em mm
        
                // Dimensões da imagem
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
        
                // Calcular escala para ajustar a imagem ao PDF
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight); // A menor escala que mantém a imagem proporcional
        
                const imgScaledWidth = imgWidth * ratio;  // Largura da imagem escalada
                const imgScaledHeight = imgHeight * ratio; // Altura da imagem escalada
    
                pdf.addImage(imgData, 'PNG', 0, 0, imgScaledWidth, imgScaledHeight);
    
                // Converte o PDF para um blob
                const pdfBlob = pdf.output('blob');
    
                // Cria uma URL temporária a partir do Blob
                const pdfUrl = URL.createObjectURL(pdfBlob);
                
                // Define a URL temporária para o estado

                setPdfUrl(pdfUrl);
            }).finally(()=>{

                setIsLoading(false);
            })
    
        }, 100);
    }

    async function GetImageFromStorage(src: string) {
        return await fetch(src, { method: 'GET', mode: 'cors', cache: 'no-cache', referrerPolicy: "strict-origin-when-cross-origin" });
    }

    const convertImagesToBase64 = async (element:HTMLElement) => {
        try{
            const images = element.querySelectorAll('img');

            for (let index = 0; index < images.length; index++) {

                const img = images[index];

                const src = img.getAttribute('src');
            
                if (src && !src.startsWith('data:')) {
                    
                    let response = await GetImageFromStorage(src)
                    
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        img.setAttribute('src', base64data);
                    
                    };
                    
                    let blob = await response.blob()

                    reader.readAsDataURL(blob);
                }
            }
        }catch (error) {
            throw error
        }
      };

    const printPdf = () => {
        const link = document.createElement('a');
        link.href = pdfUrl!;
        link.setAttribute('download', 'Certificado.pdf');
    
        // Adiciona o link temporário ao DOM e clica nele para iniciar o download
        document.body.appendChild(link);
        link.click();
    
        // Remove o link temporário do DOM
        document.body.removeChild(link);
    }

    if(isLoading){
        return (        
            <div>
                <PageLoader
                />
            </div>        
        )
    }

    if(pdfUrl===null){
        return(
            <div style={{height:'100vh', alignContent:'center'}}>

                <Container style={{marginBottom:'10vh'}}>
                    <Row>
                        <Col xs={'auto'} className="mx-auto">
                            <Col>
                                <i className='bi bi-exclamation-triangle' style={{fontSize:150, color:'red'}} />
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={'auto'} className="mx-auto">
                            <h1>
                                Certificado Inválido
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={'auto'} className="mx-auto">
                            <h4>
                                Não foi localizado um certificado válido para as informações fornecidas.
                            </h4>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    return (
        <>
            {
                (
                    <CertificateContainer className="px-0 mx-0" style={{maxWidth:'99.3vw'}}>
                        <CertificateArea>
                        {pdfUrl!==null && (
                                <embed
                                    src={pdfUrl.concat("#view=FitV&toolbar=0")}
                                    type="application/pdf"
                                    style={{width: '100%', minHeight: '80vh',  objectFit: 'fill'}}
                                />

                        )}  
                        </CertificateArea>
                        <Row className="download">
                            <Col md="auto" className="ml-auto my-auto">
                                <Button onClick={()=>{printPdf()}}>
                                    <i className="bi bi-download"/>
                                </Button>
                            </Col>
                        </Row>
                        
                    </CertificateContainer>
                )
            }   
            {
                certificateInfo?.holderName.toUpperCase() === "BIG BRAIN" &&<BigBrainFooter/>
            }
            {
                certificateInfo?.holderName.toUpperCase() === "REEDUCATION" &&<ReeducationFooter/>
            }
        </>
    );
};


export default ValidateCertificate;