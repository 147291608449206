
enum ModuleContentTypeEnum {
    Undefined = 0,
    Lesson = 1,
    Task = 2,
    Assessment = 3,
    Certificate = 10

}

export default ModuleContentTypeEnum
