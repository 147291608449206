import { Col, Container, Row } from 'react-bootstrap';
import { AddingModuleHeader } from '../../../../../components/Headers/AddingModuleHeader';
import { TertiaryYellowButton } from '../../../../../components/Buttons/styles';
import "react-toggle/style.css"
import { forwardRef, useContext, useImperativeHandle } from 'react';
import { AddModuleContext } from '../../../../../context/creator/createCourse/AddModuleContext';
import { AddContentButton, AddingModulePageStyles, ModuleDetailButton, ReorderModuleButton } from './AddingModulePageStyles';
import { CreateCourseContext } from '../../../../../context/creator/createCourse/CreateCourseContext';
import CourseStepEnum from '../../../../../domain/enum/CourseStepEnum';
import CreatorServices from '../../../../../services/CreatorServices';
import { ICreateCourseContentPayload } from '../../../../../domain/payloads/createCourse/ICreateCourseContentPayload';
import { ModuleInfoForm } from './ModuleInfoForm';
import { ModuleContentForm } from './addModuleContent/ModuleContentForm';
import { ConfirmDeleteModuleModal } from '../../../../../components/Modals/ConfirmDeleteModuleModal';
import { ConfirmDeleteModuleContentModal } from '../../../../../components/Modals/ConfirmDeleteModuleContentModal';
import { ConfirmQuestionModal } from '../../../../../components/Modals/ConfirmQuestionModal';
import { OrderingContentModal } from '../../../../../components/Modals/OrderingContentModal';
import { useCreateLessonModuleContentContext } from '../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

const creatorService = new CreatorServices();

export const AddingModulePage = forwardRef((props, ref) => {

    const { 
        course, 
        isEdit,
        openModuleIndex, 
        isChanged,
        setIsLoading, 
        setNavigationStep, 
        setIsChanged, 
        setOpenModulePage, 
        setUpdateDate, 
        setDataIsLoaded,
        reRender
    } = useContext(CreateCourseContext);

    const {
        module,
        validateModule,
        selectedModuleContentIndex,
        addNewModuleContent,
        changeSelectedModuleContextIndex,
        validateModuleInfo,
        validateModuleContent,
        handleOnDeleteModuleContent,
        setOpenModalOrderContent,
        handleLessonContentFocusLose,
    } = useContext(AddModuleContext)

    const {
        
    } = useCreateLessonModuleContentContext()

    useImperativeHandle(ref, () => ({
        submit(isBacking:boolean, saveAsDraft: boolean, stepRender?:number) {            
            onSubmit(isBacking, saveAsDraft, stepRender);
        }
    }));  
    
    const onSubmit =  (isBacking:boolean, saveAsDraft: boolean, stepRender?:number) => {
        if(isChanged)
        {
            setIsLoading(true)

            let data: ICreateCourseContentPayload = {
                courseId: course.id,
                isEditMode: isEdit||course.courseStepId >= CourseStepEnum.Content,
                modules:course.modules
            }
            
            creatorService.createCourseContent(data).then(function(response) {

                if(response.success) {
                    updateCourseObj(data, response.result, isBacking);                

                    if(!saveAsDraft)
                    {
                        setNavigationStep(stepRender ? stepRender :isBacking ?  CourseStepEnum.InitialData : CourseStepEnum.Content);    
                    }    
                }
            }).catch(err => {
                setIsLoading(false);
            });
        }
        else
        {            
            if(isBacking){
                setOpenModulePage(false);   
            }         
        }
    };

    const updateCourseObj = (data: any, response: any, isBacking:boolean) => {
        
        course.courseStepId = response.courseStepId;
        course.modules = response.modules.sort((a, b) => a.order - b.order)
        module.id = course.modules[openModuleIndex].id
        module.courseId = course.modules[openModuleIndex].courseId
        module.moduleContents = course.modules[openModuleIndex].moduleContents


        setUpdateDate(response.updateDate);  

        setIsLoading(false) 
        setIsChanged(false);
        setDataIsLoaded(true)

        if(isBacking){

            setOpenModulePage(false); 

        }
        reRender()
    }

    return (
        <>
            <OrderingContentModal />
            <ConfirmDeleteModuleModal/>
            <ConfirmDeleteModuleContentModal/>
            <AddingModuleHeader />
            <AddingModulePageStyles fluid={true}>
                <Row className="content" >
                    <Col md={3}  className="h-100 d-flex flex-column px-4" onClick={()=> handleLessonContentFocusLose()}>
                        <Row className="content-menu pt-4">
                            <Col>
                                <Row>
                                    <Col>
                                        <p>
                                        Preencha os detalhes do módulo, e depois selecione “Adicionar conteúdo” para começar a inserir seus conteúdos.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col >
                                        <ModuleDetailButton 
                                            className="w-100 py-2" 
                                            onClick={()=>changeSelectedModuleContextIndex(-1)}
                                            disabled={!(validateModuleInfo()&&(validateModuleContent(module.moduleContents[selectedModuleContentIndex])||selectedModuleContentIndex===-1))}>
                                            <Container>
                                                <Row>
                                                    <Col className="text-left pl-0 my-auto">
                                                        <p>
                                                            Detalhes do módulo
                                                        </p>
                                                    </Col>
                                                    <Col md="auto" className='pr-0'>
                                                        <i className='bi bi-chevron-right'/>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </ModuleDetailButton>
                                    </Col>
                                    
                                </Row>
                                {
                                    module.moduleContents.length > 0 ?
                                    (
                                    
                                        module.moduleContents.sort((a, b) => a.order -b.order ).map((thisModuleContent, index) => {
                                            return(
                                                <Row className='mt-3 module-content-button'>
                                                    <Col>
                                                        <ModuleDetailButton type='button' className="w-100 pr-2" onClick={()=>changeSelectedModuleContextIndex(index)} disabled={!(validateModule())&&selectedModuleContentIndex !== index}>
                                                            <Container>
                                                                <Row>
                                                                    <Col md={10} className="text-left pl-0 my-auto">
                                                                        <p>
                                                                            {thisModuleContent.title === "" ? "Novo conteúdo" : thisModuleContent.title}
                                                                        </p>
                                                                    </Col>
                                                                    {
                                                                        thisModuleContent.order === selectedModuleContentIndex?
                                                                        (
                                                                            <></>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Col md="auto" className={'pr-0 mr-0'}>
                                                                                <i className='bi bi-chevron-right'/>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                </Row>
                                                            </Container>
                                                        </ModuleDetailButton>
                                                        {
                                                            thisModuleContent.order === selectedModuleContentIndex?
                                                            (
                                                                <TertiaryYellowButton className="ml-auto delete-lesson py-1 px-2" onClick={()=>handleOnDeleteModuleContent()}>
                                                                    <i className='bi bi-trash'/>
                                                                </TertiaryYellowButton>
                                                            )
                                                            :
                                                            (
                                                                <></>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    
                                    )
                                    :
                                    (
                                        <></>
                                    )
                                }
                            </Col>
                        </Row>

                        <Row className="flex-grow-1">
                            <Col className="mt-auto mb-4 ml-auto">
                                <AddContentButton
                                        className="float-right"
                                        onClick={() => addNewModuleContent()}
                                        disabled={!((validateModuleInfo()&&module.moduleContents.length===0)||validateModule())}
                                        type="button"
                                        >
                                    <Row>
                                        <Col md={2} className="my-auto">
                                            <i className="bi bi-plus-circle" />
                                        </Col>
                                        <Col md={10}  className="my-auto">
                                            <p>Adicionar conteúdo</p>
                                        </Col>
                                    </Row>
                                </AddContentButton>

                            </Col>
                            <Col md="auto" className="mt-auto mb-4">
                                <ReorderModuleButton title="Reordenar conteúdos"
                                    onClick={() => setOpenModalOrderContent(true)}>
                                    <Container className='px-2'>
                                        <Row>
                                            <Col>
                                                <i className='bi bi-arrow-down-up'/>
                                            </Col>
                                        </Row>
                                    </Container>
                                </ReorderModuleButton>
                            </Col>
                        </Row>
                    </Col>
                    { 
                        selectedModuleContentIndex >=0?
                        (
                            <ModuleContentForm/>
                        )
                        :
                        (
                            <ModuleInfoForm/>
                        )
                    }
                </Row>
            </AddingModulePageStyles>
        </>
    );
})
