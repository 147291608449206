import styled from 'styled-components';

import { 
    Col, 
    Image, 
    Button,
    Card
} from 'react-bootstrap';
export const CourseInfoComponent = styled(Col)`

    iframe{
        width: 100%;
        min-height: 64vmin;
        height: 100%;
        border:none;        
    }

    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.7vmin;
        line-height: 3.3vmin;
        color: #6468C8;
    }  
    
    @media screen and (max-width:990px){
        h1 {
            font-size: 6.8vmin;
            line-height: 8vmin;
        }
    }
`
export const CourseDescription = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    color: #636060;

    @media screen and (max-width:990px){
        font-size: 5.3vmin;
        line-height:8vmin;
    }
`

export const CourseIntroductionHeader = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vmin;
    line-height: 3.3vmin;
    color: #514F4E;

    @media screen and (max-width:990px){
        font-size: 5.5vmin;
        line-height:8vmin;
    }
`

export const CourseIntroductionDescription = styled.div` 
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;
    }
    blockquote{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 1.95vmin;
            line-height: 2.78vmin;
            padding: 0 0.7vmin 0 2.8vmin;
            color:#999;
        }

        border: solid #b1b1b1;
        border-width: 0 0 0 0.7vmin;
    }
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.88vmin;
        line-height: 4.44vmin;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.33vmin;
        line-height: 3.88vmin;
    }
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
    }
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 2.5vmin;
    }
    h6{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 1.95vmin;
        line-height: 2.5vmin;
    }
    li{
        color:#514F4E;
        font-size:2.22vmin!important;
        line-height:3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    ul{
        color:#514F4E;
        font-size:2.22vmin!important;
        line-height:3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    @media screen and (max-width:990px){
        p {
            font-size: 5vmin;
            line-height:8vmin;
        }
    }
`

export const CourseContentHeader = styled.h1`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vmin;
    line-height: 3.3vmin;
    color: #514F4E;
`
export const CourseContentDescription = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    color: #636060;

    @media screen and (max-width:990px){
        font-size: 5.5vmin;
        line-height:8vmin;     
    }    
`

export const MenuBottom = styled.div`
    @media screen and (max-width: 990px){
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.2vmin;
        line-height: 3.3vmin;
        color: #636060;  
        background-color: #fff;
        width: 100%;
        height:40px;
        border-bottom: solid 1px #ddd;
        position: fixed; 
        top: 57px;
        z-index: 8;
        left: 0;
        right: 0;
        padding-left: 15px;
        margin-top:auto;


    ul {
        padding: 15px 0px 12px 0px !important;
        margin: 0 !important;

        li {
            font-size: 4.3vmin;
            display: inline-block;
            margin-right: 20px;
        }

        a {
            color: #6c757d;
            text-decoration: none;

            &:hover {
                color: #6c757d;
            }
        }
    }
    }
`
export const HoldCourseInfo = styled(Col)`
    display: contents !important;

    @media screen and (max-width: 990px){
        display: initial !important;
        padding: 0;
    }   
`

export const Intro = styled(Col)`
    @media screen and (max-width: 990px){
        height: 30px !important;
        position: relative !important;
        top: -23px;
        opacity: 0;
        z-index: -1;
    }   
`

export const IntroModules = styled(Col)`
    @media screen and (max-width: 990px){
        height: 30px !important;
        position: relative !important;
        top: -32px;
        opacity: 0; 
        z-index: -1;
    }   
`

export const IntroCertificate = styled(Col)`
    @media screen and (max-width: 990px){
        height: 30px !important;
        position: relative !important;
        top: -35px;
        z-index: -1;
    }   
`

export const CourseImage = styled(Image)`   
    object-fit:cover;
    width: 63vmin;
    height:30vmin;    

    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, black 1, black);    
`

export const CourseVideoIntro = styled.div`
    color: #636060;
`
export const CourseIntroductionImage = styled.img`
    height:100%;
    max-height:60vh;
    max-width: 60vw;
    height: auto;

`

export const StartCourseButton = styled(Button)`

    background-color: #6468C8;   
    box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    border-radius: 1.11vmin;
    border:none!important;
    width: 100%;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;    

    i {
        font-size: 4.05vmin;
        color: #FFFFFF;
    }
    :active{
        background-color: #6468C8!important;
        border: 0.14vmin solid #6468C8!important;;

    }
    :focus{
        background-color: #6468C8!important;
        box-shadow: 0px 0.14vmin 0.28vmin rgba(0, 0, 0, 0.2);
    }

    &:hover {
        background-color:#3E408E!important;
        color: #fff !important;    
    }    

    
    @media screen and (max-width:990px){       
        font-size: 5vmin;
        font-weight: 400;
        line-height: 7vmin;

        .hold-text-main {            
            position: relative;
            top: -1px !important;
            align-items: center;   

            span {
                position: relative;
                top: -2px;
            }
        }

        i {
            position: relative;
            font-size: 8.5vmin;
            margin-right: 2px;
            top: 3px;
        }
    }
`

export const FavCourseButton = styled(Button)`
    border: 1px solid #0C8292;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    color: #0C8292 !important;
    width: 100%;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;
    
    i {
        font-size: 3.05vmin;
    }

    &:hover {
        background: #fff;
        color: #0C8292 !important;    
    }
`
export const CourseCertificateTag = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center; 
    padding: 12px; 
    gap: 8px;
    height: 48px;
    background: #F9F9F9;
    border: 1px solid #E0E1F4;
    border-radius: 4px;    
    font-family: Lato;
    font-style: normal;    
    font-size: 2.2vmin;    

    i {
        font-size: 3.05vmin;
        color: #6468C8;
    }

    @media screen and (max-width:990px){ 
        font-size: 4.7vmin; 
        max-width: 100%;
        line-height: 5.3vmin;

        i {
            font-size: 7vmin; 
        }
    }
`

export const CourseResponsibleHeader = styled.h1`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8vmin;
    line-height: 2.5vmin;
    color: #636060;

    @media screen and (max-width:990px){ 
        font-size: 5.2vmin;
    }
`
export const CourseResponsibleContent = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    color: #636060;

    @media screen and (max-width:990px){ 
        font-size: 5.2vmin; 
    }
`

export const CourseWorkLoadHeader = styled.h1`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8vmin;
    line-height: 2.5vmin;
    color: #636060;

    @media screen and (max-width:990px){ 
        font-size: 5.2vmin;
        margin-bottom: 10px;
    }
`
export const CourseWorkLoadContent = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    color: #636060;  

    @media screen and (max-width:990px){ 
        font-size: 5.2vmin; 
        padding-bottom: 10px;

        i {
            margin-right: 8px;
        }
    }    
`

export const CoursePublisedDateHeader = styled.h1`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8vmin;
    line-height: 2.5vmin;
    color: #636060;
    
    @media screen and (max-width:990px){ 
        font-size: 5.2vmin; 
        margin-top: 20px;
    }
`
export const CoursePublisedDateContent = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    color: #636060;

    @media screen and (max-width:990px){ 
        font-size: 5.2vmin; 
        line-height: 11vmin;
        /* margin-left: 19px; */

        i {
            margin-right: 8px;
        }
    }
`

export const ModuleCardContainer  = styled.li`
    list-style:none !important;  
    text-decoration:none;

    .mxText {
        max-width: 262px;
    }

    @media screen and (max-width:990px){ 
        margin: 0 !important;
        padding: 0 !important;

        .mxText {
            max-width: auto;
        }
    }
`

export const ModuleCardItem = styled(Card)`
    background: #FFFFFF;
    border: 1px solid #F9F9F9;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;    
    
    .card-header {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: none;
        border-top-left-radius: 15px!important;
        border-top-right-radius: 15px!important;
    }

    .card-footer {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-top: none;
        border-bottom-left-radius: 15px!important;
        border-bottom-right-radius: 15px!important;
    }

    .card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: none;        
    }


    .card-body > ul {
        list-style:none !important;  
        text-decoration:none;
        padding-left: 0px !important;  
    } 
    
    @media screen and (max-width: 990px){
        margin-bottom: 10px !important;
    }
`

export const ModuleCardItemTitle = styled.h4`
    color:#514F4E;    

    font-family: lato, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2vmin;
    line-height: 3.3vmin;
    margin-bottom:0;

    i {
        font-size: 3.05vmin;
        color: #6468C8;
        font-size: 4vmin;
        margin-left: 10px;
    }

    @media screen and (max-width:990px){ 
        font-size: 4.5vmin; 
        line-height: 6vmin;
        min-width: 128px !important;

        i {
            position: relative;
            top: 2px;
            font-size: 5.2vmin;
            margin-left: 0px;
        }
    }
`

export const ModuleLessonTitle = styled.h4`
    color:#514F4E;    

    font-family: lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vmin;
    line-height: 3.3vmin;

    @media screen and (max-width:990px){ 
        font-size: 4.5vmin;
        line-height: 7vmin;
    }
`
export const ModuleLessonIcon = styled.span`
    color:#514F4E;    
    font-size: 3.5vmin;
    line-height: 3.3vmin;

    @media screen and (max-width:990px){ 
        i {
            font-size: 6vmin;
        }
    }
`

export const ChevronButton = styled.img`
    transform: rotate(${props => props.$rotate});
    @media screen and (max-width:990px){ 
        width: 22px !important;
        position: relative;
        top: 0px;
    }
    
`