import styled from 'styled-components';
import img from '../../../assets/icons/grid-dots.svg'
import { Card, Button, Modal, Container, Form, Col, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { PrimaryPurpleButton, SecondaryGreenButton } from '../../../components/Buttons/styles';

export const CreateTrilhaQuizComponent = styled(Col)`
    .react-toggle.react-toggle--checked .react-toggle-track{
        background-color:#6468C8!important;
        length:100px!important;
        border:none!important;
    }

    .react-toggle .react-toggle-track{
        background-color:#FFFFFF!important;
        border:1px;
    }
    .react-toggle-track{
        border-radius:20px;
        width:71px;
        height:40px!important;
        border:2px solid #8D8D8D!important;
    }
    .react-toggle-thumb{
        background-color:#8D8D8D;
        border:none;
        width:30px;
        height:30px!important;
        top:5px;
        left:5px; 
        &:target{
            box-shadow:none!important;
        }
    }
    .react-toggle--checked .react-toggle-thumb{
        background-color:#FFFFFF!important;
        left:35px;
    }
    .react-toggle--focus .react-toggle-thumb{
        box-shadow:none;
    }
`

export const DepModulesList = styled.ul`
    list-style:none;
    padding-left:0px!important;

    h4 {
        text-align: left;
        letter-spacing: 0px;
        color: #5457c2!important;
        opacity: 1;
        margin-bottom: 20px;
        font-size:18px!important;
        line-height:20px;
        font-family: lato, sans-serif;
        font-weight: normal;
        font-style: normal;    

    }
    p {
        text-align: left;
        letter-spacing: 0px;
        color: #4a4a4b!important;
        opacity: 1;
        margin-bottom: 0px;
        font-size:14px!important;
        line-height:20px;
        font-family: lato, sans-serif;
        font-weight: normal;
        font-style: normal;  

    }

`

//#region Base

export const TitleBar = styled.h4`

`

export const ExitLink = styled(Row)`    
    border: 1px solid #0C8292;
    border-radius: 8px;
    opacity: 1;

    h4 {
        font-family: 'Lato', sans-serif;
        font-weight: 400!important;
        font-style: normal;
        font-size: 2.5vmin!important;
        color: #0C8292;        
        line-height:1.3rem;
        letter-spacing: 0px;
        padding: 1.4vmin;     
        margin: 0px !important;
    }

    &:hover {
        font-weight: 700!important;
    }
`

export const PageTitle =  styled.h1`
    cursor:default;
    text-align: left;
    font-size:1.45rem!important;
    line-height:3rem;
    letter-spacing: 0px;
    color: #6468C8;
    opacity: 1;
    font-family: lato, sans-serif;
    font-weight: 700;
    font-style: normal;
`
export const QuizPageTitle =  styled.h1`
    cursor:default;
    text-align: left;
    font-size:1.35rem!important;
    line-height:3rem;
    letter-spacing: 0px;
    color: #514F4E;
    opacity: 1;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
`

export const ParagraphSilver= styled.p`
    cursor:default;
    text-align: left;
    opacity: 1;
    color: #888888;
    font-size:1rem!important;
    line-height:1.3rem;
    letter-spacing: 0px;
    font-family: lato, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin:0px
`


export const StepDescription= styled.p`
    cursor:default;
    text-align: left;
    opacity: 1;
    color: #514F4E;
    font-size:1rem!important;
    line-height:1.3rem;
    letter-spacing: 0px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin:0px
`
export const RequiredFormMessage= styled.p`
    color: #bf1650;
`

export const CreateBar = styled(Row)`
    @media(min-height: 900px) {
        min-height: 7vh;         
    }
    @media(max-height: 900px) {
        min-height: 8vh;         
    }
    @media(max-height: 800px) {
        min-height: 9vh;         
    }  
    @media(max-height: 700px) {
        min-height: 10vh;         
    }       
    background: #F9F9F9  0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #514F4E33;
    opacity: 1;
`

export const CreateTrilhaForm = styled(Form)`
    input[type="text"], textarea
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #CBCACA !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }

    input[type="text"]:focus, textarea:focus
    {
        box-shadow: 0px 0px 4px var(--unnamed-color-6468c8) !important;
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 4px #6468C8 !important;
        border: 1px solid #6468C8 !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }
    .form-label
    {
        text-align: left;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .form-label
    {
        color: #514F4E!important;
    }
    .form-label.disabled
    {
        color: #8d8d8d87!important;
    }

    input[type=checkbox] {
        position: relative;
        cursor: pointer;
        width: 30px;
        height: 30px;    
        -webkit-appearance: inherit;
        background-color: #fafafa;
        border: 1px solid #cacece;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        border-radius: 3px;
        display: inline-block;
        position: relative;
    }
    
    input[type=checkbox]:active, input[type=checkbox]:checked:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
    
    input[type=checkbox]:checked {
        background-color: #6468C8;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
        
    }
    input[type=checkbox]:checked:after {
        content: '\\2713';
        font-size: 25px;
        position: absolute;
        top: -4px;
        left: 5px;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
    }
    
    
    input[type=checkbox]:before {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background-color:#e9e9e9;
    }

    
    textarea:focus { 
        outline: none !important;
        border-color: #6468C8 !important;
        box-shadow: 0px 0px 4px #6468C8 !important;
    }
    
`
//#endregion Base

//#region Selects
export const StyledSelect= styled(Select)`
    z-index: 999;

    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }

    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }

    .select__control
    {
        border-color: #b3b5df!important;
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border-radius:8px;
    }
    
    .select__control--is-focused 
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }

    .select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size:1rem!important;
        line-height:1.2rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const StyledSearchableSelect= styled(Select)`

    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }

    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }

    .react-select__control
    {
        border-color: #b3b5df!important;
        border-radius:8px;
    }

    .react-select__control--is-focused 
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }

    .react-select__multi-value {
        background: #f5f7ff 0% 0% no-repeat padding-box!important;
        border: 1px solid #6468C8;
        border-radius: 12px!important;
        opacity: 1;
        color: #6468C8;
        width: auto;
    }

    .react-select__multi-value__remove {    
        border-radius: 50%!important;    
        background: #6468C8;
        text-align: center;    
        color: #FFF;
        margin: 2px;    
    }

    .react-select__multi-value__remove:hover {
        background: transparent!important;
        color: #5a5ed6!important;
        cursor: pointer;
    }

    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size:1rem!important;
        line-height:1.2rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const StyledCreatableSelect= styled(CreatableSelect)`
    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }
    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }
    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }
    .react-select__control
    {
        border-color: #b3b5df!important;
        border-radius:8px;
    }
    .react-select__placeholder{
        font-size:1rem;
        line-height:1.2!important;
        font-family: 'Lato', sans-serif;
        font-weight: 400!important;
        letter-spacing: 0px;
    }
    .react-select__control--is-disabled{
        background: #fbfbff99 0% 0% no-repeat padding-box!important;
        border: 1px solid #8d8d8d69!important;
        .react-select__placeholder{
            color:#8d8d8d69!important;
        }
    }
    .react-select__control--is-focused 
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }
    .react-select__multi-value {
        background: #f5f7ff 0% 0% no-repeat padding-box!important;
        border: 1px solid #6468C8;
        border-radius: 12px!important;
        opacity: 1;
        color: #6468C8;
        width: auto;
    }
    .react-select__multi-value__remove {    
        border-radius: 50%!important;    
        background: #6468C8;
        text-align: center;    
        color: #FFF;
        margin: 2px;    
    }
    .react-select__multi-value__remove:hover {
        background: transparent!important;
        color: #5a5ed6!important;
        cursor: pointer;
    }
    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size:1rem!important;
        line-height:1.2rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`
//#endregion Selects

//#region Choose

export const ChooseSection = styled(Col)`
    display: flex;
    justify-content: center;    
   
    h5 {    
        color:#514F4E;
        text-decoration: none!important; 
        font-size:1.3rem!important;
        line-height:1.3rem;
        letter-spacing: 0px;
        font-family: 'Lato', sans-serif;
        font-weight: 700!important;
        font-style: normal;
        color: #514F4E;
    }    
    
    p {
        font-size:1rem!important;
        line-height:1.2rem;
        letter-spacing: 0px;
        font-family: 'Lato', sans-serif;
        font-weight: 400!important;
        font-style: normal;
        color: #8D8D8D;
    }  

    img{
        min-width:90px!important;
        min-height:90px!important;
        &:hover{
            box-shadow: none!important;
            border-radius: none!important;
        }
    }

    .card {
        text-align: center;  
        min-width: 300px;
        max-width: 15vw;
        min-height: 275px;
        max-height: 31vw;
        background-color: #FFFFFF 0% 0% no-repeat padding-box;    
        box-shadow: 0px 3px 6px #00000033!important;
        border-radius: 10px!important;
        opacity: 1!important;
        border: none!important;
        border-radius: 15px!important;
    
        
        &:hover {       
            box-shadow: 0px 5px 8px #91908c!important;
            border-radius: 15px!important;
        }
    }  
`

//#endregion Choose

export const CreateTrilhaCustomCard = styled(Card)`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CBCACA;
    border-radius: 15px;
    box-shadow: none;
    .card-header{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: none;
        border-top-left-radius: 15px!important;
        border-top-right-radius: 15px!important;
    }
    .card-header.minimized{
        border-bottom-left-radius: 15px!important;
        border-bottom-right-radius: 15px!important;
    }
    .card-footer{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-top: none;
        border-bottom-left-radius: 15px!important;
        border-bottom-right-radius: 15px!important;
    }
    .card{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #CBCACA;
        border-radius: 15px;
        box-shadow: none;

    }
    .form-label
    {
        text-align: left;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
`
export const ModuleLessonTitle = styled.h4`
    color:#514F4E;
    font-size:1.2rem!important;
    line-height:2.2rem;
    letter-spacing: 0px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
`

export const PurpleButtonText = styled.h5`
    color:#6468C8;
    font-size:1.2rem!important;
    line-height:1.3rem;
    letter-spacing: 0px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
`

export const AddAlternativeButton = styled(SecondaryGreenButton)`
    
    height:6.5vmin;

    p{
        max-width:100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
    }
`

export const AddNewQuestionButton = styled(PrimaryPurpleButton)`
    
   width:100%;

    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
    }

`

export const LessonsText = styled.p`
    color:#514F4E;
    font-size:1.1rem!important;
    line-height:1.2rem;
    letter-spacing: 0px;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-style: normal;
`

//#region listas
export const GridDottedList= styled.ul`
    list-style:none;
    padding-left:0px!important;

    ul > li{
        list-style: none;
    }
`

export const GridDottedCard= styled.ul`
    /* list-style-image: url(${img}); */
    list-style: none;    
    
`

export const GridDottedCardNoPadding= styled.ul`
    /* list-style-image: url(${img}); */
    list-style: none;
    padding-left: 0px;

    input[type="text"], textarea {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #CBCACA !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }

    input[type="text"]:focus, textarea:focus {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 4px #6468C8 !important;
        border: 1px solid #6468C8 !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }

    ul{
        list-style: none;
    }  
`

export const GridDottedItemList= styled.li`
    /*
        background: url(${img}) no-repeat left center; 
        padding-left: 25px;
    */
    vertical-align: middle; 
    list-style: none !important;
    margin: 0;

    input[type="radio"]{
            appearance: none;
            width:4.44vmin;
            height:4.44vmin;
            border: 0.28vmin solid #777575;
            border-radius:50%;
            padding:0.42vmin;
            background-clip: content-box;
        }
        input[type="radio"]:checked{
            background-color: #EA694C;
            border: 0.28vmin solid #EA694C;
        }
`
//#endregion listas

//#region Text

export const QuizToggleLabel=  styled.p`
    cursor:default;
    text-align: left;
    font-family: lato, sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.52rem;
    padding-bottom:5px;
    letter-spacing: 0px;
    color: #514F4E;
    opacity: 1;
`

export const CardComponentTitle =  styled.h3`
    cursor:default;
    text-align: left;
    font-size:1.15rem;
    line-height:2.3rem;
    letter-spacing: 0px;
    color: #514F4E;
    opacity: 1;
    font-family: lato, sans-serif;
    font-weight: 500;
    font-style: normal;
`
export const CardComponentSubTitle =  styled.h4`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 2.22vmin;
    line-height: 3.33vmin;
    color: #636060;
`

export const CardComponentDescription =  styled.h5`
    cursor:default;
    font-size:1rem;
    line-height:1.5rem;
    font-family: Lato;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    color: #646464;
    opacity: 1;
`
//#endregion Text

//#region Input

export const CreateQuizEnunciado =  styled.textarea`
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 2.22vmin;
    letter-spacing: 0px;   

    border: 1px solid #CBCACA!important;
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    resize: none;    
    padding-left:10px;
    
    :focus-visible{
        outline:none;
    }
`

export const CreateQuizTextInput =  styled.input`
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 2.22vmin;
    letter-spacing: 0px;

    border: 1px solid #CBCACA!important;
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    height:44px;
    padding-left:10px;

    :focus-visible{
        outline:none;
    }
`

export const CreateQuizMaskedTextInput =  styled(InputMask)`
    background: #FBFBFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #CBCACA !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    text-align:right;
    max-width: 100px;
    height:44px;

    :focus{
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 4px #6468C8 !important;
        border: 1px solid #6468C8 !important;
        border-radius: 8px !important;
    }
    :focus-visible{
        outline:none;
    }
`

export const CreateQuizRadioInput =  styled.input`
    width: 4.44vmin;
    height: 4.44vmin;
`
export const CreateQuizInputLabel = styled.label`
    text-align: left;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 2.22vmin;
    line-height: 6vmin !important;
    letter-spacing: 0px;
    color: #514F4E;
    opacity: 1;
`

//#endregion Input

//#region Button

export const CreateQuestionMinimizeButton = styled.img`
    transform: rotate(${props => props.$rotate});
`

export const CreateQuestionButton = styled(Button)`
    border: 1px solid;
    border-color:#6468C8!important;
    background-color:#FFF!important;
    border-radius: 8px;
    opacity: 1;
    //box-shadow:none!important;
    :disabled{
        background-color:#FFF!important;
        border-color:#6468C8!important;
        font-weight:400!important;
    }
    &:active{
        box-shadow: 0 0 0 0.2rem #6468c84d!important;
    }
    &:focus{
        box-shadow: 0 0 0 0.2rem #6468c84d!important;
    }
`

//#endregion Button
export const CreateTrilhasCancelAddLesson = styled(Card)`
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    z-index: 5;
    bottom: 190px;
    min-width: 400px!important;
    left: 50%;
    transform: translate(-50%, 0);
    .card-footer{
        background: #FFFFFF 0% 0% no-repeat padding-box;

    }
    .card-title{
        color:#514F4E;
        font-size:1.8rem!important;
        line-height:2rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .card-text{
        color:#514F4E;
        font-size:1.1rem!important;
        line-height:1.5rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`

export const AddLearningHintModal = styled(Modal)`
    .modal-content{

        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #DBD8D8;
        border-radius: 10px;
    }
    input[type="text"], textarea {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #CBCACA !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }

    input[type="text"]:focus, textarea:focus {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 4px #6468C8 !important;
        border: 1px solid #6468C8 !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    }
    h5{
        cursor:default;
        color:#514F4E;
        font-size:1rem!important;
        line-height:1.3rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size:1.5rem!important;
        line-height:3rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .modal-footer{
        border-top:none
    }
    .modal-header{
        border-bottom:none!important;
    }
    .modal-header button span{
        font-size: 40px;
    }
    .modal-header button.close{
        padding-top:10px!important;
        padding-right:24px!important;
    }
    h5{
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
`

export const PublishTrilhaModal = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;
    .modal-content{
        padding:15px;
        border-radius: 12px!important;
    }
    .modal-header{
        border-bottom:none;
    }
    .modal-body{
        padding-top:0px!important;
    }
    .close{
        font-size:3rem!important;
        font-weight:400!important;
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size:2.1rem!important;
        line-height:3rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    p{
        color:#514F4E;
        font-size:1.25rem!important;
        line-height:1.8rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
`

export const ConfirmExitCreateTrilha = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;
    .modal-content{
        border-radius: 12px!important;
    }
    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
    }
    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }
    .modal-body{
        padding-top:0px!important;
    }
    .close{
        font-size:3rem!important;
        font-weight:400!important;
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    p{
        color:#514F4E;
        font-size: 1.7vmin !important;
        line-height:1.8rem;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    } 
`

export const ModalOrangeSecondaryButton = styled(Button)`
    padding: 8px;
    color: #F27649!important;
    background-color: #FFF!important;
    border-color: #F27649!important;
    border-radius:8px; 
    font-size: 2vmin !important;
    font-weight: 600;
    font-style: normal;
`

export const ModalOrangePrimaryButton = styled(Button)`
    padding: 8px;
    color: #FFF!important;
    background-color: #F27649!important;
    border-color: #F27649!important;
    border-radius:8px;

    font-size: 2vmin !important;
    font-weight: 600;
    font-style: normal;

`

export const CreateTrilhasLessonModal = styled(Modal)`
.modal-content{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DBD8D8;
    border-radius: 10px;
    min-height: 90vh;
    padding: 20px;
}
.modal-header{
    border-bottom: none;
}
.modal-footer{
    border-top: none;
}
.card-footer{
    border-top: none;
}
h4{
    text-align: left;
    font: normal normal normal 20px/24px Lato;
    letter-spacing: 0px;
    color: #514F4E;
    opacity: 1;
}
iframe{
    border-radius: 15px;
    width: 30vw;
    height:15vw;
}

input[type="text"], textarea {
    background: #FBFBFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #CBCACA !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}
input[type="text"]{
    height: calc(1.5em + .75rem + 2px);
}

input[type="text"]:focus, textarea:focus {
    box-shadow: 0px 0px 4px var(--unnamed-color-6468c8) !important;
    background: #FBFBFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 4px #6468C8 !important;
    border: 1px solid #6468C8 !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

input[type="radio"]{
    width: 32px;
    height: 32px;
    color:#ADB5BD;
}
.form-check-label{
    background-color:#ccc
}
`

export const CreateTrilhasPrimaryModal = styled(Modal)`
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #DBD8D8;
    border-radius: 10px;
    opacity: 1;
    min-height: 90vh;
    .modal-footer{
        border-top:none
    }
    .modal-header button span{
        font-size: 40px;
    }
    .modal-header button.close{
        padding-top:10px!important;
        padding-right:24px!important;
    }
    h3{
        padding-top:3px;
        text-align: left;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
    h5{
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
`

export const ConfirmDeleteModal = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 16px;
    opacity: 1;
    .modal-footer{
        border-top:none
    }
    h4{
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
    p{
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }
    .modal-header button span{
        font-size: 40px;
    }
    .modal-body{
        padding-top:0px;
        padding-bottom:0px;
    }

`

export const CreateTrilhaContainer = styled(Container)`
    background-color:#FFF!important;
    .react-toggle.react-toggle--checked .react-toggle-track{
        background-color:#6468C8!important;
        length:100px!important;
        border:none!important;
    }

    .react-toggle .react-toggle-track{
        background-color:#FFFFFF!important;
        border:1px;
    }
    .react-toggle-track{
        border-radius:20px;
        width:71px;
        height:40px!important;
        border:2px solid #8D8D8D!important;
    }
    .react-toggle-thumb{
        background-color:#8D8D8D;
        border:none;
        width:30px;
        height:30px!important;
        top:5px;
        left:5px; 
        &:target{
            box-shadow:none!important;
        }
    }
    .react-toggle--checked .react-toggle-thumb{
        background-color:#FFFFFF!important;
        left:35px;
    }
    .react-toggle--focus .react-toggle-thumb{
        box-shadow:none;
    }
`

export const CreateTrilhaPageRow = styled(Row)`
    min-height:80vh!important
`

export const CreationStepBar = styled(Row)`
    .bg-trilha{
        background-color: #5457c2!important;
        border-radius: 8px;
    }
`

export const ImageContainer = styled(Col)`
    cursor:pointer!important;
    width: 179px;
    height: 136px;  
    background: #FBFBFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: 1px solid #CBCACA;
    opacity: 1;
    p {
        text-align: left;
        font: normal normal normal 15px/20px Lato;
        letter-spacing: 0px;
        color: #888888;
        opacity: 1;
    }
`

export const StyledFileViewer = styled.iframe`
    border-radius: 15px;
    width: 500px !important;
    height: 600px !important;
`