import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { ReeducationLogo, StudentFooterCopyRights, StudentFooterLogo, StudentFooterStyles } from "./styles";

const ReeducationFooter = (): JSX.Element => (
    <StudentFooterStyles>
        <Container fluid={true}>
            <Row>
                <Col md={"auto"}>
                    <ReeducationLogo alt="" src={"https://storagebrainzserviceprod.blob.core.windows.net/files-trilhas-prod/logo_reedu.svg"} />
                </Col>
                {/* <Col md={"auto"} className="my-auto">
            <StudentFooterButtons>
                    Termos de Uso
            </StudentFooterButtons>
        </Col>
        <Col md={"auto"} className="my-auto">
            <StudentFooterButtons>
                    Política de Privacidade
            </StudentFooterButtons>
        </Col>
        <Col md={"auto"} className="my-auto">
            <StudentFooterButtons>
                    Portal Big Brain
            </StudentFooterButtons>
        </Col> */}
                <Col md={"auto"} className="my-auto ml-auto mr-2">
                    <StudentFooterCopyRights className="mt-5">
                        Reeducation - {new Date().getFullYear()} ⓒ
                    </StudentFooterCopyRights>
                </Col>
            </Row>
        </Container>
    </StudentFooterStyles>
);

export default ReeducationFooter
