import ApiInterface from "../interface/ApiInterface"

export class SubCategoryServices {
    
    public async get() {
        return await ApiInterface.get('/SubCategory/Get', {
        })
        .then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}