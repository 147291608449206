import { Button, Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

interface ConfirmModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  subTitle: string;
  confirmText: string;
  confirmAction: () => void;
  refuseText: string;
  refuseAction: () => void;
  principalIcon: React.ReactNode;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  if (props.show)
    return (
      <ModalGenericErrorStyles show={props.show} onHide={props.onHide}>
        <Modal.Body>
          <Row className="my-4">
            <Col>{props.principalIcon}</Col>
          </Row>
          <Row>
            <Col>
              <h2> {props.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{props.subTitle}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="success-button-outline"
            onClick={() => props.confirmAction()}
          >
            {props.confirmText}
          </Button>

          <Button onClick={() => props.refuseAction()}>
            {props.refuseText}
          </Button>
        </Modal.Footer>
      </ModalGenericErrorStyles>
    );
  else return <></>;
}

const ModalGenericErrorStyles = styled(Modal)`
  .modal-content {
    width: 600px;
    height: 420px;

    background: #fff;
    border: 2px solid #c57700;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .modal-body {
    margin: 10px;
    padding: 50px 0px 10px;
    text-align: center;
    align-self: center;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #514f4e;
  }
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #636060;

    padding: 0 1rem;
    max-height: 4rem;
    overflow: auto;
  }

  .modal-footer {
    border: none;
    justify-content: center;
    gap: 2rem;

    button {
      max-width: 229px;
      width: 100%;
      height: 48px;

      box-sizing: border-box;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:focus {
        border: none !important;
      }
    }

    .cancel-button {
      color: #a56300;
      background-color: #fff;
      border: 1px solid #a56300;

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        svg {
          fill: #fff !important;
        }
      }
    }

    .sucess-button,
    .success-button {
      background: #2155ba;
      border: 1px solid #2155ba;

      & svg {
        margin-left: 14px;
      }

      &:hover,
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        svg {
          fill: #fff !important;
        }
      }
    }

    .continue-button-outline {
      background-color: transparent !important;
      color: #2155ba !important;
      border: 1px solid #2155ba !important;

      &:hover {
        background-color: #2155ba !important;
        color: #fff !important;
      }
    }

    .success-button-outline {
      background-color: transparent;
      color: #2155ba;
      border: 1px solid #2155ba;

      &:hover {
        background-color: #2155ba;
        color: #fff;
      }
    }
  }
`;