import {useState, useEffect, forwardRef, useImperativeHandle, useContext, useRef, isValidElement } from 'react';
import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'
import CourseStepEnum from '../../../../domain/enum/CourseStepEnum';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ICreateCourseIntroductionPayload from '../../../../domain/payloads/createCourse/ICreateCourseIntroductionPayload';
import CreatorServices from '../../../../services/CreatorServices'
import { AddVideoButton } from '../CreateCourseStyles';
import { PrimaryGreenButton, SecondaryGreenButton, SecondaryYellowButton, TertiaryYellowButton } from '../../../../components/Buttons/styles';
import {AddVideoModal} from '../components/modals/AddIntroductionVideo'
import { ConfirmExitAddVideoModal } from "../components/modals/ConfirmExitAddVideoModal";
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { CreateCourseIntroductionFormContext } from '../../../../context/creator/createCourse/CreateCourseIntroductionFormContext';
import { AddImageModalComponent } from '../../../../components/Modals/AddImageModal/AddImageModal';
import { AddImageObject } from '../../../../components/Modals/AddImageModal/context/AddImageObject';

const creatorService = new CreatorServices();

export const CreateCourseIntroductionForm = forwardRef((props, ref) => {

    const { 
        course, 
        isEdit, 
        isChanged,
        setDataIsLoaded,
        setUpdateDate,
        setIsChanged,
        setIsValid, 
        setIsLoading,
        setNavigationStep,
        reRender
    } = useContext(CreateCourseContext);

    const { 
        setShowAddVideoModal,
    } = useContext(CreateCourseIntroductionFormContext);

    useImperativeHandle(ref, () => ({
        submit(isBacking:boolean, saveAsDraft: boolean, stepRender?:number) {            
            onSubmit(isBacking, saveAsDraft, stepRender);
        }
    }));       

    const [reload, callReload] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

    useEffect(
        ()=>{
            checkIfIsvalid()
            callReload(!reload)
        },[]
    )
    
    const checkIfIsvalid = () =>{

        let valid = (course.presentation !=="<p></p>"&&course.presentation !=="<p><br></p>"&&course.presentation?.length>0)||(course.introductionVideoUrl !== null&&course.introductionVideoUrl !== ""||(course.introductionImageUrl !== ""))
        setIsValid(valid)
    }

    const handleChangeTextEditor = (content: string) => {

        let flag = false;

        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = content;
        for (let index = 0; index < htmlObject.children.length; index++) {
            let div = htmlObject.children.item(index);
            if(div?.innerHTML !== "<br>"&&div?.innerHTML !== "&nbsp;"&&div?.innerHTML !=="&ZeroWidthSpace;"){
                flag = true
                break
            }  
        }
        if(flag){
            course.presentation = content;
        }else{
            course.presentation = "";
        }
        setIsChanged(true);
        checkIfIsvalid()
        callReload(!reload)
    }
    function handlePaste(e: ClipboardEvent, cleanData: string, maxCharCount) {

        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = cleanData;
        cleanData = "";

        var filteredHTML = RemoveAttrFromTag(htmlObject.children);

        for (let index = 0; index < filteredHTML.length; index++) {

            cleanData = cleanData.concat(filteredHTML[index].outerHTML);

        }
        //e.clipboardData?.setData
        //let test = e.clipboardData
        //console.log("content antes de inserção",cleanData)
        setIsChanged(true);

        return cleanData;

    }

    function RemoveAttrFromTag(content:HTMLCollection):HTMLCollection{
        for (let index = 0; index < content.length; index++) {
            content[index].removeAttribute('style')
            if(content[index].children.length > 0){
                RemoveAttrFromTag(content[index].children)
            }
        }
        return content
    }

    const clearIntroductionImage = () =>{
        course.introductionImageUrl= null
        setIsChanged(true)
        checkIfIsvalid()
        callReload(!reload)
    }


    const clearIntroductionVideo = () =>{
        course.introductionVideoName = null
        course.introductionVideoUrl= null
        setIsChanged(true)
        checkIfIsvalid()
        callReload(!reload)
    }
    const setIntroductionVideo = (url:string, name:string) =>{
        course.introductionVideoName = name
        course.introductionVideoUrl= url
        setIsChanged(true)
        checkIfIsvalid()
        callReload(!reload)
    }

    const setIntroductionImage= (imageObject:AddImageObject) =>{
        course.introductionImageUrl= imageObject.ImageUrl
        setIsChanged(true)
        checkIfIsvalid()
        setShowImageModal(false)
        callReload(!reload)
    }

    const onSubmit = (isBacking:boolean, saveAsDraft: boolean, stepRender?:number) => {
        
        if(isChanged)
        {
            var data: ICreateCourseIntroductionPayload =
            {
                courseId: course.id,
                isEditMode: isEdit||course.courseStepId >= CourseStepEnum.Introduction,                
                presentation: course.presentation,
                introductionVideoUrl: course.introductionVideoUrl,
                introductionVideoName: course.introductionVideoName,
                introductionImageUrl: course.introductionImageUrl
            }
            
            setIsLoading(true);
            creatorService.createCourseIntroduction(data).then(function(response) {
                if(response.success) {
                    updateCourseObj(data, response.result);                
                    setUpdateDate(response.result.updateDate);    
                    setIsChanged(false);
                    setIsLoading(false);

                    if(!saveAsDraft)
                    {
                        setNavigationStep(stepRender ? stepRender :isBacking ?  CourseStepEnum.InitialData : CourseStepEnum.Content);    
                    }    
                }
            }).catch(err => {
                setIsLoading(false);
            });
        }
        else
        {            
            setNavigationStep(isBacking ? CourseStepEnum.InitialData : CourseStepEnum.Content);            
        }
    };

    const updateCourseObj = (data: any, response: any) => {
        //once the data is saved, then update the fresh new valuw into the obj property 
        course.presentation = data.presentation;
        course.courseStepId = response.courseStepId;
        course.introductionVideoName = response.introductionVideoName
        course.introductionVideoUrl = response.introductionVideoUrl

        setDataIsLoaded(true)
    }
   
    return (
        <Form>
            <AddVideoModal
                setVideoLink={setIntroductionVideo}
            />
            {/* <button onClick={test} type='button'>test</button> */}
            <ConfirmExitAddVideoModal/>
            <Row>
                <Col md={10} className="mx-auto">
                    <Row>
                        <Col>
                            <h1>
                                Introdução
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Que tal se aprofundar mais no assunto do curso e descrever com mais detalhes o seu objetivo e os conteúdos que serão abordados? Esta introdução vai aparecer na página de detalhes do curso.
                            </p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Row>
                                <AddVideoButton md="auto" className='mb-4 py-2 ml-3 pr-3'>
                                    {
                                        course.introductionVideoUrl !== ""&&course.introductionVideoUrl !== null?
                                        (
                                            <Row className="video-thumbnail-row py-4 pb-1">
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <h5>
                                                                Arquivo enviado
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h3>
                                                                {course.introductionVideoName !== ""?course.introductionVideoName:course.introductionVideoUrl}
                                                            </h3>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md="auto" className='mt-auto mb-1'>
                                                    <SecondaryGreenButton onClick={()=> setShowAddVideoModal(true)} >
                                                        <Row>
                                                            <Col md="auto" className='my-auto pr-0'>
                                                                <i className='bi bi-arrow-repeat'/>
                                                            </Col>
                                                            <Col className='my-auto'>
                                                                <p>
                                                                    Alterar vídeo
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </SecondaryGreenButton>
                                                </Col>
                                                <Col md="auto" className='mt-auto mb-1
                                                '>
                                                    {/* onClick={clearIntroductionVideo()} */}
                                                    <SecondaryYellowButton onClick={clearIntroductionVideo}>
                                                        <Row>
                                                            <Col md="auto" className='my-auto pr-0'>
                                                                <i className='bi bi-trash'/>
                                                            </Col>
                                                            <Col className='my-auto'>
                                                                <p>
                                                                    Excluir vídeo
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </SecondaryYellowButton>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                        <div>
                                            <Button className="add-video" onClick={()=> setShowAddVideoModal(true)}>
                                                <Row>
                                                    <Col>
                                                        <i className="bi bi-camera-video"/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p>Adicionar vídeo</p>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </div>
                                        )
                                    }
                                </AddVideoButton>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <AddVideoButton md="auto" >
                                {
                                        course.introductionImageUrl  !== ""&&course.introductionImageUrl !== null&&course.introductionImageUrl !== undefined?
                                        (
                                            <Row className="video-thumbnail-row py-4 pb-1">
                                                <Col>
                                                    <Row>
                                                        <Col md="auto" className='mx-auto'>
                                                            <img src={course.introductionImageUrl}>
                                                            </img>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md="auto" className='my-auto mb-1'>
                                                    <SecondaryGreenButton onClick={()=> setShowImageModal(true)} >
                                                        <Row>
                                                            <Col md="auto" className='my-auto pr-0'>
                                                                <i className='bi bi-arrow-repeat'/>
                                                            </Col>
                                                            <Col className='my-auto'>
                                                                <p>
                                                                    Alterar imagem
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </SecondaryGreenButton>
                                                </Col>
                                                <Col md="auto" className='my-auto mb-1
                                                '>
                                                    {/* onClick={clearIntroductionVideo()} */}
                                                    <SecondaryYellowButton onClick={clearIntroductionImage}>
                                                        <Row>
                                                            <Col md="auto" className='my-auto pr-0'>
                                                                <i className='bi bi-trash'/>
                                                            </Col>
                                                            <Col className='my-auto'>
                                                                <p>
                                                                    Excluir imagem
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </SecondaryYellowButton>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                        <div>
                                            <Button className="add-video" onClick={()=> setShowImageModal(true)}>
                                                <Row>
                                                    <Col>
                                                        <i className="bi bi-image"/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p>Adicionar imagem</p>
                                                    </Col>
                                                </Row>
                                            </Button>
                                        </div>
                                        )
                                    }

                                </AddVideoButton>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SunEditor
                                defaultValue={course.presentation}
                                height="30vh"
                                width="80.5vw"
                                placeholder="Digite a introdução aqui"
                                onChange={(content)=>handleChangeTextEditor(content)}
                                onPaste={handlePaste}
                                setOptions={{
                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['bold', 'underline', 'italic'],
                                        ['align','list'],
                                        ['link','removeFormat'],
                                    ]
                                }}
                            />
                        </Col>                        
                    </Row>                
                </Col>
            </Row>   
            {
                showImageModal&&
                <AddImageModalComponent
                    getImageFromModal={setIntroductionImage}
                    onClose={()=>setShowImageModal(false)} 
                    reRender={reRender}
                    />
            }         
        </Form>
    );
});
