import { useContext } from 'react';
import { CreateCourseContext } from '../../context/creator/createCourse/CreateCourseContext';
import { Row, Col, Modal } from 'react-bootstrap';
import { PrimaryPurpleButton, SecondaryYellowButton } from '../Buttons/styles';
import { ConfirmActionModalStyles } from './styles';
import { AddModuleContext } from '../../context/creator/createCourse/AddModuleContext';

export function ConfirmDeleteModuleContentModal() {
    const {
        selectedModuleContentIndex,
        deleteModuleContent,
        handleCancelDeleteModuleContent,
        showConfirmDeleteModuleContentModal
    } = useContext(AddModuleContext);

    return (
        <ConfirmActionModalStyles show={showConfirmDeleteModuleContentModal}>
            <Modal.Body>
                <Row className="my-3" style={{ placeContent: 'center' }}>
                    <i className="bi bi-exclamation-circle" style={{ fontSize: 80, color: '#C57700' }}></i>
                </Row>
                <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                    <Col>
                        <h1>
                            Deseja excluir esse conteúdo?
                        </h1>
                    </Col>
                </Row>
                <Row className="my-2" style={{ textAlign: 'center' }}>
                    <Col>
                        Você vai perder todas as informações inseridas.
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer as={Row}>
                <Col className="pr-1">
                    <SecondaryYellowButton onClick={() => deleteModuleContent(selectedModuleContentIndex)}>
                        <Row>
                            <Col md="auto" className="ml-auto px-0 my-auto">
                                <i className='bi bi-trash' />
                            </Col>
                            <Col md="auto" className="ml-0 mr-auto my-auto">
                                <p>
                                    Excluir conteúdo
                                </p>
                            </Col>
                        </Row>
                    </SecondaryYellowButton>
                </Col>
                <Col className="pl-1">
                    <PrimaryPurpleButton onClick={() => handleCancelDeleteModuleContent()}>
                        <Row>
                            <Col md="auto" className="mx-auto">
                                <p>
                                    Manter conteúdo
                                </p>
                            </Col>
                        </Row>
                    </PrimaryPurpleButton>
                </Col>
            </Modal.Footer>
        </ConfirmActionModalStyles>
    );
}



