import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const AssessmentContentDetailsStyles = styled(Row)` 
    overflow-y:scroll;
    
    @media(min-height: 900px) {
        max-height:93vh;  
        button{
            min-height: 5.7vh;  
        }       
    }

    @media(max-height: 900px) {
        max-height:92vh;  

        button{
            min-height: 8vh;  
        }         
    }

    @media(max-height: 800px) {
        max-height:91vh;  

        button{
            min-height: 9vh;  
        }          
    }  

    @media(max-height: 700px) {
        max-height:90vh;  
  
        button{
            min-height: 10vh;  
        }       
    }   

    #assessment-description{
        height:10vmin;
    }

    input {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 2.22vmin;
        letter-spacing: 0px;
    }
`;
export const AssessmentHeaderStyles = styled(Row)`
    margin-left:-1.4vmin;
    background:#F9F9F9;
    min-height:7vh;
    
    p{
        font-family: 'Lato';
        font-style: normal;
        font-weight:600;
        font-size: 2.22vmin;
        line-height: 5px;        
        padding-bottom:0px;
        color:#636060;
    }
`;
