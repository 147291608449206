import styled from 'styled-components';
import { useState } from 'react';
import { Button, Col, ListGroup, Nav, Row } from 'react-bootstrap';
import { StyledPagedSearchButtonList } from '../../../../components/PaginatedSearch/styles';
import ListTasksViewModel from '../../../../domain/models/task/ListTasksViewModel';
import PaginatedSearchResult from '../../../../domain/util/PaginatedSearchResult';
import { formatDateToDDMMYYYY } from '../../../../util/Formatter';
import NoTasksCorrectedYet from './NoTasksCorrectedYet';
import AllTasksCorrected from './AllTasksCorrected';
import { CourseLessonTaskStatusEnum } from '../../../../domain/enum/CourseLessonTaskStatusEnum'

interface TaskListComponentProps {
    taskViewPaginatedSearchPending: PaginatedSearchResult<ListTasksViewModel>,
    taskViewPaginatedSearchCorrected: PaginatedSearchResult<ListTasksViewModel>,
    isLoading: boolean,
    setOpenTaskId: (taskId: string) => void,
    getTaskList: (pageNumber: number, taskStatusId: CourseLessonTaskStatusEnum) => void,
}

export default function TaskListComponent(props: TaskListComponentProps) {

    const [activeTab, setActiveTab] = useState<CourseLessonTaskStatusEnum>(CourseLessonTaskStatusEnum.Pending);

    const GoDetails = (taskId: string) => {
        props.setOpenTaskId(taskId);
    }

    const getTaskList = (pageNumber: number) => {
        switch (activeTab) {
            case CourseLessonTaskStatusEnum.Pending:
                props.getTaskList(pageNumber, CourseLessonTaskStatusEnum.Pending);
                break;
            case CourseLessonTaskStatusEnum.Corrected:
                props.getTaskList(pageNumber, CourseLessonTaskStatusEnum.Corrected);
                break;
            default:
                break;
        }
    }

    function loadPage(pageNumber: number) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        getTaskList(pageNumber)
    }

    const RenderPageButtons = (activeSearch: PaginatedSearchResult<any>): JSX.Element => {
        let buttons: JSX.Element[] = [
            <ListGroup.Item>
                <Button className={activeSearch.currentPage === 1 ? "active" : ""} onClick={() => loadPage(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for (let i = 2; i < activeSearch.totalPages; i++) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto " + (activeSearch.currentPage === i ? "active" : "")} onClick={() => loadPage(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }
        if (activeSearch.totalPages > 1) {
            buttons.push(
                <ListGroup.Item>
                    <Button className={activeSearch.totalPages === activeSearch.currentPage ? "active" : ""} onClick={() => loadPage(activeSearch.totalPages)}>
                        <p>{activeSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )

        }


        return (
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasPrevious} onClick={() => loadPage(activeSearch.currentPage - 1)}>
                        <i className="bi bi-chevron-left" />
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasNext} onClick={() => loadPage(activeSearch.currentPage + 1)}>
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>)
    }

    const renderActiveTab = (activeTab: number) => {
        switch (activeTab) {
            case 1:
                return (
                    <>
                        <Row className="pt-2 pb-2 w-100">
                            <Col className="col-md-12 ">
                                <h2>
                                    {props.taskViewPaginatedSearchPending.totalRecords > 0 ?
                                        (
                                            props.taskViewPaginatedSearchPending.totalRecords == 1 ?
                                                (
                                                    <>{props.taskViewPaginatedSearchPending.totalRecords} tarefa encontrada</>
                                                ) : (
                                                    <>{props.taskViewPaginatedSearchPending.totalRecords} tarefas encontradas</>
                                                )
                                        ) : (<></>)}
                                </h2>
                            </Col>
                        </Row>
                        <Row className="pt-2 pb-2 w-100">
                            {props.taskViewPaginatedSearchPending.totalRecords > 0 ?
                                (
                                    <>
                                        <Col className="col-12">
                                            <Row className="row pl-4">
                                                <Col className="col-md-2 subTitle">
                                                    Status
                                                </Col>
                                                <Col className="col-md-2 subTitle">
                                                    Enviada por
                                                </Col>
                                                <Col className="col-md-3 subTitle">
                                                    Curso
                                                </Col>
                                                <Col className="col-md-3 subTitle">
                                                    Tarefa
                                                </Col>
                                                <Col className="col-md-1 subTitle ">
                                                    Enviada em
                                                </Col>
                                            </Row>
                                        </Col>
                                        {props.taskViewPaginatedSearchPending.items.map(
                                            (tasks: ListTasksViewModel) => {
                                                return (

                                                    <ButtonCardTask className="w-100"
                                                        onClick={() => GoDetails(tasks.id)}
                                                    >
                                                        <Col md={12} className=" card1 w-100">
                                                            <Row className="row ml-1" style={{ alignItems: "center" }}>
                                                                <Col md={2} className=" valueCard status">
                                                                    <div className="statusAwaint">
                                                                        <i className="bi bi-clock  mr-3"></i>Pendente
                                                                    </div >
                                                                </Col>
                                                                <Col md={2} className="valueCard">
                                                                    {tasks.apprenticeName}
                                                                </Col>
                                                                <Col md={3} className="valueCard">
                                                                    {tasks.courseTitle}
                                                                </Col>
                                                                <Col md={3} className=" valueCard">
                                                                    {tasks.task}
                                                                </Col>
                                                                <Col md={1} className=" valueCard">
                                                                    {formatDateToDDMMYYYY(tasks.deliveredDate)}
                                                                </Col>
                                                                <Col md={1} style={{ textAlign: 'right' }}>
                                                                    <i className="bi bi-chevron-right"></i>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </ButtonCardTask>

                                                )
                                            }
                                        )
                                        }
                                        {
                                            props.taskViewPaginatedSearchPending.totalPages > 1 ?
                                                (
                                                    <Row className="">
                                                        <Col md={"auto"} className="mx-auto mt-5">
                                                            {
                                                                RenderPageButtons(props.taskViewPaginatedSearchPending)
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>

                                )

                                : (
                                    <>
                                        <AllTasksCorrected />
                                    </>
                                )
                            }
                        </Row>
                    </>)
            case 2:
                return (
                    <>
                        <>
                            <Row className="pt-2 pb-2 w-100">
                                <Col className="col-md-12 ">
                                    <h2>
                                        {props.taskViewPaginatedSearchCorrected.totalRecords > 0 ?
                                            (

                                                props.taskViewPaginatedSearchCorrected.totalRecords == 1 ?
                                                    (
                                                        <>{props.taskViewPaginatedSearchCorrected.totalRecords} tarefa encontrada</>
                                                    ) : (
                                                        <>{props.taskViewPaginatedSearchCorrected.totalRecords} tarefas encontradas</>
                                                    )
                                            ) : (<></>)}
                                    </h2>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 w-100">
                                {props.taskViewPaginatedSearchCorrected.totalRecords > 0 ?
                                    (
                                        <>
                                            <Col className="col-12">
                                                <Row className="row pl-4">
                                                    <Col className="col-md-2 subTitle">
                                                        Status
                                                    </Col>
                                                    <Col className="col-md-2 subTitle">
                                                        Enviada por
                                                    </Col>
                                                    <Col className="col-md-3 subTitle">
                                                        Curso
                                                    </Col>
                                                    <Col className="col-md-3 subTitle">
                                                        Tarefa
                                                    </Col>
                                                    <Col className="col-md-1 subTitle ">
                                                        Enviada em
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {props.taskViewPaginatedSearchCorrected.items.map(
                                                (tasks: ListTasksViewModel) => {
                                                    return (

                                                        <ButtonCardTask className="w-100"
                                                            onClick={() => GoDetails(tasks.id)}
                                                        >
                                                            <Col md={12} className=" card1 w-100">
                                                                <Row className="ml-1" style={{ alignItems: "center" }}>
                                                                    <Col md={2} className=" valueCard status">
                                                                        <div className="statusAdjusted">
                                                                            <i className="bi bi-check2  mr-3"></i>{tasks.statusDescription}
                                                                        </div >
                                                                    </Col>
                                                                    <Col md={2} className="valueCard">
                                                                        {tasks.apprenticeName}
                                                                    </Col>
                                                                    <Col md={3} className="valueCard">
                                                                        {tasks.courseTitle}
                                                                    </Col>
                                                                    <Col md={3} className=" valueCard">
                                                                        {tasks.task}
                                                                    </Col>
                                                                    <Col md={1} className=" valueCard">
                                                                        {formatDateToDDMMYYYY(tasks.deliveredDate)}
                                                                    </Col>
                                                                    <Col md={1} style={{ textAlign: 'right' }}>
                                                                        <i className="bi bi-chevron-right"></i>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </ButtonCardTask>

                                                    )
                                                }
                                            )
                                            }
                                            {
                                                props.taskViewPaginatedSearchCorrected.totalPages > 1 ?
                                                    (
                                                        <Row className="">
                                                            <Col md={"auto"} className="mx-auto mt-5">
                                                                {
                                                                    RenderPageButtons(props.taskViewPaginatedSearchCorrected)
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                    :
                                                    (
                                                        <></>
                                                    )
                                            }
                                        </>

                                    )

                                    : (
                                        <>
                                            <NoTasksCorrectedYet />

                                        </>
                                    )
                                }
                            </Row>
                        </>

                    </>
                );
            default:
                return (
                    <></>
                );
        }
    }

    return (

        <BodyTasks className="container-fluid ">
            <Row className="row pl-3 ml-0">
                <Col className="col-md-12 pl-0 ml-0">
                    <h2>Tarefas</h2>
                </Col>
            </Row>
            <Row className="row pl-3 pt-2">
                <Col className="col-md-12 ">
                    <h3>Visualize e corrija as tarefas pendentes e revisite as que ja foram corrigidas.</h3>
                </Col>
            </Row>
            <Row className="ml-auto pl-0 ml-0 nav-item">
                <AttendCourseLessonNavBar className="pl-0 ml-0">
                    <Nav variant="pills" defaultActiveKey="/home" className="px-3 mt-2 mb-4 pl-0 ml-0">
                        <Nav.Item className="mx-1">
                            <Nav.Link
                                active={activeTab === CourseLessonTaskStatusEnum.Pending}
                                onClick={() => setActiveTab(CourseLessonTaskStatusEnum.Pending)}
                                className="pt-3 pb-2"
                            >Tarefas Pendentes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mx-1">
                            <Nav.Link
                                active={activeTab === CourseLessonTaskStatusEnum.Corrected}
                                onClick={() => setActiveTab(CourseLessonTaskStatusEnum.Corrected)}
                                className="pt-3 pb-2"
                            >Tarefas Corrigidas</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </AttendCourseLessonNavBar>
            </Row>
            <Row className="ml-3">
                {
                    renderActiveTab(activeTab)
                }
            </Row>
        </BodyTasks>

    )
}


const AttendCourseLessonNavBar = styled(Col)`
    z-index:100;
    max-width:80vw;
    .nav-link{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        background-color:#FFFFFF!important;
        color: #514F4E!important;
    }
    .nav-link.active{
        border-bottom:4px solid #EA694C;
    }
`
const ButtonCardTask = styled.button`
    width: 1240px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
    border-radius: 8px;
    margin: 0.5rem;

    .statusAdjusted{
        color: #218749;
    }
    .statusAwaint{
        color: #C57700;
    }
    i{
        font-size: 3.33vmin;
    }
`

const BodyTasks = styled(Col)`
    margin-top: 3.88vmin;
    text-align: center;
    
    Col{
        text-align: left;
    }

    .card1{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #514F4E;
        align-items: center;
    }

    .subTitle{
        margin: 0;
        padding: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #514F4E;
        text-align: left;
    }

    .valueCard{
        margin: 0;
        margin-left: auto;
        padding: 0;
        text-align: left;
    }

    h2{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #514F4E;
    }

    h3{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #514F4E;
    }

    h4{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #514F4E;
    }

    p{
        height: 24px;
        left: 612px;
        top: 293px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #0C8292;
    }

    img {
        position: absolute;
        left: 25.94%;
        right: 58.75%;
        top: 23.47%;
        bottom: 43.09%;
        left: 259px;
        top: 92px;
    }

`