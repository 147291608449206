import { Button, Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { removeToken } from '../../router/TokenUtils';

interface ProfileMenuMobileProps{
    showProfileMenuMobile:boolean
}

 const ProfileMenu = (props:ProfileMenuMobileProps)=>{
    var a  = (document.getElementById('myForm') as HTMLFormElement);

    if(a !== null){
        if(props.showProfileMenuMobile){
            (document.getElementById('myForm') as HTMLFormElement).style.right = "0px";
            (document.getElementById('overside-div') as HTMLFormElement).style.backgroundColor = "transparent";
            (document.getElementById('overside-div') as HTMLFormElement).style.zIndex = "3";

        }else{
            (document.getElementById('myForm') as HTMLFormElement).style.right = "-700px";
            (document.getElementById('overside-div') as HTMLFormElement).style.backgroundColor = "transparent";
            (document.getElementById('overside-div') as HTMLFormElement).style.zIndex = "0";

        }
    }
    return(
        <ProfileMenuStyles id="myForm">
            <Card.Body className="mx-3 mb-1">
                <Row>
                    <Col>
                        <h1>
                            Olá
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>
                            O que deseja fazer?
                        </h5>
                    </Col> 
                </Row>
                <Row className="mt-1">
                    <Button className="py-3" onClick={removeToken}>
                        <i className="bi bi-box-arrow-left mr-3 pt-2"/>Sair do Cursos Livres
                    </Button>  
                </Row>
            </Card.Body>            
        </ProfileMenuStyles>
    )
}

const ProfileMenuStyles = styled(Card)`
    position:fixed;
    width: 80%;
    z-index: 1;
    min-height: 100px;
    top:11vmin;
    z-index:1000;
    background: #FFFFFF;
    box-shadow: 0px 0.28vmin 0.56vmin rgba(203, 182, 182, 0.25);
    border-radius: 1.12vmin 0px 1.12vmin 1.12vmin;
    transition: 0.8s ease;

    @media screen and (max-width:990px){
            top:19vmin;
    }

    span {
        position: fixed;
        z-index: 0;
        width: 100%;
        height: 100%;
        left:0;
        background: transparent;
    }

    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 5.1vmin;
        color: #514F4E;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 5.1vmin;
        line-height: 2.77vmin;
        color: #514F4E;
    }
    button{
        background-color:#fff;
        border:none!important;
        box-shadow:none!important;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 5.1vmin;
        line-height: 2.64vmin;
        color: #6468C8!important;
        :hover{
            background-color:#E0E1F4;
        }
        :active{
            background-color:#E0E1F4!important;
        }
        :focus{
            background-color:#fff!important;
            border: 1px solid #0C8292!important;
            box-sizing: border-box;
        }
        i{
            font-size:2.5vmin
        }  
        
        @media screen and (max-width:990px){

            i{
                font-size:5.4vmin;
                position: relative;
                top: 2px
            }
    }

    }
`


export default ProfileMenu;
