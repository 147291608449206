import { useContext } from "react";
import { AttendCourseContext } from "../../../../../context/apprentice/attend/AttendCourseContext";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import { Modal, Row, Col } from "react-bootstrap";
import { SecondaryGreenButton, PrimaryPurpleButton } from "../../../../../components/Buttons/styles";
import styled from 'styled-components'
import ModalHeader from "react-bootstrap/esm/ModalHeader";


export const ConfirmExitModal = () => {
    const { } = useContext(AttendCourseContext);

    const {
        closeModal, continueSent
    } = useContext(TaskContext);
    return (
        <>
            <HoldModal className="modalYellow">
                <Modal.Body className="confirm-exit-modal">
                    <Row className="my-3" style={{ placeContent: 'center' }}>
                        <i className="bi bi-exclamation-circle" style={{ fontSize: 80, color: '#C57700' }}></i>
                    </Row>
                    <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                        <Col>
                            <h1>
                                Deseja cancelar o envio?
                            </h1>
                        </Col>
                    </Row>
                    <Row className="my-2" style={{ textAlign: 'center' }}>
                        <Col>
                            Você precisará voltar depois para enviar sua tarefa.
                        </Col>
                    </Row>
                </Modal.Body>
                 {/* Desktop */}
                <Modal.Footer className="d-none d-md-block">                
                    <Row>                                        
                        <Col md={6}>
                            <SecondaryGreenButton onClick={() => closeModal()}>
                                Cancelar Envio
                            </SecondaryGreenButton>
                        </Col> 
                        <Col md={6}>                        
                            <PrimaryPurpleButton onClick={() => continueSent()}>
                                Continuar Envio
                            </PrimaryPurpleButton>
                        </Col>                   
                    </Row>
                </Modal.Footer>
                <Modal.Footer className="d-block d-md-none">                
                    <Row> 
                        <Col md={6}>                        
                            <PrimaryPurpleButton onClick={() => continueSent()}>
                                Continuar Envio
                            </PrimaryPurpleButton>
                        </Col>
                        <Col md={6}>
                            <SecondaryGreenButton className="mt-2" onClick={() => closeModal()}>
                                Cancelar Envio
                            </SecondaryGreenButton>
                        </Col>                 
                    </Row>
                </Modal.Footer>                
            </HoldModal>
        </>
    );
};

const HoldModal = styled(Col)`
    @media screen and (max-width: 990px){  

        &.modalYellow {
            border: solid 2px #C57700 !important;
            border-radius: 12px !important;
        }

        border: 0 !important; 

        .close {
            span {
                font-size: 10vmin !important;
                position: absolute;
                top: 10px !important;
                right: 15px !important;
            }
        }      

        h1 {
            font-size: 5vmin !important;
            line-height: 8vmin !important;
        }

        h3 {
            font-size: 5vmin !important;
            margin-top: 10px;
        }        
    }
`
