
enum LessonContentTypeEnum {
    Text = 1,
    VideoUpload = 2,
    VideoExternal = 3,
    ImageUpload = 4,
    ImageExternal = 5,
    Document = 6,
    Embed = 7,  

}

export default LessonContentTypeEnum
