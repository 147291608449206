import React, { useState } from "react"
import { Button, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import ManageCourseActiveButtonEnum from "../../domain/enum/ManageCourseActiveButtonEnum"
import {ManageCourseSideMenuStyles} from './styles'

interface ManageCourseSideMenuProps{
    activeButton:ManageCourseActiveButtonEnum
    setActivePage:(activePage:ManageCourseActiveButtonEnum)=> void
    setIsLoading:(loading:boolean)=>void
}

const ManageCourseSideMenu = (props:ManageCourseSideMenuProps) =>{
    function activePublications(){
        window.location.href = "/manage/publications"
        props.setIsLoading(true)
    }
    function activeTasks(){
        window.location.href = "/manage/tasks"
        props.setIsLoading(true)

    }
    function activeQuestions(){
        props.setActivePage(ManageCourseActiveButtonEnum.questions)
        props.setIsLoading(true)
    }

    return(
        <ManageCourseSideMenuStyles md={"auto"}>
        <Row className="mt-4 nav-buttons">
            <Col>
                <ListGroup className="px-2">
                    <ListGroupItem className="px-0 py-0">
                        <Button className={props.activeButton === ManageCourseActiveButtonEnum.publication?"active":""} onClick={activePublications}>
                            <Row>
                                <Col md="auto" className="pr-0 py-2">
                                    <i className="bi bi-list-ul"/>
                                </Col>
                                <Col md="auto" className="my-auto">
                                    Publicações
                                </Col>
                            </Row>
                        </Button>
                    </ListGroupItem>
                    <ListGroupItem className="px-0 py-0">
                        <Button className={props.activeButton === ManageCourseActiveButtonEnum.tasks?"active":""} onClick={activeTasks}>
                            <Row>
                                <Col md="auto" className="pr-0 py-2">
                                    <i className="bi bi-file-check"/>
                                </Col>
                                <Col md="auto" className="my-auto">
                                    Tarefas
                                </Col>
                            </Row>
                        </Button>
                    </ListGroupItem>
                    {/* <ListGroupItem className="px-0 py-0">
                        <Button className={props.activeButton === ManageCourseActiveButtonEnum.questions?"active":""} onClick={activeQuestions }>
                            <Row>
                                <Col md="auto" className="pr-0 py-2">
                                    <i className="bi bi-chat-dots"/>
                                </Col>
                                <Col md="auto" className="my-auto">
                                    Dúvidas
                                </Col>
                            </Row>
                        </Button>
                    </ListGroupItem> */}
                </ListGroup>
            </Col>
        </Row>
        <Row className="fixed-bottom">
            <Col md="auto" className="mx-auto mb-4">
                <img className="" src="/img/manageCourse/teacher-using-pc.svg" alt="" />
            </Col>
        </Row>
    </ManageCourseSideMenuStyles>
    )
}
export default ManageCourseSideMenu;
