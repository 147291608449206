import { useContext } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { AddImageModalContext } from '../context/AddImageModalContext';

export function SendLinkScreen() {
    const {
        isInvalidVideoInput, handleVideoLinkChange, errorMessage, imageObject
    } = useContext(AddImageModalContext);
    return <>
        <Row className='mb-2'>
            <Col>
                <p>
                    Link da imagem
                </p>
            </Col>
        </Row>
        <Row className='mb-4'>
            <Col>
                <Form.Control
                    className='w-100'
                    placeholder='Insira o link da imagem'
                    onChange={(e) => handleVideoLinkChange(e)} />
            </Col>
        </Row>
        {isInvalidVideoInput&&imageObject.ImageUrl!==""?
            (
                <Row className='mb-3'>
                    <Col className="erro-message mx-auto py-2">
                        <Row>
                            <Col md="auto" className="my-auto pr-0">
                                <i className='bi bi-exclamation-octagon' />
                            </Col>
                            <Col className="my-auto">
                                <p>
                                    {errorMessage}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
            :
            (
                <Row>
                    <Col>
                        <p>
                            Você pode adicionar imagens da internet.
                        </p>
                    </Col>
                </Row>
            )}
        {(!isInvalidVideoInput) && imageObject?.ImageUrl !== "" ?
            (
                <Row className='img-row'>
                    <Col md="auto" className='my-auto mx-auto mt-4'>
                        <img src={imageObject.ImageUrl} alt="Esse link parece estar quebrado..."/>
                    </Col>
                </Row>
            )
            :
            (
                <></>
            )}

    </>;
}
