import { createContext, ReactNode, useContext, useState } from "react";
import { CreateCourseContext } from "./CreateCourseContext";
import VideoSourceEnum from "../../../domain/enum/VideoSourceEnum";
import LessonContentTypeEnum from "../../../domain/enum/LessonContentTypeEnum";
import HorizontalAlignEnum from "../../../domain/enum/HorizontalAlignEnum";
import { ImageSourceEnum } from "../../../components/Modals/AddImageModal/context/ImageSourceEnum";
import VideoLessonContentViewModel from "../../../domain/models/course/module/lesson/VideoLessonContentViewModel";
import LessonContentViewModel from "../../../domain/models/course/module/lesson/LessonContentViewModel";
import LessonViewModel from "../../../domain/models/course/module/lesson/LessonViewModel";
import ImageLessonContentViewModel from "../../../domain/models/course/module/lesson/ImageLessonContentViewModel";
import DocumentLessonContentViewModel from "../../../domain/models/course/module/lesson/DocumentLessonContentViewModel";
import { AddVideoObject } from "../../../components/Modals/AddVideoModal/context/AddVideoModalContext";
import { AddImageObject } from "../../../components/Modals/AddImageModal/context/AddImageObject";
import { AddDocumentObject } from "../../../components/Modals/AddDocumentModal/context/AddDocumentObject";
import { AddModuleContext } from "./AddModuleContext";

const CreateLessonModuleContentContext = createContext({} as CreateLessonModuleContentContextData);
interface CreateLessonModuleContentContextProviderProps {
    children: ReactNode;
}

export function CreateLessonModuleContentContextProvider(props: CreateLessonModuleContentContextProviderProps) {

    const {
        setIsChanged, 
        reRender
    } = useContext(CreateCourseContext);

    const {
        getSelectedModuleContent, 
        setIsValidModuleContent
    } = useContext(AddModuleContext);

    const [selectedLessonContentIndex, setSelectedLessonContentIndex] = useState<number>(-1);

    const [isEditingLessonContent, setIsEditingLessonContent] = useState<boolean>(false);

    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

    const [showImageModal, setShowImageModal] = useState<boolean>(false);

    const [showEmbedModal, setShowEmbedModal] = useState<boolean>(false);

    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);


    const moduleContent = getSelectedModuleContent();

    function getSelectedLessonContent(){
        return moduleContent.lesson!.lessonContents[selectedLessonContentIndex]
    }





    //Text
    const addNewTextLessonComponent = () => {

        const newLessonContent: LessonContentViewModel = {
            lessonContentTypeId: LessonContentTypeEnum.Text,
            order: moduleContent.lesson!.lessonContents.length,
            content: ""
        };

        const newLesson: LessonViewModel = {
            id: moduleContent.lesson!.id,
            moduleContentId: moduleContent.lesson!.moduleContentId,
            coverImage: moduleContent.lesson!.coverImage,
            lessonContents: moduleContent.lesson!.lessonContents.concat(newLessonContent)
        };

        moduleContent.lesson = newLesson;

        setSelectedLessonContentIndex(newLessonContent.order);
        reRender();
    };

    //Video
    const editVideoLessonContent = () => {
        setIsEditingLessonContent(true);
        setShowVideoModal(true);
    };

    const setVideoInModal = (): AddVideoObject => {
        return {
            VideoLink: "",
            VideoName: "",
            VideoSource: VideoSourceEnum.Undefined
        };
    };

    const getVideoFromModal = (videoObject: AddVideoObject) => {
        setIsChanged(true);
        if (isEditingLessonContent) {
            const newVideoContent: VideoLessonContentViewModel = {
                Align: HorizontalAlignEnum.Left,
                FullSize: false,
                VideoObject: videoObject,
                IsNew: true
            };

            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: videoObject.VideoSource === VideoSourceEnum.Azure ? LessonContentTypeEnum.VideoUpload : LessonContentTypeEnum.VideoExternal,
                order: selectedLessonContentIndex,
                content: JSON.stringify(newVideoContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents
                    .filter(x => { return x.order !== selectedLessonContentIndex; })
                    .concat(newLessonContent)
                    .sort((a, b) => a.order - b.order)
            };

            moduleContent.lesson = newLesson;
            setSelectedLessonContentIndex(selectedLessonContentIndex);

        } else {
            const newVideoContent: VideoLessonContentViewModel = {
                Align: HorizontalAlignEnum.Left,
                FullSize: false,
                VideoObject: videoObject,
                IsNew: true
            };

            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: videoObject.VideoSource === VideoSourceEnum.Azure ? LessonContentTypeEnum.VideoUpload : LessonContentTypeEnum.VideoExternal,
                order: moduleContent.lesson!.lessonContents.length,
                content: JSON.stringify(newVideoContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents.concat(newLessonContent)
            };

            moduleContent.lesson = newLesson;
            setSelectedLessonContentIndex(newLesson.lessonContents.length - 1);
        }

        setIsEditingLessonContent(false);
        setShowVideoModal(false);
        setIsValidModuleContent(true);
        reRender();
    };

    const onCloseVideoModal = () => {
        setShowVideoModal(false);
    };

    //Imagem
    const getImageFromModal = (imageObject: AddImageObject) => {

        setIsChanged(true);
        if (isEditingLessonContent) {
            const newImageContent: ImageLessonContentViewModel = {
                Align: HorizontalAlignEnum.Left,
                FullSize: false,
                ImageObject: imageObject,
                IsNew: true
            };
            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: imageObject.ImageSource === ImageSourceEnum.Internal ? LessonContentTypeEnum.ImageUpload : LessonContentTypeEnum.ImageExternal,
                order: selectedLessonContentIndex,
                content: JSON.stringify(newImageContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents
                    .filter(x => { return x.order !== selectedLessonContentIndex; })
                    .concat(newLessonContent)
                    .sort((a, b) => a.order - b.order)
            };

            moduleContent.lesson = newLesson;
        } else {
            const newVideoContent: ImageLessonContentViewModel = {
                Align: HorizontalAlignEnum.Left,
                FullSize: false,
                ImageObject: imageObject,
                IsNew: true
            };

            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: imageObject.ImageSource === ImageSourceEnum.Internal ? LessonContentTypeEnum.ImageUpload : LessonContentTypeEnum.ImageExternal,
                order: moduleContent.lesson!.lessonContents.length,
                content: JSON.stringify(newVideoContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents.concat(newLessonContent)
            };

            moduleContent.lesson = newLesson;

            setSelectedLessonContentIndex(newLesson.lessonContents.length - 1);

        }

        setIsEditingLessonContent(false);
        setShowImageModal(false);
        setIsValidModuleContent(true);
        reRender();
    };

    const editImageLessonContent = () => {
        setIsEditingLessonContent(true);
        setShowImageModal(true);
    };

    const onCloseImageModal = () => {
        setShowImageModal(false);
    };


    //Embed
    const getEmbedFromModal = (embed: string) => {
        setIsChanged(true);

        if (isEditingLessonContent) {
            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: LessonContentTypeEnum.Embed,
                order: selectedLessonContentIndex,
                content: embed
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents
                    .filter(x => { return x.order !== selectedLessonContentIndex; })
                    .concat(newLessonContent)
                    .sort((a, b) => a.order - b.order)
            };

            moduleContent.lesson = newLesson;
        } else {
            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: LessonContentTypeEnum.Embed,
                order: moduleContent.lesson!.lessonContents.length,
                content: embed
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents.concat(newLessonContent)
            };

            moduleContent.lesson = newLesson;
            setSelectedLessonContentIndex(newLesson.lessonContents.length - 1);

        }
        setIsEditingLessonContent(false);
        setShowEmbedModal(false);
        setIsValidModuleContent(true);
        reRender();
    };

    const editEmbedLessonContent = () => {
        setIsEditingLessonContent(true);
        setShowEmbedModal(true);
    };

    const onCloseEmbedModal = () => {
        setShowEmbedModal(false);
    };

    //Document
    const getDocumentFromModal = (documentObject: AddDocumentObject) => {
        setIsChanged(true);
        if (isEditingLessonContent) {
            const newDocumentContent: DocumentLessonContentViewModel = {
                Url: documentObject.Url,
                Name: documentObject.Name,
                ViewType: documentObject.ViewType,
                IsNew: true
            };

            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: LessonContentTypeEnum.Document,
                order: selectedLessonContentIndex,
                content: JSON.stringify(newDocumentContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents
                    .filter(x => { return x.order !== selectedLessonContentIndex; })
                    .concat(newLessonContent)
                    .sort((a, b) => a.order - b.order)
            };

            moduleContent.lesson = newLesson;
        } else {
            const newDocumentContent: DocumentLessonContentViewModel = {
                Url: documentObject.Url,
                Name: documentObject.Name,
                ViewType: documentObject.ViewType,
                IsNew: true
            };

            const newLessonContent: LessonContentViewModel = {
                lessonContentTypeId: LessonContentTypeEnum.Document,
                order: moduleContent.lesson!.lessonContents.length,
                content: JSON.stringify(newDocumentContent)
            };

            const newLesson: LessonViewModel = {
                id: moduleContent.lesson!.id,
                moduleContentId: moduleContent.lesson!.moduleContentId,
                coverImage: moduleContent.lesson!.coverImage,
                lessonContents: moduleContent.lesson!.lessonContents.concat(newLessonContent)
            };

            moduleContent.lesson = newLesson;
            setSelectedLessonContentIndex(newLesson.lessonContents.length - 1);
        }

        setIsEditingLessonContent(false);
        setShowDocumentModal(false);
        setIsValidModuleContent(true);
        reRender();
    };
    const editDocumentLessonContent = () => {
        setIsEditingLessonContent(true);
        setShowDocumentModal(true);
    };
    const onCloseDocumentModal = () => {
        setShowDocumentModal(false);
    };

    return (
        <>
            <CreateLessonModuleContentContext.Provider 
                value={{
                    selectedLessonContentIndex, 
                    setSelectedLessonContentIndex,

                    showEmbedModal,
                    setShowEmbedModal,
    
                    showVideoModal,
                    setShowVideoModal,
    
                    showImageModal,
                    setShowImageModal,
    
                    showDocumentModal,
                    setShowDocumentModal,
    
                    isEditingLessonContent,
                    setIsEditingLessonContent,

                    getSelectedLessonContent,

                    setVideoInModal,
                    getVideoFromModal,
                    onCloseVideoModal,
                    editVideoLessonContent,
    
                    getImageFromModal,
                    onCloseImageModal,
                    editImageLessonContent,
    
                    getEmbedFromModal,
                    onCloseEmbedModal,
                    editEmbedLessonContent,
    
                    getDocumentFromModal,
                    editDocumentLessonContent,
                    onCloseDocumentModal,
    
                    addNewTextLessonComponent,
                    
            }}>
                {props.children}
            </CreateLessonModuleContentContext.Provider>
        </>
    );
}

export function useCreateLessonModuleContentContext() {
    return useContext(CreateLessonModuleContentContext);
}
interface CreateLessonModuleContentContextData {
    showVideoModal: boolean
    setShowVideoModal: React.Dispatch<React.SetStateAction<boolean>>

    showImageModal: boolean
    setShowImageModal: React.Dispatch<React.SetStateAction<boolean>>

    showEmbedModal: boolean
    setShowEmbedModal: React.Dispatch<React.SetStateAction<boolean>>

    showDocumentModal: boolean
    setShowDocumentModal: React.Dispatch<React.SetStateAction<boolean>>
    
    isEditingLessonContent: boolean
    setIsEditingLessonContent: React.Dispatch<React.SetStateAction<boolean>>

    selectedLessonContentIndex: number
    setSelectedLessonContentIndex: React.Dispatch<React.SetStateAction<number>>

    getSelectedLessonContent: ()=> LessonContentViewModel

    setVideoInModal: () => AddVideoObject
    getVideoFromModal: (videoObject: AddVideoObject) => void
    onCloseVideoModal: () => void
    editVideoLessonContent: () => void

    getImageFromModal: (imageObject: AddImageObject) => void
    onCloseImageModal: () => void
    editImageLessonContent: () => void

    getEmbedFromModal: (embed: string) => void,
    editEmbedLessonContent: () => void
    onCloseEmbedModal: () => void

    getDocumentFromModal: (documentObject: AddDocumentObject) => void,
    editDocumentLessonContent: () => void
    onCloseDocumentModal: () => void,

    addNewTextLessonComponent: () => void

}
