import { useEffect, useState, useContext } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap"
import { AttendCourseContext, AttendCourseContextProvider } from '../../context/apprentice/attend/AttendCourseContext'

import {
    AttendCourseHeaderStyles,
    ChangeLightModeButton,
    InfoButton,
    ExitButton
} from './styles'

interface AttendCourseHeaderComponentProps {
    courseName: string,
    progress: number
}

export const AttendCourseHeaderComponent = (props: AttendCourseHeaderComponentProps) => {

    const { setShowModalCourseInfo } = useContext(AttendCourseContext)

    const GoHome = () => {
        window.location.href = "/home"
    }

    return (
        <>
        <AttendCourseHeaderStyles className="d-none d-md-block">
            <Container fluid={true} className="px-0">
                <Row className="mx-0">
                    <Col sm={5} className="my-auto ml-4">
                        <h1 >
                            {props.courseName}
                        </h1>
                    </Col>
                    <Col md={2} className="ml-auto mt-3 mr-2">
                        <Row>
                            <Col className="text-left mb-1">
                                <p className="m-0">
                                    Seu progresso
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <ProgressBar now={props.progress} variant="success" />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={"auto"} className="my-auto">
                        <InfoButton className="pb-4 pt-4 mt-2" onClick={() => setShowModalCourseInfo(true)}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi bi-info-circle" />
                                </Col>
                            </Row>
                        </InfoButton>
                    </Col>
                    {/* <Col md={"auto"} className="my-auto">
                    <ChangeLightModeButton className="pb-4 pt-4 mt-2">
                        <Row>
                            <Col md={"auto" } className="my-auto pr-0">
                                <i className="bi bi-moon-fill"/>
                            </Col>
                            <Col md={"auto" } className="my-auto">
                                Modo Escuro
                            </Col>
                        </Row>
                    </ChangeLightModeButton>
                    </Col> */}
                    <Col md={"auto"} className="my-auto mx-4">
                        <ExitButton className="pb-4 pt-4 mt-2" onClick={() => GoHome()}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi bi-x-lg mr-3" />
                                </Col>
                                <Col md={"auto"} className="my-auto">
                                    Sair
                                </Col>

                            </Row>
                        </ExitButton>
                    </Col>
                </Row>
            </Container>
        </AttendCourseHeaderStyles>
        {/* Mobile */}
        <AttendCourseHeaderStyles className="d-block d-md-none">
        <Container fluid={true}>
            <Row>
                <Col xs={8}>
                    <h1 >
                        {props.courseName}
                    </h1>
                </Col>
                <Col xs={1}>
                    <InfoButton onClick={() => setShowModalCourseInfo(true)}>
                        <i className="bi bi-info-circle" />
                    </InfoButton> 
                </Col>
                <Col xs={1} className="pr-2">
                    <ExitButton onClick={() => GoHome()}>
                        <i className="bi bi-x-lg mr-3" />                        
                    </ExitButton>
                </Col>
            </Row>
        </Container>
    </AttendCourseHeaderStyles>
    </>
    )
}
