import { useContext } from 'react';
import { Col, Row } from "react-bootstrap";
import { TertiaryYellowButton } from '../../../Buttons/styles';
import { AddDocumentModalContext } from '../context/AddDocumentModalContext';

export function UploadingScreen() {
    const {
        cancelUpload
    } = useContext(AddDocumentModalContext);

    return <>
        <Row className="mb-2 mt-5">
            <Col md="auto" className="mx-auto mt-5">
                <img alt="" src='/img/student/home/woman-meditating.svg' />
            </Col>
        </Row>
        <Row className="mb-4">
            <Col md="auto" className="mx-auto">
                <p>
                    Estamos carregando seu arquivo...
                </p>
            </Col>
        </Row>
        <Row>
            <Col md="auto" className="mx-auto">
                <TertiaryYellowButton onClick={() => cancelUpload()}>
                    <Row>
                        <Col className="my-auto pr-0">
                            <i className="bi bi-x" />

                        </Col>
                        <Col md="auto" className="my-auto">
                            <p>
                                Cancelar
                            </p>
                        </Col>
                    </Row>
                </TertiaryYellowButton>
            </Col>
        </Row>
    </>;
}
