import React, { useEffect, useState } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import history from "../History";
import { Application } from "../domain/BrainzAuthToken";
import Unavaliable from "../pages/mobile/Unavaliable";
import { ComponentRouterRender } from "./types/ProfileRoutesList";
import AppProfileRoutes from "./AppProfileRoutes";
import styled from "styled-components";
import {
    refreshToken,
    removeToken,
    getToken,
    getDecodedToken,
    setToken,
    isTeams,
} from "./TokenUtils";
import { Card } from "react-bootstrap";
import Loader from "../components/Common/Loader";

const defaultRoutes = AppProfileRoutes.filter((profileRoute) => {
    return profileRoute.profileCode === "";
})[0];

const BrainzRouter = () => {
    const [reload, callReload] = useState(false);

    const reRender = () => {
        callReload(!reload);
    };

    const [isMobile, setIsMobile] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [userRoutes, setUserRoutes] = useState<ComponentRouterRender<any>[]>(
        defaultRoutes.routes
    );

    const checkIfIsMobile = () => {
        if (
            window.innerWidth / window.innerHeight < 1.5 ||
            window.innerWidth < 1100
        ) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        checkIfIsMobile();
        handleUserAccess();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", checkIfIsMobile);
    });

    //creating awaiting routes
    const creatingAwaitingResponses = () => {
        let logingRoutes = AppProfileRoutes.filter((profileRoute) => {
            return profileRoute.profileCode === "handle_query_string";
        })[0];

        setUserRoutes(logingRoutes.routes);
        reRender();
    };

    const creatingUnauthResponses = () => {
        let logingRoutes = AppProfileRoutes.filter((profileRoute) => {
            return profileRoute.profileCode === "";
        })[0];

        setUserRoutes(logingRoutes.routes);
        reRender();
    };

    const closeMenu = () => {
        (document.getElementById('myForm') as HTMLFormElement).style.right = "-700px";
        (document.getElementById('overside-div') as HTMLFormElement).style.backgroundColor = "transparent";
        (document.getElementById('overside-div') as HTMLFormElement).style.zIndex = "0";
    };

    const handleUserAccess = async () => {
        let token = getToken();
        creatingUnauthResponses();

        if (
            window.location.pathname !== "/connHandTeams" &&
            window.location.pathname !== "/userDefaultTeams"&&
            !window.location.pathname.includes("validateModuleCertificate")&&
            !window.location.pathname.includes("validateCourseCertificate")
        ) {
            if (token === undefined || token === null) {
                let query = new URLSearchParams(window.location.search).get(
                    String(process.env.REACT_APP_BRAINZ_NAME_TOKEN)
                );

                if (query === null) {
                    if (isTeams()) {
                        window.location.pathname = "/connHandTeams";
                    } else {
                        window.location.href = String(
                            process.env.REACT_APP_ACCOUNT_REDIRECT
                        );
                    }
                } else {                    
                    creatingAwaitingResponses();
                    setToken(query);
                    await refreshToken(true);
                }
            } else {
                if (window.location.pathname === "/") {
                    window.location.pathname = "/home";
                }
                creatingAwaitingResponses();
                await refreshToken(false);

                let decoded = getDecodedToken();
                if (decoded.applications) {
                    let userApplications: Application[] = JSON.parse(
                        decoded.applications
                    );
                    let userApplication = userApplications.filter((userApplication) => {
                        return (
                            userApplication.Id === process.env["REACT_APP_APPLICATION_ID"]
                        );
                    });

                    if (userApplication.length > 0) {
                        let userApplicationProfiles = userApplication[0].Profiles;
                        let userRoutes: ComponentRouterRender<any>[] = [];

                        userApplicationProfiles.forEach((userApplicationProfile) => {
                            let profileRoutes = AppProfileRoutes.filter((profileRoutes) => {
                                return (
                                    profileRoutes.profileCode === userApplicationProfile.Code
                                );
                            });

                            if (profileRoutes.length > 0) {
                                userRoutes = userRoutes.concat(profileRoutes[0].routes);
                            }
                        });
                        setUserRoutes(userRoutes);
                        setIsAuthenticating(false);
                    } else {
                        removeToken();
                    }
                } else {
                    removeToken();
                }
            }
        }
    };
    
    //we only redirect user to home page case the authentication process it's completed
    return (
        <Router history={history}>
            <div className="App">
                <OverlayDiv onClick={() => closeMenu()} id="overside-div" className="d-block d-md-none d-lx-none d-xl-none">
                    <Card className="overside-div"></Card>
                </OverlayDiv>
                <OverlayDiv id="overside-div-categoria" className="d-block d-md-none d-lx-none d-xl-none">
                </OverlayDiv>
                <Switch>
                    {
                        ((getToken() === undefined || getToken() === null)&&
                        !(window.location.pathname.includes("validateModuleCertificate")||
                        window.location.pathname.includes("validateCourseCertificate")) )&& (
                            <Loader />
                        )
                    }
                    {userRoutes.map((route) => {                        
                        return (
                            <Route path={route.path} key={route.path}>
                                {React.createElement(route.component, route.props)}
                            </Route>
                        );
                    })}
                </Switch>
            </div>
        </Router>
    );
};

const OverlayDiv = styled(Card)`
  width: 80%;
  height: 80%;
  position: fixed;
  z-index: 0;
  top:8%;
  left: 5%;
  background-color: transparent !important;
  border: none;
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px !important;
  opacity:0;
`;

export default BrainzRouter;
