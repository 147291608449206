import pt from "date-fns/locale/pt";
import { useEffect, useState } from "react";
import CampaignServices from "../../../services/CampaignServices";
import styled from 'styled-components';
import DatePicker, { registerLocale } from "react-datepicker";
import HomeHeader from "../../../components/Headers/HomeHeader";
import CreatorHeaderActiveTabEnum from "../../../domain/enum/CreatorHeaderActiveTabEnum";
import { Button, Card, Col, ListGroup, ListGroupItem, Nav, Row } from "react-bootstrap";
import Search from "../../../components/Search/Search";
import { AttendCourseLessonNavBar } from "../../mylearning/styles";
import { formatDateFull, formatDateToSend, formatarValor } from "../../../util/Formatter";
import { useRouteMatch } from "react-router";
import { TertiaryGreenButton, TertiaryYellowButton } from "../../../components/Buttons/styles";
import ChangeLessonLoader from "../../../components/Loaders/ChangeLessonLoader";

const campaignServices = new CampaignServices();

const DetailsCampaign = (props: any) => {
    const match = useRouteMatch<any>("/campaign/:id?");
    const id = match!.params.id!
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState<boolean>(true);
    const [openOptionDate, setOpenOptionDate] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [detailsCampaign, setDetailsCampaign] = useState<any>();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    registerLocale("pt", pt);


    useEffect(() => {
        loadDetails(id)
    }, [])

    useEffect(() => {
        setStartDate(undefined);
        setEndDate(undefined)
        setSearchFilter('')
        setOpenOptionDate(false)
    }, [activeTab])

    function queryFilters() {
        let query = "";
        if (searchFilter !== "") query = query += "&search=" + searchFilter;
        if (startDate !== undefined)
            query = query +=
                "&initialDate=" + formatDateToSend(startDate) + "&";
        if (endDate !== undefined)
            query = query += "finalDate=" + formatDateToSend(endDate) + "&";
        return query;
    }

    function loadDetailsCampaign() {
        switch (activeTab) {
            case true:
                //loadDetailsCampaignActive(1)
                break;
            case false:
                //loadDetailsCampaignDisabled(1)
                break;
            default:
                //loadDetailsCampaignActive(1)
                break;
        }
    }
    function loadDetails(id: string) {
        setLoading(true)
        campaignServices.GetCampaignDetails(id)
            .then(response => {
                setDetailsCampaign(response.result)
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
    }
    function DesactivateCourse(courseId: any) {
        setLoading(true)
        campaignServices.DesactivateCourseCampaign(id, courseId)
            .then(response => {

                loadDetails(id)
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    function RedirectToBack() {
        window.location.href = "/controlpanel/campaigns"
    }
    function redirectToEditCampaign(id: string) {
        window.location.href = "/campaign/edit/" + id
    }

    const renderActiveTab = (activeTab: boolean) => {
        switch (activeTab) {
            case true:
                return (
                    <>
                        {detailsCampaign?.salesCourseDetails.filter((item: any) => item.active === true).length > 0 ?
                            <Row className="ml-1 w-100">
                                <Col md={4} className="pl-0">
                                    <div className="title-list">
                                        Curso
                                    </div>
                                </Col>
                                <Col md={2} className="pl-0">
                                    <div className="title-list">
                                        Valor original
                                    </div>
                                </Col>
                                <Col md={2} className="pl-0">
                                    <div className="title-list">
                                        Valor com desconto
                                    </div>
                                </Col>
                                <Col md={2} className="pl-0">
                                    <div className="title-list" >
                                        Vendas
                                    </div>
                                </Col>
                                <Col md={2} className="pl-0">
                                    <div className="title-list">
                                        Opções
                                    </div>
                                </Col>
                            </Row> : <>Sem cursos vinculados</>
                        }
                        {detailsCampaign?.salesCourseDetails.filter((item: any) => item.active === true).map((curso: any) => {

                            return (

                                <Row className="w-100" >
                                    <Col>
                                        <ListGroup className="w-100 px-0">
                                            <ListGroupItem className="w-100 px-0  py-2">
                                                <Card className={`card ${detailsCampaign?.active ? '' : 'disabled'}`} style={{ opacity: !detailsCampaign?.active ? 0.5 : 1 }}>
                                                    <Card.Body className="py-3">
                                                        <Row>
                                                            <Col md={4} className="my-auto">
                                                                <p>
                                                                    {curso.courseName}
                                                                </p>
                                                            </Col>
                                                            <Col md={2} className="my-auto">
                                                                <p >
                                                                    R${formatarValor(curso.price)}
                                                                </p>
                                                            </Col>
                                                            <Col md={2} className="my-auto">
                                                                <p className="p-percents">
                                                                    R${formatarValor(curso.priceDiscount)}
                                                                </p>
                                                            </Col>
                                                            <Col md={2} className="my-auto">
                                                                <p>
                                                                    {curso.soldQnt}
                                                                </p>
                                                            </Col>
                                                            <Col md={2} className="my-auto">
                                                                <Button className="view-icon"
                                                                    onClick={() => DesactivateCourse(curso.courseId)}
                                                                    title="Desvincular curso">
                                                                    <Row>
                                                                        <Col className="my-auto pl-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-ban mr-3" viewBox="0 0 16 16">
                                                                                <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                                                                            </svg>
                                                                            Desvincular curso
                                                                        </Col>
                                                                    </Row>
                                                                </Button>

                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Col>
                                </Row>
                            )
                        })
                        }


                    </>

                );
            case false:
                return (<>
                    {detailsCampaign?.salesCourseDetails.filter((item: any) => item.active === false).length > 0 ?
                        <Row className="ml-1 w-100">
                            <Col md={6} className="pl-0">
                                <div className="title-list">
                                    Curso
                                </div>
                            </Col>
                            <Col md={2} className="pl-0">
                                <div className="title-list">
                                    Valor original
                                </div>
                            </Col>
                            <Col md={2} className="pl-0">
                                <div className="title-list">
                                    Valor com desconto
                                </div>
                            </Col>
                            <Col md={2} className="pl-0">
                                <div className="title-list" >
                                    Vendas
                                </div>
                            </Col>
                        </Row> : <>Sem cursos desvinculados</>
                    }
                    {detailsCampaign?.salesCourseDetails.filter((item: any) => item.active === false).map((curso: any) => {

                        return (

                            <Row className="w-100">
                                <Col>
                                    <ListGroup className="w-100 px-0">
                                        <ListGroupItem className="w-100 px-0  py-2">
                                            <Card>
                                                <Card.Body className="py-3">
                                                    <Row>
                                                        <Col md={6} className="my-auto">
                                                            <p>
                                                                {curso.courseName}
                                                            </p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <p >
                                                                R${formatarValor(curso.price)}
                                                            </p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <p className="p-percents">
                                                                R${formatarValor(curso.priceDiscount)}
                                                            </p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <p>
                                                                {curso.soldQnt}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Col>
                            </Row>
                        )
                    })
                    }
                </>);
            default:
                return (
                    <></>
                );

        }
    }


    return (
        <>
            <HomeHeader activeTab={CreatorHeaderActiveTabEnum.controlPanel} />
            {!loading ? <CampaignDetailStyles className="container-fluid">

                <Row className="container-fluid d-flex pb-4">
                    <Col className="align-self-center" >
                        <h2 onClick={() => RedirectToBack()}><i className='bi bi-arrow-left mr-2' /> {detailsCampaign?.campaignName}</h2>
                    </Col>
                    {detailsCampaign?.active &&
                        <Col className="text-right">
                            {/* <TertiaryYellowButton
                                className="mt-0 button-desativar-campanha"
                            //onClick={() => setActiveTab(true)}
                            >
                                <Row className="p-0 m-0">
                                    <Col md="auto" className="pl-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-ban" viewBox="0 0 16 16">
                                            <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                                        </svg>
                                    </Col>
                                    <Col md="auto" className="my-auto">
                                        <p>Desativar campanha</p>
                                    </Col>
                                </Row>
                            </TertiaryYellowButton> */}

                            <TertiaryGreenButton
                                className="ml-3 button-editar-campanha"
                            onClick={() => redirectToEditCampaign(detailsCampaign.id)}
                            >
                                <Row className="p-0 m-0">
                                    <Col md="auto" className="pl-0">
                                        <i className="bi bi-pencil" />
                                    </Col>
                                    <Col md="auto" className="my-auto">
                                        <p>Editar</p>
                                    </Col>
                                </Row>
                            </TertiaryGreenButton>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <Card className="card-header">
                            <Row>
                                <Col>
                                    <Row className="m-2">
                                        <i className="bi bi-calendar3 icon-calendar mr-2" /> Data inicio e fim
                                    </Row>
                                    <Row className="m-2">
                                        <h2> {formatDateFull(detailsCampaign?.campaignStartDate)} &nbsp;|&nbsp;{formatDateFull(detailsCampaign?.campaignEndDate)}</h2>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="m-2">
                                        % Porcentagem de desconto
                                    </Row>
                                    <Row className="m-2">
                                        <h2>{detailsCampaign?.discountPercentage}%</h2>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="m-2">
                                        Numero de acessos por curso
                                    </Row>
                                    <Row className="m-2">
                                        <h2>{detailsCampaign?.maxSoldQnt} pessoas</h2>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className="ml-auto pl-0 ml-0 nav-item align-items-center">
                    <AttendCourseLessonNavBar className="pl-0 ml-0">
                        <Nav variant="pills" defaultActiveKey="/home" className="px-3 mt-2 mb-4 pl-0 ml-0">
                            <Nav.Item className="mx-1">
                                <Nav.Link
                                    active={activeTab === true}
                                    onClick={() => setActiveTab(true)}
                                    className="pt-3 pb-2"
                                >Cursos vinculados</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-1">
                                <Nav.Link
                                    active={activeTab === false}
                                    onClick={() => setActiveTab(false)}
                                    className="pt-3 pb-2"
                                >Cursos desvinculados</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </AttendCourseLessonNavBar>

                    <Col md={3}>
                        <Search
                            setSearchFilter={setSearchFilter}
                            searchFilter={searchFilter}
                            placeholder="Nome do Curso"
                        />
                        <i className="bi bi-search"></i>
                    </Col>
                </Row>
                <Row className="ml-3">
                    {
                        renderActiveTab(activeTab)
                    }
                </Row>
            </CampaignDetailStyles>
                :
                <Row >
                    <Col >
                        <ChangeLessonLoader
                            sideMenuIsOpen={false}
                        />
                    </Col>
                </Row>}
        </>
    );
}

export default DetailsCampaign;


const CampaignDetailStyles = styled(Col)`
margin-top: 85px;
.card.disabled {
    background-color: #f5f5f5;
    color: #aaa;
    cursor: not-allowed;
  }
.card-header{
    place-content: center;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    border: 1px solid #E8e8e8;
    background-color: #F9F9F9;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    
    color: #0C8292;
    
    h2{
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        
    }
}
.text-right{
    text-align-last: right;
}
.button-desativar-campanha{
    height: 48px !important;
    border: 1px solid #C57700!important;   
    font-weight: 600!important; 
}
.button-editar-campanha{
    height: 48px !important;
    border: 1px solid #0B717E!important; 
    font-family: Lato!important;  
    font-size: 16px!important;  
    font-weight: 600!important;  
    line-height: 19.2px!important;  
    text-align: left!important;  
   
    i {
        font-size: 24px;
    }
}
.input-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    width: 100%;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    &:focus {
        box - shadow: none !important;
    }
}
.icon-calendar{
    font-size: 16px;
    color: #0C8292;
}
                    .bi-search{
                        position: absolute;
                    top: 5px;
                    right: 30px;
                    font-size: 24px;

   }
                    .form-control {
                        background: #fff;

                    padding: 12px 8px !important;
                    width: 100% !important;
                    height: 50px !important;

                    border: 1px solid #a6a6a6;
                    border-radius: 8px;
  }
                    Col{
                        text - align: left;
}

                    .card1{
                        font - family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #514F4E;
                    align-items: center;
}

                    .subTitle{
                        margin: 0;
                    padding: 0;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #514F4E;
                    text-align: left;
}

                    .valueCard{
                        margin: 0;
                    margin-left: auto;
                    padding: 0;
                    text-align: left;
}

                    h2{
                        text - align: left;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #514F4E;
}

                    h3{
                        text - align: left;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #514F4E;
}

                    h4{
                        text - align: left;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #514F4E;
}
                    .title-list{
                        text - align: left;
                    height: 24px;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #636060;
                    margin:0;
}

                    p{
                        text - align: left;
                    height: 24px;
                    left: 612px;
                    top: 293px;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #514F4E;
                    margin:0;
}
                    .p-percents{
                        color: #218749
}

                    img {
                        position: absolute;
                    left: 25.94%;
                    right: 58.75%;
                    top: 23.47%;
                    bottom: 43.09%;
                    left: 259px;
                    top: 92px;
}
                    .custom-dropdown{
                        height: 48px;
                    gap: 12px;
                    border-radius: 8px;
                    border: 1px solid #E8E8E8;
                    opacity: 0px;
                    background-color: white !important;
                    color: #6c757d !important; /* Cor do texto em cinza claro */
}
                    .custom-dropdown-item{
                        font - family: Lato;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #777575;
                    i {
                        font - size: 24px;
}
}

                    .list-group-item{
                        border:none;
                    .card{
                        font-size: 1.8vmin;
                    background: #FFFFFF;
                    border: 1px solid #E8E8E8;
                    box-sizing: border-box;
                    box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
                    border-radius: 8px;
                    button{
                        text-align:left;
                    background-color:#fff!important;
                    border:none;
                    box-shadow:none;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #514F4E;
                    :active{
                        background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
            }
                    :focus{
                        background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
            }
        }

                    .edit-icon
                    {
                        color:#0C8292;
                    i{
                        font-size:4.5vmin;
            }
        }

                    .view-icon
                    {
                        color:#C57700;
                    i{
                        font-size:24px;
            }
        }

                    .edit-icon {
                        color:#0C8292;
                    i{
                        font-size:4vmin;
            }
        }

                    .archive-icon {
                        color:#C57700;
                    i{
                        font-size:4vmin;
            }
        }

                    .delete-icon {
                        color:#D30808;
                    i{
                        font-size:4vmin;
            }
        }

                    .restore-edit {
                        color:#0C8292;
                    i{
                        font-size:3vmin;
            } 
        }

                    .active-course{
                        font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #218749;
                    i{
                        font-size:20px;
            }    
        }
                    .draft-course{
                        font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #6468C8;
                    i{
                        font-size:20px;
            }    
        }
                    .archive-course{
                        font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #C57700;
                    i{
                        font-size:20px;
            }    
        }
    }
}
`