import axios from 'axios';
import { getToken } from "../router/TokenUtils"

const query = new URLSearchParams(window.location.search).get(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN));

const AccountInterface = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNT_URL + "/api/v1",
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        "Content-Type": "application/json",
        "accept": "/"
    }
})

AccountInterface.interceptors.request.use(function (config) {
    var token = getToken();

    if (token === undefined) {
        config.headers.Authorization = `Bearer ${query}`
    } else {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config

}, function (error) {
    return Promise.reject(error);
});

AccountInterface.interceptors.response.use(
    function (config) {
        return config
    },
    function (error: any) {
        if (error.toString() === "Error: Network Error") {
            return null;
        }

        if (error.response.status === 401) {
            console.log("usuario nao autorizado a acessar o sistema cursos livres");
        }
        if (error.response.status === 404) {
            console.log("Houve um erro, favor tentar novamente mais tarde");
        }
        return error;
    });

export default AccountInterface;