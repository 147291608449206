import styled from 'styled-components';
import { Card, Col, Container } from 'react-bootstrap';


export const ButtonCardTask = styled(Card)`
    height: 7.77vmin;
    background: #FFFFFF;
    border: 0.14vmin solid #E8E8E8;
    box-sizing: border-box;
    box-shadow: 0px 0.14vmin 0.28vmin rgba(75, 75, 75, 0.15);
    border-radius: 1.11vmin;
    margin: 1vmin;
    padding-left: 3vmin;

    .row
    {
        height: 100%;
    }
    .card-body
    {
        padding:0px;

        h4
        {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 1.94vmin;
            line-height: 20px;
            margin-bottom:0px;
        }
        .inProgressStatus{
            h4{
                color:#6468C8;
            }
            i{
                color:#6468C8;
            }
        }
        .finished{
            h4{
                color:#218749;
            }
            i{
                color:#218749;
            }
        }
        .progressPercentage{
            h4{
                color:#777575;
            }
        }
        .progress{
            max-width: 31.5vmin;
            background: #C4C4C4;
            border-radius: 1.11vmin;
            height: 1.5vmin;
        }
        .bg-success{
            border-radius: 1.11vmin;
        }
        button{
            width:100%;
            p{
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 2.22vmin;
                line-height: 2.63vmin;
            }
        }
        i
        {
            font-size: 3.33vmin;
        }
    }
`


export const BodyLearning = styled(Container)`
    margin-top: 12.88vmin;
    text-align: center;

    .subTitle{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.77vmin;
        color: #514F4E;
        text-align: left;
    }
    h2{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.77vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }
    h3{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #514F4E;;
    }
    h4{
        text-align: left;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }

    img{
        position: absolute;
        left: 25.94%;
        right: 58.75%;
        top: 23.47%;
        bottom: 43.09%;
        left: 259px;
        top: 92px;
    }

    @media screen and (max-width: 990px){
        padding: 0 !important;

        /* .subTitle {
            border: solid 1px blue;
            font-size: 5vmin;
        } */

        .title-default-mob {
            margin: 25vmin 0vmin 35px 5vmin;
            font-size: 7vmin;
            color: #6468C8;
            text-align: left;
        }

        h2 {
            font-size: 6.2vmin;
        }

        h3 {
            margin-top: 4vmin;
            font-size: 5vmin;
            line-height: 7vmin;
        }
    }
`


export const AttendCourseLessonNavBar = styled(Col)`
    z-index:100;
    max-width:80vw;
    .nav-link{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        background-color:#FFFFFF!important;
        color: #636060!important;
    }
    .nav-link.active{
        color: #514F4E!important;
        border-bottom:4px solid #EA694C;
    }

    @media screen and (max-width: 990px){
        max-width:95% !important;
        padding: 0px 12px !important;
        margin-left: 0 !important;
        z-index:0;
        /* border: solid 1px blue; */

        .nav-link {
            font-size: 4.7vmin;
        }

        .nav-item {
            float: left !important;
            width: 50% !important;
            margin: 0 !important;
        }
    }
`