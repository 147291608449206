import {useState, useEffect, forwardRef, useImperativeHandle, useContext, useRef, isValidElement } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { PrimaryGreenButton, SecondaryGreenButton, TertiaryYellowButton } from '../../../../../components/Buttons/styles';
import styled from 'styled-components'

import { FileUploadServices } from '../../../../../services/FileUploadServices'
import { GetYoutubeId } from '../../../../../util/YoutubeUtils';
import { GetVimeoId } from '../../../../../util/VimeoUtils';
import { CreateCourseContext } from '../../../../../context/creator/createCourse/CreateCourseContext';
import { CourseViewModel } from '../../../../../domain/models/course/CourseViewModel';
import {CreateCourseIntroductionFormContext} from '../../../../../context/creator/createCourse/CreateCourseIntroductionFormContext'
import { AddFileNavigationPositionEnum } from '../../../../../domain/enum/AddFileNavigationPosition';





interface AddVideoModalProps{
    setVideoLink: (url:string, name:string) => void
}
const uploadService = new FileUploadServices();

export const AddVideoModal = (props:AddVideoModalProps)=>{
    const { 
        showAddVideoModal, 
        setShowAddVideoModal,
        
        showConfirmExitAddVideo,
        setShowConfirmExitAddVideo,

        isInvalidVideoInput,
        setIsInvalidVideoInput,

        navigationPosition,
        setNavigationPosition,

        videoObject,
        setVideoObject
    } = useContext(CreateCourseIntroductionFormContext);

    const [isUploading, setIsUploading] = useState(false);

    const [reload, callReload] = useState(false);
    const [errorMessage,setErrorMessage] = useState("")

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const onInputFileChange = (e) => {
        setIsUploading(true);
        setIsInvalidVideoInput(false)


        const fileUploaded = e.target.files[0]

        if ((fileUploaded.type === "video/mp4" && (fileUploaded.size/1024/1024) < 100)) {

            const formData = new FormData();
            formData.append('File', fileUploaded);
            formData.append('ValidatedFormat', "true");

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data, image/*, video/*'
                }
            }

            uploadService.uploadTempContainer(formData, config).then(function (response) {
                if (response.success) {

                    var videoDetails = videoObject;
                    videoDetails.storageLink = response.result.path
                    videoDetails.videoName = response.result.name

                    setVideoObject(videoDetails)
                    setIsUploading(false)

                }
            }).catch(err => {
                setErrorMessage("Não conseguimos carregar seu arquivo, tente novamente")
                setIsInvalidVideoInput(true)
                console.log(err);
            });

            //setUploadMessage(fileUploaded.name)

        }

        if ((fileUploaded.size/1024/1024) > 100) {
            setErrorMessage("O arquivo é muito grande, escolha um arquivo com até 100MB")
            setIsInvalidVideoInput(true)
            setIsUploading(false)

        }

        
        if (!(fileUploaded.type === "video/mp4" )) {
            setErrorMessage("Formato não suportado")
            setIsInvalidVideoInput(true)
            setIsUploading(false)
        }

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
        }
    }

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
            hiddenFileInput.current.click();
        }
    }
    
    const ReturnModalTitle = () =>{
        if(navigationPosition === AddFileNavigationPositionEnum.index){
            return(
                <Modal.Title>
                    Vídeo da introdução
                </Modal.Title>
            )
        }
        if(navigationPosition === AddFileNavigationPositionEnum.sendFile){
            return(
                <Modal.Title>
                    <span>Vídeo da introdução {">"}</span> Enviar Arquivo
                </Modal.Title>
            )
        }
        if(navigationPosition === AddFileNavigationPositionEnum.sendLink){
            return(
                <Modal.Title>
                    <span>Vídeo da introdução {">"}</span> Enviar da internet
                </Modal.Title>
            )
        }
    }

    const onClose = ()=>{
        if(videoObject.storageLink === ""){
            setShowAddVideoModal(false);
            setTimeout(() => {
                setVideoObject(
                    {
                        externalLink:"",
                        videoName:"",
                        storageLink:""
                    }
                )
                setNavigationPosition(AddFileNavigationPositionEnum.index)
                setIsInvalidVideoInput(false) 
            }, 200);
        }
        else{
            setShowConfirmExitAddVideo(true)
            setShowAddVideoModal(false);
            callReload(!reload)
        }
    }



    const cancelUpload = ()=>{
        setIsUploading(false)
    }

    const handleVideoLinkChange = (e) => {
        let properties = videoObject;
        properties.externalLink = e.target.value

        setIsInvalidVideoInput(!isVideoValid)
        setErrorMessage("Este link não é válido, confira ou tente outro link")
        setVideoObject(properties)
        callReload(!reload)
    }

    const isVideoValid = () =>{
        //É válido se tive o nome do video e o link do storage
        //ou se o link externo for diferente de vazio e o GetId ou do vimeo ou do Youtube for válido

        return (videoObject.videoName !== ""&&videoObject.storageLink !== "")
                ||
                (videoObject.externalLink !== ""&&
                (GetVimeoId(videoObject.externalLink)!== ""||GetYoutubeId(videoObject.externalLink)!== ""))
    }

    const onSubmit = () =>{
        if(navigationPosition === AddFileNavigationPositionEnum.sendLink){
            props.setVideoLink(videoObject.externalLink,"")
        }else{
            props.setVideoLink(videoObject.storageLink,videoObject.videoName)
        }

        setNavigationPosition(AddFileNavigationPositionEnum.index)
        setVideoObject({
            externalLink:"",
            videoName:"",
            storageLink:""
        })
        setShowAddVideoModal(false)
        callReload(!reload)
    }

    const returnVideoUrlSRC = () =>{
        if(GetYoutubeId(videoObject.externalLink)!== ""){
            return "https://www.youtube.com/embed/"+GetYoutubeId(videoObject.externalLink)
    
        }
        return "https://player.vimeo.com/video/" + GetVimeoId(videoObject.externalLink)

    }

    const ReturnModalFooterButtons = ()=>{
        if(navigationPosition !== AddFileNavigationPositionEnum.index&&!isUploading){
            return(
                <Container>
                <Row className="mb-2">
                    {
                        navigationPosition === AddFileNavigationPositionEnum.sendFile?
                        (
                            <Col md="auto">
                                <SecondaryGreenButton onClick={handleAddFileButton} className="py-2 px-3">
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0">
                                            <i className='bi bi-arrow-repeat'/>
                                        </Col>
                                        <Col className="my-auto">
                                            <p>
                                                Alterar Arquivo
                                            </p>
                                        </Col>
                                    </Row>
                                </SecondaryGreenButton>
                            </Col>
                        )
                        :
                        (
                            <></>
                        )
                    }

                    <Col md="auto" className="ml-auto">
                        {/*disabled se NÃO for valido*/}
                        <PrimaryGreenButton className="py-2 px-3" disabled={!isVideoValid()} onClick={()=>onSubmit()}>
                            <Row>
                                <Col className="my-auto">
                                    <p>
                                        Enviar Video
                                    </p>
                                </Col>
                                <Col md="auto" className="my-auto pl-0">
                                    <i className="bi bi-check"/>
                                </Col>
                            </Row>
                        </PrimaryGreenButton>
                    </Col>
                </Row>
            </Container>
            )
        }

        return <></>
    }

    const ReturnModalBody = () =>{
        if(isUploading){
            return(
                <>
                    <Row className="mb-2 mt-5">
                        <Col md="auto" className="mx-auto mt-5">
                            <img alt="" src='/img/student/home/woman-meditating.svg'/>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md="auto" className="mx-auto">
                            <p>
                                Estamos carregando seu arquivo...
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <TertiaryYellowButton onClick={()=>cancelUpload()}>
                                <Row>
                                    <Col className="my-auto pr-0">
                                        <i className="bi bi-x"/>

                                    </Col>
                                    <Col md="auto" className="my-auto">
                                        <p>
                                            Cancelar
                                        </p>
                                    </Col>
                                </Row>
                            </TertiaryYellowButton>
                        </Col>
                    </Row>
                </>
            )
        }
        if(navigationPosition === AddFileNavigationPositionEnum.sendLink){
            return(
                <>
                    <Row className='mb-2'>
                        <Col>
                            <p>
                                Link do vídeo
                            </p>
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col>
                            <Form.Control 
                                className='w-100'
                                placeholder='Insira o link do vídeo'
                                onChange={(e)=>handleVideoLinkChange(e)}
                            />
                        </Col>
                    </Row>
                    {
                        isInvalidVideoInput?
                        (
                            <Row className='mb-3'>
                                <Col  className="erro-message mx-auto py-2">
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0">
                                            <i className='bi bi-exclamation-octagon'/>
                                        </Col>
                                        <Col className="my-auto">
                                            <p>
                                            {errorMessage}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <Row>
                                <Col>
                                    <p>
                                        Você pode adicionar vídeos das plataformas YouTube e Vimeo.
                                    </p>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        (!isInvalidVideoInput)&&videoObject.externalLink !== ""?
                        (
                            <Row>
                                <Col md="auto" className='mx-auto mt-4'>
                                    <iframe src={returnVideoUrlSRC()}/>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <></>
                        )
                    }

                </>
            )
        }
        if(navigationPosition === AddFileNavigationPositionEnum.sendFile){
            return(
                <>
                {
                    videoObject.storageLink === "" || videoObject.videoName === ""?
                    (
                        <>
                            {
                                isInvalidVideoInput?
                                (
                                    <Row className='mb-3'>
                                        <Col  className="erro-message mx-auto py-2">
                                            <Row>
                                                <Col md="auto" className="my-auto pr-0">
                                                    <i className='bi bi-exclamation-octagon'/>
                                                </Col>
                                                <Col className="my-auto">
                                                    <p>
                                                    {errorMessage}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                                :
                                (
                                    <></>
                                )
                            }

                            <Row>
                                <SecondaryGreenButton className="w-100" onClick={handleAddFileButton}>
                                    <Row className='my-1'>
                                        <Col md="auto" className="ml-auto my-auto pr-0">
                                            <i className="bi bi-files"/>
                                        </Col>
                                        <Col md={7} className="text-left">
                                            <p>
                                                Escolher Arquivo
                                            </p>
                                        </Col>
                                    </Row>
                                </SecondaryGreenButton>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <p>
                                    São aceitos arquivos no formato .mp4, com tamanho de até 100MB.
                                    </p>
                                </Col>
                            </Row>

                        </>
                    )
                    :
                    (
                        <>
                            {
                                isInvalidVideoInput?
                                (
                                    <Row className='mb-3'>
                                        <Col  className="erro-message mx-auto py-2">
                                            <Row>
                                                <Col md="auto" className="my-auto pr-0">
                                                    <i className='bi bi-exclamation-octagon'/>
                                                </Col>
                                                <Col className="my-auto">
                                                    <p>
                                                    {errorMessage}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                                :
                                (
                                    <></>
                                )
                            }

                            <Row className="mt-3 mb-4">
                                <Col className='my-auto ml-5'>
                                    <p>
                                        {videoObject.videoName}
                                    </p>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col md="auto" className='mx-auto mt-4'>
                                    <video controls src={videoObject.storageLink}>
                                    </video>  
                                </Col>
                            </Row>
                        </>
                    )
                }
                </>
            )
        }
        return(
            <>
                <Row>
                    <Col>
                        <p>
                            Como você quer enviar seu vídeo?
                        </p>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col>
                        <Button className='w-100 add-content px-3' onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.sendFile)}>
                            <Row className="mb-2">
                                <Col md="auto" className="my-auto pr-0">
                                    <i className="bi bi-upload"/>
                                </Col>
                                <Col className="my-auto">
                                    <h3>
                                        Enviar arquivo do meu computador
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        Escolha um arquivo do seu computador.
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col>
                        <Button className='w-100 add-content px-3' onClick={() => setNavigationPosition(AddFileNavigationPositionEnum.sendLink)}>
                            <Row className="mb-2">
                                <Col md="auto" className="my-auto pr-0">
                                    <i className="bi bi-link-45deg"/>
                                </Col>
                                <Col className="my-auto">
                                    <h3>
                                        Enviar um vídeo da internet
                                    </h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        Você pode adicionar vídeos das plataformas YouTube ou Vimeo.
                                    </p>
                                </Col>
                            </Row>
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    return(
        <AddVideoModalStyles 
            show={showAddVideoModal} 
            onHide={()=>onClose()}
            size="lg"
            >
            {
                isUploading?
                (
                    <></>
                )
                :
                (
                    <Modal.Header closeButton className="mt-4 px-4">
                    {
                        ReturnModalTitle()
                    }
                    </Modal.Header>
                )
            }
            <Modal.Body>
                <Container className="px-4">
                    {
                        ReturnModalBody()
                    }
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={onInputFileChange}
                        className="d-none"
                        multiple={false}
                        accept=".mp4"
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {
                    ReturnModalFooterButtons()
                }
            </Modal.Footer>
        </AddVideoModalStyles>
    )
}



const AddVideoModalStyles  = styled(Modal)`

    .modal-content{
        min-height:88vh;
        border-radius:1.11vmin;
        border:none;
    }
    .modal-header, .modal-footer{
        border:none;
    }
    .modal-header{
        .close{
            padding-top:1.2vmin;
            span{
                font-size:5vmin;
                font-weight: 400;
            }
        }
    }
    .modal-footer{
        button{
            p{
                font-size:2.22vmin;
            }
            .bi-check{
                font-size:3vmin;
            }
            .bi-arrow-repeat{
                font-size:2.8vmin;
            }
        }
    }
    .modal-title{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 3.33vmin;
        line-height: 3.89vmin;
        color: #514F4E;
        span{
            color: #777575;
        }
    }
    .modal-content{
        input{
            min-height: 6.66vmin;
            border: 0.14vmin solid #B9B8B8;
            box-sizing: border-box;
            border-radius: 1.11vmin;
            &:focus{
                border: 0.14vmin solid #A996F6;
                box-sizing: border-box;
                box-shadow: 0px 0px 0.56vmin #A996F6;
            }
            ::placeholder{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 2.22vmin;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #777575;
            }
        }
        iframe{
            border:none;
            width:60vmin;
            height:40vmin;

        }
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #636060;
            margin-bottom:0px;
        }
        .erro-message{
            background: #F9F9F9;
            border: 0.14vmin solid #F9DADA;
            box-sizing: border-box;
            border-radius: 0.56vmin;
            .bi-exclamation-octagon{
                font-size:3.3vmin;
                color:#D30808;
            }
        }
        .add-content{
            text-align:left;
            width:21.6vmin;
            height:14.44vmin; 
            box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2);
            border: 0.14vmin solid #F9F9F9;
            border-radius: 1.11vmin;
            background-color: #FFF;
            i{
                font-size:3.33vmin;
                color:#0C8292;
            }
            h3{
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 2.83vmin;
                line-height: 3.33vmin;
                color: #0C8292;
                margin-bottom:0px;
            }
            &:hover{
                background-color: #E1F4F7;
                border: 0.14vmin solid #F9F9F9;
            }
            &:focus, &:active{
                background-color: #d1f3f8!important;
                box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2)!important;
                border: none!important;
            }
            &:focus:after, &:active:after{
                content: '';
                position: absolute;
                top: -0.5vmin;
                left: 1.2vmin;
                bottom: -0.5vmin;
                right: 1.2vmin;
                border: 0.20vmin solid #0B717E!important;
                border-radius: 1.11vmin;
            }
        }
        img{
            height:30vmin; 
        }
        button{
            .bi-x{
                font-size: 3vmin;
            }
            p{
                font-size: 2.22vmin;

            }
        }
        video{
            border:none;
            max-width:80vmin;
            max-height:50vmin;
        }
    }
`