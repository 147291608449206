import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { PrimaryPurpleButton } from "../../../../components/Buttons/styles";
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
import { TaskContext } from "../../../../context/apprentice/attend/TaskContext";
import { ApprenticeProgressStatusEnum } from "../../../../domain/enum/ApprenticeProgressStatusEnum";
import { AttendTaskComponentStyles } from "../AttentCoursePageStyles";
import { DeliveryTaskModal } from "./modal/DeliveryTaskModal";
import { TaskStatusComponent } from "./TaskStatusComponent";

const AttendTaskComponent = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);

    const {
        setShowModal
    } = useContext(TaskContext);

    return (
        <AttendTaskComponentStyles>
            <Row>
                <Col>
                    <h3>Tarefa</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>
                        {currentUserModuleContentProgress.title}
                    </h1>
                </Col>
            </Row>
            <TaskStatusComponent />
            <Row>
                <Col>
                    <h2>Orientações</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <pre>{currentUserModuleContentProgress.moduleTask!.orientation}</pre>
                    </p>
                </Col>
            </Row>
            {
                (currentUserModuleContentProgress.progressStatusId !== ApprenticeProgressStatusEnum.Sent &&
                    currentUserModuleContentProgress.progressStatusId !== ApprenticeProgressStatusEnum.Done) && (
                    <>
                        <Row className="mt-3">
                            <Col>
                                <h2>Entrega</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <PrimaryPurpleButton className="delivery-task-button d-none d-md-block" onClick={() => setShowModal(true)}>
                                    <Row>
                                        <Col md="auto">
                                            <i className="bi bi-upload" />
                                        </Col>
                                        <Col md="auto">
                                            <p>Enviar Tarefa</p>
                                        </Col>
                                    </Row>
                                </PrimaryPurpleButton>
                                <PrimaryPurpleButton className="btn-task-mobile d-block d-md-none" onClick={() => setShowModal(true)}>
                                    <i className="bi bi-upload" />
                                    Enviar Tarefa
                                </PrimaryPurpleButton>
                                <section style={{ marginBottom: '130px' }}></section>
                            </Col>
                        </Row>
                    </>
                )
            }

            <DeliveryTaskModal />
        </AttendTaskComponentStyles>
    )
}

export default AttendTaskComponent;
