import { LogUserActivityTypeReportEnum } from "../domain/enum/LogUserActivityTypeReportEnum";
import ApiInterface from "../interface/ApiInterface"

export default class LogService {

    public async getUserActivities(institutionId: string, typeReport: LogUserActivityTypeReportEnum) {
        
        return await ApiInterface.get('/log/GetUserActivities'
            .concat('?institutionId=').concat(institutionId)
            .concat('&typeReport=').concat(typeReport.toString())
        ).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async logUserActivity(token: string) {

        return await ApiInterface.post('/log/LogUserActivity', null, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}
