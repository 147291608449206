import { useEffect, useState } from "react"
import styled from 'styled-components'
import { getCurrentTime } from "../../util/Formatter"
import { Container, Nav, NavDropdown, Row, Col, Card, Button } from "react-bootstrap"
import Navbar from 'react-bootstrap/Navbar';
import HomeHeader from "../../components/Headers/HomeHeader"
import ControlPanelSideMenu from "../../components/SideMenu/ControlPanelSideMenu"
import ReportByCourseListComponent from "../../components/PaginatedSearch/ReportByCourseListComponent"
import ReportByApprenticeListComponent from "../../components/PaginatedSearch/ReportByApprenticeListComponent"
import ControlPanelActiveButtonEnum from "../../domain/enum/ControlPanelActiveButtonEnum"
import ConsumptionByCourseViewModel from '../../domain/models/report/ConsumptionByCourseViewModel'
import ConsumptionByApprenticeViewModel from '../../domain/models/report/ConsumptionByApprenticeViewModel'
import CreatorHeaderActiveTabEnum from "../../domain/enum/CreatorHeaderActiveTabEnum"
import PaginatedSearchResult from "../../domain/util/PaginatedSearchResult"
import Select from 'react-select';

import ICreatableSelect from "../../domain/util/ICreatableSelect"
import { isAdminGlobal, getInstitutionId } from '../../util/UserUtil';
import PageLoader from "../../components/Common/Loader"

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

import ReportServices from '../../services/ReportServices'
import AccountServices from '../../services/AccountServices'
import LogService from '../../services/LogService'
import { LogUserActivityTypeReportEnum } from "../../domain/enum/LogUserActivityTypeReportEnum"
import ReportTabEnum from "../../domain/enum/ReportTabEnum"
import { useRouteMatch } from "react-router";
import ChangeLessonLoader from "../../components/Loaders/ChangeLessonLoader";
import ReportsComponent from "./reports/ReportsComponent";
import CampaignsComponent from "./campaigns/CampaignsComponent";

const ControlPanelPage = () => {

    let match = useRouteMatch<any>("/controlpanel/:page?");
    let selectedPage = match!.params.page!

    useEffect(() => {
        pageLoad()
    }, [])

    function pageLoad() {
        console.log(selectedPage)
        switch (selectedPage) {
            case 'reports':
                setActivePage(ControlPanelActiveButtonEnum.reports);
                break;
            case 'campaigns':
                setActivePage(ControlPanelActiveButtonEnum.campaigns);
                break;
            default:
                setActivePage(ControlPanelActiveButtonEnum.reports);
                break;
        }
    }

    const [activePage, setActivePage] = useState<ControlPanelActiveButtonEnum>(ControlPanelActiveButtonEnum.reports)
    const [isLoading, setIsLoading] = useState(true);
    return (
        <>
            <HomeHeader activeTab={CreatorHeaderActiveTabEnum.controlPanel} />
            <ControlPanelPageStyles fluid={true}>
                <Row>
                    <ControlPanelSideMenu
                        activeButton={activePage}
                        setActivePage={setActivePage}
                        setIsLoading={setIsLoading}
                    />


                    {
                        activePage === ControlPanelActiveButtonEnum.reports &&
                        (
                            <>
                                <ReportsComponent />
                            </>
                        )
                    }

                    {
                        activePage === ControlPanelActiveButtonEnum.campaigns &&
                        (
                            <CampaignsComponent />
                        )
                    }


                </Row>
            </ControlPanelPageStyles>
        </>
    )


}

export default ControlPanelPage

export const ControlPanelPageStyles = styled(Container)`
    margin-top:8.7vmin;

    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.3vmin;
        color: #514F4E;
    }

    .row.tab-buttons{
        position:sticky;
        position: -webkit-sticky;
        top:8.7vmin;
        z-index:10;
        background-color:#fff;

        button{
            text-align:left;
            background-color:#fff!important;
            border:none;
            box-shadow:none;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2vmin;
            line-height: 24px;
            color: #514F4E!important;
            :active{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
            :focus{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
        }
        button.active{
            border-bottom: 4px solid #EA694C!important;
        }
    }

`

export const InstitutionStyles = styled(Row)`
    width: 100%;
    height: 15.0vmin;
    padding: 5vmin;
    margin:0;
    background: #f7f7f799;
    box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
    border-radius: 8px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.94vmin;
        line-height: 2.77vmin;
        color: #6F6F6F;
    }

    i {
        font-size: 3vmin !important;
        color: #0C8292;
        cursor: pointer;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.94vmin;
        line-height: 2.77vmin;
        color: #6F6F6F;
    }

`

export const StyledSingleSelect = styled(Select)`

    input[type="text"]
    {
        background: #FBFBFF 0% 0% no-repeat padding-box !important;
        border: none!important;
        opacity: 1 !important;
    }

    input[type="text"]:focus
    {
        box-shadow: none!important;
        border: none!important;
        opacity: 1 !important;
    }

    .react-select__control
    {
        border-color: #b3b5df!important;
        border-radius:8px;
    }

    .react-select__control--is-focused
    {
        box-shadow: 0px 0px 4px #6468c8 !important;
        border: 1px solid #6468C8 !important;
    }
    
    .react-select__single-value {
        font-size: 1.8vmin;
        line-height: 2.8vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .react-select__option {
        background: #fff 0% 0% no-repeat padding-box!important;
        text-align: left;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #514F4E;
        opacity: 1;
    }

    .react-select__option:hover {
        background: #8487e0 0% 0% no-repeat padding-box!important;
        color: #FFF;
    }
`

export const CardSectionStyles = styled(Col)`
   
    height: auto;
    margin-top: 1.5vmin;
    margin-left: 3vmin;
    margin-right: 3vmin;
    background: #FFFFFF;
    box-shadow: 2px 2px 6px 2px rgba(185, 184, 184, 0.2);
    border-radius: 10px;

    h1 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #636060;
        padding: 15px;
    }

    .half-a-border-on-bottom {
      position: relative;
    }
    .half-a-border-on-bottom:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0%;
        border-bottom: 1px solid #dddbdb;
    }

    p.user_label
    {
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2vmin;
        line-height: 150%;
        color: #8A92A6;
    }

    p.total_users
    {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.4vmin;
        line-height: 100%;
        color: #232D42;
    }

    p.user_counter
    {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2vmin;        
        color: #232D42;
    }

`

export const Teste = styled(ResponsiveContainer)`
    width: 95%;
    height: 95%;
`