import { createContext, ReactNode, useContext } from "react";
import { CreateCourseContext } from "./CreateCourseContext";
import TaskViewModel from "../../../domain/models/course/module/task/TaskViewModel";
import { AddModuleContext } from "./AddModuleContext";

const CreateTaskModuleContentContext = createContext({} as CreateTaskModuleContentContextData);
interface CreateTaskModuleContentContextProviderProps {
    children: ReactNode;
}

export function CreateTaskModuleContentContextProvider(props: CreateTaskModuleContentContextProviderProps) {

    const {
        setIsChanged, reRender
    } = useContext(CreateCourseContext);

    const {
        getSelectedModuleContent, setIsValidModuleContent, validateModuleContent } = useContext(AddModuleContext);

    const moduleContent = getSelectedModuleContent();

    const handleIsTaskRequired = () => {
        moduleContent.isRequired = !moduleContent.isRequired;
        reRender();
        setIsChanged(true);
    };

    const handleTaskOrientationChange = (e: any) => {
        const task = moduleContent.moduleTask!;

        let newTask: TaskViewModel = {
            id: task.id,
            moduleContentId: task.moduleContentId,
            orientation: e.target.value
        };

        moduleContent.moduleTask = newTask;
        setIsValidModuleContent(validateModuleContent(moduleContent));
        reRender();
        setIsChanged(true);

    };
    return (
        <>
            <CreateTaskModuleContentContext.Provider value={{
                handleIsTaskRequired,
                handleTaskOrientationChange,
            }}>
                {props.children}
            </CreateTaskModuleContentContext.Provider>
        </>
    );
}

export function useCreateTaskModuleContentContext() {
    return useContext(CreateTaskModuleContentContext);
}
interface CreateTaskModuleContentContextData {
    handleIsTaskRequired: () => void;
    handleTaskOrientationChange: (e: any) => void;
}
