import { useContext } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { AddVideoModalContext } from '../context/AddVideoModalContext';

export function SendLinkScreen() {
    const {
        isInvalidVideoInput, handleVideoLinkChange, errorMessage, videoObject, returnVideoUrlSRC
    } = useContext(AddVideoModalContext);
    return <>
        <Row className='mb-2'>
            <Col>
                <p>
                    Link do vídeo
                </p>
            </Col>
        </Row>
        <Row className='mb-4'>
            <Col>
                <Form.Control
                    className='w-100'
                    placeholder='Insira o link do vídeo'
                    onChange={(e) => handleVideoLinkChange(e)} />
            </Col>
        </Row>
        {isInvalidVideoInput ?
            (
                <Row className='mb-3'>
                    <Col className="erro-message mx-auto py-2">
                        <Row>
                            <Col md="auto" className="my-auto pr-0">
                                <i className='bi bi-exclamation-octagon' />
                            </Col>
                            <Col className="my-auto">
                                <p>
                                    {errorMessage}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
            :
            (
                <Row>
                    <Col>
                        <p>
                            Você pode adicionar vídeos das plataformas YouTube e Vimeo.
                        </p>
                    </Col>
                </Row>
            )}
        {(!isInvalidVideoInput) && videoObject.VideoLink !== "" ?
            (
                <Row>
                    <Col md="auto" className='mx-auto mt-4'>
                        <iframe src={returnVideoUrlSRC()} />
                    </Col>
                </Row>
            )
            :
            (
                <></>
            )}

    </>;
}
