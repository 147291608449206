import Loader from "react-loader-spinner";
import { Col, Container, Row } from "react-bootstrap"
import styled from 'styled-components';

const RespondingAssessment = (props:any) => {
    
    return(
        <Container fluid={true}>
            <PreparingCourseComponentStyle>
                <Col className="my-auto">
                    <Row>
                        <Col md={"auto"} className="mx-auto">
                            <img className="img-loader" src={"/img/attendCourse/assessment/reviewing-woman.svg"}/>
                        </Col>  
                    </Row>
                    <Row className="mt-3"> 
                        <Col md={"auto"} className="ml-auto">
                            <PreparingLoader
                                type="TailSpin"
                                color="#EA694C"
                            />
                        </Col>  
                        <Col md={"auto"} className="mr-auto">
                            <PreparingHeader>
                                Corrigindo avaliação...
                            </PreparingHeader>
                        </Col>
                    </Row>
                </Col>
            </PreparingCourseComponentStyle>
        </Container>
    )
}

export default RespondingAssessment;

const PreparingCourseComponentStyle = styled(Row)`
    min-height:80vh;

    .img-loader
    {
        width: 50vmin;
        height: 45vmin;
    }
`

const PreparingHeader = styled.h2`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 3.33vmin;
    line-height: 3.9vmin;
    display: flex;
    align-items: center;
    color: #514F4E;
`
const PreparingLoader = styled(Loader)`
    svg{
        width:3.33vmin;
        height:3.33vmin;
    }
`