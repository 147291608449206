import React from 'react';
import ReactDOM from 'react-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './assets/css/styles.css'
import 'suneditor/dist/css/suneditor.min.css'; 


import BrainzRouter from './router/BrainzRouter';
import reportWebVitals from './reportWebVitals';
import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from "react-ga4";
import Unavaliable from './pages/mobile/Unavaliable'

const checkIfIsMobile = () => {
    if ((window.innerHeight > window.innerWidth) || window.innerWidth < 1000) {
        return true
    } else {
        return false
    }
}

ReactDOM.render(
    <React.StrictMode>
        {/* {checkIfIsMobile()
            ? (<Unavaliable />)
            : (<BrainzRouter />)
        } */}
        <BrainzRouter />
    </React.StrictMode>,
    document.getElementById('root')
);
const htmlTag:HTMLElement = document.getElementsByTagName("html")[0];
htmlTag.translate = false;  

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_METRIC_ID!);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
