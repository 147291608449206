import axios from 'axios';
import AccountInterface from './AccountInterface';
import jwt_decode from "jwt-decode";
import { BrainzAuthToken } from '../domain/BrainzAuthToken';
import {  getToken, setToken, removeToken  } from '../router/TokenUtils'

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const ApiInterface = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api/v1",
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        "Content-Type": "application/json",
        "accept": "/"
    }
})

ApiInterface.interceptors.request.use(
    function (config) {

        // here we just get the existing token in the cookie
        // whether it's expired or not.
        //config.headers.Authorization = "Bearer " + getCodedToken();
        //return config;

        // refresh token every single request to the server.
        // is this a good approach???
        let decoded: BrainzAuthToken
        let token = getToken();

        if (token === undefined) {
            window.location.href = process.env.REACT_APP_ACCOUNT_REDIRECT!;
        }
        else {
            decoded = jwt_decode(token);
            cookies.set(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), token, { domain: String(process.env.REACT_APP_BRAINZ_DOMAIN), path: "/", secure: true });
            const currentDate = new Date();
            let remainingSeconds = decoded.exp - (currentDate.getTime() / 1000)
            if (((remainingSeconds / 60) < 30)) {
                if (remainingSeconds > 0) {
                    var uriRefreshToken = "/Account/Authorization/" + process.env["REACT_APP_APPLICATION_ID"] + "/RefreshToken";
                    AccountInterface.get(uriRefreshToken).then((response) => {
                        token = response.data.result.token;
                        setToken(token);
                    })
                }
                else {
                    removeToken();
                }
            }

            config.headers.Authorization = "Bearer " + token;
        }
        return config;

    }, function (error) {
        return Promise.reject(error);
    }
);

ApiInterface.interceptors.response.use(
    function (config) {
        return config
    },
    function (error: any) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log("Não autorizado")
            }
            if (error.response.status === 404) {
                console.log("Houve um erro, favor tentar novamente mais tarde")
            }
            if (error.response.status === 500) {
                console.log("Erro no servidor")
            }
        }
        return error;
    }
);

export default ApiInterface;