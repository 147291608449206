import { useContext } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { PrimaryPurpleButton, SecondaryYellowButton } from '../Buttons/styles';
import { ConfirmActionModalStyles } from './styles';
import { AddModuleContext } from '../../context/creator/createCourse/AddModuleContext';
import { useCreateAssessementModuleContentContext } from '../../context/creator/createCourse/CreateAssessementModuleContentContext';


export function ConfirmQuestionModal() {
    const {
        selectedQuestionIndex, 
        deleteQuestion, 
        handleCancelDeleteQuestion, 
        showConfirmDeleteQuestionModal
    } = useCreateAssessementModuleContentContext();

    return (
        <ConfirmActionModalStyles show={showConfirmDeleteQuestionModal}>
            <Modal.Body>
                <Row className="my-3" style={{ placeContent: 'center' }}>
                    <i className="bi bi-check" style={{ fontSize: 100, color: '#C57700' }}></i>
                </Row>
                <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                    <Col>
                        <h1>
                            Deseja excluir esta pergunta?
                        </h1>
                    </Col>
                </Row>
                <Row className="my-2" style={{ textAlign: 'center' }}>
                    <Col>
                        Você vai perder todas as informações inseridas.
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer as={Row}>
                <Col>
                    <SecondaryYellowButton onClick={() => deleteQuestion(selectedQuestionIndex)}>
                        <Row>
                            <Col md="auto" className="ml-auto pr-0 my-auto">
                                <i className='bi bi-trash' />
                            </Col>
                            <Col md="auto" className="ml-0 mr-auto my-auto">
                                <p>
                                    Excluir pergunta
                                </p>
                            </Col>
                        </Row>
                    </SecondaryYellowButton>
                </Col>
                <Col>
                    <PrimaryPurpleButton onClick={() => handleCancelDeleteQuestion()}>
                        <Row>
                            <Col md="auto" className="mx-auto">
                                <p>
                                    Manter pergunta
                                </p>
                            </Col>
                        </Row>
                    </PrimaryPurpleButton>
                </Col>
            </Modal.Footer>
        </ConfirmActionModalStyles>
    );
}
