import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";

const TaskNotSentComponent = () => {
    const {
        currentUserModuleContentProgress,
    } = useContext(AttendCourseContext);

    return (
        <Row className="task-not-sent">
            <Col xs={1} md="auto" className="pr-0">
                <i className="bi bi-dash-circle" />
            </Col>
            <Col xs={11} md="auto">
                <p>
                    Você ainda não enviou a tarefa
                </p>
            </Col>
            {currentUserModuleContentProgress.isRequired &&
                (
                    <Col md={12}>
                        <p className="task-required">
                            Você deve enviar esta tarefa para completar o curso.
                        </p>
                    </Col>
                )}
        </Row>
    );
};
export default TaskNotSentComponent
