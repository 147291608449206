import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import CourseInfo       from './CourseInfo/CourseInfo';
import CourseDetailView from '../../../domain/models/course/CourseDetailView';
import {  
    Row,
    Col,
    Container,
} from 'react-bootstrap';

import PageLoader from '../../../components/Common/Loader';
import HomeHeader from '../../../components/Headers/HomeHeader';
import CreatorHeaderActiveTabEnum from '../../../domain/enum/CreatorHeaderActiveTabEnum';
import styled from 'styled-components'

import CourseService  from '../../../services/CourseService';
const courseService = new CourseService();

const CourseInfoPage = (props:any) => {
    
    let match = useRouteMatch<any>("/course/:id/info");
    let courseId = match!.params.id!;

    const [isLoading, setIsLoading] = useState(true);
    const [reload, callReload] = useState(false);

    const [hasCourseDetails, setHasCourseDetails] = useState(false);
    const [apprenticeCourseDetail, setApprenticeCourseDetail] = useState<CourseDetailView>()

    useEffect(() => {
        courseService.getCourseDetails(courseId).then(response => {
            if(response!= null && response.success)
            {                 
                setApprenticeCourseDetail(response.result);
                setHasCourseDetails(true);
                setIsLoading(false);
                callReload(!reload);

            }else{
                throw "Erro"
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            setHasCourseDetails(false);
        });
        
    },[])

    return (<>

        <HomeHeader
            activeTab={CreatorHeaderActiveTabEnum.home}
        />
        <CourseInfoPageStyles fluid={true} >
            <Row className='ml-xs-0 ml-sm-0 ml-md-3'>
                {
                    isLoading ?
                        <></>:

                        hasCourseDetails ?
                        <CourseInfo
                            hasBreadCrumb={true}
                            renderSideOptions={true}
                            isMyFavouriteCourse={false}
                            apprenticeCourseDetail = {apprenticeCourseDetail!}
                        /> : <></>
                        
                } 

            </Row>
        </CourseInfoPageStyles>
        { isLoading ? <PageLoader/> : <></> }   
    </>)
}


const CourseInfoPageStyles = styled(Container)`
    position:absolute;
    margin-top:9vh;
    max-height:91vh;
    overflow-y:scroll;
    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }
`

export default CourseInfoPage;