import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import {
    PrimaryPurpleButton,
    SecondaryGreenButton
} from '../../../../components/Buttons/styles';

import { TransparentOkButton } from '../../../../components/oldLayouts/SharedComponentStyles';

export const AddModuleButtonStyles = styled(PrimaryPurpleButton)`
    .bi-plus-circle {
        font-size: 3vmin;
    }
}
`;

export const OrderModuleButtonStyles = styled(SecondaryGreenButton)`
    .bi-arrow-down-up {
        font-size: 3vmin;
    }        
}
`;

export const DirectionOrderModuleButtonStyles = styled(Button)`
    background-color: #FFF0!important;
    border: none;    
    color: #0C8292;
    font-size: 3vmin;

    :hover {
        background-color: #FFF0!important;
        border: none;    
        color: #0C8292;
        font-weight: 700!important;
    }
    :focus {
        background-color: #FFF0!important;
        border: none;    
        color: #0C8292;
        box-shadow: none!important;
    }
    :active {
        background-color: #FFF0!important;
        border: none;    
        color: #0C8292;
        box-shadow: none!important;
    }
    
    :disabled {        
        color: #B9B8B8;        
    }

    .bi bi-arrow-down {    
        color: #0C8292;
    }

    .bi bi-arrow-up {        
        color: #0C8292;
    }        
}
`;

export const SaveOrderModuleButtonStyles = styled(SecondaryGreenButton)`    
    background: #218749;
    color: #FFF;
    font-size: 2vmin;

    p{
        color: #FFFFFF!important;
        margin-bottom:0px;
    }

    i {
        color: #FFFFFF;
    }
    
    .bi-check2 {
        color: #FFF;
        font-size: 3vmin;
    } 
    
    &:hover:not(:disabled){
        background: #217543;
        color: #FFF;
        p{
            color: #FFF!important;
            margin-bottom:0px;
        }
        i {
            color: #FFF;
        }
    }


    &:hover:not(:disabled){
        background: #217543 !important;
    }
    &:active:not(:disabled){
        background: #217543 !important;
    }
    &:focus:not(:disabled){
        background: #217543 !important;
        box-shadow:#fff 0 0 0 0.42vmin, #0C8292 0 0 0 0.56vmin!important;
    }
    &:disabled{
        background-color:#B9B8B8;
        box-shadow:none;
    }

}
`;

export const EditButton = styled(TransparentOkButton)`
    .bi-pencil {
        font-size: 3.5vmin;
        color: #0C8292;
    }        
}
`;

export const DeleteButton = styled(TransparentOkButton)`
    .bi-trash {
        font-size: 3.8vmin;
        color: #C57700;
    }        
}
`;

export const ModuleListComponentStyles = styled(Col)`

    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #514F4E;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #514F4E;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #514F4E;  
    }
    p{
        margin-bottom:0px
    }
    .row.nav-buttons{
        position:sticky;
        position: -webkit-sticky;
        top:8.7vmin;
        z-index:10;
        background-color:#fff;

        button{
            text-align:left;
            background-color:#fff!important;
            border:none;
            box-shadow:none;
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #514F4E!important;
            :active{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
            :focus{
                background-color:#fff!important;
                border:none!important;
                box-shadow:none!important;
            }
        }
        button.active{
            border-bottom: 4px solid #EA694C!important;
        }
    }

    .list-group-item{
        border:none;
        .card{
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(75, 75, 75, 0.15);
            border-radius: 8px;
            button{
                text-align:left;
                background-color:#fff!important;
                border:none;
                box-shadow:none;
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #514F4E;
                :active{
                    background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
                }
                :focus{
                    background-color:#fff!important;
                    border:none!important;
                    box-shadow:none!important;
                }
            }
            .archive{
                color:#C57700;
                i{
                    font-size:22px;
                }
            }
            .continue-editing{
                color:#0C8292;
                i{
                    font-size:22px;
                }
            }
            .restore-edit{
                color:#0C8292;
                i{
                    font-size:22px;
                } 
            }
            .active-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #218749;
                i{
                    font-size:20px;
                }    
            }
            .draft-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #6468C8;
                i{
                    font-size:20px;
                }    
            }
            .archive-course{
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #C57700;
                i{
                    font-size:20px;
                }    
            }
        }
    }

    .row.empty-result{
        img{
            height:285px;
        }
        h1{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #514F4E;
        }
        h3{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #636060;
        }
    }
`;

export const ModuleBlankListComponentStyles = styled(Col)`
    height: 50vmin;    
    background: #F9F9F9;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 8px;

    .bi-list-ol{    
        font-size: 3.5vmin;            
    }
   
    p{
        margin-bottom: 0px;
        text-align: center;
    }    

`;
