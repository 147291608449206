import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

export default function AllTasksCorrected(props) {

    return (
        <BodyAllTasksCorrected className=" p-0">
            <Row className="my-auto mx-auto">
                <Col md={"auto"} className="mx-auto col-6">
                    <img className="" src="/img/taskscorrection/woman-music-play.svg" />
                </Col>
                <Col md={"auto"} className="ml-auto my-auto col-6 p-5">
                    <Row className="py-3">
                        Todas as Tarefas Estão corrigidas!
                    </Row>
                    <Row>
                        <p>Aproveite esse tempo para relaxar um pouco</p>
                    </Row>
                </Col>
            </Row>
        </BodyAllTasksCorrected >
    )
}

export const BodyAllTasksCorrected = styled(Col)`
        margin-top:10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #636060;
        width: flex;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.77vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    h2{
        color: #514F4E;
    }
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.77vmin;
        line-height: 3.33vmin;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin!important;
        line-height: 3.33vmin;
        color: #0C8292;
    }
    img{
        position: initial!important;
        height:32.5vmin;
        width:32.4vmin;
    }
    `

export const ExitTask = styled.button`
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.63vmin;
        color: #6468C8!important;
        float: right;
        padding: 14px 14px;
        margin-right: 2rem;
        
        background:none;
        border:none!important;
        border-radius:0px;
        box-shadow:none!important;
        i{
            font-size:1.66vmin;
        }
    
`