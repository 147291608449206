import { forwardRef, useContext, useImperativeHandle } from 'react';
import { Row, Col} from 'react-bootstrap';

import { CreateCourseContext } from '../../../../context/creator/createCourse/CreateCourseContext'
import { AddModuleButtonStyles, OrderModuleButtonStyles } from './CreateCourseContentFormStyles';
import { CourseBlankModule } from './CourseBlankModule';
import { CourseModulesList } from './CourseModulesList';
import { ICreateCourseContentPayload } from '../../../../domain/payloads/createCourse/ICreateCourseContentPayload';
import CourseStepEnum from '../../../../domain/enum/CourseStepEnum';
import { ConfirmDeleteModuleModal } from '../../../../components/Modals/ConfirmDeleteModuleModal';
import { ModuleOrdering } from './ModuleOrdering';

import CreatorServices from '../../../../services/CreatorServices';
const creatorService = new CreatorServices();

export const CreateCourseContentForm = forwardRef((props, ref) => {
    
    const { 
        course, 
        isEdit,
        isChanged,
        stepRef,
        setIsLoading, 
        setNavigationStep, 
        setIsChanged, 
        addNewModule,
        orderModule, 
        setUpdateDate, 
        setDataIsLoaded, 
        openOrderModulePage: isOrderingModule
    } = useContext(CreateCourseContext);
    
    useImperativeHandle(ref, () => ({
        submit(isBacking:boolean, saveAsDraft: boolean, stepRender?:number) {            
            onSubmit(isBacking, saveAsDraft, stepRender);
        }
    }));  
    
    const onSubmit = (isBacking:boolean, saveAsDraft: boolean, stepRender?:number) => {
        if(isChanged)
        {
            let data: ICreateCourseContentPayload = {
                courseId: course.id,
                isEditMode: isEdit||course.courseStepId >= CourseStepEnum.Content,
                modules:course.modules
            }
            
            setIsLoading(true);
            creatorService.createCourseContent(data).then(function(response) {
                if(response.success) {
                    updateCourseObj(data, response.result);                
                    setIsChanged(false);
                    setIsLoading(false);

                    if(!saveAsDraft)
                    {
                        setNavigationStep(stepRender ? stepRender :isBacking ?  CourseStepEnum.InitialData : CourseStepEnum.Content);    
                    }    
                }
            }).catch(err => {
                setIsLoading(false);
            });
        }
        else
        {            
            setNavigationStep(isBacking ? CourseStepEnum.InitialData : CourseStepEnum.Dependency);            
        }
    };

    const updateCourseObj = (data: any, response: any) => {
        course.courseStepId = response.courseStepId;
        course.modules = response.modules
        setUpdateDate(response.result.updateDate);    
        setDataIsLoaded(true)
    }

    return (<>            
        {isOrderingModule
            ? (<ModuleOrdering ref={stepRef} /> )
            : (<>
                <Row>
                    <Col md={10} className="mx-auto">
                        <Row>
                            <Col md={7}>
                                <Row>
                                    <h1>Conteúdo</h1>
                                </Row>
                                <Row>
                                    <p>O conteúdo dos cursos é dividido em Módulos. Cada um deles pode conter diversas aulas e atividades.</p>
                                </Row>                        
                            </Col>
                            
                            <Col md="auto" className="ml-auto my-auto">
                                <OrderModuleButtonStyles  className="float-right px-4"
                                    disabled={ course.modules.length > 0 ? false : true}
                                    onClick={() => orderModule()}>
                                    <Row>
                                        <Col md="auto" className="my-auto">
                                            <i className="bi bi-arrow-down-up" />
                                        </Col>
                                    </Row>
                                </OrderModuleButtonStyles>
                            </Col> 
                            
                            <Col md="auto" className="px-0 my-auto">
                                <AddModuleButtonStyles 
                                    className="float-right px-4"
                                    onClick={() => addNewModule()}>
                                    <Row>
                                        <Col md="auto" className="my-auto pr-1 pl-1">
                                            <i className="bi bi-plus-circle" />
                                        </Col>
                                        <Col md="auto" className="my-auto">
                                            <p>Adicionar módulo</p>
                                        </Col>
                                    </Row>
                                </AddModuleButtonStyles>                      
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {course.modules.length > 0 ?
                        CourseModulesList(course)
                        : CourseBlankModule()
                    }
                </Row>      
                <ConfirmDeleteModuleModal/>  
            </>)
        }
    </>);

});



