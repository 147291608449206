import { Button, Col, Row } from "react-bootstrap";
import FileViewer from "../../../../components/Common/FileViewer";
import { DocumentViewTypeEnum } from "../../../../components/Modals/AddDocumentModal/context/AddDocumentObject";
import DocumentLessonContentViewModel from "../../../../domain/models/course/module/lesson/DocumentLessonContentViewModel";
import { AttendMediaContentComponentStyles } from "../AttentCoursePageStyles";

interface AttendDocumentContentComponentProps {
    content: string;
    documentLesson: DocumentLessonContentViewModel;
}

export default (props: AttendDocumentContentComponentProps) => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <AttendMediaContentComponentStyles
            className="md-mx-4 mb-3"
        >
            {
                props.documentLesson.ViewType === DocumentViewTypeEnum.minimized ?
                    (
                        <Col md={6}>
                            <Button className='w-100 open-new-tab-button px-3' onClick={() => openInNewTab(props.documentLesson.Url)}>
                                <Row className="mb-2">
                                    <Col md="auto" className="my-auto pr-0">
                                        <i className="bi bi-box-arrow-up-right" />
                                    </Col>
                                    <Col className="my-auto">
                                        <h3>
                                            Abrir documento inserido
                                        </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>
                                            {props.documentLesson.Name}
                                        </p>
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                    )
                    :
                    (
                        <Col md={11} className='mx-auto'>                            
                            <FileViewer linkFile={props.documentLesson.Url} />
                        </Col>
                    )
            }
        </AttendMediaContentComponentStyles>
    );
};
