import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ChangeLessonLoader from "../../../components/Loaders/ChangeLessonLoader";
import { AttendCourseContext } from "../../../context/apprentice/attend/AttendCourseContext";
import ModuleContentTypeEnum from "../../../domain/enum/ModuleContentTypeEnum";
import { AttendLessonComponent } from "./lesson/AttendLessonComponent";
import { AttendAssessmentComponent } from "./assessment/AttendAssessmentComponent";
import AttendTaskComponent from "./task/AttendTaskComponent";
import { TaskContextProvider } from "../../../context/apprentice/attend/TaskContext";
import CertificateCoursePage from "./certificate/CertificateCoursePage";

export const AttendCourseModuleContent = () => {
  const [openMenu, setOpenMenu ] = useState(false);

  const { openModuleContentType, menuCollapsed, collapseMenu } =
    useContext(AttendCourseContext);



  switch (openModuleContentType) {
    case ModuleContentTypeEnum.Lesson: {
      return (
        <>
          <AttendLessonComponent />          
        </>
      );
    }
    case ModuleContentTypeEnum.Task: {
      return (
        <TaskContextProvider>
          <AttendTaskComponent />
        </TaskContextProvider>
      );
    }
    case ModuleContentTypeEnum.Assessment: {
      return <AttendAssessmentComponent />;
    }
    case ModuleContentTypeEnum.Certificate: {
      return <CertificateCoursePage isModule={true} />;
    }
    default: {
      return <ChangeLessonLoader sideMenuIsOpen={menuCollapsed} />;
    }
  }
};
