import { Application } from '../domain/BrainzAuthToken';
import { getDecodedToken } from "../router/TokenUtils"
import { profile_Admin, profile_AdminGlobal, profile_Teacher } from './contants/Profiles';

export const isTeacher = (): boolean => {
    let decodedToken = getDecodedToken();
    let isTeacher = false;
        
    if (decodedToken != null && decodedToken.applications !== undefined) {

        let userApplication = extractUserApplicationFromToken();

        if (userApplication.length > 0) {
            let userApplicationProfiles = userApplication[0].Profiles
            let teacherProfile = userApplicationProfiles.filter(userApplicationProfile => {
                return (userApplicationProfile.Code === profile_Teacher)
            })
            isTeacher = teacherProfile.length > 0;
        }
    }

    return isTeacher;
}

export const isAdmin = (): boolean => {
    let decodedToken = getDecodedToken();
    let isAdmin = false;

    if (decodedToken != null && decodedToken.applications !== undefined) {
        
        let userApplication = extractUserApplicationFromToken();

        if (userApplication.length > 0) {
            let userApplicationProfiles = userApplication[0].Profiles;
            let adminProfile = userApplicationProfiles.filter(userApplicationProfile => {
                return (userApplicationProfile.Code === profile_Admin)
                }
            )
            isAdmin = adminProfile.length > 0;
        }        
    }

    return isAdmin;
}

export const isAdminGlobal = (): boolean => {
    let decodedToken = getDecodedToken();
    let isAdminGlobal = false;

    if (decodedToken != null && decodedToken.applications !== undefined) {

        let userApplication = extractUserApplicationFromToken();

        if (userApplication.length > 0) {
            let userApplicationProfiles = userApplication[0].Profiles;
            let adminProfile = userApplicationProfiles.filter(userApplicationProfile => {
                return (userApplicationProfile.Code === profile_AdminGlobal)
            })
            isAdminGlobal = adminProfile.length > 0;
        }
    }

    return isAdminGlobal;
}

export const getUserName = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.name
    }
    return "";
}

export const getUserEmail = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.login
    }
    return "";
}

export const getUserId = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.sub
    }
    return "";
}

export const getHolderId = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.holderId
    }
    return "";
}

export const getProviderCode = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.providerCode
    }
    return "";
}

export const getInstitutionId = (): string => {
    let decodedToken = getDecodedToken();
    if (decodedToken) {
        return decodedToken.institutionId
    }
    return "";
}

const extractUserApplicationFromToken = (): Application[] => {
    let decodedToken = getDecodedToken();

    let userApplications: Application[] = JSON.parse(decodedToken.applications);
    let userApplication = userApplications.filter(userApplication => {
            return (userApplication.Id === process.env["REACT_APP_APPLICATION_ID"])
    })

    return userApplication;
}