import { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { PrimaryPurpleButton, SecondaryGreenButton } from "../../../../../components/Buttons/styles";
import { AttendCourseContext } from "../../../../../context/apprentice/attend/AttendCourseContext";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import styled from 'styled-components'

export const ConfirmSelectionFileModal = () => {
    const {
        currentUserModuleContentProgress,
        courseDetails
    } = useContext(AttendCourseContext);

    const { 
        fileInfo,
        hiddenFileInput,
        onInputFileChange,
        handleAddFileButton,
        handleSendTask
    } = useContext(TaskContext);

    return (
        <>
        <HoldModal>
            <Modal.Header closeButton>
                <Modal.Title>Envio de Tarefa</Modal.Title>
            </Modal.Header>
            <Modal.Body className="confirm-task-file">
                <Row>
                    <Col>
                        <h3>
                            Tarefa
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>
                            {currentUserModuleContentProgress.title}
                        </h2>
                    </Col>
                </Row>
                <Row className="confirm-task-msg mt-3 mb-4">
                    <Col md={1} className="pr-0 my-auto">
                        <i className="bi bi-card-checklist" />
                    </Col>
                    <Col md={11} className="my-auto">
                        <h4>
                            Não saia da janela até ver a confirmação do envio mexer
                        </h4>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <h1>
                            {fileInfo.fileName}
                        </h1>
                    </Col>
                </Row>
            </Modal.Body>
            {/* Desktop */}
            <Modal.Footer className="choose-task-file-footer d-none d-md-block">                
                <Row>  
                    <Col md={6}>
                        <SecondaryGreenButton onClick={()=>handleAddFileButton()}>                        
                            <i className="bi bi-arrow-repeat"/>
                            Alterar Arquivo
                        </SecondaryGreenButton>
                    </Col>                  
                    <Col md={6}>
                        <PrimaryPurpleButton onClick={()=>handleSendTask(courseDetails.id, currentUserModuleContentProgress.id)}>                                                               
                            Enviar
                            <i className="bi bi-upload" />
                        </PrimaryPurpleButton>
                    </Col>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={onInputFileChange}
                        className="d-none"
                        multiple={false} 
                    />
                </Row>
            </Modal.Footer>
            {/* Mobile */}
            <Modal.Footer className="choose-task-file-footer d-block d-md-none">                
                <Row>               
                    <Col md={6}>
                        <PrimaryPurpleButton onClick={()=>handleSendTask(courseDetails.id, currentUserModuleContentProgress.id)}>                                                               
                            Enviar
                            <i className="bi bi-upload" />
                        </PrimaryPurpleButton>
                    </Col>
                    <Col md={6}>
                        <SecondaryGreenButton className="mt-2" onClick={()=>handleAddFileButton()}>                        
                            <i className="bi bi-arrow-repeat"/>
                            Alterar Arquivo
                        </SecondaryGreenButton>
                    </Col>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={onInputFileChange}
                        className="d-none"
                        multiple={false} 
                    />
                </Row>
            </Modal.Footer>            
        </HoldModal>
        </>
    );
};

const HoldModal = styled(Col)`

    .modal-footer {
        display: block;
        padding: 0 !important;  
        
        .col-md-6 {
            padding: 0px 6px !important;
        }
    }

    .choose-task-file-footer { 
        margin-bottom: 30px !important;

        button i {
            font-size: 3vmin !important;
            margin: 0px 12px;
        }
    }

    @media screen and (max-width: 990px){        

        .modal-title {
            font-size: 6.3vmin !important;
            color: #514F4E;
        }

        .close {
            span {
                font-size: 12vmin !important;
                font-weight: 400 !important;
                color: #514F4E !important;
                position: absolute;
                top: 10px !important;
                right: 15px !important;
            }
        }

        .choose-task-file-footer { 
            button i {
                font-size: 6vmin !important;
            }
        }
    }
`


