import { useContext } from 'react';
import { Row, Col, Modal, Container, ListGroup, ListGroupItem, Card } from 'react-bootstrap';
import { 
    ModuleListComponentStyles, 
    DirectionOrderModuleButtonStyles,
    SaveOrderModuleButtonStyles
 } from '../../pages/creator/createCourse/3_LessonContent/CreateCourseContentFormStyles'; 
import { ConfirmActionModalStyles } from './styles';

import { AddModuleContext } from '../../context/creator/createCourse/AddModuleContext';

export function OrderingContentModal() {

    const {
        module,        
        openModalOrderContent,
        setOpenModalOrderContent,
        handleOrderingModuleContent,
        handleSaveOrderModuleContent
    } = useContext(AddModuleContext);

    return (
        <ConfirmActionModalStyles 
            size="lg"
            show={openModalOrderContent} 
            onHide={() => setOpenModalOrderContent(false)}
            scrollable={true}
            >
            <Modal.Header closeButton>
                <Container>
                    <Row>
                        <Col md="auto">
                            <h3>Reordenar conteúdos</h3>
                            <p>Utilize as setas para movimentar os conteúdos e alterar a ordenação.</p>
                        </Col>                        
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body style={{
                maxHeight: '50vmin',
                overflowY: 'auto'
                }}
            >
                <Row className="my-3" style={{ placeContent: 'center' }}>
                
                    <ModuleListComponentStyles>
                        <Row>
                            <Col md={12} className="pl-5 mx-auto">
                                <Row>
                                    <Col>
                                        <ListGroup className="w-100 px-0">
                                            {module.moduleContents.length>0 && module.moduleContents.sort((a, b) => a.order - b.order).map((item, modIndex) => {
                                                return (
                                                    <ListGroupItem className="px-0 py-2" key={modIndex}>
                                                        <Row>
                                                            <Col md={1} className="mt-1 my-auto">
                                                                <DirectionOrderModuleButtonStyles  className="float-right px-4"
                                                                    title="mover para baixo"
                                                                    disabled={module.moduleContents[module.moduleContents.length - 1].order == item.order ? true : false}
                                                                    onClick={() => handleOrderingModuleContent("DOWN", item.order)}>
                                                                    <Row>
                                                                        <Col md="auto" className="my-auto">
                                                                            <i className="bi bi-arrow-down" />
                                                                        </Col>
                                                                    </Row>
                                                                </DirectionOrderModuleButtonStyles>
                                                            </Col>
                                                            <Col md={1} className="mt-1 my-auto">
                                                                
                                                                <DirectionOrderModuleButtonStyles title="mover para cima"
                                                                    disabled={module.moduleContents[0].order == item.order ? true : false}
                                                                className="float-right px-4"
                                                                    onClick={() => handleOrderingModuleContent("UP", item.order)}>
                                                                    <Row>
                                                                        <Col md="auto" className="my-auto">
                                                                            <i className="bi bi-arrow-up" />
                                                                        </Col>
                                                                    </Row>
                                                                </DirectionOrderModuleButtonStyles>

                                                            </Col>
                                                            <Col md={10} className="mt-1 my-auto">
                                                                <Card>
                                                                    <Card.Body className="py-2">
                                                                        <p>{item.title}</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        
                                                    </ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModuleListComponentStyles>
        

                </Row>
            </Modal.Body>
            <Modal.Footer as={Row}>
                <Col md="auto" className="ml-auto my-auto">
                    <SaveOrderModuleButtonStyles  className="float-right px-4"
                        onClick={() => handleSaveOrderModuleContent()}>
                        <Row>
                            <Col md="auto" className="my-auto">
                                <p>Salvar ordenação</p>
                            </Col>
                            <Col md="auto" className="pl-0">
                            <i className="bi bi-check2" />
                            </Col>
                        </Row>
                    </SaveOrderModuleButtonStyles>
                </Col>
            </Modal.Footer>
        </ConfirmActionModalStyles>
    );
}
