import { 
    useContext, useState
} from "react";

import { 
    Button, 
    Col, 
    ListGroup, 
    Row 
} from "react-bootstrap"

import {
    AttendCourseSideMenuItemStyle, 
    AttendCourseSideMenuStyle as AttendCourseSideMenuStyles, 
    CollapseMenuButton,
    HoldNextPrev
} from "../AttentCoursePageStyles";

import {
    contentTypeIcon,
    contentTypeDescription 
} from '../../../../util/FormatterLessonType';

import { ApprenticeProgressStatusEnum } from "../../../../domain/enum/ApprenticeProgressStatusEnum";
import ModuleContentTypeEnum from "../../../../domain/enum/ModuleContentTypeEnum";

import { AttendCourseContext } from '../../../../context/apprentice/attend/AttendCourseContext';

export const AttentCourseCollapsableSideMenu = () => {

    const {
        attendNextModuleContent, attendPreviousModuleContent
    } = useContext(AttendCourseContext);

    const {
        currentUserModuleContentProgress, 
        collapseMenu, 
        isModuleComplete,
        menuCollapsed,
        attendModuleContent,
        apprenticeCourseProgress,
        collapsableListOfModules,
        isSelectedModule,
        handleCollapseMenuItemChange,
        attendCertificate,
        isCourseComplete,
        canSeeNextModuleContentButton,
        canSeePreviousModuleContentButton,
        selectedCertificate
    } = useContext(AttendCourseContext);

    function returnProgressStatusIcon(status:number|null, moduleContentTypeId:number, isCertificateBlocked:boolean) {
        switch (moduleContentTypeId)
        {
            case (ModuleContentTypeEnum.Lesson):            
            switch(status){
                case (ApprenticeProgressStatusEnum.Done):
                    return (<i className="bi bi-check2-circle"/>)            
                default:
                    return (<></>)
            }
            case (ModuleContentTypeEnum.Assessment):            
                switch(status){
                    case (ApprenticeProgressStatusEnum.Done):
                        return (<i className="bi bi-check2-circle"/>)
                    case ApprenticeProgressStatusEnum.Required:                    
                    case ApprenticeProgressStatusEnum.Sent:
                        return (<i className="bi bi-dash-circle" />)
                    default:
                        return (<></>)
                }
            case (ModuleContentTypeEnum.Task):            
                switch(status){
                    case (ApprenticeProgressStatusEnum.Done):
                        return (<i className="bi bi-file-earmark-check"/>)
                    case ApprenticeProgressStatusEnum.Required:
                        return (<i className="bi bi-dash-circle" />)
                    case ApprenticeProgressStatusEnum.Sent:
                        return (<i className="bi bi-check2-circle" />)
                    default:
                        return (<></>)
                }
            case (ModuleContentTypeEnum.Certificate):{
                if(isCertificateBlocked)
                    return <i className="bi bi-lock"/>
                return <i className="bi bi-unlock"/>
            }

            default:
                return (<></>);
        }
    }



    return (<>
        {
            menuCollapsed?
            (   <>
                <CollapseMenuButton onClick={()=>collapseMenu()} className="py-3 pl-3 d-none d-md-block">
                    <i className="bi bi-arrow-bar-left"/>
                </CollapseMenuButton>
                
                <Button className={"showCourses d-block d-md-none"} onClick={() => collapseMenu()}>
                    <Row>
                        <Col className="px-2 d-table">
                            <p className="d-table-cell align-middle">                   
                                Conteúdo do curso
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.031 6.78039C13.1008 6.71072 13.1562 6.62796 13.194 6.53684C13.2318 6.44572 13.2513 6.34804 13.2513 6.24939C13.2513 6.15074 13.2318 6.05305 13.194 5.96194C13.1562 5.87082 13.1008 5.78806 13.031 5.71839L8.53097 1.21839C8.4613 1.14854 8.37854 1.09313 8.28742 1.05532C8.1963 1.01751 8.09862 0.998047 7.99997 0.998047C7.90132 0.998047 7.80364 1.01751 7.71252 1.05532C7.6214 1.09313 7.53864 1.14854 7.46897 1.21839L2.96897 5.71839C2.82814 5.85922 2.74902 6.05022 2.74902 6.24939C2.74902 6.44855 2.82814 6.63956 2.96897 6.78039C3.1098 6.92122 3.30081 7.00034 3.49997 7.00034C3.69913 7.00034 3.89014 6.92122 4.03097 6.78039L7.99997 2.80989L11.969 6.78039C12.0386 6.85023 12.1214 6.90565 12.2125 6.94346C12.3036 6.98127 12.4013 7.00073 12.5 7.00073C12.5986 7.00073 12.6963 6.98127 12.7874 6.94346C12.8785 6.90565 12.9613 6.85023 13.031 6.78039Z" fill="#0C8292"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00005 13C8.19896 13 8.38973 12.921 8.53038 12.7803C8.67103 12.6397 8.75005 12.4489 8.75005 12.25L8.75005 2.5C8.75005 2.30109 8.67103 2.11032 8.53038 1.96967C8.38973 1.82902 8.19896 1.75 8.00005 1.75C7.80114 1.75 7.61037 1.82902 7.46972 1.96967C7.32907 2.11032 7.25005 2.30109 7.25005 2.5L7.25005 12.25C7.25005 12.4489 7.32907 12.6397 7.46972 12.7803C7.61037 12.921 7.80114 13 8.00005 13ZM0.800048 15.4C0.800048 15.07 1.07005 14.8 1.40005 14.8L14.6 14.8C14.7592 14.8 14.9118 14.8632 15.0243 14.9757C15.1368 15.0883 15.2 15.2409 15.2 15.4C15.2 15.5591 15.1368 15.7117 15.0243 15.8243C14.9118 15.9368 14.7592 16 14.6 16L1.40005 16C1.24092 16 1.08831 15.9368 0.975784 15.8243C0.863262 15.7117 0.800048 15.5591 0.800048 15.4V15.4Z" fill="#0C8292"/>
                                </svg>
                            </p>
                        </Col>
                    </Row>
                </Button>
                
                <HoldNextPrev className="d-block d-md-none">
                    <ul>
                    {canSeePreviousModuleContentButton() && 
                        <li>
                            <button
                             onClick={()=>attendPreviousModuleContent()}>
                                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.031 0.468388C11.1008 0.538056 11.1563 0.62082 11.1941 0.711937C11.2319 0.803055 11.2513 0.900737 11.2513 0.999388C11.2513 1.09804 11.2319 1.19572 11.1941 1.28684C11.1563 1.37796 11.1008 1.46072 11.031 1.53039L2.5605 9.99939L11.031 18.4684C11.1718 18.6092 11.2509 18.8002 11.2509 18.9994C11.2509 19.1986 11.1718 19.3896 11.031 19.5304C10.8902 19.6712 10.6992 19.7503 10.5 19.7503C10.3008 19.7503 10.1098 19.6712 9.969 19.5304L0.968997 10.5304C0.899153 10.4607 0.843738 10.378 0.805928 10.2868C0.768119 10.1957 0.748657 10.098 0.748657 9.99939C0.748657 9.90074 0.768119 9.80305 0.805928 9.71194C0.843738 9.62082 0.899153 9.53806 0.968997 9.46839L9.969 0.468388C10.0387 0.398543 10.1214 0.343129 10.2125 0.305319C10.3037 0.267509 10.4013 0.248047 10.5 0.248047C10.5986 0.248047 10.6963 0.267509 10.7874 0.305319C10.8786 0.343129 10.9613 0.398543 11.031 0.468388Z" fill="#6468C8"/>
                                </svg>
                                Anterior
                            </button>
                        </li>}
                        <li></li>
                        {canSeeNextModuleContentButton() &&
                        <li>
                            <button onClick={() => attendNextModuleContent()}>
                                Próxima
                                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.968997 0.468388C1.03867 0.398543 1.12143 0.343129 1.21255 0.305319C1.30366 0.267509 1.40135 0.248047 1.5 0.248047C1.59865 0.248047 1.69633 0.267509 1.78745 0.305319C1.87857 0.343129 1.96133 0.398543 2.031 0.468388L11.031 9.46839C11.1008 9.53806 11.1563 9.62082 11.1941 9.71194C11.2319 9.80305 11.2513 9.90074 11.2513 9.99939C11.2513 10.098 11.2319 10.1957 11.1941 10.2868C11.1563 10.378 11.1008 10.4607 11.031 10.5304L2.031 19.5304C1.89017 19.6712 1.69916 19.7503 1.5 19.7503C1.30083 19.7503 1.10983 19.6712 0.968997 19.5304C0.828168 19.3896 0.74905 19.1986 0.74905 18.9994C0.74905 18.8002 0.828168 18.6092 0.968997 18.4684L9.4395 9.99939L0.968997 1.53039C0.899153 1.46072 0.843738 1.37796 0.805928 1.28684C0.768119 1.19572 0.748657 1.09804 0.748657 0.999388C0.748657 0.900737 0.768119 0.803055 0.805928 0.711937C0.843738 0.62082 0.899153 0.538056 0.968997 0.468388Z" fill="#6468C8"/>
                                </svg>

                            </button>
                        </li>}
                    </ul>                    
                </HoldNextPrev>
                </>
            )
            :
            (   
                <>
                    <div className="areaMenu" onClick={()=>collapseMenu()}></div>
                {/* <Col xs={12} md={4} id="id-col-attendCourseSideMenuStyles" className="px-0 col-attendCourseSideMenuStyles"> */}
                <Col xs={12} md={4} className="px-0 menuFixed">
                    <AttendCourseSideMenuStyles>
                        <ListGroup.Item className="py-2 content">
                            <Row className="mx-0">
                                <Col xs={6} className="my-auto">
                                    <h1>                                        
                                        Conteúdo do curso
                                    </h1>
                                </Col>
                                <Col xs={6} md={"auto"} className="ml-auto">
                                    <Button onClick={()=>collapseMenu()} className="py-3">
                                        <Row>
                                            <Col xs={9} className="my-auto">
                                                <p className="hide">
                                                    Esconder
                                                </p>
                                            </Col>
                                            <Col xs={3} className="d-none d-md-block my-auto pr-0">
                                                <i className="bi bi-arrow-bar-right"/>
                                            </Col> 
                                            <Col xs={3} className="d-block d-md-none my-auto pr-0">
                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.031 12.7629C14.1008 12.8405 14.1562 12.9327 14.194 13.0341C14.2319 13.1356 14.2513 13.2443 14.2513 13.3542C14.2513 13.464 14.2319 13.5728 14.194 13.6742C14.1562 13.7757 14.1008 13.8679 14.031 13.9454L9.53097 18.9561C9.4613 19.0338 9.37854 19.0955 9.28742 19.1376C9.1963 19.1797 9.09862 19.2014 8.99997 19.2014C8.90132 19.2014 8.80364 19.1797 8.71252 19.1376C8.6214 19.0955 8.53864 19.0338 8.46897 18.9561L3.96897 13.9454C3.82814 13.7886 3.74902 13.5759 3.74902 13.3542C3.74902 13.1324 3.82814 12.9197 3.96897 12.7629C4.1098 12.6061 4.30081 12.518 4.49997 12.518C4.69913 12.518 4.89014 12.6061 5.03097 12.7629L8.99997 17.184L12.969 12.7629C13.0386 12.6852 13.1214 12.6234 13.2125 12.5813C13.3036 12.5392 13.4013 12.5176 13.5 12.5176C13.5986 12.5176 13.6963 12.5392 13.7874 12.5813C13.8785 12.6234 13.9613 12.6852 14.031 12.7629Z" fill="#0C8292"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5.83681C9.19891 5.83681 9.38968 5.92479 9.53033 6.08141C9.67098 6.23802 9.75 6.45043 9.75 6.67192V17.5283C9.75 17.7498 9.67098 17.9622 9.53033 18.1188C9.38968 18.2754 9.19891 18.3634 9 18.3634C8.80109 18.3634 8.61032 18.2754 8.46967 18.1188C8.32902 17.9622 8.25 17.7498 8.25 17.5283V6.67192C8.25 6.45043 8.32902 6.23802 8.46967 6.08141C8.61032 5.92479 8.80109 5.83681 9 5.83681ZM0 1.66128C0 1.43979 0.0790178 1.22738 0.21967 1.07077C0.360322 0.914156 0.551088 0.826172 0.75 0.826172H17.25C17.4489 0.826172 17.6397 0.914156 17.7803 1.07077C17.921 1.22738 18 1.43979 18 1.66128C18 1.88276 17.921 2.09517 17.7803 2.25179C17.6397 2.4084 17.4489 2.49638 17.25 2.49638H0.75C0.551088 2.49638 0.360322 2.4084 0.21967 2.25179C0.0790178 2.09517 0 1.88276 0 1.66128Z" fill="#0C8292"/>
                                            </svg>
                                            </Col>    
                                        </Row>
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            collapsableListOfModules.list.sort((a, b) => a.item.order! - b.item.order!).map(
                                (courseModule, index) => {
                                    let isSelected = isSelectedModule(courseModule.item.id)

                                    return (
                                        <ListGroup.Item key={courseModule.item.id} className={(isSelected?("selectedModule"):(""))}>
                                            <Button className={"px-0 "+(isSelected?("selectedModule "):(" "))+(isSelected&&!courseModule.collapsed?("collapsedSelection"):(""))} onClick={() => handleCollapseMenuItemChange(index)}>
                                                <Row className="mx-0 py-3 pr-3 pl-1">
                                                    <Col xs={10} className="my-auto">
                                                        <h2>
                                                           Módulo {index+1} : {courseModule.item.title}
                                                        </h2>
                                                    </Col>
                                                    <Col xs={2} md={"auto"} className="ml-auto pl-md-auto pl-4">
                                                    {
                                                        isModuleComplete(courseModule.item)?
                                                        (
                                                            <i className="bi bi-check2-circle"/>
                                                        )
                                                        :(
                                                            courseModule.collapsed ?
                                                            (<i className="bi bi-chevron-up"/>)
                                                            :
                                                            (<i className="bi bi-chevron-down"/>)
                                                        )
                                                    }
                                                    </Col>
                                                </Row>
                                            </Button>
                                            {
                                                (courseModule.collapsed)?
                                                (
                                                    <Row className="mx-0">
                                                        <Col xs={12} className="px-0">
                                                            <AttendCourseSideMenuItemStyle>
                                                                {
                                                                    courseModule.item.moduleContents.sort((a, b) => a.order - b.order).map(
                                                                        moduleContentProgress =>
                                                                        {
                                                                            return(<>
                                                                                <ListGroup.Item key={moduleContentProgress.id} className="lesson">
                                                                                    <Button 
                                                                                        className="pl-3 w-100 text-left d-block d-md-none" 
                                                                                        active={moduleContentProgress.id === currentUserModuleContentProgress.id} 
                                                                                        disabled={courseModule.item.isBlocked||
                                                                                            (moduleContentProgress.moduleContentTypeId === ModuleContentTypeEnum.Certificate&&!isModuleComplete(courseModule.item))} 
                                                                                        onClick={() => attendModuleContent(moduleContentProgress)} >
                                                                                        <Row>
                                                                                            <Col xs={1} md={"auto"} className="my-auto">
                                                                                                <p>{contentTypeIcon(moduleContentProgress.moduleContentTypeId)}</p>
                                                                                            </Col>
                                                                                            <Col xs={9}>
                                                                                                <h3>{moduleContentProgress.title}</h3>
                                                                                                <p>{contentTypeDescription(moduleContentProgress.moduleContentTypeId, moduleContentProgress.isRequired)}</p>
                                                                                            </Col>
                                                                                            <Col xs={1} md={"auto"} className="my-auto">
                                                                                            {
                                                                                                courseModule.item.isBlocked?
                                                                                                (<i className="bi bi-lock"/>)
                                                                                                :
                                                                                                (returnProgressStatusIcon(moduleContentProgress.progressStatusId, moduleContentProgress.moduleContentTypeId,!isModuleComplete(courseModule.item)))
                                                                                            }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Button>
                                                                                    <Button 
                                                                                        className="pl-3 w-100 text-left d-none d-md-block" 
                                                                                        active={moduleContentProgress.id === currentUserModuleContentProgress.id} 
                                                                                        disabled={courseModule.item.isBlocked||
                                                                                            (moduleContentProgress.moduleContentTypeId === ModuleContentTypeEnum.Certificate&&!isModuleComplete(courseModule.item))} 
                                                                                        onClick={() => attendModuleContent(moduleContentProgress)} >
                                                                                        <Row>
                                                                                            <Col xs={1} md={"auto"} className="my-auto">
                                                                                                <p>{contentTypeIcon(moduleContentProgress.moduleContentTypeId)}</p>
                                                                                            </Col>
                                                                                            <Col xs={8}>
                                                                                                <h3>{moduleContentProgress.title}</h3>
                                                                                                <p>{contentTypeDescription(moduleContentProgress.moduleContentTypeId, moduleContentProgress.isRequired)}</p>
                                                                                            </Col>
                                                                                            <Col xs={1} md={"auto"} className="my-auto ml-auto">
                                                                                            {
                                                                                                courseModule.item.isBlocked?
                                                                                                (<i className="bi bi-lock"/>)
                                                                                                :
                                                                                                (returnProgressStatusIcon(moduleContentProgress.progressStatusId, moduleContentProgress.moduleContentTypeId,!isModuleComplete(courseModule.item)))
                                                                                            }
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Button>
                                                                                </ListGroup.Item>
                                                                            </>)
                                                                        }
                                                                    )                                                                    
                                                                }
                                                            </AttendCourseSideMenuItemStyle>
                                                        </Col>
                                                    </Row>
                                                )
                                                :
                                                (<></>)
                                            }
                                        </ListGroup.Item>
                                    )
                                }
                            )
                        }
                        
                        {
                            apprenticeCourseProgress.enableCertificate ?
                            (
                                <ListGroup.Item className="py-1">
                                    <Button 
                                        active={selectedCertificate} 
                                        onClick={() => attendCertificate()} disabled={!isCourseComplete()}>
                                        <Row className="mx-0">
                                            <Col xs={5} className="my-auto">
                                                <h2>
                                                    Certificado final
                                                </h2>
                                            </Col>
                                            <Col xs={"auto"} className="ml-auto mr-w">
                                                {isCourseComplete()
                                                        ? (<i className="bi bi-check2-circle"></i>)
                                                        : (<i className="bi bi-lock"/>)
                                                }
                                            </Col>
                                        </Row>
                                    </Button>                                        
                                </ListGroup.Item>
                            )
                            :
                            (
                                <></>
                            )
                        }
                        
                    </AttendCourseSideMenuStyles>
                </Col>
                </>
            )               
        }
    </>)
}
