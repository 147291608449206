import ApiInterface from "../interface/ApiInterface"
import { formatDateToDDMMYYYY, formatDateToSend } from "../util/Formatter";

export interface ICampaignPayload {
    name: string,
    discountPercentage: number,
    maxSoldQnt: number,
    campaignStartDate: string,
    campaignEndDate: string,
    coursesId: any
}

interface ICoursesId {
    id: string
}


export default class CampaignServices {
    public async CreateCampaign(data: ICampaignPayload): Promise<any> {
        return await ApiInterface.post('/SalesCampaign/CreateCampaign/', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
    public async UpdateCampaign(data: ICampaignPayload): Promise<any> {
        return await ApiInterface.put('/SalesCampaign/UpateCampaign', data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async DesactivateCampaign(campaignId: string): Promise<any> {
        return await ApiInterface.put('/SalesCampaign/DesactivateCampaign?campaignId='
            .concat(campaignId)).then(res => {
                return res.data;
            }).catch(err => {
                console.log(err);
            })
    }
    public async DesactivateCourseCampaign(campaignId: any, courseId: any): Promise<any> {
        return await ApiInterface.put('/SalesCampaign/DesactivateCourseCampaign'
            .concat('?campaignId=')
            .concat(campaignId)
            .concat('&courseId=')
            .concat(courseId)
        ).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    public async getCampaignCoursesLinked(startDate: any, endDate: any, textSearchFilter: string): Promise<any> {
        return await ApiInterface.get(`/SalesCampaign/GetSalesCampaignCoursesPaginated?initialDate=${formatDateToSend(startDate)}&finalDate=${formatDateToSend(endDate)}`
            .concat('&PageNumber=1')
            .concat('&PageSize=10')
            .concat('&search=').concat(textSearchFilter)
        ).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async GetActiveSalesCampaign(data: any, pageNumber: number) {
        return await ApiInterface.get('/SalesCampaign/GetActiveSalesCampaign?'
            .concat('&PageSize=10').concat('&PageNumber=') + pageNumber + data, {
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
    public async GetInactiveSalesCampaign(data: any, pageNumber: number) {
        return await ApiInterface.get('/SalesCampaign/GetInactiveSalesCampaign?'
            .concat('&PageSize=10').concat('&PageNumber=') + pageNumber + data, {
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
    public async GetCampaignDetails(id: string) {
        return await ApiInterface.get('/SalesCampaign/GetCampaignDetails?campaignId=' + id, {
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}