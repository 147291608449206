import { Col, FormControl, Form, InputGroup, Row } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { AddModuleContext } from '../../../../../../../context/creator/createCourse/AddModuleContext';
import { LessonContentButtonBar } from './LessonContentButtonBar';
import { AddVideoModalComponent } from '../../../../../../../components/Modals/AddVideoModal/AddVideoModal';
import { CreateCourseContext } from '../../../../../../../context/creator/createCourse/CreateCourseContext';
import { LessonContentDetailsStyles, AddImageCoverStyles } from '../../AddingModulePageStyles';
import { RenderLessonContent } from './RenderLessonContent';
import { AddImageModalComponent } from '../../../../../../../components/Modals/AddImageModal/AddImageModal';
import { AddEmbedModal, AddEmbedModalComponent } from '../../../../../../../components/Modals/AddEmbedModal/AddEmbedModal';
import { AddDocumentModalComponent } from '../../../../../../../components/Modals/AddDocumentModal/AddDocumentModal';
import { TooltipContainer } from '../../../../../../../components/Tooltip/styles';
import { InvalidTextContentPasteModal } from '../../../../../../../components/Modals/InvalidTextContentPasteModal';
import { useCreateLessonModuleContentContext } from '../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

export const LessonDetailsComponent = () => {
    const {reRender} = useContext(CreateCourseContext)
    const {
        module,
        selectedModuleContentIndex,
        handleModuleContentTitleChange,
        handleModuleContentTitleBlur,
        

        showInvalidTextPasteModal,
        setIsValidModuleContent,
        validateModuleContent,
        handleLessonContentFocusLose
    } = useContext(AddModuleContext);

    const {
        setSelectedLessonContentIndex,

        setVideoInModal,
        getVideoFromModal,
        onCloseVideoModal,

        getImageFromModal,
        onCloseImageModal,
        
        getEmbedFromModal,
        onCloseEmbedModal,

        getDocumentFromModal,
        onCloseDocumentModal,

        showVideoModal,
        showImageModal,
        showEmbedModal,
        showDocumentModal,
    } = useCreateLessonModuleContentContext()

    const OnClickEvent = (e) =>{
        let isValid = validateModuleContent(module.moduleContents[selectedModuleContentIndex])
        setIsValidModuleContent(isValid)

        if (e.target.id === "base-col" || e.target.id === "lesson-image" || e.target.id ==="lesson-title" ){
            handleLessonContentFocusLose();            
        }
    }

    return <>
        <LessonContentDetailsStyles>
            <Col onClick={(e)=>OnClickEvent(e)} id="base-col">
                {/* <AddImageCoverStyles id="lesson-image">
                    {module.moduleContents[selectedModuleContentIndex].lesson!.coverImage !== undefined && module.moduleContents[selectedModuleContentIndex].lesson!.coverImage?.toString() !== "" ?
                        (
                            <>
                                imagem
                            </>
                        )
                        :
                        (
                            <>
                                <Col md="auto" className='my-auto pr-1'>
                                    <i className='bi bi-image' />
                                </Col>
                                <Col className='my-auto pt-1'>
                                    <p>
                                        Inserir imagem de capa (opcional)
                                    </p>
                                </Col>
                            </>
                        )}

                </AddImageCoverStyles> */}
                <Row className='mt-3'onClick={()=>handleLessonContentFocusLose()}>
                    <Col className="mx-2">
                        <Form.Label htmlFor="lesson-title">Título da aula</Form.Label>
                        <InputGroup>                            
                            <FormControl
                                id="lesson-title"
                                placeholder="Inclua o título da aula"
                                autoComplete="off"
                                aria-describedby="lesson-title"
                                onChange={(e) => handleModuleContentTitleChange(e)}
                                onBlur={() => handleModuleContentTitleBlur()}
                                value={module.moduleContents[selectedModuleContentIndex].title} />
                        </InputGroup>
                    </Col>
                </Row>
                {
                    module.moduleContents[selectedModuleContentIndex].title === "" &&
                    (
                        <Row>
                            <Col className="mx-2" md="auto">
                                <TooltipContainer className="mt-3">
                                    <Row className="pl-2">
                                        <Col md={1}>
                                            <i className="bi bi-arrow-up"></i></Col>
                                        <Col md="auto">
                                            <p>Insira o título da aula para liberar os blocos de conteúdo</p>
                                        </Col>
                                    </Row>
                                </TooltipContainer>
                            </Col>
                        </Row>
                    )
                }
                {
                    module.moduleContents[selectedModuleContentIndex].lesson!.lessonContents?.sort((a, b) => a.order -b.order ).map(lessonContent => {
                        return (<RenderLessonContent
                            lessonContent={lessonContent}/>
                        )
                    })
                }
            </Col>
        </LessonContentDetailsStyles>
        <LessonContentButtonBar />
            {
                showVideoModal&&
                <AddVideoModalComponent
                    setVideoOnModal={setVideoInModal}
                    getVideoVideoFromModal={getVideoFromModal}
                    onClose={onCloseVideoModal} 
                    reRender={reRender}
                    />
            }
            {
                showImageModal&&
                <AddImageModalComponent
                    getImageFromModal={getImageFromModal}
                    onClose={onCloseImageModal} 
                    reRender={reRender}
                    />
            }
            {
                showEmbedModal&&
                <AddEmbedModalComponent
                    getEmbedFromModal={getEmbedFromModal}
                    onClose={onCloseEmbedModal} 
                    reRender={reRender}
                    />
            }
            {
                showDocumentModal&&
                <AddDocumentModalComponent
                    getDocumentFromModal={getDocumentFromModal}
                    onClose={onCloseDocumentModal} 
                    reRender={reRender}
                    />
            }
            {
                showInvalidTextPasteModal&&
                <InvalidTextContentPasteModal/>
            }
    </>;
};

