import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from 'styled-components';
import {Col} from 'react-bootstrap'

interface ChangeLessonLoaderProps{
    sideMenuIsOpen:boolean

}
const ChangeLessonLoader = (props:ChangeLessonLoaderProps) =>{
    return(
        <ChangeLessonLoaderStyles 
            className="my-auto"
            sidemenuisopen={props.sideMenuIsOpen}>
            <Loader  
                type="TailSpin"
                color="#6468C8"
                height={100}
                width={100}
            />
        </ChangeLessonLoaderStyles>
    )
}

export default ChangeLessonLoader;

const ChangeLessonLoaderStyles = styled(Col)`
    background-color: #ffffff;
    padding-left:35vw;
    ${({ sidemenuisopen }) => sidemenuisopen && `
        padding-left:45vw;
    `}
`