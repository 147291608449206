import { IOrderningModuleContentPayload } from "../domain/payloads/createCourse/IOrderningModuleContentPayload";
import { IOrderningModulesPayload } from "../domain/payloads/createCourse/IOrderningModulesPayload";
import ApiInterface from "../interface/ApiInterface"

export default class ModuleServices {    

    public async getDependencyModulesByTrilha(courseId:string):Promise<any> {
        return await ApiInterface.get('/Module/GetDependencyModulesByCourse/'+ courseId, {
        }).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        });
    }

    public async orderingModules(data: IOrderningModulesPayload): Promise<any> {
        return await ApiInterface.post('/module/ordering/course/'.concat(data.courseId), data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }
    
    public async orderingModuleContent(data: IOrderningModuleContentPayload): Promise<any> {
        return await ApiInterface.post('/module/'.concat(data.moduleId).concat('/ordering/content'), data).then(res => {
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

}
