import React, { useState, useContext } from 'react';
import { CreateCourseContext } from '../../context/creator/createCourse/CreateCourseContext'
import {formatTime} from '../../util/Formatter'

import styled from 'styled-components'
import { Modal, Row, Col, Container } from 'react-bootstrap';

import {
    PrimaryPurpleButton,
    SecondaryYellowButton,
    TertiaryPurpleButton,
    TertiaryYellowButton
} from '../Buttons/styles';

function CreateCourseHeader() {

    const { 
        course,
        isValid,
        isChanged,
        updateDate,
        submitStepData,
        isEdit
    } = useContext(CreateCourseContext);

    const [modalShow, setModalShow] = useState(false);
    const [isCloseCreate, closeCreate] = useState(false);

    const handleExitCreation = () => {
        if (isChanged) {
            setModalShow(true)
        } else {
            window.location.href = "/";
        }
    }
   
    return (
        <CreateCourseHeaderStyles className="mb-1">
            <Container fluid={true}>
                <Row>
                    <Col md={"auto"} className="my-auto ml-2">
                        <h4 className="ml-2 mt-1 text-left">
                            {isEdit ? "Editar Curso" : "Criar Curso"}
                            {(course.title.length > 0 ? ": ".concat(course.title) : "")}
                        </h4>
                    </Col>

                    {updateDate &&
                        <Col md={"auto"} className="my-auto ml-auto">                                
                            <h5 className="mt-1 text-left">                                    
                                <Row>
                                    <Col md={"auto"} className="my-auto pr-0">
                                        <i className="bi bi-file-check" />
                                    </Col>
                                    <Col md={"auto"} className="my-auto">
                                        Rascunho salvo às {formatTime(updateDate)}
                                    </Col>
                                </Row>
                            </h5>
                        </Col>
                    }
                    
                    <Col md={"auto"} className={updateDate != undefined ?  "ml-5" : "ml-auto"}>
                        <TertiaryPurpleButton 
                            onClick={() => submitStepData(false, true)}
                            disabled={!isValid||!isChanged}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi-pencil-square" />
                                </Col>
                                <Col md={"auto"} className="my-auto">
                                    Salvar Rascunho
                                </Col>
                            </Row>
                        </TertiaryPurpleButton>
                    </Col>
                    <Col md={"auto"}>
                        <TertiaryYellowButton onClick={handleExitCreation}>
                            <Row>
                                <Col md={"auto"} className="my-auto pr-0">
                                    <i className="bi-x" />
                                </Col>
                                <Col md={"auto"} className="my-auto">
                                    Sair da criação
                                </Col>
                            </Row>
                        </TertiaryYellowButton>
                    </Col>
                </Row>
            </Container>

            <ModalCloseCreateTrilha                
                show={modalShow}
                onHide={() => setModalShow(false)}
                onYes={() => closeCreate(true)} />
        </CreateCourseHeaderStyles>
    );
}

const CreateCourseHeaderStyles = styled.header`
    z-index:100;
    @media(min-height: 900px) {
        min-height: 7vh;  
        button{
            min-height: 6.7vh;  
        }       
    }
    @media(max-height: 900px) {
        min-height: 8vh; 
        button{
            min-height: 8vh;  
        }         
    }
    @media(max-height: 800px) {
        min-height: 9vh;
        button{
            min-height: 9vh;  
        }          
    }  
    @media(max-height: 700px) {
        min-height: 10vh;   
        button{
            min-height: 10vh;  
        }       
    }       
    position:fixed;
    top:0px;
    width:100%;
    background: #F9F9F9  0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #514F4E33;
    opacity: 1;
    h4{
        cursor:default;
        font-size: 3vmin !important;
        color:#514F4E;
        position: relative;
        letter-spacing: 0px;
        opacity: 1;   
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 40vw; 
        overflow: hidden;        
    }
    h5
    {        
        font-size: 2.3vmin !important;
        color: #817f7f;     
        position: relative;
        letter-spacing: 0px;
        opacity: 1;    
    }    
    button{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        margin-bottom:0px;
        i{
            font-size: 3.33vmin;
        }
    }
`

export default CreateCourseHeader

function ModalCloseCreateTrilha(props: any) {

    const exit = () =>
    {     
        window.location.href = "/home";
    }

    return (
        <ConfirmExitCreateTrilha
            {...props}
            centered
        >
            <Modal.Header className="mx-3 mt-4">
                <Container>
                    <Row>
                        <Col md="auto" className="mx-auto">
                            <i className="bi-exclamation-circle" />
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Container className="px-0">
                    <Row>
                        <Col xs={12} md={12}>
                            <Modal.Title className="mt-4">Deseja sair da criação de curso?</Modal.Title>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} md={12}>
                            <p>
                                As informações inseridas até a etapa anterior, ou a última vez que
                                você clicou em “Salvar Rascunho”, serão salvas. As informações
                                inseridas depois serão descartadas.
                            </p>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col xs={6} md={6} className="text-center">
                            <SecondaryYellowButton onClick={() => exit()}>
                                <Row>
                                    <Col md="auto" className="my-auto pr-0 ml-auto">
                                        X
                                    </Col>
                                    <Col md="auto" className="my-auto mr-auto">
                                        <p>
                                            Sair
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryYellowButton>
                        </Col>
                        <Col xs={6} md={6} className="text-center">
                            <PrimaryPurpleButton className="px-4" onClick={props.onHide}>
                                Continuar Criação
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </ConfirmExitCreateTrilha>
    );
}

const ConfirmExitCreateTrilha = styled(Modal)`
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: center;
    .modal-content{
        border-radius: 12px!important;
    }
    .modal-dialog{
        max-width:64vmin;
    }
    .modal-header{
        border-bottom:none;
        padding-bottom:0px!important;
        i{
            font-size:11.11vmin;
            color:#C57700;
        }
    }
    .modal-footer{
        border-top:none;
        padding-bottom:0px!important;
    }
    .modal-body{
        padding-top:0px!important;
        button{
            height:6.66vmin;
            width:100%;

            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;
            i{
                font-size: 3vmin;
            }        }
        p{
            color:#514F4E;
            font-size: 2.22vmin;
            line-height:3.33vmin;
            letter-spacing: 0px;
            font-family: lato, sans-serif;
            font-weight: normal;
            font-style: normal;
        } 
    }
    .modal-title{
        cursor:default;
        color:#514F4E;
        font-size: 2.8vmin !important;
        line-height:3vmin;
        letter-spacing: 0px;
        font-family: lato, sans-serif;
        font-weight: 500;
        font-style: normal;
    }

`