import { Col } from 'react-bootstrap';
import { useContext } from 'react';
import { AddModuleContext } from '../../../../../../context/creator/createCourse/AddModuleContext';
import ModuleContentTypeEnum from "../../../../../../domain/enum/ModuleContentTypeEnum";
import { ChooseModuleContentType } from './ChooseModuleContentType';
import { ModuleContentDetails } from './ModuleContentDetails';

export const ModuleContentForm = () => {

    const {
        getSelectedModuleContent
    } = useContext(AddModuleContext);
    const moduleContent = getSelectedModuleContent()
    return (
        <Col md={9}>
            {moduleContent.moduleContentTypeId === ModuleContentTypeEnum.Undefined ?
                (
                    <ChooseModuleContentType />
                )
                :
                (
                    <ModuleContentDetails />
                )}
        </Col>
    );
};


