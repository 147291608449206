import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

export default function NoCoursesCompleted() {
  return (
    <BodyNoTasks className=" p-0">
      <Row className="my-auto mx-auto">
        <Col xs={12} md={"auto"} className="mx-auto col-6">
          {/* <img alt ='' className="" src="img/taskscorrection/woman-playball.svg" /> */}
          <svg
            className="no-course-img"
            width="156"
            height="191"
            viewBox="0 0 156 191"
            fill="none"
          >
            <path
              d="M84.9762 52.4498C85.2579 52.3763 85.5458 52.2944 85.817 52.2125C90.6515 50.8013 95.4124 48.4115 98.7356 44.558C101.985 40.8011 104.117 36.158 107.803 32.7623C112.155 28.755 118.187 27.1151 123.972 28.367C126.965 28.9893 129.877 29.9532 132.651 31.2398C137.069 33.2694 141.023 36.1851 144.266 39.8057C149.611 45.7445 152.884 53.3339 154.29 61.4693C154.593 63.2515 154.807 65.0561 154.933 66.8831C155.126 69.6563 155.119 72.4396 154.912 75.2117C154.482 81.0064 153.214 86.7083 151.148 92.1398C149.728 95.8373 148.067 99.4375 146.174 102.917C145.233 104.647 144.249 106.363 143.219 108.043"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M154.931 66.8739C148.533 64.8578 143.23 60.329 140.241 54.3281C137.252 48.3271 136.834 41.3699 139.083 35.0547C140.96 36.463 142.695 38.0511 144.264 39.7965C149.609 45.7353 152.882 53.3247 154.288 61.4601C154.588 63.2437 154.802 65.0483 154.931 66.8739V66.8739Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M19.3785 81.459C19.3785 81.459 -12.2096 108.809 7.29023 140.614C11.0359 146.723 13.9366 151.79 16.1731 155.984C16.3139 156.242 16.4463 156.494 16.5808 156.744C25.5162 173.674 23.3239 175.854 23.3239 175.854"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M68.0599 54.13C64.1133 53.9545 60.1818 53.5295 56.2889 52.8574"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M56.2825 170.184V174.804C56.2806 175.307 55.9249 175.739 55.4313 175.839C55.3624 175.855 55.2918 175.863 55.2211 175.862H23.324"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M56.2826 148.617V120.674C56.286 120.014 56.0254 119.379 55.5587 118.91C55.0919 118.442 54.4575 118.179 53.796 118.18H17.2221C16.9408 118.18 16.6712 118.292 16.4737 118.492C16.2762 118.693 16.1672 118.963 16.1711 119.244V155.98"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M56.2741 148.632V137.301H16.1731V155.982"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M51.3659 156.744H16.5807"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <ellipse
              cx="36.2255"
              cy="147.021"
              rx="2.92171"
              ry="2.919"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <ellipse
              cx="36.2255"
              cy="128.362"
              rx="2.92171"
              ry="2.919"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <ellipse
              cx="36.2255"
              cy="166.694"
              rx="2.92171"
              ry="2.919"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M74.9668 118.188H53.796H17.2325H12.7155V114.129H76.9006"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M47.8328 102.231L45.941 112.788C45.8294 113.396 45.2988 113.838 44.6798 113.838H34.8007C34.1741 113.837 33.6403 113.382 33.5395 112.765L31.797 102.225L47.8328 102.231Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M49.6235 101.052C49.5708 101.718 49.0141 102.231 48.3455 102.23H31.3092C30.6477 102.231 30.0941 101.729 30.0312 101.071C29.9969 100.711 30.116 100.354 30.3593 100.086C30.6025 99.8187 30.9474 99.6662 31.3092 99.666H48.3497C48.7073 99.6672 49.0482 99.8175 49.2901 100.081C49.5319 100.344 49.6528 100.696 49.6235 101.052V101.052Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M40.3622 99.6655C40.3622 99.1258 41.739 92.3869 40.5976 86.4124C39.967 83.1364 38.5882 80.0893 35.8052 78.4219"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M40.5975 86.4135C40.5975 86.4135 40.9128 81.8754 44.8393 81.5625"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M41.0056 93.3382C42.1234 92.5577 43.469 92.1699 44.8312 92.2357"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M41.0056 90.1166C41.0056 90.1166 38.1616 86.7419 33.6067 89.4089"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.0066 88.7592C39.0066 88.7592 32.5851 82.9002 31.3365 90.8235C31.3365 90.8235 33.1421 92.0016 35.8137 90.8235C38.4853 89.6454 39.0066 88.7592 39.0066 88.7592Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M42.0101 82.9446C42.0101 82.9446 41.7767 80.307 44.8393 79.4439C47.9018 78.5808 49.6233 82.1907 49.6233 82.1907C49.6233 82.1907 47.2712 84.7905 42.0101 82.9446V82.9446Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M42.7185 92.2325C42.7185 92.2325 42.6134 89.5676 46.1762 90.1178C49.739 90.668 50.3128 93.7025 50.3128 93.7025C50.3128 93.7025 44.4799 95.8403 42.7185 92.5139"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.4286 80.8895C38.4286 81.0785 35.7486 80.6501 35.5804 80.5997C34.2814 80.2133 33.3608 79.3145 32.7554 78.128C32.5683 77.7605 31.4942 75.6941 31.7948 75.335C31.7948 75.3392 38.1658 73.2875 38.4286 80.8895Z"
              stroke="#CCDDE2"
              stroke-width="1.70607"
            />
            <path
              d="M94.0061 83.9104C94.0061 83.9104 98.086 98.4802 90.4979 103.926C90.5904 104.375 97.737 103.506 97.737 103.506L97.3377 111.36C97.3377 111.36 105.586 114.564 109.42 114.6C113.254 114.636 117.067 110.963 118.004 109.092C118.942 107.22 135.482 110.186 134.038 122.443C134.038 122.443 123.494 133.477 111.852 130.02C111.852 130.02 103.908 126.887 107.055 130.562C110.202 134.237 128.253 147.003 130.338 172.377C130.338 172.377 145.42 163.274 146.185 135.258C146.95 107.241 138.872 95.7355 130.603 86.9743C130.603 86.9743 124.341 82.5811 123.372 83.9104C122.403 85.2397 116.062 90.3973 113.958 90.0403C111.854 89.6833 108.703 89.8303 109.689 87.3607C110.675 84.8911 111.446 86.023 111.089 84.1708C110.731 82.3186 110.649 82.1065 108.451 82.3186C106.252 82.5307 94.0061 83.9104 94.0061 83.9104Z"
              fill="#636060"
            />
            <path
              d="M57.9745 170.186C56.0827 172.027 54.1531 173.928 52.2446 175.856H32.8772C31.8374 175.896 30.8589 175.364 30.3271 174.471C29.7953 173.577 29.7953 172.464 30.3271 171.571C30.8589 170.677 31.8374 170.146 32.8772 170.186H57.9745Z"
              fill="#6B70DF"
            />
            <path
              d="M132.684 66.9925C132.68 67.4797 132.648 67.9663 132.589 68.4499V68.4646L131.656 71.6671V71.6902C129.607 76.075 124.993 78.0868 122.58 78.8722C122.077 79.0383 121.565 79.1784 121.048 79.2922C120.404 77.2216 120.648 75.1951 121.914 73.3828C122.469 72.5932 123.284 72.0073 123.751 71.1652C124.194 70.3735 124.238 69.4264 123.572 68.7544C123.352 68.5309 123.093 68.3481 122.809 68.2147C122.318 67.958 121.735 67.944 121.233 68.1769C121.138 68.2294 121.048 68.2735 120.959 68.3176C119.908 67.5847 120.634 66.52 120.959 65.6107C120.999 65.5018 121.032 65.3903 121.056 65.2768C121.055 65.2671 121.055 65.2572 121.056 65.2474C121.153 64.9366 121.863 62.3347 120.02 60.0478C119.87 59.8644 119.738 59.6668 119.627 59.4577C119.251 58.7561 119.205 57.9244 119.501 57.1855C119.775 56.5266 120.201 55.9416 120.745 55.4782C121.3 54.9898 121.983 54.6712 122.714 54.5605C125.941 54.9973 129.355 57.6958 129.577 57.8806C129.582 57.8826 129.587 57.8826 129.592 57.8806C131.442 60.0079 132.722 62.992 132.684 66.9925Z"
              fill="#6B70DF"
            />
            <path
              d="M129.586 57.8664C129.266 57.6081 122.635 52.356 119.11 55.464C117.554 55.7275 116.052 56.2417 114.662 56.9865H114.542C114.542 56.9865 115.631 53.259 120.11 53.3031C122.782 53.3409 126.817 54.6492 129.586 57.8664Z"
              fill="#6B70DF"
            />
            <path
              d="M140.028 74.7666C139.48 77.7927 136.955 80.424 137.067 83.5236C137.172 86.4636 138.467 89.3154 138.784 92.2512C138.895 93.2865 138.86 94.9812 138.645 96.8229V96.9027C136.808 93.5007 132.161 88.7967 130.124 86.8227C130.451 86.1292 130.835 85.4641 131.272 84.834C132.598 82.944 133.811 81.0183 134.647 78.8658C135.196 77.4966 135.965 75.4848 135.278 74.0358C135.165 73.7836 134.995 73.5608 134.782 73.3848C134.75 73.0882 134.67 72.7987 134.544 72.528C134.307 71.9523 133.753 71.5699 133.13 71.5515C132.668 71.524 132.205 71.589 131.768 71.7426V71.7069V71.6838C132.246 70.6723 132.561 69.5914 132.701 68.4813V68.4666C133.699 67.5342 135.507 66.5136 138.002 67.7652C139.053 68.2902 139.652 69.2142 139.955 70.2852C140.384 71.7195 140.272 73.4268 140.028 74.7666Z"
              fill="#6B70DF"
            />
            <path
              d="M122.63 148.552C117.064 140.334 110.616 133.264 104.451 127.466C102.933 126.046 101.436 124.689 99.9714 123.429C96.9593 120.811 94.086 118.532 91.5258 116.609C86.9909 113.198 80.7097 113.326 76.3182 116.919C75.2142 117.81 74.277 118.889 73.5499 120.107L43.2335 170.186H32.8772C31.8374 170.146 30.8589 170.677 30.3271 171.571C29.7953 172.464 29.7953 173.577 30.3271 174.471C30.8589 175.364 31.8374 175.896 32.8772 175.856H52.2446C54.1531 173.926 56.0785 172.025 57.9745 170.186C69.325 159.15 79.9819 149.98 84.0996 146.496"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M97.3377 111.36C96.9247 104.673 98.3987 98.0045 101.592 92.1133"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M120.228 103.311C116.28 119.709 103.318 113.443 103.318 113.443L98.3003 111.692L86.935 107.735C85.1063 106.92 82.9413 106.973 81.2724 108.059C79.801 109.006 76.4379 112.979 76.3202 116.912"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M82.1008 141.156C82.7468 143.036 83.4131 144.816 84.0998 146.496C99.5365 184.21 126.275 174.808 126.275 174.808C149.906 163.353 150.658 120.108 138.519 96.8985C138.454 96.7578 138.378 96.6255 138.309 96.4785C136.045 92.2491 133.054 88.3725 129.018 85.8588C127.87 85.1448 126.649 84.5543 125.376 84.0969C124.644 83.8379 123.887 83.609 123.104 83.4102"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M85.0118 85.7165C90.4075 84.3032 95.942 83.564 101.512 82.9571C104.31 82.6547 107.486 82.3796 110.729 82.3145"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M77.8588 58.2168C77.8588 58.2168 76.8688 52.4796 79.3008 50.3439C80.3833 49.3947 80.5745 48.1578 80.8142 46.8222C81.14 45.0099 83.0611 44.2434 84.2424 45.8541C85.3081 47.3073 84.9508 49.9428 84.9508 51.6123"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M78.3844 51.5973C78.3844 51.5973 75.6245 51.6855 76.0449 45.7173V45.7026C76.1164 44.7597 76.8184 43.2456 77.7454 43.0629L78.4643 42.9222C79.5739 42.702 80.3736 41.7299 80.3749 40.5996V33.9531C80.3749 32.8464 81.1169 31.8279 82.2015 31.6137C82.8748 31.4779 83.5737 31.6507 84.1058 32.0847C84.6379 32.5187 84.9473 33.1682 84.9488 33.8544L85.0097 88.2738"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M77.6594 54.7117C72.8039 55.2871 72.4339 49.2517 72.4991 46.6036C72.4991 46.5889 72.4991 46.5826 72.4991 46.5742"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M69.5164 49.2598C69.5164 49.2598 68.9342 57.0088 73.8065 52.9957"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M122.691 189.085C122.26 189.618 121.626 189.947 120.941 189.992C120.256 190.037 119.583 189.793 119.087 189.32C118.721 189 118.324 188.719 117.901 188.48C120.323 180.846 112.55 175.611 112.341 175.477C113.407 175.855 114.422 176.216 115.368 176.571C116.069 176.831 116.735 177.08 117.367 177.318C119.64 178.183 121.48 179.908 122.49 182.119C123.314 183.91 124.098 187.413 122.691 189.085Z"
              fill="#6B70DF"
            />
            <path
              d="M138.305 96.4902C143.959 104.508 139.742 115.691 134.046 122.442C126.292 131.634 115.063 132.596 104.451 127.465"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M123.73 83.9102C122.662 82.3373 121.428 80.5502 121.037 79.2902C120.394 77.2217 120.638 75.1952 121.907 73.3808C122.46 72.5912 123.272 72.0053 123.744 71.159C124.188 70.3673 124.23 69.4223 123.564 68.7482C122.986 68.1623 122.048 67.7675 121.224 68.1728C120.522 68.5172 120.146 68.8973 119.332 68.3345C118.122 67.4945 119.204 66.2345 119.4 65.2664C119.433 65.1068 119.467 64.9472 119.509 64.7812"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M109.428 75.6328L111.446 86.011L111.86 88.1509"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M111.297 85.2227C109.615 86.8565 108.098 89.1581 111.795 89.9099C116.73 90.9137 122.08 85.6826 123.73 83.9102"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M114.527 56.9921C114.527 56.9921 115.616 53.2709 120.091 53.3129C124.566 53.3549 132.781 56.9564 132.688 66.9965C132.596 77.0366 121.033 79.2941 121.033 79.2941"
              stroke="#636060"
              stroke-width="1.70607"
            />
            <path
              d="M97.9683 16.2889C97.9665 22.0986 94.668 27.4049 89.4564 29.982C84.2448 32.5591 78.0213 31.9614 73.3965 28.4395C68.153 22.5625 68.2385 13.6668 73.5938 7.89134C78.9491 2.11589 87.8206 1.35207 94.086 6.12699C96.5913 8.91937 97.9741 12.5389 97.9683 16.2889Z"
              fill="#6B70DF"
            />
            <path
              d="M129.6 57.8777C129.6 57.8777 122.717 52.2812 119.107 55.4627C119.107 55.4627 116.409 57.6152 118.37 60.047C120.331 62.4788 119.395 65.2802 119.395 65.2802"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M131.641 71.73C132.545 71.4339 133.84 71.2701 134.418 72.5133C135.107 73.9707 134.336 75.9825 133.787 77.3433C132.947 79.4958 131.736 81.4257 130.412 83.3136C129.857 84.1131 129.388 84.9683 129.012 85.8651"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M138.519 96.9057C138.519 96.8763 138.519 96.8553 138.519 96.8259C138.729 94.9842 138.771 93.2895 138.66 92.2542C138.34 89.3142 137.045 86.4708 136.942 83.5266C136.831 80.427 139.356 77.7957 139.902 74.7696C140.322 72.447 140.362 68.9862 137.882 67.7514C135.402 66.5166 133.581 67.5225 132.581 68.4528"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M113.398 75.8007C111.994 75.9939 110.578 76.0359 109.428 75.6348C107.179 74.8389 105.941 72.3252 107.805 65.7774C110.134 57.5979 116.827 55.8402 119.091 55.4874C118.919 55.48 118.748 55.5116 118.59 55.5798"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <ellipse
              rx="3.73748"
              ry="1.26484"
              transform="matrix(0.676188 -0.736729 0.737354 0.675507 113.31 61.8275)"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M119.299 65.6133C117.681 64.5763 116.145 63.4187 114.702 62.1504"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M58.3064 169.668C57.8482 172.73 59.6327 178.507 78.9686 179.647C102.073 181.008 114.071 186.132 117.901 188.467C118.323 188.705 118.72 188.987 119.084 189.307C119.581 189.78 120.254 190.024 120.939 189.979C121.625 189.935 122.26 189.606 122.691 189.072C124.097 187.392 123.322 183.887 122.481 182.104C121.471 179.893 119.63 178.168 117.356 177.303C116.727 177.065 116.061 176.816 115.358 176.556C114.412 176.201 113.396 175.84 112.331 175.462C109.512 174.456 106.328 173.362 102.996 172.312C100.02 171.379 96.9339 170.47 93.8903 169.678C93.6023 169.605 93.3291 169.529 93.0495 169.468C89.2219 168.498 85.4825 167.738 82.195 167.354"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M93.8882 169.691C95.5694 169.222 97.285 168.886 99.019 168.686"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M72.5031 47.8484C72.4589 47.399 72.3959 46.8341 72.1289 46.4561C71.759 45.929 71.1788 46.5107 70.914 46.8446C69.4594 48.6737 68.8646 51.0215 68.276 53.237C67.7375 55.2707 67.3407 57.3392 67.0884 59.4278C66.5987 63.4178 66.5987 67.4624 66.563 71.4839C66.4878 75.9791 66.6907 80.4746 67.1704 84.9449C67.946 92.5343 70.708 100.145 78.6954 102.503C84.501 104.227 91.3071 104.145 97.737 103.494"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M72.4842 47.0187C72.4409 45.987 73.0495 45.0389 74.006 44.6478C74.8657 44.3265 76.0638 44.5617 76.0386 45.6768C76.0397 45.6901 76.0397 45.7034 76.0386 45.7167"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <ellipse
              cx="82.6576"
              cy="16.2838"
              rx="15.298"
              ry="15.2838"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M18.1445 175.851H61.966"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M115.368 176.57H144.264"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M100.755 15.1797C101.659 15.9042 102.162 16.7106 102.162 17.5464C102.162 18.5523 101.445 19.4973 100.163 20.3415"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M90.3171 23.3594C89.4952 23.4707 88.6355 23.5694 87.7716 23.6408"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M78.9309 23.7295C80.1921 23.8114 81.4848 23.8555 82.8111 23.8555C83.8032 23.8555 84.7806 23.8324 85.7349 23.782"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              d="M64.3771 15.623C63.7928 16.2362 63.4733 16.883 63.4733 17.5466C63.4733 18.7877 64.5768 19.9343 66.4727 20.9192C68.7155 22.0805 72.0913 22.9814 76.0955 23.4623"
              stroke="#636060"
              stroke-width="1.29248"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.4898 43.8125C50.597 43.8125 59.6012 52.8084 59.6012 63.9053V96.596H19.3783V63.9053C19.3783 52.8084 28.3825 43.8125 39.4898 43.8125V43.8125Z"
              stroke="#CCDDE2"
              stroke-width="1.29248"
            />
            <path
              d="M39.0189 88.7593H39.0042"
              stroke="#CCDDE2"
              stroke-width="1.29248"
            />
            <path
              d="M31.8828 88.7592H25.3521V65.781C25.3521 57.979 31.6827 51.6543 39.4919 51.6543C47.3011 51.6543 53.6317 57.979 53.6317 65.781V88.7592H40.9128"
              stroke="#CCDDE2"
              stroke-width="1.29248"
            />
          </svg>
        </Col>
        <Col xs={12} md={"auto"} className="message-end ml-md-auto my-md-auto col-6 p-md-5">
          <Row className="py-3">Você ainda não terminou nenhum curso!</Row>
          <Row>
            <p>
              Quanto você finalizar um curso, você pode acessar o seu
              certificado e revisar o conteúdo.
            </p>
          </Row>
        </Col>
      </Row>
    </BodyNoTasks>
  );
}

export const BodyNoTasks = styled.h1`
  //margin-top: 8.88vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #636060;
  width: flex;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #514f4e;
  h2 {
    color: #514f4e;
  }
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #0c8292;
  }
  img {
    position: initial !important;
    height: 234px;
    width: 232px;
  }

  .no-course-img {
    height: 38.5vmin;
    width: 38.2vmin;
  }

  @media screen and (max-width: 990px) {
    p {
      color: #514f4e;
      margin-top: 2vmin;
    }

    .no-course-img {
      height: auto;
      width: 70%;
      margin: auto auto 20px auto;
    }

    .message-end {
      padding: 0px 35px;
      margin-bottom: 20px;
    }
  }
`;

export const ExitTask = styled.button`
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 2.22vmin;
  line-height: 2.63vmin;
  color: #6468c8 !important;
  float: right;
  padding: 14px 14px;
  margin-right: 2rem;

  background: none;
  border: none !important;
  border-radius: 0px;
  box-shadow: none !important;
  i {
    font-size: 1.66vmin;
  }
`;
