import {CreateCourseContext, CreateCourseContextProvider} from '../../../context/creator/createCourse/CreateCourseContext'
import {CreateCourseIntroductionFormContextProvider} from '../../../context/creator/createCourse/CreateCourseIntroductionFormContext'

import PageLoader from '../../../components/Common/Loader'

import CreateCourseHeader from "../../../components/Headers/CreateCourseHeader";
import { CreateCourseStepBar } from "./CreateCourseStepBar";
import { CreateCourseStyles } from "./CreateCourseStyles";
import CreateCourseFooter  from "./CreateCourseFooter";

import { CreateCourseInitialDataForm } from "./1_InitialData/CreateCourseInitialDataForm";
import { CreateCourseIntroductionForm } from "./2_Introduction/CreateCourseIntroductionForm";
import { CreateCourseContentForm } from "./3_LessonContent/CreateCourseContentForm";
import { CreateCourseDependencyForm } from "./4_Dependency/CreateCourseDependencyForm";
import { CreateCourseFinalizationForm } from "./5_Finalization/CreateCourseFinalizationForm";
import { useContext } from 'react';
import { AddingModulePage } from './3_LessonContent/addModule/AddingModulePage';
import { AddModuleContextProvider } from '../../../context/creator/createCourse/AddModuleContext';

interface CreateCoursePageProps {
    isEdit: boolean
}

export const CreateCoursePageWrapper = ({isEdit}: CreateCoursePageProps) => {
    return(
        <CreateCourseContextProvider 
            isEdit={isEdit}      
        >
                <CreateCoursePage/>
        </CreateCourseContextProvider>
    )
}

export const CreateCoursePage = () => {

    const {
        navigationStep, 
        stepRef, 
        isLoading, 
        openModulePage: isAddingModule,        
    } = useContext(CreateCourseContext)

    const renderCourseStepPage = () => {
        switch (navigationStep) {
             case 1:
                return <CreateCourseInitialDataForm ref={stepRef} />;
            case 2:
                return (
                    <CreateCourseIntroductionFormContextProvider>
                        <CreateCourseIntroductionForm ref={stepRef} />
                    </CreateCourseIntroductionFormContextProvider>
                    );
            case 3:
                return <CreateCourseContentForm ref={stepRef} />;
            case 4:
                return <CreateCourseDependencyForm ref={stepRef} />;
            case 5:                
                return <CreateCourseFinalizationForm ref={stepRef} />;
            default:
                return <PageLoader/> // RETORNAR PAGINA DE ERRO/TRATATIVA CASO NAO CONTEMPLE NHN CONDICAO 
        }
    }
    
    return (
    <>
        {isLoading ? <PageLoader/> : <></>}
        {
            isAddingModule?
            (
                <AddModuleContextProvider>
                    <AddingModulePage ref={stepRef} />
                </AddModuleContextProvider>
            )
            :
            (<>
                <CreateCourseHeader />
                <CreateCourseStepBar />
                <CreateCourseStyles fluid={true}>
                    {renderCourseStepPage()}
                </CreateCourseStyles>
                <CreateCourseFooter/>
            </>)
        }
    </>
    )

}
