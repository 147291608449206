import { createContext, ReactNode, useState } from "react";
import {AddFileNavigationPositionEnum} from '../../../domain/enum/AddFileNavigationPosition'

interface CreateCourseIntroductionFormContextData{
    showAddVideoModal: boolean
    setShowAddVideoModal: React.Dispatch<React.SetStateAction<boolean>>

    showConfirmExitAddVideo: boolean
    setShowConfirmExitAddVideo: React.Dispatch<React.SetStateAction<boolean>>

    isInvalidVideoInput: boolean
    setIsInvalidVideoInput: React.Dispatch<React.SetStateAction<boolean>>

    videoObject: AddVideoObject
    setVideoObject: React.Dispatch<React.SetStateAction<AddVideoObject>>

    navigationPosition:AddFileNavigationPositionEnum
    setNavigationPosition:React.Dispatch<React.SetStateAction<AddFileNavigationPositionEnum>>

}

interface AddVideoObject{
    externalLink:string,
    videoName:string,
    storageLink:string
}



export const CreateCourseIntroductionFormContext = createContext({} as CreateCourseIntroductionFormContextData)

interface CreateCourseIntroductionFormContextProps{
    children:ReactNode
}
export const CreateCourseIntroductionFormContextProvider = (props:CreateCourseIntroductionFormContextProps) =>{
    const [showAddVideoModal, setShowAddVideoModal] = useState(false)
    const [showConfirmExitAddVideo, setShowConfirmExitAddVideo] = useState(false)
    const [navigationPosition, setNavigationPosition] = useState<AddFileNavigationPositionEnum>(AddFileNavigationPositionEnum.index)
    const [isInvalidVideoInput,setIsInvalidVideoInput] = useState(false)


    const [videoObject, setVideoObject] = useState<AddVideoObject>({
        externalLink:"",
        videoName:"",
        storageLink:""
    })
    
    return(
        <CreateCourseIntroductionFormContext.Provider
            value={{
                showAddVideoModal, 
                setShowAddVideoModal,
                
                showConfirmExitAddVideo,
                setShowConfirmExitAddVideo,

                isInvalidVideoInput,
                setIsInvalidVideoInput,

                navigationPosition,
                setNavigationPosition,

                videoObject,
                setVideoObject
            }}
        >
                {
                    props.children
                }
        </CreateCourseIntroductionFormContext.Provider>
    )
}



