import { useContext } from 'react';
import { Col, Row } from "react-bootstrap";
import { SecondaryGreenButton } from '../../../Buttons/styles';
import { PDFObject } from 'react-pdfobject'
import { AddDocumentModalContext } from '../context/AddDocumentModalContext';
import { StyledFileViewer } from '../../../../pages/creator/createTrilha/createTrilhaStyles';
import FileViewer from '../../../Common/FileViewer';

export function SendFileScreen() {

    const {
        handleAddFileButton,
        errorMessage,
        documentObject,
        isInvalidDocumentInput
    } = useContext(AddDocumentModalContext);

    return <>
        {
            isInvalidDocumentInput &&
            (
                <Row className='mb-3'>
                    <Col className="erro-message mx-auto py-2">
                        <Row>
                            <Col md="auto" className="my-auto pr-0">
                                <i className='bi bi-exclamation-octagon' />
                            </Col>
                            <Col className="my-auto">
                                <p>
                                    {errorMessage}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        {
            (documentObject.Url === "") ?
                (
                    <>
                        <Row>
                            <SecondaryGreenButton className="w-100" onClick={handleAddFileButton}>
                                <Row className='my-1'>
                                    <Col md="auto" className="ml-auto my-auto pr-0">
                                        <i className="bi bi-files" />
                                    </Col>
                                    <Col md={7} className="text-left">
                                        <p>
                                            Escolher Arquivo
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryGreenButton>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <p>
                                    São aceitos arquivos nos formatos .pdf, doc, docx, ppt, pptx, txt, com tamanho de até 50MB.
                                </p>
                            </Col>
                        </Row>
                    </>
                )
                : (
                    <>
                        <Row className="mt-1 mb-2">
                            <Col className='my-auto'>
                                <p>
                                    {documentObject.Name}
                                </p>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className='mx-auto'>
                                <FileViewer linkFile={documentObject.Url} />
                            </Col>
                        </Row>
                    </>
                )
        }
    </>;
}
