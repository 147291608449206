import { useContext } from 'react';
import { Col, Container, Modal, Row } from "react-bootstrap";
import { PrimaryPurpleButton, SecondaryYellowButton } from '../../../../../components/Buttons/styles';
import { CreateCourseIntroductionFormContext } from '../../../../../context/creator/createCourse/CreateCourseIntroductionFormContext';
import { AddFileNavigationPositionEnum } from '../../../../../domain/enum/AddFileNavigationPosition';
import styled from 'styled-components'

export const ConfirmExitAddVideoModal = () => {
    const {
        showAddVideoModal, setShowAddVideoModal, showConfirmExitAddVideo, setShowConfirmExitAddVideo, navigationPosition, setNavigationPosition, isInvalidVideoInput, setIsInvalidVideoInput, videoObject, setVideoObject
    } = useContext(CreateCourseIntroductionFormContext);

    const onConfirmClose = () => {

        setShowAddVideoModal(false);
        setShowConfirmExitAddVideo(false);

        setTimeout(() => {
            setVideoObject(
                {
                    externalLink: "",
                    videoName: "",
                    storageLink: ""
                }
            );
            setNavigationPosition(AddFileNavigationPositionEnum.index);
            setIsInvalidVideoInput(false);
        }, 200);
    };

    const onCancelClose = () => {
        setShowAddVideoModal(true);
        setShowConfirmExitAddVideo(false);
    };
    return (
        <ConfirmExitAddVideoModalStyles
            show={showConfirmExitAddVideo}
        >
            <Modal.Body className="px-0">
                <Container className="px-0">
                    <Row>
                        <Col>
                            <i className='bi bi-exclamation-circle' />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>
                                Deseja cancelar o envio?
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <p>
                                Você vai perder o conteúdo inserido.
                            </p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <SecondaryYellowButton onClick={onConfirmClose}>
                                <Row>
                                    <Col md="auto" className="pr-0">
                                        <i className='bi bi-x' />
                                    </Col>
                                    <Col className="my-auto">
                                        <p>
                                            Cancelar envio
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryYellowButton>
                        </Col>
                        <Col>
                            <PrimaryPurpleButton className="py-2" onClick={onCancelClose}>
                                <Col className="py-1 px-4">
                                    <p>
                                        Continuar envio
                                    </p>
                                </Col>
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </ConfirmExitAddVideoModalStyles>
    );
};

export const ConfirmExitAddVideoModalStyles = styled(Modal)`
    .modal-content{
        text-align: center;
        background: #FFFFFF;
        mix-blend-mode: normal;
        border: 0.28vmin solid #C57700;
        box-shadow: 0px 1.11vmin 1.11vmin  rgba(0, 0, 0, 0.15);
        border-radius: 1.11vmin;
        h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        }
        .bi-exclamation-circle{
            font-size:10vmin;
            color:#C57700;
        }
        button{
            .bi-x{
                font-size: 3vmin;
            }
            p{
                font-size: 2.22vmin;

            }
        }
    }

`
