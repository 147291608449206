import { Button, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const StudentHeaderStyles = styled.header`
    top:0px;
    z-index:1000;
    background: #F9F9F9;
    width:100%;
    max-height: 8.88vmin!important;
    box-shadow: 0px 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    position:fixed;
`
export const StudentHeaderTitle = styled.h1`
    top:0px;
    min-width: 100%;
    cursor:default;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.78vmin;
    line-height: 3.33vmin;
    color: #6468C8;
`

export const StudentHeaderButtons = styled(Col)`
    button{
        background: #F9F9F9;
        border:none;
        border-radius:0px;
        box-shadow:none!important;
        &:hover{
            background: rgba(100,104,200,0.2)!important;
        }
        &:focus{
            background: #F9F9F9!important;
            border: 0.14vmin solid #0C8292!important;
        }
        &:active{
            background: rgba(100,104,200,0.2)!important;
        }
    }
    i{
        color: #514F4E;
        font-size:4.16vmin;
    }
`

export const StudentHeaderTabs = styled(Col)`
    button{
        background: #F9F9F9;
        border:none;
        border-radius:0px;
        box-shadow:none!important;
        &:hover{
            background: rgba(100,104,200,0.2)!important;
        }
        &:focus{
            background: #F9F9F9!important;
            border: 0.14vmin solid #0C8292!important;
        }
        &:active{
            background: rgba(100,104,200,0.2)!important;
        }
    }

    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 3vmin;
        color: #514F4E;
    }
    i{
        color: #514F4E;
        font-size:4.16vmin;
    }
    .selected{
        border-bottom: 0.56vmin solid #6468C8;
    }
    .selected i{
        color: #6468C8;
    }
    .selected p{
        color: #6468C8;
    }
`
//Attend Header Styles

export const InfoButton = styled(Button)`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.63vmin;
    color: #636060!important;

    background: #F9F9F9!important;
    border:none!important;
    border-radius:0px;
    box-shadow:none!important;

    i{
            font-size:2.8vmin;
    }
`

export const ChangeLightModeButton = styled(Button)`
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 2.22vmin;
    line-height: 2.63vmin;
    color: #636060!important;

    background: #F9F9F9!important;
    border:none!important;
    border-radius:0px;
    box-shadow:none!important;

    i{
            font-size:2.8vmin;
    }

`

export const ExitButton = styled(Button)`
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.63vmin;
        color: #6468C8!important;

        background: #F9F9F9!important;
        border:none!important;
        border-radius:0px;
        box-shadow:none!important;
        i{
            font-size:1.66vmin;
        }
`

export const AttendCourseHeaderStyles = styled.header`
    top:0px;
    min-width: 100%;
    background: #F9F9F9;
    box-shadow: 0vmin 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    min-height: 8.88vmin;
    z-index:1000!important;
    position:sticky;
    max-width:90vw;
    .container-fluid{
        min-height: 8.88vmin!important;
    }
    h1{
        font-family: Lato;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        margin-bottom:0px;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 1.94vmin;
        line-height: 2.36vmin;
        color: #777575;

    }
    .bg-success{
        border-radius: 1.11vmin;
    }
    .progress{
        max-width: 31.5vmin;
        background: #C4C4C4;
        border-radius: 1.11vmin;
        height: 1.75vmin;
    }

    @media screen and (max-width: 990px){
        padding: 15px 0px;

        .holdHeaderQuizz {
            i {
                color: #6468C8 !important;
            }
        }

        h1{
            width: 200px;
            height: 21px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 6vmin;
            margin-top: 10px;
            position: relative;
            z-index: 200;
            line-height: 4.3vmin;
        }

        i {
            font-size: 5.6vmin;
        }
    }
`

export const AttendQuizHeaderStyles = styled.header`
    top:0px;
    min-width: 100%;
    background: #F9F9F9;
    box-shadow: 0px 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    .base{
        height: 8.9vmin;
        max-height: 8.9vmin;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        margin-bottom:0px;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.36vmin;
        color: #777575;
    }
    .bg-success{
        border-radius: 1.11vmin;
    }
    .progress{
        max-width:20vmax;
        background: #C4C4C4;
        border-radius: 1.11vmin;
        height: 1.75vmin;
    }
    .btn{
        min-height: 8.7vmin;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.64vmin;

        :hover{
            font-weight:700!important;
        }
        i{
            margin-right:1.4vmin;
            font-size:2.5vmin;
        }
    }
`

export const FeedBackQuizHeaderStyles = styled.header`
    top:0px;
    min-width: 100%;
    background: #F9F9F9;
    box-shadow: 0px 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    position:fixed;
    z-index:100;
    .base{
        height: 8.9vmin;
        max-height: 8.9vmin;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
        margin-bottom:0px;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.94vmin;
        line-height: 2.36vmin;
        color: #777575;
    }
    .bg-success{
        border-radius: 1.11vmin;
    }
    .progress{
        max-width:20vmax;
        background: #C4C4C4;
        border-radius: 1.11vmin;
        height: 1.75vmin;
    }
    .btn{
        background-color:#FFF0!important;
        border:none;
        min-height: 8.7vmin;

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.64vmin;
        color: #6468C8;

        :hover{
            font-weight:700!important;
        }
        :focus{
            box-shadow:none!important;
        }
        :active{
            box-shadow:none!important;
        }
        :disabled{
            background-color:#FFF0!important;
        }
        i{
            margin-right:1.4vmin;
            font-size:2.5vmin;
        }
    }
`



export const HomeHeaderStyles = styled.header`
    top:0px;
    min-width: 100%;
    z-index:500;
    background: #F9F9F9;
    max-height: 8.88vmin!important;
    box-shadow: 0px 0.14vmin 0.84vmin rgba(81, 79, 78, 0.2);
    position:fixed;
    h1{
        cursor:default;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
        color: #6468C8;
    }
    .col-md-auto.tab{
        button{
            background: #F9F9F9;
            border:none;
            border-radius:0px;
            box-shadow:none!important;
            &:hover{
                background: rgba(100,104,200,0.2)!important;
            }
            &:focus{
                background: #F9F9F9!important;
                border: 0.14vmin solid #0C8292!important;
            }
            &:active{
                background: rgba(100,104,200,0.2)!important;
            }
        }

        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 2.5vmin;
            line-height: 3vmin;
            color: #514F4E;
        }
        i{
            color: #514F4E;
            font-size:4.16vmin;
        }
        .selected{
            border-bottom: 0.56vmin solid #6468C8;
        }
        .selected i{
            color: #6468C8;
        }
        .selected p{
            color: #6468C8;
        }
    }
    .col-md-auto.nav-button{
        button{
            background: #F9F9F9;
            border:none;
            border-radius:0px;
            box-shadow:none!important;
            &:hover{
                background: rgba(100,104,200,0.2)!important;
            }
            &:focus{
                background: #F9F9F9!important;
                border: 0.14vmin solid #0C8292!important;
            }
            &:active{
                background: rgba(100,104,200,0.2)!important;
            }
        }
        i{
            color: #514F4E;
            font-size:4.16vmin;
        }
        .bi-plus-circle{
            color:#0C8292;
        }
    }

    @media screen and (max-width:992px){
        h1 {
            /* background: blue;
            color: #FFF;
            font-size: 30px;
            padding: 20px; */
        }
    }
`