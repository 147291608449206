import { Col, Row } from 'react-bootstrap';
import LessonContentViewModel from "../../../../../../../../domain/models/course/module/lesson/LessonContentViewModel";
import styled from 'styled-components';
import SunEditor from 'suneditor-react';
import { CreateCourseContext } from '../../../../../../../../context/creator/createCourse/CreateCourseContext';
import { AddModuleContext } from '../../../../../../../../context/creator/createCourse/AddModuleContext';
import { useContext } from 'react';
import { TertiaryYellowButton } from '../../../../../../../../components/Buttons/styles';
import { useCreateLessonModuleContentContext } from '../../../../../../../../context/creator/createCourse/CreateLessonModuleContentContext';

interface TextLessonComponentComponentProps {
    lessonContent: LessonContentViewModel;
    isFocused: boolean;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}
export const TextLessonComponent = (props: TextLessonComponentComponentProps) => {

    const {
        reRender, 
        setIsChanged
    } = useContext(CreateCourseContext)

    const {
        setIsValidModuleContent,
        validateModule,
        setShowInvalidTextPasteModal,
        deleteLessonContent
    } = useContext(AddModuleContext)

    const {
        getSelectedLessonContent,
    } = useCreateLessonModuleContentContext()
    
    const lessonContent = getSelectedLessonContent()
    
    const onFocus = () => {
        props.setSelectedIndex(props.lessonContent.order);
    };

    const handleChangeTextEditor = (content: string) => {
        setIsChanged(true)
        let flag = false;

        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = content;
        
        for (let index = 0; index < htmlObject.children.length; index++) {
            let div = htmlObject.children.item(index);
            if(div?.innerHTML !== "<br>"&&div?.innerHTML !== "&nbsp;"&&div?.innerHTML !=="&ZeroWidthSpace;"){
                flag = true
                break
            }  
        }

        if(flag){
            lessonContent.content = content;
            setIsValidModuleContent(true)            
        }else{
            lessonContent.content = "";
            setIsValidModuleContent(false)
        }

        validateModule();
        reRender()
    }

    const handleBlurTextEditor = (event: any, editorContents: string) => {        
        if(editorContents === "<p><br></p>")
        {            
            deleteLessonContent(lessonContent.order);
        }
    }

    function handlePaste(e: ClipboardEvent, dataToClean: string, maxCharCount) {
        setIsChanged(true)

        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = dataToClean;

        var tagP = htmlObject.getElementsByTagName("p");  

        if(tagP.length ==0){            
            var textTag = document.createElement('p')
            var text = dataToClean.replace('/<br ?/?>/g',"_")
            textTag.innerHTML = text
            
            htmlObject.innerHTML= textTag.outerHTML;
        
        }

        var images = htmlObject.getElementsByTagName("img");      

        if(images.length>0){
            setShowInvalidTextPasteModal(true)
            return ""
        }
        let cleanData = "";

        var filteredHTML = RemoveAttrFromTag(htmlObject.children);

        for (let index = 0; index < filteredHTML.length; index++) {
            cleanData = cleanData.concat(filteredHTML[index].outerHTML);
        }

        //e.clipboardData?.setData
        //let test = e.clipboardData
        //console.log("content antes de inserção",cleanData)
        //setIsChanged(true);

        setIsValidModuleContent(cleanData.trim()!=="");
        return cleanData;
    }

    function RemoveAttrFromTag(content:HTMLCollection):HTMLCollection{
        for (let index = 0; index < content.length; index++) {
            content[index].removeAttribute('style')
            if(content[index].children.length > 0){
                RemoveAttrFromTag(content[index].children)
            }
        }
        
        return content
    }
    
    return (
        <TextLessonContentComponentStyles
            onClick={onFocus}
            isFocused={props.isFocused}
        >
            <Col className="py-3">
                {props.isFocused ?
                    (
                        <>
                            <StyledSunEditor
                                defaultValue={lessonContent.content}
                                placeholder="Digite a introdução aqui"
                                height={"auto"}
                                onChange={(content) => handleChangeTextEditor(content)}
                                onBlur={handleBlurTextEditor}
                                onPaste={handlePaste}
                                setOptions={{
                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['bold', 'underline', 'italic'],
                                        ['align','list'],
                                        ['link','removeFormat'],
                                    ]
                                }}
                            />
                            <TertiaryYellowButton className="ml-auto delete-text py-1 px-2" 
                                onClick={() => deleteLessonContent(lessonContent.order)}>
                                <i className='bi bi-trash'/>
                            </TertiaryYellowButton>
                        </>                   
                    )
                    :
                    (
                        props.lessonContent.content !== "" ?
                        (<div className="pl-4 pr-2" dangerouslySetInnerHTML={{ __html: props.lessonContent.content }} />)
                        : <></>
                    )}
            </Col>
        </TextLessonContentComponentStyles>
    );
};

const StyledSunEditor = styled(SunEditor)`

`
const TextLessonContentComponentStyles = styled(Row)`
    .delete-text{
        position:absolute;
        z-index:100;
        right:1.5rem;
        top:1.05rem;
        i{
            font-size:3.3vmin;
        }
    }
`;
