import { Col, Row } from "react-bootstrap";
import HorizontalAlignEnum from "../../../../domain/enum/HorizontalAlignEnum";
import VideoSourceEnum from "../../../../domain/enum/VideoSourceEnum";
import VideoLessonContentViewModel from "../../../../domain/models/course/module/lesson/VideoLessonContentViewModel";
import { GetVimeoId } from "../../../../util/VimeoUtils";
import { GetYoutubeId } from "../../../../util/YoutubeUtils";
import { AttendMediaContentComponentStyles } from "../AttentCoursePageStyles";


export default (props: AttendVideoContentComponentProps) => {

    const returnVideoUrlSRC = (): string => {
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Azure) {
            return props.videoLesson.VideoObject.VideoLink;
        }
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Youtube) {
            return "https://www.youtube.com/embed/" + GetYoutubeId(props.videoLesson.VideoObject.VideoLink);
        }
        if (props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Vimeo) {
            return "https://player.vimeo.com/video/" + GetVimeoId(props.videoLesson.VideoObject.VideoLink);
        }
        return "";
    };

    function getVideoAlign(): string {
        switch (props.videoLesson.Align) {
            case HorizontalAlignEnum.Center:
                return 'mx-auto px-0';
            case HorizontalAlignEnum.Left:
                return 'mr-auto px-0';
            case HorizontalAlignEnum.Right:
                return 'ml-auto px-0';
            default:
                return '';
        }
    }

    return (
        <AttendMediaContentComponentStyles
            className="mb-3"
            fullSize={props.videoLesson.FullSize}
        >
            <Col
                className={"py-3 mx-auto"}
                md={11}
            >
                <Row>
                    <Col md={props.videoLesson.FullSize ? 11 : 6} className={getVideoAlign()}>
                        {props.videoLesson.VideoObject.VideoSource === VideoSourceEnum.Azure ?
                            (
                                <video controls src={returnVideoUrlSRC()}>
                                </video>
                            )
                            :
                            (
                                <iframe src={returnVideoUrlSRC()} />
                            )}
                    </Col>
                </Row>
            </Col>
        </AttendMediaContentComponentStyles>
    );
};

interface AttendVideoContentComponentProps {
    content: string;
    videoLesson: VideoLessonContentViewModel;
}




