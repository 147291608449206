import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import AccountInterface from '../interface/AccountInterface';
import { BrainzAuthToken } from '../domain/BrainzAuthToken';
import LogService from '../services/LogService';

const cookies = new Cookies();
var logService = new LogService();

function logUserActivity(token) {
    logService.logUserActivity(token).then(response => {
        window.location.href = window.location.origin + "/home";
    }).catch(err => {
        console.log(err)
        window.location.href = window.location.origin + "/home";
    })
}

export const removeToken = () => {
    if (window.location.ancestorOrigins[0] === "https://teams.microsoft.com") {
        window.localStorage.removeItem(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN));
        window.location.pathname = "/userDefaultTeams";
    } else {
        cookies.remove(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), { path: "/", domain: process.env.REACT_APP_BRAINZ_DOMAIN })
        window.location.href = process.env.REACT_APP_ACCOUNT_REDIRECT!;
    }
}

export const setToken = (query) => {
    if (isTeams()) {
        window.localStorage.setItem(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), query);
    } else {
        cookies.set(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), query, { domain: String(process.env.REACT_APP_BRAINZ_DOMAIN), path: "/", secure: true, sameSite: "strict" });
    }
}

export const getToken = () => {
    if (isTeams()) {
        return window.localStorage.getItem(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN)) as string;
    } else {
        return cookies.get(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN)) as string;
    }
}

export const isTeams = (): boolean => {
    return window.location.ancestorOrigins[0] === "https://teams.microsoft.com"
}

export function getDecodedToken(): BrainzAuthToken {
    const cookieCodedToken = getToken();
    let token = jwt_decode<BrainzAuthToken>(cookieCodedToken);
    return token
}

export const refreshToken = async (firstAccess: boolean) => {
    let remainingSeconds = 0
    var token = getDecodedToken();
    if (token) {
        const currentDate = new Date();
        remainingSeconds = token.exp - (currentDate.getTime() / 1000);
    }

    if (remainingSeconds > 0 || firstAccess) {
        var refeshTokenUri = "/Account/Authorization/".concat(String(process.env.REACT_APP_APPLICATION_ID)).concat("/RefreshToken")
        AccountInterface.get(refeshTokenUri).then((result) => {
            setToken(result.data.result.token)
            if (firstAccess) {
                logUserActivity(result.data.result.token);                
            }
        }).catch(err => {
            console.log("erro refresh", err)
            removeToken();
        });
    }
    else {
        removeToken();
    }
}
