import { Col, Row, Image, Button } from "react-bootstrap"
import { createRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import ApprenticeServices from "../../../../services/ApprenticeServices"
import ChangeLessonLoader from "../../../../components/Loaders/ChangeLessonLoader"
import { AttendCourseContext } from "../../../../context/apprentice/attend/AttendCourseContext";
import { BottomText, CertificateArea, CertificateContainer } from "../AttentCoursePageStyles";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const apprenticeService = new ApprenticeServices();

interface CertificateCourseProps {
    isModule:boolean
}

const CertificateCoursePage = (props:CertificateCourseProps) => {

    const {
        apprenticeCourseProgress,
        currentUserModuleContentProgress
    } = useContext(AttendCourseContext);

    const certificateRef= useRef<HTMLDivElement>(null)
    const [isLoading, setIsLoading] = useState(true);

    const [pdfUrl, setPdfUrl] = useState<string|null>(null); 

    useEffect(() => {
        loadPage()
    }, [])

    

    async function GetImageFromStorage(src: string) {
        return await fetch(src, { method: 'GET', mode: 'cors', cache: 'no-cache', referrerPolicy: "strict-origin-when-cross-origin" });
    }

    const convertImagesToBase64 = async (element:HTMLElement) => {
        try{
            const images = element.querySelectorAll('img');

            for (let index = 0; index < images.length; index++) {

                const img = images[index];

                const src = img.getAttribute('src');
            
                if (src && !src.startsWith('data:')) {
                    
                    let response = await GetImageFromStorage(src)
                    
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        img.setAttribute('src', base64data);
                    
                    };
                    
                    let blob = await response.blob()

                    reader.readAsDataURL(blob);
                }
            }
        }catch (error) {
            throw error
        }
      };

    async function loadPage(){

        const certificatePlaceHolder = certificateRef.current;

        if(certificatePlaceHolder==null){
            return;
        }

        if(props.isModule){
            let response = await apprenticeService.getMyModuleCertificateDetails({
                courseId: apprenticeCourseProgress.courseId,
                moduleId: currentUserModuleContentProgress.moduleId,
                apprenticeId: apprenticeCourseProgress.apprenticeId
            })

            certificatePlaceHolder.innerHTML = response.result.certificate.trim();

        }else{
            let response = await apprenticeService.getMyCourseCertificateDetails({
                courseId: apprenticeCourseProgress.courseId,
                apprenticeId: apprenticeCourseProgress.apprenticeId
            })

            certificatePlaceHolder.innerHTML = response.result.certificate.trim();
        }

        await convertImagesToBase64(certificatePlaceHolder.firstElementChild as HTMLElement)



        setTimeout(() => {
            certificatePlaceHolder.style.display = 'block';

            html2canvas(certificatePlaceHolder.firstElementChild as HTMLElement,{scale:3}).then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1);
                // Cria um documento PDF usando jsPDF
                const pdf = new jsPDF('landscape', 'px', 'a4'); // Defina o tamanho e orientação conforme necessário
                const pdfWidth = pdf.internal.pageSize.getWidth();  // Largura do PDF em mm
                const pdfHeight = pdf.internal.pageSize.getHeight(); // Altura do PDF em mm
        
                // Dimensões da imagem
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
        
                // Calcular escala para ajustar a imagem ao PDF
                const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight); // A menor escala que mantém a imagem proporcional
        
                const imgScaledWidth = imgWidth * ratio;  // Largura da imagem escalada
                const imgScaledHeight = imgHeight * ratio; // Altura da imagem escalada
    
                pdf.addImage(imgData, 'PNG', 0, 0, imgScaledWidth, imgScaledHeight);
    
                // Converte o PDF para um blob
                const pdfBlob = pdf.output('blob');
    
                // Cria uma URL temporária a partir do Blob
                const pdfUrl = URL.createObjectURL(pdfBlob);
                
                // Define a URL temporária para o estado
                setPdfUrl(pdfUrl);
            })
    
            certificatePlaceHolder.style.display = 'none';
            certificatePlaceHolder.innerHTML = '';
    
            setIsLoading(false);
        }, 100);
    }

    const printPdf = () => {
        const link = document.createElement('a');
        link.href = pdfUrl!;
        link.setAttribute('download', 'Certificado.pdf');
    
        // Adiciona o link temporário ao DOM e clica nele para iniciar o download
        document.body.appendChild(link);
        link.click();
    
        // Remove o link temporário do DOM
        document.body.removeChild(link);
    }

    return (<> 
            {
            isLoading ?
            (
                <ChangeLessonLoader
                    sideMenuIsOpen={false}
                />
            )
            :
            (
                <CertificateContainer className="px-0">
                    <CertificateArea className="d-none d-md-block">
                    {pdfUrl!==null && (
                            <embed
                                src={pdfUrl.concat("#view=FitV&toolbar=0")}
                                type="application/pdf"
                                style={{width: '100%', minHeight: '70vh',  objectFit: 'fill'}}
                            />

                    )}  
                    </CertificateArea>

                    {props.isModule?<section>
                        <section onClick={()=>{printPdf()}} className="d-block d-md-none hold-download-mobile">
                        <img src="/img/certificate/certificado-modulo-mobile-v2.png" />
                    </section>
                    </section>:<section>
                    <section onClick={()=>{printPdf()}} className="d-block d-md-none hold-download-mobile">
                        <img src="/img/certificate/certificado-curso-mobile-v2.png" />
                    </section>
                    </section>}
                    <Row className="download">
                        <Col md="auto" className="ml-auto my-auto">
                            <Button onClick={()=>{printPdf()}}>
                                <i className="bi bi-download"/>
                            </Button>
                        </Col>
                    </Row>
                    <BottomText className="ml-3 mt-4">
                        <Col className="my-auto">
                            <h3><i className="bi bi-trophy-fill"></i> você completou o {props.isModule?"módulo":"curso"}!</h3>
                            <p>Você pode imprimir seu certificado ou salvar para postar nas suas redes sociais.</p>
                        </Col>
                    </BottomText>
                    
                </CertificateContainer>
            )
        }    
        <div ref={certificateRef} style={{display:'none'}}>
        </div>
       
    </>)
}

export default CertificateCoursePage;





