import { createContext, ReactNode, useState } from "react";

export const AddEmbedModalContext = createContext({} as AddEmbedModalContextData);

interface AddEmbedModalContextProps {
    getEmbedFromModal: (embed:string) => void
    onClose: () => void
 

    reRender:()=>void
    children: ReactNode;
}

interface AddEmbedModalContextData {
    embedString:string
    setEmbedString:React.Dispatch<React.SetStateAction<string>>

    onSubmit:()=> void
    handleEmbedStringChange:(e:any)=> void
    isEmbedValid:()=> boolean

    onClose:()=> void
}

export const AddEmbedModalContextProvider = (props: AddEmbedModalContextProps) => {
    const [showAddEmbedModal, setShowAddEmbedModal] = useState(true)

    const [embedString, setEmbedString] = useState("")

    const onClose = () => {
        props.onClose()
    };    

    const handleEmbedStringChange = (e) => {
        setEmbedString(e.target.value)
        props.reRender()
    }

    const isEmbedValid = ():boolean =>{
        return (embedString !== "")
    }

    const onSubmit = () =>{
        props.getEmbedFromModal(embedString)
        setEmbedString("")
        setShowAddEmbedModal(false)
        props.reRender()
    }


    return (
        <AddEmbedModalContext.Provider
            value={{
                        
                embedString,
                setEmbedString,

                handleEmbedStringChange,

                onSubmit,
                isEmbedValid,

                onClose,
            }}
        >
            {props.children}
        </AddEmbedModalContext.Provider>
    );
};




