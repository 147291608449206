import { useContext } from 'react';
import { Modal } from "react-bootstrap";
import { AddFileNavigationPositionEnum } from '../../../../domain/enum/AddFileNavigationPosition';
import { AddDocumentModalContext as AddDocumentModalContext } from '../context/AddDocumentModalContext';

export const AddDocumentModalTitle = () => {
    const {
        navigationPosition,
        documentObject
    } = useContext(AddDocumentModalContext);

    switch (navigationPosition) {
        case AddFileNavigationPositionEnum.index: {
            return (
                <Modal.Title>
                    Escolher Documento
                </Modal.Title>
            );
        }
        case AddFileNavigationPositionEnum.verifyFile: {
            return (<>
                {
                    documentObject.Url == "" ?
                    (
                        <Modal.Title>
                            Escolher Documento
                        </Modal.Title>
                    )
                    :
                    (
                        <Modal.Title>
                            Conferir Documento
                        </Modal.Title>
                    )
                }
            </>);
        }
        case AddFileNavigationPositionEnum.chooseViewType: {
            return (
                <Modal.Title>
                    Como você quer exibir este documento?
                </Modal.Title>
            );
        }
        default:
            return <></>;
    }
};
