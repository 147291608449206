import { useContext} from "react";

//Compoenents
import { AttendCourseContext } from '../../../../context/apprentice/attend/AttendCourseContext';
import { AssessmentContext } from '../../../../context/apprentice/attend/AssessmentContext';
import AttendAssessmentHeader from "../../../../components/Headers/AttendAssessmentHeader"
import ConfirmExitAssessmentModal from "../../../../components/Modals/ConfirmExitAssessmentModal"
import AssessmentFeedbackPage from './AssessmentFeedbackPage'
import RespondingAssessment from "../../../../components/Loaders/RespondingAssessment";


//Services
import { AttendAssessmentForm } from "./AttendAssessmentForm";

export const AttendAssessmentPage = () => {
    const {
        currentUserModuleContentProgress
    } = useContext(AttendCourseContext);

    const {
        showFeedback,
        sendingResponse,
        currentQuestion,
    } = useContext(AssessmentContext);

  
    return (<>

        {sendingResponse ? 
            (<RespondingAssessment />)
            :
            (<>
                {showFeedback
                ? (
                    <AssessmentFeedbackPage
                        isFullScreen={true} />
                )
                : (<>
                    <AttendAssessmentHeader
                        totalQuestions={currentUserModuleContentProgress.assessment!.assessmentQuestions.length}
                        currentQuestion={currentQuestion + 1}
                    />

                    <ConfirmExitAssessmentModal />
                    <AttendAssessmentForm/>
                </>)
                }
            </>)
        }
    </>);
};

export default AttendAssessmentPage;



