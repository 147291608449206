import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap"
import { AssessmentContext } from "../../context/apprentice/attend/AssessmentContext"
import { TertiaryPurpleButton } from "../Buttons/styles"
import { AttendCourseHeaderStyles, FeedBackQuizHeaderStyles } from './styles'

interface AssessmentFeedBackHeaderProps {
    reproved: boolean    
}

const AssessmentFeedBackHeader = (props: AssessmentFeedBackHeaderProps) => {

    const {        
        goBackCourse        
    } = useContext(AssessmentContext);
                       
    return (
        <div>
            <FeedBackQuizHeaderStyles>
                <Container className="d-none d-md-block" fluid={true}>
                    <Row className="base">
                        <Col md={1}>
                        </Col>
                        {
                            props.reproved && <Col md={2}></Col>
                        }
                        <Col md={"auto"} className="my-auto mx-auto pl-5">
                            <h2>Resultado da avaliação</h2>
                        </Col>
                        <Col md={"auto"} className="my-auto ml-auto pr-4">
                            {/*onClick={props.showModal}*/}
                            <TertiaryPurpleButton className="ml-auto" onClick={() => goBackCourse()}>
                                Voltar para o curso <i className="bi bi-arrow-right" />
                            </TertiaryPurpleButton>
                        </Col>
                    </Row>
                </Container>
                <AttendCourseHeaderStyles className="holdHeaderQuizz d-block d-md-none">
                    <Container fluid={true}>
                        <Row>
                            <Col xs={10}>
                                <h1 >
                                Resultado da avaliação
                                </h1>
                            </Col>
                            <Col xs={1}>
                                <i onClick={() => goBackCourse()} className="bi bi-x-lg mr-3" />
                            </Col>
                        </Row>
                    </Container>
                </AttendCourseHeaderStyles>

            </FeedBackQuizHeaderStyles>
        </div>
    )
}

export default AssessmentFeedBackHeader;