import { createContext, ReactNode, useRef, useState } from "react";
import { AddFileNavigationPositionEnum as AddFileNavigationPositionEnum } from "../../../../domain/enum/AddFileNavigationPosition";
import { FileUploadServices } from '../../../../services/FileUploadServices'
import { AddDocumentObject, DocumentViewTypeEnum } from "./AddDocumentObject";

const uploadService = new FileUploadServices();

export const AddDocumentModalContext = createContext({} as AddDocumentModalContextData);

interface DocumentModalContextProps {
    getDocumentFromModal: (documentObject: AddDocumentObject) => void
    onClose: () => void

    reRender: () => void
    children: ReactNode;
}

interface AddDocumentModalContextData {
    isInvalidDocumentInput: boolean
    setIsInvalidDocumentInput: React.Dispatch<React.SetStateAction<boolean>>

    isUploading: boolean
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>

    showConfirmExitAddDocument: boolean
    setShowConfirmExitAddDocument: React.Dispatch<React.SetStateAction<boolean>>

    showAddDocumentModal: boolean
    setShowAddDocumentModal: React.Dispatch<React.SetStateAction<boolean>>

    navigationPosition: AddFileNavigationPositionEnum
    setNavigationPosition: React.Dispatch<React.SetStateAction<AddFileNavigationPositionEnum>>

    documentObject: AddDocumentObject
    setDocumentObject: React.Dispatch<React.SetStateAction<AddDocumentObject>>

    errorMessage: string
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>

    onSubmit: (viewType: DocumentViewTypeEnum) => void
    handleAddFileButton: () => void

    cancelUpload: () => void
    onClose: () => void
    onConfirmClose: () => void
    onCancelClose: () => void

    hiddenFileInput: React.RefObject<HTMLInputElement>
    onInputFileChange: (e: any) => void
}

export const AddDocumentModalContextProvider = (props: DocumentModalContextProps) => {

    const [isUploading, setIsUploading] = useState(false);
    const [showConfirmExitAddDocument, setShowConfirmExitAddDocument] = useState(false)
    const [showAddDocumentModal, setShowAddDocumentModal] = useState(true)
    const [navigationPosition, setNavigationPosition] = useState<AddFileNavigationPositionEnum>(AddFileNavigationPositionEnum.index)
    const [isInvalidDocumentInput, setIsInvalidDocumentInput] = useState(false)

    const [documentObject, setDocumentObject] = useState<AddDocumentObject>({ Url: "" } as any)

    const [errorMessage, setErrorMessage] = useState("")
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const validateFileType = (fileType: string) => {        
        var allowedTypes = [            
            "application/pdf",
            "text/plain",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",            
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"            
        ];

        var valid = allowedTypes.indexOf(fileType) > -1;
        return valid;
    }

    const validateFileSize = (fileSize: any) => {
        var valid = (fileSize / 1024 / 1024) <= 50;
        return valid;
    }

    const onInputFileChange = (e) => {
        setIsUploading(true);
        setIsInvalidDocumentInput(false)

        const fileUploaded = e.target.files[0];

        if (validateFileType(fileUploaded.type) == false) {
            setErrorMessage("Formato não suportado");
            setIsInvalidDocumentInput(true);
            setIsUploading(false);
            return;
        }

        if (validateFileSize(fileUploaded.size) == false) {
            setErrorMessage("O arquivo é muito grande, escolha um arquivo com até 50MB");
            setIsInvalidDocumentInput(true);
            setIsUploading(false);
            return;
        }

        if (isInvalidDocumentInput == false) {
            const formData = new FormData();
            formData.append('File', fileUploaded);
            formData.append('ValidatedFormat', "true");

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data, document/*, Document/*'
                }
            }

            uploadService.uploadTempContainer(formData, config).then(function (response) {
                if (response.success) {
                    var documentDetails = documentObject;
                    documentDetails.Url = response.result.path;
                    documentDetails.Name = response.result.name;
                    documentDetails.ViewType = DocumentViewTypeEnum.undefined;
                    documentDetails.Type = fileUploaded.type;
                    setDocumentObject(documentDetails);
                    setIsUploading(false);
                }
            }).catch(err => {
                setErrorMessage("Não conseguimos carregar seu arquivo, tente novamente");
                setIsInvalidDocumentInput(true);
                console.log(err);
            });
            //setUploadMessage(fileUploaded.name);
        }       

        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }
    }

    const handleAddFileButton = () => {
        if (null !== hiddenFileInput.current) {
            hiddenFileInput.current.value = ''
            hiddenFileInput.current.click();
        }
    }

    const onConfirmClose = () => {
        props.onClose();
    };

    const onCancelClose = () => {
        setShowAddDocumentModal(true);
        setShowConfirmExitAddDocument(false);
    };

    const onClose = () => {
        if (documentObject.Url === "") {
            onConfirmClose();
        }
        else {
            setShowConfirmExitAddDocument(true);
            setShowAddDocumentModal(false);
            props.reRender();
        }
    }

    const cancelUpload = () => {
        setIsUploading(false);
    }

    const onSubmit = (viewType: DocumentViewTypeEnum) => {
        documentObject.ViewType = viewType;
        props.getDocumentFromModal(documentObject);

        setNavigationPosition(AddFileNavigationPositionEnum.index);
        setDocumentObject({ Url: "" } as any);
        setShowAddDocumentModal(false);
        props.reRender();
    }

    return (
        <AddDocumentModalContext.Provider
            value={{
                isInvalidDocumentInput,
                setIsInvalidDocumentInput,

                isUploading,
                setIsUploading,

                showConfirmExitAddDocument,
                setShowConfirmExitAddDocument,

                showAddDocumentModal,
                setShowAddDocumentModal,

                navigationPosition,
                setNavigationPosition,

                documentObject,
                setDocumentObject,

                errorMessage,
                setErrorMessage,

                onSubmit,
                handleAddFileButton,

                cancelUpload,
                onClose,
                onConfirmClose,
                onCancelClose,

                hiddenFileInput,
                onInputFileChange
            }}
        >
            {props.children}
        </AddDocumentModalContext.Provider>
    );
};
