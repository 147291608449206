import { Col, Row } from "react-bootstrap";

export default function AttendEmbedContentComponent(props: AttendEmbedContentComponentProps) {

    return (
        <Row
            className="md-mx-4 mb-3"
        >
            <Col
                className={"py-3"}
            >
                <Row>
                    <Col md={"auto"} className={"mx-auto"}>
                        <div dangerouslySetInnerHTML={{ __html: props.content }} />

                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
interface AttendEmbedContentComponentProps {
    content: string;
}
