import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import ConsumptionByCourseViewModel from '../../domain/models/report/ConsumptionByCourseViewModel'
import PaginatedSearchResult from "../../domain/util/PaginatedSearchResult"
import { StyledPagedSearchButtonList, CreatorCourseListComponentStyles } from "./styles"

interface ReportByCourseListComponentProps{
    consumptionByCourseListViewPaginatedSearch:PaginatedSearchResult<ConsumptionByCourseViewModel>
    isLoading:boolean
    getConsumptionByCourse:(pageNumber:number) => void
}

const ReportByCourseListComponent = (props:ReportByCourseListComponentProps) => {    

    function loadPage(pageNumber:number){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
          });

        props.getConsumptionByCourse(pageNumber);
    }

    const RenderPageButtons = (activeSearch:PaginatedSearchResult<any>):JSX.Element =>{
        let buttons:JSX.Element[] = [
            <ListGroup.Item>
                <Button className={activeSearch.currentPage === 1?"active":""} onClick={() => loadPage(1)}>
                    <p>1</p>
                </Button>
            </ListGroup.Item>
        ]

        for(let i=2; i < activeSearch.totalPages ; i++){
            buttons.push(
                <ListGroup.Item>
                    <Button className={"my-auto "+(activeSearch.currentPage === i?"active":"")} onClick={() => loadPage(i)}>
                        <p>{i}</p>
                    </Button>
                </ListGroup.Item>
            )
        }

        if( activeSearch.totalPages > 1){
            buttons.push(
                <ListGroup.Item>
                    <Button className={activeSearch.totalPages === activeSearch.currentPage?"active":""} onClick={() => loadPage(activeSearch.totalPages)}>
                        <p>{activeSearch.totalPages}</p>
                    </Button>
                </ListGroup.Item>
            )
        }

        return(
            <StyledPagedSearchButtonList horizontal>
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasPrevious} onClick={() => loadPage(activeSearch.currentPage-1)}>
                        <i className="bi bi-chevron-left"/>
                    </Button>
                </ListGroup.Item>
                {buttons}
                <ListGroup.Item>
                    <Button disabled={!activeSearch.hasNext} onClick={() => loadPage(activeSearch.currentPage+1)}>
                        <i className="bi bi-chevron-right"/>
                    </Button>
                </ListGroup.Item>
            </StyledPagedSearchButtonList>)
    }

    const RenderLoadging = () : JSX.Element => {
        return (<>
            <Row className="mt-5 empty-result">
                <Col md="auto" className="ml-auto">
                    <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                </Col>
                <Col md="auto" className="my-auto mr-auto">
                    <Row>
                        <Col>
                            <h1>
                                Carregando dados...
                            </h1>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </>)
    }

    const RenderNoResult = () : JSX.Element => {
        return (<>
            <Row className="mt-5 empty-result">
                <Col md="auto" className="ml-auto">
                    <img className="mr-3" src={"/img/student/home/woman-waiting.svg"} />
                </Col>
                <Col md="auto" className="my-auto mr-auto">
                    <Row>
                        <Col>
                            <h1>
                                Nenhum registro
                            </h1>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </>)
    }

    const RenderResultTable = () : JSX.Element => {

        return (<>
            {props.consumptionByCourseListViewPaginatedSearch.totalRecords > 0
                ? (<>                       
                    <Row className="mt-2">
                        <Col md={6}>
                            <p className="pl-3">
                                Título do curso
                            </p>
                        </Col>
                        <Col md={2}>
                            <p className="pl-3">
                                Curso status
                            </p>
                        </Col>
                        <Col md={2}>
                            <p>
                                Pessoas cursando
                            </p>
                        </Col>
                        <Col md={2}>
                            <p>
                                Pessoas finalizadas
                            </p>
                        </Col>                                
                    </Row>
                    <Row className={props.isLoading?"d-none":""}>
                        <Col>
                            <ListGroup className="w-100 px-0">
                            {
                                props.consumptionByCourseListViewPaginatedSearch.items.map(item =>{
                                    return (
                                        <ListGroupItem className="px-0 py-2">
                                            <Card>
                                                <Card.Body className="py-2">
                                                    <Row>
                                                        <Col md={6} className="my-auto">
                                                            <p>{item.courseTitle}</p>
                                                        </Col>  
                                                        <Col md={2} className="my-auto">
                                                            <p>{item.courseStatus}</p>
                                                        </Col>                                                                        
                                                        <Col md={2} className="my-auto">
                                                            <p>{item.qtyApprenticeInProgress}</p>
                                                        </Col>
                                                        <Col md={2} className="my-auto">
                                                            <p>{item.qtyApprenticeDone}</p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </ListGroupItem>
                                    )
                                })
                            }
                            </ListGroup>
                        </Col>
                    </Row>
                    {
                        props.consumptionByCourseListViewPaginatedSearch.totalPages > 1 ?
                        (
                            <Row className={props.isLoading?"d-none":""}>
                                <Col md={"auto"} className="mx-auto my-4">
                                    {
                                        RenderPageButtons(props.consumptionByCourseListViewPaginatedSearch)
                                    }
                                </Col>
                            </Row>
                        )
                        : (<></>)
                    }
                </>)
                : (RenderNoResult())
            }
        </>)
    }

    return(
        <CreatorCourseListComponentStyles className="ml-3">            
            {props.isLoading ? 
                (RenderLoadging())
                : (RenderResultTable())
            }
        </CreatorCourseListComponentStyles>
    )
}

export default ReportByCourseListComponent;