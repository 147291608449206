import { useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { PrimaryPurpleButton, SecondaryGreenButton } from "../../../../../components/Buttons/styles";
import { TaskContext } from "../../../../../context/apprentice/attend/TaskContext";
import SendTaskModalState from "../../../../../domain/enum/SendTaskModalState";
import styled from 'styled-components'

const SendTaskError = () => {
    const {
        handleClose,
        handleRetrySend
    } = useContext(TaskContext);

    return (
        <>  
            <HoldModal className="modalRed">
                <Modal.Body>
                    <Row className="my-3" style={{ placeContent: 'center' }}>
                        <i className="bi bi-exclamation-triangle" style={{ fontSize: 80, color: '#D30808' }}></i>
                    </Row>
                    <Row className="mb-3 mt-4" style={{ textAlign: 'center' }}>
                        <Col>
                            <h1>
                                Tivemos um problema para enviar o arquivo
                            </h1>
                        </Col>
                    </Row>
                    <Row className="my-2" style={{ textAlign: 'center' }}>
                        <Col>
                            Volte e tente novamente.
                        </Col>
                    </Row>
                </Modal.Body>
                {/* Desktop */}
                <Modal.Footer className="d-none d-md-block d-lg-block">
                    <Row>                   
                        <Col md={5}>
                            <SecondaryGreenButton onClick={()=>handleClose()}>
                                <Row>
                                    <Col md="auto" className="mx-auto">
                                        <p>
                                            Cancelar Envio
                                        </p>
                                    </Col>
                                </Row>
                            </SecondaryGreenButton>
                        </Col>
                        <Col md={7}>
                            <PrimaryPurpleButton onClick={()=>handleRetrySend()}>
                                <Row>
                                    <Col md="auto" className="mx-auto">
                                        <p>
                                            Voltar
                                        </p>
                                    </Col>
                                </Row>
                            </PrimaryPurpleButton>
                        </Col>
                    </Row>
                </Modal.Footer>
                {/* Mobile */}
                <Modal.Footer className="d-block d-md-none d-lg-none">
                    <Row>
                        <Col xs={12}>
                            <PrimaryPurpleButton onClick={()=>handleRetrySend()}>
                                Voltar
                            </PrimaryPurpleButton>
                        </Col>
                        <Col xs={12}>
                            <SecondaryGreenButton className="mt-2" onClick={()=>handleClose()}>
                                Cancelar Envio
                            </SecondaryGreenButton>
                        </Col>
                    </Row>
                </Modal.Footer>                
            </HoldModal>
        </>
    );
} 
export default SendTaskError;

const HoldModal = styled(Col)`

    @media screen and (max-width: 990px){

        &.modalRed {
            border: solid 2px #D30808 !important;
            border-radius: 12px !important;
        }

        img {
            margin: auto !important;
            width: 100% !important;
        }

        h1 {
            font-size: 5vmin !important;
            text-align: center;
        }
    }
`
