import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import styled from 'styled-components'
import Toggle from 'react-toggle';
import { AddModuleContext } from "../../../../../../../context/creator/createCourse/AddModuleContext";
import { useCreateTaskModuleContentContext } from "../../../../../../../context/creator/createCourse/CreateTaskModuleContentContext";
import { useContext } from "react";
import TaskViewModel from "../../../../../../../domain/models/course/module/task/TaskViewModel";

export const TaskDetailsComponent = () => {

    const {
        getSelectedModuleContent,
        handleModuleContentTitleChange,
        handleModuleContentTitleBlur
    } = useContext(AddModuleContext);

    const {
        handleIsTaskRequired,
        handleTaskOrientationChange,
    } = useCreateTaskModuleContentContext()

    
    const moduleContent = getSelectedModuleContent()


    return(
        <>
            <TaskDetailsComponentStyles>
                <Col>
                    <Row className="correction-nav-tip">
                        <Col className="my-3 mx-3">
                            <p>
                                A tarefa é entregue através do envio de um arquivo, de acordo com as orientações que você inserir nesta tela. Você poderá corrigir as tarefas em  <span>Gerenciar Cursos {'>'} Tarefas</span>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-4 mb-1'>
                            <Form.Label htmlFor='task-title'>
                                Título da Tarefa
                            </Form.Label>
                            <InputGroup>
                                <FormControl
                                    id="task-title"
                                    placeholder="Digite um título para a tarefa"
                                    autoComplete="off"
                                    aria-describedby="task-title"
                                    value={moduleContent.title}
                                    onChange={(e) => handleModuleContentTitleChange(e)}
                                    onBlur={()=>handleModuleContentTitleBlur()}
                                    />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2 mb-4'>
                            <Form.Label htmlFor='delivery-orientations'>
                                Orientações para Entrega
                            </Form.Label>
                            <InputGroup>
                                <FormControl
                                    id="delivery-orientations"
                                    as="textarea"
                                    rows={10}
                                    placeholder="Dê algumas orientações sobre como resolver a tarefa e como entregar o arquivo"
                                    autoComplete="new-password"
                                    aria-describedby="delivery-orientations" 
                                    value={moduleContent.moduleTask!.orientation} 
                                    onChange={(e) => handleTaskOrientationChange(e)}
                                    />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-2 mb-4'>
                            <Form.Label htmlFor='delivery-orientations'>
                                Tarefa obrigatória
                            </Form.Label>
                            <Row>
                                <Col className='mb-3'>
                                    <p className="toggle-text">
                                        Se estiver ativado, as pessoas não poderão seguir em frente no curso sem entregar esta tarefa
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="auto">
                                    <Toggle
                                        icons={false}
                                        checked={moduleContent.isRequired}
                                        onChange={()=>handleIsTaskRequired()}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </TaskDetailsComponentStyles>
        </>
    )
}

const TaskDetailsComponentStyles = styled(Row)`
    .correction-nav-tip{
        background-color:#F9F9F9;
    }
    .toggle-text{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.944vmin;
        line-height: 2.777vmin;
        color: #636060;
    }
    span{
        color:#0C8292;
    }
    overflow-y:scroll;
    max-height:90vh;
`
