import { useContext } from "react";
import { Col, Row, Button } from "react-bootstrap";
import styled from 'styled-components';
import { PrimaryPurpleButton } from "../../../../components/Buttons/styles";

//Context
import { AttendCourseContext } from '../../../../context/apprentice/attend/AttendCourseContext';
import { AssessmentContainer, AssessmentRequiredTitle, AssessmentRequiredDescription, Practice, PracticeDescription, TagAlert, AssessmentStartButton } from "./styles";

export const AssessmentStartPage = () => {
    
    const {
        currentUserModuleContentProgress,
        setIsLoadingAssessment
    } = useContext(AttendCourseContext);

    return (
        <AssessmentContainer>
            <Row>
                <Col>
                    {currentUserModuleContentProgress.isRequired
                        ? (<>
                            <AssessmentRequiredTitle className="mb-3">Avaliação obrigatória do módulo</AssessmentRequiredTitle>
                            <AssessmentRequiredDescription>Você deve fazer esta avaliação para completar o curso.</AssessmentRequiredDescription>
                        </>)
                        : (<AssessmentRequiredTitle>Avaliação não obrigatória do módulo</AssessmentRequiredTitle>)
                    }
                </Col>
            </Row>

            <Row>
                <Col className="mb-md-4 mt-3 mt-md-5">
                    <Practice className="mb-3">Pratique o que aprendeu</Practice>
                    <PracticeDescription>
                        {
                            currentUserModuleContentProgress.assessment!.description == "" 
                            ? ("Vamos exercitar o que aprendeu no módulo? A avaliação ajuda a fixar o conteúdo e a identificar pontos que podem não ter ficado claros para você.")
                            :(currentUserModuleContentProgress.assessment!.description)
                        }
                    </PracticeDescription>
                </Col>
            </Row>

            <Row>
                <Col className="mb-md-4 mb-2">
                    
                    <TagAlert>  
                        <i className="bi bi-chat-dots"></i> 
                        Você não poderá acessar as aulas durante a avaliação, considere revisar o conteúdo do módulo
                    </TagAlert>
                </Col>
            </Row>

            <Row className="d-none d-md-block d-lg-block">
                <Col className="mb-4 mt-2">
                    <AssessmentStartButton onClick={() => setIsLoadingAssessment(true)}>
                        <Row>
                            <Col md="auto">
                                <i className="bi bi-list-task"></i> 
                            </Col>
                            <Col md="auto" className="pl-0 my-auto">
                                <p>
                                Começar avaliação   
                                </p>
                            </Col>
                        </Row>
                    </AssessmentStartButton>
                </Col>
            </Row>
            <section className="mt-2 mr-3 ml-3 d-block d-md-none d-lg-none text-center">
                <AssessmentStartButton onClick={() => setIsLoadingAssessment(true)}>
                    Começar avaliação 
                    <i className="bi bi-list-task"></i>
                </AssessmentStartButton>
            </section>
        </AssessmentContainer>
    )
};

export default AssessmentStartPage;

