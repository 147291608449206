import { useContext } from 'react';
import { Col, Row } from "react-bootstrap";
import { SecondaryGreenButton } from '../../../Buttons/styles';
import { AddVideoModalContext } from '../context/AddVideoModalContext';

export function SendFileScreen() {
    const {
        navigationPosition, handleAddFileButton, isInvalidVideoInput, handleVideoLinkChange, errorMessage, videoObject, returnVideoUrlSRC, setNavigationPosition
    } = useContext(AddVideoModalContext);

    return <>
        {videoObject.VideoLink === "" && videoObject.VideoName === "" ?
            (
                <>
                    {isInvalidVideoInput ?
                        (
                            <Row className='mb-3'>
                                <Col className="erro-message mx-auto py-2">
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0">
                                            <i className='bi bi-exclamation-octagon' />
                                        </Col>
                                        <Col className="my-auto">
                                            <p>
                                                {errorMessage}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <></>
                        )}

                    <Row>
                        <SecondaryGreenButton className="w-100" onClick={handleAddFileButton}>
                            <Row className='my-1'>
                                <Col md="auto" className="ml-auto my-auto pr-0">
                                    <i className="bi bi-files" />
                                </Col>
                                <Col md={7} className="text-left">
                                    <p>
                                        Escolher Arquivo
                                    </p>
                                </Col>
                            </Row>
                        </SecondaryGreenButton>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <p>
                                São aceitos arquivos no formato .mp4, com tamanho de até 100MB.
                            </p>
                        </Col>
                    </Row>

                </>
            )
            :
            (
                <>
                    {isInvalidVideoInput ?
                        (
                            <Row className='mb-3'>
                                <Col className="erro-message mx-auto py-2">
                                    <Row>
                                        <Col md="auto" className="my-auto pr-0">
                                            <i className='bi bi-exclamation-octagon' />
                                        </Col>
                                        <Col className="my-auto">
                                            <p>
                                                {errorMessage}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                        :
                        (
                            <></>
                        )}

                    <Row className="mt-3 mb-4">
                        <Col className='my-auto ml-5'>
                            <p>
                                {videoObject.VideoName}
                            </p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md="auto" className='mx-auto mt-4'>
                            <video controls src={videoObject.VideoLink}>
                            </video>
                        </Col>
                    </Row>
                </>
            )}
    </>;
}
