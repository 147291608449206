import { Container } from "react-bootstrap";
import styled from "styled-components"

export const TooltipContainer = styled.div`
  
    box-sizing: border-box;
    width: auto;
    height: 48px;
    background: #F9F9F9;
    border: 1px solid #E1F4F7;
    border-radius: 4px;

    .row {
        padding: 10px;
    }

    i {
        font-size: 2vmin;
           font-weight: 600;
        color: #0C8292;    
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.8vmin;
        line-height: 3.33vmin;
        color: #636060;
    }

    @media screen and (max-width: 990px){
        i {
            font-size: 4vmin !important;
            margin-right: 10px;
        }

        p {
            font-size: 5vmin !important;  
        }
    }
`